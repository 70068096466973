<div id="rkn-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="lil-demon-box">
                    <img src="/assets/images/rkn/lil-demons/eric-bio.jpg" alt="Profile picture of Eric Malone" class="img-responsive">
                    <a href="https://www.instagram.com/ericchrishon/" target="_blank" rel="noopener" class="lil-icons instagram" (click)="clickTrackerInsta()">
                        <span class="sr-only">Instagram logo</span>
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.facebook.com/TheProsecutorDragRacing/" target="_blank" rel="noopener" class="lil-icons facebook" (click)="clickTrackerFacebook()">
                        <span class="sr-only">Facebook logo</span>
                        <i class="fa fa-facebook-official" aria-hidden="true"></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-6">
                <div class="white-block short">
                    <h2>Eric Malone</h2>
                    <p>Eric Malone leads a fearsome squad of grudge-style drag racers out of Muscle Shoals, AL, known as
                      Team 256. They’re the focus of the MotorTrend reality show <em>Fastest Cars in the Dirty South</em>. Eric
                      and the team find rotting cars and bring them back to life on 1/8-mile tracks. Like any family,
                      they have fights, rivalries and a deep love for one another. The show follows the team as they
                      make each race with little track prep or time wasted.
                    </p>
                </div>
                <br>
                <div>
                  <!-- <a href="#other-modal" data-toggle="modal"> -->
                      <img src="/assets/images/rkn/lil-demons/eric-video.jpg" alt="Eric Malone with one of his rides" class="img-responsive">
                  <!-- </a> -->
              </div>
            </div>
        </div>

    </div>

    <section id="build-videos">
      <div class="site-wrapper">
        <div class="container">
          <div class="row">
              <div class="col-sm-12 section-heading">
                  <h2 class="heading1 mb-0">Build Videos</h2>
                  <p class="text-uppercase hidden-lg" *ngIf="videos != undefined && videos.length > 0">Tap to view</p>
                  <p class="text-uppercase hidden visible-lg" *ngIf="videos != undefined && videos.length > 0">Click to view</p>
              </div>
          </div>
        </div>
      </div>
      <build-videos *ngIf="!loading" [videos]="videos" (activeVideo)="openVideoModal($event)"></build-videos>
    </section>

    <section *ngIf="buildImages.length > 0" id="build-gallery">
        <div class="site-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 section-heading">
                        <h2 class="heading1 mb-0">Build Gallery</h2>
                        <p class="text-uppercase hidden-lg">Tap for full image</p>
                        <p class="text-uppercase hidden visible-lg">Click for full image</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="gallery-images">
            <div class="site-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row">
                                <ngx-masonry class="image-container">
                                    <a *ngFor="let image of buildImages; let i = index" [href]="image.source_url" class="image col-xs-6" ngxMasonryItem>
                                        <img [src]="image.source_url" [alt]="image.title['rendered']" class="img-responsive">
                                    </a>
                                </ngx-masonry>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!allImages && galleryIds.length > 4">
                        <div class="col-xs-12 button-container text-center">
                            <button class="btn btn-rkn-outline" (click)="showAllImages()">View All <i class="fa fa-chevron-down"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div id="video-modal" class="modal fade" bsModal #videoModal tabindex="-1" role="dialog" (onHidden)="closeVideoModal()">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
            </div>
            <div class="modal-body">
              <div *ngIf="modalActive" class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" [src]="activeVideo | safe: 'resourceUrl'" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
    </div>


    <div id="other-modal" class="modal fade" bsModal id="videoModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-close"><i class="fa fa-close"><span
                        class="sr-only">close</span></i></button>
                </div>
                <div class="modal-body">
                    <p class="description"></p>
                    <div class="embed-responsive embed-responsive-16by9 mb-4">
                        <div class="embed-responsive-item">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/LSNudcn3HWE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="addthis_inline_share_toolbox text-center"></div>
                </div>
            </div>
        </div>
    </div>
</div>
