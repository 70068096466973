<div class="site-wrapper">
  <section>
    <performance-cars-activation-banner></performance-cars-activation-banner>
  </section>
  <section class="barrett-jackson" id="home-top">


    <div class="hero">
      <img loading="lazy" class="img-responsive enter-to-win-date" src="/assets/images/barrett-jackson/enter-to-win.png" alt="Enter to Win">
      <img loading="lazy" class="img-responsive enter-contest-date" src="/assets/images/barrett-jackson/hero-dates.png" alt="Contest Dates">
      <img loading="lazy" class="img-responsive barrett-logo" src="/assets/images/barrett-jackson/barrett-jackson-logo.png" alt="Barrett Jackson Logo">
    </div>


    <div class="container">

      <div class="row spacing">
        <div class="col-md-10 col-md-offset-1 main-hero-container">
          <h1><i>Win a Trip To Barrett-&nbsp;Jackson In West Palm Beach</i></h1>
          <p>Join Dodge April 11-15 in West Palm Beach, FL for the Barrett-Jackson auction. Experience the adrenaline-fueled Dodge thrill rides. Enjoy an evening of excitement at the Opening Night Gala, see and bid on one-of-a-kind collectable classics, as well as hundreds of authentic automobilia items selling at no reserve.</p>
        </div>
      </div>

      <div class="row spacing">
        <div class="col-sm-12 text-center contest-ended">
          <div class="message-container">
            <h2 class="h1 text-uppercase">
              The Barret-Jackson Sweepstakes has ended.
            </h2>
            <hr>
            <h2 class="h3">
              The winner will be announced after 3.22!
            </h2>
          </div>
        </div>
      </div>


      <div class="row how-it-works spacing">
        <div class="col-md-10 col-md-offset-1">
          <h2 class="heading-main heading-main-inverted">How it Works</h2>
          <ul>
            <li>Sign up or login to enter to win</li>
            <li>Come back and enter daily from 3.7.18 – 3.21.18 to increase your chances of winning</li>
            <li>Winner will be drawn at random and announced after 3.22.18</li>
            <li>Winner must be 21 or older</li>
          </ul>
        </div>
      </div>


      <div class="row display-flex spacing prize-package">
        <div class="col-sm-6 grand-container">
          <h2>Prize Package Includes:</h2>
          <div class="divider"></div>
          <ul>
            <li>(2) Weekend Passes to the Barrett-Jackson West Palm Beach Auction</li>
            <li>(2) Barrett-Jackson Gala tickets</li>
            <li>(2) Roundtrip Flights</li>
            <li>(4) Nights Hotel Accommodations</li>
            <li>(1) $800 Gift Card for Expenses</li>
            <li>(2) $200 valued Swag Bags</li>
          </ul>
          <p><i>*See official rules for details</i></p>
        </div>
        <div class="col-sm-6 no-padding left right">
          <img loading="lazy" class="img-responsive" src="/assets/images/barrett-jackson/barrett-jackson-1.jpg" alt="Barrett Jackson Dodge Charger">
          <img loading="lazy" class="img-responsive" src="/assets/images/barrett-jackson/barrett-jackson-2.jpg" alt="Barrett Jackson Dodge Engine">
          <img loading="lazy" class="img-responsive" src="/assets/images/barrett-jackson/barrett-jackson-3.jpg" alt="Barrett Jackson Dodge Challenger">
        </div>
      </div>





      <div class="row spacing barrett-rules">
        <div class="col-md-10 col-md-offset-1">
          <h3 class="heading-main heading-main-inverted">Official Rules</h3>
          <p><strong class="rules-intro">NO PURCHASE NECESSARY.</strong> Begins 3/7/18 at 12:00 PM ET and ends 3/21/18 at 11:59:59 PM ET. Open only to eligible legal 50 U.S./DC residents who have or create a Dodge Garage account and are 21 or older. <a routerLink="/barrett-jackson-sweeps/rules">Click here</a> for Official Rules and complete details, including entry instructions, odds of winning, prize details, restrictions, etc. Void where prohibited. Sponsored by FCA US LLC, 1000 Chrysler Drive, Auburn Hills, MI 48326.</p>
        </div>
      </div>

    </div>
  </section>
</div>
