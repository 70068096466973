import {Component, Input, ViewChild, OnInit, ElementRef, HostListener, AfterViewInit} from '@angular/core';

@Component({
  selector: 'hero-video',
  templateUrl: './hero-video.component.html'
})
export class HeroVideoComponent implements OnInit, AfterViewInit {

  @Input() headline: string;
  @Input() videoMobile: string;
  @Input() videoDesktop: string;

  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  videoSrc: string;
  videoPlaying: Boolean = true;
  
  constructor() {}

  ngOnInit(): void {
    this.determineSrc();
  }

  ngAfterViewInit(): void {
    this.stageVideo();
  }

  stageVideo() {
    this.videoPlayer.nativeElement.muted = true;
    this.videoPlayer.nativeElement.load();
  }

  playPause() {
    // check status of video
    if (!this.videoPlayer.nativeElement.paused) {
      this.videoPlayer.nativeElement.pause();
      this.videoPlaying = false;
    }else {
      this.videoPlayer.nativeElement.play();
      this.videoPlaying = true;
    }
  }

  // Replaced video source based on viewport. Support isnt quite there for native video viewport media queries (like picture)
  // check window width, update source accordingly. Refresh and play the video again.
  @HostListener('window:resize', ['$event'])
  determineSrc() {
    if (this.videoMobile) {
      window.innerWidth < 768 ? this.videoSrc = this.videoMobile : this.videoSrc = this.videoDesktop;
    }else {
      this.videoSrc = this.videoDesktop;
    }
  }
}
