import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JuicerPost } from './juicer-post';
import { JuicerService } from './juicer.service';
import {Component, OnInit, ViewChild, TemplateRef, Input} from '@angular/core';

@Component({
  selector: 'dg-juicer',
  templateUrl: './juicer.component.html',
  styleUrls: ['./juicer.component.less']
})
export class JuicerComponent implements OnInit {

  juicerPosts: JuicerPost[] = [];
  openPost: JuicerPost = new JuicerPost();

  @Input('feedID') feedID = '';
  @Input('numPosts') numPosts: number;

  // Modals
  juicerModalRef: BsModalRef;
  @ViewChild('juicerModal', { static: true }) public juicerModal: TemplateRef<any>;

  constructor(
    private juicerService: JuicerService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.fetchJuicerFeed(this.numPosts, this.feedID);
  }

  fetchJuicerFeed(numberOfPosts = 6, feedID) {
    this.juicerService.getJuicerFeed(numberOfPosts, feedID).subscribe( data => {
      this.juicerPosts = data.posts.items;
    });
  }

  openJuicerPost(post: JuicerPost) {
    this.openPost = post;
    this.juicerModalRef = this.modalService.show(this.juicerModal, {class: 'modal-lg'});
  }

}
