import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { HPLAllocationService } from '../../services/trim/allocation/allocation.service';
import { TrimAllocation } from './trim-allocation';
import { TRIMS } from '../../services/trim/trim-data';
import { HelperService } from '../../../services/helper.service';
import { AdobeTMService } from '../../../services/adobe-tm.service';
import { GtmService } from '../../../services/gtm-tracking.service';

@Component({
  selector: 'hpl-dealer-availability',
  templateUrl: './dealer-availability.component.html',
  styleUrls: ['./dealer-availability.component.less']
})
export class HPLDealerAvailabilityComponent implements OnInit {
  @Input() model: string;

  @Input() dealerName: string
  @Input() dealercode: string;
  @Input() address: string;
  @Input() city: string;
  @Input() state: string;
  @Input() postalCode: string;
  @Input() phoneNumber: string;
  @Input() website: string;
  @Input() distance: number;
  @Input() isPowerBroker: boolean = false;

  // need the buildLink data here to be passed in
  @Input() modelLink: string;

  trimAllocations: Array<TrimAllocation> = [];

  isDealerDataLoaded: boolean;

  viewOrdersModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private allocationService: HPLAllocationService,
    private adobeTMService: AdobeTMService,
    private gtmService: GtmService,
    public helperService: HelperService
  ) {
    this.isDealerDataLoaded = false;
  }

  ngOnInit(): void {

  }

  openViewOrdersyModal(modalName: TemplateRef<any>, event) {
    // get the dealer data when the modal is clicked
    this.getDealerData();
    event.preventDefault();
    // Show the new modal
    this.viewOrdersModalRef = this.modalService.show(modalName, {
      class: 'availability-modal'
    });

    this.adobeTMService.pushToDataLayer({
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"tool",
        "page":"shopping-tools:horsepower-locator",
        "location":"dealer-results",
        "description":"open-orders-available-modal"
      }
    });

    this.gtmService.pushEvent({
      'event': 'hpl.dealer_card.overlay.open'
    })
  }

  closeViewOrdersModal(): void {
    this.viewOrdersModalRef.hide()
    this.adobeTMService.pushToDataLayer({
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"func",
        "page":"shopping-tools:horsepower-locator",
        "location":"orders-available-modal",
        "description":"close-modal"
      }
    })
    this.gtmService.pushEvent({
      'event': 'hpl.dealer_card.overlay.close'
    })
  }

  getDealerData() {
    // track ga evnt for this action
    this.helperService.trackGAEvent('hpl', 'Click', `View all ${this.model} orders`);

    this.allocationService.getModelAllocationsByDealer(this.dealercode, this.model).subscribe(data => {
      this.trimAllocations = [];
      // create new array of only the models we are looking of inventory
      const modelOnlyTrims = TRIMS.filter(t => t.model == this.model);

      modelOnlyTrims.forEach(t => {
        // find in the response where trimCode matches the response
        const foundTrimData = data.find(e => e.trimCode == t.trimCode);

        // setup trim Object from TRIMS data with default 0 inventory
        let trim: TrimAllocation = {
          id: t.id,
          trimName: t.trimCode,
          quantity: 0,
          trimLabel: t.label,
          type: t.type
        };

        // if the trimCode is in the response, update the quantity
        if (foundTrimData) {
          trim.quantity = foundTrimData.quantity;
        }
        this.trimAllocations.push(trim);
      })
      this.isDealerDataLoaded = true;
    });
  }

  trackCardLinks(type: string): void {
    let data: Object;
    let gtmData : Object;

    if (type === 'website') {
      data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"cta",
          "page":"shopping-tools:horsepower-locator",
          "location":"orders-available-modal",
          "description":"dealer-site"
        }
      }
      gtmData = {
        'event': 'hpl.dealer_card.overlay.website'
      }
    }

    if (type === 'phone') {
      data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"orders-available-modal",
          "description":"click-to-call"
        }
      };
      gtmData = {
        'event': 'hpl.dealer_card.overlay.phone'
      }
    }
    
    if (type === 'address') {
      data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"orders-available-modal",
          "description":"view-address"
        }
      };
      gtmData = {
        'event': 'hpl.dealer_card.overlay.address'
      }
    }

    this.adobeTMService.pushToDataLayer(data);
    this.gtmService.pushEvent(gtmData);
  }

  trackBuildAndPrice(model: string): void {
    let data: Object;
    let gtmData: Object;

    // Logic for the Download Specs variant
    if (model === 'Durango') {
      data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"cta",
          "page":"shopping-tools:horsepower-locator",
          "location":"orders-available-modal",
          "description":"download-specs"
        },
        "vehicle": {
          "make": "dodge",
          "model": "durango",
          "year": "2024"
        }
      }
      gtmData = {
        'event': 'hpl.dealer_card.overlay.configure',
        'hpl.dealer_card.overlay.download_specs.click': true
      }
    }else {
      data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"cta",
          "page":"shopping-tools:horsepower-locator",
          "location":"dealer-overlay",
          "description":"build-and-price"
        },
        "vehicle": {
          "make": "dodge",
          "model": "charger",
          "year": "2024"
        }
      }
      gtmData = {
        'event': 'hpl.dealer_card.overlay.configure',
        'hpl.dealer_card.overlay.configure_yours.click': true
      }
    }

    this.adobeTMService.pushToDataLayer(data);
    this.gtmService.pushEvent(gtmData);
  }


  // format the websites that do not start with http so they work when clicked
  formattedWebsite(website: string) {
    if (website.startsWith('http')) {
      return website;
    } else {
      return "https://" + website;
    }
  }

}
