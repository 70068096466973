import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rkn-social-icons',
  templateUrl: './social-icons.component.html'
})


export class RknSocialIcons implements OnInit {
  
  @Input() property: string;
  
  constructor() {}

  ngOnInit(): void {}

}
