import { ActivatedRoute } from '@angular/router';
import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {UserService} from "../../user.service";
import {UserModel} from "../../user";
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordMatch} from "../../../shared/validation/password-match";
import {StrongPassword} from "../../../shared/validation/strong-password";
import {isPlatformBrowser} from "@angular/common";
import {HelperService} from "../../../services/helper.service";
import { Router } from '@angular/router';

function requiredIfUSAValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }

  if (formControl.parent.get('country').value === 'US') {
    return Validators.required(formControl);
  }
  return null;
}

@Component({
  selector: 'dg-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.less']
})
export class RegisterFormComponent implements OnInit {

  // TODO Move username availability to Angular Validation
  // TODO Add email availability to Angular Validation

  // Disable Buttons while Loading
  loading: boolean = false;
  // Handle errors
  registerFormErrors = [];
  registerFormServerErrors: string = '';
  lastName: string;
  isLoading: boolean = false;

  hasEmojis: boolean = false;
  hasSpecialCharacters: boolean = false;
  hasWhitespace: boolean = false;
  beginsWithLetter: boolean = false;
  endsWeird: boolean = false;

  user: UserModel = new UserModel();
  registerForm: FormGroup;
  registerFormAlert = {
    show: false,
    status: '',
    code: '', // Shorthand for identifying certain alerts
    message: '',
    additionalMessage: ''
  };
  // Custom Error Messages
  emailError: string = '';
  passwordError: string = '';

  usernameAvailable: boolean = true;

  isCreationTokenValid: boolean = false;

  //countries for drop down
  countries: Array<Object> = [];

  constructor(
    private helperService: HelperService,
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.createRegisterForm();
    this.fetchCountries();
    if (isPlatformBrowser(this.platformId)) {
      if (this.helperService.getParameterByName('creationToken')) {
        this.isLoading = true;
        this.userService.getUserByToken(this.helperService.getParameterByName('creationToken')).subscribe( data => {
          console.log(this.router);
          console.log(data);
          this.isLoading = false;
          if (data.user) {
            this.registerForm.controls.firstName.setValue(data.user.firstName);
            this.registerForm.controls.lastName.setValue(data.user.lastName);
            this.registerForm.controls.email.setValue(data.user.email);
            this.registerForm.controls.country.setValue(data.user.country);
            this.registerForm.controls.zipCode.setValue(data.user.zipCode);
            this.isCreationTokenValid = true;
            // Ensure the URL matches to new activation token created
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: { creationToken: data.user.activationToken },
              queryParamsHandling: "merge"
            });
          }
        });
      }

      // for footer quick sign up. grabs saved value from form and sets as value for email.
      if (sessionStorage.hasOwnProperty('addr') && sessionStorage.getItem('addr').length > 0) {
        this.registerForm.controls.email.setValue(sessionStorage.getItem('addr'));
      }
    }
  }

  createRegisterForm() {
    this.registerForm = new FormGroup({
      'firstName': new FormControl('', [
        Validators.required
      ]),
      'lastName': new FormControl('', [
        Validators.required
      ]),
      'email': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'country': new FormControl('', [
        Validators.required
      ]),
      'zipCode': new FormControl(''),
      'username': new FormControl('', [
        Validators.required
      ]),
      'password': new FormControl('', [
        Validators.required,
        StrongPassword
      ]),
      'confirmPassword': new FormControl('', [
        Validators.required
      ]),
      'fcaCommunication': new FormControl(true),
      'eventCommunication': new FormControl(true)
    }, {
      validators: PasswordMatch // your validation method
    });

    // Dynamically set validation based on dropdown selection
    this.registerForm.controls.country.valueChanges.subscribe( country => {
      if(country === 'US') {
        this.registerForm.controls.zipCode.setValidators([
          Validators.pattern(/^\d{5}$/),
          requiredIfUSAValidator
        ]);
      } else {
        this.registerForm.controls.zipCode.clearValidators();
        this.registerForm.controls.zipCode.updateValueAndValidity();
      }
    })
  }

  register(event) {
    event.preventDefault();
    // Reset Validators to default
    this.registerForm.get('email').setValidators([Validators.required, Validators.email]);
    this.registerForm.get('email').updateValueAndValidity();

    // Check all fields before submitting
    Object.keys(this.registerForm.controls).forEach(field => {
      const control = this.registerForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.registerForm.valid && this.usernameAvailable) {

      this.isLoading = true;
      this.user.firstName = this.registerForm.controls['firstName'].value;
      this.user.lastName = this.registerForm.controls['lastName'].value;
      this.user.email = this.registerForm.controls['email'].value;
      this.user.country = this.registerForm.controls['country'].value;
      this.user.zipCode = this.registerForm.controls['zipCode'].value;
      this.user.username = this.registerForm.controls['username'].value;
      this.user.password = this.registerForm.controls['password'].value;
      this.user.confirmPassword = this.registerForm.controls['confirmPassword'].value;
      this.user.fcaCommunication = this.registerForm.controls['fcaCommunication'].value;
      this.user.eventCommunication = this.registerForm.controls['eventCommunication'].value;

      this.userService.trackGAevent('SignUpForm','CreateAccount');

      if (this.isCreationTokenValid) {
        this.userService.postCompleteRegistration(this.user, this.helperService.getParameterByName('creationToken')).subscribe( data => {
          if (data.status == 'success') {

            // Registration successful
            this.userService.postLogin(this.user).subscribe( data => {

              this.registerFormAlert.show = true;
              this.registerFormAlert.status = 'success';
              this.registerFormAlert.message = 'Your account registration is now complete.';
              this.registerFormAlert.code = 'success';

              this.isLoading = false;
              this.userService.trackGAevent('SignUpForm','CreateSuccess');
              this.userService.processLogin(data);

              // Add a special parameter if registering with Drag Strip Showdown
              if (this.router.url.includes('/drag-strip-showdown')) {
                this.userService.userModel.isActivatingRaces = true;
              }
            });

          }
        });
      } else {
        this.userService.postRegister(this.user).subscribe( data => {

          if (data.status == 'success') {

            // Registration successful
            this.userService.postLogin(this.user).subscribe( data => {

              this.registerFormAlert.show = true;
              this.registerFormAlert.status = 'success';
              this.registerFormAlert.message = 'Your account has successfully been created. You will receive an email to verify and activate your account.';
              this.registerFormAlert.code = 'success';

              this.isLoading = false;
              this.userService.trackGAevent('SignUpForm','CreateSuccess');
              this.userService.processLogin(data);
            });

          } else {

            // Registration errors
            this.isLoading = false;
            this.registerFormAlert.show = true;
            this.registerFormAlert.status = 'danger';
            this.registerFormAlert.message = 'Please fix the issues below.';
            this.registerFormAlert.code = 'fix-issues';
            this.userService.trackGAevent('SignUpForm','CreateFailure')
            if (data.message == 'That email address is already in use.') {
              //this.registerForm.controls.email.errors.invalid = true;
              this.registerForm.controls.email.setErrors({
                taken: true
              });
            } else {
              this.registerFormAlert.message = data.message;
            }

          }
        });
      }

    } else {
      this.registerFormAlert.show = true;
      this.registerFormAlert.status = 'danger';
      this.registerFormAlert.message = 'Please fix the issues below.';
      this.registerFormAlert.code = 'fix-issues';
    }
  }

  usernameAvailability(){
    if (this.registerForm.get('username').value === ''){
      this.usernameAvailable = true;
    } else {
      this.userService.checkUsername(this.registerForm.get('username').value).subscribe( data => {
        this.usernameAvailable = data;
      });
      /*this.userService.checkUsername(this.userService.userModel, (data) => {
        this.userService.userModel.usernameAvailability = data;
      });*/
    }
  }

  fetchCountries() {
    this.helperService.getNonUSCountries().subscribe(data => {
      this.countries = data;
    })
  }

  nameValidatedCheck(event) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Unicode_Property_Escapes
    const emojiRegex = /(\p{Emoji_Presentation})/gu
    const specialCharacterRegex = /[~`!@#$%^&()={}[\]:;,<>+\/?]/
    const whitespaceRegex = /\s/;
    const beginsWithLetterRegex = /^[a-zA-Z]/g;
    const endsWeirdRegex = /[-_.]$/;

    this.hasEmojis = emojiRegex.test(event.target.value);
    this.hasSpecialCharacters = specialCharacterRegex.test(event.target.value);
    this.hasWhitespace = whitespaceRegex.test(event.target.value);
    this.beginsWithLetter = beginsWithLetterRegex.test(event.target.value);
    this.endsWeird = endsWeirdRegex.test(event.target.value);

    if(this.hasEmojis || this.hasSpecialCharacters || this.hasWhitespace || !this.beginsWithLetter || this.endsWeird) {
      this.registerForm.controls.username.setErrors({ 'invalid': true })
    }
  }
}
