import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {NewsArticle} from "../news-article";
import {NewsArticleService} from "../news-article.service";
import { Router } from '@angular/router';

@Component({
  selector: 'dg-recent-articles',
  templateUrl: './recent-articles.component.html',
  styleUrls: []
})
export class RecentArticlesComponent implements OnInit {

  articleListLoading: boolean = true;
  @Input() categoryId: string = ''; // Needs to be a string to send properly
  @Output() articlesLoaded = new EventEmitter();
  newsArticles: NewsArticle[] = [];
  featureArticles: NewsArticle[] = [];
  stickyArticle: NewsArticle;

  constructor(
    public newsArticleService: NewsArticleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.fetchArticles(this.categoryId)
  }

  fetchArticles(categoryId) {

    this.newsArticleService.getArticles(1, categoryId, '', '', '', 10).subscribe(newsArticles => {
      this.newsArticleService.getStickyArticle().subscribe(article => {
        let stickyInTopTen = false;
        this.stickyArticle = article[0];
        
        newsArticles.forEach( (article, i) => {

          if(this.stickyArticle && article.id === this.stickyArticle?.id) {
            stickyInTopTen = true;
          }
          // if we have a sticky article in the sidebar section, move it to the 3rd position
          if(this.stickyArticle && article.id === this.stickyArticle?.id && i >= 3) {
            // remove 3rd post from feature articles
            let removedArticle = this.featureArticles.pop();
            // add sticky post
            this.featureArticles.push(this.newsArticleService.mutateArticle(this.stickyArticle));
            // add 3rd post to top of newsArticles
            this.newsArticles.unshift(removedArticle);
          }
          i < 3 ? this.featureArticles.push(this.newsArticleService.mutateArticle(article)) : this.newsArticles.push(this.newsArticleService.mutateArticle(article));
        });

        // Sticky article was not in top 10, put it in 3rd position and bump the last one
        if(!stickyInTopTen && this.stickyArticle) {
          let removedArticle = this.featureArticles.pop();
          // add sticky post
          this.featureArticles.push(this.newsArticleService.mutateArticle(this.stickyArticle));
          // add 3rd post to top of newsArticles
          this.newsArticles.unshift(removedArticle);
        }

        this.articleListLoading = false;
        this.articlesLoaded.emit(false);
      });
    });
  }

  goToArticle(article: NewsArticle, event) {
    event.preventDefault();
    if (this.newsArticleService.isNewsArticleViewFull) {
      this.router.navigate(['/news/article/' + article.cleanLink]);
    } else {
      this.newsArticleService.newsArticleOpen = article;
      this.newsArticleService.setModalOpen(true);
    }
    
  }

}
