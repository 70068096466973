import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mission-banner',
  templateUrl: './mission-banner.component.html'
})
export class MissionBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
