<div *ngFor="let juicerPost of juicerPosts" class="juicer-post" (click)="openJuicerPost(juicerPost)">
  <div class="featured-image">
    <img loading="lazy" [src]="juicerPost.image" [alt]="'Post from ' + juicerPost.poster_display_name">
  </div>
  <div class="juicer-post-content">
    <!--<div class="media">
      <img loading="lazy" [src]="juicerPost.poster_image" class="media-img avatar" [alt]="juicerPost.poster_display_name">
      <div class="media-body">
        <h5>{{juicerPost.poster_display_name}}</h5>
        <h6>@{{juicerPost.poster_name}}</h6>
      </div>
    </div>-->
    <div [innerHTML]="juicerPost.message"></div>
  </div>
</div>

<ng-template #juicerModal>
  <div class="modal-container juicer-modal">
    <div class="modal-header">
      <button class="modal-close" (click)="juicerModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6">
          <img loading="lazy" [src]="openPost.image" [alt]="'Post from ' + openPost.poster_name" class="img-responsive">
        </div>
        <div class="col-md-6">
          <div class="juicer-post-content">
            <a [href]="openPost.full_url" target="_blank" class="juicer-post-header">
              <img *ngIf="openPost.poster_display_name" loading="lazy" [src]="openPost.poster_image" [alt]="'Profile image for ' + openPost.poster_display_name" class="juicer-post-image">
              <h3>{{(openPost.poster_name)?openPost.poster_name: openPost.source.source + ' User'}}</h3>
              <time class="juicer-post-date" [attr.datetime]="openPost.external_created_at">{{openPost.external_created_at | timeSince: 'short'}}</time>
            </a>
            <div [innerHTML]="openPost.message"></div>
            <div class="juicer-replies-likes"><i class="fa fa-reply" aria-hidden="true"></i> {{openPost.comment_count}}&nbsp;&nbsp;<i class="fa fa-heart" aria-hidden="true"></i> {{openPost.like_count}}</div>
            <a class="btn ghost-btn ghost-btn-red" [href]="openPost.full_url" target="_blank">View on {{openPost.source.source}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
