import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'drag-pak-history',
  templateUrl: './drag-pak-history.component.html',
})

export class DragPakHistoryComponent implements OnInit {
  ngOnInit(): void {

  }

}

