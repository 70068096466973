<div class="row">
  <div class="col-sm-12">
    
    <div class="row mb-3">
      <div class="col-sm-12">
        <h2>Initial ECU launch setting</h2>
      </div>
      <div class="col-sm-12 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/ecu-launch-setting/1.webp" alt="Initial ECU Launch Settings - Drag Pak launching" class="img-responsive" width="1440" height="550">
      </div>
      <div class="col-sm-12">
        <p>
          The 2021 Dodge Challenger Mopar<sub>&reg;</sub> Drag Pak uses an integrated Two-Step and Launch Control/Torque Management feature that's contained within the Holley Dominator ECU.  
        </p>
        <div class="well mb-3">
          <p class="mb-0">
            <strong>NOTE:</strong> Track and weather conditions along with the air pressure setting in the rear slicks will determine the optimal settings for both the Two-Step and Launch Control. <a href="https://www.holley.com/products/fuel_systems/fuel_injection/holley_efi_v5/parts/V5" target="_blank">Refer to the Holley Dominator V5 software manual for detailed information on procedures and settings.</a>
          </p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row mb-5">
      <div class="col-sm-12">
        <h3 id="two-step">Two-Step</h3>
      </div>
      <div class="col-sm-12">
        <p>
          The Two-Step works off a signal sent from the brake pedal switch to the Holley Dominator V5 ECU. While the brake pedal is depressed, the switch will activate the Two-Step function. The Two-Step rev limiter can be set via the Holley Dominator V5 ECU to whatever RPM is optimal for weather and track conditions. It is preset to 2,800 RPM. 
        </p>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-sm-12">
        <h3 id="launch-control">Launch Control</h3>
      </div>
      <div class="col-sm-12">
        <p>
          Launch Control also works off a signal from the brake pedal switch that runs to the Holley Dominator V5 ECU. When the driver releases the brake pedal, that signal will start a timer within the ECU and works as a torque management function. All launch control parameters can be adjusted for a spark timing profile that best suits the car’s needs, based on weather and track conditions. <a href="https://www.holley.com/products/fuel_systems/fuel_injection/holley_efi_v5/parts/V5" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="holley-digital-dash-manual.pdf" target="_blank">Refer to the Holley Dominator V5 ECU manual for further information.</a>
        </p>
      </div>
    </div>
    
    <div class="row mb-3">
      <div class="col-sm-12">
        <h3 id="operation-tips">Operation Tips</h3>
      </div>
      <div class="col-sm-12">
        <p>
          Be sure to set switch #8 on your main switch panel to the up position to activate the Two-Step and Launch Control features. Apply adequate brake pressure to prevent the car from pushing through the beams during staging and potentially causing a red light.
        </p>
        <p>To better familiarize yourself with the brake pressure, the analog dash gauge can be used as a reference point when staging. </p>
        <p>Once you’ve lit both the Pre-Stage and Stage lights on the Christmas Tree, push the brake pedal down hard with your left foot, then press the accelerator to the floor with your right foot. </p>
        <p>If you’ve never raced a car with Two-Step and Launch Control, it will take both eye and foot coordination along with practice to cut a good reaction time.</p>
        <div class="well">
          <p class="mb-0">
            <strong>NOTE:</strong> Drag strip preparation varies by track, and both the NHRA and NMCA use different methods in grooming the track surface. Track prep at race time will dictate how aggressive your launch can be without blowing away the slicks. It’s best to start out conservative when making your initial runs and understand how to adjust the Two-Step and Launch Control settings in the Holley Dominator V5 ECU. The Holley Dominator V5 ECU manual includes detailed instructions on how to set both of these functions. 
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mt-5 text-center btn-container">
        <a routerLink="/drag-pak-experience/initial-track-test" class="btn btn-primary btn-footer-nav mr-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Initial Track Test"><i class="fa fa-chevron-left" aria-hidden="true"></i> <span>Initial Track Test</span></a>
        <a routerLink="/drag-pak-experience/post-run-checklist" class="btn btn-primary btn-footer-nav ml-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Post-Run Checklist"><span>Post-Run Checklist</span> <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
      </div>
    </div>

  </div>
</div>
