<div class="banner-bg"></div>
<navigation *ngIf="showNavAndFooter"></navigation>
<main>
  <router-outlet></router-outlet>
</main>
<performance-cars-footer *ngIf="showNavAndFooter"></performance-cars-footer>

<!-- news modal -->
<div class="modal fade" bsModal #newsModal id="news-modal" tabindex="-1" role="dialog"
  (onHidden)="newsModalListener('onHidden', $event)">
  <div class="modal-dialog modal-full-lg">
    <div class="modal-close-container">
      <button class="modal-close" (click)="closeNewsModal()"><i class="fa fa-close"></i><div class="sr-only">close</div></button>
    </div>
    <div class="modal-content">
      <div class="modal-header">
        <!-- <button class="modal-close" (click)="closeNewsModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button> -->
      </div>
      <div class="modal-body site-wrapper" *ngIf="newsArticleService.newsArticleOpen.slug">
        <dg-news-article-single-page [article]="newsArticleService.newsArticleOpen" (articleUpdated)="newsArticleOpenUpdated($event)"></dg-news-article-single-page>
      </div>
    </div>
  </div>
</div>

<!-- event modal -->
<div *ngIf="showNavAndFooter" class="modal fade" bsModal #eventModal="bs-modal" id="event-modal" tabindex="-1" role="dialog"
     (onHidden)="eventModalListener('onHidden', $event)">
  <div class="modal-dialog modal-full-lg">
    <div class="modal-close-container">
      <button class="modal-close" (click)="closeEventModal()"><i class="fa fa-close"></i><div class="sr-only">close</div></button>
    </div>
    <div class="modal-content">
      <div class="modal-body site-wrapper" *ngIf="eventService.eventOpen.id">
        <dg-event-single-page [event]="eventService.eventOpen" (eventUpdated)="eventOpenUpdated($event)"></dg-event-single-page>
      </div>
    </div>
  </div>
</div>


<div class="like-container" *ngIf="showNavAndFooter && newsArticleService.newsArticleOpen.slug">
  <div class="like-count">{{ newsArticleService.newsArticleOpen.likes }}</div>
  <button class="btn btn-like" *ngIf="!newsArticleService.newsArticleOpen.isLikedByUser" [disabled]="newsArticleService.newsArticleOpen.isLikeButtonLoading" [ngClass]="{'is-loading' : newsArticleService.newsArticleOpen.isLikeButtonLoading}" (click)="likeArticleEmitter()">
    <span class="sr-only">Like</span>
    <i aria-hidden="true" class="fa fa-star-o"></i>
  </button>
  <button class="btn btn-like btn-like-is-liked" *ngIf="newsArticleService.newsArticleOpen.isLikedByUser" [disabled]="newsArticleService.newsArticleOpen.isLikeButtonLoading" [ngClass]="{'is-loading' : newsArticleService.newsArticleOpen.isLikeButtonLoading}" (click)="removeLikeArticleEmitter()">
    <span class="sr-only">Like</span>
    <i aria-hidden="true" class="fa fa-star"></i>
  </button>
</div>
