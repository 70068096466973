<section>
  <div class="site-wrapper">
    <div class="container-fluid">
      <div class="row challenger-header">
        <div class="col-sm-12">
          <div class="challenger-heading">
            <h1 class="heading1 red">CHALLENGER 50th</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="challenger-configurator">
  <div class="row">
    <div class="col-md-6">
      <h1>Pretty Picture</h1>
    </div>
    <div class="col-md-6">
      <aside class="welcome-area">
        <h1>welcome person</h1>
      </aside>
      <aside class="type-area">
        <p>Type</p>
        <button class="btn btn-outline-primary" (click) = "selectType($event)">Stock</button>
        <button class="btn btn-outline-primary" (click) = "selectType($event)">Sold</button>
      </aside>
      <aside class="trim-area">
        <p>Trim</p>
        <div id="trim-options">
            <span *ngFor="let trim of trims" class="cat-label">
                <button data-type="trim" data-id="{{trim.id}}" (click) = "selectTrim($event, trim.id)" class="btn btn-outline-primary">{{trim.label}}</button>
            </span>
        </div>
      </aside>
      <aside class="color-area">
        <p>Color</p>
        <div id="color-options">
            <span *ngFor="let color of colors" class="cat-label">
                <button data-type="color" data-id="{{color.id}}" (click) = "selectColor($event, color.id)" class="btn btn-outline-primary">{{color.label}}</button>
            </span>
        </div>
      </aside>
      <aside class="exteriors-area">
        <p>Exterior Options</p>
        <div id="exterior-options">
            <table>
              <tbody>
                <tr *ngFor="let ext of exteriors" class="cat-label">
                  <td><input type="checkbox" name="exteriors[{{ext.id}}]" value="{{ext.id}}" (click) = "selectExterior($event, ext.id)" /></td>
                  <td><span style="text-transform: uppercase">{{ext.code}}</span> - {{ext.label}}</td>
                </tr>
              </tbody>
            </table>
        </div>
      </aside>
      <aside class="customer-area">
        <ng-container *ngIf="showCustomerInfo">
          <p>Customer Info</p>
          <form>

            <label for="first_name">First Name</label>
            <input type="text" id="first_name" name="first_name" placeholder="Your First name..">

            <label for="last_name">Last Name</label>
            <input type="text" id="last_name" name="last_name" placeholder="Your last name..">

            <label for="subject">Subject</label>
            <textarea id="subject" name="subject" placeholder="Write something.." style="height:200px"></textarea>

            <input type="submit" value="Submit">

          </form>
        </ng-container>
      </aside>
    </div>
  </div>
</section>
