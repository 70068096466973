<div class="site-wrapper">
  <div class="container-fluid">
    <div class="row">
      <section class="sidebar-container col-xs-12 col-md-4 col-lg-3">
        <a class="btn quick-nav-dropdown icon-toggle-link" [ngClass]="{'collapsed':collapse != 'open'}" role="button" href="#collapse-nav" aria-controls="collapse-nav" [attr.aria-expanded]="(collapse == 'open')?'true':'false'" (click)="toggleCollapse($event)">
          Quick Navigation
        </a>
        <nav class="sidebar-nav" [@collapseWithScrolling]="collapse" id="collapse-nav">
          <ul id="sidebar-list">
            <li class="list-item">
              <a class="ga-event" data-ga_category="DragPkOwn_LeftNav" data-ga_label="Drag Pak Landing" routerLinkActive="active" routerLink="/drag-pak-experience/" [routerLinkActiveOptions]="{exact: true}">
                2021 Dodge Challenger Mopar<sub>&reg;</sub> Drag Pak
              </a>
            </li>
            <li class="list-item">
              <a class="ga-event" data-ga_category="DragPkOwn_LeftNav" data-ga_label="Drag Pak History" routerLinkActive="active" routerLink="/drag-pak-experience/history" [routerLinkActiveOptions]="{exact: true}">
                Drag Pak History
              </a>
            </li>
            <li class="list-item">
              <a class="ga-event" data-ga_category="DragPkOwn_LeftNav" data-ga_label="Build Overview" routerLinkActive="active" routerLink="/drag-pak-experience/build-overview" [routerLinkActiveOptions]="{exact: true}">
                2021 Drag Pak Build Overview
              </a>
            </li>
            <li class="list-item">
              <a class="ga-event" data-ga_category="DragPkOwn_LeftNav" data-ga_label="Start Up Procedures" routerLinkActive="active" routerLink="/drag-pak-experience/startup-procedures" [routerLinkActiveOptions]="{exact: true}">
                Intro &amp; Start-Up Procedures
              </a>
            </li>
            <li class="list-item">
              <a class="ga-event" data-ga_category="DragPkOwn_LeftNav" data-ga_label="Competition Setup" routerLinkActive="active" routerLink="/drag-pak-experience/competition-setup" [routerLinkActiveOptions]="{exact: true}">
                Competition Setup
              </a>
            </li>
            <li class="list-item">
              <a class="ga-event" data-ga_category="DragPkOwn_LeftNav" data-ga_label="Pre-Run Checklist" routerLinkActive="active" routerLink="/drag-pak-experience/pre-run-checklist" [routerLinkActiveOptions]="{exact: true}">
                Pre-Run Checklist
              </a>
            </li>
            <li class="list-item">
              <a class="ga-event" data-ga_category="DragPkOwn_LeftNav" data-ga_label="Initial Track Test" routerLinkActive="active" routerLink="/drag-pak-experience/initial-track-test" [routerLinkActiveOptions]="{exact: true}">
                Initial Track Test
              </a>
            </li>
            <li class="list-item">
              <a class="ga-event" data-ga_category="DragPkOwn_LeftNav" data-ga_label="Initial ECU Launch Setting" routerLinkActive="active" routerLink="/drag-pak-experience/ecu-launch-setting" [routerLinkActiveOptions]="{exact: true}">
                Initial ECU Launch Setting
              </a>
            </li>
            <li class="list-item">
              <a class="ga-event" data-ga_category="DragPkOwn_LeftNav" data-ga_label="Post-Run Checklist" routerLinkActive="active" routerLink="/drag-pak-experience/post-run-checklist" [routerLinkActiveOptions]="{exact: true}">
                Post-Run Checklist
              </a>
            </li>
          </ul>
          <!--end sidebar-list -->
        </nav>
      </section>
      <section class="col-xs-12 col-md-8 col-lg-9" id="drag-pak-experience" [ngSwitch]="page">
        <drag-pak *ngSwitchCase="'drag-pak'"></drag-pak>
        <drag-pak-history *ngSwitchCase="'history'"></drag-pak-history>
        <build-overview *ngSwitchCase="'build-overview'"></build-overview>
        <startup-procedures *ngSwitchCase="'startup-procedures'"></startup-procedures>
        <competition-setup *ngSwitchCase="'competition-setup'"></competition-setup>
        <pre-run-checklist *ngSwitchCase="'pre-run-checklist'"></pre-run-checklist>
        <initial-track-test *ngSwitchCase="'initial-track-test'"></initial-track-test>
        <ecu-launch-setting *ngSwitchCase="'ecu-launch-setting'"> </ecu-launch-setting>
        <post-run-checklist *ngSwitchCase="'post-run-checklist'"></post-run-checklist>
        <div *ngSwitchDefault>

        </div>
      </section>
      <div *ngIf="!modalOpen" class="floating-button-container">
        <div class="comment-count">{{ this.postCount }}</div>
        <button class="btn btn-comment" type="button" [disabled]="modalOpen ? true : null" (click)="openModal(commentTemplate)"><span class="sr-only">Comment</span><i class="fa fa-comment-o"></i></button>
      </div>
      <div *ngIf="!modalOpen" class="scroll-to-top-container">
        <button class="btn btn-primary btn-scroll-to-top" (click)="scrollToTop()"><i class="fa fa-angle-up" aria-hidden="true"></i><span class="sr-only">Scroll to Top</span></button>
      </div>
    </div>
  </div>
</div>
<div class="display-none"> 
  <dg-comments #comments (postCountUpdatedEmitter)="updatePostCount($event)" [topic]="topic" (topicCreatedEmitter)="topicCreated($event)"></dg-comments>
</div>
<ng-template #commentTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Comments</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div id="comments-modal-body" class="modal-body">
    <dg-comments #comments (postCountUpdatedEmitter)="updatePostCount($event)" [topic]="topic" (topicCreatedEmitter)="topicCreated($event)"></dg-comments>
  </div>
</ng-template>
