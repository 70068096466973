import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HelperService } from './helper.service';
import {UserService} from "../user/user.service";

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
  loggedin: any = false;

  constructor(private userService:UserService, public router:Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (localStorage.getItem('access_token')) {
      return true
    } else {
      this.router.navigate(['/']);
    }
  }

  checkUserName(){
    if(this.userService.userModel.username){
      return true
    }else{
      return false
    }
  }
}
