import { Directive, ElementRef, EventEmitter, Output, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appLazyLoad]'
})
export class LazyLoadDirective implements AfterViewInit {
  @Output() isVisible: EventEmitter<void> = new EventEmitter();

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.canLazyLoad()) {
      this.observeElement();
    }
  }

  private canLazyLoad(): boolean {
    return window && 'IntersectionObserver' in window;
  }

  private observeElement() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.isVisible.emit();
          observer.unobserve(this.el.nativeElement);
        }
      });
    });
    observer.observe(this.el.nativeElement);
  }
}