import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { RoadkillNightsVideoService } from '../../../roadkill-nights-video/roadkill-nights-video.service';
import baguetteBox from 'baguettebox.js';

declare let addthis: any;
declare let ga: Function;

@Component({
  selector: 'dg-contributor-five',
  templateUrl: './contributor-five.component.html',
  styles: []
})
export class ContributorFiveComponent implements OnInit {
  
  loading: boolean = true;
  videos: any;
  activeVideo: any = '';
  modalActive = false;
  comingSoon = false;

  buildImages: any = [];
  galleryIds: any = [];
  allImages: boolean = false;

  @ViewChild(ModalDirective, { static: true }) public videoModal: ModalDirective;
  videoModalRef: BsModalRef;

  constructor(
    public title: Title,
    public metaService: Meta,
    private router: Router,
    public http: HttpClient,
    public sanitizer: DomSanitizer,
    public RoadkillNightsVideoService: RoadkillNightsVideoService,
  ) {
    title.setTitle( 'Westen Champlin – Hellcat Grudge Race | Dodge Garage' );
    metaService.updateTag({ name: 'description', content: 'Westen Champlin is a self-described “Redneck Scientist” auto guru from Kansas who has built some ridiculous cars, and we hope his SRT® Hellcat build will be one too.' })
    metaService.updateTag({ property: 'og:description', content: 'Westen Champlin is a self-described “Redneck Scientist” auto guru from Kansas who has built some ridiculous cars, and we hope his SRT® Hellcat build will be one too.' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Westen Champlin – Hellcat Grudge Race | Dodge Garage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: environment.url + '/assets/images/rkn/lil-demons/westen-video.jpg' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: environment.url + '/assets/images/rkn/lil-demons/westen-video.jpg' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
    
    this.getPlaylist('PLxps8cLCwSU4UNhhuSerSHI_G2qWJ6RpB');
    this.getGalleryImages(6, 0, 4);

    // Add AddThis to Page
    const script = document.createElement('script');
    script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
    document.body.appendChild(script);

    // Reload AddThis
    setTimeout(() => {
      addthis.layers.refresh();
    }, 1500);
    
  }

  // Grab lil demon's playlist data from YT API and store on page
  getPlaylist(playlistID: string) {
    
    if (playlistID.length > 0 ) {
      this.RoadkillNightsVideoService.getVideosForPlaylist(playlistID).subscribe(
        res => {
          this.videos = res['items'];
          this.loading = false;
        },
        err => {
          console.log('error', err);
          this.videos = [];
          this.loading = false;
        }
      )
    } else {
      this.videos = [];
      this.loading = false;
    }
    
  }

  // Get list of image ids based on folder from WP
  getGalleryImages(folder: number, start: number = 0, total: number = 5) {
    this.RoadkillNightsVideoService.getBuildGalleryImages(folder).subscribe(
      res => {

        // Reverse IDs when setting to ensure latest is on top
        this.galleryIds = res['data']['attachment_ids'].reverse();
        let count = start;

        // Loop through ids from primary request and get the actual image URL
        this.galleryIds.slice(start, total).forEach(image => {
          this.RoadkillNightsVideoService.getBuildGalleryImage(image).subscribe(
            (res) => {
              this.buildImages.push(res);
              count++
            }
          )
        })

        setTimeout(() => {
          baguetteBox.run('.image-container');
        }, 2000);

      }
    );
  }

  showAllImages() {
    this.getGalleryImages(6, 4, this.galleryIds.length);
    this.allImages = true;

    ga('send', 'event', 'RKN Westen Champlin Build Gallery', 'Click', 'View More');
  }

  openVideoModal(video) { 
    this.activeVideo = video;
    //this.helperService.trackGAEvent(this.previousURL, 'Click', 'Video Modal - ' + this.selectedVideo.name);
    this.modalActive = true;
    this.videoModal.show();
  }

  closeVideoModal() {
    this.videoModal.hide();
    this.modalActive = false;
  }

  clickTrackerYoutube() {ga('send', 'event', 'RKN Westen Youtube', 'Click', 'Social clicked');}
  clickTrackerInsta() { ga('send', 'event', 'RKN Westen Instagram', 'Click', 'Social clicked');}

}
