import { Component, HostListener, OnInit, Inject, PLATFORM_ID, ViewChild, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { GalleryService } from './gallery.service';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from "@angular/common";
import { GalleryModel } from './gallery';
import { environment } from "../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { NewsArticleService } from '../news-articles/news-article.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import baguetteBox from 'baguettebox.js';

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  providers: []
})

export class GalleryComponent implements OnInit {

  galleryItems: GalleryModel[] = [];
  galleryListPage: number = 1; // What page to call for the WordPress API
  galleryListLoading: boolean = true; // Loading status
  galleryListItemsRemaining: boolean = true; // If items are remaining
  galleryQuota: number = 16;
  galleryItemsShown: GalleryModel[] = [];

  gallerySearch: string = '';

  // JSON+LD schema
  schema: object = {};

  constructor(
    public galleryService: GalleryService,
    public metaService: Meta,
    public newsArticleService: NewsArticleService,
    public route: ActivatedRoute,
    private router: Router,
    public title: Title,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    this.route.params.subscribe( params => {
      this.route
        .queryParams
        .subscribe(params => {
          this.galleryItems = [];
          this.galleryListPage = 1;
          this.gallerySearch = params['s'];
          this.fetchGallery();
        });
    });

    title.setTitle( 'Gallery | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'Your eyes on speed' })
    metaService.updateTag({ property: 'og:description', content: 'Your eyes on speed' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | Gallery' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
    this.fetchGallery();
  }

  fetchGallery(page: number = 1) {
    this.galleryListLoading = true;
    this.galleryService.getGallery(page, '', '', this.gallerySearch).subscribe (galleryItems => {
      let imageSchemaArray = [];
      galleryItems.forEach ( galleryItem => {
        //console.log(galleryItem['media_details']['sizes']);
        this.newsArticleService.getArticleByWordPressId(galleryItem.post).subscribe ( articleData => {
          if (galleryItem['media_details']['sizes']) {
            galleryItem.newsArticle = this.newsArticleService.mutateArticle(articleData);
            if (!this.galleryItems.some(thisGalleryItem => thisGalleryItem.id === galleryItem.id))
              this.galleryItems.push(galleryItem);
          }
        });
        imageSchemaArray.push({
          "@type": "ImageObject",
          "thumbnail": galleryItem['media_details']['sizes']['thumbnail']['source_url'],
          "name" : galleryItem['title']['rendered'],
          "url": galleryItem['media_details']['sizes']['full']['source_url'],
        });
      });
      // Sometimes the first few media fetches are blank because they're tied up in pending articles, so loop through a couple
      // After 20 passes, give up
      // If we're not yet to the quota, get more
      if (galleryItems.length == 0 && this.galleryListPage <= 20) {
        this.galleryListPage++;
        this.fetchGallery(this.galleryListPage);
      }
      if (this.galleryItems.length < this.galleryQuota) {
        this.galleryListPage++;
        this.fetchGallery(this.galleryListPage);
      }
      // If we stop receiving gallery items and we're over a lot of pages, then stop requesting
      if (this.galleryItems.length > 0 && galleryItems.length == 0 && this.galleryListPage >= 20) {
        this.galleryListItemsRemaining = false;
      }

      if (isPlatformBrowser(this.platformId)) {
        // Setup Gallery
        setTimeout(() => {
          let gallery = baguetteBox.run('.gallery-container', {
            captions: function(element) {
              return `<a class="read-more-link" href=${element.getAttribute('data-article-link')}>Read News Story<i class="fa fa-chevron-right" aria-hidden="true"></i></a>`;
            },
            async: true,
            buttons: false,
            noScrollbars: true
          });
        }, 2000);
        // Add Listener
        document.body.addEventListener( 'click', ( event ) => {
          if ( (event.target as Element).className == 'read-more-link' ) {
            event.preventDefault();
            let element = (event.target as Element);
            this.openArticle(element);
          }
        });
      }
      this.galleryItemsShown = this.galleryItems.slice(0, this.galleryQuota);
      this.galleryListLoading = false;
      this.schema = {
        "@context": "http://schema.org",
        "@type": "ImageGallery",
        "@id" : environment.url + this.router.url,
        "url" : environment.url + this.router.url,
        "name": "Dodge Garage | Gallery",
        "description": "Your eyes on speed",
        "hasPart": imageSchemaArray,
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Gallery",
              "item": environment.url + "/gallery"
            },
          ]
        }
      }
    });
  }

  openArticle(element: Element) {
    // Get link from element
    let href = element.getAttribute('href');
    href = href.replace('/news/article/','');
    // Find this link in the array of images
    let galleryItem = this.galleryItems.find( item => {
      return item['newsArticle']['cleanLink'] == href
    });
    let newsArticle = galleryItem['newsArticle'];
    // Also make sure to close the lightbox
    baguetteBox.hide();
    this.newsArticleService.newsArticleOpen = newsArticle;
    this.newsArticleService.setModalOpen(true);
  }

  searchGallery(event) {
    this.router.navigate(['/gallery'], { queryParams: { s: event } });
  }

  onShowMore() {
    if (!this.galleryListLoading && this.galleryListItemsRemaining) { // Check if already loading so we don't hit the API multiple times. Stop checking once we have all gallery items
      this.galleryListPage++;
      this.galleryQuota += 16;
      this.fetchGallery(this.galleryListPage);
    }
  }

}
