import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddTimeSlip } from './time-slip/add-time-slip/add-time-slip.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AddVehicleComponent } from '../vehicle/add-vehicle/add-vehicle.component';



@NgModule({
  declarations: [
    AddTimeSlip,
    AddVehicleComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    SharedModule
  ],
  exports: [
    AddTimeSlip,
    AddVehicleComponent
  ]
})
export class ThirteenTwentySharedModule { }
