<form [formGroup]="pollForm" (submit)="submit()">
  <div class="poll-question" [innerHTML]="poll.question">{{poll.question}}</div>
  <div class="poll-form form-group" [ngClass]="{ 'has-error': pollForm.controls.answer.invalid && (pollForm.controls.answer.dirty || pollForm.controls.answer.touched) }">
    <ng-container *ngIf="!poll.voted">
      <div class="btn btn-radio radio" *ngFor="let answer of poll.answers; index as i" [class.active]="pollForm.controls.answer.value == answer.id">
        <label>
          <input type="radio" name="answer" [value]="answer.id" formControlName="answer">
          <span class="letter">{{(i+10).toString(36)}}</span> <span [innerHTML]="answer.name"></span>
        </label>
      </div>
      <div *ngIf="pollForm.controls.answer.invalid && (pollForm.controls.answer.dirty || pollForm.controls.answer.touched)">
        <div *ngIf="pollForm.controls.answer.errors.required" class="help-block">Please make a choice before submitting.</div>
      </div>
    </ng-container>
    <ng-container *ngIf="poll.voted">
      <div class="poll-result" *ngFor="let answer of poll.answers; index as i" [class.active]="pollForm.controls.answer.value == answer.id" [class.highest]="answer.isHighestVotes">
        <div class="poll-result-background" [ngStyle]="{'width.%': (answer.votes.length / poll.totalVotes) * 100}">&nbsp;</div>
        <div class="poll-result-foreground">
          <div class="poll-result-foreground-container">
            <span class="percentage">{{(answer.votes.length / poll.totalVotes) * 100 | number:'0.0-0'}}<sup>%</sup></span> <span [innerHTML]="answer.name" class="name"></span>
          </div>
        </div>
      </div>
    </ng-container>

    <button type="submit" class="btn btn-primary" [disabled]="isLoading" *ngIf="!poll.voted">
      Submit
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
    </button>
    <div *ngIf="poll.voted" class="total-votes-container">
      <div class="total-votes">{{poll.totalVotes}} votes</div>
      <!--<div class="flyout" [ngClass]="{'is-open': isSharingIconActive}">
        <button class="btn" type="button" (click)="isSharingIconActive = !isSharingIconActive"><i class="fa fa-share-alt" aria-hidden="true"></i> Share</button>
        <div class="flyout-menu">
          <share-buttons [url]="sharingURL" [title]="poll.question" [description]="poll.question"></share-buttons>
        </div>
      </div>-->
    </div>
  </div>
</form>
