import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {environment} from "../../environments/environment";
import { HelperService } from '../services/helper.service';
import { Video } from './video';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(
    public helperService: HelperService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  getVimeoAlbums(): Observable<any> {
    const headers = new HttpHeaders().set("Authorization", "Bearer " + environment.vimeoAPiKey);
    const url = 'https://api.vimeo.com/users/94741343/albums/';
    return this.http.get<any>(url,{headers}).pipe(catchError(this.helperService.handleError));
  }

  getVimeoVideosByAlbum(albumUrl): Observable<any> {
    const headers = new HttpHeaders().set("Authorization", "Bearer " + environment.vimeoAPiKey);
    const url = 'https://api.vimeo.com/' + albumUrl + '/videos';
    return this.http.get<any>(url,{headers}).pipe(catchError(this.helperService.handleError));
  }

  getVimeoVideo(videoId: number): Observable<any> {
    const headers = new HttpHeaders().set("Authorization", "Bearer " + environment.vimeoAPiKey);
    const url = 'https://api.vimeo.com/videos/' + videoId;
    return this.http.get<any>(url,{headers}).pipe(catchError(this.helperService.handleError));
  }

  mutateVimeoVideos(videoData) {
    let video = new Video();
    let uriExploded = videoData['uri'].split('/');
    if (uriExploded.length > 1)
      video.id = parseInt(uriExploded[2]);
    video.name = videoData['name'];
    video.description = videoData['description'];
    video.uri = videoData['uri'];
    video.createdTime = videoData['created_time'];
    if (videoData.pictures.sizes[3]) {
      video.thumbnail = videoData.pictures.sizes[3].link;
      video.thumbnailWithPlayButton = videoData.pictures.sizes[3].link_with_play_button;
    }
    if (videoData.embed) {
      video.embedCode = this.sanitizer.bypassSecurityTrustHtml(videoData.embed.html);
    }
    return video;
  }
}
