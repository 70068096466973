<div class="site-wrapper">
  <div class="container-fluid">
    <p *ngIf="userService.userModel.isPublic">
      <a [routerLink]="'/user/' + userService.userModel.username">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        Back to My Garage
      </a>
    </p>

    <div class="row garage-user-header">
      <div class="header-user">
        <h1 class="heading1"><small>Account Details<br></small>{{userService.userModel.firstName}} {{userService.userModel.lastName}} ({{userService.userModel.username}})</h1>
      </div>
      <div></div>
    </div>

    <div *ngIf="userService.userModel.isLoggedIn && !userService.userModel.isActivated">
      <dg-activation-notice></dg-activation-notice>
    </div>

    <div class="row" *ngIf="userService.userModel.isActivated">
      <div class="col-md-7">
        <form (submit)="updateUserDetails()" [formGroup]="userDetailsForm">
          <h2>Personal Details</h2>
          <dg-alert-banner [alert]="userDetailsFormAlert"></dg-alert-banner>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group" [ngClass]="{ 'has-error': userDetailsForm.controls.email.invalid && (userDetailsForm.controls.email.dirty || userDetailsForm.controls.email.touched) }">
                <label class="control-label">Email</label>
                <input type="text" name="email" id="manage-email" class="form-control" formControlName="email" required>
                <div *ngIf="userDetailsForm.controls.email.invalid && (userDetailsForm.controls.email.dirty || userDetailsForm.controls.email.touched)">
                  <div *ngIf="userDetailsForm.controls.email.errors.required" class="help-block">Please enter your email address.</div>
                  <div *ngIf="!userDetailsForm.controls.email.errors.required && userDetailsForm.controls.email.errors.email" class="help-block">Please enter a valid email address.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group" [ngClass]="{ 'has-error': userDetailsForm.controls.firstName.invalid && (userDetailsForm.controls.firstName.dirty || userDetailsForm.controls.firstName.touched) }">
                <label class="control-label">First Name</label>
                <input type="text" name="firstName" id="manage-first-name" class="form-control" formControlName="firstName" required>
                <div *ngIf="userDetailsForm.controls.firstName.invalid && (userDetailsForm.controls.firstName.dirty || userDetailsForm.controls.firstName.touched)">
                  <div *ngIf="userDetailsForm.controls.firstName.errors.required" class="help-block">Please enter your first name.</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" [ngClass]="{ 'has-error': userDetailsForm.controls.lastName.invalid && (userDetailsForm.controls.lastName.dirty || userDetailsForm.controls.lastName.touched) }">
                <label class="control-label">Last Name</label>
                <input type="text" name="lastName" id="manage-last-name" class="form-control" formControlName="lastName" required>
                <div *ngIf="userDetailsForm.controls.lastName.invalid && (userDetailsForm.controls.lastName.dirty || userDetailsForm.controls.lastName.touched)">
                  <div *ngIf="userDetailsForm.controls.lastName.errors.required" class="help-block">Please enter your first name.</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" [ngClass]="{ 'has-error': userDetailsForm.controls.country.invalid && (userDetailsForm.controls.country.dirty || userDetailsForm.controls.country.touched) }">
                <label for="register-country" class="control-label">Country</label>
                <select class="form-control" id="register-country" name="country" formControlName="country">
                  <option value="">Country</option>
                  <option value="US">United States</option>
                  <option value="" disabled class="">-----------</option>
                  <option *ngFor="let country of countries; index as i" [value]="country.value">{{ country.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6" *ngIf="userDetailsForm.controls.country.value === 'US'">
              <div class="form-group" [ngClass]="{ 'has-error': userDetailsForm.controls.zipCode.invalid && (userDetailsForm.controls.zipCode.dirty || userDetailsForm.controls.zipCode.touched) }">
                <label for="register-zipCode" class="control-label">ZIP Code</label>
                <input class="form-control"  id="register-zipCode" type="text" name="zipCode" minlength="5" maxlength="5" formControlName="zipCode" (focus)="userService.trackGAevent('SignUpForm','zipCode')">
                <div *ngIf="userDetailsForm.controls.zipCode.invalid && (userDetailsForm.controls.zipCode.dirty || userDetailsForm.controls.zipCode.touched)">
                  <div *ngIf="userDetailsForm.controls.zipCode.errors.required || userDetailsForm.controls.zipCode.errors.zipCode" class="help-block">Please enter a valid ZIP Code.</div>
                  <div *ngIf="!userDetailsForm.controls.zipCode.errors.required && !userDetailsForm.controls.zipCode.errors.zipCode" class="help-block">This ZIP Code is invalid.</div>
                </div>
              </div>
            </div>
          </div>
          <h2>Notification Settings</h2>
          <div class="form-group">
            <div class="dg-checkbox checkbox">
              <label class="control-label">
                <input type="checkbox" name="fca-communication" id="manage-fca-communication" formControlName="fcaCommunication">
                <span class="checkbox-icon"></span>
                <span class="label-text">Receive Dodge Garage bi-weekly email newsletter</span>
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="dg-checkbox checkbox">
              <label class="control-label">
                <input type="checkbox" name="event-communication" id="manage-event-communication" formControlName="eventCommunication">
                <span class="checkbox-icon"></span>
                <span class="label-text">Receive event and ticket offer emails from DodgeGarage</span>
              </label>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="isDetailsUpdateLoading" [ngClass]="{'is-loading' : isDetailsUpdateLoading}" type="submit" class="btn btn-primary">
              Update Account Info
              <i class="fa fa-circle-o-notch fa-spin" *ngIf="isDetailsUpdateLoading"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-5">
        <form (submit)="updateChangePassword()" [formGroup]="changePasswordForm">
          <h2>Change Password</h2>
          <dg-alert-banner [alert]="changePasswordFormAlert"></dg-alert-banner>
          <div class="form-group" [ngClass]="{ 'has-error': changePasswordForm.controls.password.invalid && (changePasswordForm.controls.password.dirty || changePasswordForm.controls.password.touched) }">
            <label class="control-label">Current Password</label>
            <input type="password" name="password" id="change-current-password" class="form-control" formControlName="password" required>
            <div *ngIf="changePasswordForm.controls.password.invalid && (changePasswordForm.controls.password.dirty || changePasswordForm.controls.password.touched)">
              <div *ngIf="changePasswordForm.controls.password.errors.required" class="help-block">Please enter your current password.</div>
            </div>
          </div>
          <div class="form-group" [ngClass]="{ 'has-error': changePasswordForm.controls.newPassword.invalid && (changePasswordForm.controls.newPassword.dirty || changePasswordForm.controls.newPassword.touched) }">
            <label class="control-label">New Password</label>
            <input type="password" name="newPassword" id="change-new-password" class="form-control" formControlName="newPassword" required>
            <div *ngIf="changePasswordForm.controls.newPassword.invalid && (changePasswordForm.controls.newPassword.dirty || changePasswordForm.controls.newPassword.touched)">
              <div *ngIf="changePasswordForm.controls.newPassword.errors.required" class="help-block">Please enter a new password.</div>
            </div>
          </div>
          <div class="form-group" [ngClass]="{ 'has-error': changePasswordForm.controls.confirmNewPassword.invalid && (changePasswordForm.controls.confirmNewPassword.dirty || changePasswordForm.controls.confirmNewPassword.touched) }">
            <label class="control-label">Confirm New Password</label>
            <input type="password" name="confirmNewPassword" id="change-confirm-new-password" class="form-control" formControlName="confirmNewPassword" required>
            <div *ngIf="changePasswordForm.controls.confirmNewPassword.invalid && (changePasswordForm.controls.confirmNewPassword.dirty || changePasswordForm.controls.newPassword.touched)">
              <div *ngIf="changePasswordForm.controls.confirmNewPassword.errors.required" class="help-block">Please confirm the new password.</div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="isChangePasswordLoading" [ngClass]="{'is-loading' : isChangePasswordLoading}" type="submit" class="btn btn-primary">
              Change Password
              <i class="fa fa-circle-o-notch fa-spin" *ngIf="isChangePasswordLoading"></i>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-md-7">
        <h2>Deactivate Account</h2>
        <p><button type="submit" class="btn btn-primary" (click)="openDeactivateAccountModal(confirmDeactivateModal)">Deactivate Account</button></p>
      </div>
    </div>


  </div>
</div>

<!-- Deactivate Modal -->
<ng-template #confirmDeactivateModal>
  <div class="modal-container">
    <div class="modal-header">
      <h2 class="modal-title heading1 pull-left">Deactivate Account</h2>
      <button class="modal-close" (click)="confirmDeactivateModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <dg-deactivate-account (accountDeactivatedEmitter)="confirmDeactivateModalRef.hide()" (cancelDeactivationEmitter)="confirmDeactivateModalRef.hide()"></dg-deactivate-account>
    </div>
  </div>
</ng-template>

