<div id="twenty-days">
  <div class="hero landing-hero">
    <div class="site-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="hero-inner col-sm-12">
            <div class="hero-heading">
              <img src="/assets/images/twenty-days/dodge-logo.webp" width="292" height="158" alt="Dodge Rhombus logo">
              <h1>Dodge Days <br class="d-lg-block"> of Horsepower</h1>
            </div>
            <div class="hero-countdown">
              <countdown-clock 
                *ngIf="showCountdown"
                class="slim"
                [targetDate]="targetDate" 
                (cueSnow)="startSnowfall()" 
                (winnersBeingSelected)="hideWinners()"
                (requestFreshData)="prefetchData()"
                (timerExpired)="displayWinners()"></countdown-clock>
              
              <p *ngIf="!eventEnded" class="subheading text-uppercase font-bold text-center mb-0">Until Next Winners Are Drawn</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="site-wrapper">
    <div class="container-fluid">
      <div class="row row-flex section-lead mt-5 mb-5">
        <div class="col-sm-12">
          <p>
            The 2024 Dodge Durango SRT<sup>&reg;</sup> 392 AlcHEMI Special Edition is the first of a series of “Last Call” models that will commemorate the final year of V-8 HEMI<sup>&reg;</sup> engine production for the Dodge Durango. Limited to a run of up to 1,000 pre-spec units, with a planned 250 each of the four exterior colors: Diamond Black, Destroyer Gray, Vapor Gray and White Knuckle, this is your chance to get one of them ... or more. 
          </p>
          <p>
            For April sales (4/2/24 – 4/30/24), each retail unit sold of a qualifying Dodge Durango R/T or Hornet will earn your store an entry into the following day's drawings for a chance to win. New day, new sales, new entries – no carryovers to the next drawings. Make each day count! There's no cap on winning, so sell and report your cars daily for the maximum chance of winning.  
          </p>
          <p>
            During each of the drawing days, Dodge will randomly draw 12 winners (6 from Durango R/T sales and 6 from Hornet sales) who will receive an allocation for a pre-spec Durango SRT 392 AlcHEMI Special Edition. Winners will be announced right here at 11:00am ET on drawing dates so be sure to check back!
          </p>
          <p>
            <strong>See the calendar below for the April drawing dates for which color and full program guidelines.</strong>
          </p>
        </div>
      </div> 
    </div>
  </div>

  <div class="metallic-bg winners">
    <div class="site-wrapper">
      <div class="container-fluid">
        <div class="row spacer" id="stock-leaderboard">
          <div class="col-lg-6">
            <dg-20-days-of-horsepower-winners 
              *ngIf="dataLoaded"
              [dealers]="winnersDurangoFormatted" 
              [vehicle]="'Durango R/T'" 
              [eventVehicle]="eventVehicle" 
              [id]="'winners-challenger'" 
              [start]="eventStartDate" 
              [hideWinner]="hideWinner" 
              [disabledDates]="disabledDates"
              [comingSoon]="comingSoon"></dg-20-days-of-horsepower-winners>
          </div>

          <div class="col-lg-6">
            <dg-20-days-of-horsepower-winners 
              *ngIf="dataLoaded"
              [dealers]="winnersHornetFormatted" 
              [vehicle]="'Hornet'" 
              [eventVehicle]="eventVehicle" 
              [id]="'winners-hornet'" 
              [start]="eventStartDate" 
              [hideWinner]="hideWinner" 
              [disabledDates]="disabledDatesHornet"
              [comingSoon]="comingSoon"></dg-20-days-of-horsepower-winners>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="metallic-bg grunge">
    <div class="site-wrapper">
      <div class="container-fluid">
        <div class="row spacer" id="stock-leaderboard">
          <div id="stock-leaderboard" class="col-lg-6">
            <dg-20-days-of-horsepower-leaderboard 
              *ngIf="dataLoaded"
              [dealers]="salesDurangoFormatted" 
              [vehicle]="'Durango R/T'" 
              [id]="'leaderboard-durango'" 
              [disabledDates]="disabledDates"
              [start]="eventStartDate"></dg-20-days-of-horsepower-leaderboard>
          </div>

          <div id="modified-leaderboard" class="col-lg-6">
            <dg-20-days-of-horsepower-leaderboard 
              *ngIf="dataLoaded"
              [dealers]="salesHornetFormatted" 
              [vehicle]="'Hornet'" 
              [id]="'leaderboard-hornet'" 
              [disabledDates]="disabledDatesHornet"
              [start]="eventStartDate"></dg-20-days-of-horsepower-leaderboard>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="legal-fun" class="site-wrapper mt-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 text-center">
          <img class="calendar-img mb-5" src="/assets/images/twenty-days/reporting-calendar-apr.webp" alt="calendar outlining the reporting schedule for the campaign">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <p class="font-bold footer-text text-center mb-5">
            <button class="btn btn-primary" (click)="openRulesModal($event)">View full program rules</button>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="snowfall" class="snowfall"> 
  <img class="rhombus" src="/assets/images/twenty-days/dodge-rhombus.svg">
  <img class="rhombus" src="/assets/images/twenty-days/dodge-rhombus.svg">
  <div *ngIf="winners" class="winners-container">
    <div class="heading">
      <p id="winners-heading" class="subheading">Today's Random Winners are...</p>
    </div>
    <div class="winner">
      <span class="vehicle">Durango R/T Dealer // <br> Durango SRT 392 AlcHEMI Special Edition Allocation</span>
      <div class="table">
        <div class="tr" *ngFor="let winner of winnersDurangoFormatted">
          <div class="td">{{ winner.bc }}</div>
          <div class="td">{{ winner.dealer_code }}</div>
          <div class="td">{{ winner.dealer_name }}</div>
        </div>
      </div>
    </div>
    <div class="winner" *ngIf="showHornet">
      <span class="vehicle">Hornet Dealer // <br> Durango SRT 392 AlcHEMI Special Edition Allocation</span>
      <div class="table">
        <div class="tr" *ngFor="let winner of winnersHornetFormatted">
          <div class="td">{{ winner.bc }}</div>
          <div class="td">{{ winner.dealer_code }}</div>
          <div class="td">{{ winner.dealer_name }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #rulesModal>
  <div class="modal-container modal-container-lg">
    <div class="modal-header">
      <h2 class="modal-title heading1 pull-left">DODGE DAYS OF HORSEPOWER</h2>
      <button class="modal-close" (click)="rulesModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <div>
        <p class="subheading font-bold">HORNET PROGRAM:</p>
        <p class="subhead"><strong>Eligibility:</strong></p>
        <p>As of March 1, 2024, all US, Puerto Rico and Guam Dodge Dealers are eligible. </p>
        <p class="subhead"><strong>Program Period:</strong></p>
        <p>For sales reported on April 2 – April 30, 2024.</p>
        <p class="subhead"><strong>Qualification:</strong></p>
        <p>Every new retail sale (only Type 1, L, B & E sales qualify) reported of a Hornet (not previously reported) gets a dealer one entry into the next business day’s daily drawing. Sales reported sold to a dealership do not count in this program.</p>
        <p>To qualify a sale must be properly reported & accepted into the NVDR system during the program period, program is based on vehicle reporting date.</p>
        <p>Only sales the prior business day/days are eligible for daily drawing (weekend/holiday sales will be grouped together with sales the next business day, all other daily drawings are solely based on previous business day's sales, see the calendar for specific handling of weekend/holiday sales).</p>
        <p>Multiple sales reported will provide multiple entries (1 for 1) into randomized drawing the following business day.</p>
        <p>The program resets to 0 every new business day (all previous sales that were counted in a previous drawing are removed; sales can only count for one drawing).</p>
        <p>Drawing dates will determine which dealers receive the allocation for these Durango SRT 392 AlcHEMI Special Edition models (see calendar for specific drawing dates).</p>

        <p class="subhead"><strong>Qualifying models:</strong></p>
        <p>Previously unreported Dodge Hornet models qualify (all models/trims and model years).</p>

        <p class="subhead"><strong>Award:</strong></p>
        <p>Program award is one pre-spec allocation of a Durango SRT 392 AlcHEMI Special Edition. By dealer accepting this allocation, dealer is responsible for paying FCA US LLC all applicable charges associated with vehicle (like all other orders).  6 awards will be given out on each drawing day.</p>
        <p>Every winner will have until COB (11:59pm Eastern) two following business days after the vehicle is reassigned in VOIM to accept the allocation.</p>
        <p>If the winning dealer refuses, does not respond (by COB two business days after the drawing) or cannot take the allocation for any reason, another drawing will be held until a dealer is found that can/will accept the Durango SRT 392 AlcHEMI Special Edition allocation.</p>
        <p>The units are pre-spec and there will not be opportunity to change them (color/equipment etc.), dealer must accept the unit as originally ordered or decline the allocation.</p>
        <p>Results for each day (with dealer entries and the winner) will be announced on <a href="https://www.dodgegarage.com/dodge-days-of-horsepower">https://www.dodgegarage.com/dodge-days-of-horsepower</a> by 11am Eastern Standard Time the following business day.</p>
        <p>Since these Durango SRT 392 AlcHEMI Special Edition units are the last units built before all-time shutdown of this model, if the awarded vehicle is accidentally damaged and/or scrapped, there is a potential that no replacement will be available.,</p>
        <p>There is no cap on the number of times a dealer can win.</p>

        <p class="subhead"><strong>Other:</strong></p>
        <p>Stellantis reserves the right to audit sales and/or entries to this Program. If any dealer is shown to be reporting improperly, that dealer will be removed and not qualify for the Program.</p>
      </div>
      <hr>
      <div>
        <p class="subheading font-bold">DURANGO R/T PROGRAM:</p>
        <p class="subhead"><strong>Eligibility:</strong></p>
        <p>As of March 1, 2024, all US, Puerto Rico and Guam Dodge Dealers are eligible. </p>
        <p class="subhead"><strong>Program Period:</strong></p>
        <p>For sales reported on April 2 – April 30, 2024.</p>
        <p class="subhead"><strong>Qualification:</strong></p>
        <p>Every new retail sale (only Type 1, L, B & E qualify) reported of a Durango R/T 23/24MY model (not previously reported) gets a dealer one entry into the next business day’s daily drawing. Sales reported sold to a dealership do not count in this program.</p> 
        <p>To qualify a sale must be properly reported & accepted into the NVDR system during the program period, program is based on vehicle reporting date.</p>
        <p>Only sales the prior business day/days are eligible for daily drawing (weekend/holiday sales will be grouped together with sales the next business day, all other daily drawings are solely based on previous business day's sales, see the calendar for specific handling of weekend/holiday sales).</p>
        <p>Multiple sales reported will provide multiple entries (1 for 1) into randomized drawing the following business day.</p>
        <p>The program resets to 0 every new business day (all previous sales that were counted in a previous drawing are removed; sales can only count for one drawing).</p>
        <p>Drawing dates will determine which dealers receive the allocation for these Durango SRT AlcHEMI Special Edition models (see calendar for specific drawing dates).</p>

        <p class="subhead"><strong>Qualifying models:</strong></p>
        <p>Previously unreported Dodge Durango R/T Models (23/24MY and both RWD and AWD).</p>

        <p class="subhead"><strong>Award:</strong></p>
        <p>Program award is one pre-spec allocation of a Durango SRT 392 AlcHEMI Special Edition. By dealer accepting this allocation, dealer is responsible for paying FCA US LLC all applicable charges associated with vehicle (like all other orders).  6 awards will be given out on each drawing day.</p>
        <p>Every winner will have until COB (11:59pm Eastern) two following business days after the vehicle is reassigned in VOIM to accept the allocation.</p>
        <p>If the winning dealer refuses, does not respond (by COB two business days after the drawing) or cannot take the allocation for any reason, another drawing will be held until a dealer is found that can/will accept the Durango SRT 392 AlcHEMI Special Edition allocation.</p>
        <p>The units are pre-spec and there will not be opportunity to change them (color/equipment etc.), dealer must accept the unit as originally ordered or decline the allocation.</p>
        <p>Results for each day (with dealer entries and the winner) will be announced on <a href="https://www.dodgegarage.com/dodge-days-of-horsepower">https://www.dodgegarage.com/dodge-days-of-horsepower</a> by 11am Eastern Standard Time the following business day.</p>
        <p>Since these Durango SRT 392 AlcHEMI Special Edition units are the last units built before all-time shutdown of this model, if the awarded vehicle is accidentally damaged and/or scrapped, there is a potential that no replacement will be available.</p>
        <p>There is no cap on the number of times a dealer can win.</p>

        <p class="subhead"><strong>Other:</strong></p>
        <p>Stellantis reserves the right to audit sales and/or entries to this Program. If any dealer is shown to be reporting improperly, that dealer will be removed and not qualify for the Program.</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #messageModal>
  <div class="modal-container modal-container-lg">
    <div class="modal-header">
      <h2 class="modal-title heading1 pull-left">Attention all Dodge Dealers:</h2>
      <button class="modal-close" (click)="messageModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <div>
        <p>We are disappointed to announce that the 20 Days of Horsepower Demon 170 giveaway program must be cut short by one day. Tuesday, January 2nd was the final drawing.</p>

        <p>We have worked very hard together over the last decade to build this performance brand out of respect for and on the backs of our loyal customers, and our strength is only as strong as the respect shown to them. Unfortunately, we have had a couple situations where "vehicles were delivered outside the terms of their original purchase agreement" resulting in extreme customer dissatisfaction and legitimate sold order customers without vehicles.</p>

        <p>These actions by a few have had drastic consequences on social media causing reputational damage and public embarrassment for the Brand and for the dealer network as a whole. World-class customer service is the goal we all are equally responsible for, and we will take action to try and resolve these situations on behalf of our loyal, shared customers.</p>

        <p>Respectfully,<br>
        Dodge&nbsp;<span class="font-bold text-primary">//</span>&nbsp;SRT</p>
      </div>
    </div>
  </div>
</ng-template>
