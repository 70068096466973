
import {interval as observableInterval, Observable, Subscription} from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {UserService} from "../../user/user.service";
import {Contest} from "../contest";
import {ContestService} from "../contest.service";
import {AddThisService} from '../../services/addthis.service';


declare let addthis: any;
declare let hideShowModal: Function;

@Component({
  selector: 'performance-cars-barret-jackson',
  templateUrl: './barrett-jackson.component.html'
})
export class BarrettJacksonComponent implements OnInit {

  pageTitle: string = 'Dodge Garage | Barrett Jackson';

  contest: Contest = new Contest();
  contestMessage: Object = {
    type: null as string,
    text: []
  };
  userEligible: boolean = false;
  checkboxOver18Status: boolean = false;
  checkboxToCStatus: boolean = false;

  // User Status
  contestSubmittedMessage: string = 'Your Entry For Today Has Been Submitted';

  // Countdown
  private countDownFuture: Date;
  private countDownDiff: number;
  private $countDownCounter: Observable<number>;
  private countDownSubscription: Subscription;
  private countDownMessage: string;


  source:any = '/assets/videos/crowd-slow-blur-2.mp4';
  constructor(
    public contestService: ContestService,

    public title: Title,
    public metaService: Meta,
    public userService: UserService,
    private addThisService: AddThisService
  ) {

    title.setTitle( this.pageTitle );

    metaService.updateTag({ name: 'description', content: 'This is your drag racing headquarters. Find out where to watch, who to cheer for, how to attend races as a VIP, and stay in the know on all the latest news.' })
    metaService.updateTag({ property: 'og:description', content: 'This is your drag racing headquarters. Find out where to watch, who to cheer for, how to attend races as a VIP, and stay in the know on all the latest news.' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | Barrett Jackson Contest' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    // metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''});
  }


  ngOnInit() {

    this.fetchStatus();
  }

  ngOnDestroy(): void {
    if (this.countDownSubscription) {
      this.countDownSubscription.unsubscribe();
    }
  }

  fetchStatus() {
    this.userService.getContestStatus(1).subscribe(data => {
      this.contest = data['contest'];

      if (data['entriesForDay'] == 0) {
        this.userEligible = true;
        return true
      } else {
        this.setupClock();
        return false
      }
    });
  }

  enterContest() {
    this.updateContestMessage();
    if (!this.userService.userModel.isLoggedIn) {
      this.userService.userModel.errorMessage = ['You must have an account to enter this contest.'];
      hideShowModal('.create-form');
    } else if (!this.checkboxOver18Status || !this.checkboxToCStatus) {
      this.contestMessage['type'] = 'danger';
      if (!this.checkboxOver18Status) {
        this.contestMessage['text'].push('Please verify that you are over the age of 18.');
      }
      if (!this.checkboxToCStatus) {
        this.contestMessage['text'].push('Please agree to the Terms and Conditions.');
      }
    } else {
      this.contestService.enterContest(this.contest.id).subscribe(data => {
        this.setupClock();
        if (data['success']) {
          this.contestMessage['type'] = 'success';
          this.contestMessage['text'] = 'Your Entry For Today Has Been Submitted';
        } else {
          this.contestMessage['type'] = 'danger';
          this.contestMessage['text'] = 'You Have Already Entered the Contest Today';
          this.contestSubmittedMessage = this.contestMessage['text'];
        }

        this.userEligible = false;
      });
    }

  }

  updateContestMessage() {
    this.contestMessage['type'] = null;
    this.contestMessage['text'] = [];
  }

  setupClock() {
    // Set up countdown clock
    let countDownDate = new Date();
    countDownDate.setHours(24,0,0,0);
    countDownDate.getTime();
    this.countDownFuture = countDownDate;

    this.$countDownCounter = observableInterval(1000).pipe(map((x) => {
      this.countDownDiff = Math.floor((this.countDownFuture.getTime() - new Date().getTime()) / 1000);
      return x;
    }));

    this.countDownSubscription = this.$countDownCounter.subscribe((x) => this.countDownMessage = this.dhms(this.countDownDiff));

    // We also want to show social
    this.addAddthis();
  }

  dhms(t) {
    let days, hours, minutes, seconds;
    days = Math.floor(t / 86400);
    t -= days * 86400;
    hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;
    minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    seconds = t % 60;

    return [
      //days + 'd',
      this.pad(hours, 2),
      this.pad(minutes, 2),
      this.pad(seconds,2)
    ].join(':');
  }

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  addAddthis() {
    // Add AddThis to Page
    const script = document.createElement('script');
    script.src ='//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
    document.body.appendChild(script);

    // Reload AddThis
    setTimeout(function () {
      addthis.layers.refresh();
      this.addThisService.setAddThisVisibility(true);
    }, 2000);
  }

}
