import { NewsArticle } from "../news-articles/news-article";

export type InteralStateType = {
  [key: string]: any
};

export class GalleryModel {
    id: number = 0; // WordPress ID
    year: any = '';
    make: any = '';
    model: any = '';
    keyword: any;
    posts:any = [];
    isOpen = false;
    date: Date = new Date();
    alt: string = '';
    post: number = 0;
    newsArticle: NewsArticle = new NewsArticle();
}
