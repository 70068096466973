<div id="rkn-2022-page">
    <style>
        livestream-banner {
            display:  none;
        }
    </style>
    <div class="site-wrapper container container-landing">
        <div class="row intro-container">
            <div class="col-sm-12">
                <div class="intro-content">
                    <img src="/assets/images/rkn2022/rkn-logo.png" alt="Road Kill Nights" class="img-responsive rkn-logo" width="259" height="208">
                    <div class="white-block" id="special">
                        <h2 class="text-uppercase text-center">2022 Dodge Direct Connection Grudge Match</h2>
                        <p class="intro-copy">
                          Are you ready for a rematch? We’re welcoming back some of last year’s Grudge Racers for a chance at redemption. Or for Alex Taylor, a chance to go 2-0 against a field of impressive talent that includes some new faces, familiar to the automotive faithful. In a new twist this year, competitors will use Direct Connection parts to build their own drag-race machine under a tight deadline before bringing it to Woodward to go head-to-head on the strip. Direct Connection parts available as “building blocks” for competitors will include the Hellcrate Redeye 6.2L Supercharged Crate HEMI<sup>®</sup> engine and a plug-and-play Hellcrate Redeye 6.2L Supercharged Crate HEMI Engine Control Unit (ECU).  With a small budget for parts and a deadline for a public grudge match, the builds are on for one hell of a race. Click their pictures below to check out their profiles, follow their build progress and catch some behind-the-scenes pics. Tune in for the showdown at MotorTrend Presents Roadkill Nights Powered by Dodge on Saturday, August 13, 2022, at M1 Concourse in Pontiac, MI, or livestreamed right here on DodgeGarage.
                        </p>
                        <p class="text-center text-primary ga-event"><a href="https://www.motortrend.com/roadkill-nights-2022/" target="_blank" data-ga_category="RKN Landing Page" data-ga_label="Event Registration Outgoing Click">Tickets are now available - we'll see you there!</a></p>
                    </div>
                    <img src="/assets/images/logos/direct-connection-logo.png" alt="Road Kill Nights" class="img-responsive direct-connect-logo" width="313" height="64">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <br><br><br>
                <h3>Meet the builders</h3>
                <p>Click the image below to explore each builder’s profile</p>
            </div>
            <div class="col-sm-12">
                <div class="builder-wrap">
                    <div *ngFor="let builder of builders" class="builder">
                        <a [routerLink]="builder.slug" (click)="clickTrackerContributor(builder.name)">
                            <div>
                                <img [src]="builder.thumbnailPath" class="img-responsive" [alt]="builder.name">
                            </div>
                            <p>{{ builder.name }}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>






    <div class="modal fade" bsModal #videoModal id="other-modal" tabindex="-1" role="dialog" (onHidden)="closeVideoModal()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
                </div>
                <div class="modal-body">
                    <div class="embed-responsive embed-responsive-16by9" *ngIf="modalActive">
                        <div class="embed-responsive-item">
                            <iframe width="560" height="315" [src]="'https://player.vimeo.com/video/' + activeVideo + '?autoplay=1' | safe: 'resourceUrl'" title="Let the Grudge Match Builds Begin" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <!-- <div class="addthis_inline_share_toolbox text-center"></div> -->
                </div>
            </div>
        </div>
    </div>
</div>





