<div id="rkn-2022-page">
    <div *ngIf="contributor">
        <div id="intro-content-container" class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="lil-demon-box">
                        <img [src]="contributor.profileImagePath" alt="" class="img-responsive" [title]="contributor.profileTitle" [alt]="contributor.profileAlt">
                    </div>
                </div>
                <div class="col-lg-8 col-sm-6">

                    <div class="white-block short">
                        <div class="content-header">
                            <h2>{{ contributor.name }}</h2>
                            <div>
                                <a *ngIf="contributor.instagram" [href]="instagram()" target="_blank" rel="noopener noreferrer" class="lil-icons instagram" (click)="clickTrackerSocial('instagram')">
                                    <span class="sr-only">Instagram logo</span>
                                    <i class="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                                <a *ngIf="contributor.youtube" [href]="youtube()" target="_blank" rel="noopener noreferrer" class="lil-icons youtube" (click)="clickTrackerSocial('youtube')">
                                    <span class="sr-only">Youtube logo</span>
                                    <i class="fa fa-youtube-play" aria-hidden="true"></i>
                                </a>
                                <a *ngIf="contributor.tiktok" [href]="tiktok()" target="_blank" rel="noopener noreferrer" class="lil-icons tiktok" (click)="clickTrackerSocial('tiktok')">
                                    <span class="sr-only">Tiktok logo</span>
                                    <svg title="TikTok icon" width="24px" height="24px" viewBox="0 0 24 24" role="img" fill="currentColor" stroke="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"/></svg>
                                </a>
                                <a *ngIf="contributor.facebook" [href]="facebook()" target="_blank" rel="noopener noreferrer" class="lil-icons facebook" (click)="clickTrackerSocial('facebook')">
                                    <span class="sr-only">Facebook logo</span>
                                    <i class="fa fa-facebook" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <p [innerHTML]="contributor.blurb"></p>
                    </div>

                    <br>
                    <div *ngIf="contributor.buildImagePath">
                        <!-- <a href="#other-modal" data-toggle="modal"> -->
                        <img [src]="contributor.buildImagePath" class="img-responsive" [title]="contributor.buildTitle" [alt]="contributor.buildAlt">
                        <!-- </a> -->
                    </div>
                </div>
            </div>
        </div>

        <section id="build-videos" *ngIf="hasVideos()">
            <div class="site-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 section-heading">
                            <!-- Remove Text only show -->
                            <h2 class="heading1 mb-0">Build Videos</h2>
                            <p class="text-uppercase hidden-lg" *ngIf="hasVideos()">Tap on each video below to see the build progress</p>
                            <p class="text-uppercase hidden visible-lg" *ngIf="hasVideos()">Click on each video below to see the build progress</p>
                        </div>
                    </div>
                </div>
            </div>
            <build-videos *ngIf="!loading" [videos]="videos" (activeVideo)="openVideoModal($event)"></build-videos>
        </section>

        <section id="build-gallery" *ngIf="hasImages()">
            <div class="site-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 section-heading">
                            <h2 class="heading1 mb-0">Build Gallery</h2>
                            <p class="text-uppercase hidden-lg">Tap on each picture to see the full image</p>
                            <p class="text-uppercase hidden visible-lg">Click on each picture to see the full image</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="gallery-images">
                <div class="site-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row">
                                    <ngx-masonry class="image-container">
                                        <a *ngFor="let image of buildImages; let i = index" [href]="image.source_url" class="image col-xs-6" ngxMasonryItem>
                                            <img [src]="image.source_url" [alt]="image.title['rendered']" class="img-responsive">
                                        </a>
                                    </ngx-masonry>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!allImages && galleryIds.length > 4">
                            <div class="col-xs-12 button-container text-center">
                                <button class="btn btn-rkn-outline" (click)="showAllImages()">View All <i class="fa fa-chevron-down"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div id="video-modal" class="modal fade" bsModal #videoModal tabindex="-1" role="dialog" (onHidden)="closeVideoModal()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
                </div>
                <div class="modal-body">
                    <div *ngIf="modalActive" class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" [src]="activeVideo | safe: 'resourceUrl'" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div id="other-modal" class="modal fade" bsModal id="videoModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-close"><i class="fa fa-close"><span
                        class="sr-only">close</span></i></button>
                </div>
                <div class="modal-body">
                    <p class="description"></p>
                    <div class="embed-responsive embed-responsive-16by9 mb-4">
                        <div class="embed-responsive-item">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/LSNudcn3HWE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="addthis_inline_share_toolbox text-center"></div>
                </div>
            </div>
        </div>
    </div>
</div>
