<div class="site-wrapper">
	<div class="container-fluid">
    <header class="page-header">
      <h1>Ringtone Instructions</h1>
      <p><a routerLink="/downloads" class="btn btn-outline-primary"><i class="fa fa-caret-left"></i> Back to Downloads</a></p>
		</header>
		<div class="page-content">
      <p class="lead">Select your device below</p>
      <tabset class="dg-tabs">
        <tab id="android">
          <ng-template tabHeading>
            <span>Android (mp3)</span>
          </ng-template>
          <div class="row">
            <div class="col-md-4 device-instructions">
              <h2 class="heading-main">Direct to Device</h2>
              <p>Note: Direct to device instructions may not work for all Android devices. See Desktop instructions</p>
              <ol>
                <li>After Downloading to your device, from your home screen, open up the options window where all of your apps are and scroll to the icon that looks like a gear called “Settings”</li>
                <li>Click on “My Device”</li>
                <li>Click “Sound” (should be in middle of the screen)</li>
                <li>Click “Ringtones”</li>
                <li>Click Add at bottom of the page</li>
                <li>Click “Sound Picker” to find the mp3</li>
                <li>In songs select “challenger-srt-hellcat-ringtone” and click the green circle next to it</li>
                <li>Click done in top right</li>
                <li>Your ringtone should now be set to the SRT<sup>®</sup> Hellcat Engine!</li>
                <li>Exit out and return to home screen</li>
              </ol>
            </div>
            <div class="col-md-8">
              <h2 class="heading-main">Desktop Instructions</h2>
              <div class="row ringtone-step-container">
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/android-step-1.jpg" alt="Ringtones - Android Step 1">
                  </div>
                  <div class="step-content">
                    <p>Download the Challenger SRT<sup>&reg;</sup> Hellcat Ringtone and save to your desktop.</p>
                  </div>
                </div>
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/android-step-2.jpg" alt="Ringtones - Android Step 2">
                  </div>
                  <div class="step-content">
                    <p>Connect your device to your pc/laptop, via USB, Bluetooth, or using a microSD adapter.</p>
                  </div>
                </div>
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/android-step-3.jpg" alt="Ringtones - Android Step 3">
                  </div>
                  <div class="step-content">
                    <p>Open the file folder for your device.</p>
                  </div>
                </div>
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/android-step-4.jpg" alt="Ringtones - Android Step 4">
                  </div>
                  <div class="step-content">
                    <p>Create a new folder. Label it Ringtones.</p>
                  </div>
                </div>
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/android-step-5.jpg" alt="Ringtones - Android Step 5">
                  </div>
                  <div class="step-content">
                    <p>Open the folder on your pc/laptop containing the ringtones.</p>
                  </div>
                </div>
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/android-step-6.jpg" alt="Ringtones - Android Step 6">
                  </div>
                  <div class="step-content">
                    <p>Drag and drop the ringtone file to the Ringtones folder on your device. Disconnect your device from your computer/laptop.</p>
                  </div>
                </div>
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/android-step-7.jpg" alt="Ringtones - Android Step 7">
                  </div>
                  <div class="step-content">
                    <p>Go to Sound Settings on your phone.</p>
                    <ul>
                      <li>Tap the menu/navigation hard key, and select Settings.</li>
                      <li>Select Sound and the menu titled Phone ringtone.</li>
                      <li>Scroll through the list of ringtones until you find the Challenger SRT® Hellcat Ringtone file.</li>
                      <li>If you don't see the ringtone you just added, turn the phone off and then on and it should appear.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <tab id="apple">
          <ng-template tabHeading>
            <span>Apple (m4r)</span>
          </ng-template>
          <div class="row">
            <div class="col-md-4 device-instructions">
              <h2 class="heading-main">Direct to Device</h2>
              <p>For Apple devices, you will need to download to your desktop and connect your device through iTunes to complete installation.</p>
            </div>
            <div class="col-md-8">
              <h2 class="heading-main">Desktop Instructions</h2>
              <div class="row ringtone-step-container">
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/apple-step-1.jpg" alt="Ringtones - Apple Step 1">
                  </div>
                  <div class="step-content">
                    <p>To start, please save the file to your desktop. Next, drag it directly into iTunes. (make sure you have the “Tones” folder checked in your iTunes preferences before dragging the file.</p>
                  </div>
                </div>
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/apple-step-2.jpg" alt="Ringtones - Apple Step 2">
                  </div>
                  <div class="step-content">
                    <p>Once the file is visible in your “Tones” subfolder, you should be able to play it in iTunes.</p>
                  </div>
                </div>
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/apple-step-3.jpg" alt="Ringtones - Apple Step 3">
                  </div>
                  <div class="step-content">
                    <p>The final step to sync the ringtone to your iPhone is to plug your iPhone into your pc and then select/sync the ringtone to your iPhone.</p>
                  </div>
                </div>
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/apple-step-4.jpg" alt="Ringtones - Apple Step 4">
                  </div>
                  <div class="step-content">
                    <p>Once the file is visible in your “tones” folder in your iPhone menu (in iTunes) you can disconnect your iPhone from your pc.</p>
                  </div>
                </div>
                <div class="ringtone-step col-md-4">
                  <div class="img-contain">
                    <img loading="lazy" class="img-responsive" src="/assets/images/ringtones/instructions/apple-step-5.jpg" alt="Ringtones - Apple Step 5">
                  </div>
                  <div class="step-content">
                    <p>From there, you simply have to select the “Challenger SRT Hellcat Ringtone” ringtone and enable it for a new or existing contact from your iPhone phone/contacts app.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>
