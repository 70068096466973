import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'performance-cars-trademarks',
  templateUrl: './trademarks.component.html',
  styleUrls: ['./trademarks.component.less']
})
export class TrademarksComponent implements OnInit {

  constructor(
    public title: Title,
    public metaService: Meta,
    private router: Router
  ) {
    title.setTitle( 'Legal, Safety and Trademark Information | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'Dodge Garage Legal, Safety and Trademark Information' })
    metaService.updateTag({ property: 'og:description', content: 'Dodge Garage Legal, Safety and Trademark Information' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | Legal, Safety and Trademark Information' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
  }

}
