import {Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../user/user.service';
import { environment } from '../../environments/environment';
import {HelperService} from "../services/helper.service";
import {isPlatformBrowser} from "@angular/common";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

declare let ga: Function;
declare let gtag: Function;

@Component({
  selector: 'performance-cars-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  // Date Information
  today: Date = new Date();

  displayPasswordResetBanner: boolean = false;

  // Contest for Hero (and switching the position)
  isCurrentContestEvent: boolean = false;
  upcomingContestEvents: Event[] = [];

  // User Functionality
  @ViewChild('userModal', { static: true }) public userModal: TemplateRef<any>;
  userModalRef: BsModalRef;
  userModalDefaultTab: string = 'login';

  // Lazy Loading
  heroVideoLoaded: boolean = true;
  videoSectionLoaded: boolean = false;
  featureSliderLoaded: boolean = false;
  eventsSliderLoaded: boolean = false;
  missionBannerLoaded: boolean = false;
  tileSectionLoaded: boolean = false;
  isDgThirteenTwentyTileLoaded: boolean = false;
  isDgVideoThirteenTwentyLoaded: boolean = false;
  isDgSalesIncentivesLoaded: boolean = false;
  isMountainDewBannerLoaded: boolean = false;
  isSocialBannerLoaded: boolean = false;
  isDgPollLoaded: boolean = false;
  isRacingHqTileLoaded: boolean = false;
  isMerchTileLoaded: boolean = false;
  isDownloadsTileLoaded: boolean = false;
  isSrtDemonFaqsTileLoaded: boolean = false;
  isBondurantTileLoaded: boolean = false;
  is1320TileLoaded: boolean = false;
  isDCTileLoaded: boolean = false;

  // Special Merch Tile
  isSpecialMerchTileVisible: boolean = true;

  constructor(
    public route: ActivatedRoute,
    public userService: UserService,
    public title: Title,
    public metaService: Meta,
    private modalService: BsModalService,
    private router: Router,
    public helperService: HelperService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    title.setTitle( 'Home | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'Welcome to Dodge Garage' })
    metaService.updateTag({ property: 'og:description', content: 'Welcome to Dodge Garage' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }


  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      //Check parameter for activation token
      if (this.getParameterByName('activationToken')) {
        this.userService.activation();
      }
      if (this.getParameterByName('resetToken')) {
        this.displayPasswordResetBanner = true;
        //this.userService.activation();
      }

      // Check for Create Account URL
      if (this.router.url.includes('user/create-account')) {
        this.userModalDefaultTab = 'register';
        setTimeout(() => {
          this.openUserModal();
        }, 2000);
      }
      // Google Ads Stuff
      gtag('event', 'conversion', {'send_to': 'AW-1031854740/tF1nCIqIg9gBEJS1g-wD'});

      // document.body.classList.add('fixed-nav');
      document.body.classList.remove('fixed-nav');
      document.body.classList.add('fixed-hero-active');

      //this.heroVideoLoaded = true;
    }

    //this.fetchContestEvent();

    // Check Special Merch Dates
    let currentDate = new Date();
    let givenDate = new Date('2020-03-24 00:00:00');

    // Show the special tile until the deadline
    if (givenDate > currentDate) {
      this.isSpecialMerchTileVisible = true;
    } else {
      this.isSpecialMerchTileVisible = false;
    }
  }

  homepageGASignUp(event) {
    ga('send', {
      hitType: 'event',
      eventCategory: 'SignUp',
      eventAction: 'Conversation',
      eventLabel: window.location.href
    });
    localStorage.setItem('gaAction', 'Conversation');
  }

  getParameterByName(name) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  // fetchContestEvent() {
  //   this.eventService.getEvents(25, 0, '', true, ['srt-demon-simulators'], 'eventWithRaces').subscribe( events => {
  //     this.upcomingContestEvents = events;
  //     let currentEvent = events.filter(event => {
  //       return (new Date(event.startDate) <= this.today && new Date(event.endDate) >= this.today)
  //     });
  //     if (currentEvent.length > 0) { // Check if any events were found for today
  //       this.isCurrentContestEvent = true;
  //     }
  //   });
  // }

  openUserModal() {
    //event.preventDefault();
    this.userModalRef = this.modalService.show(this.userModal, {class: 'modal-full'})
  }

  closeUserModal() {
    // Add any magic to fire when closing the modal
    if (this.userModalRef) {
      this.userModalRef.hide();
    }
  }

  // Lazy loading
  loadVideoSection(): void { this.videoSectionLoaded = true }
  loadEventSlider(): void { this.eventsSliderLoaded = true }
  loadSalesIncentives(): void { this.isDgSalesIncentivesLoaded = true }
  loadPollSection(): void { this.isDgPollLoaded = true }
  loadTileSection(): void { this.tileSectionLoaded = true }
  loadSocialSection(): void { this.isSocialBannerLoaded = true }
  loadFeatureSlider(): void { this.featureSliderLoaded = true }
  loadMissionBanner(): void { this.missionBannerLoaded = true }

}
