import { ActionsSummary } from "./actions_summary";
import {UserModel} from "../user/user";
import {Topic} from "./topic";

export class Post {
  id: number = 0;
  username: string;
  avatar_template: string;
  uploaded_avatar_id: string;
  created_at: string;
  cooked: string;
  post_number: number;
  post_type: number;
  updated_at: string;
  reply_count: number;
  reply_to_post_number: string;
  quote_count: number;
  avg_time: number;
  incoming_link_count: number;
  reads: number;
  score: number;
  yours: boolean;
  topic_id: number;
  topic_slug: string;
  topic_auto_close_at: number;
  primary_group_name: string;
  version: number;
  user_title: string;
  actions_summary: ActionsSummary[];
  moderator:boolean;
  admin: boolean;
  user_id: number;
  hidden: boolean;
  edit_reason: string;
  can_view_edit_history: boolean;
  can_delete: boolean;

  post_replies: Post[];
  replies: Post[];
  show_replies: boolean = false;
  reply_comment: string;
  showTextArea: boolean = false;
  showEditArea: boolean = false;
  edited_comment: string;
  createdDate: string;
  updatedDate: string;
  isFlagged: boolean = false;
  isFlaggedByUser: boolean = false;

  likesCount: number = 0;
  userActed: boolean = false;
  userCanAct: boolean = false;
  userCanUndo: boolean = false;

  userDeleted: boolean = false;
  isDeleted: boolean = false;

  user: UserModel = new UserModel();
  topic: Topic = new Topic();
}



