<div id="events-slider">
  <div class="container-fluid">
    <div class="col-sm-12 slider-header">
      <h2 class="heading">Upcoming Events</h2>
      <a class="btn btn-primary btn-solid" routerLink="/events" data-ga_category="Home" data-ga_action="Click" data-ga_label="More Events">View All</a>
      <div class="slider-controls">
        <button id="carousel-prev" class="carousel-button"><i class="fa fa-arrow-left"></i></button>
        <button id="carousel-next" class="carousel-button"><i class="fa fa-arrow-right"></i></button>
      </div>
    </div>
    <div class="carousel-container col-sm-12">
      <swiper #swiper [config]="config">
        <ng-container *ngFor="let event of events; index as i">
          <ng-template swiperSlide>
            <dg-event-card class="event-homepage" [event]="event"></dg-event-card>
          </ng-template>
        </ng-container>
      </swiper>
    </div>
  </div>
</div>
