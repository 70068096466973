<div class="site-wrapper">
  <header class="page-header"></header>
</div>

<div class="site-wrapper mb-5">
  <div class="container-fluid">
    <div class="row-flex">
      <div class="col-flex-md-12 header-container">
        <h1 class="mb-5">2021 Dodge Challenger Mopar<sub>&reg;</sub> Drag Pak</h1>
      </div>
    </div>
    <div class="row-flex">
      <div class="col-sm-12 mb-5">
        <dg-video-drag-pak></dg-video-drag-pak>
      </div>
      <div class="col-sm-12">
        <h2>The quickest, fastest, most powerful Drag Pak ever.</h2>
        <p>The 4th generation of drag strip domination is staged and ready for action.</p>
        <p>
          The sold-out 2021 Dodge Challenger Mopar Drag Pak was engineered for grassroots drag racers competing in sanctioned NHRA and NMCA sportsman classes. This factory, production-based turnkey package for 50 high-performance enthusiasts will take their competition to the next level. Fully dyno validated and approved by the NHRA and NMCA for racing, here's what makes it the quickest, fastest, most powerful Drag Pak ever:
        </p>
      </div>
    </div>
    <div class="feature-list-container">
      <ul class="list-2-cols-sm features-list">
        <li *ngFor="let item of listOverview">
          <span class="text-uppercase" [innerHtml]="item.title"></span>
          <ul>
            <li *ngFor="let bullet of item.bullets" [innerHtml]="bullet"></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="site-wrapper mb-5">
  <div class="container-fluid">
    <div class="expanding-gallery-container" #galleryContainer [ngClass]="{'active':galleryContainer.isOpen}">
      <div class="row-flex">
        <div class="col-flex-xs-6 col-flex-md-3 mb-3" *ngFor="let vehicle of galleryVehicles">
          <a [href]="vehicle.full">
            <img loading="lazy" [src]="vehicle.thumb" class="img-responsive" [alt]="vehicle.alt" width="212" height="138">
          </a>
        </div>
      </div>
      <button class="btn btn-outline-primary btn-view-more" (click)="galleryContainer.isOpen = !galleryContainer.isOpen">
        <i class="fa fa-chevron-down" aria-hidden="true"></i>
        View More
      </button>
      <button class="btn btn-outline-primary btn-view-less" (click)="galleryContainer.isOpen = !galleryContainer.isOpen">
        <i class="fa fa-chevron-up" aria-hidden="true"></i>
        View Less
      </button>
    </div>
  </div>
</div>

<!--<div class="site-wrapper mb-5">
  <div class="tech-specs">
    <div class="h4">Performance</div>
    <div class="h2">Tech Specifications</div>
    <div class="row-flex">
      <div class="col-flex-xs-6 col-flex-md-3 mb-3">
        <div class="spec">900</div>
        <div class="label">Horsepower</div>
      </div>
      <div class="col-flex-xs-6 col-flex-md-3 mb-3">
        <div class="spec">XXX</div>
        <div class="label">Lorem Ipsum</div>
      </div>
      <div class="col-flex-xs-6 col-flex-md-3 mb-3">
        <div class="spec">XXX</div>
        <div class="label">Lorem Ipsum</div>
      </div>
      <div class="col-flex-xs-6 col-flex-md-3 mb-3">
        <div class="spec">XXX</div>
        <div class="label">Lorem Ipsum</div>
      </div>
    </div>
  </div>
</div>-->

<div class="site-wrapper mb-5">
  <div class="container-fluid">
    <img loading="lazy" src="/assets/images/drag-pak/underbody.webp" class="img-responsive" alt="Underbody" width="1100" height="494">
  </div>
</div>

<div class="site-wrapper">
  <div class="container-fluid">
    <h2 class="text-center">For track use only</h2>
    <p class="text-center lead">All 2021 Drag Pak vehicles are not street legal, do not have a VIN and are for track use only. All orders include the Base Drag Pak, your choice of performance seat kit, choice of wheel and tire kit and optional high-impact color and heritage graphics. U.S. MSRP shown. MSRP excludes taxes and other fees. See dealer for complete pricing.</p>

    <div class="vehicle-package base-package mb-5" #basePackage [ngClass]="{'active':basePackage.isOpen}">
      <div class="package-img">
        <img loading="lazy" src="/assets/images/drag-pak/package-base.webp" alt="Base Package" class="img-responsive" width="1598" height="550">
        <div class="caption text-right"><em>Upgrade wheels shown</em></div>
      </div>
      <div class="package-content">
        <div class="content-header mb-0">
          <div class="title-and-price mb-0">
            <span>Base Drag Pak</span>
            <span class="divider"></span>
            <span class="price">$135,995 ($198,895 <small>CAD</small>)</span>
          </div>
        </div>
        <p class="price text-primary">(does not include seats, wheels and tires)</p>
        <ul class="list-2-cols-sm">
          <li>354 cu. in. HEMI<sup>&reg;</sup> V8 engine with 3.0-liter Whipple supercharger</li>
          <li>Race-ready 3-speed Turbo-400 Transmission with lightweight aluminum driveshaft</li>
          <li>All new 4-link rear suspension with Strange Engineering 9” rear axle and 4.11 final drive</li>
          <li>Specially developed double-adjustable BILSTEIN Drag Pak coilover shocks</li>
          <li>Strange Engineering low-drag 4-piston racing brakes</li>
          <li>7.50ET certified chromoly roll cage</li>
          <li>Full interior with carbon fiber instrument panel and carpet</li>
          <li>Quick-release steering wheel and adjustable pedals</li>
          <li>Holley Dominator ECU & 7" Digital Dash display</li>
          <li>Fully-programmable Racepak Smartwire Power Distribution Module</li>
          <li>Complete Aeromotive fuel system with 3-gallon fuel cell</li>
          <li>SRT<sup>&reg;</sup> Hellcat Redeye front & rear fascia with SRT<sup>&reg;</sup> Performance Spoiler & aluminum hood from production Challenger 1320</li>
          <li>Painted in White Knuckle</li>
          <li>For track use only. Not street legal, does not have a VIN</li>
          <li>Customer pickup is required from Watson Engineering in Brownstown, MI</li>
        </ul>
      </div>
      <button class="btn btn-link btn-view-more" (click)="basePackage.isOpen = !basePackage.isOpen">
        <i class="fa fa-chevron-down" aria-hidden="true"></i>
        View More
      </button>
      <button class="btn btn-link btn-view-less" (click)="basePackage.isOpen = !basePackage.isOpen">
        <i class="fa fa-chevron-up" aria-hidden="true"></i>
        View Less
      </button>
    </div>

  </div>
</div>



<div class="site-wrapper mb-5">
  <div class="container-fluid add-on-packages-contaier">
    <div class="row">
      <div class="col-lg-6">
        <h3 class="package-category">RaceTech Performance Seats</h3>
        <div class="row">
          <div class="col-sm-12">
            <div class="vehicle-package" #seatPackage1 [ngClass]="{'active':seatPackage1.isOpen}">
              <div class="package-img">
                <img loading="lazy" src="/assets/images/drag-pak/package-seats1.webp" alt="RaceTech Base Seat Kit" class="img-responsive" width="766" height="698">
              </div>
              <div class="title-and-price">
                <span>RaceTech Base Seat Kit</span>
                <span class="price">$2,625 ($3,775 <small>CAD</small>)</span>
              </div>
              <div class="package-content mb-0">
                
                <ul>
                  <li>Driver & passenger composite seats</li>
                  <li>HANS-ready 6-point driver harness</li>
                  <li>HANS-ready 4-point passenger harness</li>
                </ul>
                <ul>
                  <li>Seat brackets</li>
                  <li>All associated hardware</li>
                </ul>
              </div>
              <button class="btn btn-link btn-view-more" (click)="seatPackage1.isOpen = !seatPackage1.isOpen">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                View More
              </button>
              <button class="btn btn-link btn-view-less" (click)="seatPackage1.isOpen = !seatPackage1.isOpen">
                <i class="fa fa-chevron-up" aria-hidden="true"></i>
                View Less
              </button>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="vehicle-package" #seatPackage2 [ngClass]="{'active':seatPackage2.isOpen}">
              <div class="package-img">
                <img loading="lazy" src="/assets/images/drag-pak/package-seats2.webp" alt="Seat Option 2" class="img-responsive" width="766" height="698">
              </div>
              <div class="title-and-price">
                <span>RaceTech Head Restraint Seat Kit</span>
                <span class="price">$5,495 ($7,870 <small>CAD</small>)</span>
              </div>
              <div class="package-content">
                <ul>
                  <li>Full containment composite driver seat with winged head restraints</li>
                  <li>Composite passenger seat</li>
                  <li>HANS-ready 6-point driver harness</li>
                </ul>
                <ul>
                  <li>HANS-ready 4-point passenger harness</li>
                  <li>Seat brackets</li>
                  <li>All associated hardware</li>
                </ul>
              </div>
              <button class="btn btn-link btn-view-more" (click)="seatPackage2.isOpen = !seatPackage2.isOpen">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                View More
              </button>
              <button class="btn btn-link btn-view-less" (click)="seatPackage2.isOpen = !seatPackage2.isOpen">
                <i class="fa fa-chevron-up" aria-hidden="true"></i>
                View Less
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <h3 class="package-category">Performance Wheels &amp; Tires</h3>
        <div class="row">
          <div class="col-sm-12">
            <div class="vehicle-package" #wheelPackage1 [ngClass]="{'active':wheelPackage1.isOpen}">
              <div class="package-img">
                <img loading="lazy" src="/assets/images/drag-pak/package-wheels1.webp" alt="Wheel Option 1" class="img-responsive" width="766" height="698">
              </div>
              <div class="title-and-price">
                <span>Bogart Base Wheel &amp; Tire Kit</span>
                <span class="price">$4,865 ($6,995 <small>CAD</small>)</span>
              </div>
              <div class="package-content">
                <ul>
                  <li>Bogart 17x4.5 front wheels</li>
                  <li>Bogart 15x10 rear wheels (non-beadlock)</li>
                  <li>Wheels powder coated matte black with Mopar engraving</li>
                </ul>
                <ul>
                  <li>Mickey Thompson ET Front Tires 27.5X4.0-17</li>
                  <li>Mickey Thompson ET Drag<sup>&reg;</sup> Pro Drag Radial Tires 30.0X9.0R15</li>
                  <li>Mounted & balanced with valve stems</li>
                </ul>
              </div>
              <button class="btn btn-link btn-view-more" (click)="wheelPackage1.isOpen = !wheelPackage1.isOpen">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                View More
              </button>
              <button class="btn btn-link btn-view-less" (click)="wheelPackage1.isOpen = !wheelPackage1.isOpen">
                <i class="fa fa-chevron-up" aria-hidden="true"></i>
                View Less
              </button>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="vehicle-package" #wheelPackage2 [ngClass]="{'active':wheelPackage2.isOpen}">
              <div class="package-img">
                <img loading="lazy" src="/assets/images/drag-pak/package-wheels2.webp" alt="Wheel Option 2" class="img-responsive" width="766" height="698">
              </div>
              <div class="title-and-price">
                <span>Weld Beadlock Lightweight Wheel &amp; Tire Kit</span>
                <span class="price">$6,895 ($9,931 <small>CAD</small>)</span>
              </div>
              <div class="package-content">
                <ul>
                  <li>Weld Racing V-Series front 17x4.5 frontrunner wheels</li>
                  <li>Weld Racing Alpha-1 15x10 rear wheels (double-beadlock)</li>
                  <li>Wheels powder coated gloss black with exclusive Drag Pak machined logo</li>
                </ul>
                <ul>
                  <li>Mickey Thompson ET Front Tires 27.5X4.0-17</li>
                  <li>Mickey Thompson ET Drag<sup>&reg;</sup> Pro Drag Radial Tires 30.0X9.0R15</li>
                  <li>Mounted & balanced with valve stems</li>
                </ul>
              </div>
              <button class="btn btn-link btn-view-more" (click)="wheelPackage2.isOpen = !wheelPackage2.isOpen">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                View More
              </button>
              <button class="btn btn-link btn-view-less" (click)="wheelPackage2.isOpen = !wheelPackage2.isOpen">
                <i class="fa fa-chevron-up" aria-hidden="true"></i>
                View Less
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-wrapper color-options mb-5">
  <div class="container-fluid">
    <div class="title-and-price"><span>High-Impact Color Options</span><span class="divider"></span><span class="price">$995 ($1,410 <small>CAD</small>)</span></div>
    <div class="expanding-gallery-container" #colorsContainer [ngClass]="{'active':colorsContainer.isOpen}">
      <div class="row-flex">
        <div class="col-flex-xs-6 col-flex-md-4 mb-3" *ngFor="let color of galleryColors">
          <a [href]="color.full">
            <img loading="lazy" [src]="color.thumb" class="img-responsive" [alt]="color.alt" width="385" height="250">
          </a>
        </div>
      </div>
      <button class="btn btn-outline-primary btn-view-more" (click)="colorsContainer.isOpen = !colorsContainer.isOpen">
        <i class="fa fa-chevron-down" aria-hidden="true"></i>
        View More
      </button>
      <button class="btn btn-outline-primary btn-view-less" (click)="colorsContainer.isOpen = !colorsContainer.isOpen">
        <i class="fa fa-chevron-up" aria-hidden="true"></i>
        View Less
      </button>
    </div>
  </div>
</div>

<div class="site-wrapper livery-options mb-5">
  <div class="container-fluid text-center">
    <div class="title-and-price">
      <span>Heritage Graphics Options</span>
      <span class="divider"></span>
      <span class="price">$5,995 ($8,569 <small>CAD</small>)</span>
    </div>
    <div class="expanding-gallery-container" #liveryContainer [ngClass]="{'active':liveryContainer.isOpen}">
      <div class="row-flex">
        <div class="col-flex-xs-6 col-flex-md-3 mb-3" *ngFor="let option of galleryLivery">
          <a [href]="option.full">
            <img loading="lazy" [src]="option.thumb" class="img-responsive" [alt]="option.alt" width="385" height="250">
          </a>
        </div>
      </div>
      <button class="btn btn-outline-primary btn-view-more" (click)="liveryContainer.isOpen = !liveryContainer.isOpen">
        <i class="fa fa-chevron-down" aria-hidden="true"></i>
        View More
      </button>
      <button class="btn btn-outline-primary btn-view-less" (click)="liveryContainer.isOpen = !liveryContainer.isOpen">
        <i class="fa fa-chevron-up" aria-hidden="true"></i>
        View Less
      </button>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-sm-12 mt-5 text-center btn-container">
    <a routerLink="/drag-pak-experience/post-run-checklist" class="btn btn-primary btn-footer-nav mr-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Post-Run Checklist"><i class="fa fa-chevron-left" aria-hidden="true"></i> <span>Post-Run Checklist</span></a>
    <a routerLink="/drag-pak-experience/history" class="btn btn-primary btn-footer-nav ml-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Drag Pak History"><span>Drag Pak History</span> <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
  </div>
</div>

<!--<div class="site-wrapper">
  <div class="container-fluid text-center">
    <p><a routerLink="/drag-pak/customer" class="btn btn-primary">Begin Your Reservation</a></p>
  </div>
</div>-->

