<div id="never-lift">
  <div class="container container-landing">
    <div class="row">
      <div class="col-12">
        <section>
          <header class="never-lift-title">
            <div class="header-logos">
              <img src="/assets/images/logos/dodge-logo-large.png" alt="dodge logo">
              <h2 style="display: inline-block;">Never Lift Calendar of Muscle</h2>
            </div>
            <p class="header-copy">As the Dodge Brand accelerates toward the next generation of the Brotherhood of Muscle, tune in here to see the garage doors open with exciting announcements that celebrate the passion for performance you've come to expect and respect.</p>
          </header>
          <div class="garage-tile-wrap">
            <div *ngFor="let garageTile of garageTiles" class="tile-container">
              <div  class="garage-tile {{garageTile.type}}">
                <a *ngIf="garageTile.type === 'article'" [attr.href]="'/news/article/'+garageTile.newsArticleData.cleanLink" (click)="goToArticle(garageTile.newsArticleData, $event)" rel="bookmark" title="{{garageTile.newsArticleData.linkTitle}}" class="garage-link ga-event" data-ga_category="Never Lift Landing Page" data-ga_action="Click" [attr.data-ga_label]="garageTile.analyticsLabel">
                  <span class="sr-only" [innerHTML]="garageTile.analyticsLabel"></span>
                </a>
                <a *ngIf="garageTile.type === 'video'" href="javascript:void(0)" (click)="openVideoModal($event, garageTile.youtubeEmbedID)" class="garage-link ga-event" data-ga_category="Never Lift Landing Page" data-ga_action="Click" [attr.data-ga_label]="garageTile.analyticsLabel">
                  <span class="sr-only" [innerHTML]="garageTile.analyticsLabel"></span>
                </a>
                <a *ngIf="garageTile.type === 'video-vimeo'" href="javascript:void(0)" (click)="openVimeoVideoModal($event, garageTile.vimeoEmbedID)" class="garage-link ga-event" data-ga_category="Never Lift Landing Page" data-ga_action="Click" [attr.data-ga_label]="garageTile.analyticsLabel">
                  <span class="sr-only" [innerHTML]="garageTile.analyticsLabel"></span>
                </a>
                <countdown *ngIf="garageTile.type === 'countdown' && !countdownEnded" [config]="garageTile.countdownConfig" (event)="handleCountdownEvent($event)"></countdown>
                <div *ngIf="garageTile.overlay" class="overlay-container">
                  <img [attr.src]="garageTile.overlay.src" [attr.alt]="garageTile.overlay.alt" class="overlay-image">
                </div>
                <div *ngIf="garageTile.previewThumbnail" class="preview-thumbnail" [ngClass]="{'invert': garageTile.invert}">
                  <i class="expand fa fa-expand" aria-hidden="true"></i>
                  <img  [attr.src]="garageTile.previewThumbnail.src" [attr.alt]="garageTile.previewThumbnail.alt">
                </div>
                <p *ngIf="garageTile.title" [innerHTML]="garageTile.title" class="garage-tile-title"></p>
              </div>
              <!-- Secondary News Article -->
              <a *ngIf="garageTile.buttonText && garageTile.buttonLinkID" class="btn btn-primary tile-button ga-event" [id]="garageTile.buttonLinkID" [attr.href]="'/news/article/'+garageTile.secondaryNewsArticleData.cleanLink" (click)="goToArticle(garageTile.secondaryNewsArticleData, $event)" rel="bookmark" title="{{garageTile.secondaryNewsArticleData.linkTitle}}" data-ga_category="Never Lift Landing Page" data-ga_action="Click" [innerHTML]="garageTile.buttonText" [attr.data-ga_label]="garageTile.analyticsLabel"></a>
              <!-- Some other kind of link -->
              <a *ngIf="garageTile.buttonText && garageTile.buttonLink" class="btn btn-primary tile-button ga-event" [attr.href]="garageTile.buttonLink" [attr.target]="garageTile.buttonExternalLink ? '_blank' : null" [innerHTML]="garageTile.buttonText" data-ga_category="Never Lift Landing Page" data-ga_action="Click" [attr.data-ga_label]="garageTile.analyticsLabel"></a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<ng-template #videoModal>
  <div class="modal-container">
    <div class="modal-header">
      <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe [src]="safeURL" [class]="currentEmbedID" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="embed-responsive-item"></iframe>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #vimeoVideoModal>
  <div class="modal-container">
    <div class="modal-header">
      <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe [src]="safeURL" [class]="currentEmbedID" title="Vimeo video player"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="embed-responsive-item"></iframe>
      </div>
    </div>
  </div>
</ng-template>


