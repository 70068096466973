import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DragPakGalleries {
  vehicles: any = [
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-exterior-001.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-exterior-001.webp',
      'alt': 'Drag Pak Exterior 1'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/006-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/006-image.webp',
      'alt': 'Drag Pak 5'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-exterior-003.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-exterior-003.webp',
      'alt': 'Drag Pak Exterior 3'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-interior-001.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-interior-001.webp',
      'alt': 'Drag Pak Interior 1'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-exterior-002.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-exterior-002.webp',
      'alt': 'Drag Pak Exterior 2'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-exterior-004.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-exterior-004.webp',
      'alt': 'Drag Pak Exterior 4'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-exterior-005.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-exterior-005.webp',
      'alt': 'Drag Pak Exterior 5'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-underbody-001.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-underbody-001.webp',
      'alt': 'Drag Pak Underbody'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-interior-002.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-interior-002.webp',
      'alt': 'Drag Pak Interior 2'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-interior-003.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-interior-003.webp',
      'alt': 'Drag Pak Interior 3'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-interior-004.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-interior-004.webp',
      'alt': 'Drag Pak Interior 4'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-interior-005.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-interior-005.webp',
      'alt': 'Drag Pak Interior 5'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-interior-006.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-interior-006.webp',
      'alt': 'Drag Pak Interior 6'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-interior-007.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-interior-007.webp',
      'alt': 'Drag Pak Interior 7'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/thumbs/dragpak-interior-008.webp',
      'full': '/assets/images/drag-pak/gallery/dragpak-interior-008.webp',
      'alt': 'Drag Pak Interior 8'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/003-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/003-image.webp',
      'alt': 'Drag Pak 2'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/004-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/004-image.webp',
      'alt': 'Drag Pak 3'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/005-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/005-image.webp',
      'alt': 'Drag Pak 4'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/007-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/007-image.webp',
      'alt': 'Drag Pak 6'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/008-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/008-image.webp',
      'alt': 'Drag Pak 7'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/009-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/009-image.webp',
      'alt': 'Drag Pak 8'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/010-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/010-image.webp',
      'alt': 'Drag Pak 9'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/011-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/011-image.webp',
      'alt': 'Drag Pak 10'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/012-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/012-image.webp',
      'alt': 'Drag Pak 11'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/013-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/013-image.webp',
      'alt': 'Drag Pak 12'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/014-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/014-image.webp',
      'alt': 'Drag Pak 13'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/015-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/015-image.webp',
      'alt': 'Drag Pak 14'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/016-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/016-image.webp',
      'alt': 'Drag Pak 15'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/017-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/017-image.webp',
      'alt': 'Drag Pak 16'
    },
    {
      'thumb': '/assets/images/drag-pak/gallery/018-image-sm.webp',
      'full': '/assets/images/drag-pak/gallery/018-image.webp',
      'alt': 'Drag Pak 17'
    },
  ]

  colors: any = [
    {
      'thumb': '/assets/images/drag-pak/color-options/thumbs/dragpak-pitch-black.webp',
      'full': '/assets/images/drag-pak/color-options/dragpak-pitch-black.webp',
      'alt': 'Pitch Black'
    },
    {
      'thumb': '/assets/images/drag-pak/color-options/thumbs/dragpak-torred.webp',
      'full': '/assets/images/drag-pak/color-options/dragpak-torred.webp',
      'alt': 'TorRed'
    },
    {
      'thumb': '/assets/images/drag-pak/color-options/thumbs/dragpak-hellraisin.webp',
      'full': '/assets/images/drag-pak/color-options/dragpak-hellraisin.webp',
      'alt': 'Hellraisin'
    },
    {
      'thumb': '/assets/images/drag-pak/color-options/thumbs/dragpak-smoke-show.webp',
      'full': '/assets/images/drag-pak/color-options/dragpak-smoke-show.webp',
      'alt': 'Smoke Show'
    },
    {
      'thumb': '/assets/images/drag-pak/color-options/thumbs/dragpak-frostbite.webp',
      'full': '/assets/images/drag-pak/color-options/dragpak-frostbite.webp',
      'alt': 'Frostbite'
    },
  ]

  livery: any = [
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-whiteknuckle-dp-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-whiteknuckle-dp-color.webp',
      'alt': 'Drag Pak Color on White Knuckle'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-whiteknuckle-dp-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-whiteknuckle-dp-mono.webp',
      'alt': 'Drag Pak Mono on White Knuckle'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-whiteknuckle-dc-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-whiteknuckle-dc-color.webp',
      'alt': 'Direct Connection Color on White Knuckle'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-whiteknuckle-dc-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-whiteknuckle-dc-mono.webp',
      'alt': 'Direct Connection Mono on White Knuckle'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-pitchblack-dp-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-pitchblack-dp-color.webp',
      'alt': 'Drag Pak Color on Pitch Black'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-pitchblack-dp-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-pitchblack-dp-mono.webp',
      'alt': 'Drag Pak Mono on Pitch Black'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-pitchblack-dc-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-pitchblack-dc-color.webp',
      'alt': 'Direct Connection Color on Pitch Black'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-pitchblack-dc-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-pitchblack-dc-mono.webp',
      'alt': 'Direct Connection Mono on Pitch Black'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-torred-dp-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-torred-dp-color.webp',
      'alt': 'Drag Pak Color on TorRed'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-torred-dp-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-torred-dp-mono.webp',
      'alt': 'Drag Pak Mono on TorRed'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-torred-dc-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-torred-dc-color.webp',
      'alt': 'Direct Connection Color on TorRed'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-torred-dc-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-torred-dc-mono.webp',
      'alt': 'Direct Connection Mono on TorRed'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-hellraisin-dp-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-hellraisin-dp-color.webp',
      'alt': 'Drag Pak Color on Hellraisin'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-hellraisin-dp-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-hellraisin-dp-mono.webp',
      'alt': 'Drag Pak Mono on Hellraisin'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-hellraisin-dc-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-hellraisin-dc-color.webp',
      'alt': 'Direct Connection Color on Hellraisin'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-hellraisin-dc-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-hellraisin-dc-mono.webp',
      'alt': 'Direct Connection Mono on Hellraisin'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-smokeshow-dp-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-smokeshow-dp-color.webp',
      'alt': 'Drag Pak Color on Smoke Show'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-smokeshow-dp-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-smokeshow-dp-mono.webp',
      'alt': 'Drag Pak Mono on Smoke Show'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-smokeshow-dc-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-smokeshow-dc-color.webp',
      'alt': 'Direct Connection Color on Smoke Show'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-smokeshow-dc-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-smokeshow-dc-mono.webp',
      'alt': 'Direct Connection Mono on Smoke Show'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-frostbite-dp-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-frostbite-dp-color.webp',
      'alt': 'Drag Pak Color on Frostbite'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-frostbite-dp-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-frostbite-dp-mono.webp',
      'alt': 'Drag Pak Mono on Frostbite'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-frostbite-dc-color.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-frostbite-dc-color.webp',
      'alt': 'Direct Connection Color on Frostbite'
    },
    {
      'thumb': '/assets/images/drag-pak/livery-options/thumbs/dragpak-livery-frostbite-dc-mono.webp',
      'full': '/assets/images/drag-pak/livery-options/dragpak-livery-frostbite-dc-mono.webp',
      'alt': 'Direct Connection Mono on Frostbite'
    },
  ]
}