
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Race } from './race';
import {parseString} from "xml2js";

@Injectable()
export class RaceService {

  constructor(
    private http: HttpClient,
  ) { }

  getRaces(max: number = 10, offset: number = 0, startDate: string, endDate: string, event: number = null): Observable<Race[]> {
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('max', max.toString());
    Params = Params.append('offset', offset.toString());
    if (startDate) {
      Params = Params.append('startDate', startDate);
    }
    if (endDate) {
      Params = Params.append('endDate', endDate);
    }
    if (event) {
      Params = Params.append('event', event.toString());
    }

    Params = Params.append('getMethod', 'raceWithUniqueUser');
    const url = environment.url+'/rest/race/';
    return this.http.get<Race[]>(url, { params: Params }).pipe(
      map( results => {
        results['races'] = this.removeFaultedRaces(results['races']);
        return results;
      }));
  }

  removeFaultedRaces(races: Race[] = []) {
    return races.filter( race => {
      if (race.reactionTime != 99999 && race.raceTime != 99999 && race.speed != 99999) {
        return race
      }
    })
  }

}
