<div id="feature-slider">
  <div class="container-fluid p-0">
    <div [ngClass]="{ 'mobile-hidden' : mobileView }" class="feature-wrapper col-sm-12 p-0">
      <div class="carousel-images">
        <div class="item end"></div>
        <div class="item" [ngClass]="{'active': activeSlide === i}" *ngFor="let slide of slides; index as i">
          <div class="image ga-event" [ngClass]="{'active': activeSlide === i}" (click)="goToSlide(i)" data-ga_category="Feature SLider" data-ga_action="Image Click" attr.data-ga_label="{{ slide.heading }}">
            <img [src]="slide.image" [alt]="slide.heading">
          </div>
          <div class="content">
            <div class="indicators">
              <span class="indicator" [ngClass]="{'active': activeSlide === 0}" (click)="goToSlide(0)"></span>
              <span class="indicator" [ngClass]="{'active': activeSlide === 1}" (click)="goToSlide(1)"></span>
              <span class="indicator" [ngClass]="{'active': activeSlide === 2}" (click)="goToSlide(2)"></span>
              <span class="indicator" [ngClass]="{'active': activeSlide === 3}" (click)="goToSlide(3)"></span>
            </div>
            <img class="slide-logo" *ngIf="slide.logo" [src]="slide.logo" [alt]="slide.logoAlt" />
            <h3 class="slide-heading" *ngIf="!slide.logo" [innerHTML]="slide.heading">Slide Heading</h3>
            <p class="slide-description" [innerHTML]="slide.description">Slide Description</p>
            <a [href]="slide.link" [attr.target]="slide.external ? '_blank' : null"  class="btn btn-primary slide-cta ga-event" data-ga_category="Feature SLider" data-ga_action="Click" attr.data-ga_label="{{ slide.heading }}" [innerHTML]="slide.cta">Slide Cta</a>
          </div>
        </div>
        <div class="item end"></div>
      </div>
    </div>
    <div [ngClass]="{ 'hide-mobile' : !mobileView }" class="feature-wrapper col-sm-12 p-0">
      <div class="carousel-container">
        <swiper data-id="feature-carousel" #swiper [config]="config" (slideChange)="onSlideChange($event)">
          <ng-container *ngFor="let slide of slides; index as i">
            <ng-template swiperSlide>
              <div class="slide-container">
                <img class="slide-logo" *ngIf="slide.logo" [src]="slide.logo" [alt]="slide.logoAlt" />
                <h3 class="slide-heading" *ngIf="!slide.logo" [innerHTML]="slide.heading">Slide Heading</h3>
                <p class="slide-description" [innerHTML]="slide.description">Slide Description</p>
                <a [href]="slide.link" [attr.target]="slide.external ? '_blank' : null"  class="btn btn-primary slide-cta ga-event" data-ga_category="Feature SLider" data-ga_action="Click" attr.data-ga_label="{{ slide.heading }}" [innerHTML]="slide.cta">Slide Cta</a>
              </div>
            </ng-template>
          </ng-container>
        </swiper>
      </div>
    </div>
    <div *ngIf="isRunning" class="toggle-auto-play">
      <button (click)="toggleSlider()" class="btn-link" name=""><span class="sr-only">Pause</span><i class="fa fa-pause-circle fa-2x" [ngClass]="{ 'text-primary' : mobileView, 'text-white' : !mobileView }"></i></button>
    </div>
    <div *ngIf="!isRunning" class="toggle-auto-play">
      <button (click)="toggleSlider()" class="btn-link" ><span class="sr-only">Play</span><i class="fa fa-play-circle fa-2x" [ngClass]="{ 'text-primary' : mobileView, 'text-white' : !mobileView }"></i></button>
    </div>
  </div>
</div>
