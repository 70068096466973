import { Injectable } from '@angular/core';
import { TRIMS } from './trim-data';

@Injectable()
export class HPLTrimService {


    getSpecialEditionsByModel(model: string){
      return TRIMS.filter((element) => 
      {
        return element.model == model && element.type == 'se'
      })
    }

    getTrimsByModel(model: string){
      return TRIMS.filter((element) => 
      {
        return element.model == model && element.type == 'trim'
      })
    }


  }
