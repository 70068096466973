import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'horsepower-locator-hero',
  templateUrl: './horsepower-locator-hero.component.html',
  styleUrls: ['./horsepower-locator-hero.component.less']
})
export class HPLHeroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  sendGTMEvent(): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'hpl.homepage-banner.click',
      eventCategory: 'Hero',
      eventAction: 'Click',
      eventLabel: 'HPL Homepage Hero Banner'
    });
  }

}
