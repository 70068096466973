
import {catchError} from 'rxjs/operators';
import {Injectable, EventEmitter, Output} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { HelperService } from '../services/helper.service';
import { Router } from '@angular/router';
import { UserModel } from './user';
import { Observable , BehaviorSubject, throwError } from "rxjs";
import {environment} from "../../environments/environment";
import {CookieService} from 'ngx-cookie-service';



declare var hideShowModal: Function;
declare var hideModal: Function;
declare let ga: Function;
declare var $: any;
declare let Ecwid: any;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class UserService {
  userModel = new UserModel();
  loggedIn: boolean;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  userStatusChange: EventEmitter < boolean > = new EventEmitter();
  userLocationFound: EventEmitter < boolean > = new EventEmitter();

  // Activation
  activationStatusCheck: boolean = false;
  activationSuccess: boolean = false
  activationStatusMessages: Array<string> = []
  activationContext = 'danger';
  activationHeader = 'Oops!';

  // Password Reset
  passwordResetStatusCheck: boolean = false;
  passwordResetSuccess: boolean = false;
  passwordResetStatusMessages: Array<string> = [];
  passwordResetContext = 'default';

  // No Racing Pledge Variables
  noRacingPledgeCheckbox: boolean = false;
  noRacingPledgeModalOpened: boolean = false;
  noRacingPledgeErrorMessage: string = '';
  noRacingPledgeSuccess: boolean = false;

  constructor(
    private helperService: HelperService,
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
  ) {}

  emitUserStatusChange(loggedIn) {
    this.userStatusChange.emit(loggedIn);
  }

  emitUserLocationFound(found) {
    this.userLocationFound.emit(found);
  }

  getUserStatusChangeEmitter() {
    return this.userStatusChange;
  }

  getUserLocationFoundEmitter() {
    return this.userLocationFound;
  }

  checkUsername(username: string): Observable<any> {
    let url = environment.url + '/rest/user/usernameLookup';
    let params = new FormData;
    params.append('username', username);
    return this.http.post(url, params);
  }

  public forgotPassword() {
    let url = environment.url+'/rest/user/forgotPassword';
    let params = new FormData;
    if (this.userModel.email) { params.append('email', this.userModel.email); }
    return this.http.post(url, params);
  }

  public passwordReset($event) {
    let url = environment.url+'/rest/user/resetPassword';

    let params = new FormData;
    if (this.userModel.resetToken) { params.append('resetToken', this.userModel.resetToken); }
    if (this.userModel.password) { params.append('password', this.userModel.password); }
    if (this.userModel.confirmPassword) { params.append('confirmPassword', this.userModel.confirmPassword); }

    this.http.post(url, params).subscribe(data => {
      let response:any = data;
      this.passwordResetContext = response.validation.status;
      this.passwordResetStatusMessages = response.validation.message;
      if (response.validation.status == 'success') {
        this.userModel.username = response.user.username;
        this.postLogin(this.userModel).subscribe( data => {
          this.processLogin(data);
        });
      }
    });
  }

  postRegister(user: UserModel) {
    let url = environment.url+'/rest/user/register';
    let event = localStorage.getItem('DGEventRegistrationSource');
    let params = new FormData;
    params.append('firstName', user.firstName);
    params.append('lastName', user.lastName);
    params.append('email', user.email);
    params.append('zipCode', user.zipCode);
    params.append('country', user.country);
    params.append('username', user.username);
    params.append('password', user.password);
    params.append('confirmPassword', user.confirmPassword);
    params.append('optInNews', user.fcaCommunication);
    params.append('optInEvents', user.eventCommunication);
    // check for event registration and append
    if(event) {
      params.append('source', event);
      console.info(`Event registration source detected, appending '${event}' to user registration form.`);
    }
    return this.http.post<any>(url, params);
  }

  public postLogin(user: UserModel): Observable<any> {
    let url = environment.url+'/rest/api/login';
    return this.http.post(url, {username:user.username, password:user.password});
  }

  getUserByToken(creationToken): Observable<any> {
    let url = environment.url+'/rest/user/activation-token/'+creationToken;
    return this.http.get(url);
  }

  postCompleteRegistration(user: UserModel, creationToken) {
    let url = environment.url+'/rest/user/completeRegistration';
    let params = new FormData;
    params.append('firstName', user.firstName);
    params.append('lastName', user.lastName);
    params.append('email', user.email);
    params.append('zipCode',user.zipCode);
    params.append('country',user.country);
    params.append('username', user.username);
    params.append('password', user.password);
    params.append('confirmPassword', user.confirmPassword);
    params.append('optInNews', user.fcaCommunication);
    params.append('optInEvents', user.eventCommunication);
    params.append('token', creationToken);

    return this.http.post<any>(url, params);
  }

  public submitChangePassword(user: UserModel): Observable <any> {
    let data = new FormData();
    data.append('password', user.password);
    data.append('newPassword', user.newPassword);
    data.append('confirmNewPassword', user.confirmNewPassword);
    let url = environment.url + '/rest/user/changePassword';
    return this.http.post(url, data);
  }

  public submitUserDetails(user: UserModel): Observable<any> {
    let data = new FormData();
    data.append('email', user.email);
    data.append('firstName', user.firstName);
    data.append('lastName', user.lastName);
    data.append('zipCode', user.zipCode);
    data.append('country', user.country);
    data.append('optInNews', user.fcaCommunication);
    data.append('optInEvents', user.eventCommunication);
    let url = environment.url + '/rest/user/updateProfile';
    return this.http.post(url, data);
  }

  public activation() {
    // We have 4 possibilities: if user is logged in and activated, if user is logged in and not activated, if not logged in and activated, if not logged in and not successful
    if ((localStorage.getItem('user_access') != null) && (new Date().getTime() < parseInt(localStorage.getItem('user_access')['access_expiration']))) { // If User is logged in
      return this.getUser().subscribe(userModel => {

        if (userModel['isActivated']) { // We already know that the user is activated
          this.activationContext = 'success';
          this.activationHeader = 'You\'re All Set.';
          this.activationStatusMessages = ['Your account has already been activated.'];
          // Display Banner
          this.activationStatusCheck = true;
          return;
        } else { // We need to send the activation for the logged in user
          this.sendActivation().subscribe(response => {
            this.setActivationStatus(response);
          });
        }

      });
    } else {
      // The user is not logged in
      this.sendActivation().subscribe(response => {
        this.setActivationStatus(response);
      });
    }
  }

  public sendActivation(): Observable <any> {
    let params = new FormData;
    params.append('activationToken', this.getParameterByName('activationToken'));

    let url = environment.url+'/rest/user/activation';
    return this.http.post(url, params);
  }

  public setActivationStatus(response) {
    this.activationSuccess = (response.validation.status == 'success');
    this.activationContext = (response.validation.status == 'success')?'success':'danger';
    this.activationHeader = (response.validation.status == 'success')?'Congratulations!':'Oops';
    this.activationStatusMessages = response.validation.message;
    // Display Banner
    this.activationStatusCheck = true
  }

  public resendActivation() {
    let url = environment.url+'/rest/user/resendActivation';
    let params = new FormData;
    if (this.userModel.email) { params.append('email', this.userModel.email); }
    return this.http.post(url, params);
  }

  public sendReactivation(accessToken: string) {
    let url = environment.url+'/rest/user/sendReactivation';
    const formData = new FormData();
    formData.append('Authorization', 'Bearer ' + accessToken);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('enctype', 'application/x-www-form-urlencoded');
    headers = headers.append('Authorization', 'Bearer ' + accessToken);
    return this.http.get(url, { headers: headers });
  }

  public sendLoginHistoryPing(): Observable<any> {
    // if we have a token and user is logged in
    if (localStorage.getItem('user_access') != null && this.userModel.username) {
      // If the Access Token Exists, we need to check if it expired
      let user_access = JSON.parse(localStorage.getItem('user_access'));
      let tokenInfo = this.decodeAccessToken(user_access.access_token);
      let url = environment.url+'/rest/user/login-history';
      return this.http.post(url, tokenInfo);
    }
  }

  public deactivateAccount(): Observable<any> {
    let url = environment.url+'/rest/user/deactivate';
    let params = new FormData;
    return this.http.post(url, params);
  }

  public logout() {
    localStorage.removeItem('user_access');
    localStorage.removeItem('newsArticleLikes');
    this.cookieService.delete('UserLocationCheck');
    this.userModel = new UserModel();

    this.emitUserStatusChange(false);

    this.activationStatusCheck = false;
    let url = environment.url+'/rest/logoff';
    this.http.get(url);
    this.setLoggedIn(false);
    this.router.navigate(['/']).then(() => {
      // force reload to address dropdown bug DG-29413
      location.reload();
    });

  }

  getUser():Observable<any>{
    let url = environment.url+'/rest/user';
    return this.http.get(url).pipe(
      catchError((error: Response | any) => {
        console.log(error);
        return throwError(new Error('User not logged in'));
      }));
  }

  getUserByUsername(username):Observable<any> {
    let url = environment.url + '/rest/user/get/' + encodeURIComponent(username);
    return this.http.get(url, {headers: new HttpHeaders()})
  }

  isLoggedIn() { // Checks if user is logged in and updates their info if they are
    if (localStorage.getItem('user_access') != null) {
      // If the Access Token Exists, we need to check if it expired
      let user_access = JSON.parse(localStorage.getItem('user_access'));
      if (new Date().getTime() < parseInt(user_access.access_expiration)) {
        return this.getUser()
          .subscribe(
            userModel => {

              this.setUserInfo(userModel);

              if(!this.userHasSetLocation()) {
                this.emitUserLocationFound(false);
              }

              // this closes the modal
              this.emitUserStatusChange(true);

              // Log that user is logged in (for if statements)
              this.userModel.isLoggedIn = true;

              // Set Login Status
              this.setLoggedIn(true);
            },
            err => {
              //console.log(err);
              // User is not logged in
            });
      } else {
        // Perform logout functionality without the reroute
        localStorage.removeItem('user_access');
        //localStorage.removeItem('newsArticleLikes');
        this.userModel = new UserModel();
        this.emitUserStatusChange(false);
        this.activationStatusCheck = false;
        return new UserModel();
      }

    } else {
      localStorage.removeItem('user_access');
      //localStorage.removeItem('newsArticleLikes');
      this.userModel = new UserModel();
      return new UserModel();
    }

  }

  setLoggedIn(value: boolean) {
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
  }

  submitRefreshToken(refreshToken):Observable <any> {

    let headers = new HttpHeaders();
    headers.append('enctype', 'application/x-www-form-urlencoded');

    const formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);

    let url = environment.url+'/rest/oauth/access_token';
    return this.http.post(url, formData, {headers:headers})
  }

  postActivateRaces(userId: number): Observable <any> {
    let url = environment.url+'/rest/user/'+userId+'/races/activate';
    const formData = new FormData();
    formData.append('userId', userId.toString());
    return this.http.post(url, formData)
  }

  processLogin(loginData) {
    // Store Access Token
    let access_expiration = new Date().getTime() + (parseInt(loginData['expires_in']) * 1000);
    let user_access = JSON.stringify({
      access_token: loginData['access_token'],
      access_expiration: access_expiration.toString(),
      refresh_token: loginData['refresh_token']
    });
    localStorage.setItem('user_access', user_access);
    // Set in localStorage
    localStorage.setItem('access_token', loginData['access_token']);
    localStorage.setItem('access_expiration', access_expiration.toString()); // Not needed anymore as this is also stored in the access_token
    localStorage.setItem('refresh_token', loginData['refresh_token']);

    // We know the username, so let's make sure that is added to the UserModel
    this.userModel.username = loginData.username;

    // Send to isLogged in to fetch and set all other user info
    this.isLoggedIn();

    // Just to be secure, let's remove passwords from the User class
    this.userModel.password = null;
    this.userModel.confirmPassword = null;

    // Log that user is logged in (for if statements)
    this.userModel.isLoggedIn = true;

    // If activation banner is visible, hide it
    if (this.activationStatusCheck) {
      this.activationStatusCheck = false;
    }

    // Let's record our login history!
    this.sendLoginHistoryPing().subscribe((res) => {
      //console.log(res); we don't need to do anything
    });
  }

  isAdminUser() {
    if (localStorage.getItem('user_access') != null) {
      // If the Access Token Exists, we need to check if it expired
      let user_access = JSON.parse(localStorage.getItem('user_access'));
      let tokenInfo = this.decodeAccessToken(user_access.access_token);
      let isAdmin = false;
      if (tokenInfo.roles.includes('ROLE_ADMIN'))
        isAdmin = true;
      if (isAdmin) {
        return true;
      }
      return false;
    } else {
      console.log('not logged in');
      return false;
    }
  }

  isInactiveUser(loginData) {
    if (loginData.roles.includes('ROLE_INACTIVE')) {
      return true;
    } else {
      return false;
    }
  }

  decodeAccessToken(accessToken) {
    /*
    The access token contains user data when decoded:
      exp: time to expire
      roles: ["ROLE_ADMIN","ROLE_USER","ROLE_VERIFIED_USER"]
      sub: username
      Also contains iat and principal (unsure what they can be used for - mcrandell
     */
    let base64Url = accessToken.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  public get(prop ? : any) {
    const galleryObj = this.userModel;
    return galleryObj.hasOwnProperty(prop) ? galleryObj[prop] : galleryObj;
  }

  public set(prop: string, value: any) {
    this.userModel[prop] = value;
    return this.userModel[prop] = value;
  }

  private handleError (error: Response | any) {
    console.error(error.message || error);
    return throwError(new Error(error.message || error));
  }

  getParameterByName(name) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  setUserInfo(user) {
    if (user['muut']) {
      // Set Muut Credentials
      this.set('muutMessage', user['muut']['message']);
      this.set('muutSignature', user['muut']['signature']);
      this.set('muutTimestamp', user['muut']['timestamp']);
      this.set('muutTimeout', Date.now() + (15 * 60 * 1000));
    }

    //Set User Info
    this.set('id', user['id']);
    this.set('firstName', user['firstName']);
    this.set('lastName', user['lastName']);
    this.set('email', user['email']);
    this.set('zipCode', user['zipCode']);
    this.set('country', user['country']);
    this.set('gravatar', user['gravatar']);
    this.set('username', user['username']);
    this.set('driverProfileRequested', user['driverProfileRequested']);
    this.set('vipCode', user['vipCode']);
    this.set('noRacingPledge', user['noRacingPledge']);
    this.set('isActivated', user['isActivated']);
    this.set('fcaCommunication', user['optInNews']);
    this.set('eventCommunication', user['optInEvents']);
    this.set('isPublic', user['isPublic']);
    this.set('newsArticleLikes', user['newsArticleLikes']);
    // TODO Add logic to update the localstorage for user article likes
  }

  trackGAevent(category, label, $event = null) {
    // If we need to track the event, make sure its the right event
    // This is needed so we can detect the modal being dismissed
    if (!$event || $event.currentTarget === $event.target) {
      // If we cannot find the previous action, assume it is the header
      if (localStorage.getItem('gaAction') === null) {
        var gaAction = 'Header'
      } else {
        var gaAction = localStorage.getItem('gaAction');
      }
      ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: gaAction,
        eventLabel: label
      });
    }
  }

  postNoRacingPledge(user: UserModel): Observable<any> {
    let url = environment.url+'/rest/user/submitNoRacingPledge';
    let params = new FormData;
    params.append('firstName', user.firstName);
    params.append('lastName', user.lastName);
    params.append('email', user.email);
    return this.http.post<any>(url, params);
  }

  getContestStatus(contestId):Observable<Object>{
    let url = '/rest/user/contestStatus/'+contestId;
    return this.http.get(url).pipe(catchError(this.helperService.handleError));
  }

  clearMessages() {
    this.userModel.errorMessage = '';
    this.userModel.successMessage = '';
    this.userModel.passwordErrorMessage = '';
    this.userModel.passwordSuccessMessage = '';
    this.userModel.driverProfileErrorMessage = '';
    this.userModel.driverProfileSuccessMessage = '';
  }

  public addToLoginHistory() {
    let url = environment.url+'/rest/user/addToLoginHistory';

    let Params = new HttpParams();
    Params.append('username', this.userModel.username);
    Params.append('userAgent', window.navigator.userAgent);

    let params = new FormData;
    params.append('username', this.userModel.username);
    params.append('userAgent', window.navigator.userAgent);

    if (this.userModel.username) { // Only do action if user is logged in
      this.http.post(url, params).subscribe(data=> {
        //console.log(data);
      })
    } else {
      //console.log('Not Logged In');
    }
  }

  public showSignUoModal() {
    hideShowModal('.create-form');
  }

  public userHasSetLocation() {
    if(this.userModel.country === 'US') {
      return this.userModel.country && this.userModel.zipCode;
    } else return this.userModel.country;
  }
}
