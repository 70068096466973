import {Race} from "../race/race";

export type InteralStateType = {
  [key: string]: any
};

export class Event {
  id: number;
  name: string;
  description: string;
  date: any;
  address: string;
  city: string;
  zipcode: any;
  postalCode: string;
  state: any;
  country: string;
  website: string;
  keyword: any;
  loaded: any = false;
  geoLat: any;
  geoLng: any;
  latitude: number;
  longitude: number;
  offset: any;
  category: any = '';
  eventData:any = '';
  filtered: any = false;
  markers: Array<any> = [];
  pastMarkers: Array<any> = [];
  upcomingMarkers: Array<any> = [];
  pastevents:any = [];
  featuredImage: string;
  featuredImageThumbnail: string;
  logo: string;
  startDate: any;
  endDate: any;
  venue: string;
  month: any;
  year: any;
  races: Race[] = [];
  path: string;
}
