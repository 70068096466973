import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'dg-livestream',
  templateUrl: './livestream.component.html',
  styleUrls: ['./livestream.component.scss']
})
export class LivestreamComponent implements OnInit {

  streamName = 'Watch the Nitrocross Action Live October 5 - 6'
  promoBanner: any = document.querySelector('#livestream-banner')
  logos = [
    '/assets/images/livestream/logo-nitrocross.webp'
  ]

  constructor(
    public route: ActivatedRoute,
    public title: Title,
    public metaService: Meta,
    private router: Router
  ) {
    title.setTitle( 'Livestream | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'Welcome to Dodge Garage' })
    metaService.updateTag({ property: 'og:description', content: 'Welcome to Dodge Garage' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage - Livestream' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
    this.promoBanner ? this.promoBanner.style.display = 'none' : null
  }

  ngOnDestroy() {
    this.promoBanner ? this.promoBanner.style.display = 'block' : null
  }

}
