<div id="rkn-page">

    <div class="rkn-hero">
        <div class="site-wrapper container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="hero-inner">
                        <div class="content">
                            <div class="logos">
                                <img src="/assets/images/rkn/roadkill-nights-logo.webp" alt="Roadkill Nights - Logo" width="300" height="135">
                                <img src="/assets/images/rkn/direct-connection-logo.webp" alt="Direct Connection - Logo" width="220" height="45">
                            </div>
                            
                            <h1 class="hero-heading">Grudge Match 2024</h1>
                            <img class="img-heading img-responsive" src="/assets/images/rkn/2024/heading-grudge-match-2024.webp" alt="Grudge Match 2024" width="768" height="131"/>
                            <p class="mb-5">
                                <strong>It's Grudge Match #4</strong> — tougher, faster and with more ill will. Six racers — including three who are pushing Roadkill Legend status — will chase the ultimate Dodge bragging rights. The champ will be crowned when our racers slug it out on Woodward Avenue this summer. Connect with each racer's social channels and follow along as they build their custom rides under ever-increasing pressure.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="podcasts">
        <div class="site-wrapper container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="podcast-container">
                        <div *ngFor="let podcast of podcasts; let i = index" 
                            class="podcast" 
                            (click)="openModal(videoModal, podcast.videoId, podcast.title, podcast.description)">
                            <img [src]="podcast.thumbnail" [alt]="'Thumbnail Image for ' + podcast.title" width="376" height="221">
                            <div class="desc">
                                <span class="title text-uppercase">{{ podcast.title }}</span>
                            </div>
                        </div>
                    </div> 
                </div>

                <div class="col-sm-12 text-center">
                    <h2 class="racer-heading mb-0">Follow our racers' builds</h2>
                </div>
            </div>
        </div>
    </div>
            
    <div class="slider pt-5">
        <div class="site-wrapper container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div *ngIf="racers.length > 1" class="carousel-indicators"></div>
                    <swiper #swiper [config]="config" (slideChange)="onSlideChange($event)">
                        <ng-template swiperSlide *ngFor="let racer of racers; let i = index" >
                            <div class="slide-container">
                                <div class="slide-header">
                                    <h3 class="slide-heading">{{ racer.firstName}}<br>{{ racer.lastName }}</h3>
                                    <div class="socials">
                                        <span class="social-header">Track {{ racer.firstName}}'s build!</span>
                                        <div class="icons">
                                            <a *ngFor="let item of racer.social" target="_blank" class="social-icon" [href]="item.url" [title]="'Social Icon: ' + item.platform">
                                                <rkn-social-icons [property]="item.platform"></rkn-social-icons>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="slide-content">
                                    <img [src]="racer.image" [alt]="racer.firstName + ' ' + racer.lastName + ' - Grudge Match 2024 participant'">
                                    <div class="description">
                                        {{ racer.bio }}
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </swiper>
                    <div *ngIf="racers.length > 1" class="carousel-navigation">
                        <button id="carousel-next" class="carousel-button" type="button" aria-label="Carousel Slide Previous"><i class="fa fa-angle-right"></i></button>
                        <button id="carousel-prev" class="carousel-button" type="button" aria-label="Carousel Slide Next"><i class="fa fa-angle-left"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="revisit">
        <div class="site-wrapper container-fluid">
            <div class="row">
                <div class="col-sm-12 revisit-inner">
                    <p class="subheading">Revisit</p>
                    <div class="btn-container">
                        <a href="/roadkill-nights-2023" class="btn btn-primary">
                            Grudge Match 2023
                        </a>
                        <a href="/roadkill-nights-2022" class="btn btn-primary">
                            Grudge Match 2022
                        </a>
                        <a href="/roadkill-nights-2021" class="btn btn-primary">
                            Grudge Match 2021
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #videoModal>
        <div id="rkn-video-modal" class="modal-container">
            <div class="modal-header">
                <button class="modal-close" (click)="closeModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
            </div>
            <div class="modal-body site-wrapper">
                <div class="embed-responsive embed-responsive-16by9"></div>
            </div>
            <div class="modal-footer" style="text-align:left !important; color: white !important;">
                <p class="mb-0" style="font-size: 14px;">{{ activePodcastDescription }}</p>
            </div>
        </div>
    </ng-template>
        
</div>

<ngx-json-ld [json]="schema"></ngx-json-ld>





