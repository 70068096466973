<article class="card card-event">
  <a [href]="eventPath" (click)="goToEvent(event, $event)" class="ga-event" data-ga_category="Events" attr.data-ga_action="{{ event.name }}" data-ga_label="Event Tile">
    <div class="tile-container">

      <div class="event-img" (deferLoad)="isEventImageLoaded = true">
        <span class="date-label">
          <span class="month">{{event.startDate | date: 'MMM': 'UTC'}}</span>
          <span class="day">{{event.startDate | date: 'dd': 'UTC'}}</span>
        </span>
        <ng-container *ngIf="isEventImageLoaded">
          <img loading="lazy" class="img-responsive ga-event" src="{{ event.featuredImageThumbnail }}" alt="{{ event.name }}" data-ga_category="Events" attr.data-ga_action="{{ event.name }}" data-ga_label="Event Tile">
        </ng-container>
      </div>
      <div class="event-details ga-event" data-ga_category="Events" attr.data-ga_action="{{ event.name }}" data-ga_label="Event Tile">
        <span class="event-time">
          {{event.startDate | date: 'MMM dd': 'UTC'}}
          <ng-container *ngIf="(event.startDate.getMonth() + '-' + event.startDate.getDate()) != (event.endDate.getMonth() + '-' + event.endDate.getDate())">
          - {{event.endDate | date: 'MMM dd': 'UTC'}}
          </ng-container>
        </span>
        <h1 class="heading1 event-name ga-event" data-ga_category="Events" attr.data-ga_action="{{ event.name }}" data-ga_label="Event Tile">{{ event.name }}</h1>
        <span class="event-location"><i class="fa fa-map-marker"></i>
        <ng-container *ngIf="event.country === 'US' || event.country === 'CA'; else internationalLocation">
          {{ event.city }}, {{ event.state }}
        </ng-container>

        <ng-template #internationalLocation>
          {{ event.city }} {{ event.state }}, {{ event.country }}
        </ng-template>
        </span>
      </div>
    </div>
  </a>
</article>
