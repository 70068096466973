<div class="row">
  <div class="col-sm-12">

    <div class="row mb-4">
      <div class="col-sm-12">
        <h2>2021 Drag Pak Build overview</h2>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-12 wrapped-image">
        <p>
          <img src="/assets/images/drag-pak-experience/build-overview/1.webp" alt="2021 Drag Pak - Chassis" class="img-responsive pull-left" width="1006" height="516">

          The 2021 Dodge Challenger Mopar<sub>&reg;</sub> Drag Pak is a purpose-built vehicle designed and engineered for sportsman racers – a turn-key package loaded with suspension and chassis upgrades certified for NHRA competition. With limited production of 50 serialized vehicles, it marks another significant milestone in the Drag Pak lineage.
          <br><br>
          Built for off-road use only and not street legal, the 2021 Drag Pak is sold "as-is" and without a warranty. To achieve the maximum on-track performance from your vehicle, please follow the recommended maintenance and operating procedures outlined here. 
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mb-5">
        <img src="/assets/images/drag-pak-experience/build-overview/2.webp" alt="Build Overview - Step 2" class="img-responsive" width="1840" height="920">
      </div>
      <div class="col-sm-12 mb-5">
        <p>
          This is the quickest, fastest and most powerful Drag Pak ever built, and has been hand assembled using suspension, chassis and powertrain components that utilize the latest in drag racing technology. At the heart of the new Drag Pak is the latest evolution of the supercharged 354-cubic-inch Gen-III HEMI<sup>&reg;</sup> V8 engine, based on the same power plant that won the 2018 NHRA Factory Stock Showdown (FSS) and NMCA Factory Supercar championships. Numerous enhancements include an all-new 3.0-liter Whipple twin-screw supercharger, upgraded forged internals, new front accessory drive and a new fabricated aluminum ice tank with a clear lid that cools the supercharger intercooler. The engine combination has been fully validated by Dodge//SRT<sup>&reg;</sup> and Mopar engineers on the dyno as well as the drag strip, and is approved for the <a href="http://nhraracer.com/" target="_blank">NHRA</a> and <a href="https://www.nmcadigital.com/" target="_blank">NMCA</a> classes. 
        </p>
        <p>
          A unique, serialized Drag Pak badge sits atop the billet aluminum supercharger cover. Also under the aluminum hood, enthusiasts will see a nod to past 1970-1974 ’Cuda and Challenger heritage models, with a special red and brushed aluminum radiator support tag adorned with the silhouette of the Challenger stating the serial number, vehicle code, axle ratio and engine designation – all unique to the Drag Pak. 
        </p>
        <p class="quote h2 pull-right">
          <span class="left-quote">&ldquo;</span>This is the quickest, fastest and
          most powerful Dodge Challenger Mopar Drag Pak ever built&hellip;
          <span class="right-quote">&rdquo;</span>
        </p>
        <p>
          The all-new four-link rear suspension is engineered to launch hard, quickly decimate the 60-foot elapsed times and stay planted all the way down the quarter-mile. A new geometry setup with longer control arms increases the length of the rear swing arm, allowing better anti-squat control and increasing stability. It also incorporates a wishbone link for lateral control replacing a Panhard bar in prior models and a larger diameter anti-roll bar that’s 250 percent stiffer than prior models. 
        </p>
        <p>
          Up front, a unique cradle underpins the front suspension, featuring integrated solid engine mounts and a cutout for easy oil pan removal. 
        </p>
        <p>
          New for 2021 are exclusive double-adjustable Drag Pak-specific tuned Bilstein coilover shock absorbers supporting all four corners of the car. The new shocks improve weight transfer and body attitude for hard launches, as well as soak up bumps and undulations at high speeds down the track. The shocks feature 10 settings (front and rear) for easy compression tuning and rebound damping, allowing racers to adjust for diverse track conditions by simply turning the color-coded knobs. 
        </p>
        <p>
          Strange Engineering Pro Series II racing brakes on the front and rear provide ample stopping power after the finish line, with minimal losses due to brake drag on the way there.
        </p>
        <p>
          A certified roll cage fabricated to SFI Specification 25.5C (NHRA-certified 7.50-second ET) handles the rigorous safety requirements of factory stock racing and improves performance.
        </p>
        <p>
          The NHRA-certified roll cage is constructed from 4130 chromoly tubing and is fully TIG-welded, ensuring greater safety and integrity. The cage is fully integrated with the factory unibody through attachment points at the front and rear shock towers, B-pillars, vehicle floor and rocker panels. The chassis is designed to work in combination with the rear suspension, channeling the forces from the control arms and coilover shocks directly into the roll cage, versus the body panels. The increased stiffness allows the new Drag Pak to be more responsive to tuning changes, enabling racers to extract more performance in the quarter-mile. 
        </p>
        <p>
          Out back, a trunk-mounted, SFI-certified three-gallon drag race fuel cell feeds a brushless racing fuel pump with dual inline filters. Axle-mounted wheelie bars are standard, as is a 10-foot parachute mounted rigidly to the frame rails via a unique tubular mount.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 wrapped-image">
        <img loading="lazy" src="/assets/images/drag-pak-experience/build-overview/3.webp" alt="Build Step 3" class="img-responsive pull-left" width="1840" height="920">
        <p>
          Improved interior features include advanced, race-ready technology that balances race car function with factory comfort and technology. The instrument panel features a carbon fiber bezel with three primary gauges: RPM, brake pressure and oil pressure. The driver bezel also features a Holley EFI Digital Dash with a 7-inch, low-glare, full-color touchscreen display so drivers can tune and manage vehicle performance.
        </p>
        <p>
          On the passenger side, a custom red and brushed aluminum Drag Pak serialized badge signifies the one-of-50 build serial numbers. Driver comfort is achieved with the adjustable mounts that are included for the seats and racing pedals. Driver control is delivered through a mechanical floor shifter and lightweight steering column with a quick-release steering wheel.
        </p>
        <p>
          An all-new motorsport electrical architecture setup includes the brains of the supercharged HEMI powertrain with the programmable Holley Dominator Electronic Fuel Injection (EFI) vehicle management system. In addition, a programmable Racepak Smartwire Power Distribution Module controls all electrical components using solid state electronics, which eliminates the need for fuses and relays. These electronic modules are conveniently located in the glove compartment, mounted to the body using mounts that allow for easy service. 
        </p>
        <p>
          A choice of lightweight Racetech composite seats and Racetech HANS-ready cam-lock harnesses are FIA- and SFI-compliant. Both driver and passenger seats feature the Drag Pak logo embroidered on the seat back, while the optional Head Restraint Seat Package features a winged driver seat.
        </p>
        <p>
          Rounding out the Factory Stock racing class feel on the interior are custom-cut lightweight carpeting, carbon fiber tube protectors and SFI-compliant roll bar padding.
        </p>
        <p>
          Care and attention should be paid when transporting the Drag Pak. Starting with the vehicle in park, securely strap the vehicle down using identified tie down locations on the front engine cradle and rear axle. 
        </p>
      </div>
    </div>

    <div class="row mt-5 mb-5">
      <div class="col-sm-6">
        <img loading="lazy" src="/assets/images/drag-pak-experience/build-overview/4.webp" alt="Build Step 4" class="img-responsive" width="1140" height="600">
      </div>
      <div class="col-sm-6">
        <img loading="lazy" src="/assets/images/drag-pak-experience/build-overview/5.webp" alt="Build Step 5" class="img-responsive" width="1140" height="600">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p>
          Once securely tied down, shift the vehicle into Neutral to avoid transmission damage during transport. Always confirm the main power is shut off.
        </p>
        <p>
          It’s important to familiarize yourself with the features and functions of the 2021 Drag Pak before racing it at the drag strip. As always, check with the race sanctioning body for any updates regarding rule changes.
        </p>
        <p>
          Be sure to properly maintain your Drag Pak to prevent excess engine and transmission wear and potential drivetrain damage. This is an extreme high-performance machine that’s capable of sub-eight-second quarter-mile ETs.
        </p>
        <p>
          <strong>Enjoy the ride!</strong>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mt-5 text-center btn-container">
        <a routerLink="/drag-pak-experience/history" class="btn btn-primary btn-footer-nav mr-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Drag Pak History"><i class="fa fa-chevron-left" aria-hidden="true"></i> <span>Drag Pak History</span></a>
        <a routerLink="/drag-pak-experience/startup-procedures" class="btn btn-primary btn-footer-nav ml-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Intro and Start-Up Procedures"><span>Intro &amp; Start-Up Procedures</span> <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
      </div>
    </div>

  </div>
</div>
