import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {NewsArticle} from "../news-articles/news-article";
import {NewsArticleService} from "../news-articles/news-article.service";
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { HelperService } from "../services/helper.service";
import {DomSanitizer, Meta, SafeResourceUrl, Title} from '@angular/platform-browser';
import { CountdownEvent } from 'ngx-countdown';
import differenceInCalendarDays from 'date-fns/differenceInDays'
import {environment} from "../../environments/environment";

const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];

@Component({
  selector: 'dg-never-lift',
  templateUrl: './never-lift.component.html',
  styleUrls: ['./never-lift.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NeverLiftComponent implements OnInit {
  @Input() categoryId: string = ''; // Needs to be a string to send properly
  @Output() articlesLoaded = new EventEmitter();
  garageTiles = [];

  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('videoModal', { static: true }) public videoModal: TemplateRef<any>;
  @ViewChild('vimeoVideoModal', { static: true }) public vimeoVideoModal: TemplateRef<any>;
  videoModalRef: BsModalRef;
  currentEmbedID: '';
  currentVimeoEmbedID: '';
  safeURL: SafeResourceUrl;
  sharingURL: string;
  shareImg: any;
  pageDescription: string;


  // countdown stuff
  countdownEnded: boolean = false;
  differenceInDays: number;

  constructor(
    public newsArticleService: NewsArticleService,
    public helperService: HelperService,
    private modalService: BsModalService,
    private titleService: Title,
    private metaService: Meta,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.differenceInDays = differenceInCalendarDays(new Date(1636740000000), new Date());
    this.garageTiles = [
      {
        type: "video",
        youtubeEmbedID: "IQr-WA7_J3I",
        title: _sanitizer.bypassSecurityTrustHtml("Dodge EV Summit <span class='red'>07 08 21</span>"),
        analyticsLabel: "Dodge EV Summit Video",
        previewThumbnail: {
          src: '/assets/images/never-lift/dodge-ev-summit-thumb.jpg',
          alt: "Dodge EV Summit Video"
        }
      },
      {
        type: "article",
        wordpressPostID: 57072,
        title: _sanitizer.bypassSecurityTrustHtml("Hemi<sup>&reg;</sup> Orange & SRT<sup>&reg;</sup> Black <span class='red'>10 26 21</span>"),
        newsArticleData: {},
        secondaryNewsArticleData: {},
        analyticsLabel: "Hemi Orange & SRT Black",
        previewThumbnail: {
          src: '/assets/images/never-lift/hemi-orange-and-srt-black-thumb.jpg',
          alt: "Hemi Orange & SRT Black"
        },
        buttonText: _sanitizer.bypassSecurityTrustHtml(`Durango Hemi<sup>&reg;</sup> Orange 05 25 22`),
        buttonLinkID: 61952
      },
      {
        type: "article",
        wordpressPostID: 57494,
        title: _sanitizer.bypassSecurityTrustHtml("Never Lift <span class='red'>11 08 21</span>"),
        newsArticleData: {},
        secondaryNewsArticleData: {},
        analyticsLabel: "Never Lift Announcement Video",
        previewThumbnail: {
          src: '/assets/images/never-lift/never-lift-thumb.jpg',
          alt: "Never Lift Announcement Video"
        }
      },
      {
        type: "article",
        wordpressPostID: 57675,
        title: _sanitizer.bypassSecurityTrustHtml("Operation 25//8 <span class='red'>11 15 21</span>"),
        analyticsLabel: "25//8 Announcement Video",
        newsArticleData: {},
        secondaryNewsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/25-8-thumb.jpg',
          alt: "25//8 video"
        },
        buttonText: 'Winners Announced 01 31 22',
        buttonLinkID: 59283
      },
      {
        type: "article",
        wordpressPostID: 57642,
        title: _sanitizer.bypassSecurityTrustHtml("Jailbreak <span class='red'>11 16 21</span>"),
        analyticsLabel: "Jailbreak Announcement Video",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/jailbreak-thumb.jpg',
          alt: "jailbreak video"
        },
        buttonText: 'Jailbreak Availability',
        buttonLink: '/horsepower-locator'
      },
      {
        type: "video-vimeo",
        vimeoEmbedID: "665711819?h=13603d94e9",
        title: _sanitizer.bypassSecurityTrustHtml("TSR NHRA Partnership <span class='red'>01 14 22</span>"),
        analyticsLabel: "Direct Connection Preview Video",
        previewThumbnail: {
          src: '/assets/images/never-lift/tsr-nhra-partnership-thumb.jpg',
          alt: "A still from the TSR NHRA Partnership video"
        },
        buttonText: 'Latest Racing News',
        buttonLink: '/news/category/racing'
      },
      {
        type: "video-vimeo",
        vimeoEmbedID: "663432640?h=3889b962516",
        title: _sanitizer.bypassSecurityTrustHtml("Chief Donut Maker <span class='red'>01 20 22</span>"),
        analyticsLabel: "Chief Donut Maker Announcement Video",
        secondaryNewsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/chief-donut-maker-thumb.jpg',
          alt: "A still from the Chief Donut Maker video"
        },
        buttonText: 'WINNER ANNOUNCED 04 26 22',
        buttonLinkID: 61321
      },
      {
        type: "video",
        youtubeEmbedID: "QZ3jjeD8PDU",
        title: _sanitizer.bypassSecurityTrustHtml("Dodge Power Brokers Launch <span class='red'>03 09 22</span>"),
        analyticsLabel: "Dodge Power Broker Dealers",
        secondaryNewsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/power-brokers-thumb.jpg',
          alt: "A still from the Direct Connection Preview video"
        },
        buttonText: 'Power Brokers & Direct Connection Launch',
        buttonLinkID: 60172
      },
      {
        type: "video",
        youtubeEmbedID: "TRmcXVx8ZQA",
        title: _sanitizer.bypassSecurityTrustHtml("Direct Connection Launch <span class='red'>03 09 22</span>"),
        analyticsLabel: "Direct Connection Preview Video",
        previewThumbnail: {
          src: '/assets/images/never-lift/direct-connection-thumb.jpg',
          alt: "A still from the Direct Connection Preview video"
        },
        buttonText: 'Shop Performance Parts',
        buttonLink: 'https://www.dcperformance.com/',
        buttonExternalLink: true
      },
      {
        type: "video",
        youtubeEmbedID: "eXhUYy_qHxI",
        title: _sanitizer.bypassSecurityTrustHtml("Roadkill Nights Announced <span class='red'>06 10 22</span>"),
        analyticsLabel: "Roadkill Nights Announcement Video",
        previewThumbnail: {
          src: '/assets/images/never-lift/roadkill-nights-thumb.jpg',
          alt: "A shot from the 2021 Road Kill Nights event showing legal drag racing on Woodward Avenue."
        }
      },
      {
        type: "article",
        wordpressPostID: 63820,
        title: _sanitizer.bypassSecurityTrustHtml("Droptop Challenger <span class='red'>08 15 22</span>"),
        analyticsLabel: "Droptop Challenger: Dodge Dealers Offer New Streamlined Process for Third-Party Challenger Convertible Modifications",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/droptop-challenger.jpg',
          alt: "Droptop Challenger"
        },
      },
      {
        type: "article",
        wordpressPostID: 63817,
        title: _sanitizer.bypassSecurityTrustHtml("DC Portfolio Expands <span class='red'>08 15 22</span>"),
        analyticsLabel: "Dodge Direct Connection Performance Parts Portfolio Expands, Offers New Products Including Drag Pak Rolling Chassis, Licensed Carbon Fiber Parts",
        newsArticleData: {},
        invert: true,
        previewThumbnail: {
          src: '/assets/images/never-lift/white-hellcat.jpg',
          alt: "Direct Connection Performance Parts Portfolio Expands"
        },
      },
      {
        type: "article",
        wordpressPostID: 63812,
        title: _sanitizer.bypassSecurityTrustHtml("Durango SRT<sup>&reg;</sup> Hellcat Returns <span class='red'>08 15 22</span>"),
        analyticsLabel: "The Cat Is Back: 2023 Dodge Durango SRT<sup>&reg;</sup> Hellcat — Most Powerful SUV Ever — Returns to Dodge Lineup",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/durango-hellcat.jpg',
          alt: "Durango SRT Hellcat Returns"
        },
      },
      {
        type: "article",
        wordpressPostID: 63835,
        title: _sanitizer.bypassSecurityTrustHtml("All-New 2023 Dodge Hornet <span class='red'>08 16 22</span>"),
        analyticsLabel: "The Hive Has Arrived: All-new 2023 Dodge Hornet Unlocks Gateway to Dodge Muscle, Offers Quickest, Fastest, Most Powerful Compact Utility Vehicle Under $30,000",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/dodge-hornet.jpg',
          alt: "All-New 2023 Dodge Hornet"
        },
      },
      {
        type: "article",
        wordpressPostID: 63838,
        title: _sanitizer.bypassSecurityTrustHtml("Charger Daytona SRT<sup>®</sup> Concept <span class='red'>08 17 22</span>"),
        analyticsLabel: "Performance Made Us Do It: Dodge Charger Daytona SRT Concept Previews Brand’s Electrified Future",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/charger-daytona-srt-concept.jpg',
          alt: "Dodge Charger Daytona SRT Concept"
        },
      },
      {
        type: "article",
        wordpressPostID: 64184,
        title: _sanitizer.bypassSecurityTrustHtml("2023 Challenger Shakedown <span class='red'>08 24 22</span>"),
        analyticsLabel: "Dodge Challenger Shakedown Revealed, Kicks Off No. 1 of 7 ‘Last Call’ Special-edition Models",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/challenger-shakedown.jpg',
          alt: "Dodge Challenger Shakedown"
        },
      },
      {
        type: "article",
        wordpressPostID: 64550,
        title: _sanitizer.bypassSecurityTrustHtml("2023 Charger Super Bee <span class='red'>08 31 22</span>"),
        analyticsLabel: "Bee-lieve It: 2023 Dodge Charger Super Bee Announces #2 of 7 Dodge Special-edition ‘Last Call’",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/charger-super-bee-sm.png',
          alt: "Dodge Charger Super Bee"
        },
      },
      {
        type: "article",
        wordpressPostID: 64623,
        title: _sanitizer.bypassSecurityTrustHtml("2023 Challenger & Charger Swinger <span class='red'>09 07 22</span>"),
        analyticsLabel: "2023 Dodge Challenger and Charger Scat Pack Swinger Special-edition Models Bring Retro Flair to Dodge ‘Last Call’ Lineup ",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/dodge-dart-swinger.jpg',
          alt: "Dodge Dart Swinger"
        },
      },
      {
        type: "article",
        wordpressPostID: 64688,
        title: _sanitizer.bypassSecurityTrustHtml("2023 Charger King Daytona <span class='red'>09 14 22</span>"),
        analyticsLabel: "Dodge Crowns Latest ‘Last Call’ Special-edition Model: 2023 Dodge Charger King Daytona",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/dodge-charger-king.jpg',
          alt: "Dodge Charger King"
        },
      },
      {
        type: "article",
        wordpressPostID: 64954,
        title: _sanitizer.bypassSecurityTrustHtml("2023 Challenger Black Ghost <span class='red'>09 21 22</span>"),
        analyticsLabel: "HEMI<sup>®</sup> Spirit: Dodge Challenger Black Ghost Is Number Six of Seven Dodge ‘Last Call’ Special-edition Models",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/black-ghost.jpg',
          alt: "2023 Challenger Black Ghost"
        },
      },
      {
        type: "video",
        youtubeEmbedID: "EUs0D9fZFWA",
        title: _sanitizer.bypassSecurityTrustHtml("2023 Horsepower Locator <span class='red'>10 27 22</span>"),
        analyticsLabel: "Dodge Dials Up 'Last Call' Horsepower Locator",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/horsepower-locator-thumb.jpg',
          alt: "Horsepower Locator - Don't say we didn't warn you"
        },
        buttonText: 'Find Your Ride',
        buttonLink: '/horsepower-locator'
      },
      {
        type: "article",
        wordpressPostID: 66130,
        title: _sanitizer.bypassSecurityTrustHtml("2022 SEMA Show <span class='red'>11 01 22</span>"),
        analyticsLabel: "Dodge is all-in at the 2022 SEMA Show",
        newsArticleData: {},
        previewThumbnail: {
          src: '/assets/images/never-lift/sema.jpg',
          alt: "Dodge is all-in at the 2022 SEMA Show"
        },
      },
      {
        type: "video",
        youtubeEmbedID: "HbTCFj-N-CM",
        title: _sanitizer.bypassSecurityTrustHtml("2023 Challenger SRT<sup>®</sup> Demon 170 <span class='red'>03 20 23</span>"), 
        analyticsLabel: "2023 Challenger SRT Demon 170",
        previewThumbnail: {
          src: '/assets/images/never-lift/challenger-srt-demon-170.jpg', 
          alt: "Dodge SRT Demon 170"
        },
        buttonText: 'Meet the 1,025 HP SRT<sup>®</sup> Demon 170',
        buttonLink: '/srt-demon-170'
      },
      {
        type: "video",
        youtubeEmbedID: "wkLUSJqMK4M",
        title: _sanitizer.bypassSecurityTrustHtml("2023 Roadkill Nights <span class='red'>08 12 23</span>"),
        analyticsLabel: "2023 Roadkill Nights",
        previewThumbnail: {
          src: '/assets/images/never-lift/2023-roadkill-nights-livestream.webp',
          alt: "2023 Roadkill Nights"
        }
      },
      {
        type: "article",
        wordpressPostID: 72530,
        title: _sanitizer.bypassSecurityTrustHtml("SRT<sup>&reg;</sup> Demon 170 After Delivery <span class='red'>08 23 23</span>"),
        newsArticleData: {},
        secondaryNewsArticleData: {},
        analyticsLabel: "SRT Demon 170 After Delivery",
        previewThumbnail: {
          src: '/assets/images/never-lift/srt-demon-170-after-delivery-owner-items.webp',
          alt: "SRT Demon 170 After Delivery"
        },
        buttonText: _sanitizer.bypassSecurityTrustHtml(`SRT<sup>&reg;</sup> Demon 170 Owner Info`),
        buttonLink: '/demon-170-owner-info'
      },
      {
        type: "article",
        wordpressPostID: 74251,
        title: _sanitizer.bypassSecurityTrustHtml("New DC Crate Long Blocks + C170 <span class='red'>10 31 23</span>"),
        newsArticleData: {},
        secondaryNewsArticleData: {},
        analyticsLabel: "New DC Crate Long Blocks + C170",
        previewThumbnail: {
          src: '/assets/images/never-lift/direct-connection-hellephant-longblock.webp',
          alt: "New DC Crate Long Blocks + C170"
        },
        buttonText: _sanitizer.bypassSecurityTrustHtml(`Shop Direct Connection`),
        buttonLink: 'https://www.dcperformance.com/crate-engine',
        buttonExternalLink: true
      },
      {
        type: "article",
        wordpressPostID: 75763,
        title: _sanitizer.bypassSecurityTrustHtml("2024 Durango SRT<sup>&reg;</sup> 392 Alchemi <span class='red'>01 09 24</span>"),
        newsArticleData: {},
        secondaryNewsArticleData: {},
        analyticsLabel: "2024 Durango SRT 392 Alchemi",
        previewThumbnail: {
          src: '/assets/images/never-lift/2024-durango-srt-392-alc-hemi.webp',
          alt: "2024 Durango SRT 392 Alchemi"
        }
      },
      {
        type: "video",
        youtubeEmbedID: "uGAZkn11hcw",
        title: _sanitizer.bypassSecurityTrustHtml("Next-Gen Dodge Charger <span class='red'>03 05 24</span>"),
        analyticsLabel: "2023 Challenger SRT Demon 170",
        previewThumbnail: {
          src: '/assets/images/never-lift/next-gen-charger.webp',
          alt: "Explore the Next-Gen Charger"
        },
        buttonText: 'Explore the Next-Gen Charger',
        buttonExternalLink: true,
        buttonLink: 'https://www.dodge.com/next-gen-charger.html'
      },
    ];

    // Set Title
    this.titleService.setTitle( 'Never Lift Calendar of Dodge Muscle | DodgeGarage' );

    this.sharingURL = environment.url + this.router.url;
    this.shareImg = '/assets/images/logos/dodge-logo-large.png';
    this.pageDescription = 'Follow 24 months of new Dodge program and vehicle launches';

    // Set Meta Data
    this.metaService.updateTag({ name: 'description', content: this.pageDescription });
    this.metaService.updateTag({ rel: 'canonical', href: environment.url + this.router.url });
    this.metaService.updateTag({ property: 'og:description', content: this.pageDescription });
    this.metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
    this.metaService.updateTag({ property: 'og:title', content: 'Never Lift Calendar of Dodge Muscle | DodgeGarage' });
    this.metaService.updateTag({ property: 'og:type', content: 'article' });
    this.metaService.updateTag({ property: 'og:image', content: environment.url + this.shareImg });
    this.metaService.updateTag({ property: 'og:url', content: this.sharingURL });
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
    this.metaService.updateTag({ name: 'twitter:image', content: environment.url + this.shareImg });
  }

  ngOnInit() {
    this.garageTiles.forEach(tile => {
      if(tile.type === 'article') {
        this.newsArticleService.getArticleByWordPressId(tile.wordpressPostID).subscribe( articleData => {
          tile.newsArticleData = this.newsArticleService.mutateArticle(articleData);
        })
      }
      if(tile.buttonLinkID !== undefined) {
        this.newsArticleService.getArticleByWordPressId(tile.buttonLinkID).subscribe( articleData => {
          tile.secondaryNewsArticleData = this.newsArticleService.mutateArticle(articleData);
        })
      }
    })
  }

  goToArticle(article: NewsArticle, event) {
    event.preventDefault();
    if (this.newsArticleService.isNewsArticleViewFull) {
      this.router.navigate(['/news/article/' + article.cleanLink]);
    } else {
      this.newsArticleService.newsArticleOpen = article;
      // console.log(this.newsArticleService.newsArticleOpen);
      this.newsArticleService.setModalOpen(true);
    }
  }

  openVideoModal(event, embedID) {
    this.currentEmbedID = embedID;
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+ this.currentEmbedID + "?autoplay=1");
    event.preventDefault();
    this.videoModalRef = this.modalService.show(this.vimeoVideoModal, { class: 'neverlift-video' , initialState: { safeURL: this.safeURL }});
    this.helperService.trackGAEvent('Never Lift', 'Click', 'Never Lift Landing Page Video - Open');
  }

  openVimeoVideoModal(event, embedID) {
    this.currentVimeoEmbedID = embedID;
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/"+ this.currentVimeoEmbedID);
    event.preventDefault();
    this.videoModalRef = this.modalService.show(this.videoModal, { class: 'neverlift-video' , initialState: { safeURL: this.safeURL }});
    this.helperService.trackGAEvent('Never Lift', 'Click', 'Never Lift Landing Page Video - Open');
  }


  closeVideoModal() {
    this.videoModalRef.hide();
    this.helperService.trackGAEvent('Never Lift', 'Click', 'Never Lift Landing Page Video - Close');
  }

  handleCountdownEvent(event: CountdownEvent) {
    if (event.action === 'done') {
      this.countdownEnded = true;
      let oldTiles = this.garageTiles;
      this.garageTiles = oldTiles.map(tile => {
        if(tile.type === 'countdown' && tile.revealedTile) {
          return tile.revealedTile;
        } else return tile;
      });
      console.log(oldTiles, this.garageTiles)

    }
  }

  // Convert days to hours and add to total hours
  formatCountdown(text) {
    this.differenceInDays = this.differenceInDays = differenceInCalendarDays(new Date(1636740000000), new Date());

    return text.split(':')
      .map((segment, index) => {
       if(index === 2) { // seconds
          return `<span class="seconds">${segment}</span>`
        } else {
          return segment;
        }
      })
      .join(':');
  }

  formatDateFn(obj) {
    let date = obj.date,
      formatStr = obj.formatStr,
      duration = Number(date || 0);

    // IE11 polyfills
    if (!String.prototype.padStart) {
      String.prototype.padStart = function padStart(targetLength,padString) {
        targetLength = targetLength>>0; //truncate if number or convert non-number to 0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (this.length > targetLength) {
          return String(this);
        }
        else {
          targetLength = targetLength-this.length;
          if (targetLength > padString.length) {
            padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
          }
          return padString.slice(0,targetLength) + String(this);
        }
      };
    }


    return CountdownTimeUnits.reduce((current, [name, unit]) => {
      if (current.indexOf(name) !== -1) {
        const v = Math.floor(duration / unit);
        duration -= v * unit;
        return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
          return v.toString().padStart(match.length, '0');
        });
      }
      return current;
    }, formatStr);
  }
}
