import {UserModel} from "../../user/user";
import {Vehicle} from "../vehicle";

export class AddVehicle {
  id: number = 0;
  vehicleLookupId: number = 0;
  vin: string;
  isStock: boolean = true;
  mods: string;
  vehicleImage: File;
  vehicleImageSrc: string = '/assets/images/1320/badges/stock/club/stock.png';
  user: UserModel;
  isDefault: boolean = true;

  originalImage: string = '';

  // Original Vehicle Object
  vehicle: Vehicle = new Vehicle();

  // Validation variables
  hasErrorVehicleLookupId: boolean = false;
  hasErrorVin: boolean = false;
  hasErrorVehicleImage: boolean = false;
}
