<div class="banner system-banner" [ngClass]="['bg-' + userService.passwordResetContext]">
  <button class="close">×</button>
  <form name="reset-password-form" (submit)="userService.passwordReset($event); hideBanner()">
    <div class="banner-form" *ngIf="userService.passwordResetContext != 'success'">
      <div class="container">
        <h4 class="heading-sm">Update Password</h4>
          <div class="row">
            <div class="col-xs-6 col-sm-5">
              <div class="form-group">
                <label for="password-login-banner">Password:</label>
                <input class="form-control" type="password" name="password" id="password-login-banner" [(ngModel)]="userService.userModel.password">
              </div>
            </div>
            <div class="col-xs-6 col-sm-5">
              <div class="form-group">
                <label for="password-login-banner">Confirm Password:</label>
                <input class="form-control" type="password" name="confirmPassword" id="reset-password-confirm-banner" [(ngModel)]="userService.userModel.confirmPassword">
              </div>
            </div>
            <div class="col-xs-12 col-sm-2">
              <div class="form-group">
                <label>&nbsp;</label><br>
                <input type="hidden" name="resetToken" [(ngModel)]="userService.userModel.resetToken">
                <input type="hidden" name="email" [(ngModel)]="userService.userModel.email">
                <button type="submit" class="btn btn-block ghost-btn" [ngClass]="{'red':userService.passwordResetContext == 'default','white':userService.passwordResetContext != 'default'}">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-message" *ngIf="userService.passwordResetContext != 'default'">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 flex-container">
              <div class="context-icon">
                <i class="fa fa-exclamation-triangle" aria-hidden="true" *ngIf="userService.passwordResetContext == 'danger'"></i>
                <i class="fa fa-check" aria-hidden="true" *ngIf="userService.passwordResetContext == 'success'"></i>
              </div>
              <div class="context-message">
                <h4 *ngIf="userService.passwordResetContext == 'danger'">Oops!</h4>
                <h4 *ngIf="userService.passwordResetContext == 'success'">Congratulations!</h4>
                <p>
                  <span *ngFor="let message of userService.passwordResetStatusMessages">{{message}} </span>
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
  </form>
</div>
