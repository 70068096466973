import {Component, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {TimeSlipService} from '../time-slip.service';
import {TimeSlip} from "../time-slip";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {VehicleService} from "../../../vehicle/vehicle.service";
import {UserService} from "../../../user/user.service";
import {Vehicle} from "../../../vehicle/vehicle";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {BsModalService} from "ngx-bootstrap/modal";
import { modalCloseTimeOut } from '../../../globals';
import {format, isFuture, parse} from 'date-fns';


function isCurrentOrFutureDateValidator(control: AbstractControl): ValidationErrors | null {
  return isFuture(parse(control.value, 'yyyy-M-d', new Date())) ? { isFuture: { value: true } } : null;
}

@Component({
  selector: 'dg-add-time-slip',
  templateUrl: './add-time-slip.component.html',
  styleUrls: ['./add-time-slip.component.less']
})

@Injectable()
export class AddTimeSlip implements OnInit, OnDestroy {

  @Output() timeSlipAdded = new EventEmitter();
  @Input() vehicle = new Vehicle();
  @Input() timeslip = new TimeSlip();
  @Input() onboarding: boolean = false;

  isLoading: boolean = false;
  isEditingMode: boolean = false;

  errors: string[] = [];
  success: boolean;

  timeSlipFormAlert = {
    show: false,
    status: '',
    message: '',
    additionalMessage: ''
  };

  timeSlipForm: FormGroup;
  timeslipImage: File;
  timeslipImgSrc: string = '';
  timeslipAndVinImage: File;
  timeslipAndVinImgSrc: string = '';
  submitted = false;

  today: string = '';

  vehicles: Vehicle[] = [];

  // Modals
  sampleTimeSlipImgModalRef: BsModalRef;
  timeSlipImgModalRef: BsModalRef;
  timeSlipAndVinImgModalRef: BsModalRef;

  // Time Slip Image
  timeSlipSampleImg: string = '';
  timeSlipSampleImgAlt: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private timeSlipService: TimeSlipService,
    public userService: UserService,
    public vehicleService: VehicleService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.fetchVehicles();
    this.createTimeSlipForm();
    this.today = format(new Date(), 'yyyy-M-d');
  }

  ngOnDestroy() {
    if (this.sampleTimeSlipImgModalRef)
      this.sampleTimeSlipImgModalRef.hide();
  }

  createTimeSlipForm(clearForm: boolean = false) {
    this.timeSlipForm = new FormGroup({
      'id': new FormControl(this.timeslip.id, Validators.required),
      'raceTrack': new FormControl(this.timeslip.raceTrack, Validators.required),
      'et': new FormControl(this.timeslip.elapsedTime, Validators.required),
      'zeroToSixty': new FormControl(this.timeslip.sixtyFeetTime, Validators.required),
      'topSpeed': new FormControl(this.timeslip.topSpeed, Validators.required),
      'vehicle': new FormControl(this.timeslip.vehicle, Validators.required),
      'date': new FormControl(this.timeslip.date, [
          Validators.required,
          isCurrentOrFutureDateValidator
        ]
      ),
      'timeslip': new FormControl(this.timeslip.timeslip, Validators.required),
      'timeslipAndVin': new FormControl(this.timeslip.timeslipAndVin, Validators.required)
    });
    if (!clearForm) {
      if (this.vehicle.id != 0) {
        this.timeSlipForm.controls.vehicle.setValue(this.vehicle.id);
      } else {
        // select default
        this.timeSlipForm.controls.vehicle.setValue("");
      }
      if (this.timeslip.id != 0 && this.vehicle.id != 0) {
        this.loadTimeSlip(this.timeslip, this.vehicle);
      }
    }
  }

  fetchVehicles() {
    this.vehicleService.getVehiclesByUser(this.userService.userModel.id).subscribe( data => {
        this.vehicles = data;
        //this.vehicleCount.emit(data.length);
      },
      err => {
        console.log(err);
      });
  }

  get f() {
    return this.timeSlipForm.controls;
  }

  onTimeSlipFileChange(event, template) {
    let file = event.target.files[0]; // <--- File Object for future use.
    //this.timeSlipForm.controls.timeslip.setValue(file ? file.name : '');
    //this.timeslipImage = $event.target.files[0];

    if (event.target.files && event.target.files[0]) {
      //this.addVehicle.vehicleImage = event.target.files[0];
      this.timeslipImage = event.target.files[0];
      this.timeSlipImgModalRef = this.modalService.show(template, {class: 'modal-normal'});
    }

  }

  onTimeSlipAndVinFileChange(event, template) {
    //let file = $event.target.files[0]; // <--- File Object for future use.
    //this.timeSlipForm.controls.timeslipAndVin.setValue(file ? file.name : '');
    //this.timeslipAndVinImage = $event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      //this.addVehicle.vehicleImage = event.target.files[0];
      this.timeslipAndVinImage = event.target.files[0];
      this.timeSlipAndVinImgModalRef = this.modalService.show(template, {class: 'modal-normal'});
    }
  }

  updateTimeSlipImage(event) {
    //console.log(event);
    //this.addVehicle.originalImage = event.image;
    this.timeslipImgSrc = event.image;
    this.timeslipImage = event.file;
    this.timeSlipForm.controls.timeslip.setValue(event.file ? event.file : '');
    this.timeSlipImgModalRef.hide();
  }

  updateTimeSlipAndVinImage(event) {
    //console.log(event);
    //this.addVehicle.originalImage = event.image;
    this.timeslipAndVinImgSrc = event.image;
    this.timeslipAndVinImage = event.file;
    this.timeSlipForm.controls.timeslipAndVin.setValue(event.file ? event.file : '');
    this.timeSlipAndVinImgModalRef.hide();
  }

  onSubmit() {
    this.submitted = true;
    Object.keys(this.timeSlipForm.controls).forEach(field => {
      const control = this.timeSlipForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    // stop here if form is invalid
    if (this.timeSlipForm.invalid) {
      console.log('in return condition')
      return;
    }

    this.submitTimeSlip();
  }

  loadTimeSlip(timeSlip: TimeSlip, vehicle: Vehicle) {
    this.isEditingMode = true;
    this.timeSlipForm.controls.id.setValue(timeSlip.id);
    this.timeSlipForm.controls.raceTrack.setValue(timeSlip.raceTrack);
    this.timeSlipForm.controls.et.setValue(timeSlip.elapsedTime);
    this.timeSlipForm.controls.zeroToSixty.setValue(timeSlip.sixtyFeetTime);
    this.timeSlipForm.controls.topSpeed.setValue(timeSlip.topSpeed);
    this.timeSlipForm.controls.vehicle.setValue(vehicle.id);
    this.timeSlipForm.controls.date.setValue(new Date(timeSlip.date).toISOString().split('T')[0]);
    this.timeSlipForm.controls.timeslip.setValue(timeSlip.imagePath1);
    this.timeslipImgSrc = timeSlip.imagePath1;
    this.timeSlipForm.controls.timeslipAndVin.setValue(timeSlip.imagePath1);
    this.timeslipAndVinImgSrc = timeSlip.imagePath2;
  }

  submitTimeSlip() {
    // Convert Date
    this.timeSlipForm.controls.date.setValue(new Date(this.timeSlipForm.controls.date.value).toISOString().split('T')[0]);
    this.isLoading = true;
    this.timeSlipService.submitTimeSlip(this.timeSlipForm.controls, this.timeslipImage, this.timeslipAndVinImage).subscribe( data => {
      if (data.status == 'success') {
        this.isLoading = false;
        this.timeSlipFormAlert.show = true;
        this.timeSlipFormAlert.status = data['status'];
        this.timeSlipFormAlert.message = 'Time slip successfully added';
        this.timeSlipForm.reset();
        /*Object.keys(this.timeSlipForm.controls).forEach(field => {
          const control = this.timeSlipForm.get(field);
          //console.log(control);
          control.markAsPristine();
        });*/
        //this.timeSlipForm.resetForm();
        setTimeout(() => {
          this.timeSlipAdded.emit(data.slip);
        }, modalCloseTimeOut);
      } else {
        this.isLoading = false;
        this.timeSlipFormAlert.show = true;
        this.timeSlipFormAlert.status = 'danger';
        this.timeSlipFormAlert.message = 'There was an error submitting the time slip. Please try again.';
      }
    }, errors => {
      this.isLoading = false;
      this.timeSlipFormAlert.show = true;
      this.timeSlipFormAlert.status = 'danger';
      this.timeSlipFormAlert.message = errors.join(',');
    });
  }

  openTimeSlipImgModal(template: TemplateRef<any>, img: string, imgAlt: string, event) {
    event.preventDefault();
    this.timeSlipSampleImg = img;
    this.timeSlipSampleImgAlt = imgAlt;
    this.sampleTimeSlipImgModalRef = this.modalService.show(template, {class: 'modal-sm'});
  }
}
