import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';
import { PanelCollapseComponent } from './panel-collapse/panel-collapse.component';
import { AccordionComponent } from './accordion/accordion.component';
import { RouterModule } from '@angular/router';
import { ImageEditingComponent } from './image-editing/image-editing.component';
import { CardCollapseComponent } from './card-collapse/card-collapse.component';
import { DealerLocatorByNameComponent } from './dealer-locator-by-name/dealer-locator-by-name.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { VideoDragPakComponent } from './video-drag-pak/video-drag-pak.component';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    AlertBannerComponent,
    PanelCollapseComponent,
    AccordionComponent,
    ImageEditingComponent,
    CardCollapseComponent,
    DealerLocatorByNameComponent,
    VideoDragPakComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ModalModule.forRoot()
  ],
  exports: [
    SafeHtmlPipe,
    AlertBannerComponent,
    PanelCollapseComponent,
    AccordionComponent,
    ImageEditingComponent,
    CardCollapseComponent,
    DealerLocatorByNameComponent,
    VideoDragPakComponent
  ]
})
export class SharedModule { }
