import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { UserService } from '../user/user.service';


@Component({
  selector: 'dg-event-registration-redirect',
  templateUrl: './event-registration-redirect.component.html',
  styles: []
})
export class EventRegistrationRedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public userService: UserService,) { }
  source: string;

  ngOnInit(): void {

    // read params
    this.route.queryParams.subscribe(params => {
        this.source = params['event-name'];
        console.info(`'utm_source' query parameter detected. Storing value '${this.source}' in local storage and redirecting to home page.`)
      }
    );
    // store source in localstorage
    localStorage.setItem('DGEventRegistrationSource', this.source);
    // redirect to home page
    this.router.navigate([''], { queryParams: { logout: true } });
    // go to registration logic and add check for localstorage
  }
}
