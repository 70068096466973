<div>
  <img loading="lazy" #imgElement id="cropperImage" [src]="image" class="img-fluid">
</div>
<p class="text-center tool-btns">
  <button class="btn btn-white" (click)="rotateLeft()">Rotate</button>
  <!--<button class="btn btn-white" (click)="rotateRight()">Rotate Right</button>-->
  <button class="btn btn-primary" (click)="cropImage()" [disabled]="isLoading">
    Crop &amp; Save
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
  </button>
</p>
