import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { slide } from '../../animations';
import {Subscription} from "rxjs";
import {UserService} from "../user.service";
import {modalCloseTimeOut} from "../../globals";
import {TabsetComponent} from "ngx-bootstrap/tabs";

@Component({
  selector: 'dg-user-onboarding',
  templateUrl: './user-onboarding.component.html',
  styleUrls: ['./user-onboarding.component.less'],
  animations: [ slide ]
})
export class UserOnboardingComponent implements OnInit {

  @Input() defaultTab: string = 'login';
  // Allowable default tabs values = ['login','register']
  @Input() onboardingAlert: any = {
    show: false,
    status: '',
    code: '', // Shorthand for identifying certain alerts
    message: '',
    additionalMessage: ''
  };
  @Output() userLoggedIn = new EventEmitter();

  @ViewChild('onboardingTabs', { static: true }) onboardingTabs: TabsetComponent;

  // User login status
  loggedInSub: Subscription;

  constructor(
    public userService: UserService
  ) { }

  ngOnInit() {
    this.loggedInSub = this.userService.loggedIn$.subscribe(loggedIn => {
      if (this.userService.userModel.isLoggedIn) {
        // User is now logged in
        // Wait before emitting event so user can view the alert
        setTimeout(() => {
          this.userLoggedIn.emit('logged in');
        }, modalCloseTimeOut);
      }
    });
    if (this.defaultTab == 'register')
      this.onboardingTabs.tabs[1].active = true;
  }

}
