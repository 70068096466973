import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dg-barrett-jackson-rules',
  templateUrl: './barrett-jackson-rules.component.html'
})
export class BarrettJacksonRulesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
