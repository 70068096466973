import { TrackLocatorService } from './track-locator.service';
import { HelperService } from './../../services/helper.service';
import { environment } from './../../../environments/environment';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID, ComponentRef, Injector, ComponentFactoryResolver, ApplicationRef, NgZone } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Track } from './track';
import { isPlatformBrowser } from '@angular/common';
import {} from 'googlemaps';

declare let google: any;

@Component({
  selector: 'dg-track-info-window',
  template: `
    <div class="track">
      <div class="title-row">
        <h3 class="heading3">{{track.name}}</h3>
        <div>{{(track.distance | number:'1.0-0')?(track.distance | number:'1.0-0') + ' miles':''}}</div>
      </div>
      <div class="track-info">
        <div>
          <address>
            {{track.address}}<br>
            {{track.city}}, {{track.state}} {{track.zipcode}}
          </address>
          <div><a class="get-driections-link" href="https://maps.google.com/maps?daddr={{track.address}}+{{track.city}}+{{track.state}}+{{track.zipcode}}" target="_blank">Get Directions</a></div>
        </div>
        <div><a *ngIf="track.website" class="btn btn-sm btn-outline-primary" [href]="track.website" target="_blank">Visit Website</a></div>
      </div>
    </div>
  `
})
export class TrackInfoWindowComponent {
  track: any;
  constructor() {}
}

@Component({
  selector: 'dg-track-locator',
  templateUrl: './track-locator.component.html',
  styles: []
})
export class TrackLocatorComponent implements OnInit {

  isLoading: boolean = false;
  searchForm: FormGroup;
  @ViewChild('searchType', { static: true }) username: ElementRef;
  states: [] = [];
  isSearched: boolean = false;

  tracks: Track[] = [];
  markers: Array<any> = [];

  map: google.maps.Map;
  @ViewChild('map', { static: true }) gmapElement: any;
  compRef: ComponentRef<TrackInfoWindowComponent>;
  placeInfoWindow: any;
  //Starting position and zoom level for the map
  lat: number = 37.09024;
  lng: number = -95.712891;
  zoom: number = 4;
  isMapLoading: boolean = true;

  constructor(
    private helperService: HelperService,
    private trackLocatorService: TrackLocatorService,
    public titleService: Title,
    public metaService: Meta,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    titleService.setTitle( 'Track Locator | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'Find a track, rally friends, cheer and compete.' })
    metaService.updateTag({ property: 'og:description', content: 'Find a track, rally friends, cheer and compete.' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | Track Locator' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + '/track-locator' })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
    this.mapInit("//ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js")
    this.mapInit("https://maps.googleapis.com/maps/api/js?key=AIzaSyCf-c1dvJoDJOV75o05--1SmtrLTRZ1dxk")
    this.createSearchForm();
    this.setValidators();
    this.fetchStates();
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.createMap();
      }, 2000);
    }
  }
  
  mapInit(stringLink) {
    let script = document.createElement('script');
    script.src = stringLink;
    script.setAttribute("data-cfasync", "false");
    script.setAttribute("charset", "utf-8");
    document.body.appendChild(script);
  }

  createSearchForm() {
    this.searchForm = new FormGroup({
      'searchType': new FormControl('Zipcode', [
        Validators.required
      ]),
      'zipcode': new FormControl('', [
        Validators.required // Because this is default
      ]),
      'trackName': new FormControl(''),
      'city': new FormControl(''),
      'state': new FormControl('')
    });
  }

  setValidators() {
    const zipcodeControl = this.searchForm.get('zipcode');
    const trackNameControl = this.searchForm.get('trackName');
    const cityControl = this.searchForm.get('city');
    const stateControl = this.searchForm.get('state');
    this.searchForm.get('searchType').valueChanges.subscribe( searchType => {

      if (searchType === 'Zipcode') {
        zipcodeControl.setValidators([Validators.required]);
        trackNameControl.setValidators(null);
        cityControl.setValidators(null);
        stateControl.setValidators(null);
      }
      if (searchType === 'Track Name') {
        zipcodeControl.setValidators(null);
        trackNameControl.setValidators([Validators.required]);
        cityControl.setValidators(null);
        stateControl.setValidators(null);
      }
      if (searchType === 'City/State') {
        zipcodeControl.setValidators(null);
        trackNameControl.setValidators(null);
        cityControl.setValidators([Validators.required]);
        stateControl.setValidators([Validators.required]);
      }

      zipcodeControl.updateValueAndValidity();
      trackNameControl.updateValueAndValidity();
      cityControl.updateValueAndValidity();
      stateControl.updateValueAndValidity();
    });
  }

  updateSearchType(value) {
    Object.keys(this.searchForm.controls).forEach(field => {
      const control = this.searchForm.get(field);
      control.markAsUntouched({ onlySelf: true });
    });
    this.searchForm.markAsUntouched();
    this.searchForm.markAsPristine();
  }

  fetchStates() {
    this.helperService.getStates().subscribe( data => this.states = data);
  }

  submitSearch($event) {
    $event.preventDefault();
    // Check all fields before submitting
    Object.keys(this.searchForm.controls).forEach(field => {
      const control = this.searchForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    this.searchForm.markAsTouched();
    if (this.searchForm.valid) { // If form is valid, proceed
      this.isLoading = true;
      let zipcode = null;
      let trackName = null;
      let city = null;
      let state = null;
      if (this.searchForm.get('searchType').value == 'Zipcode') {
        zipcode = this.searchForm.get('zipcode').value;
      } else if (this.searchForm.get('searchType').value == 'Track Name') {
        trackName = this.searchForm.get('trackName').value;
      } else if (this.searchForm.get('searchType').value == 'City/State') {
        city = this.searchForm.get('city').value;
        state = this.searchForm.get('state').value;
      }
      this.trackLocatorService.getTracks(this.searchForm.get('searchType').value, zipcode, trackName, city, state).subscribe( data => {
        this.tracks = data;
        this.tracks.forEach( track => this.trackLocatorService.mutateTracks(track));
        this.setMarkers(this.tracks);
        this.isLoading = false;
        this.isSearched = true;
      });
    }
    //this.trackLocatorService.
  }

  createMap() {
    this.map = new google.maps.Map(this.gmapElement.nativeElement, {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: this.zoom,
      scrollwheel: false,
      styles: [{
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
      }, {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{"color": "#ffffff"}, {"lightness": 17}]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
      }, {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{"color": "#ffffff"}, {"lightness": 18}]
      }, {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [{"color": "#ffffff"}, {"lightness": 16}]
      }, {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
      }, {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{"color": "#dedede"}, {"lightness": 21}]
      }, {
        "elementType": "labels.text.stroke",
        "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
      }, {
        "elementType": "labels.text.fill",
        "stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
      }, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [{"color": "#fefefe"}, {"lightness": 20}]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
      }]
    });
    this.isMapLoading = false;
  }

  setMarkers(data) {
    if (isPlatformBrowser(this.platformId)) {
      // Reset Markers
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null); //Remove the marker from the map
      }
      // Close Open Windows
      if (this.placeInfoWindow) {
        this.placeInfoWindow.close();
      }
      let bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < data.length; i++) {
        let latLng = new google.maps.LatLng(parseFloat(data[i].latitude), parseFloat(data[i].longitude));
        let marker = new google.maps.Marker({
          position: latLng,
          map: this.map
        });
        marker.addListener('click', (e) => {
          if (this.placeInfoWindow) {
            this.placeInfoWindow.close();
          }
          this.zone.run(() => this.onMarkerClick(marker, e, data[i]));
        });
        this.markers.push(marker);
        //extend the bounds to include each marker's position
        bounds.extend(marker.position);
      }
      // Don't zoom in too far on only one marker
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        let extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
        let extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      this.map.fitBounds(bounds);
      let zoom = this.map.getZoom();
      this.map.setZoom(zoom > 12 ? 12 : zoom);
    }
  }

  onMarkerClick(marker, e, data) {
    if(this.compRef) this.compRef.destroy();

    // creation component, TrackInfoWindowComponent should be declared in entryComponents
    const compFactory = this.resolver.resolveComponentFactory(TrackInfoWindowComponent);
    this.compRef = compFactory.create(this.injector);
    this.compRef.instance.track = data;

    let div = document.createElement('div');
    div.appendChild(this.compRef.location.nativeElement);

    this.placeInfoWindow = new google.maps.InfoWindow();
    this.placeInfoWindow.setContent(div);
    this.placeInfoWindow.open(this.map, marker);

    // it's necessary for change detection within TrackInfoWindowComponent
    this.appRef.attachView(this.compRef.hostView);
    this.compRef.onDestroy(() => {
      this.appRef.detachView(this.compRef.hostView);
    });

    this.placeInfoWindow.addListener('closeclick', _ => {
      this.compRef.destroy();
    });
  }

}
