<div class="site-wrapper">
  <section id="hero-last-chance">
    <div class="row">
      <div class="col-sm-12">
        <div class="hero">
          
        </div>
      </div>
    </div>
  </section>
  <section id="content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 media-embed full">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/qpbI21CYB78" allow="autoplay; encrypted-media" title="don't miss the ultimate last chance"></iframe>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-lg-8">
          <p class="lead">
            Don’t miss seeing the Ultimate Last Chance make history. One lucky winner will own both the final production 2017 Dodge Viper and 2018 Dodge Challenger SRT<sup class="realign superscript">®</sup> Demon. These one-of-a-kind models, both hand-painted Viper Red with black Alcantara interiors and unique badges, will be sold together as one lot at the <a href="https://azure.barrett-jackson.com/Events/Event/Schedule/Northeast-2018/f2f3122a-2b07-4572-b74a-fa64b2953992" class="ga-event" data-ga_category="Ultimate Last Chance" data-ga_action="Event CTA" data-ga_label="to Barret Jackson auction" target="_blank" rel="noopener">Barrett-Jackson Northeast Auction on Saturday, June 23, 2018 at 3:00pm EST.</a> Watch live on Discovery. 
          </p>
          <p class="lead">
            Each vehicle will come with an exclusive authentication package, documenting their final production status with build sheets, carbon fiber Certicards, photo books, and letters of authenticity to guarantee their place in history. They were born icons, and will be legendary for years to come. With 100% of the hammer proceeds being donated to United Way, FCA’s partner in charity for 60 years, the Ultimate Last Chance auction is the opportunity to make a difference by making history.
          </p>
        </div>
        <div class="col-md-3 col-lg-4">
          <div class="callout callout-red">
            <div class="callout-inner">
              <h2 class="callout-heading">Don't Miss the Ultimate Last Chance</h2>
              <ul class="list-unstyled">
                <li>
                  <span>Where:</span>
                  <span>Barrett-Jackson Northeast Auction</span>
                </li>
                <li>
                  <span>When:</span>
                  <span>Saturday, June 23, 2018 // 3:00 PM EST</span>
                </li>
                <li>
                  <span>How:</span>
                  <span>Watch live on Discovery</span>
                </li>
              </ul>
            </div>
          </div>
        </div>        
      </div>
      <div class="row">
        <div class="col-sm-12 gallery-directions">
          <p>Click to view full image</p>
        </div>
        <div class="col-sm-12 gallery-container">
          <a *ngFor="let image of galleryItems" 
            class="gallery-image" (click)="openModal(template, image.full)">
            <img loading="lazy" src="{{ image.thumb}}" alt="" class="img-responsive">
          </a>
      </div>
      </div>
    </div>
  </section>
</div>
<section class="divider dark">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div id="viper-specs" class="card spec-card">
              <h2 class="h2 text-uppercase">2017 Dodge Viper</h2>
              <ul>
                <li>An American supercar, handcrafted in Detroit</li>
                <li>8.4L all-aluminum V10 engine</li>
                <li>645 horsepower</li>
                <li>600 lb.-ft. torque – the most of any naturally aspirated sports car engine</li>
                <li>First-ever factory-production custom 1 of 1 Program</li>
                <li>Holds the most production car track records in the world, as certified by the SCCA</li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6">
            <div id="demon-specs" class="card spec-card">
              <h2 class="h2 text-uppercase">2018 Dodge Challenger SRT Demon</h2>
              <ul>
                <li>The most powerful muscle car ever with 840 horsepower and 770 lb.-ft. torque from a supercharged 6.2L HEMI<sup class="realign superscript">®</sup> SRT<sup class="realign superscript">®</sup>  Demon V8 engine</li>
                <li>World’s fastest quarter mile production car with an ET of 9.65 seconds at 140 mph, as certified by the NHRA</li>
                <li>World’s fastest 0-60 production car at 2.3 seconds</li>
                <li>Highest g-force acceleration of any production car at 1.8g</li>
                <li>First-ever front wheel lift in a production car at 2.92 ft., as certified by Guinness World Records</li>
                <li>Dodge Challenger SRT Demon is too fast for the drag strip – officially banned by the NHRA</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template id="image-gallery-modal" #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img loading="lazy" class="img-responsive" [src]="activeImage" alt="">
  </div>
</ng-template>