import {Component, OnInit, Input, Output, ViewChild, Inject, PLATFORM_ID, EventEmitter } from '@angular/core';
import { VideoService } from '../../../video/video.service';
import { Video } from '../../../video/video';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Location, isPlatformBrowser } from '@angular/common';
import { HelperService } from '../../../services/helper.service';
import {environment} from "../../../../environments/environment";

declare let addthis: any;

@Component({
  selector: '[videosTab]',
  templateUrl: './video-tabs.component.html',
  styles: []
})
export class VideoTabsComponent implements OnInit {

  @Input() listInfo: any = {
    name: '',
    description: '',
    uri: ''
  };
  @Input() max: number = 0; // zero for unlimited
  videos: Video[] = [];
  @Output() selectedVideo = new EventEmitter<Video>();
  @ViewChild(ModalDirective, { static: true }) public videoModal: ModalDirective;
  videoModalRef: BsModalRef;
  previousURL: string = '/videos';
  isUpdateURL: boolean = false;

  // JSON+LD Schema
  schema: object = {}
  videoObjectList: any = [];

  constructor(
    private helperService: HelperService,
    private location: Location,
    private videoService: VideoService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.fetchVideos();
  }

  fetchVideos() {
    this.videoService.getVimeoVideosByAlbum(this.listInfo.uri).subscribe( data => {
      if (data.data.length > 0) {
        for (let index = 0; index < ((this.max > 0)?this.max:data.data.length); index++) {
          let video = this.videoService.mutateVimeoVideos(data.data[index]);
          this.videos.push(video);
          this.videoObjectList.push({
            "@type": "VideoObject",
            "@id": video.id.toString(),
            "name": video.name,
            "description": video.description,
            "uploadDate": video.createdTime,
            "thumbnail": video.thumbnail,
            "thumbnailUrl": video.thumbnail,
            "contentUrl": environment.url + video.uri,
            "url": environment.url + video.uri
            }
          )
        }
        this.schema = {
          "@context": "http://schema.org",
          "@type": "Collection",
          "name": this.listInfo.name,
          "description": this.listInfo.description,
          "hasPart": this.videoObjectList,
          "isPartOf": {
            "@type": "WebPage",
            "@id": environment.url + '/videos',
            "url": environment.url + '/videos',
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Videos",
                  "item": environment.url + "/videos"
                },
              ]
            }
          }
        }
      }
    });
  }

  getVideoObjects() {
    return this.videoObjectList;
  }

  pushSelectedVideo(value, event) {
    event.preventDefault();
    this.selectedVideo.emit(value);
  }

  // openVideoModal(video: Video, event) {
  //   event.preventDefault();
  //   this.selectedVideo = video;
  //   this.helperService.trackGAEvent(this.previousURL, 'Click', 'Video Modal - ' + this.selectedVideo.name);
  //   this.videoModal.show();
  // }

  // closeVideoModal(isUpdateURL: boolean = true) {
  //   // Add any magic to fire when closing the modal
  //   this.videoModal.hide();
  //   this.isUpdateURL = isUpdateURL;
  //   this.location.replaceState(this.previousURL);
  //   this.selectedVideo = new Video();
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.removeAddThis();
  //   }
  // }

  // videoModalListener(type: string, $event: ModalDirective) {
  //   // Catch closing on backdrop click only
  //   if (type == 'onHidden' && $event.dismissReason == 'backdrop-click') {
  //     this.location.replaceState(this.previousURL);
  //   }
  // }

  // removeAddThis() {
  //   const script = document.createElement('script');
  //   script.src ='//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
  //   const scripts = document.getElementsByTagName('script');
  //   for (let i = scripts.length; i--;) {
  //     if (scripts[i].src == script.src) {
  //       // Remove script if not the proper url
  //       scripts[i].parentNode.removeChild(scripts[i]);
  //       return true;
  //     }
  //   }
  //   return false;
  // }

}
