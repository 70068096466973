<!-- <ul class="list-unstyled event-schedule">
  <li class="lead text-uppercase">
    <span>Friday</span> // March 8
  </li>
  <li class="sub text-uppercase">
    Live around 9:00 AM EST
  </li>
  <li><span class="time">9:00 AM</span> Test & Tune – Index Classes </li>
  <li><span class="time">10:00 AM</span> Test & Tune – Heads Up Classes </li>
  <li>True Street, HEMI<sup>®</sup> Shootout, Chevy Street Car Challenge & Bracket Time Trials</li>
  <li><span class="time">NOON</span> Test & Tune – Index Classes</li>
  <li><span class="time">1:30 PM</span> Test & Tune – Heads Up Classes</li>
  <li><span class="time">3:30 PM</span> NMCA Index Class Qualifying #1</li>
  <li>True Street, HEMI Shootout, Chevy Street Car Challenge Time Trials & Bracket Dial in for $</li>
  <li><span class="time">5:00 PM</span> NMCA Heads Up Qualifying #1 </li>

  <li class="lead text-uppercase" style="margin-top: 15px;">
    <span>Saturday</span> // March 9
  </li>
  <li class="sub text-uppercase">
    Live around 8:30 AM EST
  </li>
  <li><span class="time">8:30 AM</span> NMCA Index Class Qualifying #2</li>
  <li>Bracket Time Trial</li>
  <li><span class="time">11:00 AM</span> NMCA Heads Up Qualifying #2</li>
  <li>True Street, HEMI Shootout, and Chevy Street Car Challenge Time Trials</li>
  <li>Bracket Race Elims Rd #1</li>
  <li><span class="time">2:00 PM</span> NMCA Index Qualifying #3</li>
  <li>HEMI Shootout and Chevy Street Car Challenge Eliminations</li>
  <li>Bracket Eliminations Rd #2</li>
  <li><span class="time">4:00 PM</span> NMCA Heads-Up Qualifying #3</li>
  <li>True Street Eliminations</li>
  <li>Complete Bracket Eliminations</li>

  <li class="lead text-uppercase" style="margin-top: 15px;">
    Daylight savings time change! Roll clocks forward 1 hour
  </li>

  <li class="lead text-uppercase" style="margin-top: 15px;">
    <span>Sunday</span> // March 10
  </li>
  <li class="sub text-uppercase">
    Live around 8:45 AM EST
  </li>
  <li><span class="time">8:45 AM</span> NMCA INDEX Class Eliminations Rd #1</li>
  <li>HEMI Quick 8 Eliminations</li>
  <li>Bracket Time Trial</li>
  <li><span class="time">11:00 AM</span> NMCA Heads-Up Eliminations</li>
  <li>Bracket Eliminations Rd #1</li>
  <li><span class="time">4:30 PM</span> Final Round All Eliminators</li>
</ul>

<p>*All times in EST & are subject to change. </p> -->


<div class="mb-3" style="padding: 0 15px;"><img loading="lazy" src="/assets/images/rkn/road-kill-logo-white-double.png" alt="Roadkill Nights Logo" class="img-responsive"></div>
<ul class="list-unstyled event-schedule">
  <li class="lead text-uppercase">
    <span>Saturday</span> // August 14
  </li>
  <li class="sub text-uppercase font-bold" style="margin-bottom: 10px; padding-bottom: 5px; border-bottom: 1px solid gray;">
    11 AM - 9 PM Coverage Includes:
  </li>
  <li class="sub text-uppercase" style="margin-bottom: 10px; padding-bottom: 10px; border-bottom: 1px solid gray;">
    Street-legal Drag racing on Woodward Ave
  </li>
  <li class="sub text-uppercase" style="margin-bottom: 10px; padding-bottom: 10px; border-bottom: 1px solid gray;">
    <a routerLink="/roadkill-nights" data-ga_category="Home" data-ga_action="Click" data-ga_label="RKN Landing Page from Livestream Schedule">Hellcat Grudge Race</a>
  </li>
  <li class="sub text-uppercase" style="margin-bottom: 10px; padding-bottom: 10px; border-bottom: 1px solid gray;">
    Interviews &amp; Special Appearances
  </li>
  <li class="sub text-uppercase" style="margin-bottom: 10px; padding-bottom: 10px; border-bottom: 1px solid gray;">
    Flamethrower &amp; wheelstander exhibition runs
  </li>
  <li class="sub text-uppercase" style="margin-bottom: 10px; padding-bottom: 10px; border-bottom: 1px solid gray;">
    Pro-Mod Exhibition Runs
  </li>
</ul>