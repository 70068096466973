<div id="hpds" class="site-wrapper">
  <div class="alert bg-danger" *ngIf="!expired">
    <div class="row">
      <div class="container-fluid">
        <div class="col-sm-12">
          The Bondurant Racing School and the Bob Bondurant School of High Performance Driving will be closed Sunday, July 1, through Saturday, July 7 in recognition of Independence Day.
        </div>
      </div>
    </div>
  </div>
  <header>
    <div class="container racing-hq">
      <div class="row">
        <div class="col-sm-12 hero-section">
          <div class="heading-contain">
            <p class="faq-links-mobile">
              <a routerLink="/track-experience" class="btn btn-outline-primary">Back to Overview</a><br><br><a href="/srt/" class="btn btn-primary">SCHEDULE & VIN SUBMISSION</a>
            </p>

            <h1 class="heading1 pull-left">
              Frequently Asked Questions
            </h1>

            <p class="pull-right faq-links">
              <a routerLink="/track-experience" class="btn btn-outline-primary">Back to Overview</a>&nbsp;&nbsp;<a href="/srt/" class="btn btn-primary">SCHEDULE & VIN SUBMISSION</a>
            </p>

          </div>
        </div>
      </div>
    </div>
  </header>

  <section>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">

          <dg-accordion [id]="'faq-accordion'" [multiselectable]="true">

            <dg-panel-collapse [title]="'drag-five'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-five">How much does it cost to attend a Radford Racing School class?</h2>
              </ng-container>
              <ng-container body>
                <p>If you purchased or leased your new Dodge//SRT<sup>&reg;</sup> vehicle, registration was included in the cost of your vehicle, so there is no additional charge (U.S. vehicles only for the SRT Experience. U.S. and Canadian vehicles for the 2-Day Drag Racing Class). The regular cost for the SRT Experience is $1,599.00 and the cost for the 2-Day Drag Racing Class is $4,699.00. <a href="https://www.radfordracingschool.com/" target="_blank" rel="noopener noreferrer">Visit Radford for information on other available classes. </a></p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-six'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-six">How do I know if the registration fee was included in the cost of my vehicle?</h2>
              </ng-container>
              <ng-container body>
                <p>Only the current, qualifying Dodge//SRT models are included in the drop down for submission. If your model is not listed and you’d like additional confirmation, call the Dodge//SRT Concierge Team Monday through Friday from 8:30 a.m. – 5:00 p.m. ET at 800-998-1110 and we’ll check your VIN for you.</p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-seven'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-seven">Do leased cars qualify?</h2>
              </ng-container>
              <ng-container body>
                <p>
                  Yes, if you leased your Dodge//SRT vehicle within the last year, and the VIN has not already been used to participate, there is no additional cost to attend. You have 1 year from the date you signed your lease to redeem your VIN.
                </p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-eight'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-eight">How long do I have to use my Dodge//SRT<sup>&reg;</sup> VIN if registration was included with the cost of my car?</h2>
              </ng-container>
              <ng-container body>
                <p>
                  You have one year from the date you take delivery to schedule your SRT Experience or three years from your delivery date to schedule the 2-Day Drag Racing Class (SRT Demon 170 owners only), as long as the VIN has not been previously used.
                </p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-ten'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-ten">Can I register for free if I own a non-U.S.-market vehicle?</h2>
              </ng-container>
              <ng-container body>
                <p>Only U.S.-market Dodge//SRT vehicles included the SRT Experience Class with their purchase. Both U.S. and Canadian SRT Demon 170 owners may redeem their VIN for the 2-Day Drag Racing Class. All other non-U.S.-market vehicle owners are welcome to attend a class at the normal cost, and should <a href="https://www.radfordracingschool.com" target="_blank" rel="noopener noreferrer">visit Radford Racing School to see available class options.</a></p>
              </ng-container>
            </dg-panel-collapse>

            <!-- <dg-panel-collapse [title]="'drag-eleven'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-eleven">How do I register to attend?</h2>
              </ng-container>
              <ng-container body>
                <p>The first step is verifying your Dodge SRT vehicle ownership. You can either: </p>
                <ol>
                  <li><a href="/srt/register.action">Submit your VIN online for verification</a></li>
                  <li>Call us at <a href="tel:18009981110">800-998-1110</a></li>
                  <li>Email <a href="mailto:SRTTE@dodgegarage.com">SRTTE@dodgegarage.com</a> the following information:<br>
                    First and Last Name<br>
                    Phone Number<br>
                    Complete VIN<br>
                    Year & Model of your Dodge SRT vehicle<br>
                  </li>
                </ol>
              </ng-container>
            </dg-panel-collapse> -->

            <dg-panel-collapse [title]="'drag-twelve'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-twelve">Can I transfer my VIN to a friend or relative?</h2>
              </ng-container>
              <ng-container body>
                <p>Only a spouse or child 18 or older living in the same household as the owner are permitted to use the owner's VIN to attend at no additional cost. If you are using your spouse's VIN, upload a letter from the owner granting permission for the VIN to be redeemed for the SRT Experience. The letter should contain the VIN, owner's name, address, phone number, name of person they approve to redeem the VIN and signature. </p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-thirteen'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-thirteen">Can I bring a friend who wants to participate as a driver?</h2>
              </ng-container>
              <ng-container body>
                <p>Absolutely! Driving guests are allowed if space is available at the normal cost. For the SRT Experience, the registration fee is $1,499.00. For the 2-Day Drag Racing Class, the registration fee is $4,699.00.</p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-fourteen'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-fourteen">Can I bring someone who doesn’t want to drive but just wants to come as a spectator?</h2>
              </ng-container>
              <ng-container body>
                <p>Yes! There are designated areas for spectators to watch the action. </p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-fifteen'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-fifteen">Are there age restrictions for the SRT Experience and 2-Day Drag Racing Classes?</h2>
              </ng-container>
              <ng-container body>
                <p>You must be 18 to participate, and must register to attend. All drivers must show a valid state issued driver's license.</p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-sixteen'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-sixteen">What vehicles will I get to drive?</h2>
              </ng-container>
              <ng-container body>
                <p>SRT Experience drivers will be in a Challenger SRT Hellcat Widebody. Participants of the 2-Day Drag Racing Class will be in a 2018 Challenger SRT Demon on day one and a 2023 Challenger SRT Demon 170 on day two, with the exception of the Charger Skid Car during that exercise.</p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-seventeen'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-seventeen">Can I drive my own car?</h2>
              </ng-container>
              <ng-container body>
                <p>No, you will be driving Radford’s fleet of Dodge//SRT vehicles.</p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-eighteen'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-eighteen">Can I take pictures and videos?</h2>
              </ng-container>
              <ng-container body>
                <p>Absolutely! Photography and videography are both permitted. However, equipment that requires mounting isn’t allowed.</p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-nineteen'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-nineteen">What if I have health restrictions?</h2>
              </ng-container>
              <ng-container body>
                <p>Please let us know what your health restrictions are when you register, and we will do our best to accommodate you.</p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-twenty'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-twenty">Can I bring my own helmet?</h2>
              </ng-container>
              <ng-container body>
                <p>Radford has helmets for use. A driver can use their own, as long as it is in good physical condition, and is at least an open-face helmet. No skull caps or quarter helmets will be permitted. The instructors will determine whether or not a driver’s helmet is suitable for use.</p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-twenty-one'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-twenty-one">Is a lunch provided?</h2>
              </ng-container>
              <ng-container body>
                <p>Yes! A boxed lunch is provided, but you're welcome to take your hour lunch break at a nearby restaurant if you'd prefer. </p>
              </ng-container>
            </dg-panel-collapse>

             <dg-panel-collapse [title]="'drag-nine'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-nine">Where is Radford Racing School?</h2>
              </ng-container>
              <ng-container body>
                <address>
                  Radford Racing School<br>
                  20000 S. Maricopa Rd.<br>
                  Gate 3<br>
                  Chandler AZ, 85226
                </address>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-twenty-two'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-twenty-two">When are the classes?</h2>
              </ng-container>
              <ng-container body>
                <p><a href="/srt/">Please view the current schedule for available class dates.</a></p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-twenty-three'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-twenty-three">How do I register to attend?</h2>
              </ng-container>
              <ng-container body>
                <p><a href="/srt/">The first step is to submit your Dodge//SRT VIN for validation.</a></p>
              </ng-container>
            </dg-panel-collapse>

            <dg-panel-collapse [title]="'drag-twenty-four'">
              <ng-container heading>
                <h2 class="heading2 question-q red">Q.</h2>
                <h2 class="question heading2 uppercase" id="question-collapse-drag-twenty-four">What is the cancellation or rescheduling policy?</h2>
              </ng-container>
              <ng-container body>
                <p>Cancellations and rescheduling are only permitted due to a family emergency or military deployment.</p>
              </ng-container>
            </dg-panel-collapse>
          </dg-accordion>
        </div>
      </div>
    </div>
  </section>
</div>
