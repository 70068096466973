import {Component, Input, OnInit} from '@angular/core';
import {PanelCollapseComponent} from "../panel-collapse/panel-collapse.component";

@Component({
  selector: 'dg-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.less']
})
export class AccordionComponent implements OnInit {

  @Input() id: string = '';
  @Input() multiselectable: boolean = true;
  protected panels: PanelCollapseComponent[] = [];

  constructor() { }

  ngOnInit() {
  }

  closeOtherPanels(openPanel: PanelCollapseComponent) {
    if (this.multiselectable) {
      return;
    }
    this.panels.forEach((panel: PanelCollapseComponent) => {
      if (panel !== openPanel) {
        panel.collapse = 'closed';
      }
    });
  }

  addPanel(panel: PanelCollapseComponent): void {
    this.panels.push(panel);
  }

}
