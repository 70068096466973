import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {expandCollapse} from "../../animations";
import {AccordionComponent} from "../accordion/accordion.component";

@Component({
  selector: 'dg-panel-collapse',
  templateUrl: './panel-collapse.component.html',
  styleUrls: ['./panel-collapse.component.less'],
  animations: [ expandCollapse ]
})
export class PanelCollapseComponent implements OnInit {

  @Input() title: string;
  @Input() collapse: string = 'closed';
  parentId: string = 'accordion';

  protected accordion: AccordionComponent;

  constructor(@Inject(AccordionComponent) accordion: AccordionComponent) {
    this.accordion = accordion;
  }

  ngOnInit() {
    this.accordion.addPanel(this);
    if (this.accordion) {
      this.parentId = this.accordion.id;
    }
  }

  toggleCollapse(event) {
    event.preventDefault();
    this.collapse = this.collapse == 'open' ? 'closed' : 'open';
    this.accordion.closeOtherPanels(this);
  }


}
