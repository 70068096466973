<div class="search">
  <div class="">
    <form method="get" id="searchform" (submit)="searchGallery()">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Search" name="s" id="gallerySearch" [(ngModel)]="gallerySearch" aria-label="Gallery Search">
        <span class="input-group-btn">
          <button class="btn" type="submit"><i class="fa fa-search"></i><span class="sr-only">Search</span></button>
        </span>
      </div>
    </form>
  </div>
</div>
