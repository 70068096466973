import { Injectable } from '@angular/core';
import { HelperService } from '../services/helper.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TwentyDaysOfHorsepowerDealer} from './dealer';

@Injectable()
export class TwentyDaysOfHorsepowerService {
  constructor(
    public helperService: HelperService,
    private http: HttpClient
  ) {

  }

  env: string = environment.url === 'http://localhost:4200' ? '//localhost:9080' : environment.url

  // TODO Resolve when data is wired up. For now, return static array
  public getDealers(): Observable<any> {
    return this.http.get(`https://test.dodgegarage.com/rest/20-days-of-hp`)
  }

  // public getEntries(id: Number, vehicle: String, date?: String): Observable<any> {
  //   return this.http.get(`${this.env}/rest/20-days-of-hp`, {
  //     vehicle: vehicle,
  //     drawingDate: date
  //   })
  // }

  public getEntries(data: { raffle: number; vehicle: string; drawingDate: string; winner?: string }): Observable<any> {
    return this.http.post<any>(`${this.env}/sales-raffle-api/raffle/getEntries`, data); 
  }
  
  public getWinners(data: { raffle: number; vehicle: string; drawingDate: string; winner?: string }): Observable<any> {
    return this.http.post<any>(`${this.env}/sales-raffle-api/raffle/getWinners`, data);
  }

  // private rng(min: number, max: number): any {
  //   if (min >= max) {
  //     throw new Error('Minimum value must be less than the maximum value');
  //   }
  
  //   return Math.floor(Math.random() * (max - min + 1)) + min;
  // }

  // public getDealers(): any {

  //   let arr = []
  //   for (let i = 0; i < 25; i++) {
  //     arr.push({
  //       bc: this.rng(10, 40),
  //       code: this.rng(10000, 99999),
  //       name: 'Dealer Name',
  //       units: this.rng(1, 20)
  //     })
  //   }

  //   return arr
  // }
}
