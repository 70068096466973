import { Component, OnInit, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService } from "../services/helper.service";

@Component({
  selector: 'auction-brag-book',
  templateUrl: './auction-brag-book.component.html'
})
export class AuctionBragBookComponent implements OnInit {

  book: string = 'https://indd.adobe.com/embed/23e94c00-d6df-400d-8553-0471b8e08dcf?startpage=1&allowFullscreen=false&branding=false';
  //height: any;


  constructor(
    public helperService: HelperService,
    public sanitizer: DomSanitizer,
    private router: Router,
    public title: Title,
    public metaService: Meta
  ) {
    title.setTitle( 'The Ultimate Last Chance Auction | Dodge Garage'  );
    metaService.updateTag({ name: 'description', content: 'The Dodge Ultimate Last Chance Auction Book' });
    metaService.updateTag({ property: 'og:description', content: 'The Dodge Ultimate Last Chance Auction Book' });
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
    metaService.updateTag({ property: 'og:title', content: 'Dodge Ultimate Last Chance Auction Book | Dodge Garage' });
    metaService.updateTag({ property: 'og:type', content: '' });
    metaService.updateTag({ property: 'og:image', content: 'https://www.dodgegarage.com/assets/images/ultimate-last-chance/last-chance-social-thumb.jpg' });
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url });
    metaService.updateTag({ name: 'twitter:card', content: 'summary' });
    metaService.updateTag({ name: 'twitter:image', content: 'https://www.dodgegarage.com/assets/images/ultimate-last-chance/last-chance-social-thumb.jpg' });
  }

  ngOnInit() {
    
  }
  ngAfterViewInit() {
    //this.onResize(event);
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   const footerHeight = document.querySelector('.footer').clientHeight
  //   this.height = event.target.innerHeight - footerHeight;
  // }

}
