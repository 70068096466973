<div class="alert bg-danger alert-banner">
  <div class="row">
    <div class="col-sm-12 flex-container">
      <div class="context-icon">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </div>
      <div class="context-message">
        <h4>Notice</h4>
        <div>
          <p>Your account needs to be activated{{additionalText}}. If you need to resend the activation email, please <a href="#" (click)="resendActivation($event)"  class="alert-link">click here</a>.</p>
          <p *ngIf="alert.additionalMessage">{{alert.additionalMessage}}</p>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
