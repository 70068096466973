<div class="my-garage" [ngClass]="{'my-garage-container': isOwnersProfile, 'public-profile': !isOwnersProfile}">
  <!--  (window:resize)="onResize(event)" -->

  <div class="site-wrapper">
    <div class="container-fluid">
      <div class="row garage-user-header">
        <div [ngClass]="(isOwnersProfile == false) ? 'header-public' : 'header-user'">
          <h1 class="heading1" *ngIf="isOwnersProfile"><small>My Garage<br></small>Welcome, <br class="visible-xs"> {{user.firstName}} {{user.lastName}}</h1>
          <h1 class="heading1" *ngIf="!isOwnersProfile">{{user.username}}'s Garage</h1>
        </div>
        <div class="col-md-5" *ngIf="isOwnersProfile">
          <div class="manage-account">
            <a class="btn btn-ghost-primary text-uppercase" [routerLink]="'/manage-account'">Manage Account</a>
            <a class="btn btn-cart btn-primary text-uppercase" (click)="checkout()">
              <span>Cart</span>
              <span class="quantity">{{cartQuantity}}</span>
            </a>
            <a class="btn btn-ghost-primary btn-leaderboard text-uppercase" [routerLink]="'/1320-club'">View 1320 Leaderboards</a>
            <a class="btn btn-ghost-primary btn-leaderboard text-uppercase" [routerLink]="'/1320-club/8-seconds'">View 8-Second Leaderboards</a>
            <!-- <div class="addthis_inline_share_toolbox text-right" attr.data-title="{{user.username + '\'s Garage | Dodge Garage'}}" attr.data-media="{{featuredImage}}"></div> -->
            <!--<div class="flyout" [ngClass]="{'is-open': isSharingIconActive}">
              <div class="flyout-menu">
                <share-buttons></share-buttons>
              </div>
              <button class="btn" (click)="isSharingIconActive = !isSharingIconActive"><i class="fa fa-share-alt" aria-hidden="true"></i></button>
            </div>-->
          </div>
        </div>
        <div *ngIf="!isOwnersProfile">
          <!-- <div class="addthis_inline_share_toolbox text-right" attr.data-title="{{user.username + '\'s Garage | Dodge Garage'}}" attr.data-media="{{featuredImage}}"></div> -->
          <!--<div class="flyout" [ngClass]="{'is-open': isSharingIconActive}">
            <div class="flyout-menu">
              <share-buttons></share-buttons>
            </div>
            <button class="btn" (click)="isSharingIconActive = !isSharingIconActive"><i class="fa fa-share-alt" aria-hidden="true"></i></button>
          </div>-->
        </div>
      </div>
    </div>
  </div>

  <!-- Bring back tabs if we need them for now we don't -->
<!--  <tabset class="my-garage-tabs">-->
<!--    <tab [heading]="'My Vehicles'" [id]="'my-vehicles'">-->
  <div id="my-vehicles-wrapper">
    <div class="site-wrapper">
      <div class="flex-header" *ngIf="isOwnersProfile">
        <h2 class="heading1">My Vehicles</h2>
          <button class="btn btn-primary btn-add-vehicle" (click)="openVehicleModal(vehicleModal)"><i class="fa fa-plus" aria-hidden="true"></i>
            <span class="hidden-xs">Add a 1320 Qualifying Vehicle</span>
            <span class="visible-xs-inline">Add</span>
        </button>
      </div>
      <div class="vehicle-tabs-container">
        <tabset class="vehicle-tabs" #vehicleTabs>

          <ng-container *ngIf="noVehicles">
            <div class="tab-content">
              <div class="tab-pane active">
                <div class="row vehicle-tab-lead">
                  <div class="col-sm-6 col-md-7">
                    <div class="vehicle-img-container" [ngStyle]="{'background-image': 'url(' + defaultImg + ')'}">
                      <div>
                        <img loading="lazy" class="img-responsive" src="{{defaultImg}}" alt="">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-5 vehicle-details">
                    <h3 class="vehicle-header">No active vehicles</h3>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!noVehicles">
            <tab *ngFor="let vehicle of user.vehicles" [heading]="vehicle.year+' '+' '+vehicle.model+' '+vehicle.trim" [id]="vehicle.id" [active]="vehicle.id == selectedVehicleId">
              <div class="row vehicle-tab-lead">
                <div class="col-sm-6 col-md-7">
                  <!-- If vehicle image exists -->
                  <div class="vehicle-img-container"
                    *ngIf="vehicle.images && vehicle.images.length > 0"
                    [ngStyle]="{'background-image': 'url(' + vehicle.images[0].imagePath + ')'}">
                    <img loading="lazy" class="img-responsive" [src]="vehicle.images[0].imagePath" alt="{{vehicle.year}} {{vehicle.model}} {{vehicle.trim}}">
                  </div>
                  <!-- If vehicle image does not exist -->
                  <div class="vehicle-img-container"
                    *ngIf="vehicle.images && vehicle.images.length == 0"
                    [ngStyle]="{'background-image': vehicle.isStock ? 'url(' + stockImg + ')' : 'url(' + modifiedImg + ')'}">
                    <div *ngIf="vehicle.isStock; else notStock">
                      <img loading="lazy" class="img-responsive" src="{{stockImg}}" alt="{{vehicle.year}} {{vehicle.model}} {{vehicle.trim}}">
                    </div>
                    <ng-template #notStock>
                      <img loading="lazy" class="img-responsive" src="{{modifiedImg}}" alt="{{vehicle.year}} {{vehicle.model}} {{vehicle.trim}}">
                    </ng-template>
                  </div>
                </div>
                <div class="col-sm-6 col-md-5 vehicle-details">
                  <button *ngIf="isOwnersProfile" class="btn btn-link btn-edit-vehicle" (click)="openVehicleModal(vehicleModal,vehicle)">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                    <span class="hidden-xs">Edit Vehicle</span>
                  </button>
                  <h3 class="vehicle-header">{{vehicle.year}} {{vehicle.model}} {{vehicle.trim}}</h3>
                  <p *ngIf="vehicle.isDemon">You're a Demon, Harry!</p>
                  <p *ngIf="vehicle.isEightSecond">And so gosh darn fast!</p>
                  <div class="vehicle-mods" *ngIf="vehicle.mods">
                    <h4 class="subheading">Modifications</h4>
                    <p class="vehicle-mods-desc">{{vehicle.mods}}</p>
                  </div>
                </div>
              </div>

              <div class="row" [ngClass]="vehicle.isDemon ? 'vehicle-demon' : ''">
                <!-- 1320 Club Badges -->
                <div class="badges-container profile-accordion" [ngClass]="vehicle.isDemon ? 'col-md-6' : 'col-lg-12'">
                  <button class="btn btn-primary btn-accordion" type="button" data-toggle="collapse" attr.data-target="#badges-{{vehicle.id}}" aria-expanded="false" attr.aria-controls="badges-{{vehicle.id}}">
                    1320 Achievements <i class="fa fa-chevron-down"></i>
                  </button>
                  <div class="badge-collapse collapse" attr.id="badges-{{vehicle.id}}">
                    <div class="well">
                      <div class="col-md-3 cta-1320">
                        <h3 class="hidden-xs">1320 Club Badges</h3>
                        <div class="cta-text">
                          <p *ngIf="isOwnersProfile">Your top achievements so far for this vehicle.<br>Use the arrows to find your best achievement to purchase decal.</p>
                          <p *ngIf="!isOwnersProfile">Top achievements so far for this vehicle.</p>
                        </div>
                      </div>
                      <div class="col-sm-2" data-badge="Decal and Hat">
                        <div class="badge-boxes member-badge" [ngClass]="{'memmber-cta': isOwnersProfile }">
                          <div class="badge-box">
                            <div *ngIf="!badgesLoaded" class="article-loader text-center">
                              <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                              <span class="sr-only">Loading...</span>
                            </div>

                            <div *ngFor="let badge of vehicle.badgesClub" class="badge-1320">
                              <div class="single-badge">
                                <div class="img">
                                  <a href="#" *ngIf="isOwnersProfile" (click)="openImageModal(imgModal, badge.detail.logo, $event)">
                                    <img loading="lazy" [src]="badge.detail.logo" class="img-responsive" alt="club badge">
                                  </a>
                                  <img loading="lazy" [src]="stockBadgeImg" *ngIf="!isOwnersProfile && vehicle.isStock" class="img-responsive" alt="stock badge">
                                  <img loading="lazy" [src]="modifiedBadgeImg" *ngIf="!isOwnersProfile && !vehicle.isStock" class="img-responsive" alt="modified badge">
                                </div>
                                <div class="btns">
                                  <button *ngIf="isOwnersProfile" (click)="addEcwidProduct(badge.detail.storeProductId)" class="btn btn-primary">Add to Cart<br>$25 // Hat &amp; Decal</button>
                                  <button *ngIf="isOwnersProfile && vehicle.isStock" (click)="addEcwidProduct(133386474)" class="btn btn-primary">Add to Cart<br>$8 // Decal Only</button>
                                  <button *ngIf="isOwnersProfile && !vehicle.isStock" (click)="addEcwidProduct(133386013)" class="btn btn-primary">Add to Cart<br>$8 // Decal Only</button>
                                </div>
                              </div>
                            </div>
                            <ng-container *ngIf="isOwnersProfile && vehicle.badgesClub?.length === 0">
                              <div class="single-badge">
                                <a href="#" (click)="openImageModal(imgModal, 'assets/images/1320/badges/stock/club/hats.jpg', $event)">
                                  <img loading="lazy" src="assets/images/1320/badges/stock/club/hats.jpg" class="img-responsive" alt="club badge">
                                </a>
                                <button *ngIf="vehicle.isStock" (click)="addEcwidProduct(121223635)" class="btn btn-primary">Add to Cart<br>$25 // Hat &amp; Decal</button>
                                <button *ngIf="!vehicle.isStock" (click)="addEcwidProduct(121223720)" class="btn btn-primary">Add to Cart<br>$25 // Hat &amp; Decal</button>
                                <button *ngIf="vehicle.isStock" (click)="addEcwidProduct(133386474)" class="btn btn-primary">Add to Cart<br>$8 // Decal Only</button>
                                <button *ngIf="!vehicle.isStock" (click)="addEcwidProduct(133386013)" class="btn btn-primary">Add to Cart<br>$8 // Decal Only</button>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>

                      <ng-template #notFound>
                        <div class="badge-1320">
                          <div class="single-badge no-badge">
                            <p class="font-bold text-uppercase">Badge Not Earned</p>
                          </div>
                        </div>
                      </ng-template>

                      <!-- 1/4 Mile Time -->
                      <div class="col-sm-2" data-badge="1/4 Mile Time">
                        <div class="badge-boxes" data-category="et">
                          <span class="badge-label">1/4 Mile ET</span>
                          <button class="btn-slot slot-previous" (click)="counterEtDec(vehicle)" [disabled]="slotCounterET === 0"><i class="fa fa-chevron-up"></i><span class="sr-only">previous</span></button>
                          <button class="btn-slot slot-next" (click)="counterEtInc(vehicle)" [disabled]="vehicle.slotCounterETMax === 0 || slotCounterET === vehicle.slotCounterETMax - 1"><i class="fa fa-chevron-down"></i><span class="sr-only">next</span></button>

                          <div class="badge-box">
                            <div *ngIf="!badgesLoaded" class="article-loader text-center">
                              <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                              <span class="sr-only">Loading...</span>
                            </div>
                            <div class="badges" [ngStyle]="{'margin-top.px': slotCounterETOffset}">
                              <div *ngIf="vehicle.slotCounterETMax > 0; else notFound">
                                <div *ngFor="let badge of vehicle.badgesElapsedTime">
                                  <div class="badge-1320" *ngIf="badge.found == true || isOwnersProfile">
                                    <div class="single-badge">
                                      <img loading="lazy" [src]="badge.detail.logo" [ngClass]="{'disable': !badge.found}" class="img-responsive" alt="badge not earned">
                                      <button *ngIf="isOwnersProfile" [ngClass]="{'btn-unavailable disable': !badge.found && isOwnersProfile }" [disabled]="!badge.found" (click)="addEcwidProduct(badge.detail.storeProductId)" class="btn btn-primary" aria-label="Buy Badge">
                                        <span>$5 // Add To Cart</span>
                                        <span><i class="fa fa-lock"></i></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- 60ft Time -->
                      <div class="col-sm-2" data-badge="60ft Time">
                        <div class="badge-boxes"  data-category="sixtyfoot">
                          <span class="badge-label">60 Ft. Time</span>
                          <button class="btn-slot slot-previous" (click)="counterSixtyDec(vehicle)" [disabled]="slotCounterSixty === 0"><i class="fa fa-chevron-up"></i><p class="sr-only">previous</p></button>
                          <button class="btn-slot slot-next" (click)="counterSixtyInc(vehicle)" [disabled]="vehicle.slotCounterSixtyMax === 0 || slotCounterSixty === vehicle.slotCounterSixtyMax - 1"><i class="fa fa-chevron-down"></i><p class="sr-only">next</p></button>

                          <div class="badge-box">
                            <div *ngIf="!badgesLoaded" class="article-loader text-center">
                              <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                              <span class="sr-only">Loading...</span>
                            </div>
                            <div class="badges" [ngStyle]="{'margin-top.px': slotCounterSixtyOffset}">
                              <div *ngIf="vehicle.slotCounterSixtyMax > 0; else notFound">
                                <div *ngFor="let badge of vehicle.badgesSixtyFeetTime">
                                  <div class="badge-1320" *ngIf="badge.found == true || isOwnersProfile">
                                    <div class="single-badge" >
                                      <img loading="lazy" [src]="badge.detail.logo" [ngClass]="{'disable': !badge.found}" class="img-responsive" alt="badge not earned">
                                      <button *ngIf="isOwnersProfile" [ngClass]="{'btn-unavailable disable': !badge.found && isOwnersProfile }" [disabled]="!badge.found" (click)="addEcwidProduct(badge.detail.storeProductId)" class="btn btn-primary" aria-label="Buy Badge">
                                        <span>$5 // Add To Cart</span>
                                        <span><i class="fa fa-lock"></i></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="col-sm-2" data-badge="1/4 Mile Speed">
                        <div class="badge-boxes"  data-category="speed">
                          <span class="badge-label">1/4 Mile Speed</span>
                          <button class="btn-slot slot-previous" (click)="counterSpeedDec(vehicle)" [disabled]="slotCounterSpeed === 0"><i class="fa fa-chevron-up"></i><p class="sr-only">previous</p></button>
                          <button class="btn-slot slot-next" (click)="counterSpeedInc(vehicle)" [disabled]="vehicle.slotCounterSpeedMax === 0 || slotCounterSpeed === vehicle.slotCounterSpeedMax - 1"><i class="fa fa-chevron-down"></i><p class="sr-only">next</p></button>

                          <div class="badge-box">
                            <div *ngIf="!badgesLoaded" class="article-loader text-center">
                              <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                              <span class="sr-only">Loading...</span>
                            </div>

                            <div class="badges" [ngStyle]="{'margin-top.px': slotCounterSpeedOffset}">
                              <div *ngFor="let badge of vehicle.badgesTopSpeed">
                                <div *ngIf="vehicle.slotCounterSpeedMax > 0; else notFound">
                                  <div class="badge-1320" *ngIf="badge.found == true || isOwnersProfile">
                                    <div class="single-badge" >
                                      <img loading="lazy" [src]="badge.detail.logo" [ngClass]="{'disable': !badge.found}" class="img-responsive" alt="badge not earned">
                                      <button *ngIf="isOwnersProfile" [ngClass]="{'btn-unavailable disable': !badge.found && isOwnersProfile }" [disabled]="!badge.found" (click)="addEcwidProduct(badge.detail.storeProductId)" class="btn btn-primary" aria-label="Buy Badge">
                                        <span>$5 // Add To Cart</span>
                                        <span><i class="fa fa-lock"></i></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  <!-- End 1320 Club Badges -->

                <!-- 8-Second Club Badges -->
                <div *ngIf="vehicle.isDemon" class="badges-container profile-accordion col-md-6">
                  <button class="btn btn-primary btn-accordion" type="button" data-toggle="collapse" attr.data-target="#badges-8sc-{{vehicle.id}}" aria-expanded="false" attr.aria-controls="#badges-8sc-{{vehicle.id}}">
                    8-Second Club Achievements <i class="fa fa-chevron-down"></i>
                  </button>
                  <div class="badge-collapse collapse" attr.id="badges-8sc-{{vehicle.id}}">
                    <div class="well">

                      <div *ngIf="vehicle.isDemon && !vehicle.isEightSecond" class="well-overlay">
                        <span>Get out there and keep racing to qualify for the 8-Second Club badges</span>
                      </div>

                      <div class="col-md-3 cta-1320">
                        <h3 class="hidden-xs">8-Second Club Badges</h3>
                        <div class="cta-text">
                          <p *ngIf="isOwnersProfile">Your top achievements so far for this vehicle.<br>Use the arrows to find your best achievement to purchase decal.</p>
                          <p *ngIf="!isOwnersProfile">Top achievements so far for this vehicle.</p>
                        </div>
                      </div>
                      <div class="col-sm-2" data-badge="Decal and Hat">
                        <div class="badge-boxes member-badge" [ngClass]="{'memmber-cta': isOwnersProfile }">
                          <div class="badge-box">
                            <div *ngIf="!badgesLoaded" class="article-loader text-center">
                              <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                              <span class="sr-only">Loading...</span>
                            </div>
                            <div *ngFor="let badge of vehicle.badgesEightSecondClub" class="badge-1320">
                              <div class="single-badge">
                                <div class="img">
                                  <a href="#" *ngIf="isOwnersProfile" (click)="openImageModal(imgModal, badge.detail.logo, $event)">
                                    <img loading="lazy" [src]="badge.detail.logo" class="img-responsive" alt="club badge">
                                  </a>
                                  <img loading="lazy" [src]="stockBadgeImg" *ngIf="!isOwnersProfile && vehicle.isStock" class="img-responsive" alt="stock badge">
                                  <img loading="lazy" [src]="modifiedBadgeImg" *ngIf="!isOwnersProfile && !vehicle.isStock" class="img-responsive" alt="modified badge">
                                </div>
                                <div class="btns">
                                  <button *ngIf="isOwnersProfile" (click)="addEcwidProduct(badge.detail.storeProductId)" class="btn btn-primary">Add to Cart<br>$25 // Hat &amp; Decal</button>
                                  <button *ngIf="isOwnersProfile && vehicle.isStock" (click)="addEcwidProduct(133386474)" class="btn btn-primary">Add to Cart<br>$8 // Decal Only</button>
                                  <button *ngIf="isOwnersProfile && !vehicle.isStock" (click)="addEcwidProduct(133386013)" class="btn btn-primary">Add to Cart<br>$8 // Decal Only</button>
                                </div>
                              </div>
                            </div>
                            <ng-container *ngIf="isOwnersProfile && vehicle.badgesEightSecondClub?.length === 0">
                              <div class="badge-1320">
                                <div class="single-badge">
                                  <div class="img">
                                    <a href="#" (click)="openImageModal(imgModal, 'assets/images/1320/badges/stock/club/hats.jpg', $event)">
                                      <img loading="lazy" src="assets/images/1320/badges/stock/club/hats.jpg" class="img-responsive" alt="club badge">
                                    </a>
                                  </div>
                                  <div class="btns">
                                    <button *ngIf="vehicle.isStock" (click)="addEcwidProduct(121223635)" class="btn btn-primary">Add to Cart<br>$25 // Hat &amp; Decal</button>
                                    <button *ngIf="!vehicle.isStock" (click)="addEcwidProduct(121223720)" class="btn btn-primary">Add to Cart<br>$25 // Hat &amp; Decal</button>
                                    <button *ngIf="vehicle.isStock" (click)="addEcwidProduct(133386474)" class="btn btn-primary">Add to Cart<br>$8 // Decal Only</button>
                                    <button *ngIf="!vehicle.isStock" (click)="addEcwidProduct(133386013)" class="btn btn-primary">Add to Cart<br>$8 // Decal Only</button>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>

                      <ng-template #notFound>
                        <div class="badge-1320">
                          <div class="single-badge no-badge">
                            <p class="font-bold text-uppercase">Badge Not Earned</p>
                          </div>
                        </div>
                      </ng-template>

                      <!-- 1/4 Mile Time -->
                      <div class="col-sm-2" data-badge="1/4 Mile Time">
                        <div class="badge-boxes" data-category="et">
                          <span class="badge-label">1/4 Mile ET</span>
                          <button class="btn-slot slot-previous" (click)="counterEtDec(vehicle, true)" [disabled]="eightSecondSlotCounterET === 0"><i class="fa fa-chevron-up"></i><span class="sr-only">previous</span></button>
                          <button class="btn-slot slot-next" (click)="counterEtInc(vehicle, true)" [disabled]="vehicle.eightSecondSlotCounterETMax === 0 || eightSecondSlotCounterET === vehicle.eightSecondSlotCounterETMax - 1"><i class="fa fa-chevron-down"></i><span class="sr-only">next</span></button>

                          <div class="badge-box">
                            <div *ngIf="!badgesLoaded" class="article-loader text-center">
                              <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                              <span class="sr-only">Loading...</span>
                            </div>
                            <div class="badges" [ngStyle]="{'margin-top.px': eightSecondSlotCounterETOffset}">
                              <div *ngIf="vehicle.eightSecondSlotCounterETMax > 0; else notFound">
                                <div *ngFor="let badge of vehicle.badgesElapsedTime8SC">
                                  <div class="badge-1320" *ngIf="badge.found == true || isOwnersProfile">
                                    <div class="single-badge">
                                      <img loading="lazy" [src]="badge.detail.logo" [ngClass]="{'disable': !badge.found}" class="demon-badge img-responsive" alt="badge not earned">
                                      <button *ngIf="isOwnersProfile" [ngClass]="{'btn-unavailable disable': !badge.found && isOwnersProfile }" [disabled]="!badge.found" (click)="addEcwidProduct(badge.detail.storeProductId)" class="btn btn-primary" aria-label="Buy Badge">
                                        <span>$5 // Add To Cart</span>
                                        <span><i class="fa fa-lock"></i></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- 60ft Time -->
                      <div class="col-sm-2" data-badge="60ft Time">
                        <div class="badge-boxes"  data-category="sixtyfoot">
                          <span class="badge-label">60 Ft. Time</span>
                          <button class="btn-slot slot-previous" (click)="counterSixtyDec(vehicle, true)" [disabled]="eightSecondSlotCounterSixty === 0"><i class="fa fa-chevron-up"></i><p class="sr-only">previous</p></button>
                          <button class="btn-slot slot-next" (click)="counterSixtyInc(vehicle, true)" [disabled]="vehicle.eightSecondSlotCounterSixtyMax === 0 || eightSecondSlotCounterSixty === vehicle.eightSecondSlotCounterSixtyMax - 1"><i class="fa fa-chevron-down"></i><p class="sr-only">next</p></button>

                          <div class="badge-box">
                            <div *ngIf="!badgesLoaded" class="article-loader text-center">
                              <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                              <span class="sr-only">Loading...</span>
                            </div>
                            <div class="badges" [ngStyle]="{'margin-top.px': eightSecondSlotCounterSixtyOffset}">
                              <div *ngIf="vehicle.eightSecondSlotCounterSixtyMax > 0; else notFound">
                                <div *ngFor="let badge of vehicle.badgesSixtyFeetTime8SC">
                                  <div class="badge-1320" *ngIf="badge.found == true || isOwnersProfile">
                                    <div class="single-badge" >
                                      <img loading="lazy" [src]="badge.detail.logo" [ngClass]="{'disable': !badge.found}" class="demon-badge img-responsive" alt="badge not earned">
                                      <button *ngIf="isOwnersProfile" [ngClass]="{'btn-unavailable disable': !badge.found && isOwnersProfile }" [disabled]="!badge.found" (click)="addEcwidProduct(badge.detail.storeProductId)" class="btn btn-primary" aria-label="Buy Badge">
                                        <span>$5 // Add To Cart</span>
                                        <span><i class="fa fa-lock"></i></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-2" data-badge="1/4 Mile Speed">
                        <div class="badge-boxes"  data-category="speed">
                          <span class="badge-label">1/4 Mile Speed</span>
                          <button class="btn-slot slot-previous" (click)="counterSpeedDec(vehicle, true)" [disabled]="eightSecondSlotCounterSpeed === 0"><i class="fa fa-chevron-up"></i><p class="sr-only">previous</p></button>
                          <button class="btn-slot slot-next" (click)="counterSpeedInc(vehicle, true)" [disabled]="vehicle.eightSecondSlotCounterSpeedMax === 0 || eightSecondSlotCounterSpeed === vehicle.eightSecondSlotCounterSpeedMax - 1"><i class="fa fa-chevron-down"></i><p class="sr-only">next</p></button>

                          <div class="badge-box">
                            <div *ngIf="!badgesLoaded" class="article-loader text-center">
                              <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                              <span class="sr-only">Loading...</span>
                            </div>

                            <div class="badges" [ngStyle]="{'margin-top.px': eightSecondSlotCounterSpeedOffset}">
                              <div *ngFor="let badge of vehicle.badgesTopSpeed8SC">
                                <div *ngIf="vehicle.eightSecondSlotCounterSpeedMax > 0; else notFound">
                                  <div class="badge-1320" *ngIf="badge.found == true || isOwnersProfile">
                                    <div class="single-badge" >
                                      <img loading="lazy" [src]="badge.detail.logo" [ngClass]="{'disable': !badge.found}" class="demon-badge img-responsive" alt="badge not earned">
                                      <button *ngIf="isOwnersProfile" [ngClass]="{'btn-unavailable disable': !badge.found && isOwnersProfile }" [disabled]="!badge.found" (click)="addEcwidProduct(badge.detail.storeProductId)" class="btn btn-primary" aria-label="Buy Badge">
                                        <span>$5 // Add To Cart</span>
                                        <span><i class="fa fa-lock"></i></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  <!-- End 8-Second Club Badges -->
              </div>

              <!-- Start Slip Submissions -->
              <div class="submissions-container profile-accordion" *ngIf="isOwnersProfile">
                <div class="row">
                  <button class="btn btn-primary btn-accordion" type="button" data-toggle="collapse" attr.data-target="#slip-submissions-{{vehicle.id}}" aria-expanded="false" attr.aria-controls="#slip-submissions-{{vehicle.id}}">
                    1320 Submissions <i class="fa fa-chevron-down"></i>
                  </button>
                  <div class="collapse" attr.id="slip-submissions-{{vehicle.id}}">
                    <div class="well">
                      <h3>Submissions</h3>
                      <div class="submission-table">
                        <p *ngIf="vehicle.slips.length == 0">Time slips haven't been submitted for this vehicle</p>
                        <p class="submit-time-slip-btn-container" *ngIf="isOwnersProfile && vehicle.slips.length == 0"><button class="btn btn-primary" (click)="openTimeslipModal(timeslipModal)">Submit Time Slip</button></p>
                        <div class="table-responsive">
                          <table class="table table-striped" *ngIf="vehicle.slips.length > 0">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Track</th>
                                <th>1/4 Mile ET</th>
                                <th>60 Ft. Time</th>
                                <th>1/4 Mile Speed</th>
                                <th>Status</th>
                                <th *ngIf="isOwnersProfile">&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let slip of vehicle.slips">
                                <td>{{slip.date | date:'MM/dd/yy':'UTC'}}</td>
                                <td>{{slip.raceTrack}}</td>
                                <td>{{slip.elapsedTime}}</td>
                                <td>{{slip.sixtyFeetTime}}</td>
                                <td>{{slip.topSpeed}}</td>
                                <td>{{slip.status}}</td>
                                <td class="edit" *ngIf="isOwnersProfile"><a *ngIf="slip.status == 'PENDING'" class="btn btn-link" (click)="openTimeslipModal(timeslipModal, slip, vehicle)">Edit</a></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <p *ngIf="isOwnersProfile && vehicle.slips.length > 0">
                          <button class="btn btn-primary" (click)="openTimeslipModal(timeslipModal)">Submit Time Slip</button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- End Slip Submissions -->

            </tab>
          </ng-container>
        </tabset>
      </div>
    </div>
  </div>
<!--    </tab>-->
    <!-- Activity Feed Will Go Here -->
<!--  </tabset>-->

  <div> <!-- My Vehicles Content -->



  </div>
</div>



<!-- Modals -->
<ng-template #vehicleModal>
  <div class="modal-container">
    <div class="modal-header">
      <button class="modal-close" (click)="vehicleModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="signup-form form-1320">
        <dg-add-vehicle [vehicle]="vehicleToEdit" (vehicleSaved)="closeVehicleModal($event)" (vehicleDeleted)="vehicleDeleted()"></dg-add-vehicle>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #timeslipModal>
  <div class="modal-container">
    <div class="modal-header">
      <button class="modal-close" (click)="timeslipModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="signup-form form-1320">
        <dg-add-time-slip [vehicle]="vehicleToEdit" [timeslip]="timeslipToEdit" (timeSlipAdded)="closeTimeslipModal()"></dg-add-time-slip>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #imgModal>
  <div class="modal-body text-center">
    <p><img loading="lazy" [src]="imgBigger" alt="imgBigger" class="img-responsive center-block"></p>
    <p class="text-center"><button class="btn btn-white" (click)="imgModalRef.hide()">Close</button></p>
  </div>
</ng-template>
