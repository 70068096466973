<section id="lb-reveal-hero" [attr.data-stage]="activeStage" [ngClass]="{ 'inline-banner': isInline }">
    <ng-container *ngTemplateOutlet="getStageTemplate(activeStage)"></ng-container>
</section>

<ng-template #stage1>
    <img *ngIf="loading" class="poster" src="/assets/images/lb-reveal/reveal-site-teaser-poster.webp" alt="LB Reveal">
    <video *ngIf="!loading" #videoPlayer autoplay muted playsinline preload="auto" 
        onloadedmetadata="this.muted = true" 
        (play)="videoPlaying()" 
        (ended)="videoEnded()">
        <source src="/assets/videos/reveal-site-teaser-xs.mp4" type="video/mp4" media="(max-width: 599px)"> 
        <source src="/assets/videos/reveal-site-teaser-mobile.mp4" type="video/mp4" media="(min-width: 600px) and (max-width: 1199px)">
        <source src="/assets/videos/reveal-site-teaser-stage-2-full.mp4" type="video/mp4" media="(min-width: 1600px)">
        <source src="/assets/videos/reveal-site-teaser-xl-stage-2.mp4" type="video/mp4">
        Your browser does not support the video tag.
    </video>
    <button *ngIf="showPauseButton" class="btn-control" aria-label="Pause Video" (click)="pauseVideo()">
        <i *ngIf="isVideoPlaying" class="fa fa-pause"></i>
        <i *ngIf="!isVideoPlaying" class="fa fa-play"></i>
    </button>
    <button *ngIf="showReplayButton" class="btn-control" aria-label="Replay Video" (click)="replayVideo()"><i class="fa fa-refresh"></i></button>

    <countdown-clock class="slim" [targetDate]="revealDate" (timerExpired)="updateActiveStage(2)"></countdown-clock>

    <div class="site-wrapper disclaimer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <span>Preproduction model shown. Actual production model may vary. Features and products shown are for illustrative purposes only. Available late 2024.</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #stage2>
    <div id="stage-2" class="lb-reveal-banner">
        <div class="content-container container-fluid site-wrapper">
            <div class="video-trigger" (click)="openModal(videoModal)">
                <img src="/assets/images/lb-reveal/lb-reveal-video-thumb.webp" alt="Watch the reveal!">
                <span class="play-button" aria-label="Play reveal video">
                    <img src="/assets/images/lb-reveal/yt-play.svg" alt="YouTube play icon">
                </span>
            </div>
            <p class="text-uppercase">Click to watch the smoke clear</p>
        </div>
    </div>
</ng-template>

<ng-template #stage3>
    <div id="stage-3" class="lb-reveal-banner">
        <div class="split">
            <div class="content-container">
                <div class="video-trigger" (click)="openModal(videoModal)">
                    <img class="img-responsive" src="/assets/images/lb-reveal/lb-reveal-video-thumb.webp" alt="Watch the reveal!">
                    <span class="play-button" aria-label="Play reveal video">
                        <img src="/assets/images/lb-reveal/yt-play.svg" alt="YouTube play icon">
                    </span>
                </div>
                <p class="text-uppercase">Click to rewatch the smoke clear</p>
            </div>
        </div>
        <div class="split">
            <a class="text-uppercase" href="https://www.dodge.com/next-gen-charger.html" target="_blank">Explore the next-gen Charger</a>
            <span>Preproduction model shown. Actual production model may vary. Features and products shown are for illustrative purposes only. Available late 2024.</span>
        </div>
    </div>
</ng-template>

<ng-template #stage4>
    <a href="https://www.dodge.com/next-gen-charger.html" target="_blank" id="stage-4" class="lb-reveal-banner"> 
        <div class="content-container">
            <span class="text-uppercase">Explore the next-gen Charger</span>
            <span>Preproduction model shown. Actual production model may vary. Features and products shown are for illustrative purposes only. Available late 2024.</span>
        </div>
    </a>
</ng-template>

<ng-template #videoModal>
    <div id="lb-video-modal" class="modal-container">
        <div class="modal-header">
            <button class="modal-close" (click)="closeModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
        </div>
        <div class="modal-body site-wrapper">
            <div class="embed-responsive embed-responsive-16by9"></div>
        </div>
    </div>
</ng-template>