import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'initial-track-test',
  templateUrl: './initial-track-test.component.html',
})

export class DragPakInitialTrackTestComponent implements OnInit {
  ngOnInit(): void {

  }

}

