import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dg-gallery-search',
  templateUrl: './gallery-search.component.html',
  styles: []
})
export class GallerySearchComponent implements OnInit {

  @Input() gallerySearch: string;
  @Output() search = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  searchGallery() {
    this.search.emit(this.gallerySearch);
  }

}
