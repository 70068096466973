import {
  Component, Input, ViewChild, ElementRef, TemplateRef, HostListener, Inject, PLATFORM_ID, AfterViewInit, OnInit, OnChanges, SimpleChanges
} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import { TableFilterPipe } from './pipes/table-filter.pipe'; 

@Component({
  selector: 'dg-20-days-of-horsepower-leaderboard',
  templateUrl: './20-days-of-horsepower-leaderboard.component.html',
  styleUrls: ['./20-days-of-horsepower-leaderboard.component.scss'],
  providers: [ TableFilterPipe ]
})
export class TwentyDaysOfHorsepowerLeaderBoardComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('rowPosition', { read: ElementRef }) rowRef: ElementRef;
  @ViewChild('dateSelect', { read: ElementRef }) dateSelectRef: ElementRef;
  @ViewChild('scrollPosition', { read: ElementRef, static: true }) dataRef: ElementRef;

  @Input() dealers: Array<any>;
  @Input() vehicle: Object;
  @Input() id: string;
  @Input() start: string;
  @Input() comingSoon: boolean = false;
  @Input() disabledDates: Array<any>;
  comingSoonReactive: boolean;

  dealersSource: Array<any>;
  isDesc: boolean = true;
  column: string = 'unitsSold';
  direction: number = 1;
  loading: boolean = true;

  boardHeight: number;
  dataHeight: number;
  cellHeight: number;
  rowHeight: number;
  maxHeight: number;
  scrollPosition: number = 0;
  offsetY: number = 0;
  // Disable both by default, if we have more than one page we'll set that in calcCellHeight
  scrollTop: boolean = true;
  scrollBottom: boolean = true;
  showControls: boolean = false;

  
  today: Date = this.forceDateToEST(new Date());
  latestDate: Date = this.setCurrentDate();
  currentDate: Date = this.today;
  startDate: Date;
  endDate: Date; // Always set as current day
  selectedDateNew?: Date = this.latestDate;
  activeDate: string = this.formatDate(this.selectedDateNew);

  search1: string;
  search2: string;

  arrayIndex: number = 1;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object, 
    private tableFilter: TableFilterPipe 
  ) {
    
  }

  ngOnInit() {
    // Set date to start date when comingSoon is active
    // if (this.comingSoonReactive) {
    //   this.selectedDateNew = this.setUTCDate(new Date(this.start));
    //   this.startDate = this.setUTCDate(new Date(this.start));
    //   this.latestDate = this.startDate;
    //   this.endDate = this.latestDate;
    // }else {
    //   // Set calendar input default date based on latest data received to keep it current
    //   this.startDate = this.setUTCDate(new Date(this.start));
    //   this.latestDate = this.forceDateToEST(new Date(this.dealers[0].drawing_date));
    //   this.selectedDateNew = this.forceDateToEST(new Date(this.dealers[0].drawing_date));
    //   this.endDate = this.latestDate;
    // }
    if (this.comingSoon) {
      this.selectedDateNew = this.startDate;
    }

    console.log(this.dealers)

    const drawingDate = this.forceDateToEST(new Date(this.dealers[0].drawing_date));
    drawingDate.setHours(drawingDate.getHours() + 8);

    this.latestDate = drawingDate;
    this.selectedDateNew = drawingDate;
    this.startDate = this.setUTCDate(new Date(this.start));
    this.endDate = this.latestDate;

    console.log('dealers: ', this.dealers)
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dealersSource = this.dealers;
      this.filterDataByDate(this.currentDate)

      if(!this.isLastPage()) {
        this.scrollBottom = false;
      }

      this.loading = false;
      console.log('table loaded!');
    }, 2000);
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes['comingSoon']) {
    //   this.comingSoonReactive = this.comingSoon;
    //   this.selectedDateNew = this.comingSoon ? this.startDate : this.latestDate;
    //   console.log('coming soon changed: ', this.comingSoonReactive, this.selectedDateNew);
    // }
    if (changes['dealers']) {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
      }, 2000)
    }
  }

  openCalendar() {
    this.dateSelectRef.nativeElement.showPicker();
  }

  setUTCDate(date: Date) {
    const dateToUtcTime = date.toISOString();
    const utcDate = new Date(dateToUtcTime);

    return utcDate;
  }

  setCurrentDate() {
    const current = this.forceDateToEST(new Date());
    let finalDate = new Date(current);

    // If Sunday, change to previous friday
    if (current.getDay() === 0) {
      finalDate.setDate(current.getDate() - 2)
    }
    
    // If Saturday, change to previous friday
    else if (current.getDay() === 6) {
      finalDate.setDate(current.getDate() - 1)
    }

    // If Monday before 11am, change to previous friday
    else if (current.getDay() === 1 && current.getHours() < 11) {
      finalDate.setDate(current.getDate() - 3)
    }

    // If we're on a valid date before 11am, show previous day.
    else if (current.getHours() < 11) {
      finalDate.setDate(current.getDate() - 1)
    }

    // If we're on a valid date after 11am, show current day.
    else {
      finalDate.setDate(current.getDate())
    }

    return finalDate
  }

  setCurrentDateBasedOnData() {
    let date = new Date(this.dealers[0].salesDate);
    return this.forceDateToEST(new Date(this.dealers[0].salesDate));
  }

  forceDateToEST(date: Date) {
    // Create a new Date object representing the current date and time
    let currentDate = new Date(date);

    // Format the date in Eastern Standard Time (EST)
    let estOptions = { timeZone: 'America/New_York' };
    let estDate = currentDate.toLocaleString('en-US', estOptions);

    return new Date(estDate);
  }

  showValue() {
    this.activeDate = this.formatDate(this.selectedDateNew);
    this.scrollToTop();
  }

  filterDataByDate(date: any) {
    // const d = new Date(date + 'T12:00')

    // if (d.getDay() === 0 || d.getDay() === 6) {
    //   this.selectedDate = this.currentDate
    // }

    this.scrollToTop()
  }

  
  formatDate(date: Date) {
    const currentDate = date;
    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  formatDateString(date) {
    const currentDate = new Date(date);
    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  titleDate(date: Date) {
    const d = new Date(date);
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ]
    const month = d.getMonth();
    const day = String(d.getDate()).padStart(0, '0');
    const formattedDate = `${months[month]} ${day}`;
    return formattedDate;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.calcCellHeight();
      }, 500);
    }
  }

  calcCellHeight() {
    if (isPlatformBrowser(this.platformId) && !this.comingSoon) {
      const parent = document.getElementById(this.id)
      const rows = parent.querySelectorAll('.body .lb-row')

      if (rows.length > 0) {
        let cell = this.rowRef.nativeElement;
        let cellRect = cell.getBoundingClientRect();
        let cellMargin = window.getComputedStyle(cell);
        let cellHeight = cellRect.height + parseFloat(cellMargin.marginBottom);

        this.cellHeight = cellHeight;
        this.dataHeight = rows.length * this.cellHeight;

        if (rows.length >= 10) {
          if (window.innerWidth >= 992) {
            this.boardHeight = (this.cellHeight * 10);
            rows.length > 10 ? this.showControls = true : this.showControls = false;
          } else {
            this.boardHeight = (this.cellHeight * 5);
            rows.length > 5 ? this.showControls = true : this.showControls = false;
          }
        }else {
          this.boardHeight = this.cellHeight * rows.length
          this.scrollBottom = true;
          this.showControls = false;
        }
      }
    }
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  resetSearchFilters() {
    this.search1 = '';
    this.search2 = '';
  }

  scrollDown() {
    this.scrollPosition++

    if ( (this.dataHeight + this.offsetY) / this.boardHeight >= 2 ) {
      this.offsetY = (this.boardHeight * this.scrollPosition) * -1;
      this.scrollTop = false;
      this.scrollBottom = false;
    } else {
      this.scrollToBottom();
    }

    if(this.isLastPage()) {
      this.scrollBottom = true;
    }
  }

  scrollUp() {
    this.scrollPosition--

    if (this.offsetY < this.boardHeight * -1) {
      this.offsetY = this.boardHeight * (this.scrollPosition * -1);
      this.scrollTop = false;
      this.scrollBottom = false;
    } else {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    this.scrollPosition = 0;
    this.offsetY = 0;
    this.scrollTop = true;
    this.scrollBottom = true;
    setTimeout(() => {
      this.calcCellHeight();
      if(!this.isLastPage()) {
        this.scrollBottom = false;
      }
    });
  }

  scrollToBottom() {
    this.scrollPosition = Math.floor(this.dataHeight / this.boardHeight) - 1;
    this.offsetY = (this.dataHeight - this.boardHeight) * -1;
    this.scrollTop = false;
    this.scrollBottom = true;
  }

  isLastPage() {
    return (this.dataHeight + this.offsetY) <= this.boardHeight;
  }

  collapseTable() {
    this.maxHeight = this.rowHeight * 10;
  }

}
