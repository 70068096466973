import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentComponent } from './comment.component';
import { CommentFormComponent } from './comment-form/comment-form.component';
import { DeleteCommentComponent } from './delete-comment/delete-comment.component';
import { FlagCommentComponent } from './flag-comment/flag-comment.component';
import { SingleCommentComponent } from './single-comment/single-comment.component';
import { UserSharedModule } from '../user/user-shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    CommentComponent,
    CommentFormComponent,
    DeleteCommentComponent,
    FlagCommentComponent,
    SingleCommentComponent
  ],
  imports: [
    CommonModule,
    UserSharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ModalModule.forRoot()
  ],
  exports: [
    CommentComponent,
    CommentFormComponent,
    DeleteCommentComponent,
    FlagCommentComponent,
    SingleCommentComponent
  ]
})
export class CommentSharedModule { }
