<div id="rkn-page">
    <style>
        livestream-banner {
            display:  none;
        }
    </style>
    <div class="site-wrapper container container-landing">
        <div class="row intro-container">
            <div class="col-md-6">
                <div id="rkn-caorousel-landing" class="carousel slide video-list mb-5" data-ride="carousel">
                    <!-- Wrapper for slides -->
                    <div class="carousel-inner" role="listbox" aria-label="Roadkill Nights Carousel">

                        <div class="item active" role="option">
                            <div class="video-thumbnail" (click)="openVideoModal('585866412'); clickTrackerVideo('video 5')">
                                <i class="fa fa-play"></i>
                                <picture>
                                    <source media="(max-width: 991px)" srcset="/assets/images/rkn/hammer-video-5-m.jpg">
                                    <img src="/assets/images/rkn/hammer-video-5-d.jpg" alt="road kill header image - video 5" class="img-responsive">
                                </picture>
                            </div>
                        </div>

                        <div class="item" role="option">
                            <div class="video-thumbnail" (click)="openVideoModal('583084754'); clickTrackerVideo('video 4')">
                                <i class="fa fa-play"></i>
                                <picture>
                                    <source media="(max-width: 991px)" srcset="/assets/images/rkn/hammer-video-4-m.jpg">
                                    <img src="/assets/images/rkn/hammer-video-4-d.jpg" alt="road kill header image - video 4" class="img-responsive">
                                </picture>
                            </div>
                        </div>

                        <div class="item" role="option">
                            <div class="video-thumbnail" (click)="openVideoModal('580458539'); clickTrackerVideo('video 3')">
                                <i class="fa fa-play"></i>
                                <picture>
                                    <source media="(max-width: 991px)" srcset="/assets/images/rkn/hammer-video-3-m.jpg">
                                    <img src="/assets/images/rkn/hammer-video-3-d.jpg" alt="road kill header image - video 3" class="img-responsive">
                                </picture>
                            </div>
                        </div>

                        <div class="item" role="option">
                            <div class="video-thumbnail" (click)="openVideoModal('577200223'); clickTrackerVideo('video 2')">
                                <i class="fa fa-play"></i>
                                <picture>
                                    <source media="(max-width: 991px)" srcset="/assets/images/rkn/hammer-video-2-m.jpg">
                                    <img src="/assets/images/rkn/hammer-video-2-d.jpg" alt="road kill header image - video 2" class="img-responsive">
                                </picture>
                            </div>
                        </div>

                        <div class="item" role="option">
                            <div class="video-thumbnail" (click)="openVideoModal('573006945'); clickTrackerVideo('video 1')">
                                <i class="fa fa-play"></i>
                                <picture>
                                    <source media="(max-width: 991px)" srcset="/assets/images/rkn/hammer-video-1-m.jpg">
                                    <img src="/assets/images/rkn/hammer-video-1-d.jpg" alt="road kill header image - video 1" class="img-responsive">
                                </picture>
                            </div>
                        </div>
                    </div>

                    <!-- Indicators -->
                    <ol class="carousel-indicators">
                        <li data-target="#rkn-caorousel-landing" data-slide-to="0" class="active">1</li>
                        <li data-target="#rkn-caorousel-landing" data-slide-to="1">2</li>
                        <li data-target="#rkn-caorousel-landing" data-slide-to="2">3</li>
                        <li data-target="#rkn-caorousel-landing" data-slide-to="3">4</li>
                        <li data-target="#rkn-caorousel-landing" data-slide-to="4">5</li>
                    </ol>
                </div>

            </div>

            <div class="col-md-6">

                <div class="white-block" id="special">
                    <div class="row mt-4 mb-5">
                        <div class="col-lg-5">
                            <img src="/assets/images/rkn/road-kill-logo-white-double.png" alt="Road Kill Nights" class="img-responsive">
                        </div>
                        <div class="col-lg-7 heading-container">
                            <h2 style="margin-bottom: 0;">HELLCAT GRUDGE RACE</h2>
                            <p>Someone’s going to get beat at their own game.</p>
                        </div>
                    </div>
                    <p>What do you get when you give the TV star of
                      <em>Fastest Cars in the Dirty South</em> and four prominent
                      social media automotive influencers each a Dodge Challenger or Charger SRT<sup>&reg;</sup> Hellcat, $10,000 for
                      mods and a deadline for a public grudge match? One hell of a race. Check out the profiles below
                      to watch their builds unfold, see behind-the-scenes pics and come back for DodgeGarage
                      commentary and a chance to vote for your favorite. Tune in for the showdown at MotorTrend
                      Presents Roadkill Nights Powered by Dodge on August 14, 2021, at M1 Concourse in Pontiac, MI, or
                      livestreamed right here on DodgeGarage. 
                    </p>
                    <br>
                    <div class="text-center">
                        <p><a href="https://www.motortrend.com/events/roadkill-nights/" target="_blank" rel="noopener noreferrer">Registration is open - we’ll see you there!</a></p>
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-12">
                <div class="poll-container">
                    <dg-poll [pollId]="19"></dg-poll>
                </div>
            </div> -->
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="poll-container">
                    <dg-poll [pollId]="20"></dg-poll>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <br><br><br>
                <h3>Meet the builders&nbsp;&nbsp;<span id="breaker"><small>click to explore</small></span></h3>
            </div>
        </div>
    </div>

    <div id="influencer-ribbon">
        <div class="influencer-tile" id="influencer-1">
            <a routerLink="throtl" (click)="clickTrackerThrotl()">
                <img src="/assets/images/rkn/lil-demons/throtl.png" class="img-responsive" alt="throtl">
                <p>Throtl</p>
            </a>
        </div>
        <div class="influencer-tile" id="influencer-2">
            <a routerLink="tavarish" (click)="clickTrackerTavarish()">
                <img src="/assets/images/rkn/lil-demons/tavarish.png" class="img-responsive" alt="tavarish">
                <p>Tavarish</p>
            </a>
        </div>
        <div class="influencer-tile" id="influencer-3">
            <a routerLink="eric-malone" (click)="clickTrackerMalone()">
                <img src="/assets/images/rkn/lil-demons/eric-malone.png" class="img-responsive" alt="eric-malone">
                <p>Eric Malone</p>
            </a>
        </div>
        <div class="influencer-tile" id="influencer-4">
            <a routerLink="alex-taylor" (click)="clickTrackerAlex()">
                <img src="/assets/images/rkn/lil-demons/alex-taylor.png" class="img-responsive" alt="alex-taylor">
                <p>Alex Taylor</p>
            </a>
        </div>
        <div class="influencer-tile" id="influencer-5">
            <a routerLink="westen-champlin" (click)="clickTrackerWesten()">
                <img src="/assets/images/rkn/lil-demons/westen-champlin.png" class="img-responsive" alt="westen-champlin">
                <p>Westen Champlin</p>
            </a>
        </div>
    </div>




    <div class="modal fade" bsModal #videoModal id="other-modal" tabindex="-1" role="dialog" (onHidden)="closeVideoModal()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
                </div>
                <div class="modal-body">
                    <div class="embed-responsive embed-responsive-16by9" *ngIf="modalActive">
                        <div class="embed-responsive-item">
                            <iframe width="560" height="315" [src]="'https://player.vimeo.com/video/' + activeVideo + '?autoplay=1' | safe: 'resourceUrl'" title="Let the Hellcat Grudge Race Builds Begin" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <!-- <div class="addthis_inline_share_toolbox text-center"></div> -->
                </div>
            </div>
        </div>
    </div>
</div>





