import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GalleryModel } from './gallery';
import { HelperService } from '../services/helper.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(
    private helperService: HelperService,
    private http: HttpClient
  ) { }

  getGallery(page: number = 1, category: string = '', author: string = '', search: string = '', perPage: number = 16): Observable<GalleryModel[]> {
    let Params = new HttpParams();

    // Begin assigning parameters
    Params = Params.append('page', page.toString());
    Params = (category) ? Params.append('categories', category) : Params;
    Params = (author) ? Params.append('author', author) : Params;
    Params = (search) ? Params.append('search', search) : Params;
    Params = (perPage) ? Params.append('per_page', perPage.toString()) : Params;

    const url = environment.newsApiUrl + 'media?_embed&parent_exclude=0&media_type=image';
    return this.http.get<GalleryModel[]>(url, { params: Params }).pipe(catchError(this.helperService.handleError));
  }
}
