import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../../services/helper.service';

@Pipe({
  name: 'timeSinceOrDate'
})
export class TimeSinceOrDatePipe implements PipeTransform {

  constructor(private helperService: HelperService){}

  transform(value: any, format?: any): unknown {
    if (value) {
      return this.helperService.timeSinceOrDate(value, format);
    }
    return null
  }

}
