import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import {AddVehicle} from "./add-vehicle/add-vehicle";
import {UserService} from "../user/user.service";
import {Vehicle} from "./vehicle";
import {TimeSlip} from "../dg-1320/time-slip/time-slip";

@Injectable()
export class VehicleService {

  constructor(
    private http: HttpClient,
    public userService: UserService
  ) { }

  getVehiclesByUser(userId):Observable<Vehicle[]> {
    const url = environment.url+'/rest/user/'+userId+'/vehicles';
    return this.http.get<Vehicle[]>(url);
  }

  submitVehicle(addVehicle: AddVehicle):Observable<any> {
    let uploadData = new FormData();
    uploadData.append('vehicleId', addVehicle.id.toString());
    uploadData.append('vehicleLookupId', addVehicle.vehicleLookupId.toString());
    uploadData.append('vin', addVehicle.vin.toString());
    uploadData.append('isStock', addVehicle.isStock.toString());
    if (addVehicle.isDefault)
      uploadData.append('isDefault', addVehicle.isDefault.toString());
    if (addVehicle.mods) {
      uploadData.append('mods', addVehicle.mods.toString());
    }
    if (addVehicle.vehicleImage) {
      uploadData.append('vehicleImage', addVehicle.vehicleImage, addVehicle.vehicleImage.name);
    }
    if (addVehicle.originalImage) {
      uploadData.append('originalImage', addVehicle.originalImage);
    }
    const url = environment.url+'/rest/user/'+this.userService.userModel.id+'/vehicles';
    let headers = new HttpHeaders();
    headers.append('enctype', 'multipart/form-data');
    //headers.append('Accept', 'application/json');
    return this.http.post(url, uploadData, {headers:headers});
  }

  deleteVehicle(vehicle: Vehicle):Observable<any> {
    const url = environment.url+'/rest/user/'+this.userService.userModel.id+'/vehicles/'+vehicle.id;
    return this.http.delete(url);
  }

  deleteVehicleImage(vehicle: Vehicle, imageFileName: string): Observable<any> {
    const url = environment.url+'/rest/user/'+this.userService.userModel.id+'/vehicles/'+vehicle.id+'/image/'+imageFileName;
    return this.http.delete(url);
  }

  validateVehicle(addVehicle: AddVehicle) {
    let isValidated = true;
    if (addVehicle.vehicleLookupId == 0) {
      addVehicle.hasErrorVehicleLookupId = true;
      isValidated = false;
    }
    if (addVehicle.vin === undefined || addVehicle.vin.length != 17 || ( /[^a-zA-Z0-9]/.test( addVehicle.vin ) )) {
      addVehicle.hasErrorVin = true;
      isValidated = false;
    }
    /*if (addVehicle.vehicleImage === undefined && addVehicle.originalImage == '') {
      addVehicle.hasErrorVehicleImage = true;
      isValidated = false;
    }*/
    return isValidated;
  }

  getLookup(distinctField, year = null, model = null):Observable<any> {
    let Params = new HttpParams();
    if (distinctField) {
      Params = Params.append('distinct', distinctField);
    }
    if (model) {
      Params = Params.append('model', model);
    }
    if (year) {
      Params = Params.append('year', year.toString());
    }
    const url = environment.url+'/rest/vehicles/lookup';
    return this.http.get(url, { params: Params });
  }

}
