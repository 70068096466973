import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsArticleCardComponent } from './news-article-card/news-article-card.component';
import { RouterModule } from '@angular/router';
import { TimeSinceOrDatePipe } from '../shared/pipes/time-since-or-date.pipe';



@NgModule({
  declarations: [
    NewsArticleCardComponent,
    TimeSinceOrDatePipe
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: [
    //NewsArticleService
  ],
  exports: [
    NewsArticleCardComponent,
    TimeSinceOrDatePipe
  ]
})
export class NewsArticleSharedModule { }
