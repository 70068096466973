import { ContributorsService } from './../contributors/contributors.service';

import {catchError, map} from 'rxjs/operators';
import {Injectable, SecurityContext} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable, BehaviorSubject} from "rxjs";
import { Location } from '@angular/common';
import {NewsArticle} from "./news-article";
import {HelperService} from "../services/helper.service";
import {Author} from "./author";
import {Category} from "./category";
import {DomSanitizer} from "@angular/platform-browser";
import { Meta, Title } from '@angular/platform-browser';
import { Tag } from './tag';
import * as readingTime from 'reading-time';

@Injectable({
  providedIn: 'root',
})
export class NewsArticleService {

  newsArticle = new NewsArticle();
  newsArticles: NewsArticle[] = [];
  categories: Category[] = [];
  authors: Author[] = [];
  tags: Tag[] = [];
  contributors: Author[] = [];
  articleSearch: string = '';

  isNewsModalOpen: boolean = false;
  isNewsModalOpen$ = new BehaviorSubject<boolean>(this.isNewsModalOpen);
  isNewsArticleChanged: boolean = false;
  isNewsArticleChanged$ = new BehaviorSubject<boolean>(this.isNewsArticleChanged);
  isNewsArticleViewFull: boolean = false;
  newsArticleOpen: NewsArticle = new NewsArticle();
  previousURL: string = '';

  constructor(
    private contributorsService: ContributorsService,
    public helperService: HelperService,
    public title: Title,
    public metaService: Meta,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private location: Location,
  ) {
    title.setTitle( 'News | Dodge Garage' );

    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | News' });

    this.contributorsService.getContributors().subscribe( data => {
      this.contributors = data;
    });
  }

  getArticle(slug): Observable<NewsArticle> {
    const url = environment.newsApiUrl + 'posts?_embed&slug=' + slug;
    return this.http.get<NewsArticle>(url).pipe(map(articleModel => articleModel[0]),
      catchError(this.helperService.handleError),);
  }

  getArticleByWordPressId(id): Observable<NewsArticle> {
    const url = environment.newsApiUrl + 'posts/' + id + '?_embed';
    return this.http.get<NewsArticle>(url).pipe(catchError(this.helperService.handleError));
  }

  getArticleData(wordpressId: number): Observable<any> {
    const url = environment.url + '/rest/news/' + wordpressId;
    return this.http.get<any>(url);
  }

  getArticles(page: number = 1, category: string = '', author: string = '', tag: string = '', search: string = '', perPage: number = 10): Observable<NewsArticle[]> {

    let Params = new HttpParams();

    // Begin assigning parameters
    Params = Params.append('page', page.toString());
    Params = (category) ? Params.append('categories', category) : Params;
    Params = (author) ? Params.append('author', author) : Params;
    Params = (tag) ? Params.append('tags', tag) : Params;
    Params = (search) ? Params.append('search', search) : Params;
    Params = (perPage) ? Params.append('per_page', perPage.toString()) : Params;

    const url = environment.newsApiUrl + 'posts?_embed';
    return this.http.get<NewsArticle[]>(url, { params: Params }).pipe(catchError(this.helperService.handleError));
  }

  searchArticles(search: string = '', perPage: number = 10) {
    let Params = new HttpParams();

    Params = Params.append('keyword', search.toString());
    Params = (perPage) ? Params.append('per_page', perPage.toString()) : Params;

    const url = environment.newsSearchApiUrl + 'search?_embed';
    return this.http.get<NewsArticle[]>(url, { params: Params }).pipe(catchError(this.helperService.handleError));
  }

  getRelatedArticles( category: Category ): Observable<any> {
    const url = environment.newsApiUrl + 'posts/?_embed&categories=' + category.id;
    return this.http.get<NewsArticle[]>(url).pipe(
      catchError(this.helperService.handleError));
  }

  getStickyArticle(): Observable<any> {
    // Get the first sticky article returned by the API
    const url = environment.newsApiUrl + 'posts?_embed';
    let Params = new HttpParams();
    Params = Params.append('sticky', 'true');
    Params = Params.append('per_page', '1');

    return this.http.get<NewsArticle[]>(url, { params: Params }).pipe(catchError(this.helperService.handleError));
  }

  getAuthors(): Observable<any> {
    return this.http.get(environment.newsApiUrl + 'users?per_page=100').pipe(catchError(this.helperService.handleError));
  }

  getCategories(): Observable<any> {
    return this.http.get(environment.newsApiUrl + 'categories?per_page=100').pipe(catchError(this.helperService.handleError));
  }

  getTags(): Observable<any> {
    return this.http.get(environment.newsApiUrl + 'tags?per_page=100').pipe(catchError(this.helperService.handleError));
  }

  getTagsBySlug(slug: string): Observable<any> {
    return this.http.get(environment.newsApiUrl + 'tags?per_page=100&slug='+slug).pipe(catchError(this.helperService.handleError));
  }

  updateArticleWithTopic(restId: number, discourseTopicId: number, discourseCategoryId: number): Observable<any> {
    let formData = new FormData();
    formData.append('id', restId.toString());
    formData.append('discourseTopicId', discourseTopicId.toString());
    formData.append('discourseCategoryId', discourseCategoryId.toString());
    const url = environment.url + '/rest/news/' + restId + '/discourseTopic';
    return this.http.post(url, formData);
  }

  likeArticle(restId: number, userId: number = null): Observable<any> {
    let formData = new FormData();
    formData.append('id', restId.toString());
    formData.append('userId', (userId)?userId.toString():null);
    const url = environment.url + '/rest/news/' + restId + '/like';
    return this.http.post(url, formData);
  }

  removeLikeArticle(restId: number, newsArticleLikeId: number): Observable<any> {
    const url = environment.url + '/rest/news/' + restId + '/like/' + newsArticleLikeId;
    return this.http.delete(url);
  }

  mutateArticle(article) {
    let newsArticle = new NewsArticle();
    Object.keys(article).forEach( key => {
      newsArticle[key] = article[key];
    });
    newsArticle.cleanLink = this.helperService.cleanLink(article['link']);
    newsArticle.title = this.helperService.fixRBalls(article['title']['rendered']);
    newsArticle.linkTitle = this.helperService.htmlDecode(newsArticle.title);
    newsArticle.slug = article['slug'];
    newsArticle.author = this.mutateAuthor(article['_embedded']['author'][0]);
    // Mutate Categories
    article.categories = article._embedded['wp:term'][0].filter( category => category.slug != 'uncategorized').sort(this.sortCategories);
    article.categories.forEach( category => this.mutateCategory(category));
    newsArticle.categories = article.categories;
    newsArticle.readTime = readingTime(article.content.rendered, { wordsPerMinute: 300 }).text;
    //newsArticle.timeSince = this.helperService.timeSince(article['date']);
    newsArticle.excerpt = this.truncateText(article['excerpt']['rendered']);
    newsArticle.featuredImage.src = (article._embedded['wp:featuredmedia'])?article._embedded['wp:featuredmedia'][0].source_url:'';
    newsArticle.featuredImage.alt = (article._embedded['wp:featuredmedia'])?(article._embedded['wp:featuredmedia'][0]['alt_text'])?article._embedded['wp:featuredmedia'][0]['alt_text']:article['title']['rendered']:article['title']['rendered'];
    if (article._embedded['wp:featuredmedia'] && article._embedded['wp:featuredmedia'][0]  && article._embedded['wp:featuredmedia'][0]['code'] !== "rest_forbidden") {
      let imgSizes = article._embedded['wp:featuredmedia'][0]['media_details']['sizes'];
      Object.keys(newsArticle.featuredImage).forEach( key => {
        if (!['alt','src'].includes(key) && imgSizes[key]) {
          newsArticle.featuredImage[key].src = imgSizes[key]['source_url'];
          newsArticle.featuredImage[key].width = imgSizes[key]['width'];
          newsArticle.featuredImage[key].height = imgSizes[key]['height'];
        }
      });
    }
    newsArticle.socialImage = article.custom_fields.social_image;
    // Reset and mutate assigned tags
    newsArticle.tags = [];
    let tagData = [].concat.apply([], article._embedded['wp:term']).filter( tag => tag.taxonomy == 'post_tag');
    tagData.forEach( data => newsArticle.tags.push(this.mutateTag(data)));

    return newsArticle;
  }

  mutateAuthor(author) {
    author.slugUrl = this.helperService.slugURL(author['name']);
    let contributor = this.contributors.filter(contributor => contributor.slugUrl == author.slugUrl);
    if (contributor.length > 0 && contributor[0]) {
      author.bio = contributor[0]['bio'];
      author.image = contributor[0]['image'];
    }
    return author;
  }

  mutateCategory(category) {
    category.link = '/news/category/' + category.slug;
    category.name = this.helperService.fixRBalls(category.name);
    return category;
  }

  mutateTag(tagData) {
    let tag: Tag = new Tag();
    tag.id = tagData.id;
    tag.name = tagData.name;
    tag.slug = tagData.slug;
    tag.link = '/news/tag/' + tagData.slug;
    return tag;
  }

  truncateText(content) {
    let maxLength = 150;
    if (content.length > maxLength) {
      content = content.substr(0,maxLength) + '...';
    }
    // Fix RBalls
    content = this.helperService.fixRBalls(content);

    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  setAltText (filename, article = null) {
    let altText = '';
    if (article) {  // If article was passed, check if alt tag or title exist
      //article._embedded['wp:featuredmedia'][0].alt_text
      if (article._embedded['wp:featuredmedia'][0].alt_text) {
        altText = article._embedded['wp:featuredmedia'][0].alt_text;
      }
    }
    return altText;

  }

  setModalOpen(value: boolean) {
    // Update news modal subject
    this.isNewsModalOpen$.next(value);
    this.isNewsModalOpen = value;
  }

  setNewsArticleChanged(value: boolean, url: string = null) {
    // Update news article
    this.isNewsArticleChanged$.next(value);
    this.isNewsArticleChanged = value;
    if (url) {
      this.newsArticleOpen = new NewsArticle();
      this.newsArticleOpen.slug = url.substr(url.lastIndexOf('/') + 1).replace('.html','');
      //this.location.go(url);
      setTimeout(() => this.location.replaceState(url));
    }
  }

  sortCategories(firstCategory) {
    return firstCategory.name.match(/(Video)|(Gallery)/g) ? 1 : -1;
  }
}
