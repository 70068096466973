import {
  AfterViewInit,
  Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, SimpleChange, SimpleChanges,
  ViewChild
} from '@angular/core';
import Cropper from 'cropperjs';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'dg-image-editing',
  templateUrl: './image-editing.component.html',
  styleUrls: ['./image-editing.component.less']
})
export class ImageEditingComponent implements OnInit, AfterViewInit {

  @Input() imageFile: File;
  @Input() aspectRatio: any = 16 / 9;
  @Output() croppedImage = new EventEmitter();
  image: string|ArrayBuffer = '/assets/images/1320/badges/stock/club/stock.png';
  cropper: any;
  isLoading: boolean = false;
  //@ViewChild('imgElement') imgElement;


  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    //console.log(this.image);

  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {

      let reader = new FileReader();
      reader.onload = (e) => { // called once readAsDataURL is completed
        //this.addVehicle.originalImage = reader.result;
        this.image = reader.result;
        //this.imgModalRef = this.modalService.show(template, {class: 'modal-normal'});
        //this.initCropper();
      };
      reader.onloadend = (e) => {
        this.initCropper();
      };
      reader.readAsDataURL(this.imageFile); // read file as data url


    }
  }

  initCropper() {
    let cropperImage = <HTMLImageElement> document.getElementById('cropperImage');
    let cropperResult = document.querySelector('#cropperResult');
    this.cropper = new Cropper(cropperImage, {
      aspectRatio: this.aspectRatio,
      viewMode: 2,
      rotatable: true
    });
    //console.log(this.cropper);
  }

  rotateLeft() {
    this.cropper.rotate(90);
  }

  rotateRight() {
    this.cropper.rotate(-90);
  }

  cropImage() {
    this.isLoading = true;
    let canvas = this.cropper.getCroppedCanvas({
      maxWidth: 2000, // Let's take big ass images and make them smaller
    });
    this.image = canvas.toDataURL(this.imageFile.type)
    let imageName = this.imageFile.name;
    canvas.toBlob((blob) => {
      const imageFile = new File([blob], imageName, { type: this.imageFile.type });
      //this.croppedImage.emit(imageFile);
      this.croppedImage.emit({
        'image': this.image,
        'blob': blob,
        'file': imageFile
      });
    });
  }

  /*ngOnChanges(changes: SimpleChanges) {
    const image: SimpleChange = changes.name;
    console.log('prev value: ', image.previousValue);
    console.log('got name: ', image.currentValue);
    //this._name = name.currentValue.toUpperCase();
  }*/

}
