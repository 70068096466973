import {Injectable} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import { Observable } from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Badge} from "./badge";
import {environment} from "../../../environments/environment";
import {UserService} from "../../user/user.service";
import {TimeSlip} from "../time-slip/time-slip";

@Injectable()
export class BadgeService {
  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private userService: UserService
  ) {
  }

  getAllBadges(){
    const url = environment.url+'/rest/badge';
    return this.http.get<Badge[]>(url);
  }

  getBadgesByVehicle(userId, vehicleId) {
    const url = environment.url+'/rest/user/' + userId + '/vehicles/' + vehicleId + '/badges';
    return this.http.get<any[]>(url);
  }
}

