<div class="row">
  <div class="col-sm-12">
    
    <div class="row mb-4">
      <div class="col-sm-12">
        <h2>Pre-Run Checklist</h2>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-sm-12">
        <div class="card card-horizontal well">
          <div class="img-container">
            <img loading="lazy" class="img-responsive" src="/assets/images/drag-pak-experience/pre-run-checklist/1.webp" alt="Pre-Run Checklist" width="1440" height="1002">
          </div>
          <div class="content">
            <div class="content-inner">
              <p class="mb-0">
                Before any runs are made in the 2021 Dodge Challenger Mopar<sub>&reg;</sub> Drag Pak, it’s important to follow these guidelines to ensure care and safety measures are in place. These procedures will minimize the possibility of engine or transmission damage occurring during operation. The Drag Pak is delivered with all fluids at the proper operating levels, along with a base calibration within the Holley Dominator V5 ECU. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12">
        <h3 id="fluid-levels">Fluid Levels</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h4>Engine</h4>
        <p>
          The Drag Pak’s 354-cubic-inch supercharged HEMI<sup>&reg;</sup> V8 engine is delivered with (7) quarts of Shell 5W-40 Rotella T6 oil (Mopar part number 68001332PA = (1) quart). The engine does not come with a dipstick, but there are provisions to install one. It’s recommended that the oil and filter be changed every 20 runs or less.
        </p>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Transmission</h4>
        <p>
          Check the fluid level in the Drag Pak’s transmission with its locking dipstick, while on level ground. Follow these steps to ensure an accurate fluid reading on the dipstick:
        </p>
        <ol>
          <li>With a crew person sitting in the vehicle with their foot on the brake, the engine needs to be idling with the transmission in 3rd gear.</li>
          <li>The transmission fluid level should measure at the “full” line on the dipstick.</li>
          <li>Do not race the car if the trans fluid level is not at the full mark on the dipstick, or transmission damage might occur and performance will be hampered</li>
        </ol>
        <div class="well my-3">
          <p class="mb-0"><strong>NOTE:</strong> When replacing the transmission fluid, you will need to adhere to the following procedures:</p>
        </div>
        <ol class="spaced">
          <li>Start the car and run it for at least one minute to build heat in the trans fluid.</li>
          <li>Shut the car off and drain the fluid from the trans pan. The Drag Pak's transmission pan holds approximately (6) quarts.</li>
          <li>During refilling, first add (5.5) quarts of transmission fluid and take a reading on the dipstick – see procedures above. Add additional transmission fluid until the level is at the "full" line.</li>
          <li>We recommend using Redline D6 Transmission Fluid. Don’t mix different transmission fluids or damage may occur. </li>
          <li>The transmission has a heavy-duty metal screen transmission oil filter. Under normal race conditions, the transmission oil filter only needs to be changed at least once every season. </li>
        </ol>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Radiator</h4>
        <p>
          The Drag Pak is delivered with a 50/50 mix of coolant and water. It’s recommended that the cooling system be drained and the 50/50 mix be replaced with distilled water prior to the vehicle making a run down the drag strip. Make sure to purge the cooling system of any air trapped in the system. <a routerLink="/drag-pak-experience/competition-setup" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="Competition Setup">Refer to the Competition Setup section for detailed instructions.</a>
        </p>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Intercooler</h4>
        <p>
          It’s important to fill the intercooler tank with a mix of water and ice prior to the run. The recommended mixture is 1/3 water and 2/3 ice. Top off the intercooler tank and prime the intercooler pump prior to leaving the pits and heading to the staging lanes. 
        </p>
        <p>
          <strong>WARNING: Failure to add ice-water mix and prime will result in serious engine damage.</strong>
        </p>
        <div class="well mt-3">
          <p><strong>NOTE:</strong> Make sure the intercooler pump is on at all times when the engine is running</p>
          <p class="mb-0"><strong>NOTE:</strong> Make sure the reservoir does not run low on water as it may starve and damage the pump.</p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row mb-5">
      <div class="col-sm-12">
        <h3 id="break-in">Break-In &amp; Settings</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h4>Engine Break-In</h4>
        <p>
          The Drag Pak’s 354-cubic-inch supercharged HEMI V8 engine is run on a dyno prior to installation. No break-in procedures are required prior to competition.
        </p>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Transmission Break-In</h4>
        <p>
          The three-speed automatic racing transmission is run on a trans dyno. No additional break-in procedures are needed prior to competition.
        </p>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Fuel pressure settings</h4>
        <p>
          Without the engine running, the fuel pressure is pre-set and should have a static reading of 70 psi. Refer to the Holley Digital Dash if any adjustments are needed.
        </p>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Lug nut torque settings</h4>
        <p>
          Check lug nut torque on all four wheels prior to every run. Rear wheels need to be torqued to 130 ft.-lbs., and fronts should be 95 ft.-lbs. This setting is the same for both the BOGART and WELD wheel options.
        </p>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Front and rear tire pressure</h4>
        <p>
          Tire pressure is crucial to ensure maximum traction and proper handling on the vehicle. The rear slicks are pre-set to 20 psi and the fronts are at 40 psi. Air pressure adjustments might be needed based on track and weather conditions. The tire manufacturer recommends a pressure range of 35-40 psi in the front, and 18-20 in the rear.
        </p>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Ride height</h4>
        <p class="mb-3">
          The ride height measurements need to be taken in the middle of the front and rear wheel arches on a level surface. The recommended measurements are 27-¾ inches front and 28 inches rear. <a routerLink="/drag-pak-experience/competition-setup" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="Competition Setup">Refer to the Competition Setup section for more details.</a>
        </p>
        <div class="well mb-3">
          <p class="mb-0"><strong>NOTE:</strong> This measurement needs to be performed with the driver in the car.</p>
        </div>
        <p>
          If you need to change the Drag Pak’s ride height, you can do this by adjusting the shocks and/or springs. When adjusting the shocks, it’s recommended that you raise the vehicle and let the suspension relax before changing ride height. Gloves are recommended when using the proper Bilstein spanner wrench (part number B4-XS1-Z001A00) to avoid injuring your knuckles.
        </p>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Brakes</h4>
        <div class="wrapped-image">
          <img loading="lazy" class="img-responsive pull-right" src="/assets/images/drag-pak-experience/pre-run-checklist/rotor.webp" alt="Drag Pak Pre Run Checklist - Brake Rotor" width="768" height="488">
          <p class="mb-3">
            Before the Drag Pak is raced, it’s imperative that the front and rear brake pads get seated properly to the rotors. This process, known as “bedding”, is when brake pad material is transferred to the rotor surface. The heat generated will finish the curing process of the brake pad. <a href="https://www.strangeengineering.net/faq/" target="_blank">Click here for the recommended brake bedding procedures.</a>
          </p>
          <p><strong>WARNING: Failure to properly bed-in the brakes may result in loss of brake performance.</strong></p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mt-5 text-center btn-container">
        <a routerLink="/drag-pak-experience/competition-setup" class="btn btn-primary btn-footer-nav mr-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Competitiion Setup"><i class="fa fa-chevron-left" aria-hidden="true"></i> <span>Competitiion Setup</span></a>
        <a routerLink="/drag-pak-experience/initial-track-test" class="btn btn-primary btn-footer-nav ml-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Initial Track Test"><span>Initial Track Test</span> <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
      </div>
    </div>

  </div>
</div>
