import { Allocation } from "./allocation";
import { HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../../../environments/environment";


@Injectable()
export class HPLAllocationService {
  constructor (
    private http: HttpClient
  ){}

  getAllocations(zipcode: string, model: string, radius: string=null, trimCode: string=null, powerBroker:boolean=null, max: string = '200', offset: string = '0' ):Observable<any>  {
    const url = environment.testHPLApiUrl + '/rest/dealership';
    let Params = new HttpParams();
    Params = (zipcode) ? Params.append('zipcode', zipcode) : Params;
    Params = (model) ? Params.append('model', model) : Params;
    Params = (trimCode) ? Params.append('trimCode', trimCode) : Params;

    // this is gross but because the backend uses strings for booleans, need to set the request parameters up doing
    if(powerBroker) {
      Params = (powerBroker) ? Params.append('powerBroker', 'true') : Params;
    };

    Params = (max) ? Params.append('max', max) : Params;
    Params = (offset) ? Params.append('offset', offset) : Params;

    // if the radius parameter is NOT equal to "all", append it - otherwise, don't add it to the request
    if(radius !== "all"){
      Params = (radius) ? Params.append('radius', radius) : Params;
    }

    return this.http.get(url, {params: Params});
  }

  getModelAllocationsByDealer(dealercode:string, model:string):Observable<any> {
    const url = environment.testHPLApiUrl + '/rest/carInventory';
    let Params = new HttpParams();
    Params = (dealercode) ? Params.append('dealercode', dealercode) : Params;
    Params = (model) ? Params.append('model', model) : Params;

    return this.http.get(url, {params: Params});
  }




}

