import { trigger, state, style, animate, transition } from '@angular/animations';


// Slide animation. Enter from right, exit on left
export let slide = trigger('slide', [

  transition(':enter', [
    style({opacity: 0, transform: 'translateX(100%)'}),
    animate('500ms ease-in-out', style({opacity: 1, transform: 'translateX(0)'}))
  ]),
  transition(':leave', [
    animate('250ms ease-in-out', style({opacity: 0, transform: 'translateX(-100%)'}))
  ])

]);

// Slide animation. Enter from bottom, exit on top
export let slideY = trigger('slideY', [

  transition(':enter', [
    style({opacity: 0, transform: 'translateY(100%)'}),
    animate('500ms ease-in-out', style({opacity: 1, transform: 'translateY(0)'}))
  ]),
  transition(':leave', [
    animate('250ms ease-in-out', style({opacity: 0, transform: 'translateY(-100%)'}))
  ])

]);

export let collapse = trigger('collapse', [
  state('open', style({
    opacity: '1',
    height: '*',
    'overflow': 'hidden'
  })),
  state('closed',   style({
    opacity: '0',
    height: 0,
    'overflow': 'hidden'
  })),
  transition('closed => open', animate('200ms ease-in')),
  transition('open => closed', animate('100ms ease-out'))
]);

export let collapseWithScrolling = trigger('collapseWithScrolling', [
  state('open', style({
    opacity: '1',
    height: '*',
  })),
  state('closed',   style({
    opacity: '0',
    height: 0,
  })),
  transition('closed => open', animate('200ms ease-in')),
  transition('open => closed', animate('100ms ease-out'))
]);


export let expandCollapse = trigger('expandCollapse', [
  state('*', style({'overflow-y': 'hidden'})),
  state('void', style({'overflow-y': 'hidden'})),
  transition('* => void', [
    style({height: '*'}),
    animate('250ms ease-out', style({height: 0}))
  ]),
  transition('void => *', [
    style({height: 0}),
    animate('250ms ease-in', style({height: '*'}))
  ])
]);


