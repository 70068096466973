import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Component({
  selector: 'dg-srt-registration',
  templateUrl: './srt-registration.component.html',
  styleUrls: ['./srt-registration.component.less']
})
export class SRTRegistrationComponent implements OnInit {
  shareImg: any;
  sharingURL: string;

  constructor(
    private router: Router,
    private metaService: Meta,
    private titleService: Title
  ) {
    let description = 'Well-rounded, future-forward Chrysler, Jeep and Dodge vehicles built to reward drivers who are brave enough to drive what they love.';
    let title = 'SRT Track Experience | DodgeGarage';
    this.shareImg = environment.url + '/assets/images/srt/radford-racing.jpg';
    this.sharingURL = environment.url + this.router.url;

    // Set Title
    this.titleService.setTitle( title );

    // Set Meta Data
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ rel: 'canonical', href: environment.url + this.router.url });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
    this.metaService.updateTag({ property: 'og:title', content: title});
    this.metaService.updateTag({ property: 'og:type', content: 'article' });
    this.metaService.updateTag({ property: 'og:image', content: this.shareImg });
    this.metaService.updateTag({ property: 'og:url', content: this.sharingURL });
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
    this.metaService.updateTag({ name: 'twitter:image', content: this.shareImg });
  }

  ngOnInit(): void {

  }

}
