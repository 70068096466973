import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdobeTMService {
  
  pushToDataLayer(data: any): void {
    if (typeof window !== 'undefined' && window['adobeDataLayer']) {
      window['adobeDataLayer'].push(data);
    } else {
      console.warn('Adobe Data Layer is not initialized or available.');
    }
  }
}