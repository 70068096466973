import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  TemplateRef, ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT, isPlatformBrowser, TitleCasePipe} from "@angular/common";
import {expandCollapse, collapse, collapseWithScrolling} from "../animations";
import {environment} from "../../environments/environment";
import {Meta, Title} from "@angular/platform-browser";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {Topic} from "../comment/topic";
import {AddThisService} from '../services/addthis.service';

declare let addthis: any;
declare let ga: Function;

@Component({
  selector: 'drag-pak-experience',
  templateUrl: './drag-pak-experience.component.html',
  //styleUrls: ['./drag-pack-experience.component.less'],
  animations: [ expandCollapse, collapse, collapseWithScrolling ],
  providers: [TitleCasePipe]
})
export class DragPakExperienceComponent implements OnInit, OnDestroy {

  page: string;
  hash: string;
  sharingURL: string;
  collapse: string = 'open';
  modalRef: BsModalRef
  topics: {};
  topic: Topic = new Topic();
  postCount: number;
  modalOpen: boolean;
  shareImg: any;
  @ViewChild('comments') comments;

  constructor(
    // private dragPakExperienceService: DragPakExperienceService,
    private renderer2: Renderer2,
    private titleService: Title,
    private titleCasePipe: TitleCasePipe,
    private metaService: Meta,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private addThisService: AddThisService,
    @Inject(DOCUMENT) private _document,
    @Inject(PLATFORM_ID) private platformId: Object
  ){
    
    // Topic ID's are pulled from the environment files.
    this.topics = {
      'drag-pak' : environment.dragPakDiscourseIds['drag-pak'],
      'history' :  environment.dragPakDiscourseIds['history'],
      'build-overview':  environment.dragPakDiscourseIds['build-overview'],
      'startup-procedures':  environment.dragPakDiscourseIds['startup-procedures'],
      'competition-setup':  environment.dragPakDiscourseIds['competition-setup'],
      'pre-run-checklist':  environment.dragPakDiscourseIds['pre-run-checklist'],
      'initial-track-test':  environment.dragPakDiscourseIds['initial-track-test'],
      'ecu-launch-setting':  environment.dragPakDiscourseIds['ecu-launch-setting'],
      'post-run-checklist':  environment.dragPakDiscourseIds['post-run-checklist']
    }

    this.modalOpen = false;
    // listen for modal open/close
    this.modalService.onShow.subscribe(()=> {
      this.modalOpen = true;
    })
    this.modalService.onHide.subscribe(() => {
      this.modalOpen = false;
    })

    this.postCount = 0;

    this.route.params.subscribe( params => {
      if (params.page) {
        this.page = params.page;
      } else {
        this.page = 'drag-pak'
      }
      
      this.sharingURL = environment.url + this.router.url;

      // Set comments
      this.topic = {
        discourseTopicId: this.getTopicId(this.page),
        discourseCategoryId: 11,
        polymorphicId: 123,
        polymorphicType: '',
        title: this.titleCasePipe.transform(this.page),
        url: ''
      };

      
      this.shareImg = '/assets/images/drag-pak-experience/' + this.page + '/social-img.jpg';
      
      console.log(this.page, this.shareImg);

      // Set Title
      this.titleService.setTitle( 'Drag Pak Experience | ' + this.titleCasePipe.transform(this.page) + ' | Dodge Garage' );

      // Set Meta Data
      // this.metaService.updateTag({ name: 'description', content: this.event.description });
      this.metaService.updateTag({ rel: 'canonical', href: environment.url + this.router.url });
      // this.metaService.updateTag({ property: 'og:description', content: this.event.description });
      this.metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
      this.metaService.updateTag({ property: 'og:title', content: 'Drag Pak Experience | ' + this.titleCasePipe.transform(this.page) });
      this.metaService.updateTag({ property: 'og:type', content: 'article' });
      this.metaService.updateTag({ property: 'og:image', content: environment.url + this.shareImg });
      this.metaService.updateTag({ property: 'og:url', content: this.sharingURL });
      this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
      this.metaService.updateTag({ name: 'twitter:image', content: environment.url + this.shareImg });
    });
    this.route.fragment.subscribe((fragment: string) => {
      this.hash = fragment;
      this.sharingURL = environment.url + '/drag-pak-experience/' + this.router.url + this.hash;
    })
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {

      if (window.innerWidth < 767) {
        this.collapse = 'closed';
      }
      this.initDragPak();
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      // Remove class from body
      document.body.classList.remove('drag-pak-experience');
    }
  }

  // toggleExpand(event) {
  //   const className = 'expanded';
  //   const hasClass = event.target.classList.contains(className);
  //   let expanded = document.querySelectorAll('.expanded');
  //   expanded.forEach(el => {
  //     this.renderer2.removeClass(el, className);
  //   })
  //
  //   if(!hasClass) {
  //     this.renderer2.addClass(event.target, className);
  //   }
  // }

  // nav collapse
  toggleCollapse(event) {
    event.preventDefault();
    this.collapse = this.collapse == 'open' ? 'closed' : 'open';
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 767) {
      this.collapse = 'closed';
    } else {
      this.collapse = 'open';
    }
  }

  openModal(template: TemplateRef<any>) {
    this.trackGAEvent('DragPkOwn_AddThis','click', 'Open Comment Modal')
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'comments-modal' })
    );
  }

  initDragPak() {
    document.body.classList.add('drag-pak-experience');

    // Add AddThis to Page
    const script = document.createElement('script');
    script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
    document.body.appendChild(script);

    // Reload AddThis
    setTimeout(() => {
      addthis.layers.refresh();
      this.addThisService.setAddThisVisibility(true);
    }, 3000);
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  topicCreated(event) {
    this.topic.discourseTopicId = event.discourseTopicId;
    this.topic.discourseCategoryId = event.discourseCategoryId;
  }

  getTopicId(page): number {
    if (this.topics.hasOwnProperty(page)) {
      return this.topics[page];
    } else {
      throw new Error("Unable to find discourse id for page '" + page + "'.")
      return 0;
    }
  }

  updatePostCount(postCount) {
    this.postCount = postCount;
  }

  trackGAEvent(category, action, label) {
    ga('send', 'event', category, action, label);
  }
}
