import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

import {HelperService} from "../services/helper.service";

@Component({
  selector: 'dg-demon-faq',
  templateUrl: './demon-faq.component.html'
})
export class DemonFaqComponent implements OnInit {

  constructor(
    public helperService: HelperService,
    public title: Title,
    public metaService: Meta,
    private router: Router,
  ) {
    title.setTitle( '2018 Dodge Challenger SRT Demon FAQ | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'So you’ve ordered your Dodge Challenger SRT® Demon, and it’s here! Whether you’re just picking it up from the dealership or you’ve done your first burnout, you might have questions about the world’s fastest quarter-mile production car. Let’s get them answered.' })
    metaService.updateTag({ property: 'og:description', content: 'So you’ve ordered your Dodge Challenger SRT® Demon, and it’s here! Whether you’re just picking it up from the dealership or you’ve done your first burnout, you might have questions about the world’s fastest quarter-mile production car. Let’s get them answered.' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: '2018 Dodge Challenger SRT Demon FAQ | Dodge Garage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
  }

}
