<article class="site-wrapper skinny-site-wrapper single-article-container" *ngIf="article.isLoaded">
  <div class="featured-img">
    <picture *ngIf="article.featuredImage.src; else defaultImage">
      <source media="(min-width: 992px)" [srcset]="article.featuredImage.full.src">
      <source media="(min-width: 576px)" [srcset]="article.featuredImage.large.src">
      <img class="img-responsive" [src]="article.featuredImage.medium.src" [alt]="article.featuredImage.alt" width="1170" height="518">
    </picture>
    <ng-template #defaultImage>
      <picture>
        <img loading="lazy" class="img-responsive" src="/assets/images/default-news-image.webp" alt="Dodge Garage News" width="1170" height="518">
      </picture>
    </ng-template>
  </div>
  <div class="container-fluid">
    <header class="entry-header">
      <h1 class="entry-title" [innerHtml]="article.title" #articleTitle></h1>
      <div class="article-meta">
        <span>
          By <a [routerLink]=" ['/news/author/'+article.author.slugUrl] ">{{article.author.name}}</a>
        </span>
        <span>
          <i class="fa fa-clock-o"></i>&nbsp;<time class="updated" [attr.datetime]="article.date | date: 'yyyy-MM-dd'">{{article.date | timeSinceOrDate:'M/d/yyy h:mm a'}}</time>
        </span>
        <span *ngFor="let category of article.categories" class="cat-label">
          <i class="fa fa-bookmark-o"></i>&nbsp;<a [routerLink]="category.link">{{category.name}}</a>
        </span>
        <span>
          {{ article.readTime }}
        </span>
        <div class="article-tags">
          <a *ngFor="let tag of article.tags" [routerLink]="tag.link" (click)="helperService.trackGAEvent('Article Tag', tag.name, '/news/article/'+article.cleanLink)" [innerHtml]="tag.name"></a>
        </div>
      </div>
    </header>
    <div class="entry-content">
      <div [innerHtml]="article.content?.rendered | safe: 'html'"></div>
    </div>
    <!--<div class="addthis_inline_share_toolbox mb-5" attr.data-title="{{article.title}}" attr.data-media="{{article.featuredImage.src}}"></div>-->



    <div class="comment-container">
      <div *ngIf="this.article.isDiscourseActive">
        <dg-comments [topic]="topic" (topicCreatedEmitter)="topicCreated($event)"></dg-comments>
      </div>
      <div *ngIf="!this.article.isDiscourseActive">
        <h2 class="article-title comments-heading">Comments</h2>
        <!-- Muut -->
        <div class="muuts" data-show_title="false" *ngIf="(userService.userModel.muutMessage && userService.userModel.username) || !userService.userModel.username">
          <div class="muut-loading">
            <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div *ngIf="userService.userModel.isLoggedIn && !userService.userModel.isActivated">
          <dg-activation-notice [additionalText]="' to comment'"></dg-activation-notice>
        </div>
      </div>
    </div>

    <div class="next-prev-post">
      <div class="row">
        <div class="col-xs-6 next-wrap">
          <div *ngIf="previousArticle && previousArticle.title != ''">
            <span class="pull-left"> 
              <a class="ga-event" [href]=" ['/news/article/'+previousArticle.cleanLink] " title="{{previousArticle.title}}" data-ga_category="News Single Article - Previous" attr.data-ga_action="{{previousArticle.title}} - CTA Click" attr.data-ga_label="{{article.title}}">
                <i class="fa fa-angle-left"></i>  Previous
              </a>
            </span>
            <div class="prev-article-img">
              <a class="ga-event" [href]=" ['/news/article/'+previousArticle.cleanLink] " title="{{previousArticle.title}}" data-ga_category="News Single Article - Previous" attr.data-ga_action="{{previousArticle.title}} - Image Click" attr.data-ga_label="{{article.title}}">
                <img *ngIf="previousArticle.featuredImage.src; else previousArticleDefaultImage" loading="lazy" class="img-responsive" src="{{previousArticle.featuredImage.medium.src}}" alt="{{previousArticle.featuredImage.alt}}" width="385" height="170">
                <ng-template #previousArticleDefaultImage>
                  <img loading="lazy" class="img-responsive" src="/assets/images/default-news-image-thumb.webp" alt="Dodge Garage News" width="385" height="170">
                </ng-template>
              </a>
            </div>

            <h3>
              <a class="ga-event" [href]=" ['/news/article/'+previousArticle.cleanLink] " title="{{previousArticle.title}}" data-ga_category="News Single Article - Previous" attr.data-ga_action="{{previousArticle.title}} - Title Click" attr.data-ga_label="{{article.title}}">
                <span [innerHtml]="previousArticle.title">  </span>
              </a>
            </h3>
          </div>
        </div>
        <div class="col-xs-6 prev-wrap pull-right">
          <div *ngIf="nextArticle && nextArticle.title != ''">
            <span class="pull-right">
              <a class="ga-event" [href]=" ['/news/article/'+nextArticle.cleanLink] " title="{{nextArticle.title}}" data-ga_category="News Single Article - Next" attr.data-ga_action="{{nextArticle.title}} - CTA Click" attr.data-ga_label="{{article.title}}">
                Next <i class="fa fa-angle-right"></i>
              </a>
            </span>
            <div class="prev-article-img">
              <a class="ga-event" [href]=" ['/news/article/'+nextArticle.cleanLink] " title="{{nextArticle.title}}" data-ga_category="News Single Article - Next" attr.data-ga_action="{{nextArticle.title}} - Image Click" attr.data-ga_label="{{article.title}}">
                <img *ngIf="nextArticle.featuredImage.src; else nextArticleDefaultImage" loading="lazy" class="img-responsive" src="{{nextArticle.featuredImage.medium.src}}" alt="{{nextArticle.featuredImage.alt}}" width="385" height="170">
                <ng-template #nextArticleDefaultImage>
                  <img loading="lazy" class="img-responsive" src="/assets/images/default-news-image-thumb.webp" alt="Dodge Garage News" width="385" height="170">
                </ng-template>
              </a>
            </div>
            <h3>
              <a class="ga-event" [href]=" ['/news/article/'+nextArticle.cleanLink] " title="{{nextArticle.title}}" data-ga_category="News Single Article - Next" attr.data-ga_action="{{nextArticle.title}} - Title Click" attr.data-ga_label="{{article.title}}">
                <span [innerHtml]="nextArticle.title">  </span>
              </a>
            </h3>
          </div>
        </div><!-- /prev-wrap -->
      </div>
    </div>
  </div>
  <div class="like-container">
    <div class="like-count">{{ article.likes }}</div>
    <button class="btn btn-like" *ngIf="!article.isLikedByUser" (click)="likeArticle()" [disabled]="isLikeButtonLoading" [ngClass]="{'is-loading' : isLikeButtonLoading}">
      <span class="sr-only">Like</span>
      <i aria-hidden="true" class="fa fa-star-o"></i>
    </button>
    <button class="btn btn-like btn-like-is-liked" *ngIf="article.isLikedByUser" (click)="removeLikeArticle()" [disabled]="isLikeButtonLoading" [ngClass]="{'is-loading' : isLikeButtonLoading}">
      <span class="sr-only">Like</span>
      <i aria-hidden="true" class="fa fa-star"></i>
    </button>
  </div>
  <ngx-json-ld [json]="schema"></ngx-json-ld>
</article>
