<div class="panel panel-default">
  <div class="panel-heading" [id]="'heading-'+title">
    <span class="icon-toggle-link ga-event" [ngClass]="{'collapsed':collapse != 'open'}" [attr.aria-controls]="'collapse-'+title" [attr.aria-expanded]="(collapse == 'open')?'true':'false'" [attr.data-parent]="'#'+parentId" role="button" (click)="toggleCollapse($event)" data-ga_category="Panel Accordion" attr.data-ga_action="Toggle" [attr.data-ga_label]="collapse">
      <ng-content select="[heading]"></ng-content>
    </span>
  </div>
  <div [attr.aria-labelledby]="'heading-'+title" class="panel-collapse collapse in" [@expandCollapse]  [id]="'collapse-'+title" >
    <div class="panel-body" *ngIf="collapse == 'open'">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
</div>


