import {OnInit, Component, ElementRef, TemplateRef, ViewChild, ChangeDetectorRef, Input} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'daytona-charger',
  templateUrl: './daytona-charger.component.html',
  styleUrls: ['./daytona-charger.component.scss']
})
export class DaytonaChargerComponent implements OnInit {
  @Input() isInline: boolean = false;  


  currentDate: Date = this.forceDateToEST(new Date())
  revealDate: Date = this.forceDateToEST(new Date('March 05 2024 11:00:00 GMT-0500 (Eastern Standard Time)'));
  revealDateSplit: Date = this.forceDateToEST(new Date('March 05 2024 11:30:00 GMT-0500 (Eastern Standard Time)'));
  finalStageDate: Date = this.forceDateToEST(new Date('March 07 2024 9:00:00 GMT-0500 (Eastern Standard Time)'));
  
  loading: boolean = true;
  isVideoPlaying: boolean = false;
  showReplayButton: boolean = false;
  showPauseButton: boolean = false;
  modalActive: boolean = false;
  activeStage: number = this.setActiveStageInitial();
  // activeStage: number = 2;

  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('videoModal', { static: true }) public videoModal: TemplateRef<any>;

  // Stages
  @ViewChild('stage1') stage1Template: TemplateRef<any>;
  @ViewChild('stage2') stage2Template: TemplateRef<any>;
  @ViewChild('stage3') stage3Template: TemplateRef<any>;
  @ViewChild('stage4') stage4Template: TemplateRef<any>;

  videoModalRef: BsModalRef;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private modalService: BsModalService, 
  ) {
    // this.revealDate = this.forceDateToEST(new Date(this.currentDate.getTime() + 20 * 1000));
  }

  ngOnInit(): void {
    this.loading = false;
  }

  getStageTemplate(stage: number): TemplateRef<any> {
    switch (stage) {
      case 1:
        return this.stage1Template;
      case 2:
        return this.stage2Template;
      case 3:
        return this.stage3Template;
      case 4:
        return this.stage4Template;
      default:
        return this.stage1Template;
    }
  }

  updateActiveStage(stage: number) {
    this.activeStage = stage
    this.changeDetector.markForCheck();
  }

  setActiveStageInitial() {
    if (this.currentDate > this.finalStageDate) {
      return 4
    }else if (this.currentDate > this.revealDateSplit) {
      return 3
    }else if (this.currentDate > this.revealDate && this.currentDate < this.revealDateSplit) {
      return 2
    }else {
      return 1
    }
  }

  
  // Video controls
  playVideo(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.play().catch(error => {
      console.error('Error attempting to play video:', error);
    });
  }
  
  pauseVideo(): void { 
    this.isVideoPlaying ? this.videoPlayer.nativeElement.pause() : this.videoPlayer.nativeElement.play()
    this.isVideoPlaying = !this.isVideoPlaying
  }

  replayVideo(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.currentTime = 0; // Reset the video to the start
    video.play(); // Play the video
    this.showReplayButton = false;
  }

  videoPlaying(): void {
    this.showPauseButton = true;
    this.isVideoPlaying = true;
  }
 
  videoEnded(): void {
    this.showReplayButton = true;
    this.showPauseButton = false;
  }


  // Modals
  openModal(template: TemplateRef<any>) {
    this.videoModalRef = this.modalService.show(template, {class: 'modal-lb-video'});

    // Add video player
    const iframe = document.createElement('iframe');
    iframe.setAttribute('width', '560');
    iframe.setAttribute('height', '315');
    iframe.setAttribute('class', 'embed-responsive-item');
    // Set the YouTube video URL with autoplay enabled
    iframe.setAttribute('src', 'https://www.youtube.com/embed/uGAZkn11hcw?autoplay=1');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowfullscreen', 'true');

    // Append the iframe to the modal body or a specific container within the modal
    document.querySelector('#lb-video-modal .modal-body .embed-responsive').appendChild(iframe);

    // Click tracking
    window.dataLayer.push({
      "event": "LB Reveal Video Play",
      "lbRevealVideoPlay": true
    })


    // Subscribe to close and destroy video player
    this.modalService.onHidden.subscribe(() => {
      // Remove the iframe from the modal body when the modal is hidden
      if (iframe) {
        iframe.remove();
      }
    });
  }

  closeModal() {
    this.videoModalRef.hide();
  }

  onModalClose() {
    console.log('Modal has been closed');
  }


  // Date helper to enforce tz standards
  forceDateToEST(date: Date) {
    // Create a new Date object representing the current date and time
    let currentDate = new Date(date);

    // Format the date in Eastern Standard Time (EST)
    let estOptions = { timeZone: 'America/New_York' };
    let estDate = currentDate.toLocaleString('en-US', estOptions);

    return new Date(estDate);
  }
}
