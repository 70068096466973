import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'dg-last-chance-auction-book-full',
  templateUrl: './last-chance-auction-book-full.component.html',
  styleUrls: ['./last-chance-auction-book-full.component.less']
})
export class LastChanceAuctionBookFullComponent implements OnInit {

  constructor(
    private router: Router,
    public title: Title,
    public metaService: Meta
  ) {
    title.setTitle( 'Dodge Ultimate Last Chance Auction Book Full Version | Dodge Garage'  );
    metaService.updateTag({ name: 'description', content: 'The full version of Dodge Ultimate Last Chance Auction Book' });
    metaService.updateTag({ property: 'og:description', content: 'The full version of Dodge Ultimate Last Chance Auction Book' });
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
    metaService.updateTag({ property: 'og:title', content: 'Dodge Ultimate Last Chance Auction Book Full Version | Dodge Garage' });
    metaService.updateTag({ property: 'og:type', content: '' });
    metaService.updateTag({ property: 'og:image', content: 'https://www.dodgegarage.com/assets/images/ultimate-last-chance/last-chance-social-thumb.jpg' });
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url });
    metaService.updateTag({ name: 'twitter:card', content: 'summary' });
    metaService.updateTag({ name: 'twitter:image', content: 'https://www.dodgegarage.com/assets/images/ultimate-last-chance/last-chance-social-thumb.jpg' });
  }

  ngOnInit() {}

}
