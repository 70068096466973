<div class="site-wrapper skinny-site-wrapper">
  <header class="page-header">
    <div class="container-fluid">
      <div class="row-flex">
        <div class="col-flex-sm-8">
          <h1>Your eyes on speed</h1>
          <p>Like what you see? Click a pic for the full view and check out the related news story to get all the details. </p>
        </div>
        <div class="col-flex-sm-3 col-flex-sm-offset-1">
          <dg-gallery-search (search)="searchGallery($event)" [gallerySearch]="gallerySearch"></dg-gallery-search>
          <a routerLink="/gallery" id="submit" class="btn btn-primary btn-reset-gallery pull-right mt-3">Clear Search</a>
        </div>
      </div>
    </div>
  </header>
</div>
<section id="gallery">
  <div class="site-wrapper skinny-site-wrapper">
    <div class="gallery-container container-fluid">
      <div class="row-flex">
        <div class="col-flex-sm-4 col-flex-md-3" *ngFor="let galleryItem of galleryItemsShown; let i = index">
          <dg-gallery-card [galleryItem]="galleryItem"></dg-gallery-card>
        </div>
      </div>
      <div *ngIf="galleryListLoading" class="text-center">
          <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
          <span class="sr-only">Loading...</span>
      </div>
      <div class="show-more-container" *ngIf="!this.galleryListLoading && this.galleryItems.length >= this.galleryQuota">
        <button class="btn btn-primary" (click)="onShowMore()">Show More</button>
      </div>
    </div>
  </div>
  <ngx-json-ld [json]="schema"></ngx-json-ld>
</section>









