<div id="coming-soon">
  <div class="lead-bar"></div>
  <section id="hero" class="bg-black">
    <img src="/assets/images/eu/eu-coming-soon-garage-door.webp" alt="DodgeGarage EU Coming Soon" />
  </section>
  <section id="intro">
    <div class="container-fluid site-wrapper">
      <div class="row">
        <div class="col-sm-12 intro-content">
          <h1>Opening soon in Europe</h1>
          <p>
            We're welcoming the European Brotherhood of Muscle into the garage with the hottest news, information, events, motorsports and more. Everything making noise in the performance world - on your side of the Atlantic - can be found right here. When these doors open, it's on! Get ready.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section id="cta-items">
    <div class="site-wrapper container-fluid">
      <div class="row">
        <div class="col-sm-12 cta-container">
          <div id="cta-news" class="cta-item">
            <div class="content">
              <img src="/assets/images/eu/bg-cta-news.webp" alt="DodgeGarage EU Coming Soon - News" />
              <div>
                <h2>News</h2>
                <p>
                  Keep pace with everything happening on the scene with inside stories and content from contributors across Europe - curated exclusively for real enthusiasts.
                </p>
              </div>
            </div>
          </div>
          <div id="cta-events" class="cta-item">
            <div class="content">
              <img src="/assets/images/eu/bg-cta-events.webp" alt="DodgeGarage EU Coming Soon - Events" />
              <div>
                <h2>Events</h2>
                <p>
                  Know where to go, when to go, who will be there and why you should be, too. The DodgeGarge EU calendar will dominate your social schedule.
                </p>
              </div>
            </div>
          </div>
          <div id="cta-motorsports" class="cta-item">
            <div class="content">
              <img src="/assets/images/eu/bg-cta-motorsports.webp" alt="DodgeGarage EU Coming Soon - Motorsports" />
              <div>
                <h2>Motorsports</h2>
                <p>
                  Follow our European motorsports coverage for expert insights and insider access only DodgeGarage EU can deliver.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="site-wrapper container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <img loading="lazy" src="/assets/images/logos/dodge-garage-logo.png" alt="Dodge Garage - Logo" width="177" height="42" class="footer-logo">
          <div class="social-icons">
            <a class="ga-event" href="https://www.facebook.com/DodgeEurope/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="#fff"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg></a>
            <a class="social-icon" href="https://www.instagram.com/dodge.europe/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="#fff"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
            <a class="ga-event" href="https://www.youtube.com/playlist?list=PLf6xEkPrQwBXHwz7wjKYfaWZBTh_F0bCo" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" fill="#fff"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg></a>
          </div>
          <p>
            &copy;2024 FCA US LLC. All Rights Reserved. Chrysler, Dodge, Jeep, Ram, Wagoneer, Mopar and SRT are registered trademarks of FCA US LLC. ALFA ROMEO and FIAT are registered trademarks of FCA US LLC Group Marketing S.p.A., used with permission.
          </p>
          <p>
            <a href="#" target="_blank">Copyright</a> | 
            <a href="#" target="_blank">Privacy Statement</a> | 
            <a href="#" target="_blank">Terms of Use</a> | 
            <a href="#" target="_blank">Legal, safety and trademark information</a> | 
            <a href="#" target="_blank">Accessibility</a> | 
            <a href="#" target="_blank">Manage Your Privacy Choices</a> | 
            <a href="#" target="_blank">Cookie Settings</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</div>