import { Trim } from "./trim";
export const TRIMS:Array<Trim> = [



  {
    id: 1,
    model: 'Durango',
    trimCode: 'WDEX7529QPXJ',
    group: 'SRT 392 AlcHEMI 475 HP 6.4L SRT HEMI V8',
    color: 'Diamond Black',
    features: [],
    label: 'SRT 392 AlcHEMI 475 HP 6.4L SRT HEMI V8 - Diamond Black',
    link: '/assets/docs/horsepower-locator/2024-dodge-durango-srt-392-alchemi-special-edition.pdf',
    type: 'se',
    status: null,
    configId: 0
  },
  {
    id: 2,
    model: 'Durango',
    trimCode: 'WDEX7529QPAS',
    group: 'SRT 392 AlcHEMI 475 HP 6.4L SRT HEMI V8',
    color: 'Vapor Gray',
    features: [],
    label: 'SRT 392 AlcHEMI 475 HP 6.4L SRT HEMI V8 - Vapor Gray',
    link: '/assets/docs/horsepower-locator/2024-dodge-durango-srt-392-alchemi-special-edition.pdf',
    type: 'se',
    status: null,
    configId: 0
  },
  {
    id: 3,
    model: 'Durango',
    trimCode: 'WDEX7529QPDN',
    group: 'SRT 392 AlcHEMI 475 HP 6.4L SRT HEMI V8',
    color: 'Destroyer Gray',
    features: [],
    label: 'SRT 392 AlcHEMI 475 HP 6.4L SRT HEMI V8 - Destroyer Gray',
    link: '/assets/docs/horsepower-locator/2024-dodge-durango-srt-392-alchemi-special-edition.pdf',
    type: 'se',
    status: null,
    configId: 0
  },
  {
    id: 4,
    model: 'Durango',
    trimCode: 'WDEX7529QPW7',
    group: 'SRT 392 AlcHEMI 475 HP 6.4L SRT HEMI V8',
    color: 'White Knuckle',
    features: [],
    label: 'SRT 392 AlcHEMI 475 HP 6.4L SRT HEMI V8 - White Knuckle',
    link: '/assets/docs/horsepower-locator/2024-dodge-durango-srt-392-alchemi-special-edition.pdf',
    type: 'se',
    status: null,
    configId: 0
  },

  {
    id: 21,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7M2925MAA3AAYADKPSE',
    group: 'R/T AWD 496 HP STAGE 1',
    color: 'Triple Nickel',
    features: ['Plus Group', 'Sun & Sound', 'Blacktop', 'Black Seats'],
    label: 'R/T AWD 496 HP STAGE 1 <BR>- PLUS GROUP / SUN & SOUND / BLACKTOP / BLACK SEATS <BR>- TRIPLE NICKEL',
    link: '/assets/docs/horsepower-locator/1-dodge-charger-daytona-rt.pdf',
    type: 'se',
    status: null,
    configId: 1
  },
  {
    id: 22,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7M2925MAA3AAYADKPXJ',
    group: 'R/T AWD 496 HP STAGE 1',
    color: 'Diamond Black',
    features: ['Plus Group', 'Sun & Sound', 'Blacktop', 'Black Seats'],
    label: 'R/T AWD 496 HP STAGE 1 <BR>- PLUS GROUP / SUN & SOUND / BLACKTOP / BLACK SEATS <BR>- DIAMOND BLACK',
    link: '/assets/docs/horsepower-locator/1-dodge-charger-daytona-rt.pdf',
    type: 'se',
    status: null,
    configId: 1
  },
  {
    id: 23,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7M2925MAA3AAYADKPW7',
    group: 'R/T AWD 496 HP STAGE 1',
    color: 'White Knuckle',
    features: ['Plus Group', 'Sun & Sound', 'Blacktop', 'Black Seats'],
    label: 'R/T AWD 496 HP STAGE 1 <BR>- PLUS GROUP / SUN & SOUND / BLACKTOP / BLACK SEATS <BR>- WHITE KNUCKLE',
    link: '/assets/docs/horsepower-locator/1-dodge-charger-daytona-rt.pdf',
    type: 'se',
    status: null,
    configId: 1
  },
  {
    id: 24,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7M2925MAA3AAYADKPR6',
    group: 'R/T AWD 496 HP STAGE 1',
    color: 'Redeye',
    features: ['Plus Group', 'Sun & Sound', 'Blacktop', 'Black Seats'],
    label: 'R/T AWD 496 HP STAGE 1 <BR>- PLUS GROUP / SUN & SOUND / BLACKTOP / BLACK SEATS <BR>- REDEYE',
    link: '/assets/docs/horsepower-locator/1-dodge-charger-daytona-rt.pdf',
    type: 'se',
    status: null,
    configId: 1
  },
  {
    id: 25,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7M2925MAA3AAYADKPPS',
    group: 'R/T AWD 496 HP STAGE 1',
    color: 'After Dark',
    features: ['Plus Group', 'Sun & Sound', 'Blacktop', 'Black Seats'],
    label: 'R/T AWD 496 HP STAGE 1 <BR>- PLUS GROUP / SUN & SOUND / BLACKTOP / BLACK SEATS <BR>- AFTER DARK',
    link: '/assets/docs/horsepower-locator/1-dodge-charger-daytona-rt.pdf',
    type: 'se',
    status: null,
    configId: 1
  },
  {
    id: 31,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7M2925MAAYCEJPXJ',
    group: 'R/T AWD 496 HP STAGE 1',
    color: 'Diamond Black',
    features: ['Plus Group', 'Red Seats'],
    label: 'R/T AWD 496 HP STAGE 1 <BR>- PLUS GROUP / RED SEATS <BR>- DIAMOND BLACK',
    link: '/assets/docs/horsepower-locator/2-dodge-charger-daytona-rt.pdf',
    type: 'se',
    status: null,
    configId: 2
  },
  {
    id: 32,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7M2925MAAYPXJ',
    group: 'R/T AWD 496 HP STAGE 1',
    color: 'Diamond Black',
    features: ['Plus Group', 'Black Seats'],
    label: 'R/T AWD 496 HP STAGE 1 <BR>- PLUS GROUP / BLACK SEATS <BR>- DIAMOND BLACK',
    link: '/assets/docs/horsepower-locator/2-dodge-charger-daytona-rt.pdf',
    type: 'se',
    status: null,
    configId: 2
  },
  {
    id: 41,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SAAYADKADZANNPSE',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'Triple Nickel',
    features: ['Track Pack', 'Plus Group', 'Sun & Sound', 'Carbon & Suede', 'Black Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / PLUS GROUP / SUN & SOUND / CARBON & SUEDE / BLACK SEATS <BR>- TRIPLE NICKEL',
    link: '/assets/docs/horsepower-locator/3-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 3
  },
  {
    id: 42,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SAAYADKADZANNPXJ',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'Diamond Black',
    features: ['Track Pack', 'Plus Group', 'Sun & Sound', 'Carbon & Suede', 'Black Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / PLUS GROUP / SUN & SOUND / CARBON & SUEDE / BLACK SEATS <BR>- DIAMOND BLACK',
    link: '/assets/docs/horsepower-locator/3-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 3
  },
  {
    id: 43,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SAAYADKADZANNPW7',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'White Knuckle',
    features: ['Track Pack', 'Plus Group', 'Sun & Sound', 'Carbon & Suede', 'Black Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / PLUS GROUP / SUN & SOUND / CARBON & SUEDE / BLACK SEATS <BR>- WHITE KNUCKLE',
    link: '/assets/docs/horsepower-locator/3-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 3
  },
  {
    id: 44,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SAAYADKADZANNPR6',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'Redeye',
    features: ['Track Pack', 'Plus Group', 'Sun & Sound', 'Carbon & Suede', 'Black Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / PLUS GROUP / SUN & SOUND / CARBON & SUEDE / BLACK SEATS <BR>- REDEYE',
    link: '/assets/docs/horsepower-locator/3-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 3
  },
  {
    id: 45,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SAAYADKADZANNPPS',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'After Dark',
    features: ['Track Pack', 'Plus Group', 'Sun & Sound', 'Carbon & Suede', 'Black Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / PLUS GROUP / SUN & SOUND / CARBON & SUEDE / BLACK SEATS <BR>- AFTER DARK',
    link: '/assets/docs/horsepower-locator/3-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 3
  },
  {
    id: 51,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SAAYADKANNPSE',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'Triple Nickel',
    features: ['Track Pack', 'Sun & Sound', 'Plus Group', 'Black Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / SUN & SOUND / PLUS GROUP / BLACK SEATS <BR>- TRIPLE NICKEL',
    link: '/assets/docs/horsepower-locator/6-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 6
  },
  {
    id: 52,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SAAYADKANNPXJ',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'Diamond Black',
    features: ['Track Pack', 'Sun & Sound', 'Plus Group', 'Black Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / SUN & SOUND / PLUS GROUP / BLACK SEATS <BR>- DIAMOND BLACK',
    link: '/assets/docs/horsepower-locator/6-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 6
  },
  {
    id: 53,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SAAYADKANNPW7',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'White Knuckle',
    features: ['Track Pack', 'Sun & Sound', 'Plus Group', 'Black Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / SUN & SOUND / PLUS GROUP / BLACK SEATS <BR>- WHITE KNUCKLE',
    link: '/assets/docs/horsepower-locator/6-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 6
  },
  {
    id: 61,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SAAYANNPXJ',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'Diamond Black',
    features: ['Track Pack', 'Plus Group', 'Black Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / PLUS GROUP / BLACK SEATS <BR>- DIAMOND BLACK',
    link: '/assets/docs/horsepower-locator/4-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 4
  },
  {
    id: 62,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SAAYANNCEJPXJ',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'Diamond Black',
    features: ['Track Pack', 'Plus Group', 'Red Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / PLUS GROUP / RED SEATS <BR>- DIAMOND BLACK',
    link: '/assets/docs/horsepower-locator/4-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 4
  },
  {
    id: 71,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SADKANNCEJPXJ',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'Diamond Black',
    features: ['Track Pack', 'Sun & Sound', 'Red Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / SUN & SOUND / RED SEATS <BR>- DIAMOND BLACK',
    link: '/assets/docs/horsepower-locator/5-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 5
  },
  {
    id: 72,
    model: 'Charger Daytona 2-Door',
    trimCode: 'LB7S2925SADKANNPXJ',
    group: 'SCAT PACK AWD 670 HP STAGE 2',
    color: 'Diamond Black',
    features: ['Track Pack', 'Sun & Sound', 'Black Seats'],
    label: 'SCAT PACK AWD 670 HP STAGE 2 <BR>- TRACK PACK / SUN & SOUND / BLACK SEATS <BR>- DIAMOND BLACK',
    link: '/assets/docs/horsepower-locator/5-dodge-charger-daytona-scat-pack.pdf',
    type: 'se',
    status: null,
    configId: 5
  },

];
