import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dg-style-guide-gallery',
  templateUrl: './style-guide-gallery.component.html',
  styleUrls: ['./style-guide-gallery.component.less']
})
export class StyleGuideGalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
