<div class="row">
  <div class="col-sm-12">
    
    <div class="row mb-4">
      <div class="col-sm-12">
        <h2>Intro &amp; Start-Up Procedures</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p>
          Before starting the Drag Pak, it's important you familiarize yourself with the numerous gauges and switches. Each one of these has an important function during the starting procedure and overall operation.
        </p>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h3 id="switch-panel">Smartwire switch panel layout</h3>
      </div>
      <div class="col-sm-12 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/startup-procedures/1.webp" alt="Switch Panel Layout - 1" class="img-responsive" width="1440" height="279">
      </div>
      <div class="col-sm-12">
        <p>
          The SmartWire Panel is located above and in front of the driver, mounted on the upper windshield/upper roll bar and contains eight switches total. 
        </p>
        <p>
          #1 is a push button for the starter. 
        </p>
        <p>
          The remaining (7) are toggle switches that control:
        </p>
        <div class="well">
          <ul class="list-unstyled list-labels">
            <li><strong>Ignition: Switch #2</strong></li>
            <li><strong>Fuel: Switch #3</strong></li>
            <li><strong>Water Pump: Switch #4 </strong></li>
            <li><strong>Radiator Fan: Switch #5</strong></li>
            <li><strong>Intercooler Pump: Switch #6</strong></li>
            <li><strong>Optional Air Fuel Power: Switch #7</strong></li>
            <li><strong>Headlamps and Two-Step Arm: Switch #8</strong></li>
          </ul>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h3 id="power-switches">Main power switches</h3>
      </div>
      <div class="col-sm-6 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/startup-procedures/2.webp" alt="Startup Procedures - 2" class="img-responsive" width="1140" height="680">
      </div>
      <div class="col-sm-6 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/startup-procedures/3.webp" alt="Startup Procedures - 3" class="img-responsive" width="1140" height="680">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p>
          There are two main power switches on the Drag Pak. One is located on the right side rear fascia below the tail lamp. The second is a lever located on the inside next to the trans tunnel on the passenger side. Either switch can be used as they both operate the vehicle’s main power switch. To turn the power on while inside the car, pull the lever back. To turn the power on from outside, pull the lever on the rear facia-mounted power switch.
        </p>
      </div>
    </div>

    <hr class="my-5">

    <div class="row mb-5">
      <div class="col-sm-12">
        <div class="card card-horizontal well">
          <div class="img-container">
            <img loading="lazy" class="img-responsive" src="/assets/images/drag-pak-experience/startup-procedures/4.webp" alt="Startup Procedures - 4" width="566" height="566">
          </div>
          <div class="content">
            <div class="content-inner">
              <h3 id="shifter">Shifter</h3>
              <p class="mb-0">
                The 2021 Drag Pak is equipped with a unique automatic shifter that has a special pattern designed for drag racing. It’s important for you to familiarize yourself with how it functions before operating the vehicle. The shifter uses a cable to operate, and one special feature is the “Clean Neutral” function. This allows the driver to safely put the car in the Neutral position when going through traps without the risk of accidentally shifting into Park or Reverse.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-md-6">
        <ul>
          <li>To shift from Park to Reverse or 1st gear, squeeze the lever and push forward. </li>
          <li>To up-shift (from 1st into 2nd gear or from 2nd into 3rd gear), simply push the lever forward.</li>
          <li>To engage Neutral gear from 3rd gear, squeeze the lever and push forward.</li>
          <li>To re-engage 3rd gear from Neutral, squeeze the lever and pull back.</li>
        </ul>
      </div>
      <div class="col-md-6">
        <ul>
          <li>To down-shift (from 3rd into 2nd gear or from 2nd into 1st gear), squeeze the lever and pull back.</li>
          <li>To shift from 1st gear to Reverse, squeeze the lever, press down the button with your thumb and pull back.</li>
          <li>To shift from Reverse to Park, squeeze the lever and pull back.</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/startup-procedures/5.webp" alt="Startup Procedures" class="img-responsive" width="1600" height="496">
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h3 id="line-lock">Line Lock</h3>
        <p>
          The Line Lock button is located on the shifter handle to aid during the burnout procedure. To activate, first pump and hold the brake pedal to create adequate pressure – note the brake pressure gauge on the dash. Once you’ve reached approximately 600 pounds, hold the Line Lock button, then release the brake pedal. Burnout styles may vary, but make sure the rear slicks have rolled through the water box. Once adequate heat has been generated with the slicks via a burnout, release the Line Lock button and stage the car.
        </p>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h3 id="launch-control">Two-Step/Launch Control</h3>
        <p>
          The 2021 Drag Pak uses an integrated Two-Step and Launch Control/Torque Management feature that’s contained within the Holley Dominator ECU. This feature is enabled by turning on the headlamps - switch #8 on the SmartWire Switch Panel. Both the Two-Step and Launch Control get activated by depressing the brake pedal. For detailed information on their operation and proper usage, refer to the <a routerLink="/drag-pak-experience/ecu-launch-setting" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="Initial ECU Launch">Initial ECU Launch section.</a>
        </p>
      </div>
    </div>

    <hr class="my-5">

    <div class="row mb-5">
      <div class="col-sm-12">
        <div class="card card-horizontal well">
          <div class="img-container">
            <img loading="lazy" class="img-responsive" src="/assets/images/drag-pak-experience/startup-procedures/6.webp" alt="Parachute Lever" width="566" height="494">
          </div>
          <div class="content">
            <div class="content-inner">
              <h3 id="parachute-lever">Parachute lever</h3>
              <p class="mb-0">
                The parachute lever is located above the driver and is cable operated. <br class="visible-lg"> <a href="/assets/docs/drag-pak/sim-42020ks.pdf" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="sim-42020ks.pdf" target="_blank">Download this pdf for more information about deployment, repacking and care of the parachute.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12">
        <h3 id="power-window">Power Window Operation</h3>
        <p>
          The door windows on the 2021 Drag Pak are equipped with the Smart Glass feature like production street cars. This feature drops and raises the door glass approximately 5mm as the vehicle doors open and close. They use a special module for operation to accommodate the constant on/off switching of the main power during storage and race operations. After an extended period of the main power being switched off, the windows may need to go through a complete up/down cycle to recalibrate the module.
        </p>
        <div class="well mt-5">
          <p>
            <strong>To recalibrate the Smart Glass module:</strong>
          </p>
          <ol class="spaced">
            <li>Turn the main power off and wait 15 seconds, then turn back on</li>
            <li>Using the driver-side window switch, lower the door glass until the glass comes to a full stop, then continue to hold the switch for an additional 3 seconds.</li>
            <li>Next, raise the glass until the glass comes to a full stop and continue to hold the switch for 3 additional seconds</li>
          </ol>
          <p>
            <strong>
              NOTE: The main power must be on in order for the door windows to operate
              properly. Make sure the windows are in the dropped position before
              shutting off the main power to the vehicle so you can open and close the
              doors.
            </strong>
          </p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row mb-5">
      <div class="col-sm-12">
        <div class="card card-horizontal well">
          <div class="img-container">
            <img loading="lazy" class="img-responsive" src="/assets/images/drag-pak-experience/startup-procedures/7.webp" alt="Trunk Operation" width="580" height="530">
          </div>
          <div class="content">
            <div class="content-inner">
              <h3 id="trunk">Trunk Operation</h3>
              <p>
                The 2021 Drag Pak uses a production trunk with the Dodge Challenger SRT<sup>&reg;</sup> Performance spoiler. If the main power switch is turned on, the trunk can be opened by pushing the button located in the middle of the trunk lid above the tail lamp panel.
              </p>
              <p class="mb-0">
                If the main power is shut off, a cable can be found within the fuel door on the driver’s side that serves as a manual override to the production electronic switch. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row mb-5">
      <div class="col-sm-12">
        <div id="analog-gauges-card" class="card card-horizontal well">
          <div class="img-container">
            <img loading="lazy" class="img-responsive" src="/assets/images/drag-pak-experience/startup-procedures/8.webp" alt="analog gauges" width="580" height="298">
          </div>
          <div class="content">
            <div class="content-inner">
              <h3 id="gauges">Analog Gauges</h3>
              <p class="mb-0">
                The 2021 Drag Pak uses three analog gauges, which are mounted in the dash within the sightline of the driver. The AutoMeter PRO-COMP ULTRA-LITE series gauges monitor important vehicle information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h4 class="mt-5"><strong>Going from left to right:</strong></h4>
        <ul>
          <li>First is the 5-inch tachometer that measures engine speed up to 10,000 RPM.</li>
          <li>
            Second is the 2-5/8-inch brake pressure gauge, which is critical when setting the Line Lock and Two-Step. Inadequate brake pressure will cause the car to creep through the staging beams.
          </li>
          <li>
            Third is a 2-5/8-inch oil pressure gauge that needs to be monitored during start-up and competition.
          </li>
        </ul>  
        <p>
          Recommendations for brake pressure during activation of the Line Lock and Two-Step when staging are covered in the <a routerLink="/drag-pak-experience/ecu-launch-setting" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="Initial ECU Launch">Initial ECU Launch section.</a>
        </p>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h3 id="shift-light">Shift Light</h3>
      </div>
      <div class="col-sm-12">
        <p>
          The shift light is located in the dash in the direct sightline of the driver. The shift light RPM setting is pre-programmed for recommended shift points. The RPM settings can be customized using the Holley Dominator software. 
        </p>
      </div>
    </div>

    <hr class="my-5">

    <div class="row mb-5">
      <div class="col-sm-12">
        <div class="card card-horizontal well">
          <div class="img-container">
            <img loading="lazy" class="img-responsive" src="/assets/images/drag-pak-experience/startup-procedures/9.webp" alt="Holley Digital Dash" width="800" height="576">
          </div>
          <div class="content">
            <div class="content-inner">
              <h3 id="digital-dash">Holley Digital Dash</h3>
              <p class="mb-0">w
                The 2021 Drag Pak uses the Holley Digital Dash (part number 553-106) to monitor many engine functions. <a href="/assets/docs/drag-pak/holley-digital-dash-manual.pdf" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="holley-digital-dash-manual.pdf" target="_blank">Download the Holley Digital Dash User Manual for detailed information on its functions.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12">
        <div class="well">
          <h4>
            <strong>Pre-Start Procedures</strong>
          </h4>
          <ol>
            <li>Make sure the vehicle’s transmission is in Park.</li>
            <li>Make sure the fuel tank has been filled with the recommended VP Racing C16 Fuel.</li>
            <li>Confirm the engine and transmission have oil. Further details are in the <a routerLink="/drag-pak-experience/pre-run-checklist" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="Pre-Run Checklist">Pre-Run Checklist section.</a></li>
            <li>Confirm the radiator and intercooler tank have coolant or water. Further details are in the <a routerLink="/drag-pak-experience/pre-run-checklist" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="Pre-Run Checklist">Pre-Run Checklist section.</a></li>
            <li>Confirm the battery is charged and has a minimum voltage of 11 volts.</li>
          </ol>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row mb-5">
      <div class="col-sm-12 mb-3">
        <h3 id="startup">Start-Up Procedures</h3>
      </div>
      <div class="col-sm-12 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/startup-procedures/10.webp" alt="Start-Up Procedures" class="img-responsive" width="1440" height="279">
      </div>
      <div class="col-sm-12">
        <p>Please refer to the switch panel located on the upper roll bar directly above the driver’s sight line.</p>
        <ol class="spaced">
          <li>Make sure vehicle is in Park and all switches on the panel are in the down position.</li>
          <li>Turn on main power at either the interior or exterior location.</li>
          <li>Move the ignition #2 switch to the up position. This will send power to the modules and read out battery voltage on the digital dash. Battery voltage needs to be above 11v.</li>
          <li>
            Push the start button to crank the engine for 10-15 seconds to build oil pressure within the engine.
            <br>
            <strong>NOTE:</strong> The Holley Digital Dash will go blank during this step, which is normal. Watch for the needle on the oil pressure gauge to move off zero. If the gauge reads zero after cranking for 15 seconds, do not attempt to start the vehicle. 
          </li>
          <li>
            Turn the fuel pump #3 switch to the up position.
            <br>
            <strong>NOTE:</strong> The fuel pump will make an audible noise.
            <br>
            <strong>NOTE:</strong> The static fuel pressure should read 70+/- 2 psi on the Holley Digital Dash.
          </li>
          <li>Press the start button. The engine should start within seconds.</li>
          <li>
            Immediately toggle-up the remaining switches on the SmartWire panel:
            <ul>
              <li>Water Pump: Switch #4 </li>
              <li>Radiator Fan: Switch #5</li>
              <li>Intercooler Pump: Switch #6</li>
              <li>Optional Air Fuel Power: Switch #7</li>
              <li>Headlamps and Two-Step Arm: Switch #8</li>
            </ul>
          </li>
        </ol>
        <p><strong>WARNING:</strong> Engine damage can occur if the recommended start-up procedure is not followed.</p>
        <p><strong>WARNING:</strong> Do not start or run an engine in a closed garage or confined area.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h3>Things to pay attention to while starting the drag pak for the first time:</h3>
        <ul>
          <li>During idling, the oil pressure will range from 30 psi to 80 psi, depending on how much heat is in the engine.</li>
          <li>Do not exceed 180 degrees Fahrenheit coolant temperature during operation.</li>
          <li>The Drag Pak’s engine is designed to run at cold temperatures and doesn’t require a warm-up prior to competition.</li>
        </ul>
        <div class="well mt-5">
          <p class="mb-0">
            <strong>NOTE:</strong> During initial transport, the Drag Pak is filled with a 50/50 mix of coolant and water to protect the engine from sub-freezing temperatures. <a routerLink="/drag-pak-experience/pre-run-checklist" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="Pre-Run Checklist">Refer to the Competition Set-Up section for proper preparation of the cooling system and intercooler for competition.</a>
          </p>
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-sm-12 mt-5 text-center btn-container">
        <a routerLink="/drag-pak-experience/build-overview" class="btn btn-primary btn-footer-nav mr-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="2021 Drag Pak Build Overview"><i class="fa fa-chevron-left" aria-hidden="true"></i> 2021 Drag Pak Build Overview</a>
        <a routerLink="/drag-pak-experience/competition-setup" class="btn btn-primary btn-footer-nav ml-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Competition Setup">Competition Setup <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
      </div>
    </div>

    

  </div>
</div>
