import {UserModel} from "../user/user";
import {VehicleImage} from "./vehicle-image";
import {Badge} from "../dg-1320/badge/badge";
import {TimeSlip} from "../dg-1320/time-slip/time-slip";

enum Status {
  Approved = "APPROVED",
  Denied = "DENIED",
  Pending = "PENDING",
  Reviewed = "REVIEWED",
  InReview = "IN-REVIEW"
}
export class Vehicle {
  id: number = 0;
  year: number;
  make: string;
  model: string;
  trim: string;
  isStock: boolean;
  type: string;
  vin: string;
  isDefault: boolean = false;
  mods: string = '';
  vehicleLookupId: number = 0;
  user: UserModel;
  status: Status;
  images: VehicleImage[] = [];
  badges: any[] = [];
  badgesClub: any = [];
  badgesElapsedTime: any = [];
  badgesSixtyFeetTime: any = [];
  badgesTopSpeed: any = [];

  slotCounterETMax: any;
  slotCounterSixtyMax: any;
  slotCounterSpeedMax: any;

  //8SC
  isDemon: boolean;
  isEightSecond: boolean;
  badgesEightSecondClub: any = [];
  badgesElapsedTime8SC: any = [];
  badgesSixtyFeetTime8SC: any = [];
  badgesTopSpeed8SC: any = [];

  eightSecondSlotCounterETMax: any;
  eightSecondSlotCounterSixtyMax: any;
  eightSecondSlotCounterSpeedMax: any;

  slips: TimeSlip[] = [];
}
