import { Injectable } from '@angular/core';

@Injectable()
export class EventDodgeOfficialService {

  constructor() {}

  /*data = [
    {
      event: 'NHRA Spring Nationals',
      date: 'April 20-22',
      desc: 'Vehicles on Display',
      href: '/events/137/nhra-spring-nationals'
    },
    {
      event: 'NHRA Four-Wide Nationals',
      date: 'April 27-29',
      desc: 'Vehicles on Display, Thrill Rides & SRT Demon Simulators',
      href: '/events/139/nhra-four-wide-nationals'
    },
    {
      event: 'Mecum Indy',
      date: 'May 15-20',
      desc: 'Vehicles on Display & Thrill Rides',
      href: '/events/143/mecum-indy'
    },
    {
      event: 'NMCA Bluegrass Nationals',
      date: 'May 17-20',
      desc: 'Dodge/Mopar<sub class="realign subscript">®</sub> HEMI<sup class="realign superscript">®</sup> Shootout – The first 75 to register their ’05 & later Gen III will be comped entry!',
      href: '/events/144/nmca-bluegrass-nationals'
    },
    {
      event: 'NHRA Heartland Nationals',
      date: 'May 18-20',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/146/menards-nhra-heartland-nationals-presented-by-minties'
    },
    {
      event: 'Mopars in the Park',
      date: 'June 1-3',
      desc: 'Vehicles on Display',
      href: '/events/140/mopars-in-the-park'
    },
    {
      event: 'Barrett-Jackson Northeast',
      date: 'June 20-23',
      desc: 'Vehicles on Display & Thrill Rides',
      href: '/events/155/barrett-jackson-northeast'
    },
    {
      event: 'NHRA New England Nationals',
      date: 'July 6-9',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/159/nhra-new-england-nationals'
    },
    {
      event: 'Carlisle Chrysler Nationals',
      date: 'July 13-15',
      desc: 'Vehicles on Display',
      href: '/events/160/carlisle-chrysler-nationals'
    },
    {
      event: 'NHRA Dodge Mile High Nationals Block Party',
      date: 'July 19',
      desc: 'Vehicles on Display & SRT Demon Simulators'
    },
    {
      event: 'NHRA Dodge Mile High Nationals',
      date: 'July 20-22',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/162/nhra-dodge-mile-high-nationals'
    },
    {
      event: 'NMRA/NMCA Super Bowl of Street Legal Drag Racing',
      date: 'July 26-29',
      desc: 'Dodge/Mopar<sub class="realign subscript">®</sub> HEMI<sup class="realign superscript">®</sup> Shootout – The first 75 to register their ’05 & later Gen III will be comped entry!',
      href: '/events/163/nmranmca-super-bowl-of-street-legal-drag-racing'
    },
    {
      event: 'Mecum Harrisburg',
      date: 'August 2-4',
      desc: 'Vehicles on Display & Thrill Rides',
      href: '/events/166/mecum-harrisburg'
    },
    {
      event: 'NHRA Lucas Oil Nationals',
      date: 'August 17-19',
      desc: 'Vehicles on Display',
      href: '/events/168/nhra-lucas-oil-nationals'
    },
    {
      event: 'NMCA All-American Nationals',
      date: 'August 23-26',
      desc: 'Dodge/Mopar<sub class="realign subscript">®</sub> HEMI<sup class="realign superscript">®</sup> Shootout – The first 75 to register their ’05 & later Gen III will be comped entry!',
      href: '/events/172/nmca-all-american-nationals'
    },
    {
      event: 'NHRA U.S. Nationals',
      date: 'August 31 - September 3',
      desc: 'Vehicles on Display',
      href: '/events/174/nhra-us-nationals'
    },
    {
      event: 'Mecum Louisville',
      date: 'September 6-8',
      desc: 'Vehicles on Display & Thrill Rides',
      href: '/events/175/mecum-louisville'
    },
    {
      event: 'NHRA Dodge Nationals Block Party',
      date: 'September 12',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/177/nhra-dodge-nationals-block-party'
    },
    {
      event: 'NHRA Dodge Nationals',
      date: 'September 12-16',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/176/nhra-dodge-nationals'
    },
    {
      event: 'NMCA World Street Finals',
      date: 'September 20-23',
      desc: 'Dodge/Mopar<sub class="realign subscript">®</sub> HEMI<sup class="realign superscript">®</sup> Shootout – The first 75 to register their ’05 & later Gen III will be comped entry!'
    },
    {
      event: 'NHRA Midwest Nationals',
      date: 'September 21-23',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/178/nhra-midwest-nationals'
    },
    {
      event: 'Barrett-Jackson Las Vegas',
      date: 'September 27-29',
      desc: 'Vehicles on Display',
      href: '/events/179/barrett-jackson-las-vegas'
    },
    {
      event: 'Mecum Dallas',
      date: 'October 3-6',
      desc: 'Vehicles on Display',
      href: '/events/180/mecum-dallas'
    },
    {
      event: 'NHRA Fall Nationals',
      date: 'October 5-7',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/181/nhra-fall-nationals'
    },
    {
      event: 'NHRA Carolina Nationals',
      date: 'October 12-14',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/182/nhra-carolina-nationals'
    },
    {
      event: 'NHRA Nationals - Las Vegas',
      date: 'October 26-28',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/183/nhra-nationals-last-vegas'
    },
    {
      event: 'SEMA',
      date: 'October 30 - November 2',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/184/sema'
    },
    {
      event: 'NHRA Finals',
      date: 'November 9-11',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/185/nhra-finals'
    },
    {
      event: 'Mecum Las Vegas',
      date: 'November 15-17',
      desc: 'Vehicles on Display & Thrill Rides',
      href: '/events/186/mecum-las-vegas'
    }
  ]*/

  data = [
    {
      event: 'Mecum Indy',
      date: 'May 14-22',
      desc: 'Display Vehicles, Dodge Thrill Rides',
      href: '/events/486/mecum-indy'
    },
    {
      event: 'Barrett-Jackson Vegas',
      date: 'January 3-13',
      desc: 'Display Vehicles, Dodge Thrill Rides',
      href: '/events/558/barrett-jackson-las-vegas'
    },
    {
      event: 'Mecum Kissimmee',
      date: 'January 3-13',
      desc: 'Vehicles on Display & Thrill Rides',
      href: '/events/199/mecum-kissimmee'
    },
    {
      event: 'Barrett-Jackson Scottsdale',
      date: 'January 12-20',
      desc: 'Vehicles on Display & Thrill Rides',
      href: '/events/200/barrett-jackson-scottsdale'
    },
    {
      event: 'NHRA Winternationals',
      date: 'February 7-10',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/207/nhra-winternationals'
    },
    {
      event: 'Mecum Phoenix',
      date: 'March 14-16',
      desc: 'Vehicles on Display & Thrill Rides'
    },
    {
      event: 'NHRA Gatornationals',
      date: 'March 14-16',
      desc: 'Vehicles on Display & SRT Demon Simulators',
      href: '/events/226/nhra-gatornationals'
    },
  ];

  //Get all data
  getData: Function = () => this.data
}
