import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    // Check if running in the browser
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  pushEvent(event: any) {
    // Only attempt to push to the dataLayer if in the browser
    if (this.isBrowser && window && window.dataLayer) {
      window.dataLayer.push(event);
    }
  }
}