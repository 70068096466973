import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {Post} from '../post';
import {CommentService} from "../comment.service";
import {UserService} from "../../user/user.service";

@Component({
  selector: 'dg-delete-comment',
  templateUrl: './delete-comment.component.html',
  styleUrls: ['./delete-comment.component.less']
})
export class DeleteCommentComponent implements OnInit {

  @Input() post: Post;
  @Input() level: string;
  @Output() deleteCommentEmitter = new EventEmitter();
  @Output() cancelDeleteEmitter = new EventEmitter();

  isLoading: boolean = false;

  deleteCommentAlert = {
    show: false,
    status: '',
    message: '',
    additionalMessage: ''
  };

  constructor(
    private commentsService: CommentService,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  //The comment isn't actually deleted but we update the comment to be [deleted] so that children comments are still visible. The user can always come back and edit the comment.
  deleteComment() {
    this.isLoading = true;
    this.commentsService.updateComment(this.post, "[deleted]", this.userService.userModel.username).subscribe (data => {
      this.post = data.post;
      this.deleteCommentEmitter.emit(this.post);
      this.isLoading = false;
    }, errors => {
      //this.errors = errors;
      this.isLoading = false;
      this.deleteCommentAlert.show = true;
      this.deleteCommentAlert.status = 'danger';
      this.deleteCommentAlert.message = 'There was an error deleting the comment.';
    });
  }

  cancelDelete() {
    //this.modalRef.hide();
    this.cancelDeleteEmitter.emit('cancel');
  }

}
