import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'dg-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.less']
})
export class AlertBannerComponent implements OnInit {

  @Input() alert = {
    show: false,
    status: '',
    message: '',
    additionalMessage: ''
  };
  @Output() alertDismissed = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  closeAlert() {
    this.alert.show = false;
    this.alertDismissed.emit('alert dismissed');
  }

}
