import {Component, OnInit, ViewChild, NgZone, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import {SwiperComponent} from "swiper/angular";
import SwiperCore, { SwiperOptions, Pagination, Navigation, Autoplay } from 'swiper';
import { isPlatformBrowser } from '@angular/common';

SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
  selector: 'feature-slider',
  templateUrl: './feature-slider.component.html'
})
export class FeatureSlider implements OnInit {

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  isRunning: boolean = true;

  slides: any = [
    {
      heading: 'Dodge Complete Performance Package',
      description: 'Protect your ride with this Mopar Vehicle Protection<sup>&reg;</sup> plan',
      cta: 'Explore Details',
      link: 'https://www.mopar.com/en-us/dodge-complete-performance-package.html',
      image: '/assets/images/cta-tiles/dodge-performance-mvp.webp',
      external: true
    },
    {
      heading: 'Power Brokers',
      description: 'Find your Dodge Power Brokers Dealer',
      cta: 'Find Dealer',
      link: 'https://www.dodgegarage.com/power-brokers-dealers/',
      image: '/assets/images/cta-tiles/banner-power-brokers-third.jpg',
      logo: '/assets/images/logos/logo-power-brokers.png',
      logoAlt: 'Power Brokers Logo',
      external: true
    },
    {
      heading: 'Direct Connection',
      description: 'Shop Direct Connection Performance Parts',
      cta: 'Shop Parts',
      link: 'https://www.dcperformance.com/',
      image: '/assets/images/cta-tiles/banner-shop-direct-connection-third.jpg',
      logo: '/assets/images/logos/direct-connection-logo.png',
      logoAlt: 'Direct Connection Logo',
      external: true
    },
    {
      heading: '2nd Edition Direct Connection Catalog',
      description: 'View the performance parts catalog',
      cta: 'View Catalog',
      link: '/direct-connection-catalog/',
      image: 'assets/images/cta-tiles/dc-catalog-banner.webp',
      external: true
    }
  ]

  activeSlide: number = 0;
  mobileWidth: number = 1200;
  mobileView: boolean = false;

  config: SwiperOptions = {
    slidesPerView: 1,
    autoplay: {
      delay: 5000
    },
    pagination: {
      clickable: true,
      type: 'bullets',
      bulletActiveClass: 'active',
      bulletClass: 'carousel-nav-item'
    }
  };

  constructor(
    public zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.isMobile();
  }

  onSlideChange(event) {
    this.zone.run(() => this.activeSlide = event.activeIndex);
  }
  goToSlide(index) {
    this.swiper.swiperRef.slideTo(index);
  }

  @HostListener('window:resize', ['$event'])
  isMobile() {
    if (isPlatformBrowser(this.platformId)) {
      window.innerWidth < this.mobileWidth ? this.mobileView = true : this.mobileView = false;
    }
  }

  toggleSlider() {
    if(this.isRunning) {
      this.swiper.swiperRef.autoplay.stop();
    } else {
      this.swiper.swiperRef.autoplay.start();
    }
    this.isRunning = !this.isRunning;
  }
}
