export const exteriorColors: Array<any> = [
  {
    name: 'White Knuckle',
    hex: '#FFFFFF',
    code: 'PW7',
    price: 0,
    priceCA: 0,
    active: false
  },
  {
    name: 'Pitch Black',
    hex: '#000000',
    code: 'PX8',
    price: 0,
    priceCA: 0,
    active: false
  },
  {
    name: 'Granite',
    hex: '#45494C',
    code: 'PAU',
    price: 95,
    priceCA: 445,
    active: false
  },
  {
    name: 'Destroyer Grey',
    hex: '#45494C',
    code: 'PDN',
    price: 95,
    priceCA: 0,
    active: false
  },
  {
    name: 'Triple Nickel',
    hex: '#C1C5C8',
    code: 'PSE',
    price: 95,
    priceCA: 0,
    active: true
  },
  {
    name: 'Torred',
    hex: '#D01F35',
    code: 'PR3',
    price: 0,
    priceCA: 0,
    active: false
  },
  {
    name: 'Octane Red',
    hex: '#702E3E',
    code: 'PRV',
    price: 395,
    priceCA: 395,
    active: false
  },
  {
    name: 'Sinamon Stick',
    hex: '#CA734B',
    code: 'PEC',
    price: 395,
    priceCA: 395,
    active: false
  },
  {
    name: 'Go Mango',
    hex: '#FF4713',
    code: 'PVP',
    price: 395,
    priceCA: 0,
    active: false
  },
  {
    name: 'F8 Green',
    hex: '#556545',
    code: 'PFQ',
    price: 95,
    priceCA: 445,
    active: false
  },
  {
    name: 'Sublime',
    hex: '#97D608',
    code: 'PFB',
    price: 395,
    priceCA: 0,
    active: false
  },
  {
    name: 'B5 Blue',
    hex: '#238DC1',
    code: 'PQD',
    price: 95,
    priceCA: 395,
    active: false
  },
  {
    name: 'Frostbite',
    hex: '#005677',
    code: 'PCA',
    price: 95,
    priceCA: 395,
    active: false
  },
  {
    name: 'Plum Crazy',
    hex: '#230871',
    code: 'PHG',
    price: 95,
    priceCA: 395,
    active: false
  }
]