import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {
  transform(items: any, filter: {[key: string]: any }, defaultFilter: boolean = true): any {
    if (!filter){
      return items;
    }

    if (!Array.isArray(items)){
      return items;
    }

    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);
      var newFilterKeys = [];
      for(let i =0; i < filterKeys.length; i++){
        if(filter[filterKeys[i]]) {
          newFilterKeys.push(filterKeys[i]);
        }
      }

      if(newFilterKeys.length >= 1) {
        if (defaultFilter) {
          return items.filter(item =>
            newFilterKeys.reduce((x, keyName) =>
              (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
        }
        else {
          if(newFilterKeys.length === 1){
            return items.filter(item => {
              return newFilterKeys.some((keyName) => {
                return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
              });
            });
          } else {
            return items.filter(item => {
              return newFilterKeys.every((keyName) => {
                return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
              });
            });
          }
        }
      }
      else {
        return items;
      }
    }

  }

}
