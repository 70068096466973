import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
//import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class UserInterceptor implements HttpInterceptor {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    //console.log('start interceptor');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isPlatformBrowser(this.platformId)) {
      const user_access = JSON.parse(localStorage.getItem('user_access'));

      //We want to not send the authorization header over to dodgegarage.chat or Vimeo
      if (user_access && !req.url.includes('dodgegarage.chat') && !req.url.includes('api.vimeo.com')) {
        const cloned = req.clone({
          headers: req.headers.set("Authorization", "Bearer " + user_access.access_token)
        });

        return next.handle(cloned);
      }
      else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }
}
