<dg-alert-banner [alert]="timeSlipFormAlert"></dg-alert-banner>
<div class="signup-form">
  <form [formGroup]="timeSlipForm">
    <div class="row">
      <div class="col-lg-7">
        <div class="row">
          <div class="col-sm-7">
            <div class="form-group" [ngClass]="{ 'has-error': timeSlipForm.controls.raceTrack.invalid && (timeSlipForm.controls.raceTrack.dirty || timeSlipForm.controls.raceTrack.touched) }">
              <label for="raceTrack">Race Track</label>
              <input id="raceTrack" type="text" formControlName="raceTrack" class="form-control"
                    [ngClass]="{ 'is-invalid': timeSlipForm.controls.raceTrack.invalid && (timeSlipForm.controls.raceTrack.dirty || timeSlipForm.controls.raceTrack.touched) }">
              <div *ngIf="timeSlipForm.controls.raceTrack.invalid && (timeSlipForm.controls.raceTrack.dirty || timeSlipForm.controls.raceTrack.touched)">
                <div *ngIf="timeSlipForm.controls.raceTrack.errors.required" class="help-block">Race track is required</div>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="form-group" [ngClass]="{ 'has-error': timeSlipForm.controls.date.invalid && (timeSlipForm.controls.date.dirty || timeSlipForm.controls.date.touched) }">
              <label for="time-slip-date">Time Slip Date</label>
              <input type="date" placeholder="mm/dd/yyyy" id="time-slip-date" name="time-slip-date" formControlName="date" [max]="today" class="form-control"
                    [ngClass]="{ 'is-invalid': timeSlipForm.controls.date.invalid && (timeSlipForm.controls.date.dirty || timeSlipForm.controls.date.touched) }">
              <div *ngIf="timeSlipForm.controls.date.invalid && (timeSlipForm.controls.date.dirty || timeSlipForm.controls.date.touched)">
                <div *ngIf="timeSlipForm.controls.date.errors.required" class="help-block">Time slip date is required</div>
                <div *ngIf="timeSlipForm.controls.date.errors.isFuture" class="help-block">Time slip date can not be from the future</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" [ngClass]="{ 'has-error': timeSlipForm.controls.vehicle.invalid && (timeSlipForm.controls.vehicle.dirty || timeSlipForm.controls.vehicle.touched) }">
              <label for="vehicle">Vehicle</label><br>
              <select id="vehicle" name="vehicle" formControlName="vehicle" class="form-control"
                      [ngClass]="{ 'is-invalid': timeSlipForm.controls.vehicle.invalid && (timeSlipForm.controls.vehicle.dirty || timeSlipForm.controls.vehicle.touched) }">
                <option value="">Choose...</option>
                <option *ngFor="let vehicle of vehicles" [value]="vehicle.id">{{vehicle.id + ' ' + vehicle.model + ' ' + vehicle.trim}}</option>
              </select>
              <div *ngIf="timeSlipForm.controls.vehicle.invalid && (timeSlipForm.controls.vehicle.dirty || timeSlipForm.controls.vehicle.touched)">
                <div *ngIf="timeSlipForm.controls.vehicle.errors.required" class="help-block">Vehicle is required</div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
            <label>&nbsp;
              <div class="form-control-static">You can manage your vehicles on your profile</div>
            </label>
            </div>
          </div>
        </div>
        <div class="row input-block">
          <div class="col-sm-4">
            <div class="form-group" [ngClass]="{ 'has-error': timeSlipForm.controls.et.invalid && (timeSlipForm.controls.et.dirty || timeSlipForm.controls.et.touched) }">
              <label for="et">1/4 Mile ET</label>
              <input type="number" id="et" name="et" formControlName="et" class="form-control" min="0"
                     [ngClass]="{ 'is-invalid': timeSlipForm.controls.et.invalid && (timeSlipForm.controls.et.dirty || timeSlipForm.controls.et.touched) }">
              <div *ngIf="timeSlipForm.controls.et.invalid && (timeSlipForm.controls.et.dirty || timeSlipForm.controls.et.touched)">
                <div *ngIf="timeSlipForm.controls.et.errors.required" class="help-block">1/4 mile ET is required</div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group" [ngClass]="{ 'has-error': timeSlipForm.controls.zeroToSixty.invalid && (timeSlipForm.controls.zeroToSixty.dirty || timeSlipForm.controls.zeroToSixty.touched) }">
              <label for="zeroToSixty">60 ft. Time</label>
              <input type="number" id="zeroToSixty" formControlName="zeroToSixty" class="form-control"
                    [ngClass]="{ 'is-invalid': timeSlipForm.controls.zeroToSixty.invalid && (timeSlipForm.controls.zeroToSixty.dirty || timeSlipForm.controls.zeroToSixty.touched) }" min="0">
              <div *ngIf="timeSlipForm.controls.zeroToSixty.invalid && (timeSlipForm.controls.zeroToSixty.dirty || timeSlipForm.controls.zeroToSixty.touched)">
                <div *ngIf="timeSlipForm.controls.zeroToSixty.errors.required" class="help-block">60 ft. time is required</div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group" [ngClass]="{ 'has-error': timeSlipForm.controls.topSpeed.invalid && (timeSlipForm.controls.topSpeed.dirty || timeSlipForm.controls.topSpeed.touched) }">

              <label for="topSpeed">1/4 Mile Speed</label>
              <input type="number" id="topSpeed" formControlName="topSpeed" class="form-control"
                    [ngClass]="{ 'is-invalid': timeSlipForm.controls.topSpeed.invalid && (timeSlipForm.controls.topSpeed.dirty || timeSlipForm.controls.topSpeed.touched) }" min="0">
              <div *ngIf="timeSlipForm.controls.topSpeed.invalid && (timeSlipForm.controls.topSpeed.dirty || timeSlipForm.controls.topSpeed.touched)">
                <div *ngIf="timeSlipForm.controls.topSpeed.errors.required" class="help-block">1/4 mile speed is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="well well-transparent">

              <div class="row">
                <div class="col-sm-7">
                  <div class="form-group dg-upload" [ngClass]="{ 'has-error': timeSlipForm.controls.timeslip.invalid && (timeSlipForm.controls.timeslip.dirty || timeSlipForm.controls.timeslip.touched) }">
                    <span class="control-label section-label">Upload photo of time slip <strong>only</strong>:<br><span class="text-danger">This image will be viewable to the public.</span></span>
                    <div class="upload-container">
                      <label class="control-label btn btn-ghost-black text-uppercase" for="time-slip-file">
                        {{timeslipImgSrc ? 'Update File': 'Choose File'}}
                        <input type="file"
                               id="time-slip-file" name="time-slip-file"
                               accept="image/png, image/jpeg" [ngClass]="{ 'is-invalid': timeSlipForm.controls.timeslip.invalid && (timeSlipForm.controls.timeslip.dirty || timeSlipForm.controls.timeslip.touched) }" (change)="onTimeSlipFileChange($event, timeSlipImgModal)"/>
                      </label>
                      <!--<p>
                        {{ timeslipImage ? timeslipImage.name : 'No File Chosen' }}
                      </p>-->
                    </div>
                    <!--<p *ngIf="timeslip.imagePath1"><a [href]="timeslip.imagePath1" target="_blank">Current Image</a></p>-->
                    <div *ngIf="timeSlipForm.controls.timeslip.invalid && (timeSlipForm.controls.timeslip.dirty || timeSlipForm.controls.timeslip.touched)">
                      <div *ngIf="timeSlipForm.controls.timeslip.errors.required" class="help-block">Time slip photo required</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5">
                  <div *ngIf="!timeslipImgSrc" class="img-placeholder">
                    <p>No File Chosen</p>
                  </div>
                  <img loading="lazy" *ngIf="timeslipImgSrc" [src]="timeslipImgSrc" alt="Time Slip Only" class="img-responsive time-slip-img">
                </div>
              </div>

              <div class="row">
                <div class="col-sm-7">
                  <div class="form-group dg-upload" [ngClass]="{ 'has-error': timeSlipForm.controls.timeslipAndVin.invalid && (timeSlipForm.controls.timeslipAndVin.dirty || timeSlipForm.controls.timeslipAndVin.touched) }">
                <span class="control-label section-label">Upload photo of time slip <strong>with VIN</strong>:</span>
                <div class="upload-container">
                  <label class="control-label btn btn-ghost-black text-uppercase" for="time-slip-vin-file">
                    {{timeslipAndVinImgSrc ? 'Update File': 'Choose File'}}
                    <input class="btn ghost-btn form-control" type="file"
                        id="time-slip-vin-file" name="time-slip-vin-file"
                        accept="image/png, image/jpeg" [ngClass]="{ 'is-invalid': timeSlipForm.controls.timeslipAndVin.invalid && (timeSlipForm.controls.timeslipAndVin.dirty || timeSlipForm.controls.timeslipAndVin.touched) }" (change)="onTimeSlipAndVinFileChange($event, timeSlipAndVinImgModal)"/>
                  </label>
                  <!--<p>
                    {{ timeslipAndVinImage ? timeslipAndVinImage.name : 'No File Chosen' }}
                  </p>-->
                </div>
                <!--<p *ngIf="timeslip.imagePath2"><a [href]="timeslip.imagePath2" target="_blank">Current Image</a></p>-->
                <div *ngIf="timeSlipForm.controls.timeslipAndVin.invalid && (timeSlipForm.controls.timeslipAndVin.dirty || timeSlipForm.controls.timeslipAndVin.touched)">
                <div *ngIf="timeSlipForm.controls.timeslipAndVin.errors.required" class="help-block">Time slip and VIN photo required</div>
              </div>
              </div>
                </div>
                <div class="col-sm-5">
                  <div *ngIf="!timeslipAndVinImgSrc" class="img-placeholder">
                    <p>No File Chosen</p>
                  </div>
                  <img loading="lazy" *ngIf="timeslipAndVinImgSrc" [src]="timeslipAndVinImgSrc" alt="Time Slip Only" class="img-responsive time-slip-img">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 mb-15">
        <div class="row">
          <div class="col-sm-12">
            <h3>Time Slip Requirements</h3>
            <ul class="list-flush-left">
              <li>Must be from an approved event at an sanctioned 1/4 mile drag strip facility</li>
              <li>Results must be both entered manually and images uploaded</li>
              <li>Manual entry info must match time slip image for approval</li>
              <li>VIN must match the 1320 Club vehicle selected</li>
            </ul>
          </div>
          <div class="col-sm-12">
            <h3>Time Slip Requirements</h3>
            <div class="row">
              <div class="col-xs-6 text-center">
                <a href="#" (click)="openTimeSlipImgModal(sampleTimeSlipImgModal, '/assets/images/1320/timeslip-without-vin.jpg', 'Example time slip without VIN', $event)">
                  <img loading="lazy" src="/assets/images/1320/timeslip-without-vin.jpg" alt="Example time slip without VIN" class="img-responsive">
                  <span class="caption">Time Slip</span>
                </a>
              </div>
              <div class="col-xs-6 text-center">
                <a href="#" (click)="openTimeSlipImgModal(sampleTimeSlipImgModal, '/assets/images/1320/timeslip-with-vin.jpg', 'Example time slip with VIN', $event)">
                  <img loading="lazy" src="/assets/images/1320/timeslip-with-vin.jpg" alt="Example time slip with VIN" class="img-responsive">
                  <span class="caption">Time Slip with VIN</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group text-center" *ngIf="!isEditingMode && onboarding"> <!-- If not editing and onboarding -->
      <button class="btn btn-primary" (click)="onSubmit()" [disabled]="isLoading">
        Submit and Proceed to My Garage
        <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
      </button>
    </div>
    <div class="form-group text-center" *ngIf="!isEditingMode && !onboarding"> <!-- If not editing and onboarding -->
      <button class="btn btn-primary" (click)="onSubmit()" [disabled]="isLoading">
        Submit Time Slip
        <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
      </button>
    </div>
    <div class="form-group text-center" *ngIf="isEditingMode && !onboarding"> <!-- If not editing and onboarding -->
      <button class="btn btn-primary" (click)="onSubmit()" [disabled]="isLoading">
        Update Time Slip
        <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
      </button>
    </div>


    <!--<div class="row">
      <hr>
      <div class="col-sm-12 create-btn">
        <input type="submit" value="submit" class="btn ghost-btn red full-width" data-form="create-form">
      </div>
    </div>-->
  </form>
</div>

<!-- Modal -->
<ng-template #sampleTimeSlipImgModal>
  <div class="modal-body text-center">
    <p><img loading="lazy" [src]="timeSlipSampleImg" [alt]="timeSlipSampleImgAlt" class="img-responsive center-block"></p>
    <p>{{timeSlipSampleImgAlt}}</p>
    <p class="text-center"><button class="btn btn-white" (click)="sampleTimeSlipImgModalRef.hide()">Close</button></p>
  </div>
</ng-template>

<!-- Modal -->
<ng-template #timeSlipImgModal>
  <div class="modal-container">
    <div class="modal-header">
      <h2 class="modal-title pull-left">Edit Your Image</h2>
      <button class="modal-close" (click)="timeSlipImgModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <dg-image-editing [imageFile]="timeslipImage" [aspectRatio]="5/7" (croppedImage)="updateTimeSlipImage($event)"></dg-image-editing>
    </div>
  </div>
</ng-template>

<!-- Modal -->
<ng-template #timeSlipAndVinImgModal>
  <div class="modal-container">
    <div class="modal-header">
      <h2 class="modal-title pull-left">Edit Your Image</h2>
      <button class="modal-close" (click)="timeSlipAndVinImgModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <dg-image-editing [imageFile]="timeslipAndVinImage" [aspectRatio]="5/7" (croppedImage)="updateTimeSlipAndVinImage($event)"></dg-image-editing>
    </div>
  </div>
</ng-template>
