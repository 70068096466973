<div id="gallery-1" class="gallery galleryid-19721 gallery-columns-3 gallery-size-thumbnail"><figure class="gallery-item">
  <div class="gallery-icon landscape">
    <a href="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG001.png" rel="group"><img loading="lazy" width="220" height="220" src="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG001-220x220.png" class="attachment-thumbnail size-thumbnail" alt="" aria-describedby="gallery-1-19892" srcset="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG001-220x220.png 220w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG001-327x327.png 327w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG001.png 768w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG001-450x450.png 450w" sizes="(max-width: 220px) 100vw, 220px"></a>
  </div>
  <figcaption class="wp-caption-text gallery-caption" id="gallery-1-19892">
    The 1964-65 Race HEMIs lacked the visual dazzle of the GTO and Mustang but took the Package Car theme to new heights. The only customer options were color, transmission type (4-speed stick or TorqueFlite automatic) and tire size. Priced at $4,717 new, this ’65 A990 HEMI Coronet sedan is one of 101 built (roughly 50 Dodge 330 Race HEMI lightweight sedans were built in 1964). Note the single headlamp grille. Eliminating the high beams shed nearly 3 pounds.
  </figcaption></figure><figure class="gallery-item">
  <div class="gallery-icon landscape">
    <a href="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG002.png" rel="group"><img loading="lazy" width="220" height="220" src="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG002-220x220.png" class="attachment-thumbnail size-thumbnail" alt="" aria-describedby="gallery-1-19893" srcset="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG002-220x220.png 220w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG002-327x327.png 327w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG002.png 768w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG002-450x450.png 450w" sizes="(max-width: 220px) 100vw, 220px"></a>
  </div>
  <figcaption class="wp-caption-text gallery-caption" id="gallery-1-19893">
    As with the 1962-64 Max Wedge Package Cars, the HEMI lightweight sedans rode on the same medium-duty front suspension as Slant Six and 318-powered models. Note the lack of a front anti-roll bar and the 10-inch diameter front brake drums. Far less potent 1964-65 426 Street Wedge Coronets (and later 1966-71 Street HEMIs) came standard with anti-roll bars and 11-inch drums. The Race HEMI scheme shed many pounds while freeing the front suspension to rise quickly for faster rearward weight transfer on “the green”. 4.56 gears came standard.
  </figcaption></figure><figure class="gallery-item">
  <div class="gallery-icon landscape">
    <a href="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG003.png" rel="group"><img loading="lazy" width="220" height="220" src="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG003-220x220.png" class="attachment-thumbnail size-thumbnail" alt="" aria-describedby="gallery-1-19894" srcset="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG003-220x220.png 220w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG003-327x327.png 327w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG003.png 768w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG003-450x450.png 450w" sizes="(max-width: 220px) 100vw, 220px"></a>
  </div>
  <figcaption class="wp-caption-text gallery-caption" id="gallery-1-19894">
    The mighty A864 426 Race HEMI started with iron heads and an aluminum cross ram intake manifold in 1964. In reaction to a 1965 NHRA ruling banning aluminum body panels in Super Stock, the 1965 A990 HEMI went to aluminum heads and a magnesium cross ram. Visible on this 1965 A990 are the aluminum alternator bracket, deep-groove Race HEMI pulleys and tube-type exhaust headers. The free-wheeling clutch fan saved 5 horsepower and was carried over from the late 1963 Stage III Max Wedge program.
  </figcaption></figure><figure class="gallery-item">
  <div class="gallery-icon landscape">
    <a href="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG004.png" rel="group"><img loading="lazy" width="220" height="220" src="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG004-220x220.png" class="attachment-thumbnail size-thumbnail" alt="" aria-describedby="gallery-1-19895" srcset="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG004-220x220.png 220w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG004-327x327.png 327w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG004.png 768w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG004-450x450.png 450w" sizes="(max-width: 220px) 100vw, 220px"></a>
  </div>
  <figcaption class="wp-caption-text gallery-caption" id="gallery-1-19895">
    Dated March 24, 1964, this once-secret memo from Dodge to NHRA president Wally Parks outlines the “package” behind the 1964 Dodge 330 (and Plymouth Savoy) A864 lightweight HEMI sedan. Line 5 indicates the switch from manual door glass wind up mechanisms to simple Ferrari-esque pull-straps. For contrast, in Dodge’s new 2019 Challenger Scat Pack 1320, the door glass controls are power operated! Have we gone tame? Nope. The electric equipment is actually much lighter than old-school roll-up window regulators. The strip-only Challenger LC22R Drag-Pak also uses electric door window controls – with plastic windows.
  </figcaption></figure><figure class="gallery-item">
  <div class="gallery-icon landscape">
    <a href="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG005.png" rel="group"><img loading="lazy" width="220" height="220" src="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG005-220x220.png" class="attachment-thumbnail size-thumbnail" alt="" aria-describedby="gallery-1-19896" srcset="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG005-220x220.png 220w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG005-327x327.png 327w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG005.png 768w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG005-450x450.png 450w" sizes="(max-width: 220px) 100vw, 220px"></a>
  </div>
  <figcaption class="wp-caption-text gallery-caption" id="gallery-1-19896">
    Like today’s Dodge Challenger Scat Pack 1320, the Race HEMI sedans came standard with no back seat. Automatic-equipped Race HEMIs used push-button shift controls (1964) or the column-mounted lever seen in this 1965 Coronet sedan. The detents between gears were modified. Instead of shifting from First, to Second, then into Drive, the A990 pattern was reversed to eliminate the threat of accidentally shifting into Neutral or Reverse. The radio was dealer installed. “Tunes” were strictly n/a from the factory.
  </figcaption></figure><figure class="gallery-item">
  <div class="gallery-icon landscape">
    <a href="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG006.png" rel="group"><img loading="lazy" width="220" height="220" src="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG006-220x220.png" class="attachment-thumbnail size-thumbnail" alt="" aria-describedby="gallery-1-19897" srcset="https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG006-220x220.png 220w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG006-327x327.png 327w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG006.png 768w, https://www.dodgegarage.com/news-api/wp-content/uploads/2018/10/Less-is-More_IMG006-450x450.png 450w" sizes="(max-width: 220px) 100vw, 220px"></a>
  </div>
  <figcaption class="wp-caption-text gallery-caption" id="gallery-1-19897">
    The trunk of this all-original, un-restored 1965 A990 showcases the huge, factory-installed battery. Also used in 1964 Race HEMI sedans, it puts 90 pounds behind the rear tires, an optimal place to counteract the extra mass of the beefy 426 Race HEMI up front.
  </figcaption></figure>
</div>
