import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

import { ContributorsService } from './contributors.service';
import {HelperService} from "../services/helper.service";
import { Author } from '../news-articles/author';

@Component({
  selector: 'contributors',
  templateUrl: './contributors.component.html'
})
export class ContributorsComponent implements OnInit {

  constructor(
    public helperService: HelperService,
    public title: Title,
    public metaService: Meta,
    private router: Router,
    private contributorsService: ContributorsService,
  ) {
    title.setTitle( 'Contributors | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'Dodgegarage.com is a digital content hub and premier destination for muscle car and race enthusiasts to hang out to get the latest on all things Dodge, SRT®, Mopar and anything automotive related worth talking about! Just as we look for a wide variety of enthusiast stories and events, we have editorial contributors with diverse backgrounds and experiences to help craft stories from their own unique perspectives. Meet our current contributors.' })
    metaService.updateTag({ property: 'og:description', content: 'Dodgegarage.com is a digital content hub and premier destination for muscle car and race enthusiasts to hang out to get the latest on all things Dodge, SRT®, Mopar and anything automotive related worth talking about! Just as we look for a wide variety of enthusiast stories and events, we have editorial contributors with diverse backgrounds and experiences to help craft stories from their own unique perspectives. Meet our current contributors.' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | Contributors' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  //Prop set to capture service data and use in template via interpolation.
  contributors: Author[];
  pastContributors: Author[];

  ngOnInit() {
    //Get data from service and set local prop
    //this.contributors = this.contributorsService.getData()
    this.contributorsService.getContributors('current').subscribe( data => {
      this.contributors = data;
    });

    this.contributorsService.getContributors('past').subscribe( data => {
      this.pastContributors = data;
    });
  }

}
