import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'build-overview',
  templateUrl: './build-overview.component.html',
})

export class DragPakBuildOverviewComponent implements OnInit {
  ngOnInit(): void {

  }

}

