import {Component, OnInit, TemplateRef} from '@angular/core';
import {UserService} from "../../user.service";
import {UserModel} from "../../user";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";

declare var loggedIn: Function;

@Component({
  selector: 'performance-cars-login-form-inline',
  templateUrl: './login-form-inline.component.html',
  styleUrls: ['./login-form-inline.component.less']
})
export class LoginFormInlineComponent implements OnInit {

  userModel = new UserModel();
  forgotPasswordModalRef: BsModalRef;

  isLoading: boolean = false;

  user: UserModel = new UserModel();
  loginForm: FormGroup;
  loginFormAlert = {
    show: false,
    status: '',
    code: '', // Shorthand for identifying certain alerts
    message: '',
    additionalMessage: ''
  };
  accessToken: string = '';

  constructor(
    public userService: UserService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.createLoginForm();
  }

  createLoginForm() {
    this.loginForm = new FormGroup({
      'username': new FormControl('', [
        Validators.required
      ]),
      'password': new FormControl('', [
        Validators.required
      ])
    });
  }

  login() {
    // Check all fields before submitting
    Object.keys(this.loginForm.controls).forEach(field => {
      const control = this.loginForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.loginForm.valid) { // If form is valid, proceed
      this.isLoading = true;
      // Set Form Values to User Model
      this.user.username = this.loginForm.controls['username'].value;
      this.user.password = this.loginForm.controls['password'].value;
      this.userService.postLogin(this.user).subscribe( data => {

        if (this.userService.isInactiveUser(data)) {
          // User is inactive
          this.loginFormAlert.show = true;
          this.loginFormAlert.status = 'danger';
          this.loginFormAlert.message = 'This account has been deactivated. If you would like to reactivate your account, please <a href="#" (click)="sendReactivation($event)">click here</a>.';
          this.loginFormAlert.code = 'deactivated';
          this.accessToken = data.access_token;
          this.isLoading = false;
          return;
        }

        this.loginFormAlert.show = true;
        this.loginFormAlert.status = 'success';
        this.loginFormAlert.message = 'You have logged in successfully.';
        this.loginFormAlert.code = 'success';
        // Display response message
        this.isLoading = false;

        // Process Login Data
        this.userService.processLogin(data);
      }, error => {
        this.loginFormAlert.show = true;
        this.loginFormAlert.status = 'danger';
        this.loginFormAlert.message = 'Credentials are invalid, please try again.';
        this.loginFormAlert.code = 'invalid-credentials';
        this.isLoading = false;
      });
    } else {
      this.loginFormAlert.show = true;
      this.loginFormAlert.status = 'danger';
      this.loginFormAlert.message = 'Please fix the issues below.';
      this.loginFormAlert.code = 'fix-issues';
    }
  }

  openModal(modalName: TemplateRef<any>, event) {
    event.preventDefault();
    // Show the new modal
    this.forgotPasswordModalRef = this.modalService.show(modalName);
  }

  sendReactivation(event) {
    event.preventDefault();
    this.isLoading = true;
    this.userService.sendReactivation(this.accessToken).subscribe( data => {
      this.loginFormAlert.status = 'success';
      this.loginFormAlert.message = 'The reactivation email was sent successfully.';
      this.loginFormAlert.code = 'reactivation-code-sent';
      this.isLoading = false;
    }, (error) => {
      this.loginFormAlert.additionalMessage = 'There was an error resending the reactivation email.';
      this.isLoading = false;
    });
  }

}
