import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '../../event/event.service';
import {Event} from "../../event/event";
import {SwiperComponent} from "swiper/angular";
import SwiperCore, { SwiperOptions, Pagination, Navigation } from 'swiper';

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'events-slider',
  templateUrl: './events-slider.component.html',
  styles: []
})
export class EventsSlider implements OnInit {

  events: Event[] = [];
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  config: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 25,
    breakpoints: {
      600: { slidesPerView: 2 },
      768: { slidesPerView: 3 },
      1024: { slidesPerView: 4 }
    },
    navigation: {
      'nextEl': '#carousel-next',
      'prevEl': '#carousel-prev'
    }
  };

  constructor(
    public route: ActivatedRoute,
    public eventService: EventService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe( () => {
      this.fetchEvents(8);
    });
  }

  private fetchEvents(max: number = 6, offset: number = 0) {
    this.eventService.getEvents(max,offset).subscribe( data => {
      data.forEach( event => this.eventService.mutateEvent(event));
      this.events = this.events.concat(data);
    })
  }

}
