<div class="site-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div *ngIf="!comingSoon" class="video-container">
          <div class="video-grid" [class.gallery-centered]="galleryCentered" #videoContainer>
            <div *ngFor="let video of videos" class="grid-item" (click)="selectedTile = video.snippet.position">
              <div class="skew-wrapper" [ngClass]="{'active': selectedTile == video.snippet.position}">
                <div class="item-inner" (click)="emitActiveVideo(video.snippet.resourceId.videoId)" [attr.data-video-id]="video.snippet.resourceId.videoId">
                  <img [src]="video.snippet.thumbnails.high.url ? video.snippet.thumbnails.high.url : video.snippet.thumbnails.default.url" alt="">
                </div>
              </div>
            </div>
          </div>

          <button *ngIf="controlsVisible" class="slide-control previous" (click)="slideGallery('backwards')"><i class="fa fa-chevron-left"></i><span class="sr-only">Previous</span></button>
          <button *ngIf="controlsVisible" class="slide-control next" (click)="slideGallery('forwards')"><i class="fa fa-chevron-right"></i><span class="sr-only">Next</span></button>
        </div>

        <div *ngIf="comingSoon" class="coming-soon-container">
          <img src="/assets/images/rkn2022/coming-soon.png" alt="Coming soon" class="img-responsive">
        </div>
      </div>
    </div>
  </div>
</div>


