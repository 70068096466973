import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BracketRacingCtaComponent } from './bracket-racing-cta/bracket-racing-cta.component';
import { DemonFaqCtaComponent } from './demon-faq-cta/demon-faq-cta.component';
import { DragRacingFaqCtaComponent } from './drag-racing-faq-cta/drag-racing-faq-cta.component';
import { ProRacingCtaComponent } from './pro-racing-cta/pro-racing-cta.component';
import { RacingEventsCalendarCtaComponent } from './racing-events-calendar-cta/racing-events-calendar-cta.component';
import { SportsmanCtaComponent } from './sportsman-cta/sportsman-cta.component';
import { StockSuperStockCtaComponent } from './stock-super-stock-cta/stock-super-stock-cta.component';
import { ThirteenTwentyTileComponent } from '../homepage/sections/thirteen-twenty-tile/thirteen-twenty-tile.component';
import { TrackLocatorCtaComponent } from './track-locator-cta/track-locator-cta.component';
import { RouterModule } from '@angular/router';
import { RacingArticlesCtaComponent } from './racing-articles-cta/racing-articles-cta.component';
import { DragPakCtaComponent } from './drag-pak-cta/drag-pak-cta.component';
import { NHRAMembershipCtaComponent } from './nhra-membership-cta/nhra-membership-cta.component';
import { JailbreakCtaComponent } from './jailbreak-cta/jailbreak-cta.component';
import { HellcatAvailabilityCtaComponent } from './hellcat-availability-cta/hellcat-availability-cta.component';


@NgModule({
  declarations: [
    BracketRacingCtaComponent,
    DemonFaqCtaComponent,
    DragRacingFaqCtaComponent,
    ProRacingCtaComponent,
    RacingEventsCalendarCtaComponent,
    SportsmanCtaComponent,
    StockSuperStockCtaComponent,
    ThirteenTwentyTileComponent,
    TrackLocatorCtaComponent,
    RacingArticlesCtaComponent,
    DragPakCtaComponent,
    NHRAMembershipCtaComponent,
    JailbreakCtaComponent,
    HellcatAvailabilityCtaComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
    exports: [
        BracketRacingCtaComponent,
        DemonFaqCtaComponent,
        DragRacingFaqCtaComponent,
        ProRacingCtaComponent,
        RacingEventsCalendarCtaComponent,
        SportsmanCtaComponent,
        StockSuperStockCtaComponent,
        ThirteenTwentyTileComponent,
        TrackLocatorCtaComponent,
        RacingArticlesCtaComponent,
        DragPakCtaComponent,
        NHRAMembershipCtaComponent,
        JailbreakCtaComponent,
        HellcatAvailabilityCtaComponent
    ]
})
export class CtaTilesSharedModule { }
