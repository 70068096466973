// Use this directive as an attribute on any iframe to adjust the frame height
// after rendering the page.
//
// I ripped this hack from here:
// https://stackoverflow.com/questions/41801329/adjust-height-of-iframe-according-to-content-size-in-angular-2

import {Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';


@Directive({
  selector: '[iframeAutoHeight]'
})
export class IframeAutoHeightDirective implements OnInit {
  private el: any;
  private renderer: Renderer2;
  private prevHeight: number;
  private sameCount: number;

  // For window resizing
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.setHeight();
    }, 500);
  }

  constructor(_elementRef: ElementRef, _renderer: Renderer2) {
    this.el = _elementRef.nativeElement;
    this.renderer = _renderer;
  }

  ngOnInit() {
    const self = this;
    if (this.el.tagName === 'IFRAME') {
      this.renderer.listen(this.el, 'load', () => {
        self.prevHeight = 0;
        self.sameCount = 0;
        setTimeout(() => {
          self.setHeight();
        }, 500);
      });
    }
  }

  setHeight() {
    const self = this;
    if (this.el.contentWindow.document.body.scrollHeight !== this.prevHeight) {
      this.sameCount = 0;
      this.prevHeight = this.el.contentWindow.document.body.scrollHeight;
      this.renderer.setStyle(self.el, 'height', this.el.contentWindow.document.body.scrollHeight  + 'px');
    } else {
      this.sameCount++;
      if (this.sameCount < 2) {
        setTimeout(() => {
          self.setHeight();
        }, 500);
      }
    }
  }
}
