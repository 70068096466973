import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

import {NewsArticle} from "../news-articles/news-article";
import {NewsArticleService} from "../news-articles/news-article.service";
import { imageGallery, imageGalleryInterior } from './image-gallery';
import { fabFive, standardFeatures, SRTStandardFeatures } from './stats';
import { exteriorColors } from './exterior-colors';
declare let ga: Function;

@Component({
  selector: 'dg-demon-170',
  templateUrl: './demon-170.component.html',
  styleUrls: ['./demon-170.component.scss']
})
export class Demon170Component implements OnInit {

  constructor(
    public title: Title,
    public metaService: Meta,
    private router: Router,
    private http: HttpClient,
    public newsArticleService: NewsArticleService,
    private modalService: BsModalService
  ) {
    title.setTitle( '2023 Dodge Challenger SRT Demon 170 | DodgeGarage' );
    metaService.updateTag({ name: 'description', content: 'The SRT® Demon 170 is most powerful muscle car in the world with 1,025 horsepower' })
    metaService.updateTag({ property: 'og:description', content: 'The SRT® Demon 170 is most powerful muscle car in the world with 1,025 horsepower' })
    metaService.updateTag({ property: 'og:site_name', content: 'DodgeGarage' })
    metaService.updateTag({ property: 'og:title', content: '2023 Dodge Challenger SRT Demon 170 | DodgeGarage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: environment.url + this.imageArray[0].image })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})

    // Prevent undefined access
    this.newsArticleData = new NewsArticle();
  }

  @ViewChild('featuresModal', { static: true }) public featuresModal: TemplateRef<any>;
  featuresModalRef: BsModalRef;
  newsArticleData: NewsArticle;

  heroVideo: Object = {
    headline: '2023 Dodge Challenger SRT<sup>&reg;</sup> Demon 170',
    videoDesktop: '/assets/videos/video-170-desktop.mp4',
    videoMobile: '/assets/videos/video-170-mobile.mp4'
  }
  footerVideo: Object = {
    headline: null,
    videoDesktop: '/assets/videos/video-170-garage-desktop.mp4',
    videoMobile: '/assets/videos/video-170-garage-mobile.mp4'
  }
  standardSRTFeatures: Array<any> = SRTStandardFeatures;
  fabFive: Array<any> = fabFive; //For intro stats
  imageArray: Array<any> = imageGallery; //for gallery carousel
  standards: Array<any> = standardFeatures;
  colorSwatches: Array<any> = exteriorColors;
  interiorGallery: Array<any> = imageGalleryInterior;

  // Exterior configurator
  activeColor: String;

  caDealerAllocation: Array<any>;

  ngOnInit() {
    // loop through array and set active color as default
    this.colorSwatches.forEach(item => {
      if (item.active) { this.activeColor = item.code }
    });

    // For news article
    // D170 PR article --> 69330
    // Random article for testing --> 68642
    this.newsArticleService.getArticleByWordPressId(68642).subscribe( articleData => {
      this.newsArticleData = this.newsArticleService.mutateArticle(articleData);
    });
  }

  // To handle event emitter from color swatch component
  updateJellybeans(event) {
    this.activeColor = event;
  }

  openModal() {
    this.featuresModalRef = this.modalService.show(this.featuresModal, { class: 'features-modal package-modal modal-xl'});
    ga('send', 'event', 'Demon_170-Landing', 'Click', 'Standard Features Modal Open');
  }

  closeModal() {
    this.featuresModalRef.hide();
  }

  goToArticle(article: NewsArticle, event) {
    event.preventDefault();
    if (this.newsArticleService.isNewsArticleViewFull) {
      this.router.navigate(['/news/article/' + article.cleanLink]);
    } else {
      this.newsArticleService.newsArticleOpen = article;
      // console.log(this.newsArticleService.newsArticleOpen);
      this.newsArticleService.setModalOpen(true);
    }
  }

}
