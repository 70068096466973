import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { UserService } from "../../user/user.service";
import { HelperService } from "../../services/helper.service";

@Component({
  selector: 'dg-password-reset-banner',
  templateUrl: './password-reset-banner.component.html',
  styleUrls: ['./password-reset-banner.component.less']
})
export class PasswordResetBannerComponent implements OnInit {

  constructor(
    public userService: UserService,
    public helperService: HelperService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.userService.userModel.resetToken = this.getParameterByName('resetToken');
    this.renderer.addClass(document.body, 'banner-active')
    //this.userService.userModel.email = this.getParameterByName('email');
  }

  ngOnInit() {
    this.clickToClose()
  }

  hideBanner() {
    const banner = this.el.nativeElement.querySelector('.system-banner')
    const body = this.renderer
    const rect = banner.getBoundingClientRect()

    //hide success message after scrolling passed.
    window.addEventListener('scroll', () => {
      if (banner.classList.contains('bg-success')) {
        window.pageYOffset >= rect.top + rect.height ? this.helperService.hideBanner(banner, body) : ''
      }
    })

    setTimeout(() => {
      banner.classList.contains('bg-success') ? this.renderer.removeClass( document.body, 'banner-active' ) : ''
    }, 500)

    //hide success message after 10 seconds idle.
    setTimeout(() => {
      banner.classList.contains('bg-success') ? this.helperService.hideBanner(banner, body) : ''
    }, 10000)
  }

  clickToClose() {
    const banner: any = this.el.nativeElement.querySelector('.system-banner')
    const body = this.renderer
    const bg: any = document.querySelector('.banner-bg')
    const nav: any = document.querySelector('#primary-nav')
    const close: any = this.el.nativeElement.querySelector('.close')
    nav.onclick = () => this.helperService.hideBanner(banner, body)
    bg.onclick = () => this.helperService.hideBanner(banner, body)
    close.onclick = () => this.helperService.hideBanner(banner, body)
  }

  getParameterByName(name) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

}
