import {
  Component, OnInit, PLATFORM_ID, Inject, TemplateRef, ViewChild
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { NavigationEnd, Router } from "@angular/router";
import { UserService } from "../user/user.service";
import {Vehicle} from "../vehicle/vehicle";
import { VehicleService } from '../vehicle/vehicle.service';
import { isPlatformBrowser } from "@angular/common";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

//import {TwentyDaysOfHorsepowerLeaderBoardComponent} from './leaderboard/20-days-of-horsepower-leaderboard.component';
import { TwentyDaysOfHorsepowerService } from './20-days-of-horsepower.service';

@Component({
  selector: 'dg-20-days-of-horsepower',
  templateUrl: './20-days-of-horsepower.component.html',
  styleUrls: ['./20-days-of-horsepower.component.scss']
})
export class TwentyDaysOfHorsepowerComponent implements OnInit {


  // modals
  rulesModalRef: BsModalRef;
  @ViewChild('rulesModal', { static: true }) public rulesModal: TemplateRef<any>;
  
  messageModalRef: BsModalRef;
  @ViewChild('messageModal', { static: true }) public messageModal: TemplateRef<any>;

  userVehicles: Vehicle[] = [];

  hasVehiclesAndSlips: boolean = false;

  isStockSharingIconActive: boolean = false;

  dataLoaded: boolean = false; 
  dealers: Array<any> = [];

  salesChallenger: Array<any> = [];
  salesChallengerFormatted: Array<any> = [];
  winnersChallenger: Array<any> = [];
  winnersChallengerFormatted: Array<any> = [];
  latestWinnerChallenger: any;

  salesDurango: Array<any> = [];
  salesDurangoFormatted: Array<any> = [];
  winnersDurango: Array<any> = [];
  winnersDurangoFormatted: Array<any> = [];
  latestWinnerDurango: any; 
  
  salesHornet: Array<any> = [];
  salesHornetFormatted: Array<any> = [];
  winnersHornet: Array<any> = [];
  winnersHornetFormatted: Array<any> = [];
  latestWinnerHornet: any;

  eventVehicle: string = 'Durango SRT 392 AlcHEMI';
  eventStartDate: string = 'Jan 9 2024 11:00:00 GMT-0500 (Eastern Standard Time)';
  eventEndDate: string = 'May 1 2024 11:00:00 GMT-0500 (Eastern Standard Time)';
  targetDate: Date = this.setCountdownDate();
  eventEnded: boolean = false;

  showCountdown: boolean = true;
  snowfall: boolean = false;
  fadeSnow: boolean = false;
  snowfallImage: string = '/assets/images/twenty-days/demon-snowstorm-10.gif';
  snowfallSrc: string;
  winners: boolean = false;
  comingSoon: boolean = false;
  hideWinner: boolean = false;
  showHornet: boolean = true;

  disabledDates = [
    new Date('Jan 15 2024'),
    new Date('Feb 5 2024'),
    new Date('Mar 4 2024'),
    new Date('Apr 3 2024'),
    new Date('Apr 4 2024')
  ];
  
  disabledDatesHornet = [
    new Date('Jan 15 2024'),
    new Date('Feb 5 2024'),
    new Date('Mar 4 2024'),

    // Manually disable dates where we did not have hornet data
    new Date('Mar 13 2024'),
    new Date('Mar 14 2024'),
    new Date('Mar 15 2024'),
    new Date('Mar 18 2024'),
    new Date('Mar 19 2024'),
    new Date('Mar 20 2024'),
    new Date('Mar 21 2024'),
    new Date('Mar 22 2024'),
    new Date('Mar 25 2024'),
    new Date('Apr 3 2024'),
    new Date('Apr 4 2024')
  ];

  environment;

  constructor(
    public userService: UserService,
    public vehicleService: VehicleService,
    public leaderboardService: TwentyDaysOfHorsepowerService,
    private router: Router,
    public title: Title,
    public metaService: Meta,
    private modalService: BsModalService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    
    this.environment = environment;

    // Page Setup SSR
    
    // Set Meta Data
    title.setTitle('Dodge Days of Horsepower | Dodge Garage');
    metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
    metaService.updateTag({ name: 'description', content: 'Dodge Days of Horsepower' });
    metaService.updateTag({ rel: 'canonical', href: this.environment.url + this.router.url });
    metaService.updateTag({ property: 'og:description', content: 'Dodge Days of Horsepower' });
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
    metaService.updateTag({ property: 'og:title', content: 'Dodge Days of Horsepower'});
    metaService.updateTag({ property: 'og:type', content: 'article' });
    metaService.updateTag({ property: 'og:image', content: this.environment.url + '/assets/images/demon-170/products/Demon170LogoOnBlack.webp' });
    metaService.updateTag({ property: 'og:url', content: this.environment.url + this.router.url });
    metaService.updateTag({ name: 'twitter:card', content: 'summary' });
    metaService.updateTag({ name: 'twitter:image', content: this.environment.url + '/assets/images/demon-170/products/Demon170LogoOnBlack.webp' });


    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event) => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        
        // Set up data for tables
        this.getAllEntries();
        this.getAllWinners();
       

        // If event is expired, show post-event content
        if (this.forceDateToEST(new Date()) > new Date(this.eventEndDate)) {
          this.eventEnded = true;
          //this.openMessageModal();
        }
      });
    }
  }

  ngOnInit() {
    // Run setCountdownDate to ensure we are past the start date to remove Coming Soon state
    this.setCountdownDate()
  }

  getEntryData(id: number, vehicle: string, date?: string) {
    const data = {
      raffle: id,
      vehicle: vehicle,
      drawingDate: date
    }
    this.leaderboardService.getEntries(data).subscribe(response => {
      console.log(response)
    })
  }

  getAllEntries(date?: string) {
    const durango = {
      raffle: 1,
      vehicle: 'Dodge Durango R/T',
      drawingDate: date
    }
    const hornet = {
      raffle: 2,
      vehicle: 'Dodge Hornet',
      drawingDate: date
    }

    this.dataLoaded = false;
    this.leaderboardService.getEntries(durango).subscribe(response => {
      this.salesDurango = response.entries;
      this.salesDurangoFormatted = this.salesDurango.map(x => ({...x , salesDateFormatted: this.updateOffsetOnDateFromData(x.drawing_date)}));

      setTimeout(() => {
        this.dataLoaded = true;
      }, 100)
    })
    this.leaderboardService.getEntries(hornet).subscribe(response => {
      this.salesHornet = response.entries;
      this.salesHornetFormatted = this.salesHornet.map(x => ({...x , salesDateFormatted: this.updateOffsetOnDateFromData(x.drawing_date)}));

      setTimeout(() => {
        this.dataLoaded = true;
      }, 100)
    })
  }
  
  getAllWinners(date?: string) {
    const durango = {
      raffle: 1,
      vehicle: 'Dodge Durango R/T',
      drawingDate: date
    }
    const hornet = {
      raffle: 2,
      vehicle: 'Dodge Hornet',
      drawingDate: date
    }

    this.dataLoaded = false;
    this.leaderboardService.getWinners(durango).subscribe(response => {
      this.winnersDurango = response.winners;
      this.winnersDurangoFormatted = this.winnersDurango.map(x => ({...x , salesDateFormatted: this.formatDateString(x.winner_date)}));
      
      setTimeout(() => {
        this.dataLoaded = true;
      }, 100)
    })
    this.leaderboardService.getWinners(hornet).subscribe(response => {
      this.winnersHornet = response.winners;
      this.winnersHornetFormatted = this.winnersHornet.map(x => ({...x , salesDateFormatted: this.formatDateString(x.winner_date)}));

      // test the most recent winner sales date, if it doesn't match today then hide the hornet section
      if(this.winnersHornetFormatted[0]?.salesDateFormatted !== this.formatDateString(new Date())) {
        this.showHornet = false;
      }
      
      setTimeout(() => {
        this.dataLoaded = true;
      }, 100)
    })
  }

  formatEntriesDurango(data) {
    this.salesDurango = data.entries
  }

  getDealerData(inline = false) {
    if (!inline) {
      this.dataLoaded = false;
    }
    this.leaderboardService.getDealers().subscribe(res => {
      this.dealers = res;

      // organize data
      this.salesChallenger = this.dealers['dailyChallenger']
      this.salesHornet = this.dealers['dailyHornet']
      this.winnersChallenger = this.dealers['challengerWinners']
      this.winnersHornet = this.dealers['hornetWinners']

      this.latestWinnerChallenger = this.winnersChallenger[0];
      this.latestWinnerHornet = this.winnersHornet[0];

      this.salesChallengerFormatted = this.salesChallenger.map(x => ({...x , salesDateFormatted: this.formatDateString(x.salesDate)}));
      this.salesHornetFormatted = this.salesHornet.map(x => ({...x , salesDateFormatted: this.formatDateString(x.salesDate)}));
      this.winnersChallengerFormatted = this.winnersChallenger.map(x => ({...x , salesDateFormatted: this.formatDateString(x.winner_date)}));
      this.winnersHornetFormatted = this.winnersHornet.map(x => ({...x , salesDateFormatted: this.formatDateString(x.winner_date)}));

      if (!inline) {
        setTimeout(() => {
          this.dataLoaded = true;
        }, 100)
      }
    });
  }

  openRulesModal(event) {
    event.preventDefault();
    this.rulesModalRef = this.modalService.show(this.rulesModal, {class: 'modal-full'});
  }
  
  openMessageModal() {
    this.messageModalRef = this.modalService.show(this.messageModal, {class: 'mt-0'});
  }

  // Start snowfall at 1 minute remaining
  startSnowfall(): void {
    this.snowfall = true;
    setTimeout(() => {
      this.snowfallSrc = this.snowfallImage;
    }, 250)
  } 

  // Show winners when countdown expires completely.
  displayWinners(): void {
    const today = new Date();
    this.getAllWinners(this.formatDateString(today.toISOString()));
    this.winners = true;

    setTimeout(() => {
      this.snowfallSrc = '';
      this.hideWinner = false;
      this.comingSoon = false;
    }, 500)

    // When the winners are displayed, reset countdown clock and wackiness
    setTimeout(() => {
      this.winners = false;
      
      // Run this function alone to check to make sure we are past the start date
      this.setCountdownDate();
      this.targetDate = this.setCountdownDate();
      this.snowfall = false;

      if (!this.eventEnded) { 
        this.showCountdown = false;
      }

      // We no longer need to filter by date, get all winners
      this.getAllWinners();

      setTimeout(() => {
        this.showCountdown = true;
      }, 200);
    }, 12000);
  }

  hideWinners() {
    this.hideWinner = true;
  }
  
  prefetchData() {
    this.getAllEntries();
  }

  formatEventStartDate() {
    return new Date(this.eventStartDate)
  }

  getNextWeekday(currentDate, targetDay) {
    // Ensure targetDay is within valid range (1 to 5, where 1 is Monday and 5 is Friday)
    if (targetDay < 1 || targetDay > 5) {
      throw new Error('Invalid target day. Should be between 1 (Monday) and 5 (Friday).');
    }
  
    // Clone the current date to avoid modifying the original object
    const nextDate = new Date(currentDate);
  
    // Get the current day of the week (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
    let currentDay = nextDate.getDay();
  
    // Calculate the number of days to add to reach the target weekday
    let daysToAdd = targetDay - currentDay;
  
    // If the target day is the same as or before the current day, add a week
    if (daysToAdd <= 0) {
      daysToAdd += 7;
    }
  
    // Add the calculated days to the current date
    nextDate.setDate(nextDate.getDate() + daysToAdd);
  
    return nextDate;
  }

  setCountdownDate(testing = false) {
    let currentDay = this.forceDateToEST(new Date());
    let startDay = this.forceDateToEST(new Date('Jan 9 2024 10:00:00 GMT-0500 (Eastern Standard Time)'));
    let lastDay = this.forceDateToEST(new Date('May 1 2024 10:00:00 GMT-0500 (Eastern Standard Time)'));
    let hour = currentDay.getHours();
    let nextDay = new Date(currentDay.getTime() + 24 * 60 * 60 * 1000);

    if (currentDay < startDay) {
      this.comingSoon = true;
      return startDay;
    }
    
    if (testing === true) {
      nextDay = new Date(currentDay.getTime() + 15 * 1000); //set for 15s
      return nextDay;
    }else {
      // If date is before our start date, extend countdown to start date
      if (currentDay > lastDay) {
        // If the event has ended, cancel countdown clock and flip state of page
        this.eventEnded = true;
        nextDay = currentDay;
        return nextDay;
      }else {
        this.comingSoon = false;
      }

      // If it is past 11am on the current day, set countdown for tomorrow at 11am
      // If it is before 11am on the current day, set countdown for today at 11am
      if (!this.eventEnded) {
        if (hour >= 10) {
          if (currentDay.getDay() < 1 || currentDay.getDay() >= 5) {
            nextDay = this.getNextWeekday(currentDay, 1)

            // If nextDay is 12/25 or 1/1, add another day.
            if (
              nextDay.getMonth() + 1 === 12 && nextDay.getDate() === 25 || 
              nextDay.getMonth() + 1 === 1 && nextDay.getDate() === 1 ||
              nextDay.getMonth() + 1 === 1 && nextDay.getDate() === 15 || 
              nextDay.getMonth() + 1 === 2 && nextDay.getDate() === 2 ||
              nextDay.getMonth() + 1 === 2 && nextDay.getDate() === 5 || 
              nextDay.getMonth() + 1 === 3 && nextDay.getDate() === 4
            ) {
              nextDay = this.getNextWeekday(currentDay, 2)
            }
          }else {
            if (
              nextDay.getMonth() + 1 === 12 && nextDay.getDate() === 25 || 
              nextDay.getMonth() + 1 === 1 && nextDay.getDate() === 1 ||
              nextDay.getMonth() + 1 === 1 && nextDay.getDate() === 15 || 
              nextDay.getMonth() + 1 === 2 && nextDay.getDate() === 2 ||
              nextDay.getMonth() + 1 === 2 && nextDay.getDate() === 5 || 
              nextDay.getMonth() + 1 === 3 && nextDay.getDate() === 4 ||
              nextDay.getDay() < 1 || nextDay.getDay() > 5
            ) {
              nextDay = this.getNextWeekday(nextDay, 1)
            }
          }

          nextDay.setHours(10, 0, 0)
          return nextDay 
        }else {
          if (currentDay.getDay() < 1 || currentDay.getDay() > 5) {
            currentDay = this.getNextWeekday(currentDay, 1)

            // If nextDay is 12/25 or 1/1, add another day.
            if (
              currentDay.getMonth() + 1 === 12 && currentDay.getDate() === 25 || 
              currentDay.getMonth() + 1 === 1 && currentDay.getDate() === 1 ||
              currentDay.getMonth() + 1 === 1 && currentDay.getDate() === 15 || 
              currentDay.getMonth() + 1 === 2 && currentDay.getDate() === 2 ||
              currentDay.getMonth() + 1 === 2 && currentDay.getDate() === 5 || 
              currentDay.getMonth() + 1 === 3 && currentDay.getDate() === 4
            ) {
              currentDay = this.getNextWeekday(currentDay, 2)
            }
          }else {
            if (
              currentDay.getMonth() + 1 === 12 && currentDay.getDate() === 25 || 
              currentDay.getMonth() + 1 === 1 && currentDay.getDate() === 1 ||
              currentDay.getMonth() + 1 === 1 && currentDay.getDate() === 15 || 
              currentDay.getMonth() + 1 === 2 && currentDay.getDate() === 1 ||
              currentDay.getMonth() + 1 === 2 && currentDay.getDate() === 5 || 
              currentDay.getMonth() + 1 === 3 && currentDay.getDate() === 4
            ) {
              //currentDay = this.getNextWeekday(nextDay, 2)
              currentDay = nextDay
            }
          }

          currentDay.setHours(10, 0, 0)
          return currentDay
        }
      }
    }
  }

  forceDateToEST(date: Date) {
    // Create a new Date object representing the current date and time
    let currentDate = new Date(date);

    // Format the date in Eastern Standard Time (EST)
    let estOptions = { timeZone: 'America/New_York' };
    let estDate = currentDate.toLocaleString('en-US', estOptions);

    return new Date(estDate);
  }

  updateOffsetOnDateFromData(date) {
    const currentDate = this.forceDateToEST(new Date(date));
    currentDate.setHours(currentDate.getHours() + 8);

    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    
    return formattedDate;
  }

  formatDateString(date) {
    const currentDate = this.forceDateToEST(new Date(date));
    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  setMetaData(leaderboardType: string = 'default') {
    let description = 'Join the club & show off your 1/4 mile dominance with pride.'
    let image = environment.url + '/assets/images/1320/1320-logo-overlay.png';
    let url = environment.url + '/1320-club';
    if (leaderboardType == 'stock-leaderboard') {
      description = 'Check out the 1320 Club Stock Leaderboard. Can you compete?'
      image = environment.url + '/assets/images/1320/1320-logo-stock.png';
      url = environment.url + '/1320-club#stock-leaderboard';
    } else if (leaderboardType == 'modified-leaderboard') {
      description = 'Check out the 1320 Club Modified Leaderboard. Can you compete?'
      image = environment.url + '/assets/images/1320/1320-logo-modified.png';
      url = environment.url + '/1320-club#modified-leaderboard';
    }

    this.title.setTitle( '1320 Club | Dodge Garage' );
    this.metaService.updateTag({ name: 'description', content: description })
    this.metaService.updateTag({ property: 'og:description', content: description })
    this.metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    this.metaService.updateTag({ property: 'og:title', content: 'Dodge Garage' })
    this.metaService.updateTag({ property: 'og:type', content: '' })
    this.metaService.updateTag({ property: 'og:image', image })
    this.metaService.updateTag({ property: 'og:url', url })
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    this.metaService.updateTag({ name: 'twitter:image', image })
    this.metaService.updateTag({ name: 'twitter:image:width', content: ''})
    this.metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }
}
