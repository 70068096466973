import {Component, OnInit, ViewChild} from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute , Router} from '@angular/router';
import { DomSanitizer , Meta, Title} from '@angular/platform-browser';
// import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { HttpClient } from '@angular/common/http';
import { RKNContributor } from '../rkn-contributor';
import { RKN2022ContributorsService } from '../rkn-contributors.service';
import { RoadkillNightsVideoService } from '../../roadkill-nights-video/roadkill-nights-video.service';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import baguetteBox from 'baguettebox.js';
import {forkJoin} from 'rxjs';


//declare let addthis: any;
declare let ga: Function;

@Component({
  selector: 'rkn-2022-contributors',
  templateUrl: './rkn-2022-contributors.component.html',
  styles: [],
  providers: [
    RKN2022ContributorsService
  ]
})
export class RKN2022ContributorsComponent implements OnInit {
  slug: string;
  contributor: RKNContributor;
  loading: boolean = true;
  videos: any;
  activeVideo: any = '';
  modalActive = false;
  comingSoon = false;

  buildImages: any = [];
  galleryIds: any = [];
  allImages: boolean = false;

  @ViewChild(ModalDirective, { static: true }) public videoModal: ModalDirective;
  videoModalRef: BsModalRef;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private contributorsService: RKN2022ContributorsService,
    public sanitizer: DomSanitizer,
    public RoadkillNightsVideoService: RoadkillNightsVideoService,
    private _sanitizer: DomSanitizer,
  ) {
    // Get matching contributor and set metadata or re-route
    this.contributorsService.getContributors().subscribe(data => {
      this.route.params.subscribe( params => {
        this.slug = params.slug;
        this.contributor = data.find((contributor: RKNContributor) => contributor.slug === this.slug);


        if (this.contributor !== undefined) {
          this.getPlaylist(this.contributor.playlistID);
          if(this.contributor.folder !== null) {
            // Just get initial set of images
            this.getGalleryImages(this.contributor.folder, 0, 4);
          }


          // Set Title
          this.titleService.setTitle( this.contributor.name + ' – Direct Connection Grudge Match | Dodge Garage' );

          // Set Metadata
          this.metaService.updateTag({ name: 'description', content: this.contributor.metaDescription })
          this.metaService.updateTag({ property: 'og:description', content: this.contributor.metaDescription })
          this.metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
          this.metaService.updateTag({ property: 'og:title', content: this.contributor.name + ' – Direct Connection Grudge Match | Dodge Garage' })
          this.metaService.updateTag({ property: 'og:type', content: '' })
          this.metaService.updateTag({ property: 'og:image', content: environment.url + this.contributor.buildImagePath })
          this.metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
          this.metaService.updateTag({ name: 'twitter:card', content: 'summary' })
          this.metaService.updateTag({ name: 'twitter:image', content: environment.url + this.contributor.buildImagePath })
          this.metaService.updateTag({ name: 'twitter:image:width', content: ''})
          this.metaService.updateTag({ name: 'twitter:image:height', content: ''})
        } else {
          router.navigate(['**'], { skipLocationChange: true }).then();
        }
      });
    })

  }

  ngOnInit(): void {
  }

  /// Grab lil demon's playlist data from YT API and store on page
  getPlaylist(playlistID: string) {

    if (playlistID.length > 0 ) {
      this.RoadkillNightsVideoService.getVideosForPlaylist(playlistID).subscribe(
        res => {
          let observableList = [];
          let tempVideos = res['items'];

          if (tempVideos.length === 0) {
            this.loading = false;
            return;
          }

          for(let i = 0; i < tempVideos.length; i++) {
            observableList.push(this.RoadkillNightsVideoService.getVideoPrivacyStatus(tempVideos[i].snippet.resourceId.videoId));
          }

          //fork you, stupid observables
          forkJoin(observableList).subscribe((res: any[]) => {
            this.videos = tempVideos.filter((item, index) => {
              return res[index].items[0] !== undefined; // undefined = not public?
            })
            this.loading = false;
          });
        },
        err => {
          console.log('error', err);
          this.videos = [];
          this.loading = false;
        }
      )
    } else {
      this.videos = [];
      this.loading = false;
    }
  }

  // Get list of image ids based on folder from WP
  getGalleryImages(folder: number, start: number = 0, total: number = 5) {
    this.RoadkillNightsVideoService.getBuildGalleryImages(folder).subscribe(
      res => {
        // TODO Remove
        console.log(res);
        // Reverse IDs when setting to ensure latest is on top
        this.galleryIds = res['data']['attachment_ids'].reverse();
        let count = start;

        // Loop through ids from primary request and get the actual image URL
        this.galleryIds.slice(start, total).forEach(image => {
          this.RoadkillNightsVideoService.getBuildGalleryImage(image).subscribe(
            (res) => {
              this.buildImages.push(res);
              count++
            }
          )
        });


        setTimeout(() => {
          baguetteBox.run('.image-container');
        }, 2000);

      }
    );
  }

  showAllImages() {
    this.getGalleryImages(this.contributor.folder, this.contributor.start, this.galleryIds.length);
    this.allImages = true;

    ga('send', 'event', `RKN ${this.contributor.name} Build Gallery`, 'Click', 'View More');
  }

  openVideoModal(video) {
    this.activeVideo = video;
    //this.helperService.trackGAEvent(this.previousURL, 'Click', 'Video Modal - ' + this.selectedVideo.name);
    this.modalActive = true;
    this.videoModal.show();
  }

  closeVideoModal() {
    this.videoModal.hide();
    this.modalActive = false;
  }

  hasVideos() {
    return this.videos != undefined && this.videos.length > 0 && !this.loading;
  }

  hasImages() {
    return this.buildImages.length > 0 && !this.loading;
  }

  instagram() {
    return this.sanitizer.bypassSecurityTrustUrl(`https://www.instagram.com/${this.contributor.instagram}`);
  }

  youtube() {
    return this.sanitizer.bypassSecurityTrustUrl(`https://www.youtube.com/${this.contributor.youtube}`);
  }

  tiktok() {
    return this.sanitizer.bypassSecurityTrustUrl(`https://www.tiktok.com/${this.contributor.tiktok}`);
  }

  facebook() {
    return this.sanitizer.bypassSecurityTrustUrl(`https://www.facebook.com/${this.contributor.facebook}`);
  }

  clickTrackerSocial(domain) {
    ga('send', 'event', `RKN ${this.contributor.name} ${domain}`, 'Click', 'Social clicked');
  }
}
