<div [id]="id" class="leaderboard-container stock">
  <div class="leaderboard-header">
    <span class="title text-uppercase">{{ titleDate(selectedDateNew) }} <span class="font-bold text-primary">//</span> Drawing <br>{{ vehicle }} UNITS</span> 
    <div *ngIf="!comingSoon" class="calendar-select ml-auto">
      <button class="btn btn-primary" (click)="dp.toggle()"><i class="fa fa-calendar"></i></button>
      <input 
        [id]="'datepicker-' + vehicle"  
        class="date-picker form-control" 
        bsDatepicker 
        [daysDisabled]="[0,6]" 
        [datesDisabled]="disabledDates"
        [minDate]="startDate" 
        [maxDate]="endDate"
        [(ngModel)]="selectedDateNew" 
        (bsValueChange)="showValue()"
        #dp="bsDatepicker" 
        [bsConfig]="{ showWeekNumbers: false, selectFromOtherMonth: true }">
        <span class="helper-text text-center">Click for<br>other dates</span>
    </div>
  </div>
  <fieldset>
    <legend class="sr-only">{{ vehicle }} - Units Sold Leaderboard</legend>
    <div class="search-filter-mobile stock visible-xs visible-sm">
      <button class="btn btn-filter btn-block" type="button" 
        data-toggle="collapse" 
        [attr.data-target]="'#mobile-filters-' + id" 
        aria-expanded="false" 
        [attr.aria-controls]="'mobile-filters-' + id">
        Filters <i class="fa fa-chevron-down"></i>
      </button>
      <div class="collapse mobile-collapse" [id]="'mobile-filters-' + id">
        <div class="filter-container">
          <input [(ngModel)]="search1" (ngModelChange)="scrollToTop()" placeholder="Search Dealer Code" aria-label="search dealer codes">
          <input [(ngModel)]="search2" (ngModelChange)="scrollToTop()" placeholder="Search Dealer Name" aria-label="Search Dealer Name">
          <button class="btn btn-dark btn-block" (click)="resetSearchFilters()">Reset</button>
        </div>
        <!-- <div class="sorting-container">
          <div class="sorting-item">
            <span>Pos</span>
            <button class="btn btn-sorting" aria-label="sort acsending/descending" [ngClass]="{'sorting': !isDesc && column == 'id'}" (click)="sort('id')">
              <i class="fa fa-chevron-down"></i>
            </button>
          </div>
          <div class="sorting-item">
            <span>1/4 ET</span>
            <button class="btn btn-sorting" aria-label="sort acsending/descending" [ngClass]="{'sorting': !isDesc && column == 'elapsedTime'}" (click)="sort('elapsedTime')">
              <i class="fa fa-chevron-down"></i>
            </button>
          </div>
          <div class="sorting-item">
            <span>60 ft</span>
            <button class="btn btn-sorting" aria-label="sort acsending/descending" [ngClass]="{'sorting': !isDesc && column == 'sixtyFeetTime'}" (click)="sort('sixtyFeetTime')">
              <i class="fa fa-chevron-down"></i>
            </button>
          </div>
          <div class="sorting-item">
            <span>1/4 Speed</span>
            <button class="btn btn-sorting" aria-label="sort acsending/descending" [ngClass]="{'sorting': !isDesc && column == 'topSpeed'}" (click)="sort('topSpeed')">
              <i class="fa fa-chevron-down"></i>
            </button>
          </div>
        </div> -->
      </div>
    </div>

    <div class="leaderboard" (window:resize)="onResize($event)">
      <div class="lb-section head hidden-xs hidden-sm">
        <div class="lb-row">
          <div class="lb-cell bc">BC</div>
          <div class="lb-cell code">
            <label class="form-accessibility-label">Dealer Code
              <input class="form-control form-accessibility-input text-center" type="text" [(ngModel)]="search1" (ngModelChange)="scrollToTop()" placeholder="Dealer Code">
            </label>
          </div>
          <div class="lb-cell name">
            <label class="form-accessibility-label">Dealer Name
              <input class="form-control form-accessibility-input" type="text" [(ngModel)]="search2" (ngModelChange)="scrollToTop()" placeholder="Dealer Name">
            </label>
          </div>
          <div class="lb-cell units">Units Sold</div>
        </div>
      </div>
      <div class="lb-section body" [style.height.px]="boardHeight">
        <div *ngIf="!comingSoonReactive && !loading" class="lb-scroll" #scrollPosition [style.margin-top.px]="offsetY">
          <div class="lb-row" #rowPosition *ngFor='let dealer of dealers | tableFilter: { dealer_code:search1, dealer_name:search2, salesDateFormatted:activeDate }'>  
            <div class="lb-cell bc"><span>{{ dealer.bc }}</span></div>
            <div class="lb-cell code"><span>{{ dealer.dealer_code }}</span></div>
            <div class="lb-cell name"><span>{{ dealer.dealer_name }}</span></div>
            <div class="lb-cell units"><span>{{ dealer.units_sold }}</span></div>
          </div>
        </div>
        <div *ngIf="loading" class="loader"></div>
        <div *ngIf="comingSoonReactive && !loading" class="coming-soon">
          <img src="/assets/images/twenty-days/coming-soon.webp" alt="Coming Soon graphic with grunge text">
        </div>
      </div>

      <div *ngIf="showControls" class="lb-section controls">
        <button class="btn to-top" aria-label="scroll to top of leaderboard" [disabled]="scrollTop" (click)="scrollToTop()"><i class="fa fa-arrow-up"></i></button>
        <button class="btn btn-primary" [disabled]="scrollTop" (click)="scrollUp()">Scroll Up</button>
        <button class="btn btn-primary" [disabled]="scrollBottom" (click)="scrollDown()">Scroll Down</button>
        <button class="btn to-bottom" aria-label="scroll to bottom of leaderboard" [disabled]="scrollBottom" (click)="scrollToBottom()"><i class="fa fa-arrow-down"></i></button>
      </div>
    </div>
  </fieldset>
</div>
