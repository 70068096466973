import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from "../../user/user.service";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Post} from "../post";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {CommentService} from "../comment.service";
import {Topic} from "../topic";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

declare let ga: any;

@Component({
  selector: 'dg-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.less']
})
export class CommentFormComponent implements OnInit {

  @Input() topic: Topic = new Topic();
  @Input() parentPost: Post = new Post();
  @Input() postToEdit: Post = new Post();
  @Output() topicCreatedEmitter = new EventEmitter();
  @Output() newCommentEmitter = new EventEmitter();
  @Output() editedCommentEmitter = new EventEmitter();
  @Output() cancelEditEmitter = new EventEmitter();

  // User login status
  loggedInSub: Subscription;
  @ViewChild('userModal', { static: true }) public userModal: TemplateRef<any>;
  userModalRef: BsModalRef;

  commentForm: FormGroup;
  isLoading: boolean = false;

  commentFormAlert = {
    show: false,
    status: '',
    message: '',
    additionalMessage: ''
  };

  isEditingMode: boolean = false;
  placeholder: string = 'Type your comment here...';

  constructor(
    private modalService: BsModalService,
    public commentService: CommentService,
    public userService: UserService,
    private http: HttpClient
  ) { }


  ngOnInit() {
    this.loggedInSub = this.userService.loggedIn$.subscribe(loggedIn => {
      if (this.userService.userModel.isLoggedIn) {
        // Subscribe to when the user logs in
        //console.log(this.post);
      }
    });
    this.createCommentForm();
    if (this.postToEdit.id != 0) {
      this.isEditingMode = true;
      this.commentForm.controls.comment.setValue(this.postToEdit.cooked.replace(/<[^>]*>/g, ''));
    }
    if (this.parentPost.id != 0) {
      this.placeholder = 'Type your reply...';
    } else if (this.topic.polymorphicType == 'Time Slip') {
      this.placeholder = 'Comment on run...';
    }
    //console.log(this.topic);
  }


  createCommentForm() {
    this.commentForm = new FormGroup({
      'comment': new FormControl('', [
        Validators.required
      ]),
    });
  }



  //Brains of submitting a comment
  submitComment(event) {
    event.preventDefault();
    this.checkLogin();
    Object.keys(this.commentForm.controls).forEach(field => {
      const control = this.commentForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.commentForm.valid) { // If form is valid, proceed
      let commentString = this.commentForm.controls.comment.value;
      let username = this.userService.userModel.username;
      this.isLoading = true;

      if (this.topic.discourseTopicId) { // If topic exists, submit the new comment, otherwise create the new topic, wait for a response then submit
        if (this.postToEdit.id != 0) { // If editing a post

          this.commentService.updateComment(this.postToEdit, commentString, username).subscribe( commentData => {
            this.commentFormAlert.show = false;
            this.editedCommentEmitter.emit(commentData);
            this.commentForm.reset();
            this.isLoading = false;
          }, error => {
            this.commentFormAlert.show = true;
            this.commentFormAlert.status = 'danger';
            if (error.error.errors) {
              let errors = '';
              if (Array.isArray(error.error.errors)) {
                errors = error.error.errors.join(' ');
              } else {
                errors = error.error.errors;
              }
              errors = errors.replace('new users','users');
              this.commentFormAlert.message = errors;
            } else {
              this.commentFormAlert.message = 'There was an issue updating the comment. Please try again.';
            }
            this.isLoading = false;
          });

        } else {
          //Submit new post
          this.postComment(commentString, username);
        }
      } else {
        //If brand new topic needs to be created

        // Retrieve Category Information
        this.commentService.getCategories().subscribe( categoryData => {
          let categories = categoryData.category_list.categories;
          let category = categories.filter(category => category.name == this.topic.polymorphicType).shift();

          //If the category exists, push that information into creating the topic, otherwise create the category and topic
          if(category) {
            this.topic.discourseCategoryId = category.id;

            // console.log('------------------');
            // console.log(commentString);
            // console.log(username);
            // console.log(this.topic);

            //Create the new topic
            this.commentService.createNewTopic(this.topic).subscribe( data => {
              this.topic.discourseTopicId = data.topic_id;
              this.topicCreatedEmitter.emit(this.topic);

              //Does the user exist in discourse?
              this.commentService.getDiscourseUser(username).subscribe(next => {
                //Yes. Post the comment.
                //console.log('Data: '+next);
                this.postComment(commentString, username)
              }, error => {

                console.log(error);
                //If we get a 500 then the user does not exist and we need to create them in discourse
                if (error.status == 500 && this.userService.userModel.isLoggedIn) {
                  this.commentService.createNewUser(this.userService.userModel).subscribe(response => {
                    //Now we can post the comment.
                    this.postComment(commentString, username);
                  });
                }
              });
            });
          }
          else {

            this.commentService.createNewCategory(this.topic.polymorphicType).subscribe( newCategoryData => {

              this.topic.discourseCategoryId = newCategoryData.category.id;
              this.commentService.createNewTopic(this.topic).subscribe( data => {
                this.topic.discourseTopicId = data.topic_id;
                this.topicCreatedEmitter.emit(this.topic);

                //Does the user exist in discourse?
                this.commentService.getDiscourseUser(username).subscribe(user => {
                  //Yes. Post the comment.
                  this.postComment(commentString, username)
                }, error => {
                  //If we get a 404 then the user does not exist and we need to create them in discourse
                  if (error.status == 404 && this.userService.userModel.isLoggedIn) {
                    this.commentService.createNewUser(this.userService.userModel).subscribe(response => {
                      //Now we can post the comment.
                      this.postComment(commentString, username);
                    });
                  }
                });
              });
            });
          }
        });
      }
    }
  }

  postComment(commentString, username){
    this.commentService.postNewComment(commentString, username, this.topic.discourseTopicId, this.parentPost).subscribe(commentData => {
      ga('send', {
        hitType: 'event',
        eventCategory: this.topic.polymorphicType + ': ' + this.topic.title,
        eventAction: 'Comment',
        eventLabel: (this.parentPost.id == 0) ? 'Entering a Comment' : 'Responding to a Comment'
      });

      this.commentFormAlert.show = false;
      this.newCommentEmitter.emit(commentData);
      this.commentForm.reset();
      this.isLoading = false;
    }, error => {
      console.log('error from comment-form', error);
      
      this.commentFormAlert.show = true;
      this.commentFormAlert.status = 'danger';
      if (error.error.errors) {
        let errors = '';
        if (Array.isArray(error.error.errors)) {
          errors = error.error.errors.join(' ');
        } else {
          errors = error.error.errors;
        }
        errors = errors.replace('new users', 'users');
        this.commentFormAlert.message = errors;
      } else {
        this.commentFormAlert.message = 'There was an issue submitting the comment. Please try again.';
      }
      this.isLoading = false;
    });
  }

  checkLogin() {
    if (!this.userService.userModel.isLoggedIn) {
      this.openUserModal();
    }
  }

  openUserModal() {
    this.userModalRef = this.modalService.show(this.userModal, {class: 'modal-full'})
  }

  closeUserModal() {
    // Add any magic to fire when closing the modal
    if (this.userModalRef) {
      this.userModalRef.hide();
    }
  }
}
