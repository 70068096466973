
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse } from "@angular/common/http";
import {environment} from "../../environments/environment";
import {DatePipe} from "@angular/common";

import * as he from 'he/he';

declare let ga: Function;

@Injectable()
export class HelperService {
  currentDate: any;
  articlesLoaded: boolean = false;

  // Set status of livestream components
  streamLive: boolean = false; // for banners
  streamLiveHome: boolean = false; // for homepage embed component

  pipe = new DatePipe('en-US');

  ISO8601_DATE_REGEX =
    /^(\d{4})-?(\d\d)-?(\d\d)(?:T(\d\d)(?::?(\d\d)(?::?(\d\d)(?:\.(\d+))?)?)?(Z|([+-])(\d\d):?(\d\d))?)?$/;

  constructor(
    public httpClient: HttpClient
  ) {}

   public timeSince(date) {
     this.currentDate = new Date();

     date = new Date(date);
     let seconds = Math.floor((this.currentDate - date) / 1000);
     let interval = Math.floor(seconds / 31536000);

     if (interval >= 1) {
       if (interval === 1) {
         return interval + ' year ago';
       } else {
         return interval + ' years ago';
       }
     }

     interval = Math.floor(seconds / 2592000);
     if (interval >= 1) {
       if (interval === 1) {
         return interval + ' month ago';
       } else {
         return interval + ' months ago';
       }
     }

     interval = Math.floor(seconds / 86400);
     if (interval >= 1) {
       if (interval === 1) {
         return interval + ' day ago';
       } else {
         return interval + ' days ago';
       }
     }

     interval = Math.floor(seconds / 3600);
     if (interval >= 1) {
       if (interval === 1) {
         return interval + ' hour ago';
       } else {
         return interval + ' hours ago';
       }
     }

     interval = Math.floor(seconds / 60);
     if (interval >= 1) {
       if (interval === 1) {
         return interval + ' minute ago';
       } else {
         return interval + ' minutes ago';
       }

     }
     return Math.floor(seconds) + ' seconds ago';
   }

    timeSinceShort(value) {
      let d = new Date(value);
      let now = new Date();
      let seconds = Math.round(Math.abs((now.getTime() - d.getTime())/1000));
      
      let minutes = Math.round(Math.abs(seconds / 60));
      let hours = Math.round(Math.abs(minutes / 60));
      let days = Math.round(Math.abs(hours / 24));
      let months = Math.round(Math.abs(days/30.416));
      let years = Math.round(Math.abs(days/365));
      if (Number.isNaN(seconds)){
        return '';
      } else if (seconds <= 60) {
        return seconds + 's';
      } else if (minutes <= 60) {
        return minutes + 'm';
      } else if (hours <= 24) {
        return hours + 'h';
      } else if (days <= 45) {
        return days + 'd';
      } else if (days <= 545) {
        return months + 'mo';
      } else { // (days > 545)
        return years + 'y';
      }
    }

    timeSinceOrDate(value, format = 'M/d/yyy h:mm a') {
      // Returns time since up until 24 hours then returns the date and time
      let d = this.toDate(value);
      let now = new Date();
      let seconds = Math.round(Math.abs((now.getTime() - d.getTime())/1000));
      
      let minutes = Math.round(Math.abs(seconds / 60));
      let hours = Math.round(Math.abs(minutes / 60));
      let days = Math.round(Math.abs(hours / 24));
      let months = Math.round(Math.abs(days/30.416));
      let years = Math.round(Math.abs(days/365));
      if (Number.isNaN(seconds)){
        return '';
      } else if (seconds <= 60) {
        return seconds + ' second' + ((seconds != 1)?'s':'') + ' ago';
      } else if (minutes <= 60) {
        return minutes + ' minute' + ((minutes != 1)?'s':'') + ' ago';
      } else if (hours <= 24) {
        return hours + ' hour' + ((hours != 1)?'s':'') + ' ago';
      } else {
        return this.pipe.transform(d, format);
      }
    }

    public getFormattedDate(oldDate) {
      // Format datetime to MM/DD/YYYY
      // Set date as a js date object
      let date = new Date(oldDate);

      // Set Year from Date
      let year = date.getFullYear();

      // Set Month from Date
      let month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;

      // Set Day From Day
      let day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;

      // Return Pretty Date
      return month + '/' + day + '/' + year;
    }

    public cleanFloatParse(numberString) {
      return parseFloat(numberString);
    }

    public cleanLink(link) {
      link = link.replace(/^.*\/\/[^\/]+/, '').replace(/\/news(?:-api)?\//, '');
      return link;
    }

    public getThumbnail(image) {
        // //console.log(image);
        // if (image.indexOf('-credit') !== -1) {
        //    image = image.replace('-credit', '');
        // }

        return image;
        // .replace('-header', '-thumb');
    }

    public getThumbnailUrl(data) {
        if ( !data._embedded['wp:featuredmedia']) {
            return 'false';
        }else {
            return data._embedded['wp:featuredmedia'][0].source_url;
        }
    }

    public getCategorySlug(data) {
        return data._embedded['wp:term'][0][0].slug;
    }

    public getCategoryName(data) {
        return data._embedded['wp:term'][0][0].name;
    }

  public objectToFormData(obj) {
    let fd = new FormData();
    let formKey;

    for(let property in obj) {

      if(obj.hasOwnProperty(property)) {
        formKey = property;

        if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
          this.objectToFormData(obj[property]);
        } else {
          fd.append(formKey, obj[property]);
        }

      }

    }
    return fd;
  }

  public get(prop) {
      return this[prop];
  }

  public set(prop, value) {
      this[prop] = value;
  }

  /*private createAuthHeader(headers: Headers) {
      headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
  }*/

  public handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(errorMessage);
  }

  slugURL(text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }

  fixRBalls(text) {
    if (text.indexOf('HEMI®') > -1) {
      //text = text.replace('HEMI','HEMI<super>&reg;</super>');
    }
    return text.toString()
      .replace('Mopar®','Mopar<sub class="realign subscript">&reg;</sub>')
      .replace('Mopar<sub>®</sub>','Mopar<sub class="realign subscript">&reg;</sub>')
      .replace('Mopar<sub>&reg;</sub>','Mopar<sub class="realign subscript">&reg;</sub>')
      .replace('Jeep®','Jeep<sub class="realign subscript">&reg;</sub>')
      .replace('Jeep<sub>®</sub>','Jeep<sub class="realign subscript">&reg;</sub>')
      .replace('Jeep<sub>&reg;</sub>','Jeep<sub class="realign subscript">&reg;</sub>')
      .replace('SRT®','SRT<sup class="realign superscript">&reg;</sup>')
      .replace('SRT<sup>®</sup>','SRT<sup class="realign superscript">&reg;</sup>')
      .replace('SRT<sup>&reg;</sup>','SRT<sup class="realign superscript">&reg;</sup>')
      .replace('HEMI®','HEMI<sup class="realign superscript">&reg;</sup>')
      .replace('HEMI<sup>®</sup>','HEMI<sup class="realign superscript">&reg;</sup>')
      .replace('HEMI<sup>&reg;</sup>','HEMI<sup class="realign superscript">&reg;</sup>')
      .replace('FIAT®','FIAT<sup class="realign superscript">&reg;</sup>')
      .replace('FIAT<sup>®</sup>','FIAT<sup class="realign superscript">&reg;</sup>')
      .replace('FIAT<sup>&reg;</sup>','FIAT<sup class="realign superscript">&reg;</sup>')
      .replace('Uconnect®','Uconnect<sup class="realign superscript">&reg;</sup>')
      .replace('Uconnect<sup>®</sup>','Uconnect<sup class="realign superscript">&reg;</sup>')
      .replace('Uconnect<sup>&reg;</sup>','Uconnect<sup class="realign superscript">&reg;</sup>')
  }

  trackGAEvent(category, action, label) {
    ga('send', 'event', category, action, label);
  }

  hideBanner(banner, body) {
    banner.classList.add('rollup')
    body.removeClass( document.body, 'banner-active' )
    banner.addEventListener('animationend', () => banner.classList.add('hidden') )
  }

  htmlDecode (input) {
    /*var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;*/
    let stripedHtml = input.replace(/<[^>]+>/g, '');
    let decodedStripedHtml = he.decode(stripedHtml).trim();
    return decodedStripedHtml;
  }

  getParameterByName(name) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  dhms(t) {
    let days, hours, minutes, seconds;
    days = Math.floor(t / 86400);
    t -= days * 86400;
    hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;
    minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    seconds = t % 60;

    if (days != 0) {
      return [
        this.pad(days, 2),
        this.pad(hours, 2),
        this.pad(minutes, 2),
        this.pad(seconds, 2)
      ].join(':');
    } else if (hours != 0) {
      return [
        //this.pad(days, 2),
        this.pad(hours, 2),
        this.pad(minutes, 2),
        this.pad(seconds, 2)
      ].join(':');
    } else {
      return [
        //this.pad(days, 2),
        //this.pad(hours, 2),
        this.pad(minutes, 2),
        this.pad(seconds, 2)
      ].join(':');
    }
  }

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  generateColorHexCode() {
    let letters = '0123456789ABCDEF';
    let color = '';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getStates():Observable<any> {
    return this.httpClient.get(environment.url + '/assets/json/states.json');
  }

  getCountries():Observable<any> {
    return this.httpClient.get('/assets/json/countries.json');
  }

  getNonUSCountries():Observable<any> {
    return this.httpClient.get('/assets/json/non-us-countries.json');
  }

  /**
   * Converts a value to date.
   *
   * Supported input formats:
   * - `Date`
   * - number: timestamp
   * - string: numeric (e.g. "1234"), ISO and date strings in a format supported by
   *   [Date.parse()](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse).
   *   Note: ISO strings without time return a date without timeoffset.
   *
   * Throws if unable to convert to a date.
   */
  toDate(value: string|number|Date): Date {
    if (this.isDate(value)) {
      return value;
    }

    if (typeof value === 'number' && !isNaN(value)) {
      return new Date(value);
    }

    if (typeof value === 'string') {
      value = value.trim();

      const parsedNb = parseFloat(value);

      // any string that only contains numbers, like "1234" but not like "1234hello"
      if (!isNaN(value as any - parsedNb)) {
        return new Date(parsedNb);
      }

      if (/^(\d{4}-\d{1,2}-\d{1,2})$/.test(value)) {
        /* For ISO Strings without time the day, month and year must be extracted from the ISO String
        before Date creation to avoid time offset and errors in the new Date.
        If we only replace '-' with ',' in the ISO String ("2015,01,01"), and try to create a new
        date, some browsers (e.g. IE 9) will throw an invalid Date error.
        If we leave the '-' ("2015-01-01") and try to create a new Date("2015-01-01") the timeoffset
        is applied.
        Note: ISO months are 0 for January, 1 for February, ... */
        const [y, m, d] = value.split('-').map((val: string) => +val);
        return new Date(y, m - 1, d);
      }

      let match: RegExpMatchArray|null;
      if (match = value.match(this.ISO8601_DATE_REGEX)) {
        return this.isoStringToDate(match);
      }
    }

    const date = new Date(value as any);
    if (!this.isDate(date)) {
      throw new Error(`Unable to convert "${value}" into a date`);
    }
    return date;
  }

  /**
   * Converts a date in ISO8601 to a Date.
   * Used instead of `Date.parse` because of browser discrepancies.
   */
  isoStringToDate(match: RegExpMatchArray): Date {
    const date = new Date(0);
    let tzHour = 0;
    let tzMin = 0;

    // match[8] means that the string contains "Z" (UTC) or a timezone like "+01:00" or "+0100"
    const dateSetter = match[8] ? date.setUTCFullYear : date.setFullYear;
    const timeSetter = match[8] ? date.setUTCHours : date.setHours;

    // if there is a timezone defined like "+01:00" or "+0100"
    if (match[9]) {
      tzHour = Number(match[9] + match[10]);
      tzMin = Number(match[9] + match[11]);
    }
    dateSetter.call(date, Number(match[1]), Number(match[2]) - 1, Number(match[3]));
    const h = Number(match[4] || 0) - tzHour;
    const m = Number(match[5] || 0) - tzMin;
    const s = Number(match[6] || 0);
    const ms = Math.round(parseFloat('0.' + (match[7] || 0)) * 1000);
    timeSetter.call(date, h, m, s, ms);
    return date;
  }

  isDate(value: any): value is Date {
    return value instanceof Date && !isNaN(value.valueOf());
  }
}
