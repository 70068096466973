import {Component, OnInit, OnDestroy} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'dg-event-single-page-test',
  templateUrl: './event-single-page-test.component.html',
  styleUrls: ['./event-single-page-test.component.less']
})
export class EventSinglePageComponentTest implements OnInit, OnDestroy {
  private routeSub: Subscription; // Subscription to route parameters
  paramId: string; // Store a specific URL parameter
  paramName: string; // Store a specific URL parameter

  constructor(private route: ActivatedRoute) {
    this.paramId = this.route.snapshot.params['id'];
    this.paramName = this.route.snapshot.params['name'];
  }

  ngOnInit(): void {
    // Subscribe to route params
    this.routeSub = this.route.params.subscribe(params => {
      console.log('Current ID:', this.paramId);
      console.log('Current Name:', this.paramName);
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}
