<div class="site-wrapper">
  <header class="page-header">
    <div class="container-fluid">
      <div class="row-flex">
        <div class="col-flex-sm-8 col-flex-lg-9">
          <h1>Track Locator</h1>
          <p>Find a track, rally friends, cheer and compete.</p>
        </div>
        <div class="col-flex-sm-4 col-flex-lg-3">
          <a class="btn btn-block btn-outline-primary" href="http://www.life.dodge.com/" target="_blank">Get Geared Up</a>
        </div>
      </div>
    </div>
  </header>
  <div class="container-fluid">

    <div class="track-search-container">
      <p>Search by</p>
      <form class="form-inline" [formGroup]="searchForm" (submit)="submitSearch($event)">
        <div class="form-group">
          <label class="sr-only" for="track-search-type">Type</label>
          <select class="form-control" formControlName="searchType" id="track-search-type" #searchType (change)="updateSearchType(searchType.value)">
            <option value="Zipcode">Zip Code</option>
            <option value="Track Name">Track Name</option>
            <option vlaue="City/State">City/State</option>
          </select>
        </div>
        <div class="form-group" *ngIf="searchType.value == 'Zipcode'" [ngClass]="{ 'has-error': searchForm.controls.zipcode.invalid && (searchForm.controls.zipcode.dirty || searchForm.controls.zipcode.touched) }">
          <label class="sr-only" for="track-search-zipcode">Zip Code</label>
          <input type="text" formControlName="zipcode" class="form-control" id="track-search-zipcode" placeholder="Enter zip code">
        </div>
        <div class="form-group" *ngIf="searchType.value == 'Track Name'" [ngClass]="{ 'has-error': searchForm.controls.trackName.invalid && (searchForm.controls.trackName.dirty || searchForm.controls.trackName.touched) }">
          <label class="sr-only" for="track-search-track-name">Track Name</label>
          <input type="text" formControlName="trackName" class="form-control" id="track-search-track-name" placeholder="Enter track name">
        </div>
        <div class="form-group" *ngIf="searchType.value == 'City/State'" [ngClass]="{ 'has-error': searchForm.controls.city.invalid && (searchForm.controls.city.dirty || searchForm.controls.city.touched) }">
          <label class="sr-only" for="track-search-city">City</label>
          <input type="text" formControlName="city" class="form-control" id="track-search-city" placeholder="Enter city">
        </div>
        <div class="form-group" *ngIf="searchType.value == 'City/State'" [ngClass]="{ 'has-error': searchForm.controls.state.invalid && (searchForm.controls.state.dirty || searchForm.controls.state.touched) }">
          <label class="sr-only" for="track-search-state">State</label>
          <select class="form-control" formControlName="state" id="track-search-state">
            <option value="" selected="selected">Select State</option>
            <option *ngFor="let state of states" [value]="state.abbreviation">{{state.abbreviation}}</option>
          </select>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="isLoading">
          Find Tracks
          <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
        </button>
      </form>
      <div *ngIf="searchForm.invalid && (searchForm.dirty || searchForm.touched)" class="form-group has-error">
        <div class="help-block" *ngIf="searchForm.controls.zipcode.invalid && searchForm.controls.zipcode.errors.required">Please enter a zip code.</div>
        <div class="help-block" *ngIf="searchForm.controls.trackName.invalid && searchForm.controls.trackName.errors.required">Please enter a track name.</div>
        <div class="help-block" *ngIf="searchForm.controls.city.invalid && (searchForm.controls.city.errors.required || searchForm.controls.state.errors.required)">Please enter a city and state.</div>
      </div>
    </div>
    
    <div *ngIf="isSearched && tracks.length > 0">
      <h2 class="heading2">{{tracks.length}} Tracks Found</h2>
    </div>

    <div *ngIf="isSearched && tracks.length == 0">
      <p>Zero race tracks have been found based on your query.</p>
    </div>

    <div *ngIf="!isSearched">
      <p>Please search to find race tracks.</p>
    </div>

    <div #map id="map">
      <div *ngIf="isMapLoading" class="loading-icon">
        <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div *ngIf="isSearched && tracks.length > 0">
      <div class="track-results-container">
        <div class="track-container" *ngFor="let track of tracks">
          <div class="track">
            <div class="title-row">
              <h3 class="heading3">{{track.name}}</h3>
              <div>{{(track.distance | number:'1.0-0')?(track.distance | number:'1.0-0') + ' miles':''}}</div>
            </div>
            <div class="track-info">
              <div>
                <address>
                  {{track.address}}<br>
                  {{track.city}}, {{track.state}} {{track.zipcode}}
                </address>
                <div><a class="get-driections-link" href="https://maps.google.com/maps?daddr={{track.address}}+{{track.city}}+{{track.state}}+{{track.zipcode}}" target="_blank">Get Directions</a></div>
              </div>
              <div><a *ngIf="track.website" class="btn btn-sm btn-outline-primary" [href]="track.website" target="_blank">Visit Website</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</div>

