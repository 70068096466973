<div id="videos-section" class="relative bg-white">
  <div class="container-fluid">
    <div class="col-sm-12 slider-header">
      <h2 class="heading">Featured Videos</h2>
      <a class="btn btn-primary btn-solid" routerLink="/videos" data-ga_category="Home" data-ga_action="Click" data-ga_label="All Videos">View All</a>
    </div>
    <div class="col-sm-12">
      <tabset class="dg-tabs stack-xs">
        <ng-container *ngFor="let album of videoAlbums">
          <tab videosTab [id]="album.name | kebab" *ngIf="album.uri != ''" [listInfo]="album" (selectedVideo)="openVideoModal($event)"></tab>
        </ng-container>
      </tabset>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #videoModal tabindex="-1" role="dialog"
  (onHidden)="videoModalListener('onHidden', $event)">
  <div class="modal-dialog modal-lg modal-video">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="video-modal-heading" class="modal-title heading1 pull-left">{{activeVideo.name}}</h2>
        <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
      </div>
      <div class="modal-body">
        <p class="description" *ngIf="activeVideo.description && activeVideo.description != ''">{{activeVideo.description}}</p>
        <div class="embed-responsive embed-responsive-16by9 mb-4">
          <div class="embed-responsive-item" *ngIf="activeVideo.embedCode != ''" [innerHTML]="activeVideo.embedCode | safe: 'html'"></div>
        </div>
      </div>
    </div>
  </div>
</div>
