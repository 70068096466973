import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { RKN2022ContributorsService } from '../rkn-contributors.service';
import { RKNContributor } from '../rkn-contributor';
import {AddThisService} from '../../services/addthis.service';
declare let ga: Function;
declare let addthis: any;

@Component({
  selector: 'rkn-2022-landing-page',
  templateUrl: './rkn-2022-landing-page.component.html',
  styles: [],
  providers: [
    RKN2022ContributorsService
  ]
})


export class RKN2022LandingPageComponent implements OnInit {
  builders: RKNContributor[] = [];
  videos: any;
  activeVideo: string = '';
  modalActive = false;

  @ViewChild(ModalDirective, { static: true }) public videoModal: ModalDirective;
  videoModalRef: BsModalRef;
  
  constructor(
    public title: Title,
    public metaService: Meta,
    public sanitizer: DomSanitizer,
    private router: Router,
    private contributorsService: RKN2022ContributorsService,
    private addThisService: AddThisService
  ) {
    this.contributorsService.getContributors().subscribe(data => {
      this.builders = this.shuffleArray(data);
    });

    title.setTitle( 'Roadkill Nights Powered by Dodge Direct Connection Grudge Match | Dodge Garage' );
    metaService.updateTag({ name: 'description', content: 'Roadkill Nights Dodge Direct Connection Grudge Match with Alex Taylor, Christina Roki, Corruptt Builds, David Patterson, Demonology, Throtl, Collete Davis, Tavarish and Westen Champlin.' })
    metaService.updateTag({ property: 'og:description', content: 'Roadkill Nights Dodge Direct Connection Grudge Match with Alex Taylor, Christina Roki, Corruptt Builds, David Patterson, Demonology, Throtl, Collete Davis, Tavarish and Westen Champlin.' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Roadkill Nights Powered by Dodge Direct Connection Grudge Match | Dodge Garage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: environment.url + '/assets/images/rkn/roadkill-header.jpg' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: environment.url + '/assets/images/rkn/roadkill-header.jpg' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }
  

  ngOnInit() {
    // Add AddThis to Page
    const script = document.createElement('script');
    script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
    document.body.appendChild(script);

    // Reload AddThis
    setTimeout(() => {
      addthis.layers.refresh();
      this.addThisService.setAddThisVisibility(true);
    }, 1500);
  }

  openVideoModal(videoID: string) { 
    this.activeVideo = videoID;
    this.modalActive = true;
    this.videoModal.show();
  }

  closeVideoModal() {
    this.activeVideo = '';
    this.modalActive = false;
    this.videoModal.hide();
  }

  //clickTrackerVideo(video: string) {ga('send', 'event', 'RKN LP Video', 'Click', 'Video play - ' + video);}

  clickTrackerContributor(contributor) {
    ga('send', 'event', `RKN ${contributor} HP`, 'Click', 'Image clicked');
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

}
