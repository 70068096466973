import { Component, OnInit, OnDestroy, Renderer2, PLATFORM_ID, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from "@angular/common";
import { Router } from '@angular/router';

@Component({
  selector: 'eu-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class EuComingSoonComponent implements OnInit, OnDestroy {

  constructor(
    public title: Title,
    public metaService: Meta,
    public sanitizer: DomSanitizer,
    private router: Router,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    title.setTitle( 'DodgeGarage EU' );
    metaService.updateTag({ name: 'description', content: 'DodgeGarage EU Coming Soon' })
    metaService.updateTag({ property: 'og:description', content: 'DodgeGarage EU Coming Soon' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'DodgeGarage EU' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: environment.url + '/assets/images/challenger-drop-top/challenger-drop-top-banner.jpg' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: environment.url + '/assets/images/challenger-drop-top/challenger-drop-top-banner.jpg' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit(): void {
    // if (isPlatformBrowser(this.platformId)) {
    //   this.renderer.addClass(document.body, 'dg-eu');
    // }
  }

  ngOnDestroy(): void {
    // if (isPlatformBrowser(this.platformId)) {
    //   this.renderer.removeClass(document.body, 'dg-eu');
    // }
  }

}
