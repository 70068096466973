<div class="product-card">
  <swiper data-id="demon-product-carousel" #swiper 
    [config]="config" 
    (slideChange)="slideChange($event)">
    <ng-container *ngFor="let image of product['images']; index as i;">
      <ng-template swiperSlide>
        <img [src]="image" [alt]="product['description']"/>
      </ng-template>
    </ng-container>
  </swiper>
  <div class="indicators" *ngIf="showControls">
    <div *ngFor="let dot of product['images']; index as i" 
      class="dot" 
      [ngClass]="{'active': activeIndex === i}" 
      (click)="slideTo(i)"></div>
  </div>
  <button *ngIf="showControls" (click)="slidePrev()" class="carousel-button carousel-prev"><i class="fa fa-angle-left"></i></button>
  <button *ngIf="showControls" (click)="slideNext()" class="carousel-button carousel-next"><i class="fa fa-angle-right"></i></button>
  <div class="card-content">
    <p class="product-name">{{ product['name'] }}</p>
  </div>
</div>