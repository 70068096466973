<div class="media-tile ringtone downloads" *ngFor="let ringtone of ringtones">
  <div class="preview">
    <div class="trigger">
      <button class="btn-preview audio ga-event" (click)="previewAudio($event)" data-ga_category="Ringtones" data-ga_action="Preview Play" [attr.data-ga_label]=" ringtone.name" aria-label="click to preview audio"></button>
    </div>
    <div class="content">
      <p class="title text-uppercase">{{ ringtone.model }}</p>
      <p class="title text-uppercase">{{ ringtone.name }}</p>
    </div>
  </div>
  <div class="dropup">
    <button class="btn btn-block btn-primary download" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Download
    </button>
    <ul class="dropdown-menu">
      <li style="background-color: white;">
        <a class="ga-event" data-ga_category="Ringtones" [attr.data-ga_action]="ringtone.name" [attr.data-ga_label]="ringtone.files.mp3" href="{{ ringtone.files.mp3 }}" target="_blank" rel="noopener">mp3 (Android)</a>
      </li>
      <li *ngIf="ringtone.files.m4r" style="background-color: white;">
        <a class="ga-event" href="{{ ringtone.files.m4r }}" data-ga_category="Ringtones" [attr.data-ga_action]="ringtone.name" [attr.data-ga_label]="ringtone.files.m4r" target="_blank" rel="noopener">m4r (Apple)</a>
      </li>
    </ul>
  </div>
  <audio class="audio-preview">
    <source src="{{ ringtone.files.ogg }}" type="audio/ogg">
    <source src="{{ ringtone.files.mp3 }}" type="audio/mpeg">
    Your browser does not support the audio element.
  </audio>
</div>
