import {Component, Input, OnInit} from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { ChallengerService } from '../challenger.service';

@Component({
  selector: 'challenger-list-page',
  templateUrl: './challenger-list-page.component.html',
  styleUrls: ['./challenger-list-page.component.less']
})
export class ChallengerListPageComponent implements OnInit {
  nativeElement: any;
  public showCustomerInfo:boolean = false;

  @Input() selectedColor:any;
  @Input() selectedTrim:any;
  @Input() selectedExteriors:any;

  @Input() colors: {}[] = [];
  @Input() trims: {}[] = [];
  @Input() exteriors: {}[] = [];

  constructor(
    public helperService: HelperService,
    public challengerService: ChallengerService,
  ) {
    this.getOptions()
  }

  ngOnInit() {
  }

  private getOptions(selections = {}) {
    this.challengerService.getOptions(selections).subscribe( data => {
      this.colors = data.matrix.colors;
      this.trims = data.matrix.trims;
      this.exteriors = data.matrix.exteriors;
    })
  }

  selectType($event: MouseEvent) {
    if ((<HTMLInputElement>event.srcElement).innerText == "STOCK"){
      this.showCustomerInfo = false;
    } else {
      this.showCustomerInfo = true;
    }
  }

  selectTrim($event: MouseEvent, trimId){
    this.selectedTrim = trimId;
    this.refreshOptions()
  }

  selectColor($event: MouseEvent, colorId){
    this.selectedColor = colorId
    this.refreshOptions()
  }

  selectExterior($event: MouseEvent, exteriorId){
    if ((<HTMLInputElement>event.srcElement).checked) {
      if (this.selectedExteriors == undefined) {
        this.selectedExteriors = [exteriorId]
      } else {
        this.selectedExteriors.push(exteriorId)
      }
    } else {
      let index = this.selectedExteriors.indexOf(exteriorId);

      if (index > -1) {
        this.selectedExteriors.splice(index, 1);
      }
    }

    this.refreshOptions()
  }

  refreshOptions(){
    this.challengerService.getOptions({
      selections: {
        color: this.selectedColor,
        trim: this.selectedTrim,
        exteriors: this.selectedExteriors
      }
    }).subscribe( () => {
    });
  }
}
