import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { GtmService } from '../../../services/gtm-tracking.service';

@Component({
  selector: 'badassador-homepage-banner',
  templateUrl: './homepage-banner.component.html',
  styleUrls: ['./homepage-banner.component.scss']
})
export class BadassadorHomepageBannerComponent implements OnInit {

  constructor(
    private gtmService: GtmService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {}
}
