import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AddThisService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  public setAddThisVisibility(visibility: Boolean) {
    if (isPlatformBrowser(this.platformId)) {
      // let's set the visibility of any and all elements matching the addThis id. Sometimes there's two.
      for(let i = 0; i < 2; i++) {
        let optionalCharacter = i === 1 ? '2' : '';
        let addThisElement = document.getElementById('at-expanding-share-button' + optionalCharacter);
        if(addThisElement) {
          if (addThisElement.classList.contains('hidden') && visibility === true)
            addThisElement.classList.remove('hidden');
          if (!addThisElement.classList.contains('hidden') && visibility === false)
            addThisElement.classList.add('hidden');
        }
      }
    }
  }
}
