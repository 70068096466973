import {Component, ViewChild, Input, AfterViewInit, HostListener, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {SwiperComponent} from "swiper/angular";
import SwiperCore, { SwiperOptions, Autoplay } from 'swiper';
import baguetteBox from 'baguettebox.js';
declare let ga: Function;

SwiperCore.use([Autoplay]);

@Component({
  selector: 'interior-gallery',
  templateUrl: './interior-gallery.component.html'
})
export class InteriorGalleryComponent implements AfterViewInit {

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('packageModal', { static: true }) public packageModal: TemplateRef<any>;
  @ViewChild('optionsModal', { static: true }) public optionsModal: TemplateRef<any>;
  @Input() slides: any = [];
  @Input() showControls: boolean = false;
  packageModalRef: BsModalRef;
  optionsModalRef: BsModalRef;
  gallery: any;
  indicators: Array<any> = Array.from(Array(4).keys());
  activeImage: any = 0; //default to first in list
  activePackage: Array<any>;
  activeTitle: any;
  activeIndex: any;

  config: SwiperOptions = {
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.indicators',
      clickable: true
    }
  };

  constructor(
    private modalService: BsModalService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.gallery = baguetteBox.run('.interior-image-gallery');
    }, 2000);
  }

  // Slider functionality
  showSlide(index) {
    ga('send', 'event', 'Demon_170-Landing', 'Click', 'View Interior Slide #' + index);
    this.activeImage = index;
    if (window.innerWidth < 992) {
      baguetteBox.show(index, this.gallery[0]);
    }
  }

  showGalleryFromFeature() {
    baguetteBox.show(this.activeImage, this.gallery[0]);
  }

  slidePrev() { this.swiper?.swiperRef.slidePrev(); }
  slideNext() { this.swiper?.swiperRef.slideNext(); }
  // slideTo(index) { 
  //   this.swiper?.swiperRef.slideTo(index);
  //   console.log('yawn', index);
  // }

  // slideChange(event) { 
  //   this.activeIndex = event.activeIndex 
  //   console.log(this.activeIndex);
  //   console.log(event);
  // }

  @HostListener('window:resize', ['$event'])
  onResize() {
    window.innerWidth > 767 ? this.swiper?.swiperRef.disable() : this.swiper?.swiperRef.enable();
    window.innerWidth > 767 ? this.swiper?.swiperRef.init() : null;
  }


  // Modal Functionality
  openModal(packages, title) {
    this.activePackage = packages;
    this.activeTitle = title;
    this.packageModalRef = this.modalService.show(this.packageModal, { class: 'package-modal'});
    ga('send', 'event', 'Demon_170-Landing', 'Click', 'Package Modal Open - ' + this.activeTitle);
  }
  openOptionsModal() {
    this.activePackage = ['Rear Seat Delete', 'Rear Seatbelt Delete', 'Cargo Net', 'Front Floormats'];
    this.activeTitle = 'Rear Seat Delete Group';
    this.optionsModalRef = this.modalService.show(this.optionsModal, { class: 'package-modal'});
    ga('send', 'event', 'Demon_170-Landing', 'Click', 'Options Modal Open - ' + this.activeTitle);
  }

  closeModal() {
    this.packageModalRef.hide();
  }
  closeOptionsModal() {
    this.optionsModalRef.hide();
  }

}
