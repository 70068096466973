<div id="demon-170">
  <hero-video
    [headline]="heroVideo['headline']"
    [videoMobile]="heroVideo['videoMobile']"
    [videoDesktop]="heroVideo['videoDesktop']"></hero-video>
  <section id="intro" class="section-spacing" aria-label="Page Intro">
    <div class="site-wrapper skinny-site-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="section-lead">
              <span class="accent">1,025 HP.</span>
              <span class="accent">ZERO BS.</span>
            </h2>
            <div class="column-container">
              <p>
                Make no mistake. The limited-production 2023 Dodge Challenger SRT<sup>&reg;</sup> Demon 170, featuring the first-ever 1,000-plus-horsepower production HEMI<sup>&reg;</sup> engine, is absolutely here to kick ass and cross names off the competitor lane list. The seventh and final Last Call Special Edition is already banned in its stock form by the NHRA thanks to dominating the quarter-mile with a certified 8.91-seconds ET at 151.17 mph.
              </p>
              <p>
                With only a red key in hand, horsepower levels are determined by the percentage of ethanol fuel detected. A recalibrated powertrain control module optimizes fueling and spark timing for both premium and high-ethanol-blended fuel to unlock the full horsepower potential.
              </p>
              <p>
                What does it take to achieve a 40% boost increase<sup>1</sup>, go 0-60 mph in 1.66 seconds and generate the highest g-force acceleration of any production car<sup>2</sup> at 2.004 Gs? Next-level engineering, a hell of a lot of determination, testing and passion to deliver the craziest performance numbers ever for the Brotherhood of Muscle. <a [attr.href]="'/news/article/'+newsArticleData.cleanLink" (click)="goToArticle(newsArticleData, $event)">CHECK OUT all of the extensive upgrades, including an improved, modified 3.0L supercharger, new driveline components, TransBrake 2.0, unique drag mode suspension and a staggered widebody design.</a>
              </p>
              <p>
                Production is limited to 3,300 at most based on production capacity, with 300 destined for Canada. Ordering opens Monday, March 27 at a U.S. MSRP<sup>3</sup> of $96,666 and Canadian MSRP<sup>3.1</sup> of $134,785. With options to choose from including 14 exterior colors, 2 Satin Black paint options, 4 interiors and optional carbon fiber wheels, there's guaranteed to be a configuration that fuels your fire.
              </p>
              <p>
                Are you a <span class="font-bold text-bold">current</span> 2018 Challenger SRT Demon owner? When you place your order for the new 2023 SRT Demon 170 from a dealer with allocation, you'll have the unique opportunity to request to match your original SRT Demon VIN.
              </p>
              <p>
                <a href="/demon-170-owner-info">Because more is more, the Challenger SRT Demon 170 comes with customized, limited-edition and serialized items available after you take delivery. From the included personalized, commemorative Demon decanter set and a custom instrument panel badge, to an appearance-mimicking Goodwool breathable mesh car shell available for purchase.</a> Ready to make history with us?
              </p>
              <p class="mb-5">Thought so.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="well">
              <h3 class="section-lead text-uppercase">HOLY $#!& LEVEL OF PERFORMANCE</h3>
              <ul class="list-stats">
                <li>
                  <span class="icon">
                    <img src="/assets/images/demon-170/icon-explosion.svg" alt="Explosion Icon" />
                  </span>
                  <span class="stat">
                    <span class="lg">1,025</span>
                    <span>total horsepower at 6,500 rpm</span>
                  </span>
                </li>
                <li>
                  <span class="icon">
                    <img src="/assets/images/demon-170/icon-speedometer.svg" alt="Speedometer Icon" />
                  </span>
                  <span class="stat">
                    <span class="lg">945 lb.-ft</span>
                    <span>of torque at 4,200 rpm</span>
                  </span>
                </li>
                <li>
                  <span class="icon">
                    <img src="/assets/images/demon-170/icon-stopwatch.svg" alt="Stopwatch Icon" />
                  </span>
                  <span class="stat">
                    <span>The world's quickest 0-60 production car<sup>4</sup> at</span>
                    <span class="lg">1.66 seconds</span>
                  </span>
                </li>
                <li>
                  <span class="icon">
                    <img src="/assets/images/demon-170/icon-tire.svg" alt="Tire Icon" />
                  </span>
                  <span class="stat">
                    <span>The highest g-force acceleration of any production car<sup>5</sup> at</span>
                    <span class="lg">2.004 Gs</span>
                  </span>
                </li>
                <li>
                  <span class="icon">
                    <img src="/assets/images/demon-170/icon-flag.svg" alt="Flag Icon" />
                  </span>
                  <span class="stat">
                    <span>History's first-ever eight-second factory muscle car<sup>6</sup></span>
                    <span class="lg">8.91-seconds ET at 151.17 mph</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-spacing" aria-label="Image Gallery Carousel">
    <feature-image-slider [slides]="imageArray" [showControls]="true"></feature-image-slider>
    <p class="text-center caption mb-0">Click image to view full size.</p>
  </section>
  <section class="section-spacing" aria-label="Features">
    <div class="site-wrapper skinny-site-wrapper mb-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="h1 text-center text-uppercase">
              Design your SRT<sup>&reg;</sup> Demon 170
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="standard-stats bg-gray-200">
      <div class="site-wrapper skinny-site-wrapper mb-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="features">
                <img src="/assets/images/demon-170/demon-badge.svg" alt="Demon 170 - Logo">
                <div class="well well-inner bg-white">
                  <h3 class="heading-sm">
                    Along with the <span class="text-underline cursor-pointer" (click)="openModal()">standard SRT<sup>&reg;</sup>&nbsp;features</span> you've come to expect and respect, the 2023 Dodge Challenger SRT Demon 170 sets itself apart and comes with:
                  </h3>
                  <ul class="list-features">
                    <li *ngFor="let item of standards; let i = index" class="text-uppercase" [innerHTML]="item" [ngClass]="{'break': i === standards.length / 2}"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-spacing" aria-label="Exterior Options">
    <div class="site-wrapper skinny-site-wrapper mb-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="section-lead text-uppercase text-center">Exterior Colors</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="color-changer">
              <div class="img-container">
                <img [src]="'/assets/images/demon-170/jellybeans/' + activeColor + '_BODY.webp'" alt="Vehicle - Base Body" width="600" height="338">
              </div>
              <color-swatches [colors]="colorSwatches" (emitActiveColorCode)="updateJellybeans($event)"></color-swatches>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="graphics-section" class="section-spacing" aria-label="Exterior Graphic Options">
    <div class="site-wrapper skinny-site-wrapper mb-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="section-lead text-uppercase text-center">Graphics</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="promo-group">
              <div class="img-container slide-left">
                <img class="img-responsive" [src]="'/assets/images/demon-170/jellybeans/' + activeColor + '_BODY_HOOD.webp'" alt="Vehicle - Satin Black Hood"  width="600" height="338">
              </div>
              <span class="text-uppercase name">Satin Black painted hood</span>
              <strong class="price">$1,995 ($2,595 CAD)</strong>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="promo-group">
              <div class="img-container slide-left">
                <img class="img-responsive" [src]="'/assets/images/demon-170/jellybeans/' + activeColor + '_DARK.webp'" alt="Vehicle - Satin Black Hood/Roof/Decklid" width="600" height="338">
              </div>
              <span class="text-uppercase name">Satin Black painted hood/roof/decklid</span>
              <strong class="price">$3,695 ($5,200 CAD)</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-spacing bg-gray-200" aria-label="Interior Options">
    <div class="site-wrapper skinny-site-wrapper mb-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="section-lead text-uppercase text-center">Interior</h2>
          </div>
        </div>
        <div class="row">
          <div id="interior-gallery" class="col-sm-12">
            <interior-gallery [slides]="interiorGallery"></interior-gallery>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-spacing" aria-label="Wheel Options">
    <div class="site-wrapper skinny-site-wrapper mb-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="section-lead text-uppercase text-center">Wheels</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="promo-group alt">
              <div class="img-container">
                <img class="img-responsive" src="/assets/images/demon-170/SRT-Demon-170-Forged-Aluminum-Wheels.webp" alt="SRT Demon 170 - Forged Aluminum Wheels" width="600" height="338">
              </div>
              <span class="text-uppercase name">Forged Aluminum Wheels;<br class="visible-xs-block"/> Front: 18x8", Rear: 17x11"</span>
              <strong class="price">$0 ($0 CAD)</strong>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="promo-group alt">
              <div class="img-container">
                <img class="img-responsive" src="/assets/images/demon-170/SRT-Demon-170-Carbon-Fiber-Wheels.webp" alt="SRT Demon 170 - Carbon Fiber Wheels" width="600" height="338">
                </div>
              <span class="text-uppercase name">Carbon Fiber 2-Piece Wheels;<br class="visible-xs-block"/> Front: 18x8", Rear: 17x11"</span>
              <strong class="price">$11,495 ($18,995 CAD)</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-spacing" aria-label="Where to order">
    <div class="site-wrapper skinny-site-wrapper mb-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="section-lead text-uppercase text-center">
              Ready to blow your garage's ever-loving mind? <!--<br class="visible-lg-block"> Find a dealer. Make the call. -->
            </h2>
          </div>
        </div>
<!-- Sales of Demon 170 Closed       -->
<!--        <div class="row">-->
<!--          <div class="col-sm-6">-->
<!--            <div class="card-basic bg-black text-white">-->
<!--              <p class="section-lead text-uppercase">U.S. Dealers</p>-->
<!--              <a routerLink="/horsepower-locator" class="btn btn-primary">2023 Dodge Horsepower Locator</a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-sm-6">-->
<!--            <div class="card-basic bg-black text-white">-->
<!--              <p class="section-lead text-uppercase">Canadian Dealers</p>-->
<!--              <modal-ca-allocation></modal-ca-allocation>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </section>
  <hero-video
    [videoMobile]="footerVideo['videoMobile']"
    [videoDesktop]="footerVideo['videoDesktop']"></hero-video>
  <!-- <section class="section-spacing bg-gray-darkest" aria-label="Promotional Videos">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/75fQOYI-yek"></iframe>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/mXQi19XWqLI"></iframe>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <section class="section-spacing bg-gray-200" aria-label="Disclaimers">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-lg-8 col-lg-offset-2">
          <p class="caption">
            Preproduction model shown throughout. Actual production model may vary. Available late 2023. Limited production availability. Only attempt on approved, closed courses.
          </p>
          <p class="caption mb-0">
            1: Compared to the Challenger SRT Hellcat Redeye Widebody.
          </p>
          <p class="caption mb-0">
            2: Current production vehicles, based on 0-60 g-force.
          </p>
          <p class="caption mb-0">
            3: U.S. MSRP does not include tax, title, destination ($1,595) or gas guzzler ($2,100) fees. Final pricing determined by dealership.
          </p>
          <p class="caption mb-0">
            3.1: "Starting At" price represents the Manufacturer's Suggested Retail Price ("MSRP") plus freight (from $1,895 to $2,795), federal A/C and applicable Green Levy charges minus current Manufacturer Discounts which allow the dealer to sell for less. Taxes, insurance, registration, license, retailer administration fees, new tire duties (in Quebec) and the Luxury Tax Surcharge (if, and where applicable) not included. Your dealer may charge a Luxury Tax Surcharge on vehicles priced over $100,000 under the Government of Canada's Select Luxury Items Tax Act. The actual value of the Luxury Tax Surcharge will be calculated based on the vehicle's final negotiated selling price (before applicable taxes), and will represent the lesser of: (i) 10% of the vehicle's selling price; or (ii) 20% of the final negotiated selling price that exceeds the $100,000 threshold. Please see your dealer or visit the Government of Canada's website for further details. Manufacturer discounts may vary from time to time and may vary considerably by model, trim and engine option selected. Please see your retailer or visit Build & Price for further pricing information. Price listed is for base models. Vehicles shown may be an upgraded model. Retailers may sell for less.
          </p>
          <p class="caption mb-0">
            4: Current production vehicles, based on 0-60 time, excludes non-mass-production vehicles.
          </p>
          <p class="caption mb-0">
            5: Current production vehicles, based on 0-60 g-force.
          </p>
          <p class="caption mb-0">
            6: Based on quarter-mile time.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #featuresModal>
  <div class="modal-container">
    <div class="modal-header">
      <p class="h2">Standard SRT<sup>&reg;</sup> Redeye Widebody Model Features:</p>
      <button class="modal-close" (click)="closeModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <ul class="list-features">
        <li *ngFor="let item of standardSRTFeatures; let i = index" class="text-uppercase" [ngClass]="{'break': i === standardSRTFeatures.length / 2}" [innerHTML]="item"></li>
      </ul>
    </div>
  </div>
</ng-template>
