import {Vehicle} from "../vehicle/vehicle";

export type InteralStateType = {
  [key: string]: any
};

export class UserModel {
  id: number = 0;
  firstName: any;
  lastName: any;
  email: any;
  country: any;
  zipCode: any = '';
  username: any = '';
  gravatar: any;
  vipCode: any;
  usernameAvailability: any = true;
  password: any;
  confirmPassword: any;
  newPassword: any;
  confirmNewPassword: any;
  fcaCommunication: any = true;
  eventCommunication: any = true;

  accessToken: any;
  refreshToken: any;

  isActivated: boolean = false;

  muutMessage: any;
  muutSignature: any;
  muutTimestamp: any;
  muutTimeout: any;

  errorMessage: any;
  successMessage: any;
  passwordErrorMessage: any;
  passwordSuccessMessage: any;
  driverProfileErrorMessage: any;
  driverProfileSuccessMessage: any;

  activationToken: any;
  resetToken: any;

  driverProfileRequested: any;

  noRacingPledge: boolean;

  birthdate: any;

  isLoggedIn: boolean = false;

  ecwidUser: any;

  isPublic: boolean = false;
  isAdminUser: boolean = false;

  vehicles: Vehicle[] = [];

  // Drag Strip Showdown
  isActivatingRaces: boolean = false; // Used if creating or logging in after initally not opting into the contest, this boolean flag will let us know to go back and retroactively assign races with this user's qr code to the user

  newsArticleLikes: any;
}
