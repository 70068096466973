<div id="livestream-embed" [class.unsponsored]="!sponsored">
  <div class="btn-group d-inline-flex">
    <button class="btn btn-tab" [ngClass]="{'active': activeRound === 3}" (click)="updateEmbed(3)">Round 3 | 10/5</button>
    <button class="btn btn-tab" [ngClass]="{'active': activeRound === 4}" (click)="updateEmbed(4)">Round 4 | 10/6</button>
  </div>
  <section class="video-module" [class.no-sidebar]="!hasSchedule">
    <div class="video-player embed-responsive embed-responsive-16by9">
      <iframe id="ls_embed_1522450462" class="embed-responsive-item" [src]="safeEmbed" width="960" height="540" frameborder="0" scrolling="no" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="live stream"></iframe>
    </div>
    <aside class="video-sidebar hidden-xs hidden-sm" [hidden]="!hasSchedule">
      <ul class="nav nav-tabs">
        <li role="presentation" class="active">
          <a class="text-center text-uppercase" href="#schedule" aria-controls="schedule" data-toggle="tab">Schedule</a>
        </li>
      </ul>

      <div class="tab-content">
        <div  class="tab-pane active" id="schedule">
          <livestream-schedule></livestream-schedule>
        </div>
      </div>
      <div class="sponsor" *ngIf="sponsored">
        <span>Powered by:</span>
        <img loading="lazy" src="/assets/images/livestream/speedvideo-logo.png" alt="Powered by SpeedVideo">
      </div>
    </aside>
  </section>
</div>
