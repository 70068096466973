export const teams: Array<any> = [
  {
    name: "Team Sick Bastards",
    vehicle: {
      name: "1965 Plymouth AFX",
      img: "//placekitten.com/672/187"
    },
    participants: [
      {
        name: "EJ Naegeli",
        image: "/assets/images/rkn/teams-2023/ej-naegeli.webp",
        type: "Rookie",
        bio: "Vehicle wrapper turned drag racer. A rookie driver who pulled off a 12.29 pass at her first Sick Summer event in a gasser bracket car converted to a drag-and-driver.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/ej_was_here/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/@ej_was_here"
          },
          {
            platform: "facebook",
            url: "https://www.facebook.com/ejnaegeli",
          }
        ]
      },
      {
        name: "Tom Bailey",
        image: "/assets/images/rkn/teams-2023/tom-bailey.webp",
        type: "Mentor",
        bio: "Five-time winner of Hot Rod Drag Week. World record holder for street-legal vehicles at 5.77 seconds. Publisher of Sick The Mag and a popular YouTube content creator.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/sickseconds/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/@sickthemagazine"
          },
          {
            platform: "facebook",
            url: "https://www.facebook.com/sicksecond"
          }
        ]
      }
    ]
  },
  {
    name: "Team Corruptt",
    vehicle: {
      name: "2010 Dodge Dart",
      img: "//placekitten.com/672/187"
    },
    participants: [
      {
        name: "Kayla Rundle",
        image: "/assets/images/rkn/teams-2023/kayla-rundle.webp",
        type: "Rookie",
        bio: "Automotive design engineer. Performance car enthusiast. Content creator on YouTube, Instagram and TikTok.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/kaylarundle/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/@KaylaRundle"
          },
          {
            platform: "tiktok",
            url: "https://www.tiktok.com/@kaylarundle"
          }
        ]
      },
      {
        name: "Tony Arme",
        image: "/assets/images/rkn/teams-2023/tony-arme.webp",
        type: "Mentor",
        bio: "Owner and builder at American Legends Hotrods and Muscle Cars. Grudge Match veteran. Notorious for the “Corruptt Builds” and numerous national award-winning vehicles.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/corrupttbuilds/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/c/CorrupttBuilds"
          },
          {
            platform: "facebook",
            url: "https://www.facebook.com/alhotrods"
          }
        ]
      }
    ]
  },
  {
    name: "Team Dahminators",
    vehicle: {
      name: "Plymouth Prowler",
      img: "//placekitten.com/672/187"
    },
    participants: [
      {
        name: "Erica Schrull",
        image: "/assets/images/rkn/teams-2023/erica-schrull.webp",
        type: "Rookie",
        bio: "Car lover. Dodge enthusiast. Has owned five Dodge Challengers and currently rips in her Pearl White 2016 Dodge Challenger SRT<sup>&reg;</sup> Hellcat daily driver.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/ericaschrull/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/ericaschrull"
          }
        ]
      },
      {
        name: "Rob Dahm",
        image: "/assets/images/rkn/teams-2023/rob-dahm.webp",
        type: "Mentor",
        bio: "Normal guy. Custom rotary-powered car builder. Content creator on the Rob Dahm YouTube channel. Ready to take on his first piston engine challenge.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/robdahm/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/@RobDahm"
          }
        ]
      }
    ]
  },
  {
    name: "Team Soul Snatcher",
    vehicle: {
      name: "2010 Dodge Charger",
      img: "//placekitten.com/672/187"
    },
    participants: [
      {
        name: "Lee Carter",
        image: "/assets/images/rkn/teams-2023/lee-carter.webp",
        type: "Rookie",
        bio: "Founder of LeeC Parts, a salvage yard specializing in exotics and performance cars. Content creator of the ScrapLife Garage YouTube channel. His car obsession is borderline unhealthy.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/scraplifegarage/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/@scraplifegarage"
          },
          {
            platform: "facebook",
            url: "https://www.facebook.com/GFYLeeC"
          },
          {
            platform: "tiktok",
            url: "https://www.tiktok.com/@gfyleec"
          }
        ]
      },
      {
        name: "Herman Young",
        image: "/assets/images/rkn/teams-2023/herman-young.webp",
        type: "Mentor",
        bio: "Content creator of the Demonology YouTube channel, chronicling Dodge SRT<sup>&reg;</sup> Demon drag racing, tuning and modding. Snatcher of souls.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/demonology638/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/@Demonology"
          },
          {
            platform: "facebook",
            url: "https://www.facebook.com/OfficialDemonology"
          }
        ]
      }
    ]
  },
  {
    name: "Team Truck Yeah",
    vehicle: {
      name: "1995 Dodge Ram",
      img: "//placekitten.com/672/187"
    },
    participants: [
      {
        name: "Lacey Blair",
        image: "/assets/images/rkn/teams-2023/lacey-blair.webp",
        type: "Rookie",
        bio: "Owner of a powder coating shop outside of Dallas, Texas. Truck builder and content creator. First-time drag racer who has competed in dyno and burnout competitions.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/laceyblair_lbm/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/@LaceyBlair"
          },
          {
            platform: "tiktok",
            url: "https://www.tiktok.com/@lacey_blair"
          }
        ]
      },
      {
        name: "Justin Keith",
        image: "/assets/images/rkn/teams-2023/justin-kieth.webp",
        type: "Mentor",
        bio: "BMX racing prodigy. Founder of Street Car Takeover, the largest street car drag racing series in the country with 18 events at the largest drag strips. Owner of Killer Performance car shop, specializing in restomods.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/justinkeith_sct/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/@StangkilrProductions"
          },
          {
            platform: "facebook",
            url: "https://www.facebook.com/Stangkilr"
          }
        ]
      }
    ]
  },
  {
    name: "Team Throtl",
    vehicle: {
      name: "1999 Plymouth Prowler",
      img: "//placekitten.com/672/187"
    },
    participants: [
      {
        name: "Quinn Clark",
        image: "/assets/images/rkn/teams-2023/quinn-clark.webp",
        type: "Rookie",
        bio: "Car builder at Throtl. Drifter. Slider. True rookie drag racer.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/throtl/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/@throtl"
          },
          {
            platform: "tiktok",
            url: "https://www.tiktok.com/@throtl"
          }
        ]
      },
      {
        name: "Rickie Fernandez",
        image: "/assets/images/rkn/teams-2023/rickie-fernandez.webp",
        type: "Mentor",
        bio: "Two-time Grudge Match veteran. Driver of the Throtl 1,000+HP Challenger SRT<sup>&reg;</sup> Hellcat Redeye and 1,000+HP SRT Redeye-swapped Viper.",
        social: [
          {
            platform: "instagram",
            url: "https://www.instagram.com/throtl/"
          },
          {
            platform: "youtube",
            url: "https://www.youtube.com/@throtl"
          },
          {
            platform: "tiktok",
            url: "https://www.tiktok.com/@throtl"
          }
        ]
      }
    ]
  }
]