import { Component, Inject, Input, Output, EventEmitter, OnInit, OnDestroy, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'countdown-clock',
  templateUrl: './countdown-clock.component.html',
  styleUrls: ['./countdown-clock.component.scss']
})
export class CountdownClockComponent implements OnInit, OnDestroy {
  @Input() targetDate: Date;

  // Emitters
  @Output() timerExpired: EventEmitter<void> = new EventEmitter<void>(); 
  @Output() cueSnow: EventEmitter<void> = new EventEmitter<void>();
  @Output() requestFreshData: EventEmitter<void> = new EventEmitter<void>();
  @Output() winnersBeingSelected: EventEmitter<void> = new EventEmitter<void>();

  // Emitter flags
  cueSnowFlag: boolean = true;
  requestFreshDataFlag: boolean = true;

  hours: string = '00';
  minutes: string = '00';
  seconds: string = '00';
  countdownInterval: any;

  rumble: boolean = false;


  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.startCountdown();
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) { 
      this.stopCountdown();
    }
  }

  startCountdown() {
    this.countdownInterval = setInterval(() => {
      const currentTime = this.forceDateToEST(new Date()).getTime();
      const timeRemaining = this.targetDate.getTime() - currentTime;

      if (timeRemaining > 0) {
        this.hours = String(Math.floor(timeRemaining / (1000 * 60 * 60))).padStart(2, '0');
        this.minutes = String(Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
        this.seconds = String(Math.floor((timeRemaining % (1000 * 60)) / 1000)).padStart(2, '0');
        
        // When the time hits 15 minutes or less, hide winners
        if (Number(this.hours) === 0 && Number(this.minutes) < 15) {
          this.winnersBeingSelected.emit();
        }

        if (Number(this.hours) === 0 && Number(this.minutes) === 0 && Number(this.seconds) < 2 ) {
          if (this.requestFreshDataFlag) {
            this.requestFreshData.emit();
            this.requestFreshDataFlag = false;
          }
        }
        
        // When the time hits 2 minutes or less, cue the rumbling
        if (Number(this.hours) === 0 && Number(this.minutes) < 2 ) {
          this.rumble = true;
        }

        // When the time hits 10s, cue snowfall
        if (Number(this.hours) === 0 && Number(this.minutes) === 0 && Number(this.seconds) < 3 ) {
          if (this.cueSnowFlag) {
            this.cueSnow.emit();
            this.cueSnowFlag = false;
          }
        }
      } else {
        this.stopCountdown();
        this.hours = '00';
        this.minutes = '00';
        this.seconds = '00';
        this.timerExpired.emit();
        this.rumble = false;
        this.cueSnowFlag = true;
        this.requestFreshDataFlag = true;
      }
    }, 1000);
  }

  forceDateToEST(date: Date) {
    // Create a new Date object representing the current date and time
    let currentDate = new Date(date);

    // Format the date in Eastern Standard Time (EST)
    let estOptions = { timeZone: 'America/New_York' };
    let estDate = currentDate.toLocaleString('en-US', estOptions);

    return new Date(estDate);
  }

  stopCountdown() {
    clearInterval(this.countdownInterval);
  }
}