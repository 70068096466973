<img loading="lazy" class="video-bg-img" src="/assets/images/drag-pak/drag-pak-video-poster.webp" alt="Explore the 2021 Dodge Challenger Mopar Drag Pak" width="939" height="350">

<div class="play-container">
  <div class="play-button">
    <i class="fa fa-play"></i>
  </div>
</div>

<ng-template #videoModal>
  <div id="video-drag-pak" class="modal-container">
    <div class="modal-header">
      <!-- <h2 class="modal-title pull-left">Explore the 2021 Dodge Challenger Mopar Drag Pak</h2> -->
      <button class="modal-close" (click)="videoModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/454025588?autoplay=1&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="Explore the 2021 Dodge Challenger Mopar Drag Pak"></iframe>
      </div>
    </div>
  </div>
</ng-template>