import { Component, OnInit, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { VideoService } from '../../video/video.service';
import { VideoAlbum } from '../../video/video-album';
import { ActivatedRoute } from '@angular/router';
import { Video } from '../../video/video';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import {AddThisService} from '../../services/addthis.service';

declare let addthis: any;

@Component({
  selector: 'videos-section',
  templateUrl: './videos-section.component.html',
  styles: []
})
export class VideosSection implements OnInit {

  videoAlbums: VideoAlbum[] = [];
  activeVideo: Video = new Video();
  @ViewChild(ModalDirective, { static: true }) public videoModal: ModalDirective;
  videoModalRef: BsModalRef;

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private videoService: VideoService,
    private addThisService: AddThisService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.fetchVideos();
    this.route.params.subscribe( params => {
      if (params.videoId) {
        this.fetchVideo(params.videoId);
      }
    });
  }

  fetchVideos() {
    this.videoService.getVimeoAlbums().subscribe( data => {
      let albums = data.data.filter( album => (album.name != 'Curated + Internal' && album.name != 'DodgeGarage Videos'))
      // We want the video categories to be in a specific order
      let desiredOrder = [ 'Inside Scoop', 'Weekend Warriors', 'The Rundown', 'Chasing the Title' ];
      //let orderedAlbumbs = [];
      desiredOrder.forEach((key) => {
        let found = false;
        albums = albums.filter((item) => {
          if(!found && item.name == key) {
            this.videoAlbums.push(item);
            found = true;
            return false;
          } else 
            return true;
        })
      });
    })
  }

  fetchVideo(videoId) {
    this.videoService.getVimeoVideo(videoId).subscribe( data => {
      this.activeVideo = this.videoService.mutateVimeoVideos(data);
      if (isPlatformBrowser(this.platformId)) {

        // Add AddThis to Page
        const script = document.createElement('script');
        script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
        document.body.appendChild(script);
        // Reload AddThis
        setTimeout(() => {
          addthis.layers.refresh();
          this.addThisService.setAddThisVisibility(true);
        }, 3000);

        this.videoModal.show();
      }
    });
  }

  openVideoModal(event) {
    this.activeVideo = event;
    this.videoModal.show();
  }

  closeVideoModal() {
    // Add any magic to fire when closing the modal
    this.activeVideo = new Video();
    this.videoModal.hide();
  }

  videoModalListener(type: string, $event: ModalDirective) {
    // Catch closing on backdrop click only
    if (type == 'onHidden' && $event.dismissReason == 'backdrop-click') {
      this.closeVideoModal();
    }
  }

  removeAddThis() {
    const script = document.createElement('script');
    script.src ='//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
    const scripts = document.getElementsByTagName('script');
    for (let i = scripts.length; i--;) {
      if (scripts[i].src == script.src) {
        // Remove script if not the proper url
        scripts[i].parentNode.removeChild(scripts[i]);
        return true;
      }
    }
    return false;
  }

}
