export const fabFive: Array<any> = [
  "1,025 horsepower",
  "945 Ib.-ft. of torque at 6,500 rpm",
  "The world's quickest 0-60 production car at 1.66 seconds<sup>4</sup>",
  "The highest g-force acceleration of any production car at 2.004 Gs<sup>5</sup>",
  "The first production muscle car ever to run a sub-nine-second quarter-mile from the factory<sup>6</sup>"
]

export const standardFeatures: Array<any> = [
  "6.2L V8 SUPERCHARGED D170 ENGINE",
  "3.0L SUPERCHARGER W/BLACK LID",
  "SRT DEMON 170 SUPERCHARGER BADGE",
  "YELLOW PAINTED ENGINE BLOCK",
  "TRANSBRAKE 2.0",
  "3.09 REAR AXLE RATIO",
  "240MM REAR AXLE",
  "DRAG SUSPENSION",
  "BREMBO BLACK 4-PISTON BRAKES W/YELLOW LOGO",
  'FORGED ALUMINUM WHEELS; FRONT: 18X8", REAR: 17X11"',
  "MICKEY THOMPSON<sup>&reg;</sup> DRAG RADIALS; FRONT: 245/55R18, REAR: P315/50R17",
  "AIR GRABBER HOOD",
  "SRT DEMON 170 EXTERIOR BADGES",
  "SATIN BLACK DODGE TAIL LAMP BADGE",
  "REAR BODY FLARES",
  "SRT PERFORMANCE SPOILER",
  "HIGH-INTENSITY DISCHARGE HEADLAMPS",
  "DC ILLUMINATED AIR CATCHER HEADLAMPS",
  "CLOTH DRIVER SEAT",
  "SRT DEMON 170 INSTRUMENT PANEL BADGE",
  "REAL CARBON FIBER INTERIOR ACCENTS",
  "ALCANTARA STEERING WHEEL W/RED LED",
  "PASSENGER SEAT/SEATBELT DELETE",
  "REAR SEAT/SEATBELT DELETE",
  "TRUNK CARPET/LAMP DELETE",
  "SOUND INSULATION DELETE",
  "BRIGHT PEDAL DELETE",
  "CLOTH DOOR TRIM PANEL W/MAP POCKET LIGHT",
  "DRIVER FLOOR MAT",
  "2 SPEAKER AUDIO"
]

export const SRTStandardFeatures: Array<any> = [
  "8-spd auto hp90 transmission (buy)",
  "Autostick<sup>&reg;</sup> automatic transmission",
  "Remote start system",
  "Srt track experience",
  "Line lock",
  "After-run chiller",
  "Srt configurable drive modes",
  "Launch assist",
  "Srt power chiller",
  "Performance shift indicator",
  "Srt performance pages",
  "Anti-spin differential rear axle",
  "High-speed engine controller",
  "Severe-duty ii engine cooling",
  "Active exhaust",
  "Conical cold air intake",
  "Engine oil cooler",
  "Adaptive damping suspension",
  "Rear stabilizer bar",
  "Last call plaque",
  "Black exhaust tips",
  "Black fuel filler door",
  "Exterior mirrors w/heating element",
  "Halogen headlamps",
  "Automatic headlamps",
  "Led tail lamps",
  "Daytime running lamps, park/turn",
  "220 mph primary speedometer",
  "Leather-wrapped shift knob (auto)",
  "Tilt/telescope steering column",
  "Steering-wheel-mounted shift control",
  "Steering-wheel-mounted audio ctrls",
  "Child seat upper tether anchorages",
  "Supplemental front seat side air bags",
  "Supp. Side curtain front/rear air bags",
  "Active head restraints",
  "Adv srt multistage front air bags",
  "Door trim panel w/ambient lighting",
  "Rear view auto dim mirror",
  "Air cond atc w/dual zone control",
  "Illuminated cupholders",
  "Front reading/map lamps",
  "Rear courtesy lamps",
  "Illuminated door pull handles",
  "Tire pressure monitoring display",
  'Uconnect<sup>&reg;</sup> 4c with 8.4" display',
  "Compass gauge",
  "Hd radio",
  '8.4" touchscreen display',
  "Apple carplay",
  "Google android auto",
  "Siriusxm satellite radio, 6-month trial",
  "Siriusxm guardian, 1-yr trial",
  "Media hub (2 usb, aux)",
  "4g lte wi-fi hotspot, 3-month/1 gb trial",
  "220-amp alternator",
  "730-amp maintenance-free battery",
  "Rain brake support",
  "Electronic stability control",
  "Sentry key theft deterrent system",
  "Push button start",
  "Keyless entry",
  "Illuminated entry",
  "18.5-gallon fuel tank",
  "Tire service kit",
  "Parksense<sup>&reg;</sup> rear park assist system",
  "Parkview<sup>&reg;</sup> rear back-up camera"
]

// Keeping this around until I can confirm things with Kate before launch.
export const SRTStandardFeaturesOld: Array<any> = [
  '6.2L V8 SUPERCHARGED HO ENGINE',
  '8-SPD AUTO HP90 TRANSMISSION (BUY)',
  'LEATHER WRAPPED SHIFT KNOB (AUTO)',
  'AUTOSTICK (R) AUTOMATIC TRANSMISSION',
  'REMOTE START SYSTEM',
  'TIP START',
  'STEERING WHEEL MOUNTED SHIFT CONTROL',
  'ELECTRONIC STABILITY CONTROL',
  'BRAKE ASSIST',
  '220 AMP ALTERNATOR',
  '730 AMP MAINTENANCE FREE BATTERY',
  'LINE LOCK',
  'RAIN BRAKE SUPPORT',
  'CHILD SEAT UPPER TETHER ANCHORAGES',
  'SUPPLEMENTAL FRT SEAT SIDE AIR BAGS',
  'SUPP. SIDE CURTAIN FRT/RR AIR BAGS',
  'ACTIVE HEAD RESTRAINTS',
  'ADV SRT MULTISTAGE FRONT AIR BAGS**',
  'DOOR TRIM PANEL W/AMBIENT LIGHTING',
  'ANTI-SPIN DIFFERENTIAL REAR AXLE',
  'HIGH-SPEED ENGINE CONTROLLER',
  'SEVERE-DUTY II ENGINE COOLING',
  'LAST CALL PLAQUE',
  'ACTIVE EXHAUST',
  'BLACK EXHAUST TIPS',
  'BLACK FUEL FILLER DOOR',
  'ILLUMINATED AIR-CATCHER HEADLAMPS',
  'CONICAL COLD AIR INTAKE',
  'AFTER-RUN CHILLER',
  'ENGINE OIL COOLER',
  'SRT CONFIGURABLE DRIVE MODES',
  'LAUNCH ASSIST',
  'SRT POWER CHILLER',
  'SENTRY KEY THEFT DETERRENT SYSTEM',
  'PUSH BUTTON START',
  'KEYLESS ENTRY',
  'ILLUMINATED ENTRY',
  'REAR VIEW AUTO DIM MIRROR',
  'EXTERIOR MIRRORS W/HEATING ELEMENT',
  'AIR COND ATC W/DUAL ZONE CONTROL',
  'ILLUMINATED CUPHOLDERS',
  'PERFORMANCE SHIFT INDICATOR',
  'SRT PERFORMANCE PAGES',
  'POWER 6-WAY DRIVER SEAT',
  '220 MPH PRIMARY SPEEDOMETER',
  'FRONT READING/MAP LAMPS',
  'REAR COURTESY LAMPS',
  'ILLUMINATED DOOR PULL HANDLES',
  'HALOGEN HEADLAMPS',
  'AUTOMATIC HEADLAMPS',
  'LED TAIL LAMPS',
  'DAYTIME RUNNING LAMPS, PARK/TURN',
  'TIRE PRESSURE MONITORING DISPLAY',
  '18.5 GALLON FUEL TANK',
  'STEERING WHEEL MOUNTED AUDIO CTRLS',
  'UCONNECT® 4C WITH 8.4" DISPLAY',
  'COMPASS GAUGE',
  'HD RADIO',
  '8.4" TOUCHSCREEN DISPLAY',
  'APPLE CARPLAY',
  'GOOGLE ANDROID AUTO',
  'SIRIUSXM® SATELLITE RADIO',
  'SIRIUSXM® GUARDIAN, 1-YR TRIAL',
  'MEDIA HUB (2 USB, AUX)',
  '4G LTE WI-FI HOT SPOT',
  'TILT/TELESCOPE STEERING COLUMN',
  'REAR STABILIZER BAR',
  'ADAPTIVE DAMPING SUSPENSION',
  'TIRE SERVICE KIT',
  'PARKSENSE® REAR PARK ASSIST SYSTEM',
  'PARKVIEW® REAR BACK-UP CAMERA',
  'SRT TRACK EXPERIENCE AT RADFORD RACING SCHOOL'
]
