<div class="alert" *ngIf="userService.activationStatusCheck" [ngClass]="['bg-' + userService.activationContext]">
  <div class="row">
    <div class="col-md-6">
      <div class="pull-left context-icon">
        <i class="fa fa-exclamation-triangle" aria-hidden="true" *ngIf="userService.activationContext == 'danger'"></i>
        <i class="fa fa-check" aria-hidden="true" *ngIf="userService.activationContext == 'success'"></i>
      </div>
      <div class="context-header">
        <h4 *ngIf="userService.activationContext == 'danger'">Oops!</h4>
        <h4 *ngIf="userService.activationContext == 'success'">{{userService.activationHeader}}</h4>
      </div>
      <p class="context-message">
        <span *ngFor="let message of userService.activationStatusMessages">{{message}} </span>
      </p>
      <div class="clearfix"></div>
    </div>
    <div class="col-md-6" *ngIf="!userService.userModel.isLoggedIn">
      <div class="row">
        <div class="col-sm-3 hidden-md hidden-lg">
          <p class="sign-in-helper">Please sign in to continue.</p>
        </div>
        <div class="col-sm-9 col-md-12">
          <performance-cars-login-form-inline></performance-cars-login-form-inline>
        </div>
      </div>
    </div>
  </div>


</div>
