import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl } from '@angular/forms';

import { RoadkillNightsVideoService } from './roadkill-nights-video.service';

@Component({
  selector: 'roadkill-nights-video',
  templateUrl: './roadkill-nights-video.component.html',
  styleUrls: ['./roadkill-nights-video.component.less']
})
export class RoadkillNightsVideo implements OnInit {

  apiKey : string = 'YOUR-APIKEY-YOUTUBE';
  videos: any;
  activeVideo: any = '';
  channelName: string;
  modalActive = false;
  comingSoon = false;

  @ViewChild(ModalDirective, { static: true }) public videoModal: ModalDirective;
  videoModalRef: BsModalRef;

  playlistId = new FormControl('');

  constructor(
    public title: Title,
    public metaService: Meta,
    private router: Router,
    public http: HttpClient,
    public sanitizer: DomSanitizer,
    public RoadkillNightsVideoService: RoadkillNightsVideoService
  ) {
    title.setTitle( 'Roadkill Nights Video Test | Dodge Garage' );
    metaService.updateTag({ name: 'description', content: 'Roadkill Nights Video Test' })
    metaService.updateTag({ property: 'og:description', content: 'Roadkill Nights Video Test' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Roadkill Nights Video Test | Dodge Garage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
    
    this.getPlaylist('PLPR6DfFbLCkkcFU-pxfE-1mqeSc50SVe7');
    
  }

  // Grab lil demon's playlist data from YT API and store on page
  getPlaylist(playlistID: string) {
    this.RoadkillNightsVideoService.getVideosForPlaylist(playlistID).subscribe(
      res => {
        this.videos = res['items']
        this.comingSoon = false
        console.log(this.videos);

        // Get channels's name
        this.channelName = this.videos[0].snippet.channelTitle
      },
      (err) => {this.comingSoon = true}
    )
  }

  openVideoModal(video) { 
    this.activeVideo = video;
    //this.helperService.trackGAEvent(this.previousURL, 'Click', 'Video Modal - ' + this.selectedVideo.name);
    this.modalActive = true;
    this.videoModal.show();
  }

  closeVideoModal() {
    this.videoModal.hide();
    this.modalActive = false;
  }

  // For testing
  formSubmit(value, event) {
    event.preventDefault()
    this.getPlaylist(value)
  }

}
