<dg-alert-banner [alert]="deleteCommentAlert"></dg-alert-banner>

<p>Deleting a post does not delete replies.</p>

<div class="text-right">
  <button class="btn btn-primary" (click)="deleteComment()" [disabled]="isLoading" [ngClass]="{'is-loading' : isLoading}">
    Submit
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
  </button>
  <button class="btn btn-white" (click)="cancelDelete()">Cancel</button>
</div>
