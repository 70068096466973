import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'dg-style-guide-form',
  templateUrl: './style-guide-form.component.html',
  styleUrls: ['./style-guide-form.component.less']
})
export class StyleGuideFormComponent implements OnInit {

  states: Array<Object> = [];

  constructor(
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.fetchStates();
  }

  fetchStates() {
    this.helperService.getStates().subscribe( data => {
      this.states = data;
    })
  }
}
