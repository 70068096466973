<section class="individual-event" autoscroll="true">
  <div class="site-wrapper">
    <div class="row">
      <div class="col-sm-12">
        <header class="clearfix">
          <h1>Event Page</h1>
          <p>{{ paramId }}</p>
          <p>{{ paramName }}</p>
        </header>
      </div>
    </div>
  </div>
</section>
