
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { HelperService } from '../services/helper.service';
import { Event } from './event';
import { EventSearch } from './eventSearch';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {NewsArticle} from '../news-articles/news-article';
import {Location} from '@angular/common';

@Injectable()
export class EventService {
  eventSearch = new EventSearch();
  events: Event[] = [];
  pastEvents: Event[] = [];

  isEventModalOpen: boolean = false;
  isEventModalOpen$ = new BehaviorSubject<boolean>(this.isEventModalOpen);
  isEventChanged: boolean = false;
  isEventChanged$ = new BehaviorSubject<boolean>(this.isEventChanged);
  isEventViewFull: boolean = false;
  eventOpen: Event = new Event();


  constructor(
    public helperService: HelperService,
    private http: HttpClient,
    private location: Location,
  ) {}

  getEvent(eventId): Observable<any> {
    const url = environment.url+'/rest/event/show/'+eventId;
    return this.http.get(url).pipe(
      catchError(this.helperService.handleError));
  }

  getEvents(max: number = 6, offset: number = 0, category: string = '', isDodgeOfficial: boolean = false, features: Array<any> = [], getMethod = null): Observable<Event[]> {

    let Params = new HttpParams();

    // Begin assigning parameters
    Params = Params.append('max', max.toString());
    Params = Params.append('offset', offset.toString());
    Params = (this.eventSearch.term) ? Params.append('term', this.eventSearch.term) : Params;
    Params = (this.eventSearch.state) ? Params.append('state', this.eventSearch.state) : Params;
    Params = (category) ? Params.append('category', category) : Params;
    Params = (isDodgeOfficial) ? Params.append('dodgeofficial', isDodgeOfficial.toString()) : Params;
    if (features) {
      features.forEach( feature => {
        Params = Params.append('features', feature);
      });
    }
    Params = (getMethod) ? Params.append('getMethod', getMethod) : Params;

    const url = environment.url+'/rest/event/getEvents';
    return this.http.get<Event[]>(url, { params: Params }).pipe(catchError(this.helperService.handleError));
  }

  getPastEvents(max: number = 6, offset: number = 0, category: string = '', isDodgeOfficial: boolean = false, features: Array<any> = [], getMethod = null): Observable<Event[]> {

    let Params = new HttpParams();

    // Begin assigning parameters
    Params = Params.append('max', max.toString());
    Params = Params.append('offset', offset.toString());
    Params = (this.eventSearch.term) ? Params.append('term', this.eventSearch.term) : Params;
    Params = (this.eventSearch.state) ? Params.append('state', this.eventSearch.state) : Params;
    Params = (category) ? Params.append('category', category) : Params;
    Params = (isDodgeOfficial) ? Params.append('dodgeofficial', isDodgeOfficial.toString()) : Params;
    if (features) {
      features.forEach( feature => {
        Params = Params.append('features', feature);
      });
    }
    Params = (getMethod) ? Params.append('getMethod', getMethod) : Params;

    const url = environment.url+'/rest/event/getPastEvents';
    return this.http.get<Event[]>(url, { params: Params }).pipe(catchError(this.helperService.handleError));

  }

  mutateEvent(event: Event) {
    event.startDate = new Date(event.startDate);
    event.endDate = new Date(event.endDate);
    return event;
  }

  setModalOpen(value: boolean) {
    // Update news modal subject
    this.isEventModalOpen$.next(value);
    this.isEventModalOpen = value;
  }

  getEventPath(event: Event) {
    return '/events/'+ event.id + '/' + this.helperService.slugURL(event.name);
  }

  // setEventChanged(value: boolean, event: Event = null) {
  //   // Update event
  //   this.isEventChanged$.next(value);
  //   this.isEventChanged = value;
  //   if (event) {
  //     this.eventOpen = new Event();
  //     this.eventOpen.path = this.getEventPath(event)
  //     //this.location.go(url);
  //     setTimeout(() => this.location.replaceState(this.eventOpen.path));
  //   }
  // }
}
