import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from "../../environments/environment";

@Injectable()
export class DownloadsService {

  constructor(
    private http: HttpClient
  ) { }

  wallpapers = [
    {
      name: 'Dodge Hornet RT',
      src: {
        thumb: '/assets/images/downloads/dodge-hornet-rt/dodge-hornet-rt-thumb.webp',
        mobile: '/assets/images/downloads/dodge-hornet-rt/dodge-hornet-rt-mobile.jpg',
        tablet: '/assets/images/downloads/dodge-hornet-rt/dodge-hornet-rt-tablet.jpg',
        desktop: '/assets/images/downloads/dodge-hornet-rt/dodge-hornet-rt-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Challenger SRT Hellcat Redeye Widebody Fade',
      src: {
        thumb: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-fade/dodge-challenger-srt-hellcat-redeye-widebody-fade-thumb.webp',
        mobile: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-fade/dodge-challenger-srt-hellcat-redeye-widebody-fade-mobile.png',
        tablet: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-fade/dodge-challenger-srt-hellcat-redeye-widebody-fade-tablet.png',
        desktop: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-fade/dodge-challenger-srt-hellcat-redeye-widebody-fade-desktop.png',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Challenger SRT Hellcat Redeye Widebody with Logo',
      src: {
        thumb: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-with-logo/dodge-challenger-srt-hellcat-redeye-widebody-with-logo-thumb.webp',
        mobile: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-with-logo/dodge-challenger-srt-hellcat-redeye-widebody-with-logo-mobile.png',
        tablet: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-with-logo/dodge-challenger-srt-hellcat-redeye-widebody-with-logo-tablet.png',
        desktop: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-with-logo/dodge-challenger-srt-hellcat-redeye-widebody-with-logo-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Challenger SRT Hellcat Redeye Widebody Light',
      src: {
        thumb: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-light/dodge-challenger-srt-hellcat-redeye-widebody-light-thumb.webp',
        mobile: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-light/dodge-challenger-srt-hellcat-redeye-widebody-light-mobile.jpg',
        tablet: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-light/dodge-challenger-srt-hellcat-redeye-widebody-light-tablet.jpg',
        desktop: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-light/dodge-challenger-srt-hellcat-redeye-widebody-light-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Challenger SRT Hellcat Redeye Widebody Flames',
      src: {
        thumb: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-flames/dodge-challenger-srt-hellcat-redeye-widebody-flames-thumb.webp',
        mobile: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-flames/dodge-challenger-srt-hellcat-redeye-widebody-flames-mobile.jpg',
        tablet: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-flames/dodge-challenger-srt-hellcat-redeye-widebody-flames-tablet.jpg',
        desktop: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-flames/dodge-challenger-srt-hellcat-redeye-widebody-flames-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Challenger SRT Hellcat Redeye Widebody Design',
      src: {
        thumb: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-design/dodge-challenger-srt-hellcat-redeye-widebody-design-thumb.webp',
        mobile: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-design/dodge-challenger-srt-hellcat-redeye-widebody-design-mobile.jpg',
        tablet: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-design/dodge-challenger-srt-hellcat-redeye-widebody-design-tablet.jpg',
        desktop: '/assets/images/downloads/dodge-challenger-srt-hellcat-redeye-widebody-design/dodge-challenger-srt-hellcat-redeye-widebody-design-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Challenger SRT Demon 170',
      src: {
        thumb: '/assets/images/downloads/dodge-challenger-srt-demon-170/dodge-challenger-srt-demon-170-thumb.webp',
        mobile: '/assets/images/downloads/dodge-challenger-srt-demon-170/dodge-challenger-srt-demon-170-mobile.jpg',
        tablet: '/assets/images/downloads/dodge-challenger-srt-demon-170/dodge-challenger-srt-demon-170-tablet.jpg',
        desktop: '/assets/images/downloads/dodge-challenger-srt-demon-170/dodge-challenger-srt-demon-170-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Charger SRT Hellcat',
      src: {
        thumb: '/assets/images/downloads/charger-srt-hellcat/charger-srt-hellcat-thumb.jpg',
        mobile: '/assets/images/downloads/charger-srt-hellcat/charger-srt-hellcat-mobile.jpg',
        tablet: '/assets/images/downloads/charger-srt-hellcat/charger-srt-hellcat-tablet.jpg',
        desktop: '/assets/images/downloads/charger-srt-hellcat/charger-srt-hellcat-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Hemi SRT',
      src: {
        thumb: '/assets/images/downloads/hemi-srt/hemi-srt-thumb.jpg',
        watch: '/assets/images/downloads/hemi-srt/hemi-srt-watch.jpg',
        mobile: '/assets/images/downloads/hemi-srt/hemi-srt-mobile.jpg',
        tablet: '/assets/images/downloads/hemi-srt/hemi-srt-tablet.jpg',
        desktop: '/assets/images/downloads/hemi-srt/hemi-srt-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Funny Car 2021',
      src: {
        thumb: '/assets/images/downloads/funny-car-2021/funnycar-thumb.jpg',
        watch: '/assets/images/downloads/funny-car-2021/funnycar-watch.jpg',
        mobile: '/assets/images/downloads/funny-car-2021/funnycar-mobile.jpg',
        tablet: '/assets/images/downloads/funny-car-2021/funnycar-tablet.jpg',
        desktop: '/assets/images/downloads/funny-car-2021/funnycar-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Durango Front',
      src: {
        thumb: '/assets/images/downloads/durango-front/durango-front-thumb.jpg',
        watch: '/assets/images/downloads/durango-front/durango-front-watch.jpg',
        mobile: '/assets/images/downloads/durango-front/durango-front-mobile.jpg',
        tablet: '/assets/images/downloads/durango-front/durango-front-tablet.jpg',
        desktop: '/assets/images/downloads/durango-front/durango-front-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dragster',
      src: {
        thumb: '/assets/images/downloads/dragster/dragster-thumb.jpg',
        watch: '/assets/images/downloads/dragster/dragster-watch.jpg',
        mobile: '/assets/images/downloads/dragster/dragster-mobile.jpg',
        tablet: '/assets/images/downloads/dragster/dragster-tablet.jpg',
        desktop: '/assets/images/downloads/dragster/dragster-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Challenger',
      src: {
        thumb: '/assets/images/downloads/challenger/challenger-thumb.jpg',
        watch: '/assets/images/downloads/challenger/challenger-watch.jpg',
        mobile: '/assets/images/downloads/challenger/challenger-mobile.jpg',
        tablet: '/assets/images/downloads/challenger/challenger-tablet.jpg',
        desktop: '/assets/images/downloads/challenger/challenger-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Sideview',
      src: {
        thumb: '/assets/images/downloads/charger-sideview/charger-sideview-thumb.jpg',
        watch: '/assets/images/downloads/charger-sideview/charger-sideview-watch.jpg',
        mobile: '/assets/images/downloads/charger-sideview/charger-sideview-mobile.jpg',
        tablet: '/assets/images/downloads/charger-sideview/charger-sideview-tablet.jpg',
        desktop: '/assets/images/downloads/charger-sideview/charger-sideview-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: '340 Wedge',
      src: {
        thumb: '/assets/images/downloads/340-wedge/340-wedge-thumb.jpg',
        watch: '/assets/images/downloads/340-wedge/340-wedge-watch.jpg',
        mobile: '/assets/images/downloads/340-wedge/340-wedge-mobile.jpg',
        tablet: '/assets/images/downloads/340-wedge/340-wedge-tablet.jpg',
        desktop: '/assets/images/downloads/340-wedge/340-wedge-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: '2021 Durango SRT Hellcat',
      src: {
        thumb: '/assets/images/downloads/2021-durango-srt-hellcat/2021-durango-srt-hellcat-thumb.jpg',
        watch: '/assets/images/downloads/2021-durango-srt-hellcat/2021-durango-srt-hellcat-watch.jpg',
        mobile: '/assets/images/downloads/2021-durango-srt-hellcat/2021-durango-srt-hellcat-mobile.jpg',
        tablet: '/assets/images/downloads/2021-durango-srt-hellcat/2021-durango-srt-hellcat-tablet.jpg',
        desktop: '/assets/images/downloads/2021-durango-srt-hellcat/2021-durango-srt-hellcat-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Red Charger RT',
      src: {
        thumb: '/assets/images/downloads/red-charger-rt/red-charger-rt-thumb.jpg',
        watch: '/assets/images/downloads/red-charger-rt/red-charger-rt-watch.jpg',
        mobile: '/assets/images/downloads/red-charger-rt/red-charger-rt-mobile.jpg',
        tablet: '/assets/images/downloads/red-charger-rt/red-charger-rt-tablet.jpg',
        desktop: '/assets/images/downloads/red-charger-rt/red-charger-rt-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: '2021 Challenger Super Stock',
      src: {
        thumb: '/assets/images/downloads/2021-challenger-srt-super-stock/2021-challenger-srt-super-stock-thumb.jpg',
        watch: '/assets/images/downloads/2021-challenger-srt-super-stock/2021-challenger-srt-super-stock-watch.jpg',
        mobile: '/assets/images/downloads/2021-challenger-srt-super-stock/2021-challenger-srt-super-stock-mobile.jpg',
        tablet: '/assets/images/downloads/2021-challenger-srt-super-stock/2021-challenger-srt-super-stock-tablet.jpg',
        desktop: '/assets/images/downloads/2021-challenger-srt-super-stock/2021-challenger-srt-super-stock-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: '68 Charger Grille',
      src: {
        thumb: '/assets/images/downloads/68-charger-grille/68-charger-grille-thumb.jpg',
        watch: '/assets/images/downloads/68-charger-grille/68-charger-grille-watch.jpg',
        mobile: '/assets/images/downloads/68-charger-grille/68-charger-grille-mobile.jpg',
        tablet: '/assets/images/downloads/68-charger-grille/68-charger-grille-tablet.jpg',
        desktop: '/assets/images/downloads/68-charger-grille/68-charger-grille-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger RT Poster',
      src: {
        thumb: '/assets/images/downloads/charger-rt-poster/charger-rt-poster-thumb.jpg',
        watch: '/assets/images/downloads/charger-rt-poster/charger-rt-poster-watch.jpg',
        mobile: '/assets/images/downloads/charger-rt-poster/charger-rt-poster-mobile.jpg',
        tablet: '/assets/images/downloads/charger-rt-poster/charger-rt-poster-tablet.jpg',
        desktop: '/assets/images/downloads/charger-rt-poster/charger-rt-poster-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: '2021 Charger Hellcat Redeye',
      src: {
        thumb: '/assets/images/downloads/2021-charger-hellcat-redeye/2021-charger-hellcat-redeye-thumb.jpg',
        watch: '/assets/images/downloads/2021-charger-hellcat-redeye/2021-charger-hellcat-redeye-watch.jpg',
        mobile: '/assets/images/downloads/2021-charger-hellcat-redeye/2021-charger-hellcat-redeye-mobile.jpg',
        tablet: '/assets/images/downloads/2021-charger-hellcat-redeye/2021-charger-hellcat-redeye-tablet.jpg',
        desktop: '/assets/images/downloads/2021-charger-hellcat-redeye/2021-charger-hellcat-redeye-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: '426 Super Stock',
      src: {
        thumb: '/assets/images/downloads/426-super-stock/426-super-stock-thumb.jpg',
        watch: '/assets/images/downloads/426-super-stock/426-super-stock-watch.jpg',
        mobile: '/assets/images/downloads/426-super-stock/426-super-stock-mobile.jpg',
        tablet: '/assets/images/downloads/426-super-stock/426-super-stock-tablet.jpg',
        desktop: '/assets/images/downloads/426-super-stock/426-super-stock-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: '2021 Pack',
      src: {
        thumb: '/assets/images/downloads/2021-pack/2021-pack-thumb.jpg',
        watch: '/assets/images/downloads/2021-pack/2021-pack-watch.jpg',
        mobile: '/assets/images/downloads/2021-pack/2021-pack-mobile.jpg',
        tablet: '/assets/images/downloads/2021-pack/2021-pack-tablet.jpg',
        desktop: '/assets/images/downloads/2021-pack/2021-pack-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: '68 Charger RT',
      src: {
        thumb: '/assets/images/downloads/68-charger-rt/68-charger-rt-thumb.jpg',
        watch: '/assets/images/downloads/68-charger-rt/68-charger-rt-watch.jpg',
        mobile: '/assets/images/downloads/68-charger-rt/68-charger-rt-mobile.jpg',
        tablet: '/assets/images/downloads/68-charger-rt/68-charger-rt-tablet.jpg',
        desktop: '/assets/images/downloads/68-charger-rt/68-charger-rt-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Challenger Shakedown',
      src: {
        thumb: '/assets/images/downloads/dodge-challenger-shakedown/dodge-challenger-shakedown-thumb.jpg',
        watch: '/assets/images/downloads/dodge-challenger-shakedown/dodge-challenger-shakedown-watch.jpg',
        mobile: '/assets/images/downloads/dodge-challenger-shakedown/dodge-challenger-shakedown-mobile.jpg',
        tablet: '/assets/images/downloads/dodge-challenger-shakedown/dodge-challenger-shakedown-tablet.jpg',
        desktop: '/assets/images/downloads/dodge-challenger-shakedown/dodge-challenger-shakedown-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Those Flares Though',
      src: {
        thumb: '/assets/images/downloads/those-flares-though/those-flares-though-thumb.jpg',
        watch: '/assets/images/downloads/those-flares-though/those-flares-though-watch.jpg',
        mobile: '/assets/images/downloads/those-flares-though/those-flares-though-mobile.jpg',
        tablet: '/assets/images/downloads/those-flares-though/those-flares-though-tablet.jpg',
        desktop: '/assets/images/downloads/those-flares-though/those-flares-though-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Fever Scat Pack',
      src: {
        thumb: '/assets/images/downloads/dodge-fever-scat-pack/dodge-fever-scat-pack-thumb.jpg',
        watch: '/assets/images/downloads/dodge-fever-scat-pack/dodge-fever-scat-pack-watch.jpg',
        mobile: '/assets/images/downloads/dodge-fever-scat-pack/dodge-fever-scat-pack-mobile.jpg',
        tablet: '/assets/images/downloads/dodge-fever-scat-pack/dodge-fever-scat-pack-tablet.jpg',
        desktop: '/assets/images/downloads/dodge-fever-scat-pack/dodge-fever-scat-pack-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Challenger SRT Hellcat Widebody 2.0',
      src: {
        thumb: '/assets/images/downloads/challenger-srt-hellcat-widebody-2/challenger-srt-hellcat-widebody-2-thumb.jpg',
        watch: '/assets/images/downloads/challenger-srt-hellcat-widebody-2/challenger-srt-hellcat-widebody-2-watch.jpg',
        mobile: '/assets/images/downloads/challenger-srt-hellcat-widebody-2/challenger-srt-hellcat-widebody-2-mobile.jpg',
        tablet: '/assets/images/downloads/challenger-srt-hellcat-widebody-2/challenger-srt-hellcat-widebody-2-tablet.jpg',
        desktop: '/assets/images/downloads/challenger-srt-hellcat-widebody-2/challenger-srt-hellcat-widebody-2-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Plymouth Cuda',
      src: {
        thumb: '/assets/images/downloads/plymouth-cuda/plymouth-cuda-thumb.jpg',
        watch: '/assets/images/downloads/plymouth-cuda/plymouth-cuda-watch.jpg',
        mobile: '/assets/images/downloads/plymouth-cuda/plymouth-cuda-mobile.jpg',
        tablet: '/assets/images/downloads/plymouth-cuda/plymouth-cuda-tablet.jpg',
        desktop: '/assets/images/downloads/plymouth-cuda/plymouth-cuda-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Mopar 340',
      src: {
        thumb: '/assets/images/downloads/mopar-340/mopar-340-thumb.jpg',
        watch: '/assets/images/downloads/mopar-340/mopar-340-watch.jpg',
        mobile: '/assets/images/downloads/mopar-340/mopar-340-mobile.jpg',
        tablet: '/assets/images/downloads/mopar-340/mopar-340-tablet.jpg',
        desktop: '/assets/images/downloads/mopar-340/mopar-340-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Charger',
      src: {
        thumb: '/assets/images/downloads/dodge-charger/dodge-charger-thumb.jpg',
        watch: '/assets/images/downloads/dodge-charger/dodge-charger-watch.jpg',
        mobile: '/assets/images/downloads/dodge-charger/dodge-charger-mobile.jpg',
        tablet: '/assets/images/downloads/dodge-charger/dodge-charger-tablet.jpg',
        desktop: '/assets/images/downloads/dodge-charger/dodge-charger-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Dodge Challenger SRT Hellcat Widebody',
      src: {
        thumb: '/assets/images/downloads/dodge-challenger-srt-hellcat-widebody/dodge-challenger-srt-hellcat-widebody-thumb.jpg',
        watch: '/assets/images/downloads/dodge-challenger-srt-hellcat-widebody/dodge-challenger-srt-hellcat-widebody-watch.jpg',
        mobile: '/assets/images/downloads/dodge-challenger-srt-hellcat-widebody/dodge-challenger-srt-hellcat-widebody-mobile.jpg',
        tablet: '/assets/images/downloads/dodge-challenger-srt-hellcat-widebody/dodge-challenger-srt-hellcat-widebody-tablet.jpg',
        desktop: '/assets/images/downloads/dodge-challenger-srt-hellcat-widebody/dodge-challenger-srt-hellcat-widebody-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 11',
      src: {
        thumb: '/assets/images/downloads/charger-wb-11/charger-wb-11-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-11/charger-wb-11-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-11/charger-wb-11-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-11/charger-wb-11-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-11/charger-wb-11-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 12',
      src: {
        thumb: '/assets/images/downloads/charger-wb-12/charger-wb-12-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-12/charger-wb-12-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-12/charger-wb-12-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-12/charger-wb-12-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-12/charger-wb-12-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Production Widebody',
      src: {
        thumb: '/assets/images/downloads/production-widebody/production-widebody-thumb.jpg',
        watch: '/assets/images/downloads/production-widebody/production-widebody-smartwatch.jpg',
        mobile: '/assets/images/downloads/production-widebody/production-widebody-mobile.jpg',
        tablet: '/assets/images/downloads/production-widebody/production-widebody-tablet.jpg',
        desktop: '/assets/images/downloads/production-widebody/production-widebody-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Funny Car',
      src: {
        thumb: '/assets/images/downloads/funny-car/funny-car-thumb.jpg',
        watch: '/assets/images/downloads/funny-car/funny-car-smartwatch.jpg',
        mobile: '/assets/images/downloads/funny-car/funny-car-mobile.jpg',
        tablet: '/assets/images/downloads/funny-car/funny-car-tablet.jpg',
        desktop: '/assets/images/downloads/funny-car/funny-car-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 01',
      src: {
        thumb: '/assets/images/downloads/charger-wb-1/charger-wb-1-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-1/charger-wb-1-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-1/charger-wb-1-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-1/charger-wb-1-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-1/charger-wb-1-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 02',
      src: {
        thumb: '/assets/images/downloads/charger-wb-2/charger-wb-2-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-2/charger-wb-2-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-2/charger-wb-2-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-2/charger-wb-2-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-2/charger-wb-2-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 03',
      src: {
        thumb: '/assets/images/downloads/charger-wb-3/charger-wb-3-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-3/charger-wb-3-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-3/charger-wb-3-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-3/charger-wb-3-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-3/charger-wb-3-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 04',
      src: {
        thumb: '/assets/images/downloads/charger-wb-4/charger-wb-4-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-4/charger-wb-4-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-4/charger-wb-4-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-4/charger-wb-4-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-4/charger-wb-4-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 05',
      src: {
        thumb: '/assets/images/downloads/charger-wb-5/charger-wb-5-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-5/charger-wb-5-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-5/charger-wb-5-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-5/charger-wb-5-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-5/charger-wb-5-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 06',
      src: {
        thumb: '/assets/images/downloads/charger-wb-6/charger-wb-6-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-6/charger-wb-6-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-6/charger-wb-6-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-6/charger-wb-6-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-6/charger-wb-6-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 07',
      src: {
        thumb: '/assets/images/downloads/charger-wb-7/charger-wb-7-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-7/charger-wb-7-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-7/charger-wb-7-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-7/charger-wb-7-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-7/charger-wb-7-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 08',
      src: {
        thumb: '/assets/images/downloads/charger-wb-8/charger-wb-8-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-8/charger-wb-8-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-8/charger-wb-8-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-8/charger-wb-8-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-8/charger-wb-8-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 09',
      src: {
        thumb: '/assets/images/downloads/charger-wb-9/charger-wb-9-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-9/charger-wb-9-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-9/charger-wb-9-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-9/charger-wb-9-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-9/charger-wb-9-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Charger Wide Body 10',
      src: {
        thumb: '/assets/images/downloads/charger-wb-10/charger-wb-10-thumb.jpg',
        watch: '/assets/images/downloads/charger-wb-10/charger-wb-10-smartwatch.jpg',
        mobile: '/assets/images/downloads/charger-wb-10/charger-wb-10-mobile.jpg',
        tablet: '/assets/images/downloads/charger-wb-10/charger-wb-10-tablet.jpg',
        desktop: '/assets/images/downloads/charger-wb-10/charger-wb-10-desktop.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Angry Bee',
      src: {
        thumb: '/assets/images/downloads/thumbs/angry-bee.jpg',
        watch: '/assets/images/downloads/smart-watch/angry-bee.jpg',
        mobile: '/assets/images/downloads/mobile/angry-bee.jpg',
        tablet: '/assets/images/downloads/tablet/angry-bee.jpg',
        desktop: '/assets/images/downloads/desktop/angry-bee.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Demon Hellcat',
      src: {
        thumb: '/assets/images/downloads/thumbs/demon-hellcat.jpg',
        watch: '/assets/images/downloads/smart-watch/demon-hellcat.jpg',
        mobile: '/assets/images/downloads/mobile/demon-hellcat.jpg',
        tablet: '/assets/images/downloads/tablet/demon-hellcat.jpg',
        desktop: '/assets/images/downloads/desktop/demon-hellcat.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Funny Car Angry Bee',
      src: {
        thumb: '/assets/images/downloads/thumbs/funny-car-angry-bee.jpg',
        watch: '/assets/images/downloads/smart-watch/funny-car-angry-bee.jpg',
        mobile: '/assets/images/downloads/mobile/funny-car-angry-bee.jpg',
        tablet: '/assets/images/downloads/tablet/funny-car-angry-bee.jpg',
        desktop: '/assets/images/downloads/desktop/funny-car-angry-bee.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Heaven and Hell',
      src: {
        thumb: '/assets/images/downloads/thumbs/heaven-and-hell.jpg',
        watch: '/assets/images/downloads/smart-watch/heaven-and-hell.jpg',
        mobile: '/assets/images/downloads/mobile/heaven-and-hell.jpg',
        tablet: '/assets/images/downloads/tablet/heaven-and-hell.jpg',
        desktop: '/assets/images/downloads/desktop/heaven-and-hell.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Lightair Catcher',
      src: {
        thumb: '/assets/images/downloads/thumbs/lightair-catcher.jpg',
        watch: '/assets/images/downloads/smart-watch/lightair-catcher.jpg',
        mobile: '/assets/images/downloads/mobile/lightair-catcher.jpg',
        tablet: '/assets/images/downloads/tablet/lightair-catcher.jpg',
        desktop: '/assets/images/downloads/desktop/lightair-catcher.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Redeye Widebody',
      src: {
        thumb: '/assets/images/downloads/thumbs/redeye-widebody.jpg',
        watch: '/assets/images/downloads/smart-watch/redeye-widebody.jpg',
        mobile: '/assets/images/downloads/mobile/redeye-widebody.jpg',
        tablet: '/assets/images/downloads/tablet/redeye-widebody.jpg',
        desktop: '/assets/images/downloads/desktop/redeye-widebody.jpg',
      },
      isLoaded: false
    },
    {
      name: 'srtmush',
      src: {
        thumb: '/assets/images/downloads/thumbs/srtmush.jpg',
        watch: '/assets/images/downloads/smart-watch/srtmush.jpg',
        mobile: '/assets/images/downloads/mobile/srtmush.jpg',
        tablet: '/assets/images/downloads/tablet/srtmush.jpg',
        desktop: '/assets/images/downloads/desktop/srtmush.jpg',
      },
      isLoaded: false
    },
    {
      name: 'srtmush 2',
      src: {
        thumb: '/assets/images/downloads/thumbs/srtmush-2.jpg',
        watch: '/assets/images/downloads/smart-watch/srtmush-2.jpg',
        mobile: '/assets/images/downloads/mobile/srtmush-2.jpg',
        tablet: '/assets/images/downloads/tablet/srtmush-2.jpg',
        desktop: '/assets/images/downloads/desktop/srtmush-2.jpg',
      },
      isLoaded: false
    },
    {
      name: 'Demon Badges',
      src: {
        thumb: '/assets/images/downloads/thumbs/demon-badges.jpg',
        watch: '/assets/images/downloads/smart-watch/demon-badges.jpg',
        mobile: '/assets/images/downloads/mobile/demon-badges.jpg',
        tablet: '/assets/images/downloads/tablet/demon-badges.jpg',
        desktop: '/assets/images/downloads/desktop/demon-badges.jpg',
        full: '/assets/images/downloads/full-size/demon-badges.jpg'
      },
      isLoaded: false
    },
    {
      name: 'Demon Burnout',
      src: {
        thumb: '/assets/images/downloads/thumbs/demon-burnout.jpg',
        watch: '/assets/images/downloads/smart-watch/demon-burnout.jpg',
        mobile: '/assets/images/downloads/mobile/demon-burnout.jpg',
        tablet: '/assets/images/downloads/tablet/demon-burnout.jpg',
        desktop: '/assets/images/downloads/desktop/demon-burnout.jpg',
        full: '/assets/images/downloads/full-size/demon-burnout.jpg'
      },
      isLoaded: false
    },
    {
      name: 'Demon Launch 2',
      src: {
        thumb: '/assets/images/downloads/thumbs/demon-launch-2.jpg',
        watch: '/assets/images/downloads/smart-watch/demon-launch-2.jpg',
        mobile: '/assets/images/downloads/mobile/demon-launch-2.jpg',
        tablet: '/assets/images/downloads/tablet/demon-launch-2.jpg',
        desktop: '/assets/images/downloads/desktop/demon-launch-2.jpg',
        full: '/assets/images/downloads/full-size/demon-launch-2.jpg'
      },
      isLoaded: false
    },
    {
      name: 'Demon Launch',
      src: {
        thumb: '/assets/images/downloads/thumbs/demon-launch.jpg',
        watch: '/assets/images/downloads/smart-watch/demon-launch.jpg',
        mobile: '/assets/images/downloads/mobile/demon-launch.jpg',
        tablet: '/assets/images/downloads/tablet/demon-launch.jpg',
        desktop: '/assets/images/downloads/desktop/demon-launch.jpg',
        full: '/assets/images/downloads/full-size/demon-launch.jpg'
      },
      isLoaded: false
    },
    {
      name: 'Demon Poster',
      src: {
        thumb: '/assets/images/downloads/thumbs/demon-poster.jpg',
        watch: '/assets/images/downloads/smart-watch/demon-poster.jpg',
        mobile: '/assets/images/downloads/mobile/demon-poster.jpg',
        tablet: '/assets/images/downloads/tablet/demon-poster.jpg',
        desktop: '/assets/images/downloads/desktop/demon-poster.jpg',
        full: '/assets/images/downloads/full-size/demon-poster.jpg'
      },
      isLoaded: false
    },
    {
      name: 'Hellcat Logo Evolution',
      src: {
        thumb: '/assets/images/downloads/thumbs/hellcat-logo-evolution.jpg',
        watch: '/assets/images/downloads/smart-watch/hellcat-logo-evolution.jpg',
        mobile: '/assets/images/downloads/mobile/hellcat-logo-evolution.jpg',
        tablet: '/assets/images/downloads/tablet/hellcat-logo-evolution.jpg',
        desktop: '/assets/images/downloads/desktop/hellcat-logo-evolution.jpg',
        full: '/assets/images/downloads/full-size/hellcat-logo-evolution.jpg'
      },
      isLoaded: false
    },
    {
      name: 'Hellcat Burnout',
      src: {
        thumb: '/assets/images/downloads/thumbs/hellcat-burnout.jpg',
        watch: '/assets/images/downloads/smart-watch/hellcat-burnout.jpg',
        mobile: '/assets/images/downloads/mobile/hellcat-burnout.jpg',
        tablet: '/assets/images/downloads/tablet/hellcat-burnout.jpg',
        desktop: '/assets/images/downloads/desktop/hellcat-burnout.jpg',
        full: '/assets/images/downloads/full-size/hellcat-burnout.jpg'
      },
      isLoaded: false
    },
  ]

  ringtones = [
    {
      name: 'SRT Demon',
      model: 'Dodge Challenger',
      background: '/assets/images/ringtones/srt-demon-bg.jpg',
      files: {
        mp3: '/assets/audio/challenger-srt-demon-ringtone.mp3',
        m4r: '/assets/audio/challenger-srt-demon-ringtone.m4r',
        ogg: '/assets/audio/challenger-srt-demon-ringtone.ogg'
      },
      isLoaded: false
    },
    {
      name: 'SRT Hellcat',
      model: 'Dodge Challenger',
      background: '/assets/images/ringtones/srt-hellcat-bg.jpg',
      files: {
        mp3: '/assets/audio/challenger-srt-hellcat-ringtone.mp3',
        m4r: '/assets/audio/challenger-srt-hellcat-ringtone.m4r',
        ogg: '/assets/audio/challenger-srt-hellcat-ringtone.ogg'
      },
      isLoaded: false
    }
  ]

  stencils = [
    {
      name: 'Challenger Logo',
      thumbnail: '/assets/images/stencils/challenger-stencil.jpg',
      pdf: '/assets/images/stencils/challenger-stencil.pdf',
      isLoaded: false
    },
    {
      name: 'Charger Logo',
      thumbnail: '/assets/images/stencils/charger-stencil.jpg',
      pdf: '/assets/images/stencils/charger-stencil.pdf',
      isLoaded: false
    },
    {
      name: 'Dragpak Logo',
      thumbnail: '/assets/images/stencils/dragpack-stencil.jpg',
      pdf: '/assets/images/stencils/dragpack-stencil.pdf',
      isLoaded: false
    },
    {
      name: 'Durango Logo',
      thumbnail: '/assets/images/stencils/durango-stencil.jpg',
      pdf: '/assets/images/stencils/durango-stencil.pdf',
      isLoaded: false
    },
    {
      name: 'Fratzog Logo',
      thumbnail: '/assets/images/stencils/fratzog-stencil.jpg',
      pdf: '/assets/images/stencils/fratzog-stencil.pdf',
      isLoaded: false
    },
    {
      name: 'Dodge Logo',
      thumbnail: '/assets/images/stencils/stencil-dodge-rhombus.jpg',
      pdf: '/assets/images/stencils/dodge-rhombus.pdf',
      isLoaded: false
    },
    {
      name: 'Dodge Rhombus',
      thumbnail: '/assets/images/stencils/stencil-rhombus.jpg',
      pdf: '/assets/images/stencils/rhombus.pdf',
      isLoaded: false
    },
    {
      name: 'SRT Demon Logo',
      thumbnail: '/assets/images/stencils/stencil-demon.jpg',
      pdf: '/assets/images/stencils/demon.pdf',
      isLoaded: false
    },
    {
      name: 'SRT Hellcat Logo',
      thumbnail: '/assets/images/stencils/stencil-hellcat.jpg',
      pdf: '/assets/images/stencils/hellcat.pdf',
      isLoaded: false
    },
    {
      name: 'Angry Bee Logo',
      thumbnail: '/assets/images/stencils/stencil-angry-bee.jpg',
      pdf: '/assets/images/stencils/angry-bee.pdf',
      isLoaded: false
    },
    {
      name: 'Scat Pack Logo',
      thumbnail: '/assets/images/stencils/stencil-scat-pak-bee.jpg',
      pdf: '/assets/images/stencils/scat-pack-bee.pdf',
      isLoaded: false
    }
  ]

  holidayCards = [
    {
      name: 'Brotherhood',
      thumbnail: '/assets/images/holiday-cards/thumb-dodge-holiday-card-brotherhood.jpg',
      img: '/assets/images/holiday-cards/dodge-holiday-card-brotherhood.jpg',
      isLoaded: false
    },
    {
      name: 'Kick Ass',
      thumbnail: '/assets/images/holiday-cards/thumb-dodge-holiday-card-kickass.jpg',
      img: '/assets/images/holiday-cards/dodge-holiday-card-kickass.jpg',
      isLoaded: false
    },
    {
      name: 'New Toy',
      thumbnail: '/assets/images/holiday-cards/thumb-dodge-holiday-card-new-toy.jpg',
      img: '/assets/images/holiday-cards/dodge-holiday-card-new-toy.jpg',
      isLoaded: false
    },
    {
      name: 'Creature is stirring',
      thumbnail: '/assets/images/holiday-cards/thumb-dodge-holiday-card-stirring.jpg',
      img: '/assets/images/holiday-cards/dodge-holiday-card-stirring.jpg',
      isLoaded: false
    },
    {
      name: 'Untamable Holiday Spirit',
      thumbnail: '/assets/images/holiday-cards/thumb-dodge-holiday-card-untamable.jpg',
      img: '/assets/images/holiday-cards/dodge-holiday-card-untamable.jpg',
      isLoaded: false
    }
  ];

  birthdayCards = [
    {
      name: 'bday-ass-in-gear',
      download: '/assets/images/downloads/birthday-cards/2018/bday-ass-in-gear/bday-ass-in-gear.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2018/bday-ass-in-gear/bday-ass-in-gear-sm.jpg',
      isLoaded: false
    },
    {
      name: 'bday-drag',
      download: '/assets/images/downloads/birthday-cards/2018/bday-drag/bday-drag.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2018/bday-drag/bday-drag-sm.jpg',
      isLoaded: false
    },
    {
      name: 'bday-night',
      download: '/assets/images/downloads/birthday-cards/2018/bday-night/bday-night.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2018/bday-night/bday-night-sm.jpg',
      isLoaded: false
    },
    {
      name: 'bday-smoke',
      download: '/assets/images/downloads/birthday-cards/2018/bday-smoke/bday-smoke.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2018/bday-smoke/bday-smoke-sm.jpg',
      isLoaded: false
    },
    {
      name: 'bday-vintage-1',
      download: '/assets/images/downloads/birthday-cards/2018/bday-vintage-1/bday-classic-vinatge.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2018/bday-vintage-1/bday-classic-vinatge-sm.jpg',
      isLoaded: false
    },
    {
      name: 'bday-vintage-3',
      download: '/assets/images/downloads/birthday-cards/2018/bday-vintage-3/bday-vintage-3.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2018/bday-vintage-3/bday-vintage-3-sm.jpg',
      isLoaded: false
    },
    {
      name: 'dodge-garage-birthday-50th',
      download: '/assets/images/downloads/birthday-cards/2020/dodge-garage-birthday-50th.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2020/dodge-garage-birthday-50th-sm.jpg',
      isLoaded: false
    },
    {
      name: 'dodge-garage-birthday-belated',
      download: '/assets/images/downloads/birthday-cards/2020/dodge-garage-birthday-belated.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2020/dodge-garage-birthday-belated-sm.jpg',
      isLoaded: false
    },
    {
      name: 'dodge-garage-birthday-outrun-01',
      download: '/assets/images/downloads/birthday-cards/2020/dodge-garage-birthday-outrun-01.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2020/dodge-garage-birthday-outrun-01-sm.jpg',
      isLoaded: false
    },
    {
      name: 'dodge-garage-birthday-super-bee',
      download: '/assets/images/downloads/birthday-cards/2020/dodge-garage-birthday-super-bee.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2020/dodge-garage-birthday-super-bee-sm.jpg',
      isLoaded: false
    },
    {
      name: 'dodge-garage-birthday-tame',
      download: '/assets/images/downloads/birthday-cards/2020/dodge-garage-birthday-tame.jpg',
      imgSrc: '/assets/images/downloads/birthday-cards/2020/dodge-garage-birthday-tame-sm.jpg',
      isLoaded: false
    }
  ]



  //Get all data
  getWallpapers: Function = () => this.wallpapers
  getRingtones: Function = () => this.ringtones
  getStencils: Function = () => this.stencils
  getHolidayCards: Function = () => this.holidayCards
  getBirthdayCards: Function = () => this.birthdayCards;

  getDownloads(type = 'all'): Observable <any> {
    return this.http.get('assets/json/downloads.json').pipe(
      map((items: Array<any>) => {
        if (type != 'all') {
          return items.find(items => items.type == type);
        } else {
          return items
        }
      }, error => error)
    );
  }

  getSchemaWallpaperArray(items: Array<any>) {
    return items.map(item => {
      return {
        "@type": "ImageObject",
        "thumbnail": environment.url + item.src.thumb,
        "name" : item.name,
        "url": environment.url + item.src.desktop,
        "width": 2560,
        "height": 1440
      }
    });
  }

  getSchemaPDFArray(items: Array<any>) {
    return items.map(item => {
      return {
        "@type": "DigitalDocument",
        "thumbnailUrl": environment.url + item.thumbnail,
        "name" : item.name,
        "author" : "DodgeGarage",
        "url": environment.url + item.download,
      }
    });
  }

  getSchemaImageArray(items: Array<any>) {
    return items.map(item => {
      return {
        "@type": "ImageObject",
        "thumbnail": environment.url + item.imgSrc,
        "name" : item.name,
        "url": environment.url + item.download,
      }
    });
  }

  getSchemaAudioArray(items: Array<any>) {
    return items.map(item => {
      return {
        "@type": "AudioObject",
        "name": item.name,
        "encodingFormat": "audio/mpeg",
        "url": environment.url + item.files.mp3,
        "contentUrl": environment.url + item.files.mp3,
      }
    });
  }
}
