import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
declare let ga: Function;

@Component({
  selector: 'color-swatches',
  templateUrl: './color-swatches.component.html'
})
export class ColorSwatchesComponent implements OnInit {

  @Input() colors: Array<any>;
  @Output() emitActiveColorCode = new EventEmitter<string>();
  activeColor: String;

  constructor() {}

  ngOnInit(): void {
    // loop through array and set active color as default
    this.colors.forEach(item => {
      if (item.active) { this.activeColor = item.code }
    });
  }

  updateActiveColor(code) {
    this.activeColor = code;
    this.emitActiveColorCode.emit(code);
    ga('send', 'event', 'Demon_170-Landing', 'Click', 'Exterior Color Swatch - ' + code);
  }
}
