export class Video {
    id: number = 0;
    name: string = '';
    description: string = '';
    uri: string = '';
    thumbnail: string = '';
    thumbnailWithPlayButton: string = '';
    createdTime: string = '';
    embedCode: any = '';
    isLoaded: boolean = false;
}
