import { Component, Input, OnInit, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {SwiperComponent} from "swiper/angular";
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCard implements OnInit {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  @Input() product: Object;
  @ViewChild('swiper') swiper?: SwiperComponent;
  showControls: boolean = true;
  activeIndex: any = 0;

  config: SwiperOptions = {
    slidesPerView: 1,
    allowTouchMove: false
  };

  ngOnInit() {
    this.product['images'].length > 1 ? this.showControls = true : this.showControls = false;
  }

  slidePrev() { this.swiper?.swiperRef.slidePrev(); }
  slideNext() { this.swiper?.swiperRef.slideNext(); }
  slideTo(index) { this.swiper?.swiperRef.slideTo(index); }
  slideChange(event) { this.activeIndex = event.activeIndex; }

}
