export const imageGallery: Array<any> = [
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 01",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_01.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 02",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_02.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 03",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_03.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 04",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_04.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 05",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_05.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 06",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_06.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 07",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_07.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 08",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_08.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 09",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_09.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 10",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_10.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 11",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_11.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 12",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_12.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 13",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_13.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 14",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_14.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 15",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_15.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 16",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_16.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 17",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_17.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 18",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_18.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 19",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_19.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 20",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_20.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 21",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_21.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 22",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_22.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 23",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_23.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 24",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_24.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 25",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_25.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 26",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_26.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 27",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_27.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 28",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_28.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 29",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_29.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 30",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_30.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 31",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_31.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 32",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_32.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 33",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_33.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 34",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_34.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 35",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_35.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 36",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_36.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 37",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_37.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 38",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_38.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 39",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_39.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 40",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_40.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 41",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_41.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 42",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_42.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 43",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_43.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 44",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_44.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 45",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_45.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 46",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_46.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 47",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_47.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 48",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_48.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 49",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_49.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 50",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_50.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 51",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_51.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 52",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_52.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 53",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_53.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 54",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_54.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 55",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_55.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 56",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_56.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 57",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_57.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 58",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_58.webp"
  },
  {
    alt: "2023 Challenger SRT Demon 170 - Gallery Image 59",
    image: "/assets/images/demon-170/gallery/MY23-Dodge-Challenger-SRT-Demon-170_59.webp"
  }
]

export const imageGalleryInterior: Array<any> = [
  {
    image: "/assets/images/demon-170/interior/LA_2023_Demon-170_SRT_HellCat_Black_Cloth_CF_01.webp",
    thumb: "/assets/images/demon-170/interior/LA_2023_Demon-170_SRT_HellCat_Black_Cloth_CF_01-thumb.webp",
    title: "Base Cloth Interior (Passenger/Rear Seat Delete)",
    price: '0 ($0 CAD)'
  },
  {
    image: "/assets/images/demon-170/interior/LA_2023_Demon-170_SRT_HellCat_Black_Cloth_CF 2 Seats.webp",
    thumb: "/assets/images/demon-170/interior/LA_2023_Demon-170_SRT_HellCat_Black_Cloth_CF 2 Seats-thumb.webp",
    title: "Full Cloth Interior (Pass/Rear)",
    price: '1,995 ($2.595 CAD)',
    package: [
      "Cloth front passenger seat",
      "Cloth rear seat"
    ]
  },
  {
    image: "/assets/images/demon-170/interior/LA_2023_Demon-170_SRT_HellCat_Black_Laguna_leather_CF.webp",
    thumb: "/assets/images/demon-170/interior/LA_2023_Demon-170_SRT_HellCat_Black_Laguna_leather_CF-thumb.webp",
    title: "Premium Group - Black Alcantara/Laguna",
    price: "5,995 ($8,095 CAD)",
    package: [
      "Laguna/alcantara performance seats w/logo",
      "Ventilated front seats",
      "Heated front seats",
      "Heated steering wheel",
      "Power tilt/telescope steering column",
      "Radio/driver seat/mirrors memory",
      "Suede headliner",
      "Premium stitched dash panel",
      "Suede door trim panel w/map pocket lamp",
      "18 speakers harman kardon audio w/subwoofer",
      "Surround sound",
      "Rain-sensitive windshield wipers",
      "Auto high beam headlamp control",
      "Blind spot and cross path detection",
      "Power multi-function f/away mirrors",
      "Exterior mirrors w/heating element",
      "Trunk Dress Kit"
    ]
  },
  {
    image: "/assets/images/demon-170/interior/LA_2023_Demon-170_SRT_HellCat_Black-DemonicRed_Laguna_leather_CF.webp",
    thumb: "/assets/images/demon-170/interior/LA_2023_Demon-170_SRT_HellCat_Black-DemonicRed_Laguna_leather_CF-thumb.webp",
    title: "Demonic Red Laguna Leather Interior",
    disclaimer: "Must purchase Premium Group",
    price: "0 ($0 CAD)",
    package: [
      "Demonic red laguna performance seats w/logo",
      "Demonic red laguna door trim panel",
      "Trunk Dress Kit"
    ]
  }
]
