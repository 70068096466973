import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RoadkillNightsVideoService {

  constructor(public http: HttpClient) { }

  // Get videos by playlist
  getVideosForPlaylist(playlistId, maxResults = 50): Observable<Object> {
    let url = `https://youtube.googleapis.com/youtube/v3/playlistItems?key=${environment.rknYouTubeKey}&playlistId=${playlistId}&order=date&part=snippet&maxResults=${maxResults}`
    return this.http.get(url).pipe(map((res) => { return res; }))
  }
  
  // Access folder in WP with build images
  getBuildGalleryImages(folder): Observable<Object> {
    let url = `${environment.rknWpURL}filebird/public/v1/attachment-id/?folder_id=${folder}`

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${environment.rknWPKey}`
    });

    return this.http.get(url, {headers: headers}).pipe(map((res) => { return res; }))
  }

  // Get specific image based off what was returned from getBuildGalleryImages method
  getBuildGalleryImage(id): Observable<Object> {
    let url = `${environment.rknWpURL}wp/v2/media/${id}`
    return this.http.get(url).pipe(map((res) => { return res; }))
  }

  getVideoPrivacyStatus(id): Observable<Object> {
    let url = `https://youtube.googleapis.com/youtube/v3/videos?key=${environment.rknYouTubeKey}&id=${id}&part=status`;
    return this.http.get(url).pipe(map((res) => { return res; }));
  }
  
  
}
