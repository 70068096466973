import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Post} from "../post";
import {CommentService} from "../comment.service";
import {UserService} from "../../user/user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
  selector: 'dg-flag-comment',
  templateUrl: './flag-comment.component.html',
  styleUrls: ['./flag-comment.component.less']
})
export class FlagCommentComponent implements OnInit, OnDestroy {

  @Input() post: Post;
  @Output() flagCommentEmitter = new EventEmitter();
  @Output() cancelFlagEmitter = new EventEmitter();

  flagCommentAlert = {
    show: false,
    status: '',
    message: '',
    additionalMessage: ''
  };

  flagCommentForm: FormGroup;
  reasonChanges: Subscription;
  isLoading: boolean = false;

  // Discourse Flag Reasons
  // 3 = Off Topic
  // 4 = Inappropriate
  // 8 = Spam
  // 7 = Something Else

  constructor(
    private commentsService: CommentService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.createFlagCommentForm();
    this.subscribeReasonChanges();
  }

  ngOnDestroy() {
    if (this.reasonChanges) {
      this.reasonChanges.unsubscribe();
    }
  }

  createFlagCommentForm() {
    this.flagCommentForm = new FormGroup({
      'flagReason': new FormControl('', [
        Validators.required
      ]),
      'customReason': new FormControl('')
    });
  }

  subscribeReasonChanges() {
    // initialize value changes stream
    let changes$ = (<any>this.flagCommentForm).controls.flagReason.valueChanges;
    // subscribe to the stream
    this.reasonChanges = changes$.subscribe(reasonType => {
      if (reasonType == 7) {
        this.flagCommentForm.get('customReason').setValidators([Validators.required]);
        this.flagCommentForm.get('customReason').updateValueAndValidity();
      } else {
        this.flagCommentForm.get('customReason').clearValidators();
        this.flagCommentForm.get('customReason').updateValueAndValidity();
      }
    });
  }

  flagPost() {
    Object.keys(this.flagCommentForm.controls).forEach(field => {
      const control = this.flagCommentForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.flagCommentForm.valid) { // If form is valid, proceed
      this.isLoading = true;
      this.commentsService.flagPost(this.post, this.userService.userModel.username, this.flagCommentForm.controls.flagReason.value, this.flagCommentForm.controls.customReason.value).subscribe(data => {
        // TODO add message to comment that the comment is now flagged.
        this.isLoading = false;
        this.flagCommentEmitter.emit(data);
      }, errors => {
        this.isLoading = false;
        this.flagCommentAlert.show = true;
        this.flagCommentAlert.status = 'danger';
        this.flagCommentAlert.message = 'There was an error flagging the comment.';
      });
    }
  }

  cancelFlag() {
    this.cancelFlagEmitter.emit('cancel');
  }

}
