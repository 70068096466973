
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { parseString } from "xml2js";

@Injectable()
export class PodcastService {

  podcastUrl = 'https://www.podcastone.com/podcast?categoryID2=320';

  constructor(
    private http: HttpClient
  ) { }

  getAllPodcasts() {
    return this.http.get(this.podcastUrl, { responseType: 'text' }).pipe(
      map(res => {
        let data;
        console.log(res);
        parseString( res, function (err, result) {
          console.dir(result.rss.channel["0"].item); //Prints JSON object!
          data = result.rss.channel["0"].item;
        });
        return data;
      }));
  }

  getPodcastsByDay(day, count = 0) {
    return this.http.get(this.podcastUrl, { responseType: 'text' }).pipe(
      map(res => {
        let data;
        parseString( res, function (err, result) {
          //console.dir(result.rss.channel[0]); //Prints JSON object!
          data = result.rss.channel[0].item.filter( item => {
            return item.pubDate[0].includes(day);
          });
          if (count > 0) {
            data = data.slice(0, count);
          }
          // Retrieve the image
          data.forEach(item => {
            item.image = result.rss.channel[0].image[0].url[0];
          });
        });
        return data;
      }));
  }

  mutatePodcast(podcast) {
    podcast.title = podcast['title'][0];
    podcast.description = podcast['description'][0];
    podcast.src = podcast['link'][0];
    podcast.date = new Date(podcast['pubDate'][0]);
    podcast.isPlaying = false;
    return podcast;
  }

}
