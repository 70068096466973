import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Post} from "../post";
import {Subscription} from "rxjs";
import {UserService} from "../../user/user.service";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {CommentService} from "../comment.service";

declare let ga: any

@Component({
  selector: 'dg-single-comment',
  templateUrl: './single-comment.component.html',
  styleUrls: ['./single-comment.component.less']
})
export class SingleCommentComponent implements OnInit, OnDestroy {

  @Input() post: Post = new Post();
  @Input() ancestorType: number = 0;
  @Output() replyToPostEmitter = new EventEmitter();
  @Output() editCommentEmitter = new EventEmitter();
  @Output() postCountEmitter = new EventEmitter();

  @ViewChild('userModal', { static: true }) public userModal: TemplateRef<any>;
  userModalRef: BsModalRef;
  deleteModalRef: BsModalRef;
  flagModalRef: BsModalRef;

  // User login status
  loggedInSub: Subscription;

  constructor(
    private commentService: CommentService,
    private modalService: BsModalService,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.loggedInSub = this.userService.loggedIn$.subscribe(loggedIn => {
      if (this.userService.userModel.isLoggedIn) {
        // Subscribe to when the user logs in
        //console.log(this.post);
      }
    });
    this.fetch1320Status(this.post.username);
    if (this.post.cooked.includes('href="/user/')) {
      let regExp = /<a class="mention" \b[^>]*>(.*?)<\/a>/g;
      let mentionArray = [];
      while (mentionArray = regExp.exec(this.post.cooked)) {
        this.fetchMention1320Status(mentionArray);
      }
    }
  }

  ngOnDestroy() {
    if (this.loggedInSub)
      this.loggedInSub.unsubscribe();
  }

  fetch1320Status(username: string) {
    this.userService.getUserByUsername(username).subscribe(data => {
      if (!data.status || data.status != 'error') {
        this.post.user = data;
      }
    });
  }

  fetchMention1320Status(mention) {
    let mentionUsername = mention[0].split('href="/user/').pop().split('"')[0];
    this.userService.getUserByUsername(mentionUsername).subscribe(data => {
      if (data.status && data.status == 'error') { // If the link is not to a valid user
        let mentionURL = mention[0].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        this.post.cooked = this.post.cooked.replace(new RegExp(mentionURL, 'g'), mention[1]);
      }
    });
  }

  replyToPost(post) {
    if (this.userService.userModel.isLoggedIn) {
      post.showTextArea = !post.showTextArea;
      post.showEditArea = false;
    } else {
      this.openUserModal();
    }
  }

  likeComment(post: Post) {
    // User cannot repeatedly like / unlike a post. Also has a window for how long a user can change after certain time period: https://meta.discourse.org/t/at-what-point-can-you-no-longer-like-or-unlike-a-post/57141/7
    if (this.userService.userModel.isLoggedIn) {
      this.commentService.likePostByPostId (post.id, this.userService.userModel.username).subscribe (data => {

        let action_summary = data.actions_summary.filter(category => category.id == 2).shift();
        post.likesCount = action_summary.count ? action_summary.count : 0;
        post.userActed = action_summary.acted ? action_summary.acted: false;
        post.userCanAct = action_summary.can_act ? action_summary.can_act: false;
        post.userCanUndo = action_summary.can_undo ? action_summary.can_undo: false;

        ga('send', {
          hitType: 'event',
          eventCategory: post.topic.polymorphicType + ': ' + post.topic.title,
          eventAction: 'Comment',
          eventLabel: 'Liking a Comment'
        });

      }, error => {
        //console.log(error);
      });
    } else {
      this.openUserModal();
    }
  }

  unLikeComment(post: Post) {
    // User cannot repeatedly like / unlike a post. Also has a window for how long a user can change after certain time period: https://meta.discourse.org/t/at-what-point-can-you-no-longer-like-or-unlike-a-post/57141/7
    this.commentService.unlikePostByPostId(post.id, this.userService.userModel.username).subscribe( data => {

      let action_summary = data.actions_summary.filter(category => category.id == 2).shift();
      post.likesCount = action_summary.count ? action_summary.count : 0;
      post.userActed = action_summary.acted ? action_summary.acted: false;
      post.userCanAct = action_summary.can_act ? action_summary.can_act: false;
      post.userCanUndo = action_summary.can_undo ? action_summary.can_undo: false;

    }, errors => {
      //console.log(errors);
    });
  }

  editComment(post) {
    post.showEditArea = !post.showEditArea;
    post.showTextArea = false;
  }

  addNewComment(event, thisPost: Post = new Post()) {
    event.user = this.userService.userModel;
    let newPost = this.commentService.processPostAndReplies(event, null, thisPost.topic);
    if (!thisPost.replies) { // Create replies array if first reply
      thisPost.replies = [];
    }
    thisPost.reply_count++;
    thisPost.replies.unshift(newPost);
    thisPost.showTextArea = false;
    thisPost.show_replies = true;
    //this.postCount++;
    this.postCountEmitter.emit(newPost);
  }

  commentUpdated(event, post: Post = new Post()) {
    post.cooked = event.post.cooked;
    post.updated_at = event.post.updated_at;
    post.showEditArea = false;
  }

  commentDeleted(event) {
    this.post.cooked = event.cooked;
    this.post.isDeleted = true;
    if (this.deleteModalRef)
      this.deleteModalRef.hide();
  }

  commentFlagged(event) {
    this.post.isFlaggedByUser = true;
    if (this.flagModalRef) {
      this.flagModalRef.hide();
    }
  }

  openUserModal() {
    this.userModalRef = this.modalService.show(this.userModal, {class: 'modal-full'})
  }

  closeUserModal() {
    // Add any magic to fire when closing the modal
    if (this.userModalRef) {
      this.userModalRef.hide();
    }
  }

  openDeleteCommentModal(template: TemplateRef<any>) {
    this.deleteModalRef = this.modalService.show(template)
  }

  openFlagCommentModal(template: TemplateRef<any>) {
    if (this.userService.userModel.isLoggedIn) {
      this.flagModalRef = this.modalService.show(template);
    } else {
      this.openUserModal();
    }

  }

  postCountIncrease(event) {
    this.postCountEmitter.emit(event)
  }

}
