<div class="hero-video">
  <div class="site-wrapper skinny-site-wrapper">
    <div class="container-fluid">
      <h1 *ngIf="headline" class="hero-headline" [innerHTML]="headline">Video Hero Headline</h1>
    </div>
  </div>
  <video class="bg-video" #videoPlayer autoplay muted playsinline loop>
    <source [src]="videoSrc" type="video/mp4">
  </video>
  <div class="controls">
    <button class="overlay-control" (click)="playPause()">
      <i *ngIf="!videoPlaying" class="fa fa-play"></i>
      <i *ngIf="videoPlaying" class="fa fa-pause"></i>
    </button>
  </div>
</div>