import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DragPakLists {
  overview: any = [
    {
      'title': 'ENGINE',
      'bullets': [
        '354 cu. in. HEMI<sup>&reg;</sup> V8 engine',
        'Block: Mopar<sub>&reg;</sub> cast aluminum block with billet steel main caps',
        'Bore &amp; Stroke: 4.070" x 3.400"',
        'Crankshaft: Billet',
        'Rods: Forged H-beam',
        'Pistons: Forged 2618 alloy',
        'Camshaft: Mopar<sub>&reg;</sub> spec with 0.675" lift'
      ]
    },
    {
      'title': 'TRANSMISSION',
      'bullets': [
        'Automatic – TH400 with SFI case',
        'Race-ready 3-speed Turbo-400 Transmission with lightweight aluminum driveshaft'
      ]
    },
    {
      'title': 'TORQUE CONVERTER',
      'bullets': [
        '10" (258mm) high performance torque converter'
      ]
    },
    {
      'title': 'VALVETRAIN',
      'bullets': [
        'Tappets: Mechanical roller',
        'Rocker arms: 1.80:1 In, 1.85:1 Ex ratio',
        'Valve Springs: Dual drag race spring'
      ]
    },
    {
      'title': 'CYLINDER HEADS',
       'bullets': [
         'Aluminum: 2.20” In, 1.68” Ex valves'
       ]
    },
    {
      'title': 'OIL AND COOLING',
      'bullets': [
        'Internal wet sump oil pump',
        'Fabricated aluminum pan',
        'Electric water pump'
      ]
    },
    {
      'title': 'DAMPER',
      'bullets': [
        'SFI approved ATI Performance Products "Super Damper"'
      ]
    },
    {
      'title': 'INTAKE MANIFOLD/INDUCTION',
      'bullets': [
        'Whipple 3.0L twin-screw supercharger'
      ]
    },
    {
      'title': 'THROTTLE BODY',
      'bullets': [
        'Billet aluminum, 109mm'
      ]
    },
    {
      'title': 'HEADERS',
      'bullets': [
        'American Racing Headers'
      ]
    },
    {
      'title': 'FUEL SYSTEM',
      'bullets': [
        'SFI-certified drag race fuel cell',
        'Brushless racing pump with dual inline filters'
      ]
    },
    {
      'title': 'GEARS &amp; AXLES',
      'bullets': [
        'Strange Engineering custom Drag Pak axle',
        '9" Aluminum center section',
        '40 Spline gun-drilled "Hy-Tuf" axles',
        '4.11:1 final drive ratio'
      ]
    },
    {
      'title': 'DRIVESHAFT',
      'bullets': [
        '4" diameter aluminum X 0.125” wall thickness',
        '1350 Spicer u-joints'
      ]
    },
    {
      'title': 'ENGINE CONTROLS &amp; IGNITION',
      'bullets': [
        'Holley Dominator EFI &amp; 7" Digital Dash',
        'Racepak Smartwire PDM'
      ]
    },
    {
      'title': 'SAFETY',
      'bullets': [
        'Racetech lightweight FIA certified seats',
        'SFI &amp; FIA certified safety harnesses',
        'Window net and driveshaft loop',
        '10\' cross-form parachute with integrated mount'
      ]
    },
    {
      'title': 'CHASSIS',
      'bullets': [
        '7.50 ET certified chromoly roll cage',
        'All-new design for improved chassis stiffness'
      ]
    },
    {
      'title': 'REAR SUSPENSION',
      'bullets': [
        'Four-Link Rear Suspension with Wishbone',
        'All-new geometry for improved performance',
        'Custom BILSTEIN Drag Pak double-adjustable coilovers',
        'Anti-roll bar (250% stiffer than previous generation)'
      ]
    },
    {
      'title': 'FRONT SUSPENSION',
      'bullets': [
        'Mopar<sub>&reg;</sub> K-Member with integrated engine mounts',
        'Cut-out for easy oil pan removal',
        'Custom BILSTEIN Drag Pak double-adjustable coilovers'
      ]
    },
    {
      'title': 'STEERING',
      'bullets': [
        'Manual steering rack (15:1 ratio)'
      ]
    },
    {
      'title': 'BRAKES',
      'bullets': [
        'Strange Engineering front &amp; rear rotors & calipers',
        'Line lock &amp; brake pressure switch'
      ]
    },
    {
      'title': 'WHEELS',
      'bullets': [
        'Front: Bogart racing 17X4.5 aluminum',
        'Rear:  Bogart racing 15X10 aluminum',
        'Available Weld Beadlock Lightweight Wheel upgrade'
      ]
    },
    {
      'title': 'TIRES',
      'bullets': [
        'Mickey Thompson<sup>&reg;</sup>',
        'Front: ET Front<sup>&trade;</sup> 27.5X4.0-17',
        'Rear: ET Drag<sup>&reg;</sup> Pro Drag Radial 30.0X9.0R15'
      ]
    }
  ]
}
