<div class="alert alert-dismissible alert-banner" [ngClass]="['bg-' + loginFormAlert.status]" *ngIf="loginFormAlert.show">
  <button type="button" class="close-alert" aria-label="Close" (click)="loginFormAlert.show = !loginFormAlert.show"><span aria-hidden="true">&times;</span></button>
  <div class="row">
    <div class="col-sm-12 flex-container">
      <div class="context-icon">
        <i class="fa fa-exclamation-triangle" aria-hidden="true" *ngIf="loginFormAlert.status == 'danger'"></i>
        <i class="fa fa-check" aria-hidden="true" *ngIf="loginFormAlert.status == 'success'"></i>
        <i class="fa fa-exclamation-triangle" aria-hidden="true" *ngIf="loginFormAlert.status == 'warning'"></i>
      </div>
      <div class="context-message">
        <h4 *ngIf="loginFormAlert.status == 'danger'">Oops!</h4>
        <h4 *ngIf="loginFormAlert.status == 'success'">Congratulations!</h4>
        <h4 *ngIf="loginFormAlert.status == 'warning'">Notice</h4>
        <div>
          <p *ngIf="loginFormAlert.code != 'deactivated'" [innerHTML]="loginFormAlert.message | safeHtml">{{loginFormAlert.message}}</p>
          <p *ngIf="loginFormAlert.code == 'deactivated'">This account has been deactivated. If you would like to reactivate your account, please <a href="#" (click)="sendReactivation($event)">click here</a> to email an activation link.</p>
        </div>
        <p *ngIf="loginFormAlert.additionalMessage" [innerHTML]="loginFormAlert.additionalMessage | safeHtml">{{loginFormAlert.additionalMessage}}</p>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>

<form [formGroup]="loginForm" (submit)="login()">
  <fieldset>
    <legend class="sr-only">Login Form</legend>
    <div class="form-group" [ngClass]="{ 'has-error': loginForm.controls.username.invalid && (loginForm.controls.username.dirty || loginForm.controls.username.touched) }">
      <label class="control-label">Username</label>
      <input type="text" name="username" class="form-control" formControlName="username" (focus)="userService.trackGAevent('SignIn','EmailUserName')" required #username>
      <div *ngIf="loginForm.controls.username.invalid && (loginForm.controls.username.dirty || loginForm.controls.username.touched)">
        <div *ngIf="loginForm.controls.username.errors.required" class="help-block">Please enter your username.</div>
      </div>
    </div>
    <div class="form-group" [ngClass]="{ 'has-error': loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched) }">
      <label class="control-label">Password</label>
      <input type="password" name="password" class="form-control" formControlName="password" (focus)="userService.trackGAevent('SignIn','Password')" required>
      <div *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
        <div *ngIf="loginForm.controls.password.errors.required" class="help-block">Please enter your password.</div>
      </div>
    </div>
    <div class="form-group">
      <a href="#" (click)="openModal(forgotPasswordModal, $event)">Forgot Password?</a>
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-primary" [disabled]="isLoading" [ngClass]="{'is-loading' : isLoading}">
        Sign In
        <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
      </button>
    </div>
  </fieldset>
</form>

<ng-template #forgotPasswordModal>
  <div class="modal-container modal-container-lg">
    <div class="modal-header">
      <h2 class="modal-title heading1 pull-left">Forgot Password</h2>
      <button class="modal-close" (click)="forgotPasswordModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <dg-forgot-password></dg-forgot-password>
    </div>
  </div>
</ng-template>

