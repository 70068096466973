<section class="individual-event" autoscroll="true">
  <div class="site-wrapper">
    <div class="row">
      <div class="col-sm-12">
        <header class="clearfix">
          <img loading="lazy" class="img-responsive" src="{{event.featuredImage}}" alt="{{event.name}}">
        </header>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="event-heading-container col-lg-10 col-lg-offset-1">
          <h1 class="h1">{{event.name}}</h1>
          <div class="event-date">
            {{event.startDate | date: 'MM/dd/yyyy': 'UTC'}}
            <ng-container *ngIf="(event.startDate.getMonth() + '-' + event.startDate.getDate()) != (event.endDate.getMonth() + '-' + event.endDate.getDate())">
              - {{event.endDate | date: 'MM/dd/yyyy': 'UTC'}}
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="event-directions">
          <div class="col-lg-10 col-lg-offset-1">
            <div class="row">
              <div class="col-md-7">
                <div class="location-details">
                  <div class="row">

                    <div class="col-sm-6 event-description">
                      <div class="description" *ngIf="event.description">
                        <p>{{event.description}}</p>
                      </div>
                      <div class="logo-container" *ngIf="event.logo">
                        <img loading="lazy" class="logo img-responsive" src="{{event.logo}}" alt="{{event.name}}">
                      </div>
                      <a class="btn btn-primary btn-event ga-event" href="{{event.website}}" target="_blank" *ngIf="event.website !== null" data-ga_category="Individual Events" attr.data-ga_action="{{ event.name }}" data-ga_label="Visit Website">
                        Visit Website
                      </a>
                      <div class="addthis_inline_share_toolbox" attr.data-title="{{event.name}}" attr.data-media="{{url + event.featuredImageThumbnail}}"></div>
                    </div>

                    <div class="{{!event.description ? 'col-sm-6' : 'col-sm-6 col-md-5'}}  event-information">
                      <h2 class="h2">LOCATION</h2>
                      <p class="event-address">
                        <a style="display: block;" class="address-map ga-event" href="https://maps.google.com/maps?daddr={{event.address}}+{{event.city}}+{{event.state}}+{{event.postalCode}}" target="_blank" data-ga_category="Individual Events" attr.data-ga_action="{{ event.name }}" data-ga_label="Get Directions">
                          <span *ngIf="event.venue">{{event.venue}}<br /></span>
                          <ng-container *ngIf="event.country === 'US' || event.country === 'CA'; else internationalAddress">
                            {{event.address}}<br />
                            {{event.city}}, {{event.state}} {{event.postalCode}}<br />
                          </ng-container>

                          <ng-template #internationalAddress>
                            {{event.address}}<br />
                            {{event.postalCode}} {{event.city}} {{event.state}}, {{event.country}}<br />
                          </ng-template>
        									<span class="anchor-red"><i class="fa fa-map-marker"></i> Get Directions</span>
                        </a>
                      </p>
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-md-5 map-container">
                <div class="the-map" #map></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div id="events-home" *ngIf="events?.length > 0">
    <div class="site-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-10 col-lg-offset-1 heading-container">
            <h2 class="text-uppercase" *ngIf="event.category == 'general'">Other Upcoming Enthusiast Events</h2>
            <h2 class="text-uppercase" *ngIf="event.category != 'general'">Other Upcoming {{event.category}} Events</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 col-lg-offset-1">
            <div class="event-card-container">
              <dg-event-card *ngFor="let event of events; let i = index;" [event]="event">
                ..loading
              </dg-event-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-json-ld [json]="schema"></ngx-json-ld>
</section>
