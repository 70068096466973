import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges,
} from '@angular/core';

import {CommentService} from "./comment.service";
import { Post } from './post';
import {UserService} from '../user/user.service';
import {Subscription} from "rxjs";

import {Notification} from "./notification";
import {Topic} from "./topic";

@Component({
  selector: 'dg-comments',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.less']
})
export class CommentComponent implements OnInit, OnDestroy, OnChanges {

  @Input() topic: Topic = new Topic();
  @Output() topicCreatedEmitter = new EventEmitter();
  @Output() postCountUpdatedEmitter = new EventEmitter<number>();

  posts: Post[] = [];
  postCount: number = 0;

  // User login status
  loggedInSub: Subscription;
  notifications: Notification[] = [];

  constructor(
    private commentService: CommentService,
    public userService: UserService
  ) {
  }

  ngOnInit() {
    console.log('discourse topic id from comment component', this.topic.discourseTopicId, this.topic);
    
    this.loggedInSub = this.userService.loggedIn$.subscribe(loggedIn => {
      if (this.userService.userModel.isLoggedIn) {
        // Subscribe to when the user logs in
        if (this.topic.discourseTopicId) {
          this.fetchComments(this.userService.userModel.username);
        }
      }
    });

    if (this.topic.discourseTopicId && !this.userService.userModel.isLoggedIn) {
      this.fetchComments('dgapiuser');
    }
  }

  ngOnDestroy() {
    if (this.loggedInSub) {
      this.loggedInSub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.topic.currentValue['discourseTopicId']) {
      this.topic = changes.topic.currentValue;
      this.loggedInSub = this.userService.loggedIn$.subscribe(loggedIn => {
        if (this.userService.userModel.isLoggedIn) {
          // Subscribe to when the user logs in
          this.fetchComments(this.userService.userModel.username);
        }
      });
      if (!this.userService.userModel.isLoggedIn) {
        this.fetchComments('dgapiuser');
      }
    }
  }

  fetchComments(username: string = 'dgapiuser') {
    console.log('fetchComments - outside observable', this.topic.discourseTopicId, username);
    this.commentService.getPostsByTopicId(this.topic.discourseTopicId, username).subscribe( data => {

      console.log('fetch top level comments', data);
      //We need to check that the data isnt returning null
      if(data != null) {
        if (data.posts_count >= 1)
          this.postCount = data.posts_count - 1; // Because the first post is a dummy, we want to remove it from the count

        let stream = data.post_stream.stream; // We need the IDs of all of the posts
        stream.shift(); // Remove the fake first post
        // Remove bump messages from system
        let bumpArray = [];
        data.post_stream.posts.forEach(post => {
          if (post.action_code == 'autobumped')
            bumpArray.push(post.id)
        });
        stream = stream.filter((el) => !bumpArray.includes(el));
        this.postCount = this.postCount - bumpArray.length;
        // Unfortunately, the initial get posts call does not get all of the data we need. Luckily, we can get everything in one more call.
        let streamString = stream.join('&post_ids[]=');
        streamString = '&post_ids[]=' + streamString;

        console.log('Post stream before getting all posts', streamString, username);
        console.log('Discourse topic ID', this.topic.discourseTopicId);

        this.commentService.getAllPostsByPostIds(this.topic.discourseTopicId, username, streamString).subscribe(postData => {
          let flatPosts = postData.post_stream.posts;

          // Reverse so the newest posts are first
          flatPosts.reverse();
          let posts = flatPosts.filter(post => {
            if (!post.reply_to_post_number) return post
          });

          posts.forEach(post => {
            this.commentService.processPostAndReplies(post, flatPosts, this.topic);
          });

          this.posts = posts;
          console.log('Posts in sub comment loop', this.posts);
        });
        this.postCountUpdatedEmitter.emit(this.postCount);
      }

    }, error => {
      console.log('fetchComments - Error', error);
      // If the user is not permitted to view the comments, they might not have a discourse account yet. Let's create one.
      if (error.status == 500 && this.userService.userModel.isLoggedIn) {
        this.commentService.createNewUser(this.userService.userModel).subscribe( response => {
          if (response.success && response.active) { // Check if user needs to be activated
            this.fetchComments(this.userService.userModel.username);
          }
          //If there is a user but they are inactive within discourse. This scenario has never happened yet.
          // else {
          //   console.log('activate new user');
          //   this.commentService.activateNewUser(response.values.username).subscribe( data => {
          //     if (data.success) {
          //       this.fetchComments(this.userService.userModel.username);
          //     }
          //   });
          // }
        });
      }
    });
  }

  topicCreated(event) {
    this.topic.discourseTopicId = event.discourseTopicId;
    this.topic.discourseCategoryId = event.discourseCategoryId;
    this.topicCreatedEmitter.emit(this.topic);
  }

  addNewComment(event) {
    event.user = this.userService.userModel;
    let newPost = this.commentService.processPostAndReplies(event, null, this.topic);
    this.posts.unshift(newPost);
    this.postCount++;
    this.postCountUpdatedEmitter.emit(this.postCount);
  }

  postCountIncrease(event) {
    this.postCount++;
    this.postCountUpdatedEmitter.emit(this.postCount);
  }
}
