<div class="site-wrapper">
  <section id="legal-trademarks">
    <div class="container-fluid">
      <div class="row event-header">
        <div class="col-sm-12 col-md-8 col-md-offset-2">
          <div class="event-heading">
            <h1 class="heading1 red">Legal, Safety and Trademark Information</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-8 col-md-offset-2">
          <p>
            Driveability Program, Avenger, Blacktop, Caliber, Challenger, Challenger SRT Hellcat, Charger, Citadel, Crosshair Grille, Dart, Dart Aero, Daytona, Durango, Durango Crew, Grand Caravan, Grand Caravan Crew, HEMI, 392 HEMI, Journey, Journey Crew, Mopar Vehicle Protection, ParkSense, ParkView, Pentastar, R/T, Sentry Key, SRT8, SRT Hellcat, Super Bee, SXT, the Ram's Head logo, TechAuthority, Tigershark, Uconnect, VES, Viper and Yellow Jacket are registered trademarks and Crossroad, Keyless Enter 'n Go, Scat Pack and the Scat Pack Bee Design are trademarks of FCA US LLC.
          </p>
          <p>
            Alfa Romeo is a registered trademark of Fiat Group Marketing & Corporate Communication S.p.A., used with permission.
          </p>
          <p>
            Ally is a registered service mark of Ally Financial.
          </p>
          <p>
            Alpine and the Alpine logo are registered trademarks of Alpine Electronics, Inc. All rights reserved.
          </p>
          <p>
            Alpine is a registered trademark of Boston Acoustics, Inc.
          </p>
          <p>
            Apple logo is a registered trademark of Apple Inc.
          </p>
          <p>
            Apple Music is a registered trademark of Apple Inc.
          </p>
          <p>
            Automotive News is a registered trademark of Crains Communications, Inc.
          </p>
          <p>
            The trademarks Beats by Dr. Dre®, Beats®, Beats Audio™, and the b logo are the property of Beats Electronics, LLC.
          </p>
          <p>
            Blu-ray is a trademark of the Blu-ray Disc Association.
          </p>
          <p>
            Bluetooth is a registered trademark of Bluetooth SIG, Inc.
          </p>
          <p>
            Boston Acoustics is a registered trademark of Boston Acoustics, Inc.
          </p>
          <p>
            Chrysler Capital is a registered trademark of FCA US LLC and licensed to Santander Consumer USA Inc. Lease agreements are owned by CCAP Auto Lease Ltd. and serviced by Chrysler Capital.
          </p>
          <p>
            Chrysler Group Service Contracts LLC (CGSC LLC) is a wholly owned subsidiary of FCA US LLC.
          </p>
          <p>
            eSSENTIAL Accessibility and the eSSENTIAL Accessibility logos are registered trademarks of eSSENTIAL Accessibility Inc.
          </p>
          <p>
            The Facebook logo is a registered trademark of Facebook, Inc.
          </p>
          <p>
            Garmin is a registered trademark of Garmin Ltd.
          </p>
          <p>
            Harman Kardon is a registered trademark of Harman International Industries, Inc.
          </p>
          <p>
            HomeLink is a registered trademark of Johnson Controls Technology Company.
          </p>
          <p>
            Infinity is a registered trademark of Harman International Industries Inc.
          </p>
          <p>
            iPod is a registered trademark of Apple Inc.
          </p>
          <p>
            iTunes is a registered trademark of Apple Inc.
          </p>
          <p>
            Katzkin is a registered trademark of Katzkin Leather Interiors, Inc.
          </p>
          <p>
            Kelley Blue Book is a registered trademark of Kelley Blue Book Co., Inc.
          </p>
          <p>
            MultiAir is a registered trademark of C.R.F. Società Consortile per Azioni, used with permission.
          </p>
          <p>
            Pandora is a registered trademark of Pandora Media, Inc.
          </p>
          <p>
            The Pinterest logo is a trademark of Pinterest, Inc.
          </p>
          <p>
            Sabelt is a registered trademark of Sabelt S.p.A.
          </p>
          <p>
            Sirius, XM and all related marks and logos are trademarks of SiriusXM Radio Inc.
          </p>
          <p>
            Strategic Vision, Total Value Award and New Vehicle Experience Study are registered trademarks of Darrel Edwards, Inc.
          </p>
          <p>
            Thule is a registered trademark of Thule, Inc.
          </p>
          <p>
            TREMEC is a registered trademark of Transmisiones Y Equipos Mecanicos, S.A. de C.V.
          </p>
          <p>
            The Twitter bird is a trademark of Twitter, Inc.
          </p>
          <p>
            USAA is a registered trademark of United Services Automobile Association.
          </p>
          <p>
            Ward's is a trademark of Penton Business Media, Inc.
          </p>
          <p>
            Yelp is a registered trademark of Yelp Inc.
          </p>
          <p>
            Your Plan is sold and administered by CGSC LLC if you purchased your Plan in: Florida, Hawaii, Maine, Mississippi, Montana, Oregon, Puerto Rico, Texas, Virginia, Washington or Wyoming.
          </p>
          <p>
            Your Plan is sold and administered by CGSC LLC if your Plan covers an FCA US vehicle and you purchased your plan in: New Mexico.
          </p>
          <p>
            Your Plan is sold and administered by CGSC LLC if your Plan covers a non-FCA US vehicle and you purchased your Plan in: Alabama, California, Illinois, Louisiana, Minnesota, Missouri, Nevada, New Hampshire, New York, South Carolina, Utah or Vermont.
          </p>
          <p>
            The YouTube logo is a registered trademark of Google Inc.
          </p>
          <p>
            The information on this website is intended to provide only an outline of the coverages, benefits and exclusions regarding Mopar Vehicle Protection Plans as offered and issued by FCA US LLC or a subsidiary. FL Lic. #65505. CA Lic. #0G94472. Services/component repairs made prior to the purchase of a plan are not covered. Complete details and coverage are provided in the Plan Provisions. All transactions relating to any plan are governed solely by the Plan Provisions of the purchased plan.
          </p>
          <p>
            FCA US Vehicle means Chrysler, Jeep, Dodge, Ram, SRT, FIAT or Alfa Romeo brand vehicles only.
          </p>
          <p>
            The text, images, graphics, sound files, animation files, video files and their arrangement herein are all subject to Copyright and other intellectual property protection. These objects may not be copied for commercial use or distribution. Unless otherwise indicated, all marks displayed on FCA US LLC Internet sites are subject to the trademark rights of FCA US LLC, including each of FCA US LLC's primary brands (Chrysler, Jeep®, Dodge, Ram, Mopar® and SRT®), its model nameplates, and its corporate logos and emblems.
          </p>
          <p>
            The estimates provided are for informational purposes only and are not an offer to sell. Each estimate is based on the variables you have entered, on the Manufacturer's Suggested Retail Price, excluding tax for the vehicle you have selected and on the available finance programs. All product illustrations, specifications and competitive comparisons are based upon current information at the time of publication approval. Although descriptions are believed correct, complete accuracy cannot be guaranteed. FCA US LLC reserves the right to make changes at any time, without notice or obligation, in prices, offers, specifications, equipment, colors and materials, and to change or discontinue models. Some of the equipment shown or described throughout this website is available at extra cost and/or may be subject to late availability. See your dealer for the latest information.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
