import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'competition-setup',
  templateUrl: './competition-setup.component.html',
})

export class DragPakCompetitionSetupComponent implements OnInit {
  ngOnInit(): void {

  }



}

