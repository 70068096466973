import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef} from '@angular/core';
import {UserService} from "../user.service";
import {UserModel} from "../user";
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from "rxjs";
import {isPlatformBrowser} from "@angular/common";
import {Vehicle} from "../../vehicle/vehicle";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {HelperService} from '../../services/helper.service';

function requiredIfUSAValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }

  if (formControl.parent.get('country').value === 'US') {
    return Validators.required(formControl);
  }
  return null;
}

@Component({
  selector: 'dg-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.less']
})
export class ManageAccountComponent implements OnInit, OnDestroy {

  // User login status
  loggedInSub: Subscription;

  user: UserModel = new UserModel();
  userDetailsForm: FormGroup;
  userDetailsFormAlert = {
    show: false,
    status: '',
    message: '',
    additionalMessage: ''
  };
  changePasswordForm: FormGroup;
  changePasswordFormAlert = {
    show: false,
    status: '',
    message: '',
    additionalMessage: ''
  };
  // Disable Buttons while Loading
  isDetailsUpdateLoading: boolean = false;
  isChangePasswordLoading: boolean = false;

  // Modals
  confirmDeactivateModalRef: BsModalRef;

  //countries for drop down
  countries: Array<Object> = [];

  constructor(
    private helperService: HelperService,
    private modalService: BsModalService,
    public userService: UserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.user = this.userService.userModel;
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.createUserDetailsForm();
      this.createChangePasswordForm();
      //this.fetchCountries();
      this.loggedInSub = this.userService.loggedIn$.subscribe(
        loggedIn => {
          if (this.userService.userModel.isLoggedIn) {
            console.log('User is now logged in');
            // Set Form Data
            this.userDetailsForm.controls['email'].setValue(this.user.email);
            this.userDetailsForm.controls['firstName'].setValue(this.user.firstName);
            this.userDetailsForm.controls['lastName'].setValue(this.user.lastName);
            this.userDetailsForm.controls['country'].setValue(this.user.country);
            this.userDetailsForm.controls['zipCode'].setValue(this.user.zipCode);
            this.userDetailsForm.controls['fcaCommunication'].setValue(this.user.fcaCommunication);
            this.userDetailsForm.controls['eventCommunication'].setValue(this.user.eventCommunication);
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.loggedInSub) {
      this.loggedInSub.unsubscribe();
    }
    if (this.confirmDeactivateModalRef) {
      this.confirmDeactivateModalRef.hide();
    }
  }

  createUserDetailsForm() {
    this.userDetailsForm = new FormGroup({
      'email': new FormControl(this.user.email, [
        Validators.required,
        Validators.email
      ]),
      'firstName': new FormControl(this.user.firstName, [
        Validators.required
      ]),
      'lastName': new FormControl(this.user.lastName, [
        Validators.required
      ]),
      'country': new FormControl(this.user.country, [
        Validators.required
      ]),
      'zipCode': new FormControl(this.user.zipCode),
      'fcaCommunication': new FormControl(this.user.fcaCommunication),
      'eventCommunication': new FormControl(this.user.eventCommunication)
    });

    // Dynamically set validation based on dropdown selection
    this.userDetailsForm.controls.country.valueChanges.subscribe( country => {
      if(country === 'US') {
        this.userDetailsForm.controls.zipCode.setValidators([
          Validators.pattern(/^\d{5}$/),
          requiredIfUSAValidator
        ]);
      } else {
        this.userDetailsForm.controls.zipCode.clearValidators();
        this.userDetailsForm.controls.zipCode.updateValueAndValidity();
      }
    })
  }

  createChangePasswordForm() {
    this.changePasswordForm = new FormGroup({
      'password': new FormControl('', [
        Validators.required
      ]),
      'newPassword': new FormControl('', [
        Validators.required
      ]),
      'confirmNewPassword': new FormControl('', [
        Validators.required
      ])
    });
  }

  updateUserDetails() {
    // Check all fields before submitting
    Object.keys(this.userDetailsForm.controls).forEach(field => {
      const control = this.userDetailsForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.userDetailsForm.valid) { // If form is valid, proceed
      this.isDetailsUpdateLoading = true;
      // Set Form Values to User Model
      this.user.email = this.userDetailsForm.controls['email'].value;
      this.user.firstName = this.userDetailsForm.controls['firstName'].value;
      this.user.lastName = this.userDetailsForm.controls['lastName'].value;
      this.user.zipCode = this.userDetailsForm.controls['zipCode'].value;
      this.user.country = this.userDetailsForm.controls['country'].value;
      this.user.fcaCommunication = this.userDetailsForm.controls['fcaCommunication'].value;
      this.user.eventCommunication = this.userDetailsForm.controls['eventCommunication'].value;
      this.userService.submitUserDetails(this.user).subscribe( data => {
        this.userDetailsFormAlert.show = true;
        this.userDetailsFormAlert.status = data['status'];
        this.userDetailsFormAlert.message = data['message'].join(' ');
        // Display response message
        this.isDetailsUpdateLoading = false;
      });
    } else {
      this.userDetailsFormAlert.show = true;
      this.userDetailsFormAlert.status = 'danger';
      this.userDetailsFormAlert.message = 'Please fix the issues below.';
    }
  }

  updateChangePassword() {
    // Check all fields before submitting
    Object.keys(this.changePasswordForm.controls).forEach(field => {
      const control = this.changePasswordForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.changePasswordForm.valid) { // If form is valid, proceed
      this.isChangePasswordLoading = true;
      // Set Form Values to User Model
      this.user.password = this.changePasswordForm.controls['password'].value;
      this.user.newPassword = this.changePasswordForm.controls['newPassword'].value;
      this.user.confirmNewPassword = this.changePasswordForm.controls['confirmNewPassword'].value;
      this.userService.submitChangePassword(this.user).subscribe( data => {
        this.isChangePasswordLoading = false;
        if (data['status'] == 'success') {
          // Reset form if successful
          this.changePasswordForm.reset();
          // Hide sensitive information
          this.user.password = '';
          this.user.newPassword = '';
          this.user.confirmNewPassword = '';
        }

        this.changePasswordFormAlert.show = true;
        this.changePasswordFormAlert.status = data['status'];
        this.changePasswordFormAlert.message = data['message'].join(' ');
      });
    } else {
      this.changePasswordFormAlert.show = true;
      this.changePasswordFormAlert.status = 'danger';
      this.changePasswordFormAlert.message = 'Please fix the issues below.';
    }
  }

  openDeactivateAccountModal(template: TemplateRef<any>) {
    this.confirmDeactivateModalRef = this.modalService.show(template);
  }

  fetchCountries() {
    this.helperService.getNonUSCountries().subscribe( data => {
      this.countries = data;
    })
  }

}
