import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserService} from "../user.service";

@Component({
  selector: 'dg-deactivate-account',
  templateUrl: './deactivate-account.component.html',
  styleUrls: ['./deactivate-account.component.less']
})
export class DeactivateAccountComponent implements OnInit {

  @Output() accountDeactivatedEmitter = new EventEmitter();
  @Output() cancelDeactivationEmitter = new EventEmitter();

  // TODO Maybe add a banner after a user has deactivated their account?

  isLoading: boolean = false;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  deactivateAccount() {
    this.isLoading = true;
    this.userService.deactivateAccount().subscribe( data => {
      this.accountDeactivatedEmitter.emit('deactivated');
      this.userService.logout();
      this.isLoading = false;
    })
  }

  cancelDeactivation() {
    this.cancelDeactivationEmitter.emit('canceled');
  }

}
