import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class JuicerService {

  juicerUrl = 'https://www.juicer.io/api/feeds/';

  constructor(
    private http: HttpClient
  ) { }

  getJuicerFeed(numberOfPosts = 6, feedID): Observable<any> {
    return this.http.get(this.juicerUrl + feedID + '?per=' + numberOfPosts);
  }

}
