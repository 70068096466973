<form [formGroup]="commentForm" (submit)="submitComment($event)">

  <dg-alert-banner [alert]="commentFormAlert"></dg-alert-banner>

  <div class="form-group" [ngClass]="{ 'has-error': commentForm.controls.comment.invalid && (commentForm.controls.comment.dirty || commentForm.controls.comment.touched) }">
    <label class="sr-only" for="comment">Comment</label>
    <textarea id="comment" name="comment" class="form-control" formControlName="comment" [ngClass]="{ 'is-invalid': commentForm.controls.comment.invalid && (commentForm.controls.comment.dirty || commentForm.controls.comment.touched) }" (focus)="checkLogin()" [placeholder]="placeholder"></textarea>
    <div *ngIf="commentForm.controls.comment.invalid && (commentForm.controls.comment.dirty || commentForm.controls.comment.touched)" class="help-block">
      <span *ngIf="commentForm.controls.comment.errors.required">Please enter a comment.</span>
    </div>
  </div>
  <div class="form-group text-right">
    <button *ngIf="this.postToEdit.id != 0" class="btn btn-white" type="button" (click)="cancelEditEmitter.emit('cancel')">Cancel</button>
    <button class="btn btn-primary" type="submit" [disabled]="isLoading" [ngClass]="{'is-loading' : isLoading}">
      <span *ngIf="!isEditingMode">Post</span>
      <span *ngIf="isEditingMode">Update</span>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
    </button>
  </div>
</form>

<!-- User Modal -->
<ng-template #userModal>
  <div class="modal-container">
    <div class="modal-header">
      <button class="modal-close" (click)="userModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="signup-form form-1320">
        <dg-user-onboarding class="step-form" (userLoggedIn)="closeUserModal()"></dg-user-onboarding>
      </div>
    </div>
  </div>
</ng-template>
