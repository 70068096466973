import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'performance-cars-hpds',
  templateUrl: './hpds.component.html',
  styleUrls: ['./hpds.component.less']
})
export class HpdsComponent implements OnInit {

  expirationDate = new Date('July 7, 2018 00:00:00');
  expired = false;

  schoolClosedModalRef: BsModalRef;
  @ViewChild('schoolClosedModal') public schoolClosedModal: TemplateRef<any>;

  constructor(
    public title: Title,
    public metaService: Meta,
    private router: Router,
    private modalService: BsModalService
  ) {
    title.setTitle( 'High Performance Driving School | Dodge Garage'  );

    metaService.updateTag({ name: 'description', content: 'The all-new SRT® High Performance Driving School is a 1-day experience developed by the Bondurant Racing School specifically for SRT Owners and Enthusiasts. Please review the details below carefully, as exciting changes have taken place!' })
    metaService.updateTag({ property: 'og:description', content: 'The all-new SRT® High Performance Driving School is a 1-day experience developed by the Bondurant Racing School specifically for SRT Owners and Enthusiasts. Please review the details below carefully, as exciting changes have taken place!' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | Bondurant High Performance Driving School' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
    this.expire();
  }

  expire() {
    let currentDate = new Date()
    currentDate > this.expirationDate ? this.expired = true : this.expired = false
  }

  openModal(template: TemplateRef<any>) {
    this.schoolClosedModalRef = this.modalService.show(template);
  }

}
