export const Products: Array<any> = [
  {
    name: 'Custom SRT Demon 170 decanter set',
    description: 'This one-of-a-kind, commemorative decanter set is crafted with precision to reflect the bold, aggressive design of your SRT Demon 170. Your full VIN will be included on the lid and etched on the back of the decanter, and you have up to 16 characters with spaces to add your name to the lid for personalization.',
    contents: [
      '(1) Decanter',
      '(2) Glasses',
      '(4) Leather coasters',
      '(4) Beverage-chilling cubes',
      '(1) Commemorative Supercharger badge cover'
    ],
    price: '$0.00',
    images: [
      '/assets/images/demon-170/products/DecanterSet-1.webp',
      '/assets/images/demon-170/products/DecanterSet-2.webp',
      '/assets/images/demon-170/products/DecanterSet-3.webp'
    ]
  },
  {
    name: 'Custom srt demon 170 ip badge',
    description: 'Engraved with the last 4 digits of your VIN, choose up to 16 characters with spaces to proudly declare just who your SRT Demon 170 was built for.',
    price: '$0.00',
    images: [
      '/assets/images/demon-170/products/CustomIPBadge.webp'
    ]
  },
  {
    name: "Jay leno's garage srt demon 170 car detail kit",
    description: "Developed from real-world experience gained through years of caring for and maintaining Jay's collection, these custom-formulated products are proudly made in the USA, guaranteed to outshine and are easy for any car person to use.",
    contents: [
      "Ceramic Car Wash Shampoo – 16 oz.",
      "Radiant Ceramic Coating – 16 oz.",
      "High-Gloss Quick Detailer – 16 oz.",
      "All-Purpose Cleaner & Degreaser – 16 oz.",
      "High-Gloss Tire Shine – 16 oz.",
      "Color-Changing Wheel Cleaner – 16 oz.",
      "(1) Microfiber Chenille Wash Mitt",
      "(2) Premium Microfiber Towels"
    ],
    price: '$0.00',
    images: [
      '/assets/images/demon-170/products/JayLenoKit.webp'
    ]
  },
  {
    name: 'Radford Racing School 2-Day Drag Racing Class',
    description: 'Ready for some throttle therapy? Learn fundamental drag racing skills and knowledge from burnouts and throttle control to Line Lock, Launch Assist, TransBrake 2.0 and staging. Tackle the skid car, familiarize yourself with the drag strip and equipment … and get ready to make passes.<br><br>You’ll also be able to qualify for your official NHRA level 4, 5, 6 or 7 license – depending on your run time – at no additional cost!<br><br><ul><li>Level 4: >135 mph or 7.5 - 9.99 seconds</li><li>Level 5: <135 mph or >10 seconds</li><li>Level 6: 7.5 - 9.99 seconds</li><li>Level 7: <135 mph or >10 seconds</li></ul>Class dates beginning in 2024 will be announced soon. Due to smaller class sizes, owners will have 3 years from the date of taking delivery to register for a class.',
    price: '$0.00',
    images: [
      '/assets/images/demon-170/products/RadfordRacingSchool.webp'
    ]
  },
  {
    name: 'Custom Goodwool car shell',
    description: 'The Goodwool car shell is the only product tailor-made of high-quality Merino wool and technical fabrics, for impeccable detail and full customization. Perfectly fitted to your SRT Demon 170, the exclusive SENSITIVE<sup>&reg;</sup> fabric combines resistance and elasticity to adapt to the shapes of your car. Matching your color scheme and wearing all the badging, personalize it with optional printing on the rear license plate (up to 16 characters with spaces, and the last 4 digits of your VIN).',
    price: '$2,640.00',
    images: [
      '/assets/images/demon-170/products/CustomGoodwool-2.webp',
      '/assets/images/demon-170/products/CustomGoodwool-1.webp'
    ]
  },
  {
    name: 'Custom satin stretch indoor car cover',
    description: 'Intended for indoor use only, this color-scheme-matching custom cover provides superior protection from dirt, dust and scratches and includes a unique fleece lining. Made with an elastic synthetic fabric, it stretches in all directions and molds to the contours of your SRT Demon 170 to highlight its design. Large SRT Demon 170 logos are prominent on the sides, while "170" is boldly displayed on the front and rear fascia areas. Personalize it with optional embroidery (up to 16 characters with spaces) above the driver’s door.',
    price: '$695.00',
    images: [
      '/assets/images/demon-170/products/Demon170LogoOnBlack.webp'
    ]
  },
  {
    name: 'Direct Connection harness bar',
    description: 'Made from 4130 chromoly steel, the harness bar attaches to factory mounting locations, requiring no cutting or alterations to the vehicle. The powder coated, matte black finish offers great looks and unsurpassed durability. <strong>HARNESS NOT INCLUDED.</strong>',
    price: '$1,389.00',
    images: [
      '/assets/images/demon-170/products/DirectConnectionHarnessBar-2.webp',
      '/assets/images/demon-170/products/DirectConnectionHarnessBar-1.webp'
    ],
    subproduct: {
      name: 'Direct connection seat harness mounting kit',
      description: 'Includes quick disconnect and seat support bracket with a powder coat finish.',
      price: '$179.99'
    }
  },
  {
    name: 'Direct connection rear seat delete carbon fiber closeout panels',
    description: 'Rear seat delete specially designed trim panels including floor and back panels. Made from pre-preg carbon fiber, this upgrade will reduce weight while improving the look of your interior.',
    price: '$3,999.00',
    images: [
      '/assets/images/demon-170/products/DirectConnectionRearSeat-2.webp',
      '/assets/images/demon-170/products/DirectConnectionRearSeat-1.webp'
    ]
  },
  {
    name: 'Direct connection parachute mounting kit',
    description: '',
    price: '$1,329.00',
    contents: [
      "Rear bumper support",
      "Parachute pack mount",
      "Powder coated pack mount",
      "Powder coated support bar",
      "Disconnect pin",
      "License plate bracket",
      "<strong>PARACHUTE NOT INCLUDED</strong>"
    ],
    images: [
      '/assets/images/demon-170/products/DirectConnectionParachuteMounting-2.webp',
      '/assets/images/demon-170/products/DirectConnectionParachuteMounting-1.webp'
    ]
  },
  {
    name: 'Direct connection parachute release',
    description: '',
    price: '$899.00',
    contents: [
      "Leather-wrapped handle",
      "Floor mount/bracket",
      "Cable",
      "Actuator",
      "Hardware"
    ],
    images: [
      '/assets/images/demon-170/products/DirectConnectionParachuteRelease-2.webp',
      '/assets/images/demon-170/products/DirectConnectionParachuteRelease-1.webp'
    ]
  },
  {
    name: 'Direct connection trunk organizer',
    description: "This beautifully crafted carbon fiber trunk organizer is custom fit to the SRT Demon 170 trunk and is sized to hold the special-edition Jay Leno's Garage detailing products.",
    price: '$899.00',
    images: [
      '/assets/images/demon-170/products/DirectConnectionTrunkOrganizer.webp'
    ]
  },
]