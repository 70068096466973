<section id="horsepower-locator-banner">
  <div class="site-wrapper">
    <div class="hero-container fade-in">
      <div class="horsepower-logo">
        <a routerLink="/horsepower-locator" (click)="sendGTMEvent()">
          <img src="/assets/images/hpl-logo-white-base.webp" alt="Dodge Horsepower Locator - Logo" class="img-responsive" width="768" height="413">
        </a>
      </div>
      <div class="horsepower-content">
        <p id="dont-say-warning-text" class="text-center text-white text-uppercase">
          <span class="combo">
            <span class="note">First Call</span>
            CHARGER DAYTONA 2-DOOR
          </span>
          <span>+</span>
          <span>DURANGO SPECIAL EDITIONS</span>
        </p>
        <a routerLink="/horsepower-locator" class="btn btn-primary" (click)="sendGTMEvent()">Find your ride</a>
      </div>
    </div>
  </div>
</section>
