export class EventSearch {
  date: any;
  zipcode: any;
  term: any = null;
  state: any = '';
  country: any = '';
  category: any;
  radius:any = 200;
  max:any = null;
  offset:any = null;
}
