<ng-template tabHeading>
  <span>{{listInfo.name}}</span>
</ng-template>
<div class="tile-container">
  <div class="video-thumbnail" *ngFor="let video of videos | slice:0:4">
    <a href="#" (click)="pushSelectedVideo(video, $event)" (deferLoad)="video.isLoaded = true" [attr.aria-label]="video.name">
      <i class="fa fa-play"></i>
      <img [src]="video.thumbnail" [alt]="video.name" *ngIf="video.isLoaded">
    </a>
    <div class="video-description">
      <p>{{ video.description }}</p>
    </div>
  </div>
</div>
<ngx-json-ld [json]="schema"></ngx-json-ld>

<!-- <div class="modal fade" bsModal #videoModal tabindex="-1" role="dialog"
  (onHidden)="videoModalListener('onHidden', $event)">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title heading1 pull-left">{{selectedVideo.name}}</h2>
        <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
      </div>
      <div class="modal-body">
        <p class="description" *ngIf="selectedVideo.description && selectedVideo.description != ''">{{selectedVideo.description}}</p>
        <div class="embed-responsive embed-responsive-16by9 mb-4">
          <div class="embed-responsive-item" *ngIf="selectedVideo.embedCode != ''" [innerHTML]="selectedVideo.embedCode"></div>
        </div>
      </div>
    </div>
  </div>
</div> -->
