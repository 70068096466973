<div class="incentive-container" id="incentives">
    <div class="inventory incentive-container-child">
        <div class="video-thumb" (click)="openVideoModal($event)">
            <img class="img-responsive" src="/assets/images/cta-tiles/last-call-video-thumb.webp" alt="Last Call thumbnail">
            <i class="fa fa-play-circle"></i>
        </div>
        <div class="inventory-cta text-uppercase">
            <a href="https://www.dodge.com/vehicle-selector.sni.html?app=sni" target="_blank" (click)="helperService.trackGAEvent('Outbound', 'Click', 'Search New Inventory')">Search New Inventory</a>
        </div>
    </div>
    <div class="current-offers-grid incentive-container-child" >
        <div class="vehicle" data-vehicle="charger">
            <picture>
                <source srcset="/assets/images/sales-incentives/charger.webp" type="image/webp">
                <source srcset="/assets/images/sales-incentives/charger.png" type="image/png">
                <img loading="lazy" src="/assets/images/sales-incentives/charger.png" alt="Charger">
            </picture>
            <span class="text-uppercase">2023 Charger</span>
        </div>
        <div class="vehicle" data-vehicle="challenger">
            <picture>
                <source srcset="/assets/images/sales-incentives/challenger.webp" type="image/webp">
                <source srcset="/assets/images/sales-incentives/challenger.png" type="image/png">
                <img loading="lazy" src="/assets/images/sales-incentives/challenger.png" alt="Challenger">
            </picture>
            <span class="text-uppercase">2023 Challenger</span>
        </div>
        <div class="vehicle" data-vehicle="durango">
            <picture>
                <source srcset="/assets/images/sales-incentives/durango.webp" type="image/webp">
                <source srcset="/assets/images/sales-incentives/durango.png" type="image/png">
                <img loading="lazy" src="/assets/images/sales-incentives/durango.png" alt="Durango">
            </picture>
            <span class="text-uppercase">2024 Durango</span>
        </div>
        <div class="vehicle" data-vehicle="hornet">
            <picture>
                <source srcset="/assets/images/sales-incentives/hornet.webp" type="image/webp">
                <source srcset="/assets/images/sales-incentives/hornet.png" type="image/png">
                <img loading="lazy" src="/assets/images/sales-incentives/hornet.png" alt="Hornet">
            </picture>
            <span class="text-uppercase">2024 Hornet</span>
        </div>
        <div class="offers-cta text-uppercase">
            <a href="https://www.dodge.com/lineup.incentives.html?app=incentives" target="_blank" (click)="helperService.trackGAEvent('Outbound', 'Click', 'Incentives and Offers')" >View Current Offers</a>
        </div>
    </div>
</div>


<ng-template #videoModal>
    <div class="modal-container">
        <div class="modal-header">
            <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
        </div>
        <div class="modal-body site-wrapper">
            <div class="embed-responsive embed-responsive-16by9">
                <iframe src="https://player.vimeo.com/video/922541824?badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="LAST CALL"></iframe>
            </div>
        </div>
    </div>
</ng-template>