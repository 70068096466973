<div id="search-filters-component">
  <form #searchForm [formGroup]="searchFilterForm" id="zip-filters" (submit)="onSearchZipcodeUpdate($event)">
    <div class="form-group">
      <label for="zipcode" class="control-label">ZIP CODE</label>
      <div class="input-group" [ngClass]="{invalid: searchFilterForm.controls['zipcode'].errors && searchFilterForm.controls['zipcode'].errors.pattern}">
        <input class="form-control" type="text" name="zipcode" id="zipcode" aria-label="zipcode search" placeholder="99999"
        maxlength="5" minlength="5" pattern="\d*" formControlName="zipcode">
        <span class="input-group-btn">
          <button class="btn btn-dark" type="submit" [disabled]="!searchFilterForm.valid">
            <i class="fa fa-search"></i>
            <span class="sr-only">Search</span>
          </button>
        </span>
      </div>
      <span [ngClass]="{visible: searchFilterForm.controls['zipcode'].errors && searchFilterForm.controls['zipcode'].errors.pattern}" class="error">Numbers only.</span>
    </div>
    <div class="form-group" id="zipcode-area-group">
      <span id="zipcode-area-select">
        <label class="radio-inline">
          <input type="radio" formControlName="zipcodeRadius" name="zipcodeRadius" value="all" aria-label="search entire United States" [attr.disabled]="!searchFilterForm.valid ? true : null" (change)="onRadiusUpdate()" >
          Entire U.S.
        </label>
        <label class="radio-inline">
          <input type="radio" formControlName="zipcodeRadius" name="zipcodeRadius"
            value="100" aria-label="limit search to dealers within 100 miles" [attr.disabled]="!searchFilterForm.valid ? true : null" (change)="onRadiusUpdate()">100 Miles
        </label>
        <label class="radio-inline"><input type="radio" formControlName="zipcodeRadius" name="zipcodeRadius"
            value="50" aria-label="limit search to dealers within 50 miles" [attr.disabled]="!searchFilterForm.valid ? true : null" (change)="onRadiusUpdate()">
          50 Miles
        </label>
      </span>
    </div>
    <div class="form-group" id="model-select" role="radiogroup" (change)="onModelUpdate($event)">
      <label class="control-label" for="model-select">MODEL</label>
      <label *ngIf="availableCharger" class="radio">
        <input type="radio" formControlName="model" name="model" aria-label="Select Charger Daytona model" value="Charger Daytona 2-Door" [attr.disabled]="!searchFilterForm.valid ? true : null">
        Charger Daytona 2-Door
      </label>
      <label *ngIf="availableDurango" class="radio">
        <input type="radio" formControlName="model" name="model" aria-label="Select Durango model" value="Durango" selected="true" [attr.disabled]="!searchFilterForm.valid ? true : null">
        Durango
      </label>
    </div>
    <hr>
    <div class="form-group" id="trim-select">
      <!-- Dynamically changing label based on the selection -->
      <div *ngIf="searchFilterForm.get('model').value === 'Charger Daytona 2-Door'; else otherLabel">
        <label class="control-label">FIRST CALL</label>
      </div>
      <ng-template #otherLabel>
        <label class="control-label">SPECIAL EDITIONS</label>
      </ng-template>
      <div id="trim-select">

        <ng-container *ngIf="activeSpecialEditions[0].model === 'Charger Daytona 2-Door'">
          <span class="section-header control-label text-uppercase">R/T AWD 496 HP Stage 1</span>
          <div class="package-info">
            <span class="control-label">Packages Include:</span>
            <div class="features">
              <span *ngFor="let package of packages[0].packages" class="feature" (click)="openPackageInfoModal(packageInfoModal, $event, package, 0)">
                {{ package.name }} <i class="fa fa-info-circle"></i>
              </span>
            </div>
          </div>
          <ng-container *ngFor="let trim of activeSpecialEditions">
            <div *ngIf="trim.group === 'R/T AWD 496 HP STAGE 1'" class="radio">
              <label [for]="trim.trimCode">
                <input type="radio" [id]="trim.trimCode" [value]="trim.trimCode" formControlName="trimName" #theTrimName name="trimName" aria-label="select special edition for search" (change)="onSelectTrim(trim.trimCode, trim.label, trim.configId); trackTrimSelection(trim)" [attr.disabled]="trim.status">
                <span class="trim-list">
                  <span class="group" [innerHTML]="trim.color"></span>
                </span>
              </label>
              <span class="features">
                <span class="feature" *ngFor="let feature of trim.features" [innerHTML]="feature"></span>
              </span>
            </div>
          </ng-container>

          <span class="section-header control-label text-uppercase">SCAT PACK AWD 670 HP STAGE 2</span>
          <div class="package-info">
            <span class="control-label">Packages Include:</span>
            <div class="features">
              <span *ngFor="let package of packages[1].packages" class="feature" (click)="openPackageInfoModal(packageInfoModal, $event, package, 1)">
                {{ package.name }} <i class="fa fa-info-circle"></i>
              </span>
            </div>
          </div>
          <ng-container *ngFor="let trim of activeSpecialEditions">
            <div *ngIf="trim.group === 'SCAT PACK AWD 670 HP STAGE 2'" class="radio">
              <label [for]="trim.trimCode">
                <input type="radio" [id]="trim.trimCode" [value]="trim.trimCode" formControlName="trimName" #theTrimName name="trimName" aria-label="select special edition for search" (change)="onSelectTrim(trim.trimCode, trim.label, trim.configId); trackTrimSelection(trim)" [attr.disabled]="trim.status">
                <span class="trim-list">
                  <span class="group" [innerHTML]="trim.color"></span>
                </span>
              </label>
              <span class="features">
                <span class="feature" *ngFor="let feature of trim.features" [innerHTML]="feature"></span>
              </span>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="activeSpecialEditions[0].model === 'Durango'">
          <span class="section-header control-label text-uppercase">SRT 392 AlcHEMI 475 HP 6.4L SRT HEMI V8</span>
          <div *ngFor="let trim of activeSpecialEditions" class="radio">
            <label [for]="trim.trimCode">
              <input type="radio" [id]="trim.trimCode" [value]="trim.trimCode" formControlName="trimName" #theTrimName name="trimName" aria-label="select special edition for search" (change)="onSelectTrim(trim.trimCode, trim.label, trim.configId); trackTrimSelection(trim)" [attr.disabled]="trim.status">
              <span class="trim-list">
                <span class="group" [innerHTML]="trim.color"></span>
              </span>
            </label>
             <span class="feature" *ngFor="let feature of trim.features" [innerHTML]="feature"></span>
          </div>
        </ng-container>
        <!-- <ng-container *ngIf="searchFilterForm.get('model').value != 'Durango'">
          <hr id="top-hr">
          <div id="or-divider" class="text-center">OR</div>
          <hr id="bottom-hr">
          <label class="control-label" for="trim-select">TRIMS</label>
          <div *ngFor="let trim of activeTrims" class="radio">
            <label>
              <input type="radio" [value]="trim.trimCode" formControlName="trimName" #theTrimName name="trimName" aria-label="select a trim for search" (change)="onSelectTrim(trim.trimCode, trim.label); trackTrimSelectionBasic(trim)" [attr.disabled]="trim.status">
              <span [innerHTML]="trim.label"></span>
            </label>
          </div>
        </ng-container> -->
      </div>
    </div>
  </form>
</div>

<ng-template #packageInfoModal>
  <div class="modal-container">
    <div class="modal-header">
      <span>{{ activePackage.name }}</span>
      <button class="modal-close" (click)="packageInfoModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <ul class="list-package list-unstyled">
        <li *ngFor="let item of activePackage.features" [innerHTML]="item"></li>
      </ul>
    </div>
  </div>
</ng-template>
