<div id="rkn-page-2023">


    <!-- Hero Area -->
    <div class="rkn-hero">
        <div class="site-wrapper container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="hero-inner">
                        <div class="content">
                            <img src="/assets/images/rkn/direct-connection-logo.webp" alt="Direct Connection - Logo" width="220" height="45">
                            <h1 class="hero-heading">Grudge Match 2023</h1>
                            <p>
                                <strong>Six Roadkill rookies are chasing the ultimate glory –</strong> or a cold shot of humility. Lucky for them, each has an experienced mentor on their team tasked with one job – keep their rookie from gettin' smoked. Follow each team on their social channels as they build custom rides ready for the strip (powered by a Direct Connection Crate HEMI<sup>&reg;</sup> engine), then duke it out on Woodward Avenue August 12.
                            </p>
                            <p class="subheading">Revisit</p>
                            <div class="btn-container">
                                <a routerLink="/roadkill-nights-2022" class="btn btn-primary">
                                    Grudge Match 2022
                                </a>
                                <a routerLink="/roadkill-nights-2021" class="btn btn-primary">
                                    Grudge Match 2021
                                </a>
                            </div>
                        </div>
                        <div class="divider"></div>
                        <div class="logo">
                            <a href="https://www.motortrend.com/roadkillnights-2023/" target="_blank"  class="btn btn-primary">Get Your Tickets Today!</a>
                            <img src="/assets/images/rkn/roadkill-nights-logo.webp" alt="Roadkill Nights - Logo" width="300" height="135">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Participants Slider -->
    <div class="slider pt-5">
        <div class="site-wrapper container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div *ngIf="teams.length > 1" class="carousel-indicators"></div>
                    <swiper #swiper [config]="config">
                        <ng-template swiperSlide *ngFor="let team of teams; let i = index" >
                            <div class="slide-container">
                                <h2 class="slide-heading">
                                    <span class="text-primary">
                                        {{ (team.name).substring(0, team.name.indexOf(' ')) }}
                                    </span>
                                    {{ team.name.substring(team.name.indexOf(' ') + 1) }}
                                </h2>
                                <div class="tab-controls">
                                    <button class="btn btn-control active" (click)="toggleTab(0, $event)" type="button" aria-label="Tab Toggle - Rookie">Rookie</button>
                                    <button class="btn btn-control" (click)="toggleTab(1, $event)" type="button" aria-label="Tab Toggle - Mentor">Mentor</button>
                                </div>
                                <div class="participants">
                                    <div #participant class="participant" [class.active]="i === 0" *ngFor="let participant of team.participants; let i = index">
                                        <div class="media-container">
                                            <div class="flagged-image flag-bottom">
                                                <img [src]="participant.image" [alt]="participant.name" width="220" height="220">
                                                <div class="flag">
                                                    <strong>{{ participant.type }}</strong>
                                                </div>
                                            </div>
                                            <div class="socials">
                                                <a *ngFor="let item of participant.social" target="_blank" class="social-icon" [href]="item.url" [title]="'Social Icon: ' + item.platform">
                                                    <rkn-social-icons [property]="item.platform"></rkn-social-icons>
                                                </a>
                                            </div>
                                        </div>
                                        <h3 class="bio-heading">{{ participant.name }}</h3>
                                        <p [innerHTML]="participant.bio">Participant's Bio</p>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </swiper>
                    <div *ngIf="teams.length > 1" class="carousel-navigation">
                        <button id="carousel-next" class="carousel-button" type="button" aria-label="Carousel Slide Previous"><i class="fa fa-angle-right"></i></button>
                        <button id="carousel-prev" class="carousel-button" type="button" aria-label="Carousel Slide Next"><i class="fa fa-angle-left"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
</div>

<ngx-json-ld [json]="schema"></ngx-json-ld>





