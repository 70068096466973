import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'ringtone-instructions',
  templateUrl: './ringtone-instructions.component.html'
})
export class RingtoneInstructionsComponent implements OnInit {

  constructor(
    public title: Title,
    public metaService: Meta,
  ) {
    title.setTitle( 'Ringtone Instructions | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'Instructions to install ringtones on your mobile device' })
    metaService.updateTag({ property: 'og:description', content: 'Instructions to install ringtones on your mobile device' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | Ringtone Instructions' })
  }

  ngOnInit() {
  }

}
