import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Location } from "@angular/common";
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from '../../environments/environment';
import { DownloadsService } from './downloads.service';

@Component({
  selector: 'performance-cars-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.less']
})
export class DownloadsComponent implements OnInit, AfterViewInit {
  // set default active tab
  activeTab: String = 'wallpapers';

  //Prop set to capture service data and use in template via interpolation.
  wallpapers: any;
  featured: any;
  stencils: any;
  ringtones: any;
  coloringSheets: Array<any> = [];

  // JSON+LD schema
  schema: object = {};

  constructor(
    public title: Title,
    public metaService: Meta,
    public router: Router,
    private activeRoute: ActivatedRoute,
    public loc: Location,
    private downloadsService: DownloadsService
  ) {
    title.setTitle( 'Downloads | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'Download exclusive wallpapers for all of your devices' })
    metaService.updateTag({ property: 'og:description', content: 'Download exclusive wallpapers for all of your devices' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | Downloads' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
    this.wallpapers = this.downloadsService.getWallpapers();
    this.featured = this.downloadsService.getBirthdayCards();
    this.stencils = this.downloadsService.getStencils();
    this.ringtones = this.downloadsService.getRingtones();

    this.fetchColoringSheets();

    this.activeRoute.fragment.subscribe((fragment: string) => {
      // Handle bookmarked URLs with pumpkin/turkey hash
      if(fragment === 'pumpkin' || fragment === 'turkey') {
        // re-route links when not active
        // this.loc.go( this.activeRoute.url['value'][0].path);
        // otherwise activate active tab
        this.activeTab = fragment;
      }
      else !fragment ? this.activeTab = this.activeTab : this.activeTab = fragment;
    });
  }

  ngAfterViewInit() {
    this.prepareSchema();
  }

  updateHashOnTabChange(tab) {
    this.activeTab = tab;
    this.loc.go( this.activeRoute.url['value'][0].path + '#' + this.activeTab );
  }

  fetchColoringSheets() {
    this.downloadsService.getDownloads('Coloring Sheets').subscribe( data => {
      this.coloringSheets = data.files;
    });
  }

  prepareSchema() {
    // Prep image arrays
    let wallpaperSchemaArray = this.downloadsService.getSchemaWallpaperArray(this.wallpapers);
    let coloringSheetSchemaArray = this.downloadsService.getSchemaPDFArray(this.coloringSheets);
    let birthdaySchemaArray = this.downloadsService.getSchemaImageArray(this.featured);
    let ringtoneSchemaArray = this.downloadsService.getSchemaAudioArray(this.ringtones);

    this.schema = {
      "@context": "http://schema.org",
      "@type": "CollectionPage",
      "@id" : environment.url + this.router.url,
      "url" : environment.url + this.router.url,
      "name": "Dodge Garage | Downloads",
      "description": "Download exclusive wallpapers for all of your devices.",
      "hasPart": [
        {
          "@type": "Collection",
          "name": "Coloring Sheets",
          "hasPart": coloringSheetSchemaArray
        },
        {
          "@type": "ImageGallery",
          "name": "Birthday Cards",
          "image": birthdaySchemaArray
        },
        {
          "@type": "ImageGallery",
          "name": "Wallpaper",
          "image": wallpaperSchemaArray
        },
        {
          "@type": "Collection",
          "name": "Ringtones",
          "hasPart": ringtoneSchemaArray
        }
      ],
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Videos",
            "item": environment.url + "/downloads"
          },
        ]
      }
    };
  }

}
