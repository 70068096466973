import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { HelperService } from '../../services/helper.service';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'dg-dodge-reveal-livetream-banner',
  templateUrl: './dodge-reveal-livetream-banner.component.html',
  styles: []
})
export class DodgeRevealLivetreamBannerComponent implements OnInit {

  constructor(
    public helperService: HelperService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    // if (isPlatformBrowser(this.platformId)) {
    //   document.body.classList.add('nav-banner-open');
    // }
  }

}
