import { Component, OnInit } from '@angular/core';
import {UserService} from "../../user.service";

@Component({
  selector: 'dg-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {

  alert = {
    show: false,
    status: '',
    message: '',
    additionalMessage: ''
  };
  loading: boolean = false;

  constructor(
    public userService: UserService
  ) { }

  ngOnInit() {
  }

  forgotPassword(event) {
    event.preventDefault();
    this.loading = true;

    this.userService.forgotPassword().subscribe(data => {
      this.alert.show = true;
      this.alert.status = data['status'];
      this.alert.message = data['message'].join(' ');
      this.loading = false;
    }, (error) => {
      this.alert.show = true;
      this.alert.status = 'danger';
      this.alert.message = 'There was an error submitting your request.';
      this.loading = false;
    });

  }

  resendActivation(event) {
    event.preventDefault();
    this.loading = true;
    this.userService.resendActivation().subscribe( data => {
      this.alert.status = 'success';
      this.alert.additionalMessage = 'The activation email was sent successfully.';
      this.loading = false;
    }, (error) => {
      this.alert.additionalMessage = 'There was an error resending the activation email.';
      this.loading = false;
    });
  }

}
