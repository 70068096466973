<div class="colors">
  <label 
    *ngFor="let color of colors" 
    class="color-item" 
    [ngClass]="{'active': activeColor === color.code}" 
    [attr.data-code]="color.code" 
    (click)="updateActiveColor(color.code)">
    <div class="swatch" [ngStyle]="{'background-color': color.hex}"></div>  
    <div class="name">{{ color.name }}</div>
    <div class="price first">${{ color.price }}</div>
    <div class="price">(${{ color.priceCA }} CAD)</div>
    <input class="hidden" type="radio" name="currentColor" [value]="color.code" [checked]="activeColor === color.code">
  </label>
</div>