<div class="comment">
  <header class="comment-header">
    <div class="comment-user" *ngIf="post.user"><a [routerLink]="'/user/'+post.username">{{post.username}}</a></div>
    <div class="comment-user" *ngIf="!post.user">{{post.username}}</div>
    <div class="comment-meta">
      <time [dateTime]="post.created_at | date:'yyyy-MM-dd HH:mm'">{{post.created_at | date:'M/d/yy h:mmaaaaa\'m\''}}</time>
    </div>
  </header>
  <div *ngIf="!post.showEditArea" class="comment-body" [innerHTML]="post.cooked"></div>
  <div *ngIf="post.showEditArea">
    <dg-comment-form [topic]="post.topic" [postToEdit]="post" (editedCommentEmitter)="commentUpdated($event, post)" (cancelEditEmitter)="post.showEditArea = !post.showEditArea"></dg-comment-form>
  </div>
  <footer class="comment-footer">
    <button class="btn btn-sm btn-white" (click)="replyToPost(post)"><span class="sr-only">Reply</span><i class="fa fa-reply" aria-hidden="true"></i> {{(post.reply_count > 0)?post.reply_count:''}}</button>
    <button *ngIf="post.userCanAct" (click)="likeComment(post)" class="btn btn-sm btn-white"><span class="sr-only">Like</span><i class="fa fa-star-o" aria-hidden="true"></i> {{(post.likesCount > 0)?post.likesCount:''}}</button>
    <button *ngIf="post.userActed" [disabled]="!post.userCanUndo" (click)="unLikeComment(post)" class="btn btn-sm btn-white"><span class="sr-only">Unlike</span><i class="fa fa-star" aria-hidden="true"></i> {{(post.likesCount > 0)?post.likesCount:''}}</button>
    <button *ngIf="userService.userModel.isLoggedIn && post.username == userService.userModel.username && !post.isDeleted" (click)="editComment(post)" class="btn btn-sm btn-white"><span class="sr-only">Edit</span><i class="fa fa-edit"></i> </button>
    <button *ngIf="userService.userModel.isLoggedIn && post.username == userService.userModel.username && !post.isDeleted" (click)="openDeleteCommentModal(deleteModal)" class="btn btn-sm btn-white"><span class="sr-only">Delete Comment</span><i class="fa fa-trash-o"></i> </button>
    <button (click)="openFlagCommentModal(flagModal)" class="btn btn-sm btn-white" [disabled]="post.isFlaggedByUser" [ngClass]="{'hidden': (userService.userModel.isLoggedIn && post.username == userService.userModel.username) || post.isFlagged}">
      <span *ngIf="post.isFlaggedByUser">You've flagged this comment</span>
      <span class="sr-only">Flag</span>
      <i *ngIf="!post.isFlaggedByUser" class="fa fa-flag-o"></i>
      <i *ngIf="post.isFlaggedByUser" class="fa fa-flag"></i>
    </button>
  </footer>
</div>


<div *ngIf="post.showTextArea">
  <dg-comment-form [topic]="post.topic" [parentPost]="post" (newCommentEmitter)="addNewComment($event, post)"></dg-comment-form>
</div>

<div class="post-replies" *ngIf="post.reply_count > 0">
  <div *ngIf="!post.show_replies">
    <button class="btn btn-link btn-show-replies" (click)="post.show_replies = !post.show_replies;">Show thread ({{(post.reply_count == 1)?post.reply_count + ' Reply': post.reply_count + ' Replies'}})</button>
  </div>
  <div *ngIf="post.show_replies">
    <div *ngFor="let reply of post.replies">

      <dg-single-comment [post]="reply" (postCountEmitter)="postCountIncrease($event)"></dg-single-comment>
    </div>
  </div>
</div>

<!-- User Modal -->
<ng-template #userModal>
  <div class="modal-container">
    <div class="modal-header">
      <button class="modal-close" (click)="userModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="signup-form form-1320">
        <dg-user-onboarding class="step-form" (userLoggedIn)="closeUserModal()"></dg-user-onboarding>
      </div>
    </div>
  </div>
</ng-template>

<!--popup modal selection for delete information -->
<ng-template #deleteModal>
  <div class="modal-container modal-container-lg">
    <div class="modal-header">
      <h2 class="modal-title heading1 pull-left">Delete Comment</h2>
      <button class="modal-close" (click)="deleteModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <dg-delete-comment [post]="post" (deleteCommentEmitter)="commentDeleted($event)" (cancelDeleteEmitter)="deleteModalRef.hide()"></dg-delete-comment>
    </div>
  </div>
</ng-template>

<!--popup modal section for flag information -->
<ng-template #flagModal>
  <div class="modal-container modal-container-lg">
    <div class="modal-header">
      <h2 class="modal-title heading1 pull-left">Flag Comment</h2>
      <button class="modal-close" (click)="flagModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <dg-flag-comment [post]="post" (flagCommentEmitter)="commentFlagged($event)" (cancelFlagEmitter)="flagModalRef.hide()"></dg-flag-comment>
    </div>
  </div>
</ng-template>
