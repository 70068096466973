<div class="site-wrapper skinny-site-wrapper">
  <header class="page-header">
    <div class="px-md-4">
      <h1>2018 Dodge Challenger SRT<sup>&reg;</sup> Demon FAQ</h1>
      <p>So you've ordered your Dodge Challenger SRT<sup>&reg;</sup> Demon, and it's here! Whether you're just picking it up from the dealership or you've done your first burnout, you might have questions about the world's fastest quarter-mile production car. Let's get them answered.</p>
    </div>
  </header>
  <section class="px-xs-4 px-sm-4 mb-5">
    <h2 class="heading2 text-uppercase">Before You Pick It Up</h2>
    <dg-accordion [id]="'faq-accordion'" [multiselectable]="true" role="tablist">
      <dg-panel-collapse [title]="'faq-one'" [collapse]="'open'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Does the SRT Demon come with everything I need to drive it on the street?</h2>
        </ng-container>
        <ng-container body>
          <p>Yes, the SRT Demon is a street-legal automobile and will come to you ready to drive.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq-two'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Is there stuff I can add on when I pick it up? Where do I get all the race stuff like the skinny wheels and PCM?</h2>
        </ng-container>
        <ng-container body>
          <p>You can order the SRT Demon Crate from the Concierge: 800-998-1110, and it comes with the Mopar Direct Connection performance parts, personalized badges and SRT Demon-branded track tools.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq-three'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Can I put race fuel in it and head straight to the track?</h2>
        </ng-container>
        <ng-container body>
          <p>No, Dodge recommends at least 500 miles of break-in time before you change the PCM and 1500 miles before you start making drag passes. Please read your supplement, tip card, and track tech manual for the recommended break-in process.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq-four'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Once I get the crate, can the dealer install everything?</h2>
        </ng-container>
        <ng-container body>
          <p>This is between you and the dealer. The PCM is the only thing that has to be done by the dealer. The switch bank upgrade, air filter and mirror delete plate can be done by the customer or by the dealer.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq-five'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Do the skinny wheels come with tires? If not, what tires should I get, and where?</h2>
        </ng-container>
        <ng-container body>
          <p>No, the tires don't come with the skinny wheels. The front wheels are 18x4.5 inches. There are a couple different tire brands to choose from, one which we have used is 28/4.5-18, and should be available from any of the large performance parts warehouses.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq-six'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">I can hear all sorts of valvetrain or injector noise, is all that noise normal?</h2>
        </ng-container>
        <ng-container body>
          <p>The SRT Demon has much less noise and vibration insulation (NVH) than a standard street car. You'll be able to hear more mechanical sound than you might be used to.</p>
        </ng-container>
      </dg-panel-collapse>
    </dg-accordion>
  </section>
  <section class="px-xs-4 px-sm-4 mb-4">
    <h2 class="heading2 text-uppercase">Before You Race</h2>
    <dg-accordion [id]="'faq-accordion2'" [multiselectable]="true">
      <dg-panel-collapse [title]="'faq2-one'" [collapse]="'open'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">I did my break-in, got my crate, and had my dealer do the PCM upgrade. What now?</h2>
        </ng-container>
        <ng-container body>
          <p>Get some high-octane fuel and find a track nearby.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-two'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Any high-octane fuel? Will Sunoco SS100 keep me in the HO fuel mode or should I go for something like VP MS109?</h2>
        </ng-container>
        <ng-container body>
          <p>
            Testing was done with Sunoco 260GT or 260GT+.  When adding high octane fuel, remember that you may be mixing with pump gas in the tank. You need to make sure though that the octane rating in the tank <u>averages</u> 100 octane.
          </p>
          <p>
            Simple formula:
          </p>
          <p>(A x octane rating) + (B x octane rating) / (A+B) = Avg. Octane</p>
          <p>A = amount of premium fuel in the tank </p>
          <p>B = amount of high octane fuel in the tank </p>
          <p>So say 5 gal of 91 octane + 10 gal of 104 (GT260+)</p>
          <p>(5 x 91) + (10 x 104) / (5 + 10) = </p>
          <p>455 + 1040 / 15 =</p>
          <p>99.7 octane</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-three'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Are all dragstrips the same? Should I expect the same times wherever I go?</h2>
        </ng-container>
        <ng-container body>
          <p>Not at all. Dragstrips differ in altitude, weather, track surface, and track prep. Even the same track on the same day will change dramatically depending on temperature and surface treatment.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-four'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Why am I faulting out with Line Lock?</h2>
        </ng-container>
        <ng-container body>
          <p>The SRT Demon user guide says to be in Drive, but you can use Line lock in either Auto or Manual modes. If you've made sure line lock is on and you achieved the minimum brake pressure, (we recommend 1200-1400psi), we have seen people go to the gas pedal before the brake pedal is all the way at the top of its travel.  You have to wait for that to happen or the car will think you still have your foot on the pedal.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-five'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">How much burnout is enough burnout?</h2>
        </ng-container>
        <ng-container body>
          <p>Depends on if you're trying to win or just put on a show, but assuming you are trying to warm up the tires for a fast pass, you don't need that much wheel speed. Stationary burnout only needs to be 2-3 seconds to get a decent amount of smoke going, then let go of the “OK” button and drive the burnout forward 10-feet or so to staging.  If you have someone with you who can measure the tire temperature after the burnout you're looking for 180-220 degrees Fahrenheit with the Nitto tires.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-six'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">I've always started burnouts in Second gear. Can I do that in the SRT Demon?</h2>
        </ng-container>
        <ng-container body>
          <p>In Drag mode, you will have to start in First gear, but you can immediately shift to Second with the right paddle or the shifter once the tires start to spin. If you're not in Drag mode, once you go to the M gate you can bump the paddle or the shifter and start the burnout in Second gear.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-seven'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">How do I disable Line Lock if I don't want it?</h2>
        </ng-container>
        <ng-container body>
          <p>Line Lock can be cancelled on the same screen on the radio in which you turned it on. It will also cancel when you let go of the “ok” button on the steering wheel.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-eight'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Is there a way to practice?</h2>
        </ng-container>
        <ng-container body>
          <p>Practice makes perfect! You can practice the TransBrake process before going to the drag strip where people are watching you. You can run through all the steps of TransBrake in your driveway, all the way to releasing the last paddle to launch the car, just don't add throttle at that point.  Practice going to 1700 rpm when you're in the first steps of TransBrake activation (pull both paddles back, place one foot on the brake and the other on the throttle) and hold the rpm steady the entire time. If you try to push close to 2350 with the engine in torque reserve, there are flares in the rpm that can inadvertently push you over the 2350 limit and kick you out. Getting those steps ingrained so it becomes second nature makes a big difference!</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-nine'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">If you are on the TransBrake and you move the steering wheel even slightly can it limp?</h2>
        </ng-container>
        <ng-container body>
          <p>The TransBrake will cancel from the steering wheel turning, but only at +/- 45°, so there is a lot of room there.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-ten'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Why can't you use TransBrake like Launch Control, and just go WOT before launch?</h2>
        </ng-container>
        <ng-container body>
          <p>The TransBrake was engineered so the driver can still control the throttle rate. That way, when you are at a lesser-prepped track, you can slow the throttle rate to maintain grip. This gives the driver much more control.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-eleven'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">What is a good rpm to launch?</h2>
        </ng-container>
        <ng-container body>
          <p>1200-1500 rpm is a pretty good rpm to launch from, use your throttle feed rate to control tire slip. The TransBrake will go up to 2350 rpm if the track will hold it.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-twelve'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Why is there a limit at 2350 rpm for the TransBrake?</h2>
        </ng-container>
        <ng-container body>
          <p>RPM is limited to 2350 to maintain the durability of the driveline.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-thirteen'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">How do I engage the torque reserve and keep it from dropping out?</h2>
        </ng-container>
        <ng-container body>
          <p>To get torque reserve when you're in the two-foot process of the TransBrake you need to bring the revs up pretty quickly. There is a minimum rpm you have to keep (just a little over idle), to maintain torque reserve on.  If it does drop out of reserve, take your foot off the gas, let it come back to idle and then go into the gas again and it will come back on.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-fourteen'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">What is the best process for Launch Control?</h2>
        </ng-container>
        <ng-container body>
          <p>Launch Control is really intended for un-prepped surfaces rather than sticky drag-prepped surfaces. Foot-braking or the TransBrake are superior launch methods for a prepped track. But let's say you want to try out launch control on a prepped track. Here's how. Set the rpm you want before moving to the starting line.  After you do your burn out, pull forward and turn on the pre-stage lights, press the button on the center console, make sure that the button lights up indicating it's on, then creep forward to full stage.  Take one quick glance to make sure the button light is still on then mat the gas quickly.  Launch Control will time out if your foot is on the floor longer than 5 seconds. Launch Control will also cancel out if the car moves forward a large distance, but the creep from pre-stage to full stage isn't enough to turn it off.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-fifteen'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">What is best process for cooling off the SRT Demon?</h2>
        </ng-container>
        <ng-container body>
          <p>The chiller will do a great job of keeping temperatures down during hot lapping. If you are not hot-lapping, start the car for a couple seconds a few minutes before you head up to the staging lanes to let the after-run cool it off.  Once the after-run completes its cycle, start it up and put it in drag mode to run the chiller and that will get the intercooler to its coolest temperature.</p>
        </ng-container>
      </dg-panel-collapse>
      <dg-panel-collapse [title]="'faq2-sixteen'">
        <ng-container heading>
          <h2 class="heading2 question-q red">Q.</h2>
          <h2 class="question heading2 uppercase">Are there other items I can purchase to customize my SRT Demon or for racing?</h2>
        </ng-container>
        <ng-container body>
          <p>Yes, and the concierge and dealer can help direct you to the best place to purchase everything from customized car covers to a rear-seat delete package, to the Racelogic VBOX Video HD2 System.</p>
          <div class="row">
            <div class="col-sm-12 hero-section">
              <div class="heading-contain">
                <h2 class="h3">
                  <strong>Order through Concierge:</strong>
                </h2>
                <p><strong>Authentication Package: $1.00 –</strong> authentic build sheets for your VIN, unique carbon fiber ID card, document portfolio</p>
                <p><strong>Custom Car Cover: $695.00 –</strong> satin stretch indoor cover, matching your paint scheme and optionally embroidered with your name</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 hero-section">
              <div class="heading-contain">
                <h2 class="h3">
                  <strong>Order through Dealer and Mopar Performance:</strong>
                </h2>
                <p><strong>SRT Demon Street Tire Package: Late Availability –</strong> Mopar Part Number 82215919 includes a set of four lightweight forged 20x11-inch Devil's Rim wheels (stock wheel for Hellcat Widebody) and are delivered ready to bolt onto the vehicle with Pirelli 305/35ZR20 performance tires mounted and balanced with Tire Pressure Monitoring System sensors.</p>
                <p><strong>Rear-seat Delete Package: Late Availability –</strong> Mopar Part Number 82215918 allows you to remove the rear seat when heading to the track. The Rear-seat Delete Package includes seatback closeout panel, SRT Demon seatback panel bezel, lower seat tray with cargo net, seat belt delete C-pillar covers and rear packaging shelf to cover the tether holes.</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 hero-section">
              <div class="heading-contain">
                <h2 class="h3">
                  <strong>Order through Speedlogix and Racelogic:</strong>
                </h2>
                <p><strong>Speedlogix Four-Point Harness Bar: </strong> In its standard configuration, the SRT Demon's rear seat is deleted and mounting points are made accessible, making it easy to install the bar without cutting or drilling.</p>

                <a href="https://www.speedlogixstore.com/product-p/sx-4000.htm" target="_blank" rel="noopener"><p><strong>VISIT SPEEDLOGIX for more information</strong></p></a>

<!--                <p><strong>Racelogic VBOX Video HD2 System:</strong> Available from the producer of the industry standard VBOX systems, is the exclusive SRT Demon VBOX Video HD2 data logger and in-car camera system.</p>-->

<!--                <a href="https://www.vboxmotorsport.com/store/index.php?route=product/product&search=demon&product_id=137" target="_blank" rel="noopener"><p><strong>VISIT RACELOGIC for more information</strong></p></a>-->

              </div>
            </div>
          </div>
        </ng-container>
      </dg-panel-collapse>
    </dg-accordion>
  </section>
</div>
<section class="promo-bg">
  <div class="site-wrapper">
    <div class="promo-container">
      <div class="row-flex">
        <a class="col-flex-sm-6 col-flex-lg-4 ga-event" href="https://www.hagerty.com/insurance/dodge-demon" target="_blank" data-brand="Hagerty Insurance" data-ga_category="Demon FAQ" data-ga_action="Click" data-ga_label="Hagerty Insurance">
          <div class=" item logo-block text-uppercase text-center">
            <img loading="lazy" class="img-responsive" src="/assets/images/demon-faq/hagerty-logo.png" alt="Hagerty">
            <p>The official insurance provider of the SRT Demon</p>
            <span class="btn btn-default" data-ga_category="Demon FAQ" data-ga_action="Click" data-ga_label="Hagerty Insurance">Learn More</span>
          </div>
        </a>
<!--        <a class="col-flex-sm-6 col-flex-lg-3 ga-event" href="https://www.vboxmotorsport.com/store/index.php?route=product/product&amp;search=demon&amp;product_id=137" target="_blank" data-brand="RaceLogic" data-ga_category="Demon FAQ" data-ga_action="Click" data-ga_label="RaceLogic">-->
<!--          <div class="item logo-block text-uppercase text-center">-->
<!--            <img loading="lazy" class="img-responsive" src="/assets/images/demon-faq/racelogic-logo.png" alt="RaceLogic">-->
<!--            <p>SRT Demon VBOX Video HD2 System</p>-->
<!--            <span class="btn btn-default" data-ga_category="Demon FAQ" data-ga_action="Click" data-ga_label="RaceLogic">Shop Now</span>-->
<!--          </div>-->
<!--        </a>-->
        <a class="col-flex-sm-6 col-flex-lg-4 ga-event" href="https://www.speedlogixstore.com/product-p/sx-4000.htm" target="_blank" data-brand="Speedlogix" data-ga_category="Demon FAQ" data-ga_action="Click" data-ga_label="Speedlogix">
          <div class="item logo-block text-uppercase text-center">
            <img loading="lazy" class="img-responsive" src="/assets/images/demon-faq/speedlogix-logo.png" alt="Speedlogix">
            <p>SRT Demon four-point harness bar kit</p>
            <span class="btn btn-default" data-ga_category="Demon FAQ" data-ga_action="Click" data-ga_label="Speedlogix">Shop Now</span>
          </div>
        </a>
        <a class="col-flex-sm-6 col-flex-lg-4 ga-event" href="https://www.life.dodge.com/welcome.asp" target="_blank" data-brand="SRT Demon FAQ" data-ga_category="Demon FAQ" data-ga_action="Click" data-ga_label="Dodge Shop">
          <div class="item logo-block text-uppercase text-center">
            <img loading="lazy" class="img-responsive" src="/assets/images/demon-faq/demon-logo.png" alt="SRT Demon">
            <p>The Home for Official Dodge Gear</p>
            <span class="btn btn-default" data-ga_category="Demon FAQ" data-ga_action="Click" data-ga_label="Dodge Shop">Shop Now</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
