<div id="brag-book" class="site-wrapper"> 
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 media-container-flex">
        <div class="media-item media-caption intro-copy">
          <h1 class="heading1 heading-img">
            <img loading="lazy" src="/assets/images/ultimate-last-chance/ultimate-last-chance-text-treatment.png" alt="The Ultimate Last Chance" class="img-title">
          </h1>
          <p>
            This is your one and only chance to own both the final production 2017 Dodge Viper and 2018 Dodge Challenger SRT<sup class="realign superscript">®</sup> Demon. These one-of-a-kind models, both painted Viper Red with black Alcantara interiors and unique badges, will be sold together as one lot at the <a routerLink="/events/155/barrett-jackson-northeast" class="ga-event" data-ga_category="Ultimate Last Chance" data-ga_action="Event CTA" data-ga_label="/events/155/barrett-jackson-northeast">Barrett-Jackson Northeast Auction on Saturday June 23.</a> With 100% of the hammer proceeds being donated to United Way, the Ultimate Last Chance auction is your opportunity to make a difference by making history. 
          </p>
        </div>
        <div class="media-item media-asset">
          <div class="brag-book-embed embed-responsive embed-responsive-16by9">
            <iframe title="The Ultimate Last Chance" class="embed-responsive-item" src='https://spark.adobe.com/page/TefNgHYlgdXuh/'></iframe>
          </div>
          <div class="embed-footer text-right">
            <a href="/assets/docs/dodge-ultimate-last-chance-auction-book.pdf" class="btn btn-primary" target="_blank" rel="noopener" (click)="helperService.trackGAEvent('Ultimate Last Chance', 'Brag Book Download', '/ultimate-last-chance-book')">Download PDF</a>
            <a routerLink="/ultimate-last-chance-book-full" target="_blank" rel="noopener" class="btn btn-primary" (click)="helperService.trackGAEvent('Ultimate Last Chance', 'Brag Book Full Screen', '/ultimate-last-chance-book')">View Full Screen</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
