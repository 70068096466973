import {Inject, AfterViewInit, ElementRef, OnInit, Component, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {Router} from "@angular/router";

interface Window {
  SMCX?: any;
}

declare var window: Window;

@Component({
  selector: 'dg-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.less']
})
export class SurveyComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    /*const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'http://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdy3w2b7rEzl2dip8qP1D22MSIGAxyBtepNH27v0noN9q.js';
    const __this = this; //to store the current instance to call
                         //afterScriptAdded function on onload event of
                         //script.
    s.onload = function () { __this.afterScriptAdded(); };
    this.elementRef.nativeElement.appendChild(s);*/

    if (isPlatformBrowser(this.platformId)) {
      var frame = document.querySelector('iframe'),
        //indicator = document.querySelector('#frame-state'),
        ignoreFirstLoad = true,
        timeout = null;

      frame.addEventListener('load', event => {
        if (ignoreFirstLoad) {
          ignoreFirstLoad = false;
          return;
        }

        //console.log('lick change');
        this.router.navigate(['/']);

        // some link was clicked or frame was reloaded
        //indicator.classList.add('loaded');
        clearTimeout(timeout);

        timeout = setTimeout(function () {
          //indicator.classList.remove('loaded');
        }, 1000);
      });
    }
  }

  afterScriptAdded() {
    const params= {
      width: '350px',
      height: '420px',
    };
    if (typeof (window['functionFromExternalScript']) === 'function') {
      window['functionFromExternalScript'](params);
    }
  }

}
