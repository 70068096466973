import { Track } from './track';
import { HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackLocatorService {

  constructor(
    private http: HttpClient
  ) { }

  getTracks(searchType: string, zipcode: string = null, trackName: string = null, city: string = null, state: string = null): Observable<any> {
    let url = environment.url + '/rest/track';
    let Params = new HttpParams();
    Params = (searchType) ? Params.append('searchType', searchType) : Params;
    Params = (zipcode) ? Params.append('zipcode', zipcode) : Params;
    Params = (trackName) ? Params.append('trackName', trackName) : Params;
    Params = (city) ? Params.append('city', city) : Params;
    Params = (state) ? Params.append('state', state) : Params;
    return this.http.get(url, { params: Params });
  }

  mutateTracks(track: Track) {
    track.zipcode = track['postalCode'];
    if (!track.website.match(/^[a-zA-Z]+:\/\//) && track.website.length > 0)
    {
      track.website = 'http://' + track.website;
    }
    return track;
  }
}
