<!-- Instagram -->
<span *ngIf="property === 'instagram'">
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="42" viewBox="0 0 44 42" fill="none">
    <path d="M22.1539 10.3225C16.0894 10.3225 11.1978 15.0898 11.1978 21.0001C11.1978 26.9104 16.0894 31.6776 22.1539 31.6776C28.2184 31.6776 33.11 26.9104 33.11 21.0001C33.11 15.0898 28.2184 10.3225 22.1539 10.3225ZM22.1539 27.9419C18.2349 27.9419 15.031 24.8288 15.031 21.0001C15.031 17.1714 18.2253 14.0583 22.1539 14.0583C26.0825 14.0583 29.2768 17.1714 29.2768 21.0001C29.2768 24.8288 26.0729 27.9419 22.1539 27.9419ZM36.1136 9.88577C36.1136 11.2704 34.9694 12.3763 33.5582 12.3763C32.1374 12.3763 31.0027 11.2611 31.0027 9.88577C31.0027 8.51042 32.1469 7.39527 33.5582 7.39527C34.9694 7.39527 36.1136 8.51042 36.1136 9.88577ZM43.37 12.4134C43.2079 9.07729 42.426 6.12214 39.9182 3.6874C37.42 1.25266 34.3877 0.490638 30.9646 0.323365C27.4365 0.128214 16.8618 0.128214 13.3337 0.323365C9.92008 0.481345 6.88784 1.24336 4.38005 3.67811C1.87226 6.11285 1.09989 9.06799 0.928258 12.4041C0.728016 15.8425 0.728016 26.1484 0.928258 29.5867C1.09036 32.9229 1.87226 35.878 4.38005 38.3128C6.88784 40.7475 9.91054 41.5095 13.3337 41.6768C16.8618 41.872 27.4365 41.872 30.9646 41.6768C34.3877 41.5188 37.42 40.7568 39.9182 38.3128C42.4165 35.878 43.1984 32.9229 43.37 29.5867C43.5703 26.1484 43.5703 15.8518 43.37 12.4134ZM38.8121 33.276C38.0684 35.0974 36.6285 36.5007 34.7501 37.2348C31.9372 38.3221 25.2624 38.0712 22.1539 38.0712C19.0454 38.0712 12.3611 38.3128 9.55773 37.2348C7.68881 36.51 6.24897 35.1067 5.49568 33.276C4.38005 30.5346 4.6375 24.0296 4.6375 21.0001C4.6375 17.9706 4.38958 11.4563 5.49568 8.72416C6.23944 6.90275 7.67927 5.49952 9.55773 4.76538C12.3707 3.67811 19.0454 3.92901 22.1539 3.92901C25.2624 3.92901 31.9467 3.6874 34.7501 4.76538C36.619 5.49022 38.0588 6.89345 38.8121 8.72416C39.9278 11.4656 39.6703 17.9706 39.6703 21.0001C39.6703 24.0296 39.9278 30.5439 38.8121 33.276Z" fill="white"/>
  </svg>
</span>

<!-- YouTube -->
<span *ngIf="property === 'youtube'"> 
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="40" viewBox="0 0 55 40" fill="none">
    <path d="M53.0997 6.82282C52.4821 4.46039 50.6624 2.59981 48.3519 1.9684C44.164 0.821045 27.371 0.821045 27.371 0.821045C27.371 0.821045 10.5781 0.821045 6.39014 1.9684C4.07967 2.59991 2.25996 4.46039 1.64235 6.82282C0.520203 11.1049 0.520203 20.039 0.520203 20.039C0.520203 20.039 0.520203 28.9731 1.64235 33.2551C2.25996 35.6175 4.07967 37.4006 6.39014 38.032C10.5781 39.1794 27.371 39.1794 27.371 39.1794C27.371 39.1794 44.1639 39.1794 48.3519 38.032C50.6624 37.4006 52.4821 35.6175 53.0997 33.2551C54.2219 28.9731 54.2219 20.039 54.2219 20.039C54.2219 20.039 54.2219 11.1049 53.0997 6.82282ZM21.8788 28.1504V11.9275L35.9144 20.0392L21.8788 28.1504Z" fill="white"/>
  </svg>
</span>

<!-- Facebook -->
<span *ngIf="property === 'facebook'">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" fill="white" /></svg>
</span>

<!-- TikTok -->
<span *ngIf="property === 'tiktok'">
  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="42" viewBox="0 0 38 42" fill="none">
    <path d="M37.5202 17.2206C33.8829 17.2283 30.3352 16.1006 27.3783 13.9967V28.6639C27.3774 31.3803 26.5415 34.0318 24.9824 36.2636C23.4233 38.4954 21.2153 40.2012 18.6537 41.1528C16.0921 42.1044 13.2991 42.2565 10.6481 41.5888C7.99708 40.921 5.61456 39.4652 3.81914 37.4161C2.02373 35.367 0.901017 32.8222 0.601169 30.1222C0.301321 27.4221 0.838629 24.6955 2.14123 22.3069C3.44384 19.9184 5.44963 17.9818 7.89036 16.7562C10.3311 15.5307 13.0904 15.0745 15.7992 15.4487V22.8233C14.5606 22.436 13.2305 22.4473 11.9987 22.8557C10.767 23.264 9.69659 24.0484 8.94043 25.0969C8.18427 26.1455 7.78099 27.4045 7.78817 28.6943C7.79534 29.984 8.21262 31.2385 8.9804 32.2787C9.74818 33.3189 10.8272 34.0915 12.0634 34.4863C13.2997 34.881 14.6299 34.8778 15.8641 34.4769C17.0983 34.0761 18.1735 33.2981 18.9361 32.2542C19.6987 31.2102 20.1097 29.9537 20.1104 28.6639V0.00219727H27.3783C27.3742 0.612726 27.4267 1.22233 27.5352 1.82329C27.7879 3.16286 28.313 4.43717 29.0784 5.56834C29.8438 6.69951 30.8334 7.66377 31.9867 8.4022C33.6284 9.47914 35.5528 10.0526 37.5202 10.051V17.2206Z" fill="white"/>
  </svg>
</span>