import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

import {Post} from './post';
import {Topic} from "./topic";
import {UserModel} from "../user/user";
import {HelperService} from "../services/helper.service";
import {UserService} from "../user/user.service";
import {writevSync} from "fs";

@Injectable()
export class CommentService {

  title: string =  Math.random().toString(36).substring(2, 15)+' '+Math.random().toString(36).substring(2, 15);

  constructor(
    private helperService: HelperService,
    private http: HttpClient,
    public userService: UserService
  ){}




  //--------------------------------
  // User Details
  //--------------------------------
  createNewUser(user: UserModel): Observable<any> {
    let data = {
      "name": user.username,
      "email": user.email,
      "password": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      "username": user.username,
      "active": 'true',
      "approved": 'true',
      "overrideUsername": true, //Will use the dgapiuser instead of users username
      "discourseEndpoint": 'users.json',
      "discourseEndpointMethod": "POST"
    }

    return this.http.post(environment.discourseApiUrl, data);
  }

  activateNewUser(discourseUserId: number): Observable<any> {

    let data = {
      "user_id": discourseUserId.toString(),
      "discourseEndpoint": '/admin/users/'+ discourseUserId + '/activate',
      "discourseEndpointMethod": "PUT"
    }

    return this.http.post(environment.discourseApiUrl, data);
  }

  getDiscourseUser(username: string): Observable<any> {
    let data = {
      "overrideUsername": true, //Will use the dgapiuser instead of users username
      "discourseEndpoint": 'u/'+username+'.json',
      "discourseEndpointMethod": "GET"
    }
    return this.http.post(environment.discourseApiUrl, data);
  }

  //--------------------------------
  // Create/Update/Delete Comment
  //--------------------------------
  //Post a new comment
  postNewComment(comment: string, username: string, discourseTopicId: number, parentPost: Post): Observable<any> {
    let data = {
      "topic_id": discourseTopicId.toString(),
      "raw": comment,
      "discourseEndpoint": 'posts.json',
      "discourseEndpointMethod": "POST",
      "username": username
    }

    if (parentPost.id != 0) {
      data["reply_to_post_number"] = parentPost.post_number.toString()
    }

    return this.http.post(environment.discourseApiUrl, data);
  }



  //Update comment from an edit. This also DELETES a comment by changing the comment to [delete]
  updateComment(post: Post, modifiedComment: string, username: string): Observable<any> {
    let data = {
      "raw": modifiedComment,
      "discourseEndpoint": 'posts/'+post.id+'.json',
      "discourseEndpointMethod": "PUT",
      "username": username
    }

    return this.http.post(environment.discourseApiUrl, data);
  }

  //Get all top level comments
  getPostsByTopicId(topicId: number, username:string): Observable<any> {
    let data = {
      "discourseEndpoint": 't/'+topicId+'.json',
      "discourseEndpointMethod": "GET",
      "username": username
    }

    console.log('topic data from comment service getPostsByTopicId', data, environment.discourseApiUrl);

    return this.http.post(environment.discourseApiUrl, data);
  }

  //Get all sub-comments of a comment
  getAllPostsByPostIds(topicId: number, userName:string, stringIds: string): Observable<any> {
    let data = {
      "discourseEndpoint": 't/'+topicId+'/posts.json?'+stringIds,
      "discourseEndpointMethod": "GET",
      "username": userName
    }

    return this.http.post(environment.discourseApiUrl, data);
  }


  //--------------------------------
  // Create/Retrieve Categories
  //--------------------------------
  //Create new category
  createNewCategory(polymorphicType: string): Observable<any> {
    let data = {
      "name": polymorphicType,
      "color": this.helperService.generateColorHexCode(),
      "text_color": 'FFFFFF',
      "discourseEndpoint": 'categories.json',
      "discourseEndpointMethod": "POST",
      "overrideUsername": true //Will use the dgapiuser instead of users username
    }

    return this.http.post<Post>(environment.discourseApiUrl, data);
  }

  //Get categories
  getCategories(): Observable<any> {
    let data = {
      "discourseEndpoint": 'categories.json',
      "discourseEndpointMethod": "GET"
    }

    return this.http.post(environment.discourseApiUrl, data);
  }


  //--------------------------------
  // Create/Retrieve Topics
  //--------------------------------
  //Create new topic
  createNewTopic(topic: Topic): Observable<any> {

    //Wordpress does some things with unicodes and html. This will help clean those up before storing the title in discourse.
    // console.log('Topic Title');
    // console.log(topic.title);

    //.replace(/^[a-z\d\-_\s]+$/i, '')
    let topicTitle = this.html_entity_decode(topic.title).replace(/<[^>]*>?/gm, '').replace('&amp;', '&').replace('&lt;', '<').replace('&gt;', '>');


    let data = {
      "title": topicTitle,
      "raw": `<a href="${topic.url}" target="_blank">${topic.url}</a><br>First comment for this topic. Discourse does not allow the first comment to be deleted so we create a dummy comment.`,
      "category": topic.discourseCategoryId,
      "discourseEndpoint": 'posts.json',
      "discourseEndpointMethod": 'POST',
      "overrideUsername": true //Will use the dgapiuser instead of users username
    }

    return this.http.post<Post>(environment.discourseApiUrl, data);
  }


  //--------------------------------
  // Like/Unlike A Comment
  //--------------------------------
  likePostByPostId(postId: number, username: string): Observable<any> {
    let data = {
      "id": postId.toString(),
      "post_action_type_id": '2',
      "discourseEndpoint": 'post_actions',
      "discourseEndpointMethod": "POST",
      "username": username
    }

    return this.http.post(environment.discourseApiUrl, data);
  }

  unlikePostByPostId(postId: number, username: string): Observable<any> {
    let data = {
      "discourseEndpoint": 'post_actions/'+postId+'?post_action_type_id=2',
      "discourseEndpointMethod": "DELETE",
      "username": username
    }

    return this.http.post(environment.discourseApiUrl, data);
  }


  //--------------------------------
  // Flag A Comment
  //--------------------------------
  flagPost(post: Post, username: string, flagReason: string, customReason: string) {

    // TODO What should happen after a post is flagged?
    // Discourse Flag Reasons
    // Reason = post_action_type_id
    // 3 = Off Topic
    // 4 = Inappropriate
    // 8 = Spam
    // 7 = Something Else

    let data = {
      "id": post.id.toString(),
      "post_action_type_id": flagReason,
      "discourseEndpoint": 'post_actions',
      "discourseEndpointMethod": "POST",
      "username": username
    }

    if (customReason) {
      data["message"] = customReason
    }

    return this.http.post(environment.discourseApiUrl, data);
  }


  //--------------------------------
  // Cleaning Comments
  //--------------------------------
  //This function helps determine what features are turned on/off like can they flag, like, etc..
  processPostAndReplies(parentPost, posts = [], topic: Topic = new Topic()) {
    //Mark if deleted. This is added to the comment when soft deleting
    if (parentPost.edit_reason == 'Deleted by User'){
      parentPost.isDeleted = true;
    }

    //Mark if flagged
    if (parentPost.hidden) {
      // Hidden means the comment was flagged by admin
      parentPost.cooked = `<p>[deleted]</p>`;
      parentPost.isFlagged = true;
    }

    //Process other actions
    parentPost.actions_summary.forEach( action_summary => {
      if (action_summary.id == 2) {
        // Logged in user has liked post
        parentPost.likesCount = (action_summary && action_summary.count) ? action_summary.count : 0;
        parentPost.userActed = (action_summary && action_summary.acted) ? action_summary.acted: false;
        parentPost.userCanAct = (action_summary && action_summary.can_act) ? action_summary.can_act: false;
        parentPost.userCanUndo = (action_summary && action_summary.can_undo) ? action_summary.can_undo: false;
      }
      else if (action_summary.id == 3 || action_summary.id == 4 || action_summary.id == 7 || action_summary.id == 8) {
        // Logged in user has flagged post
        parentPost.isFlaggedByUser = (action_summary && action_summary.acted) ? action_summary.acted: false;
      }
    });

    // Attach the topic
    parentPost.topic = topic;

    // Update any mentions to link to the user's profile
    let newURL = '\\href="/u/';
    parentPost.cooked = parentPost.cooked.replace(new RegExp(newURL, 'g'), 'href="/user/');

    // Process comments recursively for each nested comment
    if (Array.isArray(posts) && posts.length) {
      parentPost.replies = posts.filter(post => post.reply_to_post_number == parentPost.post_number);
      parentPost.replies.forEach( post => {
        this.processPostAndReplies(post, posts, topic);
      });
    }

    return parentPost;
  }

  //This create a div so that we can pass in a string and have htmlentities automatically convert the unicode characters
  html_entity_decode(message) {
    var element = document.createElement("div");
    element.innerHTML = message;
    return element.innerHTML;
  }

}
