export class JuicerPost {
  poster_display_name: any;
  poster_name: any;
  poster_image: any;
  full_url: any;
  source: any;
  like_count: any;
  comment_count: any;
  message: any;
  external_created_at: any;
  image: any;
}
