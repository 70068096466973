import { Component, Input, OnInit} from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { EventService } from '../event.service';
import { Router } from '@angular/router';
import { Event } from '../event';

@Component({
  selector: 'dg-event-card',
  templateUrl: './event-card.component.html'
})
export class EventCardComponent implements OnInit {

  @Input() event;
  isEventImageLoaded: boolean = false;
  eventPath: string = '';

  constructor(
    public helperService: HelperService,
    private eventService: EventService,
    private router: Router
  ) { }

  ngOnInit() {
    this.eventPath = this.eventService.getEventPath(this.event);
  }

  goToEvent(event: Event, e) {
    e.preventDefault();
    if (this.eventService.isEventViewFull) {
      this.router.navigate([this.eventService.getEventPath(event)]);
    } else {
      this.eventService.eventOpen = event;
      this.eventService.setModalOpen(true);
    }
  }

}
