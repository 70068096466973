
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {HelperService} from "../services/helper.service";

@Injectable()
export class ContestService {

  constructor(
    private helperService: HelperService,
    private http: HttpClient
  ) { }

  enterContest(contestId):Observable<Object> {
    let url = '/rest/contest/enter/'+contestId;
    return this.http.post(url, {}).pipe(catchError(this.helperService.handleError));
  }

}
