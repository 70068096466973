import { OnInit, AfterViewInit, Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'build-videos',
  templateUrl: './build-videos.component.html',
  styleUrls: ['./build-videos.component.less']
})
export class BuildVideosComponent implements OnInit, AfterViewInit  {

  // Take videos in from parent to populate tiles
  @Input() videos: any;

  // Event emitted to populate modal on parent
  @Output() activeVideo: EventEmitter<any> = new EventEmitter();
  
  // Toggles coming soon assets if no videos are present
  comingSoon: boolean = false;

  // Slider
  @ViewChild('videoContainer') videoContainer;
  galleryCentered: boolean = false;
  selectedTile: number = 0;
  controlsVisible: boolean = false;
  slideStep: number = 0;
  

  constructor( public helperService: HelperService ) {
    
  }

  async ngOnInit() {
    await this.videos === undefined || this.videos.length === 0 ? this.comingSoon = true : this.comingSoon = false;
    //console.log(this.videos === undefined, this.comingSoon, this.videos);

    if (!this.comingSoon) {
      this.initGallery(this.videoContainer.nativeElement);
    }
      
  }

  ngAfterViewInit() {
    
  }

  // Build active video url on tile click. Send to parent.
  emitActiveVideo(video) { 
    this.activeVideo.emit('https://www.youtube.com/embed/' + video + '?rel=0&modestbranding=1&showinfo=0');
    this.helperService.trackGAEvent('RKN Tavarish Build Videos', 'Click', 'Video ID - ' + video);
  }

  slideGallery(direction: string) {
    const trackPosition = this.videoContainer.nativeElement.getBoundingClientRect().x;
    const items = document.querySelectorAll('.grid-item');
    let gridItemWidth; // Not transformed skew
    let item;
    let itemWidth;


    // Loop through grid items to reliably find an inactive item to use as constant width for sliding
    items.forEach(video => {
      if (video.querySelector('.skew-wrapper:not(.active)')) {
        gridItemWidth = Math.round(video.getBoundingClientRect().width);
        item = video.querySelector('.skew-wrapper:not(.active)').getBoundingClientRect();
        itemWidth = Math.round(item.width);
      }
    });

    if (direction === 'forwards') {
      // if left is less than item width * 3
      if ( trackPosition > ( (itemWidth * this.videos.length) - ((itemWidth * 2) + window.innerWidth) ) * -1 ) {
        this.slideStep++
        this.videoContainer.nativeElement.style.left = `${ ((itemWidth * this.slideStep) * -1) }px`;

        // console.log(
        //   'Track Position: ' + trackPosition, 
        //   'El Position: ' + this.videoContainer.nativeElement.getBoundingClientRect().x, 
        //   'Item Width: ' + itemWidth,
        //   'First: ' + (itemWidth * this.videos.length),
        //   'Second: ' + ((itemWidth * 2) + window.innerWidth),
        //   'Full width: ' + ( (itemWidth * this.videos.length) - ((itemWidth * 2) + window.innerWidth) ) * -1,
        //   'Step: ' + this.slideStep
        // );
      }
    }
    
    if (direction === 'backwards') {
      // if left is less than item width * 3
      //if (this.videoContainer.nativeElement.style.left > (itemWidth * 3) * -1) {
      if (this.slideStep > 0) {
        this.slideStep--
        this.videoContainer.nativeElement.style.left = `${ ((itemWidth * (this.slideStep)) * -1) }px`;
      } else {
        this.videoContainer.nativeElement.style.left = '0px';
        this.slideStep = 0;
      }

      //console.log(this.videoContainer.nativeElement.getBoundingClientRect().x);
      //console.log(this.videoContainer.nativeElement.style.left);
    }
  }

  initGallery(element: HTMLElement) {

    const minWidth = window.matchMedia('(min-width: 600px)');

    // console.log('dimensions', element.getBoundingClientRect());
    // console.log('window width', window.innerWidth);

    // If we're in desktop view and we have videos, init controls and layouts
    if (minWidth && this.comingSoon == false) {
      
      // If there arent enough videos, center them and keep controls hidden.
      this.videos.length < 4 ? this.galleryCentered = true : this.galleryCentered = false;

      // If there are 5 or more videos, show the controls
      this.videos.length > 4 ? this.controlsVisible = true : this.controlsVisible = false;

      //console.log('children', document.querySelectorAll('.grid-item')[0].querySelector('.skew-wrapper').getBoundingClientRect());
      
    }
  }

}


// Check window width greater than 600 for row gallery
// Check if there are 3 or more grid items for centering
// Check if there are 5 or more grid items 
