import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import {Router} from "@angular/router";
import SwiperCore, {Navigation, Pagination, SwiperOptions, Virtual} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import { teams } from './rkn-teams';
declare let ga: Function;
declare let addthis: any;

SwiperCore.use([Virtual, Pagination, Navigation]);

@Component({
  selector: 'dg-rkn-landing-page',
  templateUrl: './rkn-landing-page.component.html',
  styleUrls: ['./rkn-landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class Rkn2023LandingPageComponent implements OnInit {
  
  constructor(
    public title: Title,
    public metaService: Meta,
    public sanitizer: DomSanitizer,
    private router: Router,
  ) {
    title.setTitle( 'Roadkill Nights Direct Connection Grudge Match 2023 | Dodge Garage' );
    metaService.updateTag({ name: 'description', content: 'The Direct Connection Grudge Match 2023 lights up Woodward Avenue on August 12 at Motortrend presents Roadkill Nights powered by Dodge.' })
    metaService.updateTag({ property: 'og:description', content: 'The Direct Connection Grudge Match 2023 lights up Woodward Avenue on August 12 at Motortrend presents Roadkill Nights powered by Dodge.' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Roadkill Nights Direct Connection Grudge Match 2023 | Dodge Garage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: environment.url + '/assets/images/rkn/roadkill-nights-teaser-background.webp' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: environment.url + '/assets/images/rkn/roadkill-nights-teaser-background.webp' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  teams: Array<any> = teams;

  // structured data for event
  schema: object = {
    "@context" : "http://schema.org",
    "@type" : "Event",
    "name" : "Roadkill Nights Direct Connection Grudge Match 2023",
    "image" : environment.url + '/assets/images/rkn/roadkill-nights-teaser-background.webp',
    "url" : environment.url + '/roadkill-nights',
    "startDate": "2023-08-12",
    "location": {
      "@type": "Place",
      "name": "M1 Concourse in Pontiac, MI",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "1 Concourse Dr",
        "addressLocality": "Pontiac",
        "addressRegion": "MI",
        "postalCode": "48341"
      }
    },
    "description": "The Direct Connection Grudge Match 2023 lights up Woodward Avenue on August 12 at Motortrend presents Roadkill Nights powered by Dodge."
  };

  // Swiper config
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  config: SwiperOptions = {
    slidesPerView: 1,
    //spaceBetween: 35,
    centeredSlides: true,
    loop: true,
    speed: 200,
    initialSlide: this.getRandomNumber(this.teams.length + 1),
    navigation: {
      'nextEl': '#carousel-next',
      'prevEl': '#carousel-prev'
    },
    pagination: {
      el: '.carousel-indicators',
      clickable: true,
      type: 'bullets',
      bulletActiveClass: 'active',
      bulletClass: 'carousel-nav-item'
    }
  };
  

  ngOnInit() {
    // Add AddThis to Page
    const script = document.createElement('script');
    script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
    document.body.appendChild(script);

    // Reload AddThis
    setTimeout(() => {
      addthis.layers.refresh();
    }, 1500);
  }

  // Random number generator used to display initial team slides at random.
  getRandomNumber(max) {
    return Math.floor(Math.random() * max);
  }

  toggleTab(index, event) {
    const parent = event.target.parentElement.parentElement;

    // Toggle active control
    const controls = parent.querySelectorAll('.btn-control');
    controls.forEach(el => { el.classList.remove('active'); });
    event.target.classList.add('active');

    // Toggle active participant
    parent.querySelectorAll('.participant').forEach(el => { el.classList.remove('active'); });
    parent.querySelectorAll('.participant')[index].classList.add('active');
  }

}
