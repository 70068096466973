import {Injectable} from "@angular/core";
import { UserModel } from "../../user/user";
import {Vehicle} from "../../vehicle/vehicle";

enum Status {
  Approved = "APPROVED",
  Rejected = "REJECTED",
  Pending = "PENDING"
}

export class TimeSlip {
  id: number = 0;
  rank: number = 0;
  date: string;
  raceTrack: string;
  elapsedTime: number;
  sixtyFeetTime: number;
  topSpeed: number;
  status: string;
  timeslip: File;
  timeslipAndVin: File;
  imagePath1: string;
  imagePath2: string;
  isStock: boolean;
  user: UserModel = new UserModel();
  vehicle: Vehicle = new Vehicle();
  comment: string = '';
  discourseTopicId: number;
  discourseCategoryId: number;
}

@Injectable()
export class TimeSlipHelper {
  isApproved(status) {
    return status === 'APPROVED'
  }
}
