export const PackageInfo = [
  {
    model: 0, // R/T AWD 496 HP Stage 1
    packages: [
      {
        name: "Plus Group",
        features: [
          "Attitude adjustment lighting",
          "Premium LED headlamps with cross-car LED daytime running lamps signature light",
          "Exterior mirrors with logo lamps",
          "Frunk",
          "Leather performance seats",
          "Power 8-way driver & passenger seat, 2-way lumbar",
          "Heated & ventilated front seats",
          "Heated second row seats",
          "16-inch all digital cluster upgrade",
          "Heads up display",
          "Wireless phone charge pad",
          "NFC smart key & phone key",
        ]
      },
      {
        name: "Sun & Sound",
        features: [
          "18 speaker Alpine PRO High Performance Audio",
          "Full glass roof (fixed & tinted)",
        ]
      },
      {
        name: "Blacktop",
        features: [
          "Dark 20x9-inch Blacknoise wheels standard",
          "Dark 20x10-inch Blacknoise wheels w/ plus group",
          "Dark exterior badging",
          "Dark exhaust tips",
        ]
      }
    ]
  },
  {
    model: 1, // SCAT PACK AWD 670 HP STAGE 2
    packages: [
      {
        name: "Track Pack",
        features: [
          "Front 20x11-inch / Rear 20x11.5-inch aluminum wheels",
          "Front 305/35R20 / Rear 325/35R20 A/S tires",
          "Black 1-piece performance spoiler",
          "High back leatherette/suede performance seats",
          "Driver radio and seat memory",
          "Red Brembo 6-piston fixed front rotors with two piece fixed rear rotor",
        ]
      },
      {
        name: "Plus Group",
        features: [
          "Attitude adjustment lighting",
          "Premium LED headlamps with cross-car LED daytime running lamps signature light",
          "Exterior mirrors with logo lamps",
          "Frunk",
          "High back leather performance seats",
          "Power 12-way driver & passenger fixed head restraint seat",
          "4-way lumbar",
          "Heated & ventilated front seats",
          "Heated second row seats",
          "16-inch all digital cluster upgrade",
          "Heads up display",
          "Wireless phone charge pad",
          "NFC smart key & phone key",
          "Phone access to vehicle cameras",
        ]
      },
      {
        name: "Sun & Sound",
        features: [
          "18 speaker Alpine PRO High Performance Audio",
          "Full glass roof (fixed & tinted)",
        ]
      },
      {
        name: "Carbon & Suede",
        features: [
          "Dark front 20x11-inch / Dark rear 20x11-inch aluminum wheels",
          "Front 305/35R20 / Rear 305/35R20 A/S tires",
          "Carbon fiber exterior mirrors",
          "Carbon fiber interior accents",
          "Dark exterior badging",
          "Heated exterior mirrors",
          "IP with carbon fiber & suede wrap",
          "Leather/suede performance seats",
          "Suede headliner",
        ]
      }
    ]
  }
]