import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'startup-procedures',
  templateUrl: './startup-procedures.component.html',
})

export class DragPakStartupComponent implements OnInit {
  ngOnInit(): void {

  }

}

