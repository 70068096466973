import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from "../../../environments/environment";
import {Router} from "@angular/router";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'performance-cars-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.less']
})
export class FaqComponent implements OnInit {

  expirationDate = new Date('July 7, 2018 00:00:00')
  expired = false

  schoolClosedModalRef: BsModalRef;
  @ViewChild('schoolClosedModal') public schoolClosedModal: TemplateRef<any>;

  constructor(
    public title: Title,
    public metaService: Meta,
    private router: Router,
    private modalService: BsModalService
  ) {
    title.setTitle( 'High Performance Driving School FAQs | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'Frequently asked questions for the Bondurant High Performance Driving School' })
    metaService.updateTag({ property: 'og:description', content: 'Frequently asked questions for the Bondurant High Performance Driving School' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | High Performance Driving School FAQs' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
    this.expire()
    //this.openModal(this.schoolClosedModal);
  }

  expire() {
    let currentDate = new Date()
    currentDate > this.expirationDate ? this.expired = true : this.expired = false
  }

  openModal(template: TemplateRef<any>) {
    this.schoolClosedModalRef = this.modalService.show(template);
  }

}
