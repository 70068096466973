import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'ecu-launch-setting',
  templateUrl: './ecu-launch-setting.component.html',
})

export class DragPakECULaunchSettingComponent implements OnInit {
  ngOnInit(): void {

  }

}
