import { Component, OnInit } from '@angular/core';
import {UserService} from "../../user/user.service";

@Component({
  selector: 'performance-cars-activation-banner',
  templateUrl: './activation-banner.component.html',
  styleUrls: ['./activation-banner.component.less']
})
export class ActivationBannerComponent implements OnInit {

  constructor(
    public userService: UserService
  ) { }

  ngOnInit() {
  }

}
