import {Component, Input, OnInit, Inject, PLATFORM_ID} from '@angular/core';
import {PollService} from "../poll.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Poll} from "../poll";
import {UserService} from "../../user/user.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {HelperService} from "../../services/helper.service";
import {isPlatformBrowser} from "@angular/common";

declare let addthis: any;

@Component({
  selector: 'dg-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.less']
})
export class PollComponent implements OnInit {

  @Input() pollId: number = 0;
  poll: Poll = new Poll();
  pollForm: FormGroup;
  isLoading: boolean = false;
  isSharingIconActive: boolean = false;
  sharingURL: string = environment.url;

  constructor(
    private helperService: HelperService,
    private pollService: PollService,
    private router: Router,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.createPollForm();
    this.fetchPoll(this.pollId);
    this.sharingURL = environment.url + this.router.url + '#poll';
  }

  createPollForm() {
    this.pollForm = new FormGroup({
      'answer': new FormControl('', [
        Validators.required
      ])
    });
  }

  fetchPoll(id: number) {
    this.pollService.getPoll(id).subscribe( data => {
      this.poll = data;
      if (this.poll.voted && isPlatformBrowser(this.platformId)) {
        // Add AddThis to Page
        const script = document.createElement('script');
        script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
        document.body.appendChild(script);
        // Reload AddThis
        setTimeout(() => {
          addthis.layers.refresh();
        }, 3000);
      }
    });
  }

  submit() {
    // Check all fields before submitting
    Object.keys(this.pollForm.controls).forEach(field => {
      const control = this.pollForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.pollForm.valid) { // If form is valid, proceed

      this.helperService.trackGAEvent('Poll', 'Click', 'Submission');

      this.isLoading = true;
      this.pollService.submitVote(this.poll, this.pollForm.controls.answer.value, this.userService.userModel).subscribe(data => {
        this.poll.voted = true;
        this.poll.answers = data.answers;
        this.poll.totalVotes = data.totalVotes;
        let maxVoteCount = 0;
        this.poll.answers.forEach( answer => {
          if (maxVoteCount < answer.votes.length)
            maxVoteCount = answer.votes.length;
        });
        this.poll.answers.forEach( answer => {
          if (maxVoteCount == answer.votes.length)
            answer.isHighestVotes = true;
        });

        if (isPlatformBrowser(this.platformId)) {
          // Add AddThis to Page
          const script = document.createElement('script');
          script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
          document.body.appendChild(script);
          // Reload AddThis
          setTimeout(() => {
            addthis.layers.refresh();
          }, 3000);
        }

        this.isLoading = false;
      });
    }
  }

}
