<div class="row">
  <div class="col-sm-12">

    <div class="row mb-3">
      <div class="col-sm-12">
        <h2>Post-run checklist</h2>
      </div>
      <div class="col-sm-12 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/post-run-checklist/1.webp" alt="Post-Run Checklist - Drag Pak 3/4 View" class="img-responsive" width="1440" height="720">
      </div>
      <div class="col-sm-12">
        <p>
          After each pass with the Drag Pak, you need to check the fluid levels, monitor engine and transmission temps, check tire pressure and wheel lug nut torque.
        </p>
        <div class="well mb-3">
          <p class="mb-0">
            <strong>NOTE:</strong> The 2021 Drag Pak should be cooled down for a recommended 30-45 minutes after each pass. It should not be hot-lapped.
          </p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row mb-5">
      <div class="col-sm-12">
        <h3 id="hemi-engine">354-cubic-inch HEMI<sup>&reg;</sup> engine</h3>
        <p>
          Between runs, monitor the water level in the cooling system and check for potential leaks around the reservoir and overflow tanks. It’s recommended to run the water pump and fan for cool-down, especially when the car is back in the pits and the engine is turned off. Do not let the engine temp exceed 180 degrees Fahrenheit. Use an external generator hooked to the car’s main power switch, as this will allow operation of the electric water pump and fan without draining the battery. The engine temperature readings can be found on the Holley Digital Dash. <a href="/assets/docs/drag-pak/holley-digital-dash-manual.pdf" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="holley-digital-dash-manual.pdf" target="_blank">Download the Holley Digital Dash instructions for more information on using the Holley Digital Dash and downloading run data.</a>
        </p>
      </div>
    </div>
    
    <div class="row mb-5">
      <div class="col-sm-12">
        <h3 id="transmission">Transmission</h3>
        <p>
          The transmission fluid level should be checked after each run. Because hot-lapping can cause damage to the transmission, a 30-45 minute cool-down period between each run is recommended to allow the trans fluid temp to stabilize. You can also run fans and other cooling aids under the car to help cool the transmission fluid between runs.
        </p>
        <div class="well mt-3">
          <p class="mb-0"><strong>NOTE:</strong> The Drag Pak’s transmission should be rebuilt after every race season or when more than 40 passes have been made.</p>
        </div>
      </div>
    </div>
    
    <div class="row mb-5">
      <div class="col-sm-12">
        <h3 id="tire-pressure">Tire Pressure</h3>
        <p>
          Recheck the tire pressure in both the front and rear tires. The slicks will grow in size due to heat generated from the burnout and the run. Record the tire pressure before and after each run for reference and adjust if needed.
        </p>
      </div>
    </div>
    
    <div class="row mb-5">
      <div class="col-sm-12">
        <h3 id="wheel-lug-nuts">Wheel lug nuts</h3>
        <p>
          Re-torquing the lug nuts on the wheels after each run is recommended. Torque the rear wheels to 130 ft.-lbs. and the fronts to 95 ft.-lbs.
        </p>
      </div>
    </div>
    
    <div class="row mb-5">
      <div class="col-sm-12">
        <h3 id="record-the-data">Record the data</h3>
        <p>
          It's important to log each run you make. This valuable information will aid you in fine tuning the engine calibration, chassis/suspension settings, tire pressure requirements and most of all your driving skills. The more data you have, the more consistent both the car and driver will react in competition.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mt-5 text-center btn-container">
        <a routerLink="/drag-pak-experience/ecu-launch-setting" class="btn btn-primary btn-footer-nav mr-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Initial ECU Launch Setting"><i class="fa fa-chevron-left" aria-hidden="true"></i> <span>Initial ECU Launch Setting</span></a>
        <a routerLink="/drag-pak-experience" class="btn btn-primary btn-footer-nav ml-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="2021 Dodge Challenger Mopar Drag Pak"><span>2021 Dodge Challenger Mopar<sub>&reg;</sub> Drag Pak</span> <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
      </div>
    </div>

  </div>
</div>
