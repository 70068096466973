import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GalleryModel } from '../gallery';

@Component({
  selector: 'dg-gallery-card',
  templateUrl: './gallery-card.component.html',
  styles: []
})
export class GalleryCardComponent implements OnInit {

  @Input() galleryItem = new GalleryModel();
  @Output() openGalleryItemEmitter = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  openGalleryItem(event) {
    event.preventDefault();
    this.openGalleryItemEmitter.emit(this.galleryItem);
  }

}
