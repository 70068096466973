<div class="site-wrapper">
  <div class="container-fluid">
    <div class="container-grid">
      <div>
        <header class="page-header">
          <h1>Color Palette</h1>
        </header>

        <div class="color-swatches">
          <div class="color-swatch swatch-primary-01">
            Primary 01<br>
            #000000
          </div>
          <div class="color-swatch swatch-primary-02">
            Primary 02<br>
            #FFFFFF
          </div>
          <div class="color-swatch swatch-primary-03">
            Primary 03<br>
            #DC0000
          </div>
          <div class="color-swatch swatch-secondary-01">
            Secondary 01<br>
            #ECECEC
          </div>
          <div class="color-swatch swatch-secondary-02">
            Secondary 02<br>
            #D9D9D9
          </div>
          <div class="color-swatch swatch-secondary-03">
            Secondary 03<br>
            #BDBDBD<br>
            (Low Contrast Error)
            
          </div>
          <div class="color-swatch swatch-secondary-04">
            Secondary 04<br>
            #A5A5A5<br>
            (Low Contrast Error)
          </div>
          <div class="color-swatch swatch-secondary-05">
            Secondary 05<br>
            #7F7F7F<br>
            (Low Contrast Error)
          </div>
          <div class="color-swatch swatch-secondary-06">
            Secondary 06<br>
            #585858
          </div>
          <div class="color-swatch swatch-secondary-07">
            Secondary 07<br>
            #3E3E3E
          </div>
          <div class="color-swatch swatch8">
            Success<br>
            #5CB85C
          </div>
          <div class="color-swatch swatch9">
            Danger<br>
            #DC0000
          </div>
          <div class="color-swatch swatch10">
            Warning<br>
            #FADC09
          </div>
        </div>
      </div>

      <div>
        <header class="page-header">
          <h1>Typography</h1>
        </header>

        <h1 class="heading1">Heading 1</h1>
        <p class="article-meta">Font: Trade Gothic Condensed No. 20 / Secondary 03 #585858</p>

        <hr>

        <h2 class="heading2">Heading 2</h2>
        <p class="article-meta">Font: Trade Gothic Condensed No. 20 / Secondary 03 #585858</p>

        <hr>

        <h3 class="heading3">Heading 3</h3>
        <p class="article-meta">Font: Trade Gothic Condensed No. 20 / Secondary 03 #585858</p>

        <hr>

        <div class="single-article-container">
          <div class="entry-content mb-0">
            <p>Article Text</p>
            <p>Lorem ipsum dolor sit amet, quis quam, fusce duis. Montes vestibulum esse, tristique dui lorem. Wisi cubilia. Nonummy justo, eros aliquet elit, nulla sollicitudin ut. Iaculis sit lacus, nisi orci nunc, pede convallis vestibulum.</p>
            <p>Sed tellus. Posuere est quis, lacus sit nec. Ultricies vehicula arcu, nunc nonummy id. Vivamus odio neque, faucibus duis. Non diam amet, elit nec semper.</p>
          </div>
        </div>

        <hr>

        <p>Body Text</p>
        <p>Lorem ipsum dolor sit amet, quis quam, fusce duis. Montes vestibulum esse, tristique dui lorem. Wisi cubilia. Nonummy justo, eros aliquet elit, nulla sollicitudin ut. Iaculis sit lacus, nisi orci nunc, pede convallis vestibulum.</p>
        <p>Sed tellus. Posuere est quis, lacus sit nec. Ultricies vehicula arcu, nunc nonummy id. Vivamus odio neque, faucibus duis. Non diam amet, elit nec semper.</p>

        <p><a href="#">This is a link</a> - <a href="#" class="hover">This is a hover link</a></p>

        <p class="article-meta">Font: Aileron / Black #252525</p>
      </div>
      <div>
        <header class="page-header">
          <h1>Buttons</h1>
        </header>

        <p>
          <button class="btn btn-primary">Primary</button>&nbsp;
          <button class="btn btn-outline-primary">Outline Primary</button>&nbsp;
          <button class="btn btn-outline-primary-white">Outline Primary White</button>&nbsp;
          <button class="btn btn-outline-dark">Outline Dark</button>&nbsp;
          <button type="button" class="btn btn-link">Link</button>
        </p>
        <p>
          <button class="btn btn-lg btn-primary">Primary (lg)</button>&nbsp;
          <button class="btn btn-primary">Primary (default)</button>&nbsp;
          <button class="btn btn-sm btn-primary">Primary (sm)</button>&nbsp;
          <button class="btn btn-xs btn-primary">Primary (xs)</button>&nbsp;
        </p>

        <div class="dark-background">
          <p>
            <button class="btn btn-primary">Primary</button>&nbsp;
            <button class="btn btn-outline-primary">Outline Primary</button>&nbsp;
            <button class="btn btn-outline-primary-white">Outline Primary White</button>&nbsp;
            <button class="btn btn-outline-dark">Outline Dark</button>&nbsp;
            <button type="button" class="btn btn-link">Link</button>
          </p>
          <p>
            <button class="btn btn-lg btn-primary">Primary (lg)</button>&nbsp;
            <button class="btn btn-primary">Primary (default)</button>&nbsp;
            <button class="btn btn-sm btn-primary">Primary (sm)</button>&nbsp;
            <button class="btn btn-xs btn-primary">Primary (xs)</button>&nbsp;
          </p>
        </div>

      </div>
      <div>
        <header class="page-header">
          <h1>Form Elements</h1>
        </header>
        <dg-style-guide-form></dg-style-guide-form>
      </div>
      <div>
        <div class="dark-background">
          <header class="page-header">
            <h1>Form Elements (Dark)</h1>
          </header>
          <dg-style-guide-form></dg-style-guide-form>
        </div>
      </div>
      <div>
        <header class="page-header">
          <h1>Alerts</h1>
        </header>
        <dg-alert-banner [alert]="{show: true, status: 'success', message: 'This is a success message.'}"></dg-alert-banner>
        <dg-alert-banner [alert]="{show: true, status: 'danger', message: 'This is an alert message.'}"></dg-alert-banner>
        <dg-alert-banner [alert]="{show: true, status: 'warning', message: 'This is an warning message.'}"></dg-alert-banner>
      </div>
      <div class="grid-span-2">

        <header class="page-header">
          <h1>Article</h1>
        </header>

        <article class="site-wrapper single-article-container" itemscope itemtype="http://schema.org/Article">
          <div class="featured-img">
            <img loading="lazy" class="img-responsive" src="/assets/images/default-news-image.jpg" alt="Dodge Garage News">
          </div>
          <div class="container-fluid">
            <header class="entry-header">
              <div class="article-meta article-read-time">1 min read</div>
              <h1 class="entry-title">Article Title</h1>
              <div class="entry-meta post-time">
                <span><i class="fa fa-clock-o"></i>&nbsp;5/12/2020 7:00 PM </span>
                <span class="cat-label"><a href="/news/category/racing"><i class="fa fa-bookmark"></i>&nbsp;Racing</a></span>
                <span class="cat-label"><a href="/news/category/video"><i class="fa fa-bookmark"></i>&nbsp;Video</a></span>
                <span><a href="/news/author/dodge-garage"><i aria-hidden="true" class="fa fa-user"></i> Dodge Garage </a></span></div>
            </header>
            <div class="entry-content">
              <p>Lorem ipsum dolor sit amet, quis quam, fusce duis. Montes vestibulum esse, tristique dui lorem. Wisi cubilia. Nonummy justo, eros aliquet elit, nulla sollicitudin ut. Iaculis sit lacus, nisi orci nunc, pede <a href="#">convallis vestibulum</a>.</p>
              <p>Sed tellus. Posuere est quis, lacus sit nec. Ultricies vehicula arcu, nunc nonummy id. Vivamus odio neque, faucibus duis. <a href="#">Non diam</a> amet, elit nec semper.</p>
              <ol>
                <li>List item</li>
                <li>List item
                  <ol>
                    <li>List item</li>
                    <li>List item
                      <ol>
                        <li>List item</li>
                        <li>List item</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>List item</li>
              </ol>
              <ul>
                <li>List item</li>
                <li>List item
                  <ul>
                    <li>List item</li>
                    <li>List item
                      <ul>
                        <li>List item</li>
                        <li>List item</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>List item</li>
              </ul>
              <dg-style-guide-gallery></dg-style-guide-gallery>
            </div>
            <div class="article-tags">
              <span class="text-uppercase">Read More About:</span>&nbsp;
              <a class="btn btn-outline-primary" href="/news/tag/don-schumacher-racing">Don Schumacher Racing</a>
              <a class="btn btn-outline-primary" href="/news/tag/md-anderson-dodge-charger-srt-hellcat-funny-car">MD Anderson Dodge Charger SRT Hellcat Funny Car</a>
              <a class="btn btn-outline-primary" href="/news/tag/nhra">NHRA</a>
              <a class="btn btn-outline-primary" href="/news/tag/tommy-johnson-jr">Tommy Johnson Jr</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</div>
