import { Injectable } from '@angular/core';
import { HelperService } from '../services/helper.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { RKNContributor} from './rkn-contributor';

@Injectable()
export class RKN2022ContributorsService {

  constructor(
    public helperService: HelperService,
    private http: HttpClient
  ) {

  }

  public getContributors(): Observable<any> {
    return this.http.get(environment.url + '/assets/json/rkn-contributors.json').pipe(
      map((contributors: Array<RKNContributor>) => {
        return contributors
      }, error => error)
    );
  }
}
