<div class="row">
  <div class="col-sm-12">

    <div class="row mb-3">
      <div class="col-sm-12">
        <h2>Initial Track Test</h2>
      </div>
      <div class="col-sm-12 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/initial-track-test/1.webp" alt="Initial Track Test - Drag Pak burnout" class="img-responsive" width="1440" height="667">
      </div>
      <div class="col-sm-12">
        <p>
          To understand and take advantage of the 2021 Drag Pak’s full potential, it’s important to follow these guidelines the first time you race the car at the drag strip. We recommend renting a track or attending a test and tune session. This gives you the opportunity to make multiple runs to better acclimate yourself to the car’s characteristics, while getting familiar with the numerous operating functions. The more runs you make in your Drag Pak, the more confident driver, tuner and racer you’ll become. 
        </p>
        <div class="well mb-3">
          <p class="mb-0">
            <strong>NOTE:</strong> The 2021 Drag Pak should be cooled down for a recommended 30-45 minutes after each pass. It should not be hot-lapped.
          </p>
        </div>
        <p><strong>For your initial track test, these simple but important tips are highly recommended.</strong></p>
        <p><strong>Ensure the track has a clean and prepared drag strip and always has emergency personnel onsite.</strong></p> 
        <p>
          It’s important the drag strip has been properly prepped, or a lack of traction will result in excessive tire spin and negate gaining run data. Most tracks use a traction compound that gets sprayed from the starting line to a predetermined length down the strip. It’s also recommended the track gets dragged with used tires/slicks mounted on the back of a tractor. This procedure puts down a fresh layer of rubber after the traction compound has been applied. Make sure these have both been completed before making your initial runs. 
        </p>
        <p>
          Walk the length of the track, including the shutdown area, looking for debris. This gives you the opportunity to check for bumps or other track surface irregularities that could impact the performance and handling of the car. Be sure to note the finish line, as you’ll be approaching it at a high rate of speed during the quarter-mile run!
        </p>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12">
        <h3 id="proper-burnouts">Proper burnout procedures</h3>
      </div>
      <div class="col-sm-12">
        <p>
          Burnout procedures have many variables, but a good starting point is to roll through the burnout box so the rear slicks are just on the contact patch of the water’s outer edge coming out of the box. Make sure switch #8 is turned off and then begin your burnout in second gear with the Line Lock applied by holding the button on the shifter. <a routerLink="/drag-pak-experience/startup-procedures" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="Intro and Start-Up Procedures">Refer to the Intro & Start-Up Procedures section for more details on how to use the Line Lock.</a>
        </p>
        <p>
          Give the engine part throttle to begin the burnout. The rear slicks will start spinning freely and tire smoke should begin to emerge in the rear wheel wells. The time and length of the burnout can vary, but a massive Funny Car style burnout isn’t needed and will only cause premature wear of the slicks. The car may want to hook after a few seconds into the burnout, and this is your cue to release the Line Lock button, drive out of the burnout box and toward the starting line. At this time, turn switch #8 back on to activate the Two-Step/Launch Control function.
        </p>
        <div class="well">
          <p>
            It helps to have a crew member assist during the burnout to:
          </p>
          <ul class="mb-0">
            <li>Motion the driver into the water box</li>
            <li>Indicate to the driver when burnout is adequate</li>
            <li>Help the driver stage by indicating where the Christmas Tree pre-stage beam is in relation to the front tires</li>
          </ul>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <div class="row mb-5">
      <div class="col-sm-12">
        <h3 id="launch-procedure">Launch procedure</h3>
      </div>
      <div class="col-sm-12">
        <p>
          <strong>It's important that you familiarize yourself with the launch capabilities of the 2021 Drag Pak prior to running full passes. </strong>
        </p>
        <p>
          Start by making partial passes down the track to get comfortable with the level of torque and grip when trying to launch off the starting line. The Two-Step/Launch Control, tire pressure, shock and suspension settings and track prep are all factors that will impact how the car reacts. 
        </p>
        <div class="well">
          <p class="mb-0">
            <strong>NOTE:</strong> Make sure you log all passes and record the data. This will help establish a baseline on the car’s performance when making changes to the calibration, suspension and tire pressure. This is very important when dialing-in your Drag Pak. Track all adjustments and track conditions and keep diligent notes. Only make one change at a time and note the results to develop a deeper understanding of how to dial-in the full potential of your Drag Pak. 
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h4>60-foot runs</h4>
        <p>
          The first 60 feet of a pass are critical, as the time will indicate whether the car is hooking or spinning the tires. The proper burnout procedures should be followed and the car should be lined up in the starting line groove. If there’s excessive rear tire spin at the launch, abandon the run. Do repeated 60-foot runs as needed until the car and track are working in sync. 
        </p>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Eighth-mile runs</h4>
        <p>
          Once you’ve made the proper adjustments with the Two-Step/Launch Control, shocks/suspension and the track’s starting line surface has bite, your 60-foot runs will improve, and you’re ready for eighth-mile runs. These runs will acclimate you to the shifter operation during a full throttle pass, as you’ll make two gear changes by mid-track. It will also train you to watch the dash-mounted shift light, indicating when to shift. 
        </p>
      </div>
      <div class="col-sm-12 mb-3">
        <h4>Quarter-mile runs</h4>
        <p>
          With the experience gained during the 60-foot and eighth-mile procedures, a full quarter-mile run will showcase the Drag Pak’s true potential. Make sure the car is going straight, stable and in control. Use the Clean Neutral feature on the shifter once you’ve passed the finish line. <a routerLink="/drag-pak-experience/startup-procedures" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="Intro and Start-Up Procedures">Refer to the Intro & Start-Up Procedures section for more details on its operation.</a>
        </p>
      </div>
      <div class="col-sm-12">
        <div class="well">
          <p class="mb-0">
            <strong>NOTE:</strong> It’s important to constantly monitor the car’s oil pressure and water temp at all times during the initial track test. <a routerLink="/drag-pak-experience/startup-procedures" class="ga-event" data-ga_category="DragPkOwn_inline_link" data-ga_label="Intro and Start-Up Procedures">Refer to the Intro & Start-Up Procedures section for these specific details.</a>
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mt-5 text-center btn-container">
        <a routerLink="/drag-pak-experience/pre-run-checklist" class="btn btn-primary btn-footer-nav mr-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Pre-Run Checklist"><i class="fa fa-chevron-left" aria-hidden="true"></i> <span>Pre-Run Checklist</span></a>
        <a routerLink="/drag-pak-experience/ecu-launch-setting" class="btn btn-primary btn-footer-nav ml-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="Initial ECU Launch Setting"><span>Initial ECU Launch Setting</span> <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
      </div>
    </div>

  </div>
</div>
