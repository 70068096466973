import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'livestream-embed',
  templateUrl: './livestream-embed.component.html',
  styleUrls: ['./livestream-embed.component.scss']
})
export class LivestreamEmbedComponent implements OnInit {

  defaultEmbed: string = "https://rumble.com/embed/v5eb4xh/?pub=1wzsg2";
  secondaryEmbed: string = "https://rumble.com/embed/v5eb5h1/?pub=1wzsg2";
  
  // Update prop with current embed code url. Will be sanitized and output to the iframe.
  embedCode: string = this.defaultEmbed;
  safeEmbed: any;

  // Set to false to remove SpeedVideo sponsorship assets.
  sponsored: boolean = false; 

  hasSchedule: boolean = false;

  // Track the active round
  activeRound: number = 3;

  constructor(public sanitizer: DomSanitizer) {
    this.safeEmbed = this.sanitizer.bypassSecurityTrustResourceUrl( this.embedCode );
  }

  ngOnInit() {
    this.checkDateAndSetRound();
  }

  checkDateAndSetRound() {
    const today = new Date();
    const round4Date = new Date('2024-10-06T00:00:00');

    if (today >= round4Date) {
      this.updateEmbed(4);
    } else {
      this.updateEmbed(3);
    }
  }

  updateEmbed(round: number) {
    this.safeEmbed = this.sanitizer.bypassSecurityTrustResourceUrl( round === 4 ? this.secondaryEmbed : this.defaultEmbed );
    this.activeRound = round;
  }

}
