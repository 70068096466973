import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterFormComponent } from './forms/register-form/register-form.component';
import { LoginFormComponent } from './forms/login-form/login-form.component';
import { SharedModule } from '../shared/shared.module';
import { UserOnboardingComponent } from './user-onboarding/user-onboarding.component';
import { ForgotPasswordComponent } from './forms/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    RegisterFormComponent,
    LoginFormComponent,
    UserOnboardingComponent,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    TooltipModule.forRoot()
  ],
  exports: [
    RegisterFormComponent,
    LoginFormComponent,
    UserOnboardingComponent,
    ForgotPasswordComponent
  ]
})
export class UserSharedModule { }
