import { Component, OnInit } from '@angular/core';
import { DownloadsService } from '../downloads.service';

@Component({
  selector: 'ringtones',
  templateUrl: './ringtones.component.html'
})
export class RingtonesComponent implements OnInit {

  constructor(
    private downloadsService: DownloadsService
  ) { }

  //Prop set to capture service data and use in template via interpolation.
  ringtones: any;

  ngOnInit() {
    this.ringtones = this.downloadsService.getRingtones();
    this.scriptInit("https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css")
    this.mapInit("https://code.jquery.com/jquery-3.2.1.slim.min.js")
    this.mapInit("https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js")
    this.mapInit("https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js")
  }

  mapInit(stringLink) {
    let script = document.createElement('script');
    script.src = stringLink;
    script.setAttribute("data-cfasync", "false");
    script.setAttribute("charset", "utf-8");
    document.body.appendChild(script);
  }
  scriptInit(stringLink) {
    let script = document.createElement('link');
    script.href = stringLink;
    script.setAttribute("data-cfasync", "false");
    script.setAttribute("charset", "utf-8");
    document.head.appendChild(script);
  }
  previewAudio(e) {

    if( !e ) e = window.event

    let allAudio: any = document.querySelectorAll('.audio-preview')
    let allBtns: any = document.querySelectorAll('.btn-preview.audio')
    let btnPreview = e.currentTarget as HTMLElement
    let parent = btnPreview.closest('.ringtone')
    let audio = parent.querySelector('.audio-preview') as HTMLMediaElement


    //set all button icons to default play
    allBtns.forEach( x => x.classList.remove('playing'))

    if (!audio.paused || audio.currentTime) {
      audio.load()
    }else {
      allAudio.forEach( x => x.load() )
      audio.play()
      btnPreview.classList.add('playing')
    }

    audio.addEventListener('ended', () => allBtns.forEach( x => x.classList.remove('playing')))

  }

}
