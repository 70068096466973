import { Component, OnInit, TemplateRef } from '@angular/core';

import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

import { HelperService } from '../../../services/helper.service';
import { AdobeTMService } from '../../../services/adobe-tm.service';

@Component({
  selector: 'hpl-search-tips',
  templateUrl: './search-tips.component.html',
  styleUrls: ['./search-tips.component.less',]
})
export class HPLSearchTipsComponent implements OnInit {

  searchTipsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private helperService: HelperService,
    private adobeTMService: AdobeTMService
  ) { }

  ngOnInit(): void {
  }

  openSearchTipsModal(modalName: TemplateRef<any>, event) {
    event.preventDefault();
    // fire the ga event
    this.helperService.trackGAEvent('hpl', 'Click', 'read-notices');

    // Fire adobe tracking
    this.adobeTMService.pushToDataLayer({
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"cta",
        "page":"shopping-tools:horsepower-locator",
        "location":"hero-panel",
        "description":"read-these-notices-do-it"
      }
    });

    // Show the new modal
    this.searchTipsModalRef = this.modalService.show(modalName, Object.assign({}, { class: 'modal-full' }));
  }

}
