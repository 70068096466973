import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../../environments/environment";
import {Router} from "@angular/router";
declare let ga: Function;
declare let addthis: any;

@Component({
  selector: 'dg-rkn-landing-page',
  templateUrl: './rkn-landing-page.component.html',
  styles: []
})


export class Rkn2021LandingPageComponent implements OnInit {
  videos: any;
  activeVideo: string = '';
  channelName: string;
  modalActive = false;
  comingSoon = false;

  @ViewChild(ModalDirective, { static: true }) public videoModal: ModalDirective;
  videoModalRef: BsModalRef;
  
  constructor(
    public title: Title,
    public metaService: Meta,
    public sanitizer: DomSanitizer,
    private router: Router,
  ) {
    title.setTitle( 'Roadkill Nights Powered by Dodge Hellcat Grudge Race | Dodge Garage' );
    metaService.updateTag({ name: 'description', content: 'Roadkill Nights Hellcat Grudge Race with Eric Malone, Throtl, Tavarish, Alex Taylor, Westen Champlin and Dodge Challenger and Charger SRT Hellcats.' })
    metaService.updateTag({ property: 'og:description', content: 'Roadkill Nights Hellcat Grudge Race with Eric Malone, Throtl, Tavarish, Alex Taylor, Westen Champlin and Dodge Challenger and Charger SRT Hellcats.' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Roadkill Nights Powered by Dodge Hellcat Grudge Race | Dodge Garage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: environment.url + '/assets/images/rkn/roadkill-header.jpg' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: environment.url + '/assets/images/rkn/roadkill-header.jpg' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }
  

  ngOnInit() {
    // Add AddThis to Page
    const script = document.createElement('script');
    script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
    document.body.appendChild(script);

    // Reload AddThis
    setTimeout(() => {
      addthis.layers.refresh();
    }, 1500);
  }

  // initTinySlider() {
  //   // Add Tiny Slider Css to Pagem/ajax/libs/tiny-slider/2.9.2/tiny-slider.css';
  //   const stylesheet = document.createElement('link');
  //   stylesheet.href = '//cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.2/tiny-slider.css';
  //   stylesheet.rel = "stylesheet";
  //   document.getElementsByTagName( "head" )[0].appendChild( stylesheet );

  //   // Setup Tiny Slider
  //   let slider = tns({
  //     container: '.carousel-inner',
  //     items: 1,
  //     controls: false,
  //     navContainer: '.carousel-controls',
  //     loop: true,
  //   });

  // }

  // removeTinySliderCss() {
  //   const stylesheet = document.createElement('link');
  //   stylesheet.href = '//cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.2/tiny-slider.css';
  //   const stylesheets = document.getElementsByTagName('link');
  //   for (let i = stylesheets.length; i--;) {
  //     if (stylesheets[i].href == stylesheet.href) {
  //       // Remove script if not the proper url
  //       stylesheets[i].parentNode.removeChild(stylesheets[i]);
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  openVideoModal(videoID: string) { 
    this.activeVideo = videoID;
    this.modalActive = true;
    this.videoModal.show();
  }

  closeVideoModal() {
    this.activeVideo = '';
    this.modalActive = false;
    this.videoModal.hide();
  }

  clickTrackerVideo(video: string) {ga('send', 'event', 'RKN LP Video', 'Click', 'Video play - ' + video);}
  clickTrackerThrotl() {ga('send', 'event', 'RKN Throtl HP', 'Click', 'Image clicked');}
  clickTrackerMalone() {ga('send', 'event', 'RKN Malone HP', 'Click', 'Image clicked');}
  clickTrackerAlex() {ga('send', 'event', 'RKN Alex HP', 'Click', 'Image clicked');}
  clickTrackerTavarish() {ga('send', 'event', 'RKN Tavarish HP', 'Click', 'Image clicked');}
  clickTrackerWesten() {ga('send', 'event', 'RKN Westen HP', 'Click', 'Image clicked');}


}
