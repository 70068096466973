import { Tag } from './tag';
import {Author} from "./author";
import {Category} from "./category";

export class NewsArticle {
  id: number;
  title: string = '';
  cleanLink: string;
  linkTitle: string;
  date: Date = new Date();
  timeSince: string;
  author: Author = new Author();
  categories: Category[] = [];
  excerpt: any;
  content: any;
  slug: string;
  readTime: string;
  featuredImage = {
    alt: '',
    src: '',
    thumbnail: {
      src: '',
      width: 0,
      height: 0,
    },
    medium: { // Smallest WordPress size by default // Max 576
      src: '',
      width: 0,
      height: 0,
    },
    large: { // Max 992
      src: '',
      width: 0,
      height: 0,
    },
    full: {
      src: '',
      width: 0,
      height: 0,
    }
  }
  /*featuredImage = {
    src: '',
    alt: ''
  };*/
  thumbnail = {
    src: '',
    alt: ''
  };
  socialImage: string;
  tags: Tag[] = [];
  restId: number = 0;
  isDiscourseActive: boolean = true;
  discourseTopicId: number;
  discourseCategoryId: number;
  isLoaded: boolean = false;
  likes: number = 0
  isLikedByUser: boolean = false;
  isLikeButtonLoading: boolean = false;
}