<div id="roadkill-nights-video">
  <section id="roadkill-nights-video-header">
    <div class="site-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <p>
              To test different lil demon playlists, paste the strings below into the form and submit.<br>
              <small>These playlists are just examples pulled from their youtube accounts. Final IDs should be provided once they create them for the event.</small>
            </p>
          </div>
        </div>
        <div class="row event-header">
          <div class="col-sm-12 col-lg-6">
            <p><strong>Eric Malone (MotorTrend):</strong> PL12C0C916CECEA3BC</p>
            <p><strong>Alex Taylor:</strong> PLPR6DfFbLCkkcFU-pxfE-1mqeSc50SVe7</p>
            <p><strong>Freddy Hernandez aka Tavarish:</strong> PLrvMJaD0-PueQdWPd10PU7QgXMZ0NPIor</p>
            <p><strong>Westen Champlin:</strong> PLxps8cLCwSU63wV5TmdfIarckI6qTYu8l</p>
            <p><strong>Evan Beckerman aka Throtl:</strong> PLmnkTgCXEOE4EBZLlUPoaNMmwh0ZYIveE</p>

            <form class="form-inline w-100" (submit)="formSubmit(playlistId.value, $event)">
              <label class="sr-only" for="playlistId">Playlist ID</label> 
              <input type="text" class="form-control" id="playlistId" [formControl]="playlistId" placeholder="Playlist ID">
            
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
        <hr>
      </div>
    </div>
  </section>
  <section id="build-videos">
    <div class="site-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 section-heading">
            <h2 class="heading1 mb-0">Build Videos</h2>
            <p class="text-uppercase hidden-lg" *ngIf="!comingSoon">Tap to view</p>
            <p class="text-uppercase hidden visible-lg" *ngIf="!comingSoon">Click to view</p>
          </div>
        </div>
      </div>
    </div>
    <build-videos [videos]="videos" [comingSoon]="comingSoon" (activeVideo)="openVideoModal($event)"></build-videos>
  </section>
</div>


<div id="video-modal" class="modal fade" bsModal #videoModal tabindex="-1" role="dialog" (onHidden)="closeVideoModal()">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
      </div>
      <div class="modal-body">
        <div *ngIf="modalActive" class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" [src]="activeVideo | safe: 'resourceUrl'" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>


