import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'kebab'})
export class KebabPipe implements PipeTransform {

  replace(s: string) {
    return s.toLowerCase().replace(/ /g, '-');
  }

  transform(value: string): string  {
    return this.replace(value);
  }
}
