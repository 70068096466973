export const racers: Array<any> = [
  {
    firstName: "Westen",
    lastName: "Champlin",
    image: "/assets/images/rkn/2024/westen-champlin.webp",
    bio: "The sexiest Redneck Scientist in Kansas is a two-time Grudge Match alum. Westen's wild truck rebuilds have earned him the reputation for producing some of the most ridiculous cars on the internet.",
    social: [
      {
        platform: "instagram",
        url: "https://www.instagram.com/westengw/"
      },
      {
        platform: "tiktok",
        url: "https://www.tiktok.com/@westengw"
      },
      {
        platform: "youtube",
        url: "https://www.youtube.com/@WestenChamplin",
      }
    ]
  },
  {
    firstName: "Morgan",
    lastName: "Evans",
    image: "/assets/images/rkn/2024/morgan-evans.webp",
    bio: "This rookie drag racer comes from a high-performance racing family. Her love for the track and passion for classic cars and trucks makes her a natural fit for the Grudge Match. She's the owner of Morgan's Metals in Cocoa, Florida.",
    social: [
      {
        platform: "instagram",
        url: "https://www.instagram.com/merganevans"
      },
      {
        platform: "youtube",
        url: "https://www.youtube.com/@TorqueandTungsten",
      }
    ]
  },
  {
    firstName: "Herman",
    lastName: "Young",
    image: "/assets/images/rkn/2024/herman-young.webp",
    bio: "The creator of the popular Demonology YouTube channel that documents life with the fastest production car on the planet. Herman served as a mentor for Grudge Match 2023. This year, he wants the title for himself.",
    social: [
      {
        platform: "instagram",
        url: "https://www.instagram.com/demonology638"
      },
      {
        platform: "tiktok",
        url: "https://www.tiktok.com/@demonology638"
      },
      {
        platform: "youtube",
        url: "https://www.youtube.com/@Demonology",
      }
    ]
  },
  {
    firstName: "Allison",
    lastName: "Kovalik",
    image: "/assets/images/rkn/2024/allison-kovalik.webp",
    bio: "Builder, racer and automotive influencer, Allison has her sights on winning her first-ever Grudge Match. Competitive to the core, she brings a wealth of knowledge to the track, thanks in part to her building, racing dad.",
    social: [
      {
        platform: "instagram",
        url: "https://www.instagram.com/gen2garage"
      },
      {
        platform: "youtube",
        url: "https://www.youtube.com/@gen2garage"
      }
    ]
  },
  {
    firstName: "Tom",
    lastName: "Bailey",
    image: "/assets/images/rkn/2024/tom-bailey.webp",
    bio: "This five-time winner of Hot Rod Drag Week has credentials that are tough to beat. World record holder for a street-legal vehicle in the 1/4 mile with a 5.77 ET at 260 mph. Like we said — tough to beat. Creator of Sick Seconds Camaros, publisher of Sick The Magazine and mentor in the 2023 Grudge Match, Tom is going to bring everything he has to the drag strip in the quest to be the winner of Grudge Match 2024.",
    social: [
      {
        platform: "instagram",
        url: "https://www.instagram.com/sickseconds"
      },
      {
        platform: "instagram",
        url: "https://www.instagram.com/sickthemag"
      }
    ]
  },
  {
    firstName: "Garrett",
    lastName: "Reed",
    image: "/assets/images/rkn/2024/garrett-reed.webp",
    bio: "This automotive influencer is a custom ride builder and creator of the American Muscle HD social channels. A diehard Dodge fan, Garrett is stoked to be making his Grudge Match debut.",
    social: [
      {
        platform: "instagram",
        url: "https://www.instagram.com/americanmusclehd"
      },
      {
        platform: "tiktok",
        url: "https://www.tiktok.com/@americanmusclehd"
      },
      {
        platform: "youtube",
        url: "https://www.youtube.com/@americanmusclehd",
      }
    ]
  },
]