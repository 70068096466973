import { HelperService } from './../../services/helper.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSince'
})
export class TimeSincePipe implements PipeTransform {

  constructor(private helperService: HelperService){}

  transform(value: any, format?: any): any {
    if (value) {
      let timeSince;
      if (format == 'short') {
        timeSince = this.helperService.timeSinceShort(value);
      } else {
        timeSince = this.helperService.timeSince(value);
      }
      return timeSince;
    }
    return null
  }

}
