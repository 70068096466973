<div class="row">
  <div class="col-sm-12">

    <div class="row mb-4">
        <div class="col-sm-12">
          <h2>DODGE CHALLENGER MOPAR<sub>&reg;</sub> DRAG PAK HISTORY</h2>
          <p>
            The 2021 Dodge Challenger Mopar<sub>&reg;</sub> Drag Pak is the fourth-generation production package car produced by the passionate, dedicated engineers at Dodge//SRT<sup>&reg;</sup> and Mopar. The Drag Pak was designed and built for off-road use only, is not street legal, and carries on the tradition started in 2009 of providing Sportsman racers a safe, fast, reliable and consistent machine capable of taking its driver to the winner’s circle. When the first Drag Paks hit the strip over a decade ago, they set records and won NHRA Championships with serious horsepower under the hood. Powered by GEN III HEMI<sup>&reg;</sup> V8 engines in a variety of displacements that included 5.7L, 6.1L, 354, 392, 426 in both naturally aspirated and supercharged versions, the Dodge Challenger Mopar Drag Pak ruled drag strips across America.
          </p>
        </div>
    </div>

    <div class="row">
      <div class="col-sm-12 wrapped-image">
        <p>
          <img loading="lazy" src="/assets/images/drag-pak-experience/history/1.webp" class="img-responsive mb-3 mr-3 pull-left" alt="Drag Pak History - Challenger on drag strip" width="1440" height="785">

          As with the earlier Drag Paks, the 2021 version can trace its DNA back to the 1968 Super Stock HEMI Dodge Dart and Plymouth Barracuda that were unleashed on the strip. For over five decades, these limited-0production, purpose-built machines have won many races and continue to compete in the Super Stock/A-HEMI (SS/AH) class of NHRA Sportsman racing and the annual fan favorite NHRA Dodge HEMI Challenge. The 2021 Dodge Challenger Mopar Drag Pak promises the same with its 354-cubic-inch supercharged HEMI engine, capable of travelling the quarter-mile in under eight seconds. To improve the breed, it’s important to see how it has evolved over the years. 
        </p>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h3 id="2009">2009 Challenger Drag Pak</h3>
      </div>
      <div class="col-sm-12 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/history/2.webp" alt="Drag Pak History - 2" class="img-responsive" width="1440" height="720">
      </div>
      <div class="col-sm-12">
        <p>
          In 2008, Mopar announced the first drag race, factory-prepped package car in 40 years – the first V8 Dodge Challenger Mopar Drag Pak. The 2009 program saw a first-year sellout of all 100 factory-built vehicles. It provided racers with a good foundation that could be finished to their preference for competition in sanctioned events. The package did not include a transmission, differential, rear suspension, cage, wiring and other items. The 2009 Drag Pak consisted of a rolling chassis with interior and a choice of a 5.7L, 6.1L or 392 HEMI engine. A 5.9L Magnum V8 was also available as a low-cost alternative to make the car eligible for other sanctioned race classes.
        </p>
        <p>
          A Vehicle Serial Number (VSN) ID plate was attached to the passenger front shock tower that read <strong>"LC22 DRAG PROGRAM MANUFACTURED AT BRAMPTON ASSEMBLY PLANT CANADA MOPAR."</strong> This ID plate also included the year and vehicle production number. A visual distinction on the first-generation Drag Pak was the special carbon fiber black composite fresh air hood with front facing scoop design, inspired by the 1970 Challenger T/A. The hood was a lift-off style that attached with Dzus fasteners. All 2009 Drag Paks came in white, and many of the first-generation cars are still competing and winning races today. 
        </p>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h3 id="2011">2011 Challenger Drag Pak</h3>
      </div>
      <div class="col-sm-12 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/history/3.webp" alt="Drag Pak History - 3" class="img-responsive" width="1440" height="720">
      </div>
      <div class="col-sm-12">
        <p>
          The 2011 Drag Pak was the first turn-key drag car built by Mopar in almost 50 years. Unlike its predecessor, the 2011 Drag Pak was ready to race straight from the factory, becoming the first to offer a 500-plus-cubic-inch V10 drag package car from an original equipment manufacturer (OEM). The 2011 models came standard with a modified GEN 4 Viper V10 engine displacing 512 cubic inches, TCI Powerglide automatic transmission and Strange 9-inch rear differential. With open headers, the race Viper V10 engine had a distinct and unique sound at full throttle going down the drag strip.
        </p>
        <p>
          The second-generation Drag Pak’s standard color was white and used the production hinged hood, unlike the previous model’s carbon fiber lift-off style. The options available on the 2011 Drag Pak were a Mopar graphics package and a Competition Package including roll cage, safety harness and window net, and optional body colors.
        </p>
      </div>
    </div>

    <hr class="my-5">

    <div class="row">
      <div class="col-sm-12 mb-3">
        <h3 id="2015">2015 Challenger Drag Pak</h3>
      </div>
      <div class="col-sm-12 mb-5">
        <img loading="lazy" src="/assets/images/drag-pak-experience/history/4.webp" alt="Drag Pak History - 4" class="img-responsive" width="1440" height="785">
      </div>
      <div class="col-sm-12 wrapped-image">
        <p>
          <img loading="lazy" src="/assets/images/drag-pak-experience/history/5.webp" alt="Drag Pak History - 5" class="img-responsive pull-right" width="1440" height="837">

          By the time the third-generation Drag Pak was introduced in 2015, the performance levels among the competition had increased significantly. With the NHRA Factory Stock Showdown class gaining more steam and visibility among fans, Mopar made the decision to offer a supercharged 354-cubic-inch HEMI engine for 2015. Like the 2011 Drag Pak, the 2015 model was a complete turn-key package ready to race. However, unlike its predecessor, the 2015 model was available with two GEN III HEMI V8 engine options, including a 354 supercharged or 426 naturally aspirated (NA), and a new three-speed automatic transmission.
          <br><br>
          With the available engine options, the 2015 Drag Pak had two distinctive hoods based on engine selection. The 2015 Drag Pak, with its 354-cubic-inch HEMI engine, was the quickest and fastest factory-produced drag car and secured the NHRA Factory Stock Showdown championship with Leah Pruett at the wheel during its 2018 campaign. Geoff Turk drove his 2015 Drag Pak to the 2018 NMCA Factory Super Cars World Championship and also posted the first official 7-second run in the class. 
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mt-5 text-center btn-container">
        <a routerLink="/drag-pak-experience/" class="btn btn-primary btn-footer-nav mr-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="2021 Dodge Challenger Mopar Drag Pak"><i class="fa fa-chevron-left" aria-hidden="true"></i> <span>2021 Dodge Challenger Mopar<sub>&reg;</sub> Drag Pak</span></a>
        <a routerLink="/drag-pak-experience/build-overview" class="btn btn-primary btn-footer-nav ml-auto text-uppercase ga-event" data-ga_category="DragPkOwn_CTAButton" data-ga_label="2021 Drag PakBuild Overview"><span>2021 Drag Pak Build Overview</span> <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
      </div>
    </div>

  </div>
</div>
