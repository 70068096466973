import { Component, OnInit, Input } from '@angular/core';
import {UserService} from "../user.service";

@Component({
  selector: 'dg-activation-notice',
  templateUrl: './activation-notice.component.html',
  styleUrls: ['./activation-notice.component.less']
})
export class ActivationNoticeComponent implements OnInit {

  alert = {
    additionalMessage: ''
  };
  @Input() additionalText: string;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  resendActivation(event) {
    event.preventDefault();
    this.userService.resendActivation().subscribe( data => {
      this.alert.additionalMessage = 'The activation email was sent successfully.';
    }, (error) => {
      this.alert.additionalMessage = 'There was an error resending the activation email.';
    });
  }

}
