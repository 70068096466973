import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { Products } from './products';

import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'dg-demon-170-owner-info',
  templateUrl: './demon-170-owner-info.component.html',
  styleUrls: ['./demon-170-owner-info.component.scss']
})
export class Demon170OwnerInfoComponent implements OnInit {

  constructor(
    public title: Title,
    public metaService: Meta,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    title.setTitle( '2023 Dodge SRT Demon 170 - Owner Information | DodgeGarage' );
    metaService.updateTag({ name: 'description', content: 'The 2023 Dodge Challenger SRT® Demon 170 comes with customized, limited-edition, serialized items that become available to order after you take delivery.' })
    metaService.updateTag({ property: 'og:description', content: 'The 2023 Dodge Challenger SRT® Demon 170 comes with customized, limited-edition, serialized items that become available to order after you take delivery.' })
    metaService.updateTag({ property: 'og:site_name', content: 'DodgeGarage' })
    metaService.updateTag({ property: 'og:title', content: '2023 Dodge SRT Demon 170 - Owner Information | DodgeGarage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  products: Array<any> = Products;
  activeProduct: Number;
  descriptor: String = `
    <div class="descriptor">
      <span class="close"><i class="fa fa-close"></i></span>
      <p class="price">Price: <span data-price></span></p>
      <p data-description></p>
    </div>
  `

  ngOnInit() {}

  async showDescription(el, index, description, contents, price, subproduct) {
    if (isPlatformBrowser(this.platformId)) {
      const card = await el.target.closest('product-card');
      const isActive = await el.target.closest('.product-card').classList.contains('active');

      this.activeProduct = index;
      this.isActive();

      // Check if clicked card is not active before running descriptor building
      if (!isActive) {
        
        // Clear all descriptors
        this.clearDescriptions();
    
        if (window.matchMedia('(max-width: 767px)').matches) {
          // insert new descriptor and populate data 
          card.insertAdjacentHTML('afterend', this.descriptor);
        }else {
          // cards are laid out in rows of two on larger screens. In order to ensure that we are inserting the descriptor after the row, we need to check if the index is even or not. if not even, add descriptor after next sibling to maintain row.

          let cards = document.querySelectorAll('product-card');

          // Check if card is even or odd
          if (index % 2 == 0) {

            // Check if card is the last one and not second column.
            if (index < cards.length - 1) {
              card.nextSibling.insertAdjacentHTML('afterend', this.descriptor);
            }else {
              card.insertAdjacentHTML('afterend', this.descriptor);
            }
          }else {
            card.insertAdjacentHTML('afterend', this.descriptor);
          }
        }

        // Activate listener for close button
        document.querySelector('.close').addEventListener('click', () => {
          this.closeDescription();
        });

        // Fill out required product info
        document.querySelector('[data-price]').innerHTML = price;
        document.querySelector('[data-description]').innerHTML = description;

        // Build contents list if needed
        if (contents && contents.length > 0) {
          const template = document.querySelector('.descriptor');
          template.insertAdjacentHTML('beforeend', '<p class="mb-0"><strong>Includes:</strong></p>');
          template.insertAdjacentHTML('beforeend', '<ul class="product-contents"></ul>');

          contents.forEach(item => {
            document.querySelector('.product-contents').insertAdjacentHTML('beforeend', `<li><span class="marker"></span>${item}</li>`);
          });
        }
        
        // Build subproduct information if needed
        if (subproduct) {
          const template = document.querySelector('.descriptor');
          template.insertAdjacentHTML('beforeend', `
            <hr>
            <p class="price mb-0"><span data-price>${subproduct.name}</span></p>
            <p class="price">Price: <span data-price>${subproduct.price}</span></p>
            <p data-description>${subproduct.description}</p>
          `);
        }

      }
    }
  }

  closeDescription() {
    // Reset active index, run active check
    this.activeProduct = undefined;
    this.isActive();

    // Clean up after yourself, heathen.
    document.querySelector('.close').removeEventListener('click', function() { return false});

    // clear all descriptions
    this.clearDescriptions();
  }

  clearDescriptions() {
    if (isPlatformBrowser(this.platformId)) {
      const descriptors = document.querySelectorAll('.descriptor');
      descriptors.forEach(desc => desc.remove());
    }
  }

  isActive() {
    if (isPlatformBrowser(this.platformId)) {
      const cards = document.querySelectorAll('.product-card');

      cards.forEach((card, i) => {
        card.classList.remove('active', 'inactive');

        if (this.activeProduct != undefined) {
          if (this.activeProduct === i) {
            card.classList.add('active');
          }else {
            card.classList.add('inactive');
          }
        }
      })
    }
  }

}
