import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ContributorsService {

  constructor(
    private http: HttpClient
  ) { }

  getContributors(type = 'all'): Observable <any> {
    return this.http.get(environment.url + '/assets/json/contributors.json').pipe(
      map((items: Array<any>) => {
        if (type != 'all') {
          return items.filter(items => items.type == type);
        } else {
          return items
        }
      }, error => error)
    );
  }

  getContributorBySlug(slug): Observable <any> {
    return this.http.get(environment.url + '/assets/json/contributors.json').pipe(
      map((items: Array<any>) => {
        return items.filter(items => items.slugUrl == slug);
      }, error => error)
    );
  }
}
