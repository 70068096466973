import { Component, OnInit, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Location, isPlatformBrowser } from '@angular/common';
import { VideoService } from '../video.service';
import { VideoAlbum } from '../video-album';
import { ActivatedRoute } from '@angular/router';
import { Video } from '../video';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import {environment} from "../../../environments/environment";
import {AddThisService} from '../../services/addthis.service';

declare let addthis: any;

@Component({
  selector: 'dg-video-page',
  templateUrl: './video-page.component.html',
  styles: []
})
export class VideoPageComponent implements OnInit {

  videoAlbums: VideoAlbum[] = [];
  selectedVideo: Video = new Video();
  @ViewChild(ModalDirective, { static: true }) public videoModal: ModalDirective;
  videoModalRef: BsModalRef;

  constructor(
    public title: Title,
    public metaService: Meta,
    private location: Location,
    public route: ActivatedRoute,
    private videoService: VideoService,
    private addThisService: AddThisService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.fetchVideos();
    this.route.params.subscribe( params => {
      if (params.videoId) {
        this.fetchVideo(params.videoId);
      } else {
        this.setDefaultMeta();
      }
    });
  }

  setDefaultMeta() {
    this.title.setTitle( 'Videos | Dodge Garage' );
    this.metaService.updateTag({ name: 'description', content: 'See all of the videos on Dodge Garage' })
    this.metaService.updateTag({ property: 'og:description', content: 'See all of the videos on Dodge Garage' })
    this.metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    this.metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | Videos' })
    this.metaService.updateTag({ property: 'og:type', content: 'website' })
    this.metaService.updateTag({ property: 'og:image', content: '' })
    this.metaService.updateTag({ property: 'og:url', content: environment.url + '/videos' })
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    this.metaService.updateTag({ name: 'twitter:image', content: '' })
    this.metaService.updateTag({ name: 'twitter:image:width', content: ''})
    this.metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  setVideoMeta(video: Video) {
    this.title.setTitle( video.name + ' | Dodge Garage' );
    let metaDescription = `Check out the ${video.name} video on Dodge Garage`;
    if (video.description && video.description != '')
      metaDescription = video.description;
    this.metaService.updateTag({ name: 'description', content: metaDescription })
    this.metaService.updateTag({ property: 'og:description', content: metaDescription })
    this.metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    this.metaService.updateTag({ property: 'og:title', content: 'Dodge Garage | ' + video.name })
    this.metaService.updateTag({ property: 'og:type', content: 'video.movie' })
    this.metaService.updateTag({ property: 'og:image', content: video.thumbnail })
    this.metaService.updateTag({ property: 'og:url', content: environment.url + '/videos/' + video.id })
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    this.metaService.updateTag({ name: 'twitter:image', content: video.thumbnail })
    this.metaService.updateTag({ name: 'twitter:image:width', content: '640'})
    this.metaService.updateTag({ name: 'twitter:image:height', content: '360'})
  }

  fetchVideos() {
    this.videoService.getVimeoAlbums().subscribe( data => {
      let albums = data.data.filter( album => (album.name != 'Curated + Internal' && album.name != 'DodgeGarage Videos'))
      // We want the video categories to be in a specific order
      let desiredOrder = [ 'Inside Scoop', 'Weekend Warriors', 'The Rundown', 'Chasing the Title' ];
      //let orderedAlbumbs = [];
      desiredOrder.forEach((key) => {
        let found = false;
        albums = albums.filter((item) => {
          if(!found && item.name == key) {
            this.videoAlbums.push(item);
            found = true;
            return false;
          } else 
            return true;
        })
      });
    })
  }

  fetchVideo(videoId) {
    this.videoService.getVimeoVideo(videoId).subscribe( data => {
      this.selectedVideo = this.videoService.mutateVimeoVideos(data);
      this.setVideoMeta(this.selectedVideo);
      if (isPlatformBrowser(this.platformId)) {

        // Add AddThis to Page
        const script = document.createElement('script');
        script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
        document.body.appendChild(script);
        // Reload AddThis
        setTimeout(() => {
          addthis.layers.refresh();
          this.addThisService.setAddThisVisibility(true);
        }, 3000);

        this.videoModal.show();
      }
    });
  }

  closeVideoModal() {
    // Add any magic to fire when closing the modal
    this.videoModal.hide();
    this.location.replaceState('/videos');
    this.selectedVideo = new Video();
    if (isPlatformBrowser(this.platformId)) {
      this.removeAddThis();
    }
    this.addThisService.setAddThisVisibility(false);
  }

  videoModalListener(type: string, $event: ModalDirective) {
    // Catch closing on backdrop click only
    if (type == 'onHidden' && $event.dismissReason == 'backdrop-click') {
      this.location.replaceState('/videos');
      if (isPlatformBrowser(this.platformId)) {
        this.removeAddThis();
      }
    }
    this.addThisService.setAddThisVisibility(false);
  }

  removeAddThis() {
    const script = document.createElement('script');
    script.src ='//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
    const scripts = document.getElementsByTagName('script');
    for (let i = scripts.length; i--;) {
      if (scripts[i].src == script.src) {
        // Remove script if not the proper url
        scripts[i].parentNode.removeChild(scripts[i]);
        return true;
      }
    }
    return false;
  }

}
