import {
  Component, Inject, PLATFORM_ID, HostListener, ViewChild, TemplateRef, OnInit,
  OnDestroy, AfterViewInit, ElementRef, ChangeDetectorRef, Renderer2
} from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { UserService } from '../../user/user.service';
import { HelperService } from '../../services/helper.service';
import {collapse, expandCollapse} from '../../animations';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {BsModalRef, BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {BehaviorSubject, Subscription} from 'rxjs';
import {Category} from "../../news-articles/category";
import {NewsArticleService} from "../../news-articles/news-article.service";
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import {modalCloseTimeOut} from '../../globals';
import {CookieService} from "ngx-cookie-service";
import { add } from 'date-fns';
import { AdobeTMService } from '../../services/adobe-tm.service';
import { GtmService } from '../../services/gtm-tracking.service';

declare let ga: Function;

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  providers: [  ],
  animations: [ collapse, expandCollapse ]
})

export class Navigation implements OnInit, AfterViewInit, OnDestroy {

  // Nav Open?
  collapse: string = 'closed';

  // User Functionality
  @ViewChild('userModal', { static: true }) public userModal: TemplateRef<any>;
  @ViewChild(ModalDirective, { static: false }) missingUserLocationModal: ModalDirective;

  userModalRef: BsModalRef;
  missingUserLocationRef: BsModalRef;
  isUserModalOpen: boolean = false;
  isLocationModalOpen: boolean = false;
  userModalHiddenSub: Subscription;

  // User login status
  loggedInSub: Subscription;

  categories: Category[] = [];

  // Navbar Dropdown Active States
  isNewsActive: boolean = false;
  @ViewChild('newsMenuDropdown') newsMenuDropdown: BsDropdownDirective; 
  isEventsActive: boolean = false;
  @ViewChild('eventsMenuDropdown') eventsMenuDropdown: BsDropdownDirective; 
  isRacingHQActive: boolean = false;
  @ViewChild('racingHqMenuDropdown') racingHqMenuDropdown: BsDropdownDirective; 
  isMediaActive: boolean = false;
  @ViewChild('mediaMenuDropdown') mediaMenuDropdown: BsDropdownDirective; 
  isMerchActive: boolean = false;
  @ViewChild('merchMenuDropdown') merchMenuDropdown: BsDropdownDirective; 

  // Nav sizing
  @ViewChild('navHeader') navHeader: ElementRef;
  @ViewChild('navBanner') navBanner: ElementRef;
  navHeight: number = 0;
  mobileMax : number = 1200;

  isLivestreamBannerVisible: boolean = false;
  // used for any banner that is manually enabled and doesn't have conditional logic
  isPromotionalBannerVisible: boolean = false; 
  rknPageActive: boolean = false;

  // For Virtual Carshow Banner
  routeRestricted: boolean = false;
  isCarShowBannerActive: boolean = false;
  carshowBannerSrc: string = '/assets/images/logos/superpower-logo.png';

  // Server or browser
  isServer: boolean = true;
  isLoggedIn: boolean = false;
  isLoggedIn$ = new BehaviorSubject<boolean>(this.isLoggedIn);

  // Homepage hero
  homepageHeroActive: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    public helperService: HelperService,
    private modalService: BsModalService,
    private newsArticleService: NewsArticleService,
    private cookieService: CookieService,
    private ref: ChangeDetectorRef,
    private renderer: Renderer2, 
    private adobeTMService: AdobeTMService,
    private gtmService: GtmService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/' && this.homepageHeroActive) {
          this.renderer.addClass(this.document.body, 'fixed-hero-active');
        }else {
          this.renderer.addClass(this.document.body, 'fixed-nav');
        }
      }
    })
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      //this.mobileCollapse();

      this.loggedInSub = this.userService.loggedIn$.subscribe(
          loggedIn => {
            if (this.userService.userModel.isLoggedIn) {
              console.log('User is logged in');
              this.isLoggedIn = true;
              this.isLoggedIn$.next(true);
            }
          }
      );

      this.router.events.subscribe((event) => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        if (event instanceof NavigationEnd) {
          this.renderer.removeClass(this.document.body, 'fixed-hero-active');
          this.renderer.removeClass(this.document.body, 'modal-open');
          this.collapse = 'closed';
        }
        // Check URL to set custom active states
        this.isNewsActive = (this.router.url.includes('/news'));
        this.isEventsActive = (this.router.url.includes('/events') && !this.router.url.includes('/news'));
        this.isRacingHQActive = (this.router.url.includes('/racing-hq'));
        this.routeRestricted = (this.router.url.includes('/chief-donut-maker') || this.router.url.includes('/never-lift'));

        // If banner points on site, exclude destination page by url
        this.isPromotionalBannerVisible = !this.router.url.includes('/never-lift') && !this.router.url.includes('/chief-donut-maker');
        if (this.router.url.includes('/never-lift') || this.router.url.includes('/chief-donut-maker')) {
          this.renderer.removeClass(this.document.body, 'bracket-banner-open');
        }

        if (!this.route.snapshot.fragment || (this.route.snapshot.fragment && !this.route.snapshot.fragment.includes('!/~/'))) { // Looks for url change in ecwid
          if (window.innerWidth < 767 && this.collapse == 'open') {
            this.collapse = 'closed';
          }
        }
      });

      // if params contain logout=true, logout
      this.route.queryParams.subscribe(params => {
          if(params.logout === "true") {
            console.info('Logging out!');
            this.userService.logout();
          }
        }
      );

      this.userService.getUserStatusChangeEmitter().subscribe( isLoggedIn => {
        if(isLoggedIn){
          this.isLoggedIn = true;
          this.isLoggedIn$.next(true);
          this.ref.detectChanges(); // convince angular to actually do its job
        }
      })

      this.userService.getUserLocationFoundEmitter().subscribe(found => {

        const checkedRecently = this.cookieService.get('UserLocationCheck') === 'true';

        if(!found && !checkedRecently) {
          this.openLocationModal()
        }
      })

      this.isServer = false;
    }

    // Check dates for livestream banner
    let currentDate = new Date();
    let givenDate = this.helperService.toDate('2020-07-02T18:00:00-04:00');

    // Show the livestream until the deadline
    if (givenDate > currentDate) {
      this.isLivestreamBannerVisible = true;
    } else {
      this.isLivestreamBannerVisible = false;
    }

  }

  ngAfterViewInit() {
    this.fetchCategories();
  }

  ngOnDestroy() {
    if (this.loggedInSub)
      this.loggedInSub.unsubscribe();
  }

  // Position nav fixed when scrolled to top.
  @HostListener('window:scroll', ['$event'])
  fixedMorph($event) {
    const homepageHero: any = document.querySelector('#homepage-hero-fixed');

    if (homepageHero && window.innerWidth >= 1200) {
      const homepageHeroRect = homepageHero.getBoundingClientRect();
      if (window.scrollY >= homepageHeroRect.height) {
        document.body.classList.add('fixed-nav');
      }else {
        document.body.classList.remove('fixed-nav');
      }
    }else {
      document.body.classList.add('fixed-nav');
    }
  }

  toggleCollapse() {
    if (isPlatformBrowser(this.platformId)) {
      this.collapse = this.collapse == 'open' ? 'closed' : 'open';
      this.collapse == 'open' && window.innerWidth < this.mobileMax
        ? document.body.classList.add('modal-open') 
        : document.body.classList.remove('modal-open')

      this.trackMenuOpens(this.collapse);
    }
  }

  // Handling hovers on desktop and touches on mobile to avoid any collision with dropdown functionality.
  dropdownHoverOpen(dropdown: BsDropdownDirective, e: Event) {
    if (window.innerWidth > this.mobileMax) {
      dropdown.show()
      console.log('mouseenter', window.innerWidth <= this.mobileMax, e);
    }
  }
  dropdownHoverClose(dropdown: BsDropdownDirective, e: Event) {
    if (window.innerWidth > this.mobileMax) {
      dropdown.hide();
      console.log('mouseleave', window.innerWidth <= this.mobileMax, e);
    }
  }
  dropdownClickToggle(dropdown: BsDropdownDirective, e: Event) {
    if (window.innerWidth <= this.mobileMax) {
      dropdown.toggle();
      console.log('click', window.innerWidth <= this.mobileMax, e);
    }else {
      return false
    }
  }

  collapseOnModal() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 767 && this.collapse == 'open') {
        this.collapse = 'closed';
      }
    }
  }

  logout(event){
    event.preventDefault();
    this.userService.logout();
    this.isLoggedIn = false;
    this.isLoggedIn$.next(false);
  }

  navigationGASignUp(event) {
    event.preventDefault();
    ga('send', {
      hitType: 'event',
      eventCategory: 'SignUp',
      eventAction: 'Header',
      eventLabel: window.location.href
    });
    localStorage.setItem('gaAction', 'Header');
  }

  openUserModal(event) {
    event.preventDefault();
    if (!this.isUserModalOpen) {
      this.userModalRef = this.modalService.show(this.userModal, {class: 'modal-full'});
      this.isUserModalOpen = true;
    }
    this.userModalHiddenSub = this.modalService.onHidden.subscribe((reason: string) => {
      this.isUserModalOpen = false;
      if (this.newsArticleService.isNewsModalOpen && isPlatformBrowser(this.platformId))
        document.body.classList.add("modal-open");
    });
  }

  closeUserModal() {
    // Add any magic to fire when closing the modal
    if (this.userModalRef) {
      this.userModalRef.hide();
      this.isUserModalOpen = false;
    }
  }

  openLocationModal() {
    setTimeout(() => {
      if (!this.isUserModalOpen) {
        this.missingUserLocationModal.show();
        this.isLocationModalOpen = true;
      }
    }, 2000);
  }

  closeLocationModal() {
    this.isLocationModalOpen = false;
    this.cookieService.set('UserLocationCheck', 'true', add(new Date(), {days: 1}));
  }


  fetchCategories() {
    // Create category for 'All'
    let categoryAll = new Category();
    categoryAll.id = 0;
    categoryAll.name = 'All';
    categoryAll.slug = 'all';
    categoryAll.link = '/news';
    // Fetch the other categories
    this.newsArticleService.getCategories().subscribe( data => {
      data.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      this.categories = data;
      this.categories = this.categories.filter( category => category.slug != 'uncategorized');
      this.categories.forEach( category => {
        this.newsArticleService.mutateCategory(category);
      });
      this.categories.unshift(categoryAll);
    });
  }

  trackLogoClicks(): void {
    // Only fire on HPL for adobe tracking.
    const currentRoute = this.router.url;

    if (currentRoute === '/horsepower-locator') {
      const data = {
        "event": "interaction-click",
        "interaction": {
          "site": "dodgegarage",
          "type": "nav",
          "page": "shopping-tools:horsepower-locator",
          "location": "main-navigation",
          "description": "dodgegarage-logo"
        }
      };
      this.adobeTMService.pushToDataLayer(data);
    }
  }

  trackMenuOpens(action: string): void {
    // Only fire on HPL for adobe tracking.
    const currentRoute = this.router.url;

    if (currentRoute === '/horsepower-locator') {
      const data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"main-navigation",
          "description": `${action.toLowerCase()}-menu`
        }
      };
      this.adobeTMService.pushToDataLayer(data);
    }
  }
  
  trackMenuItemClicks(section: string, link: string): void {
    // Only fire on HPL for adobe tracking.
    const currentRoute = this.router.url;

    if (currentRoute === '/horsepower-locator') {
      const data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location": `expandable-nav-${section}`,
          "description": link
        }
      };
      this.adobeTMService.pushToDataLayer(data);
    }
  }

  trackTopMenuItemClicks(link: string): void {
    // Only fire on HPL for adobe tracking.
    const currentRoute = this.router.url;

    if (currentRoute === '/horsepower-locator') {
      const data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"main-navigation",
          "description": link
        }
      };
      this.adobeTMService.pushToDataLayer(data);
    }
  }
  
  trackLoginClicks(): void {
    // Only fire on HPL for adobe tracking.
    const currentRoute = this.router.url;

    if (currentRoute === '/horsepower-locator') {
      const data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"main-navigation",
          "description":"login"
        }
      };
      this.adobeTMService.pushToDataLayer(data);
    }
  }

}
