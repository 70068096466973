import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import {Router} from "@angular/router";

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import SwiperCore, {Navigation, Pagination, SwiperOptions, Virtual} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import { racers } from './racers';
import { isPlatformBrowser } from '@angular/common';

SwiperCore.use([Virtual, Pagination, Navigation]);

@Component({
  selector: 'dg-rkn-landing-page',
  templateUrl: './rkn-landing-page.component.html',
  styleUrls: ['./rkn-landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class RknLandingPageComponent implements OnInit {
  
  constructor(
    public title: Title,
    public metaService: Meta,
    private modalService: BsModalService, 
    public sanitizer: DomSanitizer,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    title.setTitle( 'Roadkill Nights Direct Connection Grudge Match 2024 | Dodge Garage' );
    metaService.updateTag({ name: 'description', content: "The Direct Connection Grudge Match 2024 lights up Woodward Avenue on August 10 at Motortrend presents Roadkill Nights powered by Dodge. Connect with each racer's social channels and follow along as they build their custom rides under ever increasing pressure." })
    metaService.updateTag({ property: 'og:description', content: "The Direct Connection Grudge Match 2024 lights up Woodward Avenue on August 10 at Motortrend presents Roadkill Nights powered by Dodge. Connect with each racer's social channels and follow along as they build their custom rides under ever increasing pressure." })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Roadkill Nights Direct Connection Grudge Match 2024 | Dodge Garage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: environment.url + '/assets/images/rkn/roadkill-nights-teaser-background.webp' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: environment.url + '/assets/images/rkn/roadkill-nights-teaser-background.webp' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  racers: Array<any> = racers;

  // structured data for event
  schema: object = {
    "@context" : "http://schema.org",
    "@type" : "Event",
    "name" : "Roadkill Nights Direct Connection Grudge Match 2024",
    "image" : environment.url + '/assets/images/rkn/roadkill-nights-teaser-background.webp',
    "url" : environment.url + '/roadkill-nights',
    "startDate": "2024-08-10",
    "location": {
      "@type": "Place",
      "name": "M1 Concourse in Pontiac, MI",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "1 Concourse Dr",
        "addressLocality": "Pontiac",
        "addressRegion": "MI",
        "postalCode": "48341"
      }
    },
    "description": "The Direct Connection Grudge Match 2024 lights up Woodward Avenue on August 10 at Motortrend presents Roadkill Nights powered by Dodge."
  };

  // Podcasts and video player
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('videoModal', { static: true }) public videoModal: TemplateRef<any>;
  videoModalRef: BsModalRef;

  podcasts: Array<any> = [
    {
      title: "2024 Grudge Match: Episode 1",
      description: "The Direct Connection Grudge Match is back and this time there's a twist.",
      thumbnail: "/assets/images/rkn/2024/garagecast-episode-1-thumbnail.webp",
      videoId: "3KOMqgKy9uk"
    },
    {
      title: "2024 Grudge Match: Episode 2",
      description: "Meet the 2024 Direct Connection Grudge Match racers. These drag racers are in the trenches building the ultimate drag car to battle it out on Woodward Avenue at Roadkill Nights Powered by Dodge.",
      thumbnail: "/assets/images/rkn/2024/garagecast-episode-2-thumbnail.webp",
      videoId: "I4L0cBqML0Q"
    },
    {
      title: "2024 Grudge Match: Episode 3",
      description: "After 7 relentless weeks of burning the midnight oil to craft the meanest drag monster, the Grudge Match racers are ready to unleash hell on the track today.",
      thumbnail: "/assets/images/rkn/2024/garagecast-episode-3-thumbnail.webp",
      videoId: "NV6HMgTv3U4"
    }
  ]
  activePodcastDescription: string = '';

  // Swiper config
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  config: SwiperOptions = {
    slidesPerView: 1,
    //spaceBetween: 35,
    centeredSlides: true,
    loop: true,
    speed: 200,
    initialSlide: this.getRandomNumber(this.racers.length + 1),
    navigation: {
      'nextEl': '#carousel-next',
      'prevEl': '#carousel-prev'
    },
    pagination: {
      el: '.carousel-indicators',
      clickable: true,
      type: 'bullets',
      bulletActiveClass: 'active',
      bulletClass: 'carousel-nav-item'
    }
  };
  

  ngOnInit() {
    
  }

  // Modals
  openModal(template: TemplateRef<any>, videoId: string, title: string, description: string) {
    this.videoModalRef = this.modalService.show(template, {class: 'modal-lb-video'});

    // Add video player
    const iframe = document.createElement('iframe');
    iframe.setAttribute('width', '560');
    iframe.setAttribute('height', '315');
    iframe.setAttribute('class', 'embed-responsive-item');
    // Set the YouTube video URL with autoplay enabled
    iframe.setAttribute('src', `https://www.youtube.com/embed/${videoId}?autoplay=1`);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowfullscreen', 'true');

    // Append the iframe to the modal body or a specific container within the modal
    document.querySelector('#rkn-video-modal .modal-body .embed-responsive').appendChild(iframe);

    // Set active modal description area
    this.activePodcastDescription = description;

    // Click tracking
    if (isPlatformBrowser(this.platformId)) {
      window.dataLayer.push({
        "event": "roadkill_nights.podcasts.view",
        'roadkill_nights.podcasts.videoId': videoId,
        'roadkill_nights.podcasts.name': title
      })
    }


    // Subscribe to close and destroy video player
    this.modalService.onHidden.subscribe(() => {
      // Remove the iframe from the modal body when the modal is hidden
      if (iframe) {
        iframe.remove();
      }
    });
  }

  closeModal() {
    this.videoModalRef.hide();
  }

  onModalClose() {
    console.log('Modal has been closed');
  }

  truncateText(inputString: string, length: number): string {
    return inputString.length > length ? inputString.substring(0, length) + '...' : inputString;
  }

  // Random number generator used to display initial team slides at random.
  getRandomNumber(max) {
    return Math.floor(Math.random() * max);
  }

  onSlideChange(event): void {
    console.log('slide changed! ', event.activeIndex);

    if (isPlatformBrowser(this.platformId)) {
      window.dataLayer.push({
        "event": "roadkill_nights.racer_slider.change",
        "roadkill_nights.racer_slider.index": event.activeIndex
      });
    }
  }

}
