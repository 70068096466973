<form>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="control-label">First name</label>
        <input type="text" class="form-control" placeholder="First name" value="Mark" required>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group has-success">
        <label class="control-label">Last name</label>
        <input type="text" class="form-control is-valid" placeholder="Last name" value="Otto" required>
        <div class="valid-feedback help-block">
          Looks good!
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group has-error">
        <label class="control-label">City</label>
        <input type="text" class="form-control is-invalid" placeholder="City" required>
        <div class="invalid-feedback help-block">
          Please provide a valid city.
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group has-error">
        <label class="control-label">State</label>
        <select class="form-control">
          <option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</option>
        </select>
        <div class="invalid-feedback help-block">
          Please provide a valid state.
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group has-error">
        <label class="control-label">Zip</label>
        <input type="text" class="form-control is-invalid" placeholder="Zip" required>
        <div class="invalid-feedback help-block">
          Please provide a valid zip.
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="dg-checkbox checkbox">
      <label class="control-label">
        <input type="checkbox">
        <span class="checkbox-icon"></span>
        <span class="label-text">Check me out</span>
      </label>
    </div>
  </div>
  <div>
    <label class="control-label section-label">Radio Label</label>
    <div class="form-group">
      <div class="dg-radios">
        <label class="control-label radio-inline">
          <input type="radio" name="isStock" checked>
          <span class="label-text">Radio one</span>
          <span class="radio-icon"></span>
        </label>
        <label class="control-label radio-inline">
          <input type="radio" name="isStock">
          <span class="label-text">Radio two</span>
          <span class="radio-icon"></span>
        </label>
      </div>
    </div>
  </div>
  <div>
    <button class="btn btn-primary" type="submit">Submit form</button>
  </div>
</form>
