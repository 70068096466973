<div class="container-narrow">
  <div class="signup-form form-1320">
    <dg-alert-banner [alert]="onboardingAlert"></dg-alert-banner>
    <div class="step-header text-center">
      <h2 class="step-heading heading1">Log in or create DodgeGarage account</h2>
    </div>
    <tabset #onboardingTabs>
      <tab heading="Log In" id="tab1">
        <dg-login-form></dg-login-form>
      </tab>
      <tab heading="Sign Up">
        <dg-register-form></dg-register-form>
      </tab>
    </tabset>
  </div>
</div>
