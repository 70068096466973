<div id="interior-image-slider">
  <div class="container-fluid p-0">
    <div class="interior-image-wrapper">
      <swiper data-id="interior-image-carousel" #swiper [config]="config">
        <ng-container *ngFor="let slide of slides; index as i;">
          <ng-template swiperSlide>
            <div class="image-wrap">
              <div class="img" [ngClass]="{'active': activeImage === i}">
                <img [src]="slide.thumb" [alt]="slide.title" (click)="showSlide(i)" loading="lazy">
              </div>
              <div class="description">
                <div>
                  <span>
                    {{ slide.title }} 
                    <span *ngIf="slide.package" class="icon-container" (click)="openModal(slide.package, slide.title)">
                      <i class="fa fa-info-circle"></i>
                    </span>
                  </span>
                  <span *ngIf="slide.disclaimer" class="disclaimer">({{ slide.disclaimer }})</span>
                </div>
                <div>${{ slide.price }}</div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </swiper>
      <!-- indicators -->
      <div class="indicators">
        <!-- <div *ngFor="let dot of indicators; index as i" class="dot" [ngClass]="{'active': activeIndex === i}" (click)="slideTo(i)"></div> -->
      </div>
      <div class="hidden">
        <div class="interior-image-gallery">
          <a [href]="slide.image" *ngFor="let slide of slides; index as i">
            <img [src]="slide.thumb" [alt]="slide.title" loading="lazy">
          </a>
        </div>
      </div>
      <button *ngIf="showControls" (click)="slidePrev()" class="carousel-button carousel-prev"><i class="fa fa-angle-left fa-5x"></i></button>
      <button *ngIf="showControls" (click)="slideNext()" class="carousel-button carousel-next"><i class="fa fa-angle-right fa-5x"></i></button>
    </div>
    <div class="full-size-image-wrapper">
      <div class="feature-image">
        <img [src]="slides[activeImage].image" [alt]="slides[activeImage].title">
        <i class="icon-trigger fa fa-search-plus" (click)="showGalleryFromFeature()"></i>
      </div>
      <div class="additional-options">
        <h3>Additional Options</h3>
        <ul class="list-unstyled list-underline text-uppercase">
          <li>
            <span>
              Rear Seat Delete Group
              <span class="icon-container" (click)="openOptionsModal()">
                <i class="fa fa-info-circle"></i>
              </span>
            </span>
            <span class="price">$0 ($0 CAD)</span>
          </li>
          <li>
            <span>
              Power Sunroof
            </span>
            <span class="price">$9,995 ($9,995 CAD)</span>
          </li>
          <li>
            <span>
              Demonic Red Seatbelts
            </span>
            <span class="price">$395 ($395 CAD)</span>
          </li>
          <li>
            <span>
              Trunk Dress Kit
            </span>
            <span class="price">$295 ($395 CAD)</span>
          </li>
          <li>
            <span>
              GPS Navigation
            </span>
            <span class="price">$995 ($995 CAD)</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #packageModal>
  <div class="modal-container">
    <div class="modal-header">
      <p class="text-uppercase">{{ activeTitle }}</p>
      <button class="modal-close" (click)="closeModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <p class="text-uppercase">This package includes:</p>
      <ul>
        <li *ngFor="let item of activePackage" class="text-uppercase" [innerHTML]="item"></li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #optionsModal>
  <div class="modal-container">
    <div class="modal-header">
      <p class="text-uppercase">{{ activeTitle }}</p>
      <button class="modal-close" (click)="closeOptionsModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <p class="text-uppercase">This package includes:</p>
      <ul>
        <li *ngFor="let item of activePackage" class="text-uppercase" [innerHTML]="item"></li>
      </ul>
    </div>
  </div>
</ng-template>