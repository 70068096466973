<div id="hpds" class="site-wrapper">

  <header>
    <div class="container-fluid racing-hq">
      <div class="row">

        <div class="col-sm-12">
          <h1 class="heading1">
            THE OFFICIAL HIGH PERFORMANCE DRIVING SCHOOL OF DODGE//SRT<sup>&reg;</sup>
          </h1>
        </div>

        <div class="col-sm-6 col-sm-push-6">
          <img loading="lazy" class="img-responsive" src="/assets/images/hpds-drag/radford-hero.jpg" alt="Radford racing school">
        </div>

        <div class="col-sm-6 col-sm-pull-6">
          <p>Are you the proud owner of a new Dodge//SRT<sup>&reg;</sup> vehicle? Congrats! Learning how to properly handle its power
            and capabilities with confidence in a safe environment is important. That’s why your purchase included the
            1-day SRT Experience Class at Radford Racing School. <a href="/srt/">Submit your qualifying VIN within the first 12 months
              of taking delivery to begin the registration process.</a> Only the current, qualifying models are included in
            the drop down for submission. You must take delivery of your vehicle before you submit your VIN.
          </p>
        </div>

      </div>

    </div>
  </header>

  <div class="container" *ngIf="!expired">
    <div class="row">
      <div class="alert bg-danger col-sm-12">
        The Bondurant Racing School and the Bob Bondurant School of High Performance Driving will be closed Sunday, July 1, through Saturday, July 7 in recognition of Independence Day.
      </div>
    </div>
  </div>

  <section id="full-width-section">
    <div class="container-fluid racing-hq">

      <div class="feature-list-container">
        <ul class="list-2-cols-sm features-list">
          <li>
            <p class="li-title">1-DAY SRT EXPERIENCE CLASS OVERVIEW</p>
            <p><strong>Introduction:</strong> A brief overview of the day's events, including the cars you’ll be driving.</p>
            <p><strong>SRT Experience Ground School:</strong> Introduces basic car control skills and knowledge. This classroom session assures that you’ll have the best preparation before driving.</p>
            <p><strong>SRT Experience Driving Exercises:</strong></p>
            <ul>
              <li><strong>Accident Avoidance:</strong> Learn how to react in emergency situations. From keeping your vision elevated to maintaining safe driving distance and basic vehicle physics. </li>
              <li><strong>Team Slalom:</strong> A team relay race requiring precision maneuvering and proper lines through a series of cones to get the lowest time possible. Driver changes are crucial on this drill, so be smooth and efficient! </li>
              <li><strong>Auto-Cross:</strong> Miniature road course set up with cones. The goal is to get through the course as quickly as possible, without hitting any cones. Each cone hit is a penalty that adds 1 second to your time through the course. Vision and car control skills are required to find the proper driving line. </li>
              <li><strong>Lead-and-Follow:</strong> Experience driving on the 1.6-mile road course. Drivers will follow an instructor, being shown the proper driving line, braking and acceleration points through the track. The Driver’s goal is to accurately replicate the instructor's car through the course. </li>
              <li><strong>Hot Laps:</strong> The biggest thrill of the day! Drivers ride right-seat with a driving instructor for fast-paced laps around the race track.</li>
            </ul>
          </li>

          <li style="order: 4; break-after: column;">
            <p class="li-title">Questions?</p>
            <p><a routerLink="/track-experience/faq">Check out the FREQUENTLY ASKED QUESTIONS for more information.</a></p>
            <p>Contact the Dodge//SRT Concierge Team Monday through Friday from 8:30 a.m. to 5:00 p.m. ET.</p>
            <div class="contacts">
              <p><strong><i class="fa fa-envelope" aria-hidden="true"></i> Email:&nbsp;&nbsp;</strong> <a href="mailto:srtte@dodgegarage.com">srtte@dodgegarage.com</a></p>
              <p><strong><i class="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;Phone:&nbsp;</strong> <a href="tel:+800-998-1110">800-998-1110</a></p>
            </div>
            <a href="/srt/" class="btn btn-primary">View Schedule</a>&nbsp;&nbsp;&nbsp;&nbsp; <a routerLink="/track-experience/faq" class="btn btn-primary">FAQ</a>
          </li>

          <li>
            <p class="li-title">2-DAY DRAG RACING CLASS OVERVIEW <small><br><em>(included only with 2023 Challenger SRT Demon 170)</em></small></p>
            <p><strong>Introduction:</strong> A brief overview of the day's events, including the cars you’ll be driving.</p>
            <p><strong>Drag Racing School:</strong> Introduces fundamental drag racing skills and knowledge from burnouts and throttle control to Line Lock, TransBrake 2.0 and staging. This classroom session assures that you’ll have the best preparation before driving.</p>
            <p><strong>Drag Racing Driving Exercises:</strong></p>
            <ul>
              <li><strong>Skid Car:</strong> Learn how to properly control a car that loses traction and goes into a skid. You’ll learn to apply proper correction to stabilize the vehicle through both over-steer and under-steer situations.</li>
              <li><strong>Drag Strip Intro:</strong> You'll get an intro to the track as well as the cars and racing equipment. </li>
              <li><strong>Drag Strip Passes:</strong> Experience driving on an NHRA drag strip. Behind the wheel, each student will rotate through single lane passes that include a wet-box burnout, staging, 60-foot pass, half-pass and full-pass.      </li>
            </ul>
            <br>
            <p>Want to brush up on your drag racing knowledge before attending? <a routerLink="/racing-hq/racing-faq">We've got you covered!</a></p>
          </li>

          <li>
            <p class="li-title">STEPS TO REGISTER TO ATTEND</p>
            <p>You must take delivery of your vehicle before submitting your VIN. Only Dodge//SRT models include the SRT Experience with purchase, and are eligible for program participation with VIN validation. All non-Dodge//SRT owners are welcome to attend at the normal cost, and should <a href="https://radfordracingschool.com/" target="_blank" rel="noopener noreferrer">visit Radford Racing School to see available class options.</a>
            </p>
            <p><strong>Step 1:</strong>&nbsp;&nbsp;<a href="/srt/">View current schedule & submit your Dodge//SRT<sup>&reg;</sup> VIN to verify it’s valid for participation. </a> You must take delivery of your vehicle before submitting your VIN. A VIN is valid for redemption and will be approved to participate up to one year from the date of purchase. If more than one year has passed, the VIN will not be approved.</p>
            <p><strong>Step 2:</strong> You'll receive your VIN approval via email and your information will be sent to Radford. </p>
            <p><strong>Step 3:</strong> You’ll receive a welcome email from Radford to begin the process of scheduling your class. You must work with a Radford representative directly to schedule and confirm attendance.</p>
            <p><strong>Step 4:</strong> After you schedule your class with Radford, they’ll send you an email to confirm your attendance and provide any details you need to know before arriving.</p>
          </li>
        </ul>
      </div>

    </div>
  </section>

</div>

<!--<ng-template #schoolClosedModal>-->
  <!--<div class="modal-container">-->
    <!--<div class="modal-header">-->
      <!--<button class="modal-close" (click)="schoolClosedModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>-->
    <!--</div>-->
    <!--<div class="modal-body">-->
      <!--<p>We have been made aware that the school has been closed. We’re investigating the situation, and what the next steps will be for owners. We’ll update this site as soon as we have more information, and are very sorry this has happened.</p>-->
    <!--</div>-->
  <!--</div>-->
<!--</ng-template>-->
