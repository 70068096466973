<div class="countdown-clock" [ngClass]="{'rumble': rumble}">
  <div class="countdown-item">
    <span>{{ hours }}</span>
    <span>Hrs</span>
  </div>
  <div class="divider">:</div>
  <div class="countdown-item">
    <span>{{ minutes }}</span>
    <span>Mins</span>
  </div>
  <div class="divider">:</div>
  <div class="countdown-item">
    <span>{{ seconds }}</span>
    <span>Sec</span>
  </div>
</div>