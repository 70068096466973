import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Component, OnInit, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { HelperService } from "../../services/helper.service";

@Component({
  selector: 'dg-video-drag-pak',
  templateUrl: './video-drag-pak.component.html',
  styleUrls: ['./video-drag-pak.component.less']
})
export class VideoDragPakComponent implements OnInit {

  @ViewChild('videoModal', { static: true }) public videoModal: TemplateRef<any>;
  videoModalRef: BsModalRef;

  constructor(
    private helperService: HelperService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  @HostListener('click', ['$event'])
  openModal(event) {
    event.preventDefault();
    this.helperService.trackGAEvent('2019 Dodge Challenger R/T Scat Pack 1320', 'Click', 'Open Video Modal');
    this.videoModalRef = this.modalService.show(this.videoModal, {class: 'modal-lg'})
  }

}
