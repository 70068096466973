<div class="alert alert-dismissible alert-banner" [ngClass]="['bg-' + alert.status]" *ngIf="alert.show">
  <button type="button" class="close-alert" aria-label="Close" (click)="alert.show = !alert.show"><span aria-hidden="true">&times;</span></button>
  <div class="row">
    <div class="col-sm-12 flex-container">
      <div class="context-icon">
        <i class="fa fa-exclamation-triangle" aria-hidden="true" *ngIf="alert.status == 'danger'"></i>
        <i class="fa fa-check" aria-hidden="true" *ngIf="alert.status == 'success'"></i>
      </div>
      <div class="context-message">
        <h4 *ngIf="alert.status == 'danger'">Oops!</h4>
        <h4 *ngIf="alert.status == 'success'">Congratulations!</h4>
        <div *ngIf="alert.message != 'The account needs to be activated.'">
          <p>{{alert.message}}</p>
        </div>
        <div *ngIf="alert.message == 'The account needs to be activated.'">
          <p>This account needs to be verified before continuing. If you need to resend the activation email, please <a href="#" (click)="resendActivation($event)">click here</a>. After activating, you can continue the forgotten password process.</p>
        </div>
        <p *ngIf="alert.additionalMessage">{{alert.additionalMessage}}</p>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
<div class="alert alert-success" role="alert" *ngIf="userService.userModel.successMessage">{{userService.userModel.successMessage}}</div>
<form id="forgot-password-form" class="" data-name="forgot-password" name="forgot-password-form" (submit)="forgotPassword($event)" *ngIf="!userService.userModel.isLoggedIn">
  <div class="form-group">
    <label for="forgot-password-email" class="control-label">Email:</label><br />
    <input type="text" name="emailUsername" id="forgot-password-email" [(ngModel)]="userService.userModel.email" class="form-control">
  </div>
  <div class="form-group">
    <button class="btn btn-primary" type="submit" [disabled]="loading" [ngClass]="{'is-loading' : loading}">
      Recover Password
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="loading"></i>
    </button>
  </div>
</form>
