import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from "./user.service";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      if (isPlatformBrowser(this.platformId)) {
        if (localStorage.getItem('user_access') != null) {
          let user_access = JSON.parse(localStorage.getItem('user_access'));
          if (new Date().getTime() < parseInt(user_access.access_expiration))   {
            return true;
          } else {
            this.router.navigate(['/']);
            return false;
          }
        } else {
          this.router.navigate(['/']);
          return false;
        }
      }

    }
}
