import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export function StrongPassword(control: AbstractControl) {

  const password = control;
  let n = ".*[0-9].*";
  let a = ".*[a-zA-Z].*";
  if (password.value.match(n) && password.value.match(a) && password.value.trim().length > 5) {
    return null
  } else {
    return { 'strongPassword': true }
  }

}

