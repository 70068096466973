import {Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild} from '@angular/core';
import {Event} from "../event";
import {ActivatedRoute, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";
import {HelperService} from "../../services/helper.service";
import { EventService } from '../event.service';
//import { } from '@types/googlemaps';
import {} from "googlemaps";
import {DatePipe, isPlatformBrowser} from '@angular/common';
import { AddThisService } from '../../services/addthis.service';

declare var addthis:any;

@Component({
  selector: 'dg-event-single-page',
  templateUrl: './event-single-page.component.html',
  styleUrls: ['./event-single-page.component.less']
})
export class EventSinglePageComponent implements OnInit {

  @Input() event: Event = new Event();
  @Output() eventUpdated: EventEmitter<Event> = new EventEmitter<Event>();
  events: Event[] = [];
  url: string = environment.url;

  map: google.maps.Map;
  @ViewChild('map', { static: true }) gmapElement: any;

  datePipe = new DatePipe('en-US');

  // JSON+LD schema
  schema: object = {};

  isEventAlreadyLoaded: boolean = false;

  constructor(
    public helperService: HelperService,
    private metaService: Meta,
    private titleService: Title,
    public eventService: EventService,
    public addThisService: AddThisService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // If we are coming directly from the path, it's a full view
    this.route.params.subscribe( params => {
      if (this.route.snapshot.paramMap.get('path')) {
        this.isEventAlreadyLoaded = false;
        this.eventService.isEventViewFull = true;
      }
    });

    this.isEventAlreadyLoaded = true;
    this.route.data
      .subscribe((data: { event: Event }) => {
        this.eventService.mutateEvent(data.event);
        this.event = data.event;

        // Define schema
        this.schema = {
          "@context": "http://schema.org",
          "@type": "Event",
          "name": this.event.name,
          "startDate": this.event.startDate,
          "endDate": this.event.endDate,
          "location": {
            "@type": "Place",
            "name": this.event.venue,
            "address": {
              "@type": "PostalAddress",
              "streetAddress": this.event.address,
              "addressLocality": this.event.city,
              "addressRegion": this.event.state,
              "postalCode": this.event.postalCode,
              "country": this.event.country
            }
          },
          "description": this.event.description,
          "image": {
            "@type": "ImageObject",
            "url": this.event.featuredImageThumbnail,
            "height": 220,
            "width": 220
          },
          "url": this.event.website,
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": environment.url + this.router.url,
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Events",
                  "item": environment.url + "/events"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": this.event.name,
                  "item": environment.url + this.router.url
                },
              ]
            }
          },
        }

        // Set Title
        this.titleService.setTitle(this.event.name + ' | ' + this.datePipe.transform(new Date(this.event.startDate), 'MM/dd/yyyy') + ' | Dodge Garage');

        // Set Meta Data
        this.metaService.updateTag({ name: 'description', content: this.event.description });
        this.metaService.updateTag({ rel: 'canonical', href: environment.url + this.router.url });
        this.metaService.updateTag({ property: 'og:description', content: this.event.description });
        this.metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
        this.metaService.updateTag({ property: 'og:title', content: this.event.name });
        this.metaService.updateTag({ property: 'og:type', content: 'Event' });
        this.metaService.updateTag({ property: 'og:image', content: this.event.featuredImageThumbnail });
        this.metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url });
        this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
        this.metaService.updateTag({ name: 'twitter:image', content: this.event.featuredImageThumbnail });
        this.metaService.updateTag({ name: 'twitter:image:width', content: '220'});
        this.metaService.updateTag({ name: 'twitter:image:height', content: '220'});

      });
  }

  ngOnInit() {
    this.route.data.subscribe((data: { event: Event }) => {
      // Create the map
      this.createMap();

      // Add AddThis to Page
      const script = document.createElement('script');
      script.src ='//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
      document.body.appendChild(script);

      // Reload AddThis
      setTimeout( () => {
        addthis.layers.refresh();
        this.addThisService.setAddThisVisibility(true);
      }, 500);

    });

    this.route.params.subscribe(data => {
      //this.eventService.loader(3, 0);
      this.fetchEvents(100);
    });

    if (this.event) {
      this.initEvent();
      this.eventUpdated.emit(this.event);
    }

    if (isPlatformBrowser(this.platformId)) {
      // Add class to body
      document.body.classList.add('event-open');
    }
  }

  ngOnDestroy() {
    this.event = new Event();
    this.eventService.isEventViewFull = false;
    if (isPlatformBrowser(this.platformId)) {
      // Remove class from body
      document.body.classList.remove('event-open');
      this.addThisService.setAddThisVisibility(false);
    }
  }

  private fetchEvents(max: number = 6, offset: number = 0) {
    this.eventService.getEvents(max,offset).subscribe( data => {
      let events = data.filter( item => item.category && item.category === this.event.category && item.name != this.event.name);
      events.forEach( event => {
        this.eventService.mutateEvent(event);
      })
      this.events = events;
    })
  }

  private createMap() {
    this.map = new google.maps.Map(this.gmapElement.nativeElement, {
      center: new google.maps.LatLng(this.event.latitude, this.event.longitude),
      zoom: 14,
      scrollwheel: false,
      styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
    });

    // Set Marker
    let latLng = new google.maps.LatLng(this.event.latitude, this.event.longitude);
    let marker = new google.maps.Marker({
      position: latLng,
      map: this.map
    });
  }

  initEvent() {
    if (!this.isEventAlreadyLoaded) {
    }
  }
}
