<!-- Notifications part starts needs to be implemented -->
<!--<div *ngFor="let notification of notifications">
  <div *ngIf="notification.read">
    <a (click)="getNotificationDetails(notification.topic_id)">{{notification.fancy_title}}</a>
    <a (click)="getNotificationDetails(notification.topic_id)">{{notification.data.display_username}}</a>
  </div>
  <div *ngIf="!notification.read">
    <a style="color:red" (click)="getNotificationDetails(notification.topic_id)">{{notification.fancy_title}}</a>
    <a style="color:red" (click)="getNotificationDetails(notification.topic_id)">{{notification.data.display_username}}</a>
  </div>
  <div>---------------------------------------------------------</div>
</div>-->
<!-- Notifications part ends needs to be implemented -->

<h3 class="h3">{{(postCount == 1)? postCount + ' Comment': postCount + ' Comments'}}</h3>

<dg-comment-form [topic]="topic" (topicCreatedEmitter)="topicCreated($event)" (newCommentEmitter)="addNewComment($event)"></dg-comment-form>

<section class="comment-thread" *ngFor="let post of posts">

  <dg-single-comment [post]="post" (postCountEmitter)="postCountIncrease($event)"></dg-single-comment>

  <hr>

</section>
<!--<div *ngIf="posts.length == 0">
  <p>There are no posts/comments to display. Be the first to comment!</p>
</div>-->
