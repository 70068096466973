<dg-alert-banner [alert]="flagCommentAlert"></dg-alert-banner>

<form [formGroup]="flagCommentForm" (submit)="flagPost()">

  <div class="form-group" [ngClass]="{ 'has-error': flagCommentForm.controls.flagReason.invalid && (flagCommentForm.controls.flagReason.dirty || flagCommentForm.controls.flagReason.touched) }">
  <label class="control-label">Reason for flagging the comment</label>

    <!--<div class="radio">
      <label>
        <input type="radio" formControlName="flagReason" value="3">
        <strong>It's Off-Topic</strong><br>
        This post is not relevant to the current discussion as defined by the title and first post, and should probably be moved elsewhere.
      </label>
    </div>-->
    <div class="radio">
      <label>
        <input type="radio" formControlName="flagReason" value="4">
        <strong>It's Inappropriate</strong><br>
        This post contains content that a reasonable person would consider offensive, abusive, or a violation of our community guidelines.
      </label>
    </div>
    <div class="radio">
      <label>
        <input type="radio" formControlName="flagReason" value="8">
        <strong>It's Spam</strong><br>
        This post is an advertisement, or vandalism. It is not useful or relevant to the current topic.
      </label>
    </div>
    <div class="radio">
      <label>
        <input type="radio" formControlName="flagReason" value="7">
        <strong>Something Else</strong><br>
        This post requires staff attention for another reason not listed above.
      </label>
    </div>

    <div *ngIf="flagCommentForm.controls.flagReason.invalid && (flagCommentForm.controls.flagReason.dirty || flagCommentForm.controls.flagReason.touched)" class="help-block">
      <span *ngIf="flagCommentForm.controls.flagReason.errors.required">Please select a reason for flagging this comment.</span>
    </div>
  </div>

  <div *ngIf="flagCommentForm.controls.flagReason.value == 7" class="form-group" [ngClass]="{ 'has-error': flagCommentForm.controls.customReason.invalid && (flagCommentForm.controls.customReason.dirty || flagCommentForm.controls.customReason.touched) }">
    <label class="control-label">Please provide more information</label>
    <textarea id="customReason" name="customReason" class="form-control" formControlName="customReason" placeholder="Reason for flagging the comment..." [ngClass]="{ 'is-invalid': flagCommentForm.controls.customReason.invalid && (flagCommentForm.controls.customReason.dirty || flagCommentForm.controls.customReason.touched) }"></textarea>
    <div *ngIf="flagCommentForm.controls.customReason.invalid && (flagCommentForm.controls.customReason.dirty || flagCommentForm.controls.customReason.touched)" class="help-block">
      <span *ngIf="flagCommentForm.controls.customReason.errors.required">Let us know specifically what you are concerned about and provide examples where possible.</span>
    </div>
  </div>
  <div class="form-group text-right">
    <button class="btn btn-white" type="reset" (click)="cancelFlag()">Cancel</button>
    <button class="btn btn-primary" type="submit" [disabled]="isLoading" [ngClass]="{'is-loading' : isLoading}">
      Flag Comment
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
    </button>
  </div>
</form>
