import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'pre-run-checklist',
  templateUrl: './pre-run-checklist.component.html',
})

export class DragPakPreRunChecklistComponent implements OnInit {
  ngOnInit(): void {

  }

}

