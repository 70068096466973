import {
  Component, Inject, Input, OnInit, PLATFORM_ID, OnDestroy, TemplateRef, EventEmitter,
  Output
} from '@angular/core';
import {VehicleService} from "../vehicle.service";
import {AddVehicle} from './add-vehicle';
import {isPlatformBrowser} from "@angular/common";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {UserService} from "../../user/user.service";
import {Subscription} from "rxjs";
import {Vehicle} from "../vehicle";
import { modalCloseTimeOut } from '../../globals';

@Component({
  selector: 'dg-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.less']
})

export class AddVehicleComponent implements OnInit, OnDestroy {

  //@Input() step: number = 2;
  @Input() vehicle: Vehicle = new Vehicle();
  @Input() onboarding: boolean = false;
  @Output() vehicleCount = new EventEmitter();
  @Output() vehicleSaved = new EventEmitter();
  @Output() vehicleDeleted = new EventEmitter();
  isLoading: boolean = false;
  isDeleteLoading: boolean = false;
  isEditingMode: boolean = false;
  years: any = [];
  models: any = [];
  trims: any = [];
  model: string = '';
  year: string = '';
  trim: string = '';
  addVehicle: AddVehicle = new AddVehicle();
  vehicleToDelete = new Vehicle();
  vehicles: Vehicle[] = [];

  imgToEdit: any;

  vehicleFormAlert = {
    show: false,
    status: '',
    message: '',
    additionalMessage: ''
  };

  // User login status
  loggedInSub: Subscription;

  // Modals
  confirmDeleteModalRef: BsModalRef;
  imgModalRef: BsModalRef;

  constructor(
    public userService: UserService,
    public vehicleService: VehicleService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    if (this.vehicle.id != 0) {
      this.setEditVehicle(this.vehicle);
    }
    this.fetchYears();
    if (isPlatformBrowser(this.platformId)) {
      // User Specific Functionality
      this.fetchVehicles();
    }
    this.loggedInSub = this.userService.loggedIn$.subscribe(
      loggedIn => {
      if (this.userService.userModel.isLoggedIn) {
        //console.log('User is now logged in');
        this.fetchVehicles();
      }
    });
  }

  ngOnDestroy() {
    if (this.loggedInSub)
      this.loggedInSub.unsubscribe();
    if (this.confirmDeleteModalRef)
      this.confirmDeleteModalRef.hide();
  }

  fetchYears() {
    this.vehicleService.getLookup('year')
      .subscribe( years => {
          years.sort(function(a, b){return b-a});
          this.years = years;
        },
        err => {
          console.log(err);
        });
  }

  fetchModels() {
    this.vehicleService.getLookup('model', this.year)
      .subscribe( models => {
        //console.log(models);
        this.models = models;
      },
      err => {
        console.log(err);
      });
  }

  fetchTrims() {
    this.vehicleService.getLookup(null, this.year, this.model)
      .subscribe( trims => {
          //console.log(trims);
          this.trims = trims;
        },
        err => {
          console.log(err);
        });
  }

  fetchVehicles() {
    this.vehicleService.getVehiclesByUser(this.userService.userModel.id).subscribe( data => {
      this.vehicles = data;
      this.vehicleCount.emit(data.length);
    },
    err => {
      console.log(err);
    });
  }

  onFileChanged(event, template) {
    if (event.target.files && event.target.files[0]) {
      this.addVehicle.vehicleImage = event.target.files[0];
      this.imgModalRef = this.modalService.show(template, {class: 'modal-normal'});
    }
  }

  updateVehicleImage(event) {
    //this.addVehicle.originalImage = event.image;
    this.addVehicle.vehicleImageSrc = event.image;
    this.addVehicle.vehicleImage = event.file;
    this.imgModalRef.hide();
  }

  saveVehicle() {
    let isValidated = this.vehicleService.validateVehicle(this.addVehicle);
    if (isPlatformBrowser(this.platformId)) {
      // Scroll to top to show alert
      if (document.getElementsByTagName('modal-container').length > 0) {
        let documentTop = document.getElementsByTagName('modal-container')[0].getElementsByClassName('modal-header')[0]; // Select .modal-header
        documentTop.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
    if (isValidated) {
      this.isLoading = true;
      this.vehicleService.submitVehicle(this.addVehicle).subscribe( data => {
          if (data.status == 'success') {
            this.resetVehicle();
            this.isLoading = false;
            this.vehicleFormAlert.show = true;
            this.vehicleFormAlert.status = data['status'];
            this.vehicleFormAlert.message = (this.isEditingMode)?'Vehicle successfully updated':'Vehicle successfully added';
            // Wait before emitting event so user can view the alert
            setTimeout(() => {
              this.vehicleSaved.emit(data.vehicle);
            }, modalCloseTimeOut);
          } else {
            // Nay
            this.isLoading = false;
            this.vehicleFormAlert.show = true;
            this.vehicleFormAlert.status = 'danger';
            this.vehicleFormAlert.message = (this.isEditingMode)?'There was an error updating the vehicle. Please try again.':'There was an error adding the vehicle. Please try again.';
          }
        },
        errors => {
          this.isLoading = false;
          this.vehicleFormAlert.show = true;
          this.vehicleFormAlert.status = 'danger';
          if (errors.statusText) {
            this.vehicleFormAlert.message = errors.statusText
          } else {
            this.vehicleFormAlert.message = Array.prototype.join.call(errors, ',');
          }
        });
    } else {
      this.vehicleFormAlert.show = true;
      this.vehicleFormAlert.status = 'danger';
      this.vehicleFormAlert.message = 'Please fix the issues below.';
    }

  }

  setEditVehicle(vehicle: Vehicle) {
    this.addVehicle = new AddVehicle();
    this.addVehicle.id = vehicle.id;
    this.addVehicle.vin = vehicle.vin;
    this.addVehicle.isStock = vehicle.isStock;
    this.addVehicle.isDefault = vehicle.isDefault;
    this.addVehicle.mods = vehicle.mods;
    this.fetchYears();
    this.year = vehicle.year.toString();
    this.fetchModels();
    this.model = vehicle.model;
    this.fetchTrims();
    this.addVehicle.vehicleLookupId = vehicle.vehicleLookupId;
    if (vehicle.images[0]) {
      this.addVehicle.originalImage = vehicle.images[0].imagePath;
      this.addVehicle.vehicleImageSrc = vehicle.images[0].imagePath;
    } else {
      this.updateStockImage();
    }
    this.addVehicle.vehicle = vehicle;
    this.isEditingMode = true;
  }

  updateStockImage() {
    if (this.addVehicle.originalImage == '') {
      if (this.addVehicle.isStock)
        this.addVehicle.vehicleImageSrc = '/assets/images/1320/badges/stock/club/stock.png';
      else
        this.addVehicle.vehicleImageSrc = '/assets/images/1320/badges/modified/club/modified.png';
    }
  }

  resetVehicle() {
    this.model = '';
    this.year = '';
    this.addVehicle = new AddVehicle();
    this.isEditingMode = false;
  }

  openConfirmDeleteModel(template: TemplateRef<any>, vehicle: Vehicle) {
    this.confirmDeleteModalRef = this.modalService.show(template, {class: 'modal-sm'});
    this.vehicleToDelete = vehicle;
  }

  confirmDeleteVehicle() {
    if (this.userService.userModel.isLoggedIn) {
      this.isDeleteLoading = true;
      this.vehicleService.deleteVehicle(this.vehicleToDelete).subscribe( data => {
          if (data.status == 'success') {
            // We may want to display a message in the modal before dismissing
            this.confirmDeleteModalRef.hide();
            this.fetchVehicles();
            this.isDeleteLoading = false;
            this.vehicleDeleted.emit('vehicle deleted');
          } else {
            // Nay
          }
        },
        err => {
          console.log(err);
        });
    } else {
      //console.log('User is not logged in');
    }

  }

  deleteVehicleImage(vehicle: Vehicle, originalImage: string) {
    let vehicleImageStringParts = originalImage.split('/');
    let filename = vehicleImageStringParts[vehicleImageStringParts.length - 1];
    this.vehicleService.deleteVehicleImage(vehicle, filename).subscribe( data => {
      if (data.status == 'success') {
        //this.addVehicle = new AddVehicle();
        this.addVehicle.originalImage = '';
        this.updateStockImage();
        this.fetchVehicles();
      } else {
        // Nay
      }
    });
  }

}
