import {Component, OnInit, Input, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from "../../user/user.service";
import {Router} from "@angular/router";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { AdobeTMService } from '../../services/adobe-tm.service';
import { GtmService } from '../../services/gtm-tracking.service';
declare let ga: Function;

@Component({
  selector: 'performance-cars-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  @Input() copyrightYear = (new Date()).getFullYear();

  // User Functionality
  @ViewChild('userModal', { static: true }) public userModal: TemplateRef<any>;
  userModalRef: BsModalRef;

  constructor(
    private router: Router,
    private userService: UserService,
    private modalService: BsModalService,
    private adobeTMService: AdobeTMService,
    private gtmService: GtmService
  ) {

  }

  ngOnInit() {

  }

  signUpModal(event, address) {
    if (this.userService.userModel.isLoggedIn) {
      this.router.navigate(['/manage-account']);
    } else {
      this.openUserModal(event);
      sessionStorage.setItem('addr', address);
    }

    this.trackQuickLinkClicks('open-sign-up-form');
  }

  footerGASignUp(event) {
    ga('send', {
      hitType: 'event',
      eventCategory: 'SignUp',
      eventAction: 'Footer',
      eventLabel: window.location.href
    });
    localStorage.setItem('gaAction', 'Header');
  }

  openUserModal(event) {
    event.preventDefault();
    this.userModalRef = this.modalService.show(this.userModal, {class: 'modal-full'});
  }

  closeUserModal() {
    // Add any magic to fire when closing the modal
    if (this.userModalRef) {
      this.userModalRef.hide();
    }
  }

  trackLogoClicks(): void {
    // Only fire on HPL for adobe tracking.
    const currentRoute = this.router.url;

    if (currentRoute === '/horsepower-locator') {
      const data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"footer-disclaimer",
          "description":"dodgegarage-logo"
        }
      };
      this.adobeTMService.pushToDataLayer(data);
    }
  }
  
  trackDisclaimerClicks(link: string): void {
    // Only fire on HPL for adobe tracking.
    const currentRoute = this.router.url;

    if (currentRoute === '/horsepower-locator') {
      const data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"footer-disclaimer",
          "description": link
        }
      };
      this.adobeTMService.pushToDataLayer(data);
    }
  }
  
  trackQuickLinkClicks(link: string): void {
    // Only fire on HPL for adobe tracking.
    const currentRoute = this.router.url;

    if (currentRoute === '/horsepower-locator') {
      const data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"footer-quick-links",
          "description": link
        }
      };
      this.adobeTMService.pushToDataLayer(data);
    }
  }
  
  trackBrandLinksClicks(link: string): void {
    // Only fire on HPL for adobe tracking.
    const currentRoute = this.router.url;

    if (currentRoute === '/horsepower-locator') {
      const data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"footer-bottom",
          "description": link
        }
      };
      this.adobeTMService.pushToDataLayer(data);
    }
  }

}
