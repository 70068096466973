import {Injectable} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import { Observable } from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {TimeSlip} from "./time-slip";
import {environment} from "../../../environments/environment";
import {UserService} from "../../user/user.service";
import {UserModel} from "../../user/user";
import {Vehicle} from "../../vehicle/vehicle";

@Injectable()
export class TimeSlipService {

  mockSubmitTimeSlipResponse: string[] = ['Accept'];

  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private userService: UserService
  ) {
  }

  getTimeSlip(timeslipId) {
    const url = environment.url+'/rest/slips/'+timeslipId;
    return this.http.get<TimeSlip>(url);
  }

  getTimeSlips(isStock:string|boolean = false, status: string  = 'APPROVED', eightSeconds: boolean = false): Observable<TimeSlip[]> {

    let Params = new HttpParams();
    Params = Params.append('isStock', isStock.toString());
    Params = Params.append('status', status.toString());

    const url = eightSeconds ? environment.url+'/rest/slips/eightSecondSlips' : environment.url+'/rest/slips';
    return this.http.get<TimeSlip[]>(url, { params: Params });
  }

  getTimeSlipsByVehicle(user: UserModel, vehicle: Vehicle): Observable<TimeSlip[]> {
    const url = environment.url+'/rest/user/' + user.id + '/vehicles/' + vehicle.id + '/slips';
    return this.http.get<TimeSlip[]>(url);
  }

  submitTimeSlip(timeSlip: any, timeslipImage: File = null, timeslipAndVinImage: File = null): Observable<any> {
    let uploadData = new FormData();
    uploadData.append('slipId', timeSlip.id.value.toString());
    uploadData.append('raceDate', timeSlip.date.value.toString());
    uploadData.append('raceTrack', timeSlip.raceTrack.value.toString());
    uploadData.append('vehicleId', timeSlip.vehicle.value.toString());
    uploadData.append('elapsedTime', timeSlip.et.value.toString());
    uploadData.append('sixtyFeetTime', timeSlip.zeroToSixty.value.toString());
    uploadData.append('topSpeed', timeSlip.topSpeed.value.toString());
    if (timeslipImage) {
      uploadData.append('timeslip', timeslipImage, timeslipImage.name);
    }
    if (timeslipAndVinImage) {
      uploadData.append('timeslipAndVin', timeslipAndVinImage, timeslipAndVinImage.name);
    }
    const url = environment.url+'/rest/user/'+this.userService.userModel.id+'/slips';
    let headers = new HttpHeaders();
    headers.append('enctype', 'multipart/form-data');
    //headers.append('Accept', 'application/json');
    return this.http.post(url, uploadData, {headers:headers});
  }

  approveTimeSlip( timeslip: TimeSlip): Observable<any> {
    let formData = new FormData();
    formData.append('approval', 'true');
    formData.append('comment', timeslip.comment);
    formData.append('raceTrack', timeslip.raceTrack);
    formData.append('raceDate', timeslip.date.split('T')[0]);
    formData.append('vehicleId', timeslip.vehicle.id.toString());
    formData.append('elapsedTime', timeslip.elapsedTime.toString());
    formData.append('sixtyFeetTime', timeslip.sixtyFeetTime.toString());
    formData.append('topSpeed', timeslip.topSpeed.toString());
    console.log(formData);
    const url = environment.url + '/rest/slips/' + timeslip.id;
    return this.http.post(url, formData);
  }

  denyTimeSlip( timeslip: TimeSlip): Observable<any> {
    let formData = new FormData();
    formData.append('approval', 'false');
    formData.append('comment', timeslip.comment);
    formData.append('raceTrack', timeslip.raceTrack);
    formData.append('raceDate', timeslip.date.split('T')[0]);
    formData.append('vehicleId', timeslip.vehicle.id.toString());
    formData.append('elapsedTime', timeslip.elapsedTime.toString());
    formData.append('sixtyFeetTime', timeslip.sixtyFeetTime.toString());
    formData.append('topSpeed', timeslip.topSpeed.toString());
    const url = environment.url + '/rest/slips/' + timeslip.id;
    return this.http.post(url, formData);
  }

  markTimeSlipAsDuplicate( timeslip: TimeSlip): Observable<any> {
    let formData = new FormData();
    formData.append('approval', 'false');
    formData.append('isDuplicate', 'true');
    formData.append('comment', timeslip.comment);
    formData.append('raceTrack', timeslip.raceTrack);
    formData.append('raceDate', timeslip.date.split('T')[0]);
    formData.append('vehicleId', timeslip.vehicle.id.toString());
    formData.append('elapsedTime', timeslip.elapsedTime.toString());
    formData.append('sixtyFeetTime', timeslip.sixtyFeetTime.toString());
    formData.append('topSpeed', timeslip.topSpeed.toString());
    const url = environment.url + '/rest/slips/' + timeslip.id;
    return this.http.post(url, formData);
  }

  updateTimeSlipWithTopic(slipId: number, discourseTopicId: number, discourseCategoryId: number): Observable<any> {
    let formData = new FormData();
    formData.append('id', slipId.toString());
    formData.append('discourseTopicId', discourseTopicId.toString());
    formData.append('discourseCategoryId', discourseCategoryId.toString());
    const url = environment.url + '/rest/slips/'+slipId+'/discourseTopic';
    return this.http.post(url, formData);
  }
}

