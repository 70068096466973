import { Component, OnInit, AfterViewInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { DragPakGalleries } from './drag-pak-galleries';
import { DragPakLists } from './drag-pak-lists';
import { isPlatformBrowser } from '@angular/common';
// import { tns } from '../../../../assets/vendor/tiny-slider-ssr/src/tiny-slider';
import baguetteBox from 'baguettebox.js';

@Component({
  selector: 'drag-pak',
  templateUrl: './drag-pak.component.html',
})
export class DragPakLandingPageComponent implements OnInit, OnDestroy, AfterViewInit {

  galleryVehicles: any;
  galleryColors: any;
  galleryLivery: any;
  listOverview: any;

  constructor(
    public galleriesVehicle: DragPakGalleries,
    public lists: DragPakLists,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.galleryVehicles = this.galleriesVehicle.vehicles;
      this.galleryColors = this.galleriesVehicle.colors;
      this.galleryLivery = this.galleriesVehicle.livery;
      this.listOverview = this.lists.overview;
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setupGalleries();
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      //this.removeTinySliderCss();
    }
  }

  setupGalleries() {
    // console.log(baguetteBox);
    let gallery = baguetteBox.run('.expanding-gallery-container', {
      async: true
      /*onChange:	(currentIndex, imagesCount) => {
        console.log(currentIndex);
        console.log(imagesCount);
        if (currentIndex + 1 == imagesCount) {
          this.lightboxSavedIndex = currentIndex;
          baguetteBox.hide();
          this.galleryListPage++;
          this.fetchGallery(this.galleryListPage);
        }
        //console.log(baguetteBox.showNext());*/
    });
    // console.log(gallery);
    // console.log(baguetteBox);
  }

  // initTinySlider() {
  //   // Add Tiny Slider Css to Pagem/ajax/libs/tiny-slider/2.9.2/tiny-slider.css';
  //   const stylesheet = document.createElement('link');
  //   stylesheet.href = '//cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.2/tiny-slider.css';
  //   stylesheet.rel = "stylesheet";
  //   document.getElementsByTagName( "head" )[0].appendChild( stylesheet );
  //
  //   // Setup Tiny Slider
  //   let slider = tns({
  //     container: '.slideshow',
  //     items: 1,
  //     //autoWidth: true,
  //     controlsContainer: ".slideshow-container .controls",
  //     //controls: false,
  //     nav: false,
  //     //loop: true,
  //     edgePadding: 50,
  //     responsive: {
  //       992: {
  //         items: 3
  //       },
  //       1200: {
  //         items: 4,
  //         edgePadding: 100,
  //       }
  //     }
  //   });
  //
  //   console.log(slider);
  //
  //   setTimeout(function() {
  //     //let lightbox = baguetteBox.run('.slideshow');
  //     //console.log(lightbox);
  //
  //     console.log(baguetteBox);
  //     let gallery = baguetteBox.run('.slideshow-container', {
  //       async: true,
  //       /*onChange:	(currentIndex, imagesCount) => {
  //         console.log(currentIndex);
  //         console.log(imagesCount);
  //         if (currentIndex + 1 == imagesCount) {
  //           this.lightboxSavedIndex = currentIndex;
  //           baguetteBox.hide();
  //           this.galleryListPage++;
  //           this.fetchGallery(this.galleryListPage);
  //         }
  //         //console.log(baguetteBox.showNext());*/
  //     });
  //     console.log(gallery);
  //     console.log(baguetteBox);
  //   }, 3000);
  //
  // }

  // removeTinySliderCss() {
  //   const stylesheet = document.createElement('link');
  //   stylesheet.href = '//cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.2/tiny-slider.css';
  //   const stylesheets = document.getElementsByTagName('link');
  //   for (let i = stylesheets.length; i--;) {
  //     if (stylesheets[i].href == stylesheet.href) {
  //       // Remove script if not the proper url
  //       stylesheets[i].parentNode.removeChild(stylesheets[i]);
  //       return true;
  //     }
  //   }
  //   return false;
  // }

}
