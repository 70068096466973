<form [formGroup]="registerForm" id="dg-register-form" (submit)="register($event)">
  <fieldset>
    <legend class="sr-only">Registration Form</legend>
    <dg-alert-banner [alert]="registerFormAlert"></dg-alert-banner>
    <!--<div class="alert alert-danger" role="alert" *ngIf="registerFormServerErrors">{{userService.registerFormServerErrors}}</div>-->
    <div class="alert alert-danger" role="alert" *ngIf="registerFormErrors.length > 0">
      <ul>
        <li *ngFor="let error of registerFormErrors">{{error}}</li>
      </ul>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group" [ngClass]="{ 'has-error': registerForm.controls.firstName.invalid && (registerForm.controls.firstName.dirty || registerForm.controls.firstName.touched) }">
          <label for="register-first-name" class="control-label">First Name</label>
          <input class="form-control"  id="register-first-name" type="text" name="first_name" formControlName="firstName" (focus)="userService.trackGAevent('SignUpForm','FirstName')">
          <div *ngIf="registerForm.controls.firstName.invalid && (registerForm.controls.firstName.dirty || registerForm.controls.firstName.touched)">
            <div *ngIf="registerForm.controls.firstName.errors.required" class="help-block">Please enter a first name.</div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group" [ngClass]="{ 'has-error': registerForm.controls.lastName.invalid && (registerForm.controls.lastName.dirty || registerForm.controls.lastName.touched) }">
          <label for="register-last-name" class="control-label">Last Name</label>
          <input class="form-control" id="register-last-name" type="text" name="last_name" formControlName="lastName" (focus)="userService.trackGAevent('SignUpForm','LastName')">
          <div *ngIf="registerForm.controls.lastName.invalid && (registerForm.controls.lastName.dirty || registerForm.controls.lastName.touched)">
            <div *ngIf="registerForm.controls.lastName.errors.required" class="help-block">Please enter a last name.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <div class="form-group" [ngClass]="{ 'has-error': registerForm.controls.email.invalid && (registerForm.controls.email.dirty || registerForm.controls.email.touched) }">
          <label for="register-email" class="control-label">Email</label>
          <input class="form-control"  id="register-email" type="text" name="email" formControlName="email" (focus)="userService.trackGAevent('SignUpForm','Email')">
          <div *ngIf="registerForm.controls.email.invalid && (registerForm.controls.email.dirty || registerForm.controls.email.touched)">
            <div *ngIf="registerForm.controls.email.errors.required || registerForm.controls.email.errors.email" class="help-block">Please enter a valid email address.</div>
            <div *ngIf="!registerForm.controls.email.errors.required && !registerForm.controls.email.errors.email" class="help-block">This email address is already in use.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group" [ngClass]="{ 'has-error': registerForm.controls.country.invalid && (registerForm.controls.country.dirty || registerForm.controls.country.touched) }">
          <label for="register-country" class="control-label">Country</label>
          <select class="form-control" id="register-country" name="country" formControlName="country">
            <option value="">Country</option>
            <option value="US">United States</option>
            <option value="" disabled class="">-----------</option>
            <option *ngFor="let country of countries; index as i" [value]="country.value">{{ country.name }}</option>
          </select>
          <ng-container *ngIf="registerForm.controls.country.invalid && (registerForm.controls.country.dirty || registerForm.controls.country.touched)">
            <div *ngIf="registerForm.controls.country.errors.required" class="help-block">Please select a Country.</div>
          </ng-container>
        </div>
      </div>
      <div class="col-sm-6" *ngIf="registerForm.controls.country.value === 'US'">
        <div class="form-group" [ngClass]="{ 'has-error': registerForm.controls.zipCode.invalid && (registerForm.controls.zipCode.dirty || registerForm.controls.zipCode.touched) }">
          <label for="register-zipCode" class="control-label">ZIP Code</label>
          <input class="form-control"  id="register-zipCode" type="text" name="zipCode" maxlength="5" minlength="5" formControlName="zipCode" (focus)="userService.trackGAevent('SignUpForm','zipCode')">
          <div *ngIf="registerForm.controls.zipCode.invalid && (registerForm.controls.zipCode.dirty || registerForm.controls.zipCode.touched)">
            <div *ngIf="registerForm.controls.zipCode.errors.required || registerForm.controls.zipCode.errors.zipCode" class="help-block">Please enter a valid ZIP Code.</div>
            <div *ngIf="!registerForm.controls.zipCode.errors.required && !registerForm.controls.zipCode.errors.zipCode" class="help-block">This ZIP Code is invalid.</div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <ng-template #usernameTooltip>
      <p style="margin-bottom:0"><strong>YOUR USERNAME:</strong></p>
      <ul>
        <li>Must be between 5 and 15 characters long</li>
        <li>Must begin with a letter</li>
        <li>Must not contain spaces</li>
        <li>Must not contain special characters other than a dash, underscore or period</li>
        <li>Must not contain emojis</li>
        <li>Must not contain profane or offensive language</li>
        <li>Should not be your email address as it will be publicly visible when you post comments or create a 1320 Club profile</li>
      </ul>
    </ng-template>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group" [ngClass]="{ 'has-error': (registerForm.controls.username.invalid && (registerForm.controls.username.dirty || registerForm.controls.username.touched)) || !usernameAvailable }">
          <label for="register-username" class="control-label">
            Username
            <i class="fa fa-question-circle" [tooltip]="usernameTooltip" containerClass="username-tooltip"></i>
          </label>
          <input pattern="[0-9a-zA-Z\-_.]*" class="form-control"  id="register-username" minlength="5" maxlength="15" type="text" name="username" formControlName="username" (change)="usernameAvailability(); nameValidatedCheck($event)" (focus)="userService.trackGAevent('SignUpForm','Username')">
          <div *ngIf="registerForm.controls.username.invalid && (registerForm.controls.username.dirty || registerForm.controls.username.touched)">
            <div *ngIf="registerForm.controls.username.errors.required" class="help-block">Please enter a username.</div>
            <div *ngIf="registerForm.controls.username.errors.minlength" class="help-block"> Username must be at least 5 characters long.</div>
            <div *ngIf="hasEmojis" class="help-block"> Username cannot contain emojis.</div>
            <div *ngIf="hasSpecialCharacters" class="help-block"> Username cannot contain special characters, except for dash (-), underscore (_), or period (.).</div>
            <div *ngIf="hasWhitespace" class="help-block"> Username cannot contain whitespace.</div>
            <div *ngIf="!beginsWithLetter" class="help-block"> Username must begin with a letter.</div>
            <div *ngIf="endsWeird" class="help-block"> Username must not end in dash (-), underscore (_), or period (.).</div>
          </div>
        </div>
      </div>

      <div class="col-sm-1">
      </div>
      <div class="col-sm-5">
        <div class="form-group" [ngClass]="{ 'has-error': !usernameAvailable }">
          <label class="control-label">&nbsp;</label>
          <div class="form-control-static username-verification text-success" *ngIf="usernameAvailable && registerForm.get('username').value != '' "><i class="fa fa-check"></i> Username Available</div>
          <div class="form-control-static username-verification text-danger" *ngIf="!usernameAvailable && registerForm.get('username').value != '' "><i class="fa fa-times"></i> Username Unavailable</div>
          <!--<div id="username-available" class="username-verification" *ngIf="usernameAvailable && registerForm.get('username').value != '' "><i class="fa fa-check" style="color: #28bb00; font-size: 30px;"></i> Username Available</div>
          <div id="username-unavailable" class="username-verification" *ngIf="!usernameAvailable && registerForm.get('username').value != '' "><i class="fa fa-times" style="color: red; font-size: 30px;"></i> Username Unavailable</div>-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group" [ngClass]="{ 'has-error': registerForm.controls.password.invalid && (registerForm.controls.password.dirty || registerForm.controls.password.touched) }">
          <label for="register-password" class="control-label">Password</label>
          <input class="form-control"  id="register-password" type="password" name="password" formControlName="password" (focus)="userService.trackGAevent('SignUpForm','Password')">
          <div *ngIf="registerForm.controls.password.invalid && (registerForm.controls.password.dirty || registerForm.controls.password.touched)">
            <div *ngIf="registerForm.controls.password.errors.required" class="help-block">Please enter a password.</div>
            <div *ngIf="registerForm.controls.password.errors?.strongPassword" class="help-block">Your password must contain at least 1 character, 1 number and be at least 6 characters long.</div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group" [ngClass]="{ 'has-error': (registerForm.controls.confirmPassword.invalid || registerForm.errors?.passwordMatch) && (registerForm.controls.confirmPassword.dirty || registerForm.controls.confirmPassword.touched) }">
          <label for="register-confirm-password" class="control-label">Confirm Password</label>
          <input class="form-control" type="password" id="register-confirm-password" name="confirmPassword" formControlName="confirmPassword" (focus)="userService.trackGAevent('SignUpForm','ConfirmPassword')">
          <div *ngIf="(registerForm.controls.confirmPassword.invalid || registerForm.errors?.passwordMatch) && (registerForm.controls.confirmPassword.dirty || registerForm.controls.confirmPassword.touched)">
            <div *ngIf="registerForm.controls.confirmPassword.errors?.required" class="help-block">Please confirm the new password.</div>
            <div *ngIf="registerForm.errors?.passwordMatch && (registerForm.touched || registerForm.dirty)" class="help-block">Passwords do not match.</div>
          </div>
        </div>

      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="dg-checkbox checkbox" (click)="userService.trackGAevent('SignUpForm','CheckBox')">
            <label>
              <input type="checkbox" name="fca-communication" id="register-fca-communication" checked="checked" formControlName="fcaCommunication">
              <span class="checkbox-icon"></span>
              <span class="label-text">I would like to receive news emails from DodgeGarage.</span>
            </label>
          </div>
        </div>
        <div class="form-group">
          <div class="dg-checkbox checkbox" (click)="userService.trackGAevent('EventTicketSignUpForm','CheckBox')">
            <label>
              <input type="checkbox" name="event-communication" id="register-event-communication" checked="checked" formControlName="eventCommunication">
              <span class="checkbox-icon"></span>
              <span class="label-text">I would like to receive event and ticket offer emails from DodgeGarage.</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-12 terms-conditions">
        <div class="form-group">
          By creating an account you agree to our <a href="https://www.dodge.com/crossbrand_us/terms-of-use.html" target="_blank">terms of use.</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <button [disabled]="(isLoading || !registerForm.valid )" [ngClass]="{'is-loading' : isLoading}" type="submit" class="btn btn-primary">
            Create Account
            <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
          </button>
        </div>
      </div>
    </div> 
  </fieldset>
</form>
