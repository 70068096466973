<div class="alert alert-dismissible alert-banner" [ngClass]="['bg-' + alert.status]" *ngIf="alert.show">
  <button type="button" class="close-alert" aria-label="Close" (click)="closeAlert()"><span aria-hidden="true">&times;</span></button>
  <div class="row">
    <div class="col-sm-12 flex-container">
      <div class="context-icon">
        <i class="fa fa-exclamation-triangle" aria-hidden="true" *ngIf="alert.status == 'danger'"></i>
        <i class="fa fa-check" aria-hidden="true" *ngIf="alert.status == 'success'"></i>
        <i class="fa fa-exclamation-triangle" aria-hidden="true" *ngIf="alert.status == 'warning'"></i>
      </div>
      <div class="context-message">
        <h4 *ngIf="alert.status == 'danger'">Oops!</h4>
        <h4 *ngIf="alert.status == 'success'">Congratulations!</h4>
        <h4 *ngIf="alert.status == 'warning'">Notice</h4>
        <div>
          <p [innerHTML]="alert.message | safeHtml">{{alert.message}}</p>
        </div>
        <p *ngIf="alert.additionalMessage" [innerHTML]="alert.additionalMessage | safeHtml">{{alert.additionalMessage}}</p>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
