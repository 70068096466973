<section id="home-articles">
  <div *ngIf="articleListLoading" class="article-loader text-center">
    <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span>
  </div>

  <div class="feature">
    <article class="article-tile"
    *ngFor="let newsArticle of featureArticles | slice:0:1">
      <a [attr.href]="['/news/article/'+newsArticle.cleanLink]" (click)="goToArticle(newsArticle, $event)" rel="bookmark" title="{{newsArticle.linkTitle}}" class="ga-event" data-ga_category="Recent Articles" attr.data-ga_action="{{newsArticle.title}}" data-ga_label="Home">
        <div class="article-img">
          <picture *ngIf="newsArticle.featuredImage.src; else defaultImage">
            <source media="(min-width: 576px)" [srcset]="newsArticle.featuredImage.large.src">
            <img loading="lazy" class="img-responsive" [src]="newsArticle.featuredImage.medium.src" [alt]="newsArticle.featuredImage.alt">
          </picture>
          <ng-template #defaultImage>
            <picture>
              <img loading="lazy" class="img-responsive" src="/assets/images/default-news-image.jpg" alt="Dodge Garage News">
            </picture>
          </ng-template>
        </div>
        <div class="article-meta-container">
          <header class="entry-header">
            <span class="author">By {{newsArticle.author.name}}</span>
            <h2 class="entry-title" [innerHtml]="newsArticle.title">  </h2>
          </header>
          <div class="description" [innerHtml]="newsArticle.excerpt.changingThisBreaksApplicationSecurity"></div>
          <div class="article-meta">
            <span>
              <i class="fa fa-clock-o"></i>&nbsp;<time class="updated" [attr.datetime]="newsArticle.date | date: 'yyyy-MM-dd'" itemprop="datePublished">{{newsArticle.date | date: 'MM/dd/yyyy h:mm a'}}</time>
            </span>
            <span>&bull;&nbsp; {{ newsArticle.readTime }}</span>
          </div>
        </div>
      </a>
    </article>
  </div>
  <div class="secondary">
    <article class="article-tile"
      *ngFor="let newsArticle of featureArticles | slice:1">
      <a [attr.href]="['/news/article/'+newsArticle.cleanLink]" (click)="goToArticle(newsArticle, $event)" rel="bookmark" title="{{newsArticle.linkTitle}}" class="ga-event" data-ga_category="Recent Articles" attr.data-ga_action="{{newsArticle.title}}" data-ga_label="Home">
        <div class="article-img">
          <picture *ngIf="newsArticle.featuredImage.src; else defaultImage">
            <img loading="lazy" class="img-responsive" [src]="newsArticle.featuredImage.medium.src" [alt]="newsArticle.featuredImage.alt">
          </picture>
          <ng-template #defaultImage>
            <picture>
              <img loading="lazy" class="img-responsive" src="/assets/images/default-news-image.jpg" alt="Dodge Garage News">
            </picture>
          </ng-template>
        </div>
        <div class="article-meta-container">
          <header class="entry-header">
            <span class="author">By {{newsArticle.author.name}}</span>
            <h2 class="entry-title" [innerHtml]="newsArticle.title">  </h2>
          </header>
          <div class="article-meta">
            <span>
              <i class="fa fa-clock-o"></i>&nbsp;<time class="updated" [attr.datetime]="newsArticle.date | date: 'yyyy-MM-dd'" itemprop="datePublished">{{newsArticle.date | date: 'MM/dd/yyyy h:mm a'}}</time>
            </span>
            <span>&bull;&nbsp; {{ newsArticle.readTime }}</span>
          </div>
        </div>
      </a>
    </article>
  </div>
  <div class="listing-container">
    <div class="articles">
      <article class="article-listing" *ngFor="let newsArticle of newsArticles">
        <a [attr.href]="['/news/article/'+newsArticle.cleanLink]" (click)="goToArticle(newsArticle, $event)" rel="bookmark" title="{{newsArticle.linkTitle}}" class="ga-event" data-ga_category="Recent Articles" attr.data-ga_action="{{newsArticle.title}}" data-ga_label="Home">
          <div class="article-img">
            <picture *ngIf="newsArticle.featuredImage.src; else defaultImage">
              <img loading="lazy" class="img-responsive" [src]="newsArticle.featuredImage.thumbnail.src" [alt]="newsArticle.featuredImage.alt">
            </picture>
            <ng-template #defaultImage>
              <picture>
                <img loading="lazy" class="img-responsive" src="/assets/images/default-news-image.jpg" alt="Dodge Garage News">
              </picture>
            </ng-template>
          </div>
          <div class="article-meta-container">
            <header class="entry-header">
              <span class="author">By {{newsArticle.author.name}}</span>
              <h2 class="entry-title" [innerHtml]="newsArticle.title">  </h2>
            </header>
            <div class="article-meta">
              <span>
                <i class="fa fa-clock-o"></i>&nbsp;<time class="updated" [attr.datetime]="newsArticle.date | date: 'yyyy-MM-dd'" itemprop="datePublished">{{newsArticle.date | date: 'MM/dd/yyyy h:mm a'}}</time>
              </span>
              <span>&bull;&nbsp; {{ newsArticle.readTime }}</span>
            </div>
          </div>
        </a>
      </article>
    </div>
    <a routerLink="/news" class="btn btn-block btn-view-news" data-dest="News" data-ga_category="Featured News" data-ga_action="Home" data-ga_label="News">View All News</a>
  </div>
</section>