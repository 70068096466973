
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { HelperService } from '../services/helper.service';
import { Challenger } from './challenger';
import { ChallengerSearch } from './challengerSearch';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable()
export class ChallengerService {
  constructor(
    public helperService: HelperService,
    private http: HttpClient
  ) {}

  getOptions(selections = {}): Observable<any> {
    const url = environment.url+'/rest/api/v1/challenger/options';
    return this.http.post(url, selections).pipe(
      catchError(this.helperService.handleError));
  }

}
