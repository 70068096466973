
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {UserModel} from "../user";
import {UserService} from "../user.service";
import {Observable} from "rxjs";

@Injectable()
export class UserProfileResolverService implements Resolve<UserModel> {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserModel> {
    return this.userService.getUserByUsername(route.params.username).pipe(map( user => {
      if (user.username) {
        return user;
      } else {
        this.router.navigate(['/']);
        return null;
      }
    }))
  }

}
