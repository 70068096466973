import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'thirteen-twenty-cta',
  templateUrl: './thirteen-twenty-cta.component.html',
  styles: []
})
export class ThirteenTwentyCtaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
