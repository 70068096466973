<!-- <h1>{{isEditingMode?'Edit':'Add'}} Your Vehicle</h1> -->

<p *ngIf="isEditingMode" class="text-center"><small><button class="btn btn-sm btn-link" (click)="openConfirmDeleteModel(confirmDeleteModal, vehicle)">Delete Vehicle</button></small></p>

<dg-alert-banner [alert]="vehicleFormAlert"></dg-alert-banner>

<div class="row flex-row">
  <div class="col-sm-4">
    <div class="form-group" [ngClass]="{'has-error':addVehicle.hasErrorVehicleLookupId}">
      <label class="control-label form-accessibility-label">Year
        <select class="form-control dg-form-select" [(ngModel)]="year" (change)="fetchModels()" [disabled]="isEditingMode">
          <option value="">Year</option>
          <option value="{{year}}" *ngFor="let year of years">{{year}}</option>
        </select>
      </label>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="form-group" [ngClass]="{'has-error':addVehicle.hasErrorVehicleLookupId}">
      <label class="control-label form-accessibility-label">Model
        <select class="form-control dg-form-select" [(ngModel)]="model" (change)="fetchTrims()" [disabled]="isEditingMode">
          <option value="">Model</option>
          <option value="{{model}}" *ngFor="let model of models">{{model}}</option>
        </select>
      </label>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="form-group form-accessibility-label" [ngClass]="{'has-error':addVehicle.hasErrorVehicleLookupId}">
      <label class="control-label">Trim
        <select class="form-control dg-form-select" [(ngModel)]="addVehicle.vehicleLookupId" [disabled]="isEditingMode">
          <option value="0">Trim</option>
          <option value="{{trim['id']}}" *ngFor="let trim of trims">{{trim['trim']}}</option>
        </select>
      </label>
    </div>
  </div>
</div>

<div *ngIf="addVehicle.hasErrorVehicleLookupId" class="form-group validation-helper" [ngClass]="{'has-error':addVehicle.hasErrorVehicleLookupId}">
  <div class="help-block">Please select a vehicle.</div>
</div>

<div class="row">
  <div class="col-sm-4">
    <div class="form-group" [ngClass]="{'has-error':addVehicle.hasErrorVin}">
      <label class="control-label form-accessibility-label">Full VIN
        <input type="text" class="form-control" name="vin" [(ngModel)]="addVehicle.vin" size="17" maxlength="17" placeholder="xxxxxxxxxxxxxxxxx">
      </label>
      <div *ngIf="addVehicle.hasErrorVin" class="help-block">Please enter a 17 character alphanumeric vin number.</div>
    </div>
  </div>
  <div class="col-sm-4">
    <span class="control-label section-label">Vehicle Group</span>
    <div class="form-group">
      <div class="dg-radios">
        <label class="radio-inline">
          <input type="radio" name="isStock" [value]="true" [(ngModel)]="addVehicle.isStock">
          <span class="label-text">Stock</span>
          <span class="radio-icon"></span>
        </label>
        <label class="radio-inline">
          <input type="radio" name="isStock" [value]="false" [(ngModel)]="addVehicle.isStock">
          <span class="label-text">Modified</span>
          <span class="radio-icon"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-sm-4 spacer-xs">
    <span class="control-label section-label hidden-xs">&nbsp;</span>
    <div class="form-group">
      <div class="dg-checkbox checkbox">
        <label>
          <input type="checkbox" name="isStock" [value]="true" [(ngModel)]="addVehicle.isDefault">
          <span class="checkbox-icon"></span>
          <span class="label-text">Set as Default Vehicle?</span>
        </label>
      </div>
    </div>
  </div>
</div>

<div class="row flex-row vehicle-group">
  <div class="col-sm-7 mods">
    <div class="form-group">
      <label class="control-label section-label form-accessibility-label">Vehicle Mods <small>(One mod per line)</small>
        <textarea class="form-control" rows="8" name="mods" placeholder="List all vehicle modifications" [(ngModel)]="addVehicle.mods"></textarea>
      </label>
    </div>
  </div>
  <div class="col-sm-5 stock">
    <span class="control-label section-label">Stock Qualifications</span>
    <ul class="list-flush-left">
      <li>Different tires</li>
      <li>
        Safety related modifications
        <ul class="list-flush-left list-flex half list-child">
          <li>Safety Harness</li>
          <li>Engine cut-off</li>
          <li>Roll bars</li>
          <li>Battery box</li>
          <li>Roll cage</li>
          <li>Driveshaft loop</li>
          <li>Fire suspension</li>
          <li>Hoodpins</li>
        </ul>
      </li>
      <li>All other modifications are considered a modified vehicle</li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group dg-upload" [ngClass]="{'has-error':addVehicle.hasErrorVehicleImage}">
      <span class="control-label section-label">Click the image below to change the vehicle image</span>
      <div class="vehicle-image-container add-vehicle-img">
        <button *ngIf="addVehicle.originalImage" class="btn-circle-delete" (click)="deleteVehicleImage(addVehicle.vehicle, addVehicle.originalImage)">&times;</button>
        <label class="img-upload" title="Click Here to Change the Vehicle Image">
          <img loading="lazy" [src]="addVehicle.vehicleImageSrc" alt="Click Here to Change the Vehicle Image" class="img-responsive">
          <input class="sr-only" type="file" name="vehicleImage" accept="image/png, image/jpeg" (change)="onFileChanged($event, imgModal)">
        </label>
      </div>
      <!--<span class="control-label section-label">Upload a picture of the vehicle (Can be added later)</span>
      <div class="upload-container">
        <label class="control-label btn btn-ghost-black text-uppercase">
          Choose File
          <input type="file" name="vehicleImage" accept="image/png, image/jpeg" (change)="onFileChanged($event, imgModal)">
        </label>
        <p>
          {{ addVehicle.vehicleImage ? addVehicle.vehicleImage.name : 'No File Chosen' }}
        </p>
      </div>-->
      <!-- <p *ngIf="addVehicle.vehicleImage">{{ addVehicle.vehicleImage.name }}</p> -->
    </div>
  </div>
  <!--<div class="col-sm-6" *ngIf="addVehicle.originalImage">
    <div class="form-group">
      <label class="control-label">Current Image</label>
      <div class="add-vehicle-img-group">
        <div class="add-vehicle-img">
          <button class="btn-circle-delete" (click)="deleteVehicleImage(addVehicle.vehicle, addVehicle.originalImage)">&times;</button>
            <img loading="lazy" class="img-responsive" style="max-width: 75px" [src]="addVehicle.originalImage" alt="Original Image">
        </div>
      </div>

    </div>
  </div>-->
</div>

<!--<div class="form-group spacer-xs vehicle-group" *ngIf="!isEditingMode">
  <button class="btn btn-link" (click)="saveVehicle()">+ Add Vehicle</button>
</div>
<div class="form-group" *ngIf="isEditingMode">
  <button class="btn btn-link" (click)="saveVehicle()">Update Vehicle</button>
  <button class="btn btn-link" (click)="resetVehicle()">Cancel Editing</button>
</div>-->

<div class="form-group text-center" *ngIf="!isEditingMode && onboarding"> <!-- If not editing and onboarding -->
  <button class="btn btn-primary" (click)="saveVehicle()" [disabled]="isLoading">
    Save and Proceed to Adding a Time Slip
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
  </button>
</div>
<div class="form-group text-center" *ngIf="!isEditingMode && !onboarding"> <!-- If not editing and onboarding -->
  <button class="btn btn-primary" (click)="saveVehicle()" [disabled]="isLoading">
    Add Vehicle
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
  </button>
</div>
<div class="form-group text-center" *ngIf="isEditingMode && !onboarding"> <!-- If not editing and onboarding -->
  <button class="btn btn-primary" (click)="saveVehicle()" [disabled]="isLoading">
    Update Vehicle
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="isLoading"></i>
  </button>
</div>

<div class="hidden" *ngFor="let vehicle of vehicles">
  <div class="overview-card">
    <!-- <div class="thumbnail">
      <img loading="lazy" src="https://fillmurray.com/g/600/400" alt="" class="img-responsive">
    </div> -->
    <div class="thumbnail" *ngIf="vehicle.images.length > 0">
      <img loading="lazy" class="img-responsive" [src]="vehicle.images[0].imagePath" alt="{{vehicle.year}} {{vehicle.model}} {{vehicle.trim}}">
    </div>
    <div class="content">
      <div class="content-header">
        <h4 class="title">Vehicle #{{ vehicle.id }}</h4>
        <div class="tools">
          <button class="btn btn-sm btn-link" (click)="openConfirmDeleteModel(confirmDeleteModal, vehicle)">Delete</button> <button class="btn btn-sm btn-link" (click)="setEditVehicle(vehicle)">Edit</button>
        </div>
      </div>
      <div class="details">
        <div class="year">
          <span>Year</span>
          <span>{{ vehicle.year }}</span>
        </div>
        <div class="model">
          <span>Model</span>
          <span>{{ vehicle.model }}</span>
        </div>
        <div class="trim">
          <span>Trim</span>
          <span>{{ vehicle.trim }}</span>
        </div>
        <div class="vin">
          <span>Full Vin</span>
          <span>{{ vehicle.vin }}</span>
        </div>
        <div class="group">
          <span>Vehicle Group</span>
          <span>{{ (vehicle.isStock) ? 'Stock' : 'Modified' }}</span>
        </div>
        <div class="mods">
          <span>Mods</span>
          <span>{{ (vehicle.mods) ? vehicle.mods : 'N/A' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="row spacer-xs vehicle-group" *ngIf="onboarding == true">
  <div class="col-sm-12">
    <div class="form-group">
      <button class="btn btn-primary" (click)="saveVehicle()">Add Vehicle</button>
    </div>
  </div>
</div>-->

<!-- Modal -->
<ng-template #confirmDeleteModal>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete this vehicle?</p>
    <button type="button" class="btn btn-primary" (click)="confirmDeleteVehicle()" [disabled]="isDeleteLoading">
      Yes
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="isDeleteLoading"></i>
    </button>
    <button type="button" class="btn btn-white" (click)="confirmDeleteModalRef.hide()" >No</button>
  </div>
</ng-template>

<!-- Modal -->
<ng-template #imgModal>
  <div class="modal-container">
    <div class="modal-header">
      <h2 class="modal-title pull-left">Edit Your Image</h2>
      <button class="modal-close" (click)="imgModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <dg-image-editing [imageFile]="addVehicle.vehicleImage" (croppedImage)="updateVehicleImage($event)"></dg-image-editing>
    </div>
  </div>
</ng-template>

