<h2 class="heading2 text-uppercase">{{listInfo.name}}</h2>
<hr>

<p *ngIf="listInfo.description">{{listInfo.description}}</p>

<div class="video-list">
  <div class="video-thumbnail mb-4" *ngFor="let video of videos">
    <a href="#" (click)="openVideoModal(video, $event)" (deferLoad)="video.isLoaded = true" [attr.aria-label]="video.name">
      <img [src]="video.thumbnail" [alt]="video.name" *ngIf="video.isLoaded">
    </a>
    <div class="video-description">
      <p>{{ video.description }}</p>
    </div>
  </div>
  <ngx-json-ld [json]="schema"></ngx-json-ld>
</div>

<div class="modal fade" bsModal #videoModal tabindex="-1" role="dialog"
  (onHidden)="videoModalListener('onHidden', $event)">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title heading1 pull-left">{{selectedVideo.name}}</h2>
        <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
      </div>
      <div class="modal-body">
        <p class="description" *ngIf="selectedVideo.description && selectedVideo.description != ''">{{selectedVideo.description}}</p>
        <div class="embed-responsive embed-responsive-16by9 mb-4">
          <div class="embed-responsive-item" *ngIf="selectedVideo.embedCode != ''" [innerHTML]="selectedVideo.embedCode"></div>
        </div>
        <div class="addthis_inline_share_toolbox text-center" attr.data-title="{{selectedVideo.name}}" attr.data-media="{{selectedVideo.thumbnail}}"></div>
      </div>
    </div>
  </div>
</div>
