import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {PodcastService} from "../../podcast/podcast.service";
import {AudioItem} from "../../audio/audio-item";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'social-banner',
  templateUrl: './social-banner.component.html'
})
export class SocialBannerComponent implements OnInit {

  latestPodcast: AudioItem;
  isDgJuicerLoaded: boolean = true;
  feedID: string;

  constructor(
    public podcastService: PodcastService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // front page social
    this.feedID = 'thatsmydodge-4cfbafca-ad83-45e8-8703-1374c1a4b885';
  }

  scrollToAnimation() {
    const banner = document.querySelector('#dodge-social-banner') as HTMLElement
    const bannerHeight = banner.offsetHeight

    window.addEventListener('scroll', function() {
      if ( (window.pageYOffset + window.innerHeight) >= banner.offsetTop + bannerHeight )  {
        banner.classList.add('active')
      }else {
        banner.classList.remove('active')
      }
    })
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollToAnimation();
      /*this.podcastService.getPodcastsByDay('Wed', 1).subscribe(data => {
        this.latestPodcast = this.podcastService.mutatePodcast(data[0]);
      });*/
    }
  }

}
