<div [id]="id" class="leaderboard-container stock">
  <img class="winner-header" src="/assets/images/twenty-days/winners-header.webp" alt="Random Daily Winners Gold header image">
  <div class="leaderboard-header">
    <!-- <span class="title text-uppercase">{{ titleDate(selectedDateNew) }} Drawing <span class="font-bold text-primary">//</span> <br>{{ vehicle }} WINNERS</span> -->
    <span class="title text-uppercase">  
      {{ titleDate(selectedDateNew) }} <span class="font-bold text-primary">//</span>&nbsp;<br class="visible-xs">{{ vehicle }} Dealer
      <br>
      {{ eventVehicle }} Allocation
    </span>
    <div *ngIf="!comingSoon" class="calendar-select ml-auto">
      <button class="btn btn-primary" (click)="dp.toggle()"><i class="fa fa-calendar"></i></button>
      <input 
        [id]="'datepicker-' + vehicle" 
        class="date-picker form-control" 
        bsDatepicker 
        [daysDisabled]="[0,6]" 
        [datesDisabled]="disabledDates"
        [minDate]="startDate" 
        [maxDate]="endDate"
        [(ngModel)]="selectedDateNew" 
        (bsValueChange)="showValue()"
        #dp="bsDatepicker" 
        [bsConfig]="{ showWeekNumbers: false, selectFromOtherMonth: true }">
        <span class="helper-text text-center">Click for<br>other dates</span> 
    </div>
  </div>
  <fieldset>
    <div class="leaderboard" (window:resize)="onResize($event)">
      <div class="lb-section head hidden-xs hidden-sm">
        <div class="lb-row">
          <div class="lb-cell bc">BC</div>
          <div class="lb-cell code">Dealer Code</div>
          <div class="lb-cell name">Dealer Name</div>
        </div>
      </div>
      <div class="lb-section body" [style.height.px]="boardHeight">
        <div *ngIf="!comingSoonReactive && !hideWinnerReactive && !loading" class="lb-scroll" #scrollPosition [style.margin-top.px]="offsetY">
          <div class="lb-row" #rowPosition *ngFor='let dealer of dealers | tableFilter: { salesDateFormatted:activeDate }'> 
            <div class="lb-cell bc"><span>{{ dealer.bc }}</span></div>
            <div class="lb-cell code"><span>{{ dealer.dealer_code }}</span></div>
            <div class="lb-cell name"><span>{{ dealer.dealer_name }}</span></div>
          </div>
        </div>
        <div *ngIf="loading" class="loader"></div>
        <div *ngIf="comingSoonReactive && !loading" class="coming-soon">
          <img src="/assets/images/twenty-days/coming-soon.webp" alt="Coming Soon graphic with grunge text">
        </div>
        <div *ngIf="!comingSoonReactive && hideWinnerReactive && !loading" class="choosing-winners">
          <p class="heading">Today's random winners coming soon</p>
        </div>
      </div>
    </div>
  </fieldset>
</div>
