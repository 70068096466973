<div id="demon-170-owner-info">
  <div class="hero">
    <div class="site-wrapper">
      <div class="container-fluid">
        <div class="hero-heading">
          <img src="/assets/images/demon-170/demon-170-logo.webp" alt="Demon 170 head logo">
          <h1>
            SRT<sup>&reg;</sup> Demon 170:
            <span>After Delivery Owner Info</span>
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="site-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 intro">
          <h2>Because more is more.</h2>
          <p>
            The 2023 Dodge Challenger SRT<sup>&reg;</sup> Demon 170 comes with customized, limited-edition, serialized items that become available to order after you take delivery. From the included personalized, commemorative SRT Demon 170 decanter set and a custom instrument panel badge, to an appearance-mimicking, breathable Goodwool car shell and Direct Connection carbon fiber parts available for purchase. Check out all the available items to complete your SRT Demon 170 ownership experience.
          </p>
          <p>
            <strong>
              After you take delivery of your SRT Demon 170, call the Dodge//SRT Concierge team at 800-998-1110 to verify your ownership and place your orders.
            </strong>
          </p>
          <p class="mb-0">
            <strong>
              ORDERING IS OPEN FROM SEPTEMBER 6, 2023 THROUGH NOVEMBER 26, 2024.
            </strong>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="product-grid">
            <p>Click for product information</p>
            <div class="products">
              <product-card 
                *ngFor="let product of products; let i = index" 
                [product]="product"
                (click)="showDescription($event, i, product.description, product.contents, product.price, product.subproduct)"></product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
