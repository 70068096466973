import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoListComponent } from './video-list/video-list.component';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxJsonLdModule } from "@ngx-lite/json-ld";


@NgModule({
  declarations: [
    VideoListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DeferLoadModule,
    ModalModule.forRoot(),
    NgxJsonLdModule,
  ],
  exports: [
    VideoListComponent
  ]
})
export class VideoSharedModule { }
