import { Component, OnInit, TemplateRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import { HelperService } from "../services/helper.service";
import { GalleryServiceService } from "./gallery-service.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'dg-last-chance',
  templateUrl: './last-chance.component.html'
})
export class LastChanceComponent implements OnInit {

  book: string = 'https://slate.adobe.com/a/74EGR';
  modalRef: BsModalRef;

  galleryItems = this.galleryService.getGalleryItems();
  activeImage: any;
  
  //https://spark.adobe.com/page/TefNgHYlgdXuh/embed.html

  constructor(
    public title: Title,
    public metaService: Meta,
    public helperService: HelperService,
    private router: Router,
    public sanitizer: DomSanitizer,
    private galleryService: GalleryServiceService,
    private modalService: BsModalService
  ) { 
    title.setTitle( 'The Ultimate Last Chance | Dodge Garage'  );
    metaService.updateTag({ name: 'description', content: 'The Ultimate Last Chance. Barrett-Jackson Northeast Auction Saturday, June 23, 2018 at 3:00pm EST. One hundred percent of the hammer proceeds to benefit United Way.' });
    metaService.updateTag({ property: 'og:description', content: 'The Ultimate Last Chance. Barrett-Jackson Northeast Auction Saturday, June 23, 2018 at 3:00pm EST. One hundred percent of the hammer proceeds to benefit United Way.' });
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
    metaService.updateTag({ property: 'og:title', content: 'The Ultimate Last Chance | Dodge Garage' });
    metaService.updateTag({ property: 'og:type', content: '' });
    metaService.updateTag({ property: 'og:image', content: 'https://www.dodgegarage.com/assets/images/ultimate-last-chance/ultimate-last-chance-social.jpg' });
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url });
    metaService.updateTag({ property: 'og:image:alt', content: 'The Ultimate Last Chance Auction' });
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url });
    metaService.updateTag({ name: 'twitter:card', content: 'summary' });
    metaService.updateTag({ name: 'twitter:image', content: 'https://www.dodgegarage.com/assets/images/ultimate-last-chance/ultimate-last-chance-social.jpg' });
    metaService.updateTag({ name: 'twitter:image:width', content: ''});
    metaService.updateTag({ name: 'twitter:image:height', content: ''});
  }

  ngOnInit() {
    this.galleryService.getGalleryItems();
    console.log(this.galleryItems);
  }

  openModal(template: TemplateRef<any>, fullImage) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'img-modal' })
    );
    this.activeImage = fullImage; 
  }

}
