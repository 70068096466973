import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { HelperService } from './helper.service';
import { response } from 'express';

interface dealerSearchResponse {
  slice: any
}

@Injectable({
  providedIn: 'root'
})
export class DealerLocatorService {

  constructor(
    public helperService: HelperService,
    private http: HttpClient
  ) { }

  getDealerByName( dealerName: String ): Observable<any> {
    const url = environment.dealerLocatorUrl + '/getDealerships/dodge/dealersearch?callback=jsonCallback&name=' + dealerName;
    const requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    return this.http.get<any>(url, requestOptions).pipe(
      map( response => {  // NOTE: response is of type SomeType
        // Does something on response.data
        // modify the response.data as you see fit.
        response = JSON.parse(response.slice(13, -1))
        // return the modified data:
        return response; // kind of useless
      }),
      catchError(this.helperService.handleError)
    );
  }
}
