<div class="site-wrapper">
  <header class="page-header">
    <div class="px-md-4">
      <h1>DodgeGarage Videos</h1>
    </div>
  </header>
  <div class="px-xs-4 px-sm-4">
    <div *ngFor="let album of videoAlbums">
      <dg-video-list *ngIf="album.uri != ''" [listInfo]="album"></dg-video-list>
    </div>
    
  </div>
</div>

<div class="modal fade modal-video" bsModal #videoModal tabindex="-1" role="dialog"
  (onHidden)="videoModalListener('onHidden', $event)">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title heading1 pull-left">{{selectedVideo.name}}</h2>
        <button class="modal-close" (click)="closeVideoModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
      </div>
      <div class="modal-body">
        <p class="description" *ngIf="selectedVideo.description && selectedVideo.description != ''">{{selectedVideo.description}}</p>
        <div class="embed-responsive embed-responsive-16by9 mb-4">
          <div class="embed-responsive-item" *ngIf="selectedVideo.embedCode != ''" [innerHTML]="selectedVideo.embedCode | safe: 'html'"></div>
        </div>
        <div class="addthis_inline_share_toolbox text-center" attr.data-title="{{selectedVideo.name}}" attr.data-media="{{selectedVideo.thumbnail}}"></div>
      </div>
    </div>
  </div>
</div>