<div class="site-wrapper" id="contributors">
  <div class="container-fluid racing-hq">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="heading1">Badass Contributors</h1>
        <div class="row">
          <p class="col-lg-8">
            Dodgegarage.com is a community where muscle car and race enthusiasts can connect with each other and get the latest on all things Dodge, SRT<sup class="realign superscript">®</sup>, Mopar…and anything automotive related worth talking about! Just as we look for a wide variety of enthusiast stories and events, our editorial contributors have diverse backgrounds and experiences to help craft stories from their own unique perspectives.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h2 class="heading2">Meet Our Current Contributors:</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 contributor-container" *ngFor="let contributor of contributors" [id]="contributor.slugUrl" >
        <div class="contributor-media">
          <img loading="lazy" [src]="contributor.image" [alt]="contributor.name" class="img-responsvie">
        </div>
        <div class="contributor-bio">
          <h2 class="text-uppercase">{{contributor.name}}</h2>
          <div class="bio-content" [innerHTML]="contributor.bio"></div>
          <p><a [routerLink]="['/news/author/'+contributor.slugUrl]">Read Stories by {{contributor.name}}</a></p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h2 class="heading2">Check Out Our Past Contributors + Their Stories:</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 contributor-container" *ngFor="let contributor of pastContributors" [id]="contributor.slugUrl" >
        <div class="contributor-media">
          <img loading="lazy" [src]="contributor.image" [alt]="contributor.name" class="img-responsvie">
        </div>
        <div class="contributor-bio">
          <h2 class="text-uppercase">{{contributor.name}}</h2>
          <div class="bio-content" [innerHTML]="contributor.bio"></div>
          <p><a [routerLink]="['/news/author/'+contributor.slugUrl]">Read Stories by {{contributor.name}}</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row btn-container">
  <div class="site-wrapper">
    <div class="col-sm-12">
      <a routerLink="/news" class="btn btn-block btn-primary ga-event" data-ga_category="Contributors" data-ga_action="Click" data-ga_label="Back to News">Back to News</a>
    </div>
  </div>
</div>

