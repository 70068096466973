import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  HostListener,
  Renderer2,
  PLATFORM_ID,
  Inject,
  ViewChild,
  ChangeDetectorRef,
  Injectable
} from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import {ActivatedRoute, Router} from "@angular/router";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {UserModel} from "../user";
import {UserService} from "../user.service";
import {Subscription} from "rxjs";
import {TimeSlipService} from "../../dg-1320/time-slip/time-slip.service";
import {Vehicle} from "../../vehicle/vehicle";
import {TimeSlip} from "../../dg-1320/time-slip/time-slip";
import {VehicleService} from "../../vehicle/vehicle.service";
import {BadgeService} from "../../dg-1320/badge/badge.service";
import { Badge } from '../../dg-1320/badge/badge';
import {Meta, Title} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";
import {TabsetComponent} from "ngx-bootstrap/tabs";
import {AddThisService} from '../../services/addthis.service';
declare let addthis: any;
declare let Ecwid: any;

function _window(): any {
  // return the native window obj
  return window;
}

@Injectable()
export class WindowRef {

  get nativeWindow(): any {
    return _window();
  }

}

@Component({
  selector: 'dg-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.less'],
  providers: [ WindowRef ]
})
export class UserProfileComponent implements OnInit, OnDestroy {

  // Featured/Initial Image
  featuredImage: string = '';

  noVehicles: boolean = false;

  //Stock and Modified Images
  defaultImg: string = '/assets/images/1320/badges/no-vehicle.jpg'
  stockImg: string = '/assets/images/1320/badges/stock/club/stock.png'
  modifiedImg: string = '/assets/images/1320/badges/modified/club/modified.png'
  stockBadgeImg: string = '/assets/images/1320/badges/stock/club/badge-stock.png'
  modifiedBadgeImg: string = '/assets/images/1320/badges/modified/club/badge-modified.png'

  eightSecondsStockBadgeImg: string = '/assets/images/1320/badges/eight-seconds/stock/club/badge-stock.webp'
  eightSecondsModifiedBadgeImg: string = '/assets/images/1320/badges/eight-seconds/modified/club/badge-mod.webp'

  // User login status
  loggedInSub: Subscription;

  isOwnersProfile: boolean = false;
  user: UserModel = new UserModel();

  @ViewChild('vehicleTabs', { static: true }) vehicleTabs: TabsetComponent;
  selectedVehicleTab: number = 0;
  selectedVehicleId: number;
  vehicleModalRef: BsModalRef;
  timeslipModalRef: BsModalRef;
  imgModalRef: BsModalRef;

  vehicleToEdit: Vehicle = new Vehicle();
  timeslipToEdit: TimeSlip = new TimeSlip();

  //modSlotETLength: number = this.modBadges.et.length;
  badgesLoaded: boolean = false; //disables loader when badges are rendered

  slotCounterET: number = 0;
  slotCounterETOffset: number = 0;
  eightSecondSlotCounterET: number = 0;
  eightSecondSlotCounterETOffset: number = 0;

  slotCounterSixty: number = 0;
  slotCounterSixtyOffset: number = 0;
  eightSecondSlotCounterSixty: number = 0;
  eightSecondSlotCounterSixtyOffset: number = 0;

  slotCounterSpeed: number = 0;
  slotCounterSpeedOffset: number = 0;
  eightSecondSlotCounterSpeed: number = 0;
  eightSecondSlotCounterSpeedOffset: number = 0;

  cartQuantity: number = 0;

  badges:any = {
    stock: {
      club: [],
      et: [],
      topspeed: [],
      zerotosixty: []
    },
    modified: {
      club: [],
      et: [],
      topspeed: [],
      zerotosixty: []
    }
  };

  imgBigger: string = '';

  isSharingIconActive: boolean = false;

  constructor(
    public badgeService: BadgeService,
    public timeSlipService: TimeSlipService,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    public vehicleService: VehicleService,
    private addThisService: AddThisService,
    public title: Title,
    public metaService: Meta,
    private cd: ChangeDetectorRef,
    private renderer2: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private winRef: WindowRef
  ) {

    this.route.data.subscribe((data: { user: UserModel }) => {
      this.user = data.user;
      title.setTitle( this.user.username + '\'s Garage | Dodge Garage' );
      metaService.updateTag({ name: 'description', content: 'Check out ' + data.user.username + '\'s Garage &amp; top 1/4 mile achievements.'});
      metaService.updateTag({ name: 'og:description', content: 'Check out ' + data.user.username + '\'s Garage &amp; top 1/4 mile achievements.' });
      metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
      metaService.updateTag({ property: 'og:title', content: this.user.username + '\'s Garage | Dodge Garage' });
      metaService.updateTag({ property: 'og:type', content: 'Page' });
      metaService.updateTag({ rel: 'canonical', href: environment.url + '/user/' + data.user.username });

      metaService.updateTag({ property: 'og:url', content: environment.url + '/user/' + data.user.username });
      metaService.updateTag({ property: 'og:image:alt', content: 'Garage for ' + data.user.username });
      metaService.updateTag({ name: 'twitter:card', content: 'summary' });
      metaService.updateTag({ name: 'twitter:site', content: 'Dodge Garage' });
      metaService.updateTag({ name: 'twitter:title', content: this.user.username + '\'s Garage | Dodge Garage' });
      metaService.updateTag({ name: 'twitter:description', content: 'Garage for ' + data.user.username });
      
      if (!this.user.vehicles || this.user.vehicles.length === 0) {
        this.noVehicles = true;
      }else {
        if (this.user.vehicles && this.user.vehicles.length > 0 && this.user.vehicles[0].images.length > 0) {
          this.featuredImage = environment.url + this.user.vehicles[0].images[0].imagePath;
          metaService.updateTag({ property: 'og:image', content: environment.url + this.user.vehicles[0].images[0].imagePath });
          metaService.updateTag({ name: 'twitter:image', content: environment.url + this.user.vehicles[0].images[0].imagePath });
        } else {
          this.featuredImage = environment.url + (this.user.vehicles[0].isStock)?this.stockImg:this.modifiedImg;
          metaService.updateTag({ name: 'og:image', content: environment.url + (this.user.vehicles[0].isStock)?this.stockImg:this.modifiedImg });
          metaService.updateTag({ name: 'twitter:image', content: environment.url + (this.user.vehicles[0].isStock)?this.stockImg:this.modifiedImg });
        }
      }
      
    });

    this.route.params.subscribe( params => {
      if (params.vehicleId) {
        this.selectedVehicleId = params.vehicleId;
      } else {
        this.user.vehicles.length > 0 ? this.selectedVehicleId = this.user.vehicles[0].id : null
        //this.selectedVehicleId = this.user.vehicles[0].id;
      }
    });
  }

  ngOnInit() {

    // Listen for user login change
    this.loggedInSub = this.userService.loggedIn$.subscribe(loggedIn => {
      if (this.userService.userModel.isLoggedIn) {
        if (this.user.username == this.userService.userModel.username) {
          this.isOwnersProfile = true;

          //Setup Ecwid when the user logs in
          if (isPlatformBrowser(this.platformId)) {
            // init sso profile variable
            this.winRef.nativeWindow["ecwid_sso_profile"] = '';
            document.addEventListener('readystatechange', () => {
              if (document.readyState == "complete") {
                //this.setupEcwid();
              }
            });
            // Add AddThis to Page
            const script = document.createElement('script');
            script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
            document.body.appendChild(script);
            // Reload AddThis
            setTimeout(() => {
              addthis.layers.refresh();
              this.addThisService.setAddThisVisibility(true);
            }, 3000);
          }
          

          //Retrieve badges by vehicle
          if (this.user.vehicles) {

            this.user.vehicles.forEach( vehicle => {
              this.fetchBadgesByVehicle(vehicle);
            });
          }
        }
      }
    });

    // Check if user is already logged in
    if (this.userService.userModel.isLoggedIn) {
      if (this.user.username == this.userService.userModel.username) {
        this.isOwnersProfile = true;

        //Retrieve badges by vehicle
        if (this.user.vehicles) {
          this.user.vehicles.forEach( vehicle => {
            this.fetchBadgesByVehicle(vehicle);
          });
        }
      }
    }

    //Retrieve badges by vehicle
    if (this.user.vehicles) {
      this.user.vehicles.forEach( vehicle => {
        this.fetchBadgesByVehicle(vehicle);
      });
    }

    if (isPlatformBrowser(this.platformId)) {

      // Add Ecwid script regardless of auth to ensure the script is there for any interactivity.
      this.setupEcwid();

      // Add AddThis to Page (For Public Profiles)
      const script = document.createElement('script');
      script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
      document.body.appendChild(script);
      // Reload AddThis
      setTimeout(() => {
        addthis.layers.refresh();
        this.addThisService.setAddThisVisibility(true);
      }, 3000);
    }
  }



  ngOnDestroy() {
    // destroy ecwid script
    //this.setupEcwid('destroy');


    if (this.loggedInSub)
      this.loggedInSub.unsubscribe();
    if (this.vehicleModalRef)
      this.vehicleModalRef.hide();
    if (this.timeslipModalRef)
      this.timeslipModalRef.hide();
  }

  counterEtInc(vehicle: Vehicle, counterDemon: boolean = false) {
    if (counterDemon) {
      if( this.eightSecondSlotCounterET < vehicle.eightSecondSlotCounterETMax - 1 ) {
        this.eightSecondSlotCounterET++
        this.eightSecondSlotCounterETOffset = (this.eightSecondSlotCounterET * 150) * -1;
      }else {
        this.eightSecondSlotCounterET = vehicle.eightSecondSlotCounterETMax - 1;
      }
    }else {
      if( this.slotCounterET < vehicle.slotCounterETMax - 1 ) {
        this.slotCounterET++
        this.slotCounterETOffset = (this.slotCounterET * 150) * -1;
      }else {
        this.slotCounterET = vehicle.slotCounterETMax - 1;
      }
    }
  }
  counterEtDec(vehicle: Vehicle, counterDemon: boolean = false) {
    if (counterDemon) {
      if( this.eightSecondSlotCounterET > 0 ) {
        this.eightSecondSlotCounterET--;
        this.eightSecondSlotCounterETOffset = (this.eightSecondSlotCounterET * 150) * -1;
      }else {
        this.eightSecondSlotCounterET = 0;
      }
    }else {
      if( this.slotCounterET > 0 ) {
        this.slotCounterET--;
        this.slotCounterETOffset = (this.slotCounterET * 150) * -1;
      }else {
        this.slotCounterET = 0;
      }
    }
  }

  counterSixtyInc(vehicle: Vehicle, counterDemon: boolean = false) {
    if (counterDemon) {
      if( this.eightSecondSlotCounterSixty < vehicle.eightSecondSlotCounterSixtyMax - 1 ) {
        this.eightSecondSlotCounterSixty++
        this.eightSecondSlotCounterSixtyOffset = (this.eightSecondSlotCounterSixty * 150) * -1;
      }else {
        this.eightSecondSlotCounterSixty = vehicle.eightSecondSlotCounterSixtyMax - 1;
      }
    }else {
      if( this.slotCounterSixty < vehicle.slotCounterSixtyMax - 1 ) {
        this.slotCounterSixty++
        this.slotCounterSixtyOffset = (this.slotCounterSixty * 150) * -1;
      }else {
        this.slotCounterSixty = vehicle.slotCounterSixtyMax - 1;
      }
    }
  }
  counterSixtyDec(vehicle: Vehicle, counterDemon: boolean = false) {
    if (counterDemon) {
      if( this.eightSecondSlotCounterSixty > 0 ) {
        this.eightSecondSlotCounterSixty--
        this.eightSecondSlotCounterSixtyOffset = (this.eightSecondSlotCounterSixty * 150) * -1;
      }else {
        this.eightSecondSlotCounterSixty = 0;
      }
    }else {
      if( this.slotCounterSixty > 0 ) {
        this.slotCounterSixty--
        this.slotCounterSixtyOffset = (this.slotCounterSixty * 150) * -1;
      }else {
        this.slotCounterSixty = 0;
      }
    }
  }

  counterSpeedInc(vehicle: Vehicle, counterDemon: boolean = false) {
    if (counterDemon) {
      if( this.eightSecondSlotCounterSpeed < vehicle.eightSecondSlotCounterSpeedMax - 1 ) {
        this.eightSecondSlotCounterSpeed++
        this.eightSecondSlotCounterSpeedOffset = (this.eightSecondSlotCounterSpeed * 150) * -1;;
      }else {
        this.eightSecondSlotCounterSpeed = vehicle.eightSecondSlotCounterSpeedMax - 1;
      }
    }else {
      if( this.slotCounterSpeed < vehicle.slotCounterSpeedMax - 1 ) {
        this.slotCounterSpeed++
        this.slotCounterSpeedOffset = (this.slotCounterSpeed * 150) * -1;;
      }else {
        this.slotCounterSpeed = vehicle.slotCounterSpeedMax - 1;
      }
    }
  }
  counterSpeedDec(vehicle: Vehicle, counterDemon: boolean = false) {
    if (counterDemon) {
      if( this.eightSecondSlotCounterSpeed > 0 ) {
        this.eightSecondSlotCounterSpeed--
        this.eightSecondSlotCounterSpeedOffset = (this.eightSecondSlotCounterSpeed * 150) * -1;;
      }else {
        this.eightSecondSlotCounterSpeed = 0;
      }
    }else {
      if( this.slotCounterSpeed > 0 ) {
        this.slotCounterSpeed--
        this.slotCounterSpeedOffset = (this.slotCounterSpeed * 150) * -1;
      }else {
        this.slotCounterSpeed = 0;
      }
    }
  }




  //Fetch all active badges
  fetchBadges(){
    return this.badges
  }

  fetchBadgesByVehicle(vehicle: Vehicle) {
    //Now retrieve all this vehicles badges
    this.badgeService.getBadgesByVehicle(this.user.id, vehicle.id).subscribe( data => {
      
      vehicle.type = 'stock';
      if(!vehicle.isStock){
        vehicle.type = 'modified';
      }

      vehicle.badgesClub = data[vehicle.type].club;
      vehicle.badgesElapsedTime = data[vehicle.type].et;
      vehicle.badgesSixtyFeetTime = data[vehicle.type].zerotosixty;
      vehicle.badgesTopSpeed = data[vehicle.type].topspeed;

      //Counters if logged in
      vehicle.slotCounterETMax = vehicle.badgesElapsedTime.length;
      vehicle.slotCounterSixtyMax = vehicle.badgesSixtyFeetTime.length;
      vehicle.slotCounterSpeedMax = vehicle.badgesTopSpeed.length;
      
      let demonType = 'eightSecondStock';
      if (vehicle.isDemon) {
        if (!vehicle.isStock) {
          demonType = 'eightSecondModified'
        }

        vehicle.badgesEightSecondClub = data[demonType].club;
        vehicle.badgesElapsedTime8SC = data[demonType].et;
        vehicle.badgesSixtyFeetTime8SC = data[demonType].zerotosixty;
        vehicle.badgesTopSpeed8SC = data[demonType].topspeed;

        // Counters
        vehicle.eightSecondSlotCounterETMax = vehicle.badgesElapsedTime8SC.length;
        vehicle.eightSecondSlotCounterSixtyMax = vehicle.badgesSixtyFeetTime8SC.length;
        vehicle.eightSecondSlotCounterSpeedMax = vehicle.badgesTopSpeed8SC.length;
      }

      if(!this.isOwnersProfile){
        let ETCount = 0;
        let SixtyCount = 0;
        let SpeedCount = 0;
        for(let x=0; x<vehicle.badgesElapsedTime.length; x++){
          if(vehicle.badgesElapsedTime[x].found){
            ETCount++;
          }
        }

        for(let x=0; x<vehicle.badgesSixtyFeetTime.length; x++){
          if(vehicle.badgesSixtyFeetTime[x].found){
            SixtyCount++;
          }
        }

        for(let x=0; x<vehicle.badgesTopSpeed.length; x++){
          if(vehicle.badgesTopSpeed[x].found){
            SpeedCount++;
          }
        }

        vehicle.slotCounterETMax = ETCount;
        vehicle.slotCounterSixtyMax = SixtyCount;
        vehicle.slotCounterSpeedMax = SpeedCount;
      }

      this.badgesLoaded = true
    });
  }

  fetchTimeSlips(isStock = false) {
    this.timeSlipService.getTimeSlips(isStock).subscribe( data => {
    });
  }

  fetchTimeSlipsByVehicle(user: UserModel, vehicle: Vehicle) {
    this.timeSlipService.getTimeSlipsByVehicle(user, vehicle).subscribe( data => {
      vehicle.slips = data;
    });
  }

  fetchVehicles() {
    this.vehicleService.getVehiclesByUser(this.user.id).subscribe( data => {
        this.user.vehicles = data;
        //this.vehicleCount.emit(data.length);
        this.user.vehicles.forEach( vehicle => {
          this.fetchBadgesByVehicle(vehicle)
        });
      },
      err => {
        console.log(err);
      });
  }



  openVehicleModal(template: TemplateRef<any>, vehicle: Vehicle = new Vehicle()) {
    this.vehicleModalRef = this.modalService.show(template, {class: 'modal-full'});
    this.vehicleToEdit = vehicle;
  }

  closeVehicleModal(event) {
    this.fetchVehicles();
    this.vehicleModalRef.hide();
    let isNewVehicle = this.user.vehicles.findIndex( vehicle => vehicle.id === event.id);
    if ( isNewVehicle == -1 ) { // Check if vehicle is in array
      this.selectedVehicleId = this.user.vehicles[0].id;
    } else {
      this.selectedVehicleId = event.id;
    }
  }

  vehicleDeleted() {
    this.fetchVehicles();
    this.vehicleModalRef.hide();
    this.selectedVehicleId = this.user.vehicles[0].id;
  }

  openTimeslipModal(template: TemplateRef<any>, timeslip: TimeSlip = new TimeSlip(), vehicle: Vehicle = new Vehicle()) {
    if (!timeslip) // If null, set to blank
      timeslip = new TimeSlip();
    this.timeslipModalRef = this.modalService.show(template, {class: 'modal-full'});
    this.vehicleToEdit = vehicle;
    this.timeslipToEdit = timeslip;
  }

  closeTimeslipModal() {
    this.timeslipModalRef.hide();
    if (this.user.vehicles.length > 0) {
      this.user.vehicles.forEach( vehicle => {
        this.fetchTimeSlipsByVehicle(this.user, vehicle);
      })
    }
  }


  //-------------------------------
  // ECWID Integration
  //-------------------------------

  //Setup Ecwid and Login
  setupEcwid(action:String = 'setup'){
    if (action === 'setup') {
      let that = this;
      const storeId = 15330536;
      const scriptEcwid = this.renderer2.createElement('script');
      scriptEcwid.setAttribute('type', 'text/javascript');
      scriptEcwid.setAttribute('id', 'ecwid-script-1320');
      scriptEcwid.setAttribute('charset', 'utf-8');
      scriptEcwid.setAttribute('data-cfasync', 'false');
      scriptEcwid.setAttribute('src', `https://app.ecwid.com/script.js?${storeId}&data_platform=singleproduct_v2`);
      this.renderer2.appendChild(this.document.body, scriptEcwid);

      //Has to be in a timeout
      setTimeout(function () {
        Ecwid.init();
        Ecwid.OnAPILoaded.add(function() {
          Ecwid.setSsoProfile(that.userService.userModel.ecwidUser);
          Ecwid.Cart.setCustomerEmail(that.userService.userModel.email);
          Ecwid.OnCartChanged.add(function(cart) {
            that.getEcwidCartItems();
          });
        });
        console.log('setup', Ecwid);
      }, 2500);
    }

    if (action === 'destroy') {
      Ecwid.Cart.clear();
      Ecwid.destroy();
      console.log('destroy', Ecwid);
      this.renderer2.removeChild(this.document.body, this.document.querySelector('#ecwid-script-1320'));
    }
  }

  //Get number of items in cart
  getEcwidCartItems(){
    let that = this;
    Ecwid.Cart.get(function(cart){
      that.cartQuantity = cart.productsQuantity;
      that.cd.detectChanges();
    });
  }

  //Add new product to the cart
  addEcwidProduct(id){
    let that = this;

    Ecwid.Cart.addProduct(id, function(){
      Ecwid.Cart.gotoCheckout();
      that.getEcwidCartItems();
    });
  }

  checkout(){
    Ecwid.Cart.gotoCheckout();
  }





  openImageModal(template: TemplateRef<any>, imgSrc: string, event) {
    event.preventDefault();
    this.imgBigger = imgSrc;
    this.imgModalRef = this.modalService.show(template);
  }
}
