import { Injectable } from '@angular/core';

@Injectable()
export class GalleryServiceService {

  constructor() { }

  galleryItems = [
    {
      name: 'Side by Side',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-side-by-side-no-cover.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/side-by-side-no-cover.jpg'
    },
    {
      name: 'Nose to Nose',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-nose-to-nose.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/nose-to-nose.jpg'
    },
    {
      name: 'Viper Intro',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-viper-book-intro.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/viper-book-intro.jpg'
    },
    {
      name: 'Viper Auth Package',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-viper-book-authentication.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/viper-book-authentication.jpg'
    },
    {
      name: 'Viper In Plant',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-viper-in-plant.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/viper-in-plant.jpg'
    },
    {
      name: 'Viper Engine',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-viper-engine-cover.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/viper-engine-cover.jpg'
    },
    {
      name: 'Demon Intro',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-demon-book-intro.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/demon-book-intro.jpg'
    },
    {
      name: 'Demon Auth Package',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-demon-book-authentication.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/demon-book-authentication.jpg'
    },
    {
      name: 'Demon Casual',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-demon-casual.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/demon-casual.jpg'
    },
    {
      name: 'Demon Profile',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-demon-side.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/demon-side.jpg'
    },
    {
      name: 'Demon Engine',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-demon-engine-cover.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/demon-engine-cover.jpg'
    },
    {
      name: 'Pre Unveil 01',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-ultimate-last-chance-01.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/ultimate-last-chance-01.jpg'
    },
    {
      name: 'Pre Unveil 02',
      thumb: '/assets/images/ultimate-last-chance/gallery/thumb-ultimate-last-chance-02.jpg',
      full: '/assets/images/ultimate-last-chance/gallery/ultimate-last-chance-02.jpg'
    }
  ]

  getGalleryItems: Function = () => this.galleryItems;

}
