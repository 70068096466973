<div id="mission-banner">
  <div class="mission">
    <h2 class="mission-heading text-uppercase">Dodgegarage: Always Open</h2>
    <p>
      The only place on the planet built exclusively for real enthusiasts to congregate around the latest in power and performance.
    </p>
    <p>
      Where else are you gonna find a 25//8, adrenaline-fueled power party inside the Brotherhood of Muscle? Welcome to the garage. DodgeGarage.
    </p>
  </div>
  <div class="logo">
    <img src="assets/images/logos/dodge-garage-logo.png" alt="Dodge Garage - Logo">
  </div>
</div>
